import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const instances = (token) =>
  client.get(apiUrl + '/api/apps', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const downloadUserList = (token) =>
  client.get(apiUrl + '/api/apps/download/users.xlsx', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const instanceOrganisations = (token, id, params) =>
  client.get(apiUrl + `/api/apps/${id}/organisations?${params}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteOrganisation = (token, id, orgId) =>
  client.remove(apiUrl + `/api/apps/${id}/organisations/${orgId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const instanceSettings = (token, id) =>
  client.get(apiUrl + `/api/apps/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const instanceThemes = (token, id) =>
  client.get(apiUrl + `/api/apps/${id}/themes?nopagination`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const saveInstance = (token, id, payload) =>
  client.put(apiUrl + `/api/apps/${id}`, payload, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const themesList = (token, id) =>
  client.get(apiUrl + `/api/apps/${id}/themes?get&id=${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleOrganisation = (token, organisationId, id) =>
  client.get(apiUrl + `/api/apps/${organisationId}/organisations/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const organisationUsers = (token, organisationId, id, payload) =>
  client.get(
    apiUrl +
      `/api/apps/${organisationId}/organisations/${id}/users${
        payload ? '?' + payload : ''
      }`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const groupList = (token) =>
  client.get(apiUrl + '/api/groups?nopagination', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const sectorsList = (token) =>
  client.get(apiUrl + '/api/sectors?nopagination', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
