/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useEffect, Fragment, useRef } from 'react'
import { getTotalDownloads } from '../../../../../shared/services/api/logs/logs'
import Pagination from '@material-ui/lab/Pagination'
import '../PdfDownloads.scss'
import { InputAdornment, OutlinedInput, Tooltip } from '@material-ui/core'
import Spinner from '../../../../../shared/Spinner'
import useNotification from '../../../../../hooks/useNotification'
import { useAdmin } from '../../../../../context/adminContext'
import { Info } from '@material-ui/icons'

const columnData = [
  { id: 'Username' },
  { id: 'Organisation name' },
  { id: 'Module' },
  { id: 'Total downloads' },
]

const TopDownloaders = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setIsLoading] = useState(false)
  const {
    adminState: { topDownloaders },
    setAdminState,
  } = useAdmin()
  const [page, setPage] = useState(
    +Object.fromEntries(new URLSearchParams(topDownloaders?.previousQuery))
      ?.page || 1,
  )
  const [keyword, setKeyword] = useState(
    Object.fromEntries(new URLSearchParams(topDownloaders?.previousQuery))
      ?.keyword || '',
  )
  const [columns, setColumns] = useState(columnData)
  const { warningToast } = useNotification()

  const fetchTotalDownloads = async (forceReload) => {
    try {
      const query = new URLSearchParams({
        keyword: keyword,
        page: page,
      }).toString()

      if (topDownloaders?.previousQuery === query && !forceReload) return false
      setIsLoading(true)
      const response = await getTotalDownloads(token(), query)

      if (response?.list?.data?.length === 0) {
        warningToast({ message: 'No results found.' })
      }

      response.list.previousQuery = query
      setAdminState((currentState) => ({
        ...currentState,
        topDownloaders: response.list,
      }))
      setIsLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    const ignore = fetchTotalDownloads()
  }, [page])

  return (
    <div className='pdf-logs'>
      <h5>Search</h5>

      <div className='signings-header__datepicker'>
        <form
          style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          onSubmit={(event) => {
            event.preventDefault()
            setPage(1)
            const ignore = fetchTotalDownloads(true)
          }}
        >
          <OutlinedInput
            variant='outlined'
            placeholder='Search'
            fullWidth
            value={keyword}
            onChange={(event) => setKeyword(event.target.value)}
            startAdornment={
              <InputAdornment position='start'>
                <span style={{ fontSize: 26 }} className='icon-search' />
              </InputAdornment>
            }
          />
        </form>
      </div>

      {isLoading ? (
        <Spinner centerHorizontally padding={20} size={30} />
      ) : (
        <Fragment>
          <table>
            <thead>
              <tr>
                {columns.map((item) => (
                  <th key={item.id}>{item.id}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {topDownloaders?.data?.map((log) => {
                const {
                  id,
                  module: { name },
                  user,
                  email,
                  username,
                  organisation_name,
                  total_downloads,
                } = log

                return (
                  <tr key={id}>
                    <td>
                      {user ? (
                        username
                      ) : (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          User deleted
                          <Tooltip placement='top' title={email || ''} arrow>
                            <Info style={{ marginLeft: 6, fontSize: 17.5 }} />
                          </Tooltip>
                        </span>
                      )}
                    </td>

                    <td>{organisation_name || '-'}</td>

                    <td>{name}</td>

                    <td>{total_downloads}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <Pagination
            onChange={(event, value) => setPage(value)}
            page={page}
            count={topDownloaders?.last_page}
            shape='rounded'
            hideNextButton
            hidePrevButton
            color='primary'
          />
        </Fragment>
      )}
    </div>
  )
}

export default TopDownloaders
