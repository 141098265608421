import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, LinearProgress } from '@mui/material'
import { Delete } from '@material-ui/icons'
import PropTypes from 'prop-types'

import { formatDate } from '../../../shared/helpers/formatDate'
import Button from '../../../shared/components/Button'
import Status from '../../CreateContent/shared/Components/microcomponents/Status'
import DeleteModal from './DeleteModal'

const COLUMNS = [
  { name: 'Name', width: '40%' },
  { name: 'Type', width: '10%' },
  { name: 'Updated', width: '15%' },
  { name: 'Status', width: '10%' },
  { name: 'Options', width: '20%' },
]

const ResourcesTab = ({
  getAllFilteredArticles,
  handleDeleteArticle,
  isLoading,
}) => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState()

  return (
    <div className='knowledge-base-admin__content-right'>
      {isModalOpen && (
        <DeleteModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          onDelete={handleDeleteArticle}
        />
      )}

      <table className='knowledge-base-admin__content-right-table'>
        <thead>
          <tr>
            {COLUMNS.map((item) => (
              <th style={{ width: item.width }} key={item.name}>
                {item.name}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {getAllFilteredArticles().map((article) => (
            <tr key={article.id}>
              <td>{article.title}</td>

              <td style={{ textAlign: 'center' }}>{article.type}</td>

              <td style={{ textAlign: 'center', fontSize: 13 }}>
                {formatDate(article.updated_at)}
              </td>

              <td style={{ textAlign: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Status
                    view
                    status={article.is_published === 1 ? 'Approved' : 'Draft'}
                  />

                  {article.is_published === 1 ? 'Published' : 'Draft'}
                </div>
              </td>

              <td>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <IconButton
                    style={{ padding: 8, marginRight: 10 }}
                    onClick={() => setIsModalOpen(['article', article.id])}
                  >
                    <Delete style={{ color: '#fff', fontSize: 20.5 }} />
                  </IconButton>

                  <Button
                    onClick={() =>
                      history.push(
                        `/app/admin/knowledge-base/article/${article.id}`,
                      )
                    }
                  >
                    <span
                      className='icon-edit'
                      style={{ fontSize: '20px', marginRight: '3px' }}
                    />

                    <span>Edit</span>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isLoading && <LinearProgress style={{ width: '100%' }} />}
    </div>
  )
}

ResourcesTab.propTypes = {
  getAllFilteredArticles: PropTypes.func.isRequired,
  handleDeleteArticle: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default ResourcesTab
