import React from 'react'
import { MenuItem, TextField, Select } from '@material-ui/core'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'

const CATEGORY_OPTIONS = [
  { title: 'TM User Interface', icon: '' },
  { title: 'Searching Content', icon: 'search' },
  { title: 'Creating Content', icon: 'edit' },
  { title: 'Threat Alerts', icon: 'alert' },
  { title: 'Incidents', icon: 'incident' },
  { title: 'Operations', icon: 'operation' },
  { title: 'Threat Actors', icon: 'threat-actor' },
  { title: 'Malware & Tools', icon: 'malware-tools' },
  { title: 'Scenarios', icon: 'scenario' },
  { title: 'Link Analysis', icon: 'analysis' },
  { title: 'MITRE ATT&CK', icon: '' },
  { title: 'API', icon: 'developers-platform' },
  { title: 'Support', icon: 'question' },
]

const SettingsTab = ({
  handleSubmit,
  isCategorySlug,
  titleInput,
  setTitleInput,
}) => (
  <div className='settings-tab'>
    <form
      className='settings-tab__form'
      onSubmit={(event) => handleSubmit(event)}
    >
      <TextField
        onChange={(event) =>
          setTitleInput((prevState) => ({
            ...prevState,
            title: event.target.value,
          }))
        }
        className='settings-tab__input'
        placeholder='title'
        value={titleInput?.title || ''}
        type='text'
        variant='outlined'
        required
      />

      {isCategorySlug && (
        <div className='settings-tab__subcategory-wrapper'>
          <label className='settings-tab__label' htmlFor='icon'>
            Select Display Icon
          </label>

          <Select
            onChange={(event) => {
              setTitleInput((prevState) => ({
                ...prevState,
                icon: event.target.value,
              }))
            }}
            displayEmpty
            value={titleInput?.icon || ''}
            renderValue={
              !titleInput?.icon
                ? () => <div style={{ color: '#aaa' }}>Select Icon</div>
                : undefined
            }
            variant='outlined'
          >
            {CATEGORY_OPTIONS.map(({ title, icon }, key) => (
              <MenuItem
                value={icon}
                key={key}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <span
                  className={`icon-${icon}`}
                  style={{ fontSize: '20px', marginRight: 10 }}
                />

                <span>{title}</span>
              </MenuItem>
            ))}
          </Select>
        </div>
      )}

      <Button
        style={{
          backgroundColor: '#006FFF',
          padding: '6px 16px',
          marginTop: 30,
          height: 50,
          textTransform: 'capitalize',
          color: '#fff',
          borderRadius: 12,
          width: 150,
        }}
        type='submit'
      >
        Save
      </Button>
    </form>
  </div>
)

SettingsTab.propTypes = {
  titleInput: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isCategorySlug: PropTypes.bool.isRequired,
  setTitleInput: PropTypes.func.isRequired,
}

export default SettingsTab
