import React from 'react'
import Regraph from './Regraph'

/**
 * LinkAnalysis
 */

const LinkAnalysis = ({ view }) => {
  return (
    <div style={{ width: '100%', backgroundColor: '#fff' }}>
      <Regraph view={view} />
    </div>
  )
}

LinkAnalysis.displayName = 'LinkAnalysis'

/**
 * The properties.
 * @type {Object}
 */

LinkAnalysis.propTypes = {}

export default LinkAnalysis
