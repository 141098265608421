import React, { memo } from 'react'
import { Alert, Button, Snackbar } from '@mui/material'
import { useGlobalContext } from '../../context/globalContext'

const GlobalToast = ({ time, autosave }) => {
  const { toastMessage, setToastMessage } = useGlobalContext()
  const type = toastMessage[0]
  const text = toastMessage[1]

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={time || 4000}
      onClose={() => type === 'success' && setToastMessage(null)}
      open={toastMessage !== null}
    >
      <Alert
        elevation={6}
        variant='filled'
        severity={type}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <span>{text}</span>

        {type === 'error' && autosave !== undefined && (
          <Button
            onClick={() => autosave()}
            style={{
              color: '#fff',
              backgroundColor: '006fff',
              fontSize: 13,
              textTransform: 'capitalize',
              border: '1px solid #fff',
              marginLeft: 15,
              borderRadius: 12,
              padding: '3px 6px',
            }}
          >
            Try again
          </Button>
        )}
      </Alert>
    </Snackbar>
  )
}

export default memo(GlobalToast)
