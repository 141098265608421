import React from 'react'
import { Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Spinner from '../../../../../shared/Spinner'
import { useLocation } from 'react-router-dom'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const AssociatedChips = ({
  title,
  chosenElements,
  options,
  isLoading,
  handleState,
}) => {
  const {
    createContentState: { id },
  } = useCreateManagementContext()
  const associatedType = options
    ? options.filter((item) =>
        chosenElements.some((item2) => item.id === item2.id),
      )
    : []
  const location = useLocation()
  const path = location.pathname.split('/')
  const isModuleAlert = path[path.length - 2] === 'alert' && title === 'Alerts'
  const isModuleProfile =
    path[path.length - 2] !== 'alert' && title !== 'Alerts'
  const returnOptions = () => {
    if (isModuleAlert || isModuleProfile) {
      return options.filter((option) => option.id !== id) || []
    } else return options
  }

  return (
    <div style={{ width: 'unset' }} className='associated'>
      <h4>{title}</h4>

      <div style={{ margin: '10px 0' }}>
        {associatedType.length > 0 &&
          associatedType.map((piece) => (
            <Chip
              style={{
                backgroundColor: '#006bf5',
                color: '#fff',
                margin: 2,
                fontSize: 10,
              }}
              onClick={() => {}}
              onDelete={() => handleState(piece.id, true)}
              label={piece.title}
              key={piece.id}
              deleteIcon={
                <span
                  className='icon-close'
                  style={{
                    width: 'unset',
                    height: 'unset',
                    fontSize: 14,
                    padding: '4px 4px 4px 0',
                    color: '#fff',
                  }}
                />
              }
            />
          ))}
      </div>

      <Autocomplete
        multiple
        blurOnSelect
        disableClearable
        filterSelectedOptions
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.title}
        options={returnOptions()}
        value={associatedType || null}
        onChange={(event, value) => {
          const newValue = value[value.length - 1]
          newValue && handleState(newValue)
        }}
        renderTags={() => {
          return false
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`Select ${title}`}
            variant='outlined'
            className='tag-input'
            size='small'
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <Spinner centerHorizontally size={20} /> : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  )
}

export default AssociatedChips
