/**
 * parse error response
 * @function
 * @param {Error} error - Error to parse
 * @returns {Promise} - Contains the error object
 */

export const parseError = (error) => {
  if (!error.response) return

  let errorResponseUrl

  if (error && error.response) {
    errorResponseUrl = error.response.request.responseURL
  }

  if (error.response.status === 409 && errorResponseUrl.match(/groups/gi)) {
    return Promise.reject(error.response.data)
  }

  if (error.response) {
    return Promise.reject(error.response.data)
  }

  return Promise.reject(error)
}
