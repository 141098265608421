import { useQuery } from '@tanstack/react-query'
import { getModules } from '../../api/admin/settings/groups'
import { useQueryCacheState } from '../useQueryCacheState'

const token = () => {
  return localStorage.getItem('access_token')
}

export const useQueryGetModules = () => {
  return useQuery({
    queryKey: ['modules'],
    queryFn: () => getModules(token()),
    staleTime: Infinity,
  })
}

export const useQueryCacheGetModules = () => useQueryCacheState('modules')
