import { Avatar, Divider } from '@material-ui/core'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const Severity = ({}) => {
  const {
    handleCreateState,
    createContentState: { dev_priority },
  } = useCreateManagementContext()
  const levels = [1, 2, 3, 4, 5]

  const handleState = (value) => handleCreateState('dev_priority', value, true)

  return (
    <div className='content-section__content'>
      <Divider />

      <h5>Threat severity</h5>

      <div className='levels-container'>
        {levels.map((level) => (
          <Avatar
            key={level}
            onClick={() => handleState(level)}
            className={
              dev_priority === level
                ? `level-${level}`
                : `level-${level} inactive`
            }
          >
            {level}
          </Avatar>
        ))}
      </div>
    </div>
  )
}

export default Severity
