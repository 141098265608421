/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useState } from 'react'
import './AdminContentExports.scss'
import { Button, Divider, Tooltip } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import useNotification from '../../hooks/useNotification'
import { getContentExport } from '../../shared/services/api/admin/contentExport/contentExport'
import DateRangePickerComponent from '../../shared/DateRangePicker/DateRangePicker'

const AdminContentExports = () => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [hoverItem, setHoverItem] = useState(null)
  const { successToast, errorToast } = useNotification()
  const modules = [
    { name: 'Threat Alerts', slug: 'alert' },
    { name: 'Profiles', slug: 'profile' },
    { name: 'Scenarios', slug: 'scenario' },
    { name: 'Reports', slug: 'report' },
  ]
  const [dateRange, setDateRange] = useState({})

  const exportContentList = async (slug) => {
    try {
      const data = {
        dateFrom: dateRange[slug]?.from,
        dateTo: dateRange[slug]?.to,
        module: slug,
      }
      const response = await getContentExport(token(), data)

      if (response?.error) {
        errorToast({ message: response?.message })
      } else {
        successToast({ message: response?.message })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleDateRange = (key, value) => {
    const nextState = { ...dateRange }
    nextState[key] = { ...nextState[key], ...value }
    setDateRange(nextState)
  }

  return (
    <div className='content-exports'>
      <div className='main__inner'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h3>Content Exports</h3>

          <h4>
            Please note that the export only includes content created in the
            last 6 months
          </h4>
        </div>

        <Divider style={{ margin: '20px 0' }} />

        <div className='content-exports-container'>
          {modules.map(({ slug, name }) => (
            <Fragment key={slug}>
              <div
                className='content-exports-wrapper'
                onMouseEnter={() => setHoverItem(slug)}
                onMouseLeave={() => setHoverItem(null)}
              >
                <span
                  style={{ padding: '7px 0', fontWeight: 600, fontSize: 18 }}
                >
                  {name}
                </span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <DateRangePickerComponent
                    selectedDateRange={dateRange[slug] || {}}
                    handleFilters={handleDateRange}
                    inline={slug}
                  />
                  <Tooltip
                    placement='top'
                    arrow
                    title={
                      !dateRange[slug]?.from || !dateRange[slug]?.to
                        ? 'Select both date range options'
                        : ''
                    }
                  >
                    <span>
                      <Button
                        onClick={() => exportContentList(slug)}
                        startIcon={<Save />}
                        className='edit'
                        disabled={
                          !dateRange[slug]?.from || !dateRange[slug]?.to
                        }
                      >
                        Download Content List
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AdminContentExports
