/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Info } from '@material-ui/icons'

const MitreSubTechnique = ({
  tacticId,
  view,
  subtec,
  layers,
  categoryName,
  returnActive,
  isInAnyLayer,
  handleAddTag,
  returnShade,
  returnCompareBoxes,
  handleShowInfo,
}) => {
  const isCompareMode = layers[1]?.mode
  const returnBorder = (technique_id, tactic_id) => {
    if (view) {
      if (isCompareMode) return null

      if (isInAnyLayer(technique_id, tactic_id)) return '2px solid #666'
    } else if (!view) {
      if (returnActive(technique_id, tactic_id)) return '2px dotted #006fff'
    }
  }
  return (
    <div
      key={subtec.id}
      style={{
        marginBottom: 8,
        border: returnBorder(subtec.id, tacticId),
      }}
      className={`navigator-technique subtechnique${view ? ' is-view' : ''}`}
      onClick={() =>
        !view &&
        subtec.id &&
        handleAddTag(subtec.id, tacticId, categoryName, subtec)
      }
    >
      <div
        className={`navigator-technique__background ${
          returnShade(subtec.id, tacticId)[0] || ''
        } ${returnShade(subtec.id, tacticId)[1] || ''} ${
          returnShade(subtec.id, tacticId)[2] || ''
        }`}
      />

      <span style={{ zIndex: 5, userSelect: 'none', width: '70%' }}>
        {subtec.name}
      </span>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 5,
          zIndex: 5,
        }}
        className='technique-footer'
      >
        <div style={{ display: 'flex' }}>
          {Object.entries(returnCompareBoxes()).map((box) => {
            const id = box[0]
            const value = box[1]
            const colourId = layers?.find((item) => item?.id == id)?.colour
            const equalTechnique =
              value?.techniques[subtec.id] &&
              value?.techniques[subtec.id][tacticId]
            if (equalTechnique) {
              return (
                <span
                  key={id + '_' + subtec.id + '_' + tacticId}
                  className={`compare-box mitre-colour--${colourId || 1}`}
                  // style={{ display: 'block', width: 12, height: 12, border: '2px solid #fff', marginRight: 4 }}
                />
              )
            }
          })}
        </div>

        <Info
          onClick={(event) =>
            handleShowInfo(event, [subtec.name, subtec.description])
          }
          style={{ color: '#0000008a' }}
        />
      </div>
    </div>
  )
}

export default MitreSubTechnique
