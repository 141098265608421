import { FormControl, MenuItem, Select } from '@material-ui/core'
import React from 'react'

const OrderBy = ({ orderBy, orderChange, noRelevance, handleFilters }) => {
  return (
    <FormControl>
      <Select
        className='order-by-select'
        id='order-by'
        value={orderBy}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur()
          }, 0)
        }}
        onChange={(event) =>
          handleFilters
            ? handleFilters('orderBy', event.target.value)
            : orderChange(event.target.value)
        }
        variant='outlined'
      >
        <MenuItem style={{ fontSize: 13 }} value='default'>
          Newest first
        </MenuItem>

        <MenuItem style={{ fontSize: 13 }} value='updated_at:asc'>
          Oldest first
        </MenuItem>

        {!noRelevance && (
          <MenuItem style={{ fontSize: 13 }} value='relevance:desc'>
            Relevance
          </MenuItem>
        )}

        <MenuItem style={{ fontSize: 13 }} value='title:asc'>
          A-Z
        </MenuItem>

        <MenuItem style={{ fontSize: 13 }} value='title:desc'>
          Z-A
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default OrderBy
