import React from 'react'
import { Button, IconButton } from '@material-ui/core'
import FroalaEditorComponent from '../../../../../../../shared/FroalaEditorComponent/FroalaEditorComponent'

const EditEditor = ({
  handleEditorChange,
  isEditorTouched,
  handleEditComment,
  activePiece,
  comment,
  handleCloseEditors,
}) => {
  return (
    <div className='single-comment__bottom'>
      <div className='editor-wrapper edit'>
        <IconButton className='close' onClick={handleCloseEditors}>
          <span className='icon-close' />
        </IconButton>

        <FroalaEditorComponent
          handleEditorChange={handleEditorChange}
          commentText={comment.content}
        />

        <div className='editor-footer'>
          <div />

          <Button
            disabled={isEditorTouched.length === 0}
            className='add'
            onClick={() => handleEditComment(activePiece, comment.id)}
          >
            Edit comment
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EditEditor
