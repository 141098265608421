import { Button } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {
  editPiece,
  sendInitialDraft,
} from '../../../../shared/services/api/createContent/createAlert'
import { initialData } from './data'
import cloneDeep from 'lodash/cloneDeep'
import { saveProfile } from '../../shared/helpers/saveDraft'
import ContentSection from './ContentSection'
import PublicationCheck from '../../shared/Components/microcomponents/PublicationCheck'
import Spinner from '../../../../shared/Spinner'
import BottomBar from '../../shared/Components/microcomponents/BottomBar'
import PropTypes from 'prop-types'
import { useGlobalContext } from '../../../../context/globalContext'
import CreateToast from '../../shared/CreateToast'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { changesMade, saveDraftHandler } from '../../shared/helpers/saveHandler'
import useNotification from '../../../../hooks/useNotification'
import { useDirtyCreate } from '../../../../context/dirtyCreateContext'
import { useCreateManagementContext } from '../../../../context/createManagementState'
import { getMitreData } from '../../../../shared/services/api/mitre/mitre'

/**
 * Profile - create mode
 * @param {object} configuration - includes configuration for profiles modules
 */

const Profile = ({ configuration }) => {
  const {
    createContentState,
    handleCreateState,
    setCreateContentState,
    setMitreConfig,
  } = useCreateManagementContext()
  const {
    isContentBeingEdited,
    setIsContentBeingEdited,
    setConfirmAutosaveModal,
  } = useGlobalContext()
  const { dirtyCreateState, setDirtyCreateState } = useDirtyCreate()
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setLoading] = useState(false)
  const initData = cloneDeep(initialData[configuration.slug])
  const [toastMessage, setToastMessage] = useState(undefined)
  const [isPublicationCheckActive, setIsPublicationCheckActive] =
    useState(false)
  const [isSaving, setSaving] = useState(false)
  const [isSendingForApproval, setSendingForApproval] = useState(false)
  const [isDenying, setDenying] = useState(false)
  const [isPreviewOpen, setPreviewOpen] = useState(false)
  const { infoToast, successToast, errorToast } = useNotification()

  const initialDraft = async (title) => {
    if (isSaving) return false

    try {
      setSaving(true)
      setToastMessage(['info', 'Creating the content'])
      const data = {
        ...createContentState,
        dev_title: title || 'Profile title',
        dev_type_id: configuration.id,
      }
      const response = await sendInitialDraft(token(), 'profiles', data)

      if (response.error) {
        setToastMessage(['error', response.message])
        setSaving(false)
        setIsContentBeingEdited(false)
        return false
      }

      Object.entries(response.data.content_distribution.organisations).forEach(
        (org) => {
          const id = org[0]
          org[1].id = id
        },
      )

      response.data = { ...response.data, ...createContentState }
      response.data.dev_content['content-distribution'].content =
        response.data.content_distribution
      response.data.dev_tlp = { colour: 'amber' }
      setCreateContentState(response.data)
      setDirtyCreateState(cloneDeep(response.data))
      setToastMessage(['success', response.message])
      setIsContentBeingEdited([configuration.slug, response.data.id])
      loadMitreData()
      setSaving(false)
    } catch (err) {}
  }

  const loadPieceData = async () => {
    setLoading(true)
    try {
      const response = await editPiece(token(), 'profiles', id)

      if (response.error === true) {
        setLoading(false)
        setToastMessage(['error', response.message])
        return false
      }

      if (response.data.isLocked) {
        const { first_name, last_name } = response.data.isLocked[0]
        setToastMessage([
          'warning',
          `Content is currently being edited by ${first_name} ${last_name}`,
        ])
        setLoading(false)
        setTimeout(() => history.push('/app/content-management'), 6000)
        return false
      }

      configuration.schema.forEach((section) => {
        if (
          !response.data.dev_content[section.internal_key] &&
          section.internal_key !== 'update-summary'
        ) {
          response.data.dev_content[section.internal_key] = {
            content: {},
            is_complete: false,
            is_rejected: false,
            is_accepted: false,
            has_been_edited: false,
            comments: [],
          }
        }
      })

      setIsContentBeingEdited([configuration.slug, response.data.id])

      Object.entries(response.data.content_distribution.organisations).forEach(
        (org) => {
          const id = org[0]
          org[1].id = id * 1
        },
      )

      response.data.dev_content['content-distribution'].content =
        response.data.content_distribution

      if (response?.data?.relevance?.organisations) {
        response.data.dev_content['content-distribution'].content.relevance =
          response.data.relevance.organisations
      }

      Object.values(response.data.dev_content).forEach((section) => {
        if (section.is_rejected) {
          section.is_complete = true
        }
      })

      if (response.data.published !== 1 && !response.data.is_duplicated) {
        delete response.data.dev_content['update-summary']
      }

      if (response.data.dev_content['update-summary']) {
        response.data.dev_content['update-summary'].is_accepted = false
      }

      setCreateContentState(response.data)
      setDirtyCreateState(cloneDeep(response.data))
      loadMitreData()
      setLoading(false)
    } catch (error) {}
  }

  useEffect(() => {
    const contentPath = location.pathname.split('/').slice(-2)
    const contentType = contentPath[0]
    const contentId = contentPath[1]
    if (
      isContentBeingEdited &&
      isContentBeingEdited[0] !== contentType &&
      isContentBeingEdited[1] != contentId
    ) {
      setLoading(true)
      setCreateContentState({})
      setIsContentBeingEdited(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (
      id !== 'new' &&
      (isContentBeingEdited === null || isContentBeingEdited === false)
    ) {
      setCreateContentState({})
      loadPieceData()
    } else if (id === 'new' && isContentBeingEdited === false) {
      setCreateContentState({ dev_content: initData })
    } else if (id === 'new' && isContentBeingEdited === true) {
      initialDraft()
    }
  }, [isContentBeingEdited])

  const handleExpandedSections = (section, data) => {
    const currentState = { ...data }

    currentState.is_expanded = !currentState.is_expanded
    handleCreateState(section, currentState, undefined, true)
  }

  const handleCompleteSections = (section) => {
    const currentState = { ...createContentState.dev_content[section] }

    currentState.is_complete = !currentState.is_complete

    if (currentState.is_complete) {
      currentState.is_expanded = false
    }

    if (createContentState.can_publish_or_deny && currentState.is_complete) {
      currentState.is_accepted = true
    }

    if (currentState.is_complete) {
      if (createContentState.can_publish_or_deny) {
        currentState.is_accepted = true
        handleCreateState(section, currentState, undefined, true)
        handleAutosave()
        return false
      } else if (
        !createContentState.can_publish_or_deny &&
        !currentState.is_rejected
      ) {
        handleCreateState(section, currentState, undefined, true)
        handleAutosave()
        return false
      }
    }

    handleCreateState(section, currentState, undefined, true)
  }

  const handleSectionStatus = (section, data, shouldReset, isSidebar) => {
    const currentState = { ...createContentState.dev_content[section] }

    if (shouldReset) {
      if (isSidebar) {
        currentState[data] = !currentState[data]
        currentState.is_complete = true
      } else {
        currentState[data] = !currentState[data]
        if (currentState.is_rejected) {
          currentState.is_complete = true
        }
      }
    } else {
      if (!createContentState.can_publish_or_deny) {
        currentState.is_accepted = false
        currentState.is_rejected = false
        currentState.is_complete = false
      } else if (
        data === 'is_accepted' &&
        createContentState.can_publish_or_deny
      ) {
        currentState.is_accepted = true
        currentState.is_rejected = false
        currentState.is_expanded = false
        currentState.is_complete = true
      } else if (
        data === 'is_rejected' &&
        createContentState.can_publish_or_deny
      ) {
        currentState.is_accepted = false
        currentState.is_rejected = true
        currentState.is_expanded = false
        currentState.is_complete = true
      }
    }

    handleCreateState(section, currentState)

    if (
      currentState.is_complete &&
      currentState.is_rejected &&
      createContentState.can_publish_or_deny
    ) {
      handleAutosave()

      return false
    }

    if (currentState.is_complete && !currentState.is_rejected) {
      if (
        (createContentState.can_publish_or_deny && currentState.is_accepted) ||
        (!createContentState.can_publish_or_deny && !currentState.is_accepted)
      ) {
        handleAutosave()
      }
    }
  }

  const handleCancelCreate = (message) => {
    history.push({
      pathname: '/app/content-management',
      state: { message: message },
    })
    setTimeout(() => {
      setCreateContentState({})
      setIsContentBeingEdited(false)
      setConfirmAutosaveModal(false)
    })
  }

  const handleSaveDraft = async (actionName, actionValue) => {
    const isContentChanged = await changesMade(
      isContentBeingEdited,
      actionValue,
      actionName,
      createContentState,
      dirtyCreateState,
    )

    if (
      !isContentChanged &&
      actionName !== 'approval' &&
      actionName !== 'deny'
    ) {
      successToast({ message: 'Profile has been saved' })
      return false
    }

    setSaving(true)

    // const createStateDeepClone = cloneDeep(createContentState)
    // const dirtyCreateStateDeepClone = cloneDeep(dirtyCreateState)
    // const diff = difference(createStateDeepClone, dirtyCreateStateDeepClone)
    // const isDistributionOrRelevanceChanged = !!(diff?.content_distribution || diff?.relevance)

    if (actionName === 'titleChange') createContentState.dev_title = actionValue
    const currentState = cloneDeep(createContentState)
    const data = saveProfile(currentState)
    await saveDraftHandler(
      'profiles',
      data,
      setSaving,
      setDenying,
      actionName,
      handleCancelCreate,
      setSendingForApproval,
      infoToast,
      successToast,
      errorToast,
    )
    // const finalState = {...createContentState, ...publicationCheckData}
    // setCreateContentState(finalState)
    setDirtyCreateState(cloneDeep(currentState))
  }

  const handleSendForApproval = () => handleSaveDraft('approval')
  const handleDeny = () => setTimeout(() => handleSaveDraft('deny'), 100)
  const handleAutosave = (action, value) => {
    if (isContentBeingEdited) {
      handleSaveDraft(action, value)
    } else {
      action && value.length > 0 && initialDraft(value)
    }
  }
  const isContentAlreadyEdited = JSON.parse(
    localStorage.getItem('isContentEdited'),
  )
  const handleTabClosing = () =>
    !isContentAlreadyEdited && localStorage.setItem('isContentEdited', null)

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing)
    return () => window.removeEventListener('unload', handleTabClosing)
  }, [])

  useEffect(() => {
    let intervalId
    if (!isContentAlreadyEdited && Number(id)) {
      localStorage.setItem('isContentEdited', id)
    } else if (
      isContentAlreadyEdited &&
      id &&
      Number(id) !== isContentBeingEdited[1]
    ) {
      intervalId = setTimeout(
        () => (window.location.href = '/app/workspace'),
        500,
      )
    }

    return () => {
      localStorage.setItem('isContentEdited', null)
      clearTimeout(intervalId)
    }
  }, [id])

  const loadMitreData = async () => {
    try {
      const response = await getMitreData(token(), 'profile', false)

      setMitreConfig(response)
    } catch (err) {}
  }

  return (
    <>
      {isLoading && <Spinner center size={50} border={1} />}

      {toastMessage && (
        <CreateToast
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}

      {Object.keys(createContentState).length > 0 && (
        <div className='create-alert'>
          <div className='create-alert__header'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 20px',
                height: 50,
              }}
            >
              <Button
                onClick={() => handleCancelCreate('')}
                disabled={isSaving}
                startIcon={<ArrowBackIcon />}
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: 'transparent',
                  color: '#fff',
                  padding: '6px 20px 6px 6px',
                  borderRadius: 0,
                  marginRight: 20,
                  borderRight: '1px solid #505050',
                }}
              >
                Back to content
              </Button>

              <span
                style={{ fontSize: 32, marginRight: 10 }}
                className={`icon-${configuration.slug}`}
              />

              <h2>Create {configuration.name}</h2>
            </div>
          </div>

          <ContentSection
            configuration={configuration.schema}
            handleCompleteSections={handleCompleteSections}
            handleExpandedSections={handleExpandedSections}
            handleSectionStatus={handleSectionStatus}
            setToastMessage={setToastMessage}
            handleAutosave={handleAutosave}
            isSaving={isSaving}
          />

          {isPublicationCheckActive && (
            <PublicationCheck
              isOpen={isPublicationCheckActive}
              setIsPublicationCheckActive={setIsPublicationCheckActive}
              handleCancelCreate={handleCancelCreate}
              type='profiles'
              setToastMessage={setToastMessage}
              profileType={configuration.slug}
            />
          )}

          {typeof isContentBeingEdited[1] === 'number' && (
            <BottomBar
              module='profiles'
              isSaving={isSaving}
              handleSaveDraft={handleSaveDraft}
              isPreviewOpen={isPreviewOpen}
              setPreviewOpen={setPreviewOpen}
              configuration={configuration.schema}
              isDenying={isDenying}
              handleDeny={handleDeny}
              isSendingForApproval={isSendingForApproval}
              handleSendForApproval={handleSendForApproval}
              setIsPublicationCheckActive={setIsPublicationCheckActive}
              setToastMessage={setToastMessage}
            />
          )}
        </div>
      )}
    </>
  )
}

Profile.displayName = 'Profile'

/**
 * The properties.
 * @type {Object}
 */

Profile.defaultProps = {
  configuration: {
    slug: '',
    name: '',
    schema: [],
    id: '',
  },
}

Profile.propTypes = {
  configuration: PropTypes.instanceOf(Object),
}

export default Profile
