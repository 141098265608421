import React from 'react'

import './KnowledgeBaseAdminArticle.scss'
import useLogic from './useKnowledgeBaseAdminArticle'
import {
  ArticleHeader,
  ArticleMainContent,
  SelectCategoriesBox,
} from './components'
import Spinner from '../../../shared/Spinner'

const KnowledgeBaseAdminArticle = () => {
  const {
    categories,
    data,
    handleAddSection,
    handleCategories,
    handleDeleteSection,
    handleDragEnd,
    // handleExpandCategories,
    handleSectionState,
    handleState,
    isAddingContent,
    isLoading,
    saveArticle,
    sensors,
    setAddingContent,
  } = useLogic()

  return isLoading ? (
    <Spinner center size={50} />
  ) : (
    <div className='knowledge-base__article'>
      <ArticleHeader
        data={data}
        handleState={handleState}
        saveArticle={saveArticle}
      />

      <div className='knowledge-base__article-content'>
        <ArticleMainContent
          data={data}
          isAddingContent={isAddingContent}
          handleAddSection={handleAddSection}
          handleDeleteSection={handleDeleteSection}
          handleDragEnd={handleDragEnd}
          handleSectionState={handleSectionState}
          handleState={handleState}
          sensors={sensors}
          setAddingContent={setAddingContent}
        />

        <SelectCategoriesBox
          data={data}
          categories={categories}
          handleCategories={handleCategories}
          // handleExpandCategories={handleExpandCategories}
        />
      </div>
    </div>
  )
}

export default KnowledgeBaseAdminArticle
