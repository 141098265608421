import { useQuery } from '@tanstack/react-query'
import { getForumCategories } from '../../api/admin/forum/categories'
import { useQueryCacheState } from '../useQueryCacheState'

export const useQueryGetForumCategoriesAdmin = () => {
  return useQuery({
    queryKey: ['categories-admin'],
    queryFn: getForumCategories,
  })
}

export const useQueryCacheGetForumCategoriesAdmin = () =>
  useQueryCacheState('categories-admin')
