import { Button, MenuItem, Select } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import './CreateAlert.scss'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  editPiece,
  sendInitialDraft,
} from '../../../../shared/services/api/createContent/createAlert'
import ContentSection from './ContentSection'
import { initialData } from './data'
import cloneDeep from 'lodash/cloneDeep'
import PublicationCheck from '../../shared/Components/microcomponents/PublicationCheck'
import { saveReport } from '../../shared/helpers/saveDraft'
import Spinner from '../../../../shared/Spinner'
import BottomBar from '../../shared/Components/microcomponents/BottomBar'
import PropTypes from 'prop-types'
import { useGlobalContext } from '../../../../context/globalContext'
import CreateToast from '../../shared/CreateToast'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  deleteReportPdf,
  previewReportPdf,
  uploadReportPdf,
} from '../../../../shared/services/api/reports/reports'
import UploadPDF from './UploadPDF'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { IconButton, Tooltip } from '@mui/material'
import { Delete } from '@material-ui/icons'
import ConfirmDelete from '../../../ContentManage/ConfirmDelete'
import { changesMade, saveDraftHandler } from '../../shared/helpers/saveHandler'
import { useDirtyCreate } from '../../../../context/dirtyCreateContext'
import useNotification from '../../../../hooks/useNotification'
import { useCreateManagementContext } from '../../../../context/createManagementState'
import { useUserContext } from '../../../../context/userContext'
import { getMitreData } from '../../../../shared/services/api/mitre/mitre'

/**
 * Report - create mode
 * @param {object} configuration - includes configuration for report module
 */

const Report = ({ configuration }) => {
  const {
    createContentState,
    handleCreateState,
    setCreateContentState,
    setMitreConfig,
  } = useCreateManagementContext()
  const {
    isContentBeingEdited,
    setIsContentBeingEdited,
    setConfirmAutosaveModal,
  } = useGlobalContext()
  const { dirtyCreateState, setDirtyCreateState } = useDirtyCreate()
  const {
    user: { is_pdf_upload_enabled },
  } = useUserContext()
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setLoading] = useState(false)
  const initData = cloneDeep(initialData)
  const [toastMessage, setToastMessage] = useState(undefined)
  const [isPublicationCheckActive, setIsPublicationCheckActive] =
    useState(false)
  const [isSaving, setSaving] = useState(false)
  const [isSendingForApproval, setSendingForApproval] = useState(false)
  const [isDenying, setDenying] = useState(false)
  const [isPreviewOpen, setPreviewOpen] = useState(false)
  const [isPreviewingPdf, setIsPreviewingPdf] = useState(false)
  const [deletePdfOpen, setDeletePdfOpen] = useState(undefined)
  const [isDeleting, setIsDeleting] = useState(false)
  const { infoToast, successToast, errorToast } = useNotification()

  const initialDraft = async (title) => {
    if (isSaving) return false

    try {
      setSaving(true)
      setToastMessage(['info', 'Creating the content'])
      const data = {
        ...createContentState,
        dev_title: title || 'Report title',
        dev_type_id: createContentState.dev_type_id,
      }
      const response = await sendInitialDraft(token(), 'reports', data)

      if (response.error) {
        setToastMessage(['error', response.message])
        setSaving(false)
        setIsContentBeingEdited(false)
        return false
      }

      Object.entries(response.data.content_distribution.organisations).forEach(
        (org) => {
          const id = org[0]
          org[1].id = id
        },
      )
      response.data = {
        ...response.data,
        ...{ dev_content: createContentState.dev_content },
      }
      response.data.dev_content['content-distribution'].content =
        response.data.content_distribution
      response.data.dev_tlp = { colour: 'amber' }
      response.data.dev_type_id = createContentState.dev_type_id
      setCreateContentState(response.data)
      setDirtyCreateState(cloneDeep(response.data))
      setToastMessage(['success', response.message])
      setIsContentBeingEdited(['report', response.data.id])
      loadMitreData()
      setSaving(false)
    } catch (err) {}
  }

  const loadPieceData = async () => {
    setLoading(true)
    try {
      const response = await editPiece(token(), 'reports', id)

      if (response.error) {
        setToastMessage(['error', response.message])
        setLoading(false)
        return false
      }

      if (response.data.isLocked) {
        const { first_name, last_name } = response.data.isLocked[0]
        setToastMessage([
          'warning',
          `Content is currently being edited by ${first_name} ${last_name}`,
        ])
        setLoading(false)
        setTimeout(() => history.push('/app/content-management'), 6000)
        return false
      }

      configuration?.types
        ?.filter((item) => item.id === response.data.dev_type_id)[0]
        .schema.forEach((section) => {
          if (
            !response.data.dev_content[section.internal_key] &&
            section.internal_key !== 'update-summary'
          ) {
            response.data.dev_content[section.internal_key] = {
              content: {},
              is_complete: false,
              is_rejected: false,
              is_accepted: false,
              has_been_edited: false,
              comments: [],
            }
          }
        })

      setIsContentBeingEdited(['report', response.data.id])
      Object.entries(response.data.content_distribution.organisations).forEach(
        (org) => {
          const id = org[0]
          org[1].id = id
        },
      )

      response.data.dev_content['content-distribution'].content =
        response.data.content_distribution

      if (response?.data?.relevance?.organisations) {
        response.data.dev_content['content-distribution'].content.relevance =
          response.data.relevance.organisations
      }

      Object.values(response.data.dev_content).forEach((section) => {
        if (section.is_rejected) {
          section.is_complete = true
        }
      })

      if (
        response.data.dev_content['update-summary'] &&
        response.data.dev_content['update-summary'].comments === undefined
      ) {
        response.data.dev_content['update-summary'].comments = []
      }
      if (response.data.published !== 1 && !response.data.is_duplicated) {
        delete response.data.dev_content['update-summary']
      }

      if (response.data.dev_content['update-summary']) {
        response.data.dev_content['update-summary'].is_accepted = false
      }

      setCreateContentState(response.data)
      setDirtyCreateState(cloneDeep(response.data))
      loadMitreData()
      setLoading(false)
    } catch (error) {}
  }

  const handleChangeType = (value) => {
    if (isContentBeingEdited) {
      const newState = createContentState.published
        ? { ...createContentState?.dev_content, ...initData[value] }
        : initData[value]
      handleCreateState('dev_content', [value, newState], true)
    } else {
      handleCreateState('dev_type_id', value, true)
    }
  }

  useEffect(() => {
    const contentPath = location.pathname.split('/').slice(-2)
    const contentType = contentPath[0]
    const contentId = contentPath[1]
    if (
      isContentBeingEdited &&
      isContentBeingEdited[0] !== contentType &&
      isContentBeingEdited[1] != contentId
    ) {
      setLoading(true)
      setCreateContentState({})
      setIsContentBeingEdited(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (Object.keys(createContentState).length === 1) {
      handleCreateState(
        'dev_content',
        [
          createContentState.dev_type_id,
          initData[createContentState.dev_type_id],
        ],
        true,
      )
    }
  }, [createContentState.dev_type_id])

  useEffect(() => {
    if (
      id !== 'new' &&
      (isContentBeingEdited === null || isContentBeingEdited === false)
    ) {
      setCreateContentState({})
      loadPieceData()
    } else if (id === 'new' && isContentBeingEdited === true) {
      initialDraft()
    }
  }, [isContentBeingEdited])

  const handleExpandedSections = (section, data) => {
    const currentState = { ...data }

    currentState.is_expanded = !currentState.is_expanded
    handleCreateState(section, currentState, undefined, true)
  }

  const handleCompleteSections = (section) => {
    const currentState = { ...createContentState.dev_content[section] }

    currentState.is_complete = !currentState.is_complete

    if (currentState.is_complete) {
      currentState.is_expanded = false
    }

    if (createContentState.can_publish_or_deny && currentState.is_complete) {
      currentState.is_accepted = true
    }

    if (currentState.is_complete) {
      if (createContentState.can_publish_or_deny) {
        currentState.is_accepted = true
        handleCreateState(section, currentState, undefined, true)
        handleAutosave()
        return false
      } else if (
        !createContentState.can_publish_or_deny &&
        !currentState.is_rejected
      ) {
        handleCreateState(section, currentState, undefined, true)
        handleAutosave()
        return false
      }
    }

    handleCreateState(section, currentState, undefined, true)
  }

  const handleSectionStatus = (section, data, shouldReset, isSidebar) => {
    const currentState = { ...createContentState.dev_content[section] }

    if (shouldReset) {
      if (isSidebar) {
        currentState[data] = !currentState[data]
        currentState.is_complete = true
      } else {
        currentState[data] = !currentState[data]
        if (currentState.is_rejected) {
          currentState.is_complete = true
        }
      }
    } else {
      if (!createContentState.can_publish_or_deny) {
        currentState.is_accepted = false
        currentState.is_rejected = false
        currentState.is_complete = false
      } else if (
        data === 'is_accepted' &&
        createContentState.can_publish_or_deny
      ) {
        currentState.is_accepted = true
        currentState.is_rejected = false
        currentState.is_expanded = false
        currentState.is_complete = true
      } else if (
        data === 'is_rejected' &&
        createContentState.can_publish_or_deny
      ) {
        currentState.is_accepted = false
        currentState.is_rejected = true
        currentState.is_expanded = false
        currentState.is_complete = true
      }
    }

    handleCreateState(section, currentState)

    if (
      currentState.is_complete &&
      currentState.is_rejected &&
      createContentState.can_publish_or_deny
    ) {
      handleAutosave()

      return false
    }

    if (currentState.is_complete && !currentState.is_rejected) {
      if (
        (createContentState.can_publish_or_deny && currentState.is_accepted) ||
        (!createContentState.can_publish_or_deny && !currentState.is_accepted)
      ) {
        handleAutosave()
      }
    }
  }

  const handleCancelCreate = (message) => {
    history.push({
      pathname: '/app/content-management',
      state: { message: message },
    })
    setTimeout(() => {
      setCreateContentState({})
      setIsContentBeingEdited(false)
      setConfirmAutosaveModal(false)
      // localStorage.removeItem('editModeActive')
    })
  }

  const handleSaveDraft = async (actionName, actionValue) => {
    const isContentChanged = await changesMade(
      isContentBeingEdited,
      actionValue,
      actionName,
      createContentState,
      dirtyCreateState,
    )

    if (
      !isContentChanged &&
      actionName !== 'approval' &&
      actionName !== 'deny'
    ) {
      successToast({ message: 'Report has been saved' })
      return false
    }

    setSaving(true)

    // const createStateDeepClone = cloneDeep(createContentState)
    // const dirtyCreateStateDeepClone = cloneDeep(dirtyCreateState)
    // const diff = difference(createStateDeepClone, dirtyCreateStateDeepClone)
    // const isDistributionOrRelevanceChanged = !!(diff?.content_distribution || diff?.relevance)

    if (actionName === 'titleChange') createContentState.dev_title = actionValue
    const currentState = cloneDeep(createContentState)
    const data = saveReport(currentState)
    await saveDraftHandler(
      'reports',
      data,
      setSaving,
      setDenying,
      actionName,
      handleCancelCreate,
      setSendingForApproval,
      infoToast,
      successToast,
      errorToast,
    )
    // const finalState = {...createContentState, ...publicationCheckData}
    // setCreateContentState(finalState)
    setDirtyCreateState(cloneDeep(currentState))
  }

  const handleSendForApproval = () => handleSaveDraft('approval')
  const handleDeny = () => handleSaveDraft('deny')
  const handleAutosave = (action, value) => {
    if (isContentBeingEdited) {
      handleSaveDraft(action, value)
    } else {
      action && value.length > 0 && initialDraft(value)
    }
  }
  const isContentAlreadyEdited = JSON.parse(
    localStorage.getItem('isContentEdited'),
  )
  const handleTabClosing = () =>
    !isContentAlreadyEdited && localStorage.setItem('isContentEdited', null)

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing)
    return () => window.removeEventListener('unload', handleTabClosing)
  }, [])

  useEffect(() => {
    let intervalId
    if (!isContentAlreadyEdited && Number(id)) {
      localStorage.setItem('isContentEdited', id)
    } else if (
      isContentAlreadyEdited &&
      id &&
      Number(id) !== isContentBeingEdited[1]
    ) {
      intervalId = setTimeout(
        () => (window.location.href = '/app/workspace'),
        500,
      )
    }

    return () => {
      localStorage.setItem('isContentEdited', null)
      clearTimeout(intervalId)
    }
  }, [id])

  const handleUploadPdf = async (fileData) => {
    setIsPreviewingPdf(true)
    const formData = new FormData()

    formData.append('file', fileData)
    formData.append('report_id', createContentState.id)

    try {
      const response = await uploadReportPdf(token(), formData)

      if (response.error) {
        setToastMessage(['error', response.message])
        setIsPreviewingPdf(false)
        return false
      }

      const currentState = { ...createContentState }

      currentState.is_pdf_uploaded = true
      currentState.uploaded_pdf_filename = response.filename
      setCreateContentState(currentState)
      setToastMessage(['success', response.message])
      setIsPreviewingPdf(false)
    } catch (err) {}
  }

  const handleRemovePdf = async (id) => {
    setIsDeleting(true)
    try {
      const response = await deleteReportPdf(token(), id)

      if (response.error) {
        setToastMessage(['error', response.message])
        return false
      }

      const currentState = { ...createContentState }

      currentState.is_pdf_uploaded = false
      setCreateContentState(currentState)
      setToastMessage(['success', response.message])
      setIsDeleting(false)
      setDeletePdfOpen(false)
    } catch (err) {}
  }

  const handlePreviewPdf = async () => {
    setIsPreviewingPdf(true)

    try {
      const response = await previewReportPdf(token(), createContentState.id)

      setIsPreviewingPdf(false)

      if (response.error) {
        setToastMessage(['error', response.message])

        return false
      }

      const url = `${response}?access_token=${token()}`
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } catch (err) {}
  }

  const loadMitreData = async () => {
    try {
      const response = await getMitreData(token(), 'report', false)

      setMitreConfig(response)
    } catch (err) {}
  }

  return (
    <>
      {isLoading && <Spinner center size={50} border={1} />}

      {toastMessage && (
        <CreateToast
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}

      {!isLoading && (
        <div className='create-alert'>
          <div className='create-alert__header'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: 'calc(75% - 30px)',
                padding: '10px 20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => handleCancelCreate('')}
                    disabled={isSaving}
                    startIcon={<ArrowBackIcon />}
                    style={{
                      textTransform: 'capitalize',
                      backgroundColor: 'transparent',
                      color: '#fff',
                      padding: '6px 20px 6px 6px',
                      borderRadius: 0,
                      marginRight: 20,
                      borderRight: '1px solid #505050',
                    }}
                  >
                    Back to content
                  </Button>

                  <span
                    style={{ fontSize: 32, marginRight: 10 }}
                    className='icon-report'
                  />

                  <h2>Create Report</h2>
                </div>

                {createContentState.dev_type_id ? (
                  <div className='pos-top'>
                    <h5 style={{ marginRight: 10 }}>Select Framework </h5>

                    <Select
                      onChange={(event) => handleChangeType(event.target.value)}
                      displayEmpty
                      value={createContentState.dev_type_id || ''}
                      renderValue={
                        !createContentState.dev_type_id
                          ? () => (
                              <div style={{ color: '#aaa' }}>Select type</div>
                            )
                          : undefined
                      }
                      variant='outlined'
                    >
                      {configuration?.types?.map((type) => (
                        <MenuItem key={type.name} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                ) : (
                  <div className='pos-center'>
                    <h3>Select Type</h3>

                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        gap: 15,
                      }}
                    >
                      {Object.keys(configuration?.types)?.length > 0 &&
                        configuration.types.map((type) => (
                          <Button
                            onClick={() => handleChangeType(type.id)}
                            key={type.name}
                            value={type.id}
                          >
                            {type.name}
                          </Button>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {deletePdfOpen && (
              <ConfirmDelete
                confirmDelete={deletePdfOpen}
                setConfirmDelete={setDeletePdfOpen}
                handleDeletePiece={handleRemovePdf}
                isDeleting={isDeleting}
              />
            )}

            {isContentBeingEdited && is_pdf_upload_enabled && (
              <div
                style={{
                  display: 'flex',
                  width: 'calc(25% - 30px)',
                  padding: 10,
                }}
              >
                {createContentState.is_pdf_uploaded ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Tooltip
                      title={`PDF Available: ${createContentState?.uploaded_pdf_filename}`}
                      arrow
                      placement='top'
                    >
                      <Button
                        startIcon={<PictureAsPdfIcon />}
                        onClick={() => handlePreviewPdf()}
                        style={{
                          backgroundColor: '#006fff',
                          fontSize: 12,
                          color: '#fff',
                          borderRadius: 12,
                          textTransform: 'capitalize',
                          padding: '6px 16px',
                          minWidth: 160,
                        }}
                      >
                        {isPreviewingPdf ? (
                          <Spinner
                            centerHorizontally
                            color='#fff'
                            size={22.5}
                          />
                        ) : (
                          <span
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: 200,
                            }}
                          >
                            Download {createContentState?.uploaded_pdf_filename}
                          </span>
                        )}
                      </Button>
                    </Tooltip>

                    <Tooltip title='Remove current PDF' arrow placement='top'>
                      <IconButton
                        onClick={() => setDeletePdfOpen(createContentState.id)}
                      >
                        <Delete style={{ color: '#ff0033' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <UploadPDF
                    isPreviewingPdf={isPreviewingPdf}
                    handleUploadPdf={handleUploadPdf}
                  />
                )}
              </div>
            )}
          </div>

          {Object.keys(createContentState).length > 1 &&
            createContentState.dev_type_id && (
              <>
                <ContentSection
                  configuration={
                    configuration?.types?.find(
                      (item) => item.id === createContentState.dev_type_id,
                    )?.schema
                  }
                  handleCompleteSections={handleCompleteSections}
                  handleExpandedSections={handleExpandedSections}
                  handleSectionStatus={handleSectionStatus}
                  setToastMessage={setToastMessage}
                  handleAutosave={handleAutosave}
                  isSaving={isSaving}
                />

                {isPublicationCheckActive && (
                  <PublicationCheck
                    isOpen={isPublicationCheckActive}
                    setIsPublicationCheckActive={setIsPublicationCheckActive}
                    contentState={createContentState}
                    handleCancelCreate={handleCancelCreate}
                    type='reports'
                    setToastMessage={setToastMessage}
                  />
                )}

                {typeof isContentBeingEdited[1] === 'number' && (
                  <BottomBar
                    module='reports'
                    isSaving={isSaving}
                    handleSaveDraft={handleSaveDraft}
                    isPreviewOpen={isPreviewOpen}
                    setPreviewOpen={setPreviewOpen}
                    configuration={
                      configuration?.types?.find(
                        (item) => item.id === createContentState.dev_type_id,
                      )?.schema
                    }
                    isDenying={isDenying}
                    handleDeny={handleDeny}
                    isSendingForApproval={isSendingForApproval}
                    handleSendForApproval={handleSendForApproval}
                    setIsPublicationCheckActive={setIsPublicationCheckActive}
                    setToastMessage={setToastMessage}
                  />
                )}
              </>
            )}
        </div>
      )}
    </>
  )
}

Report.displayName = 'Report'

/**
 * The properties.
 * @type {Object}
 */

Report.defaultProps = {
  configuration: {
    types: {},
  },
}

Report.propTypes = {
  configuration: PropTypes.instanceOf(Object),
}

export default Report
