import React from 'react'
import { TextField, Autocomplete } from '@mui/material'

const fields = [
  { name: 'Domain', slug: 'domain' },
  { name: 'Access Key', slug: 'access_key' },
  // { name: 'Default Content Creator', slug: 'default_creator_id'}
]

const OrganisationMisp = ({ data, handleOrganisationState, options }) => {
  return (
    <div
      className='edit-organisation__section'
      style={{ width: '33.3%', marginBottom: 0 }}
    >
      <div className='edit-organisation__section-content misp'>
        <h4>MISP Platform Settings</h4>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {fields.map(({ name, slug }) => (
            <div key={slug}>
              <h5 style={{ marginTop: slug === 'domain' ? 0 : 10 }}>{name}</h5>

              <TextField
                variant='outlined'
                placeholder={`Enter ${name}`}
                fullWidth
                value={data[slug] || ''}
                onChange={(event) =>
                  handleOrganisationState(
                    'misp_instance',
                    event.target.value,
                    slug,
                  )
                }
                inputProps={{ style: { padding: '12px 14px', fontSize: 13 } }}
              />
            </div>
          ))}

          <h5 style={{ marginTop: 10 }}>Default content creator</h5>

          <Autocomplete
            isOptionEqualToValue={(option, value) => option.id === value}
            options={options}
            id='organisation-misp'
            value={data.default_creator_id || null}
            getOptionLabel={(option) =>
              option?.first_name
                ? `${option.first_name} ${option.last_name} (${option.username})`
                : ''
            }
            onChange={(event, newValue) => {
              if (!newValue) {
                handleOrganisationState(
                  'misp_instance',
                  null,
                  'default_creator_id',
                )
                return false
              }

              handleOrganisationState(
                'misp_instance',
                newValue.id,
                'default_creator_id',
              )
            }}
            renderInput={(params) => {
              const user = options.find(
                (user) => user.id === data.default_creator_id,
              )
              params.InputProps.startAdornment = user?.first_name ? (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: 13,
                    left: 14,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 'calc(100% - 70px)',
                  }}
                >
                  {user.first_name} {user.last_name} ({user.username})
                </span>
              ) : (
                ''
              )
              return (
                <TextField
                  {...params}
                  style={{ color: '#fff' }}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      fontSize: 13,
                      zIndex: user?.first_name ? 1 : 0,
                      backgroundColor: user?.first_name ? 'transparent' : null,
                      color: user?.first_name ? 'transparent' : null,
                    },
                  }}
                  placeholder='Select user'
                />
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default OrganisationMisp
