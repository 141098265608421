import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const me = (token) =>
  client.get(apiUrl + `/api/auth/me`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const profileType = (token, id) =>
  client.get(apiUrl + `/api/profiles/${id}/type`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const updateTerms = (token) =>
  client.put(
    apiUrl + `/api/account/terms-of-use`,
    { version: 2, is_terms_of_use_accepted: true },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const refreshToken = (token) =>
  client.post(apiUrl + '/api/auth/refresh-token', { refresh_token: token })
