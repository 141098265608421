import React from 'react'

const NotFoundPage = ({}) => {
  return (
    <div
      style={{
        width: 'calc(100% - 60px)',
        height: 'calc(100% - 60px)',
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h1 style={{ color: '#fff' }}>404 - PAGE NOT FOUND</h1>
    </div>
  )
}

export default NotFoundPage
