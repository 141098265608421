import React from 'react'
import { Button, Dialog } from '@material-ui/core'

const DescriptionInfo = ({ description, setDescriptionInfo }) => {
  return (
    <Dialog
      open={description !== undefined}
      fullWidth
      maxWidth='md'
      // onClose={() => setConfirmDeleteOpen(undefined)}
    >
      <div style={{ padding: '25px 25px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #bababa',
            paddingBottom: 20,
          }}
        >
          <h3>{description[0]}</h3>

          <Button
            style={{
              backgroundColor: '#4073ff',
              color: '#fff',
              textTransform: 'capitalize',
              fontWeight: 700,
              borderRadius: 12,
              padding: '6px 20px',
            }}
            onClick={() => setDescriptionInfo(undefined)}
          >
            Close
          </Button>
        </div>

        <div
          style={{ fontSize: '15px' }}
          dangerouslySetInnerHTML={{ __html: description[1] }}
        />

        <div
          style={{
            fontSize: 12,
            display: 'flex',
            justifyContent: 'center',
            marginTop: 30,
          }}
        >
          <span>
            © 2024 The MITRE Corporation. This work is reproduced and
            distributed with the permission of The MITRE Corporation.
          </span>
        </div>
      </div>
    </Dialog>
  )
}

export default DescriptionInfo
