export const initialData = {
  'threat-actor': {
    category: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    title: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    overview: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    dates: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'country-of-origin': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'target-geography': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    language: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'sector-relevance': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    operations: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    incidents: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    motivation: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'intended-effect': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'resources-and-proficiency': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'malware-and-tools': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    reconnaissance: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    weaponisation: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    preparation: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    delivery: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    exploitation: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    installation: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    'command-and-control': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    'actions-on-objectives': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'anatomy-of-attack': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'mitre-tags': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    ioc: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-profiles': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-alerts': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'capability-score': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'content-distribution': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'author-settings': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    tlp: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
  },
  incident: {
    assessment: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    'type-tags': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    title: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    overview: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    dates: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    targets: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'sector-relevance': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    'target-geography': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'mitre-tags': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    ioc: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-profiles': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-alerts': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'content-distribution': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'author-settings': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    tlp: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    lat_long: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: [
        {
          lat: '',
          long: '',
        },
      ],
    },
  },
  operation: {
    title: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    overview: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    dates: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    assessment: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
      comments: [],
    },
    ttps: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    incidents: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    targets: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'sector-relevance': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    'target-geography': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'mitre-tags': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    ioc: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-profiles': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-alerts': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'content-distribution': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'author-settings': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    tlp: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    lat_long: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: [
        {
          lat: '',
          long: '',
        },
      ],
    },
  },
  'malware-tools': {
    title: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      content: {},
    },
    overview: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      content: {},
      comments: [],
    },
    malware: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      content: {},
      comments: [],
    },
    dates: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    availability: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    targets: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'target-geography': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'sector-relevance': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'protective-mechanisms': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'persistence-techniques': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'network-behaviour': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'additional-technical-details': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'mitre-tags': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    ioc: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-profiles': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-alerts': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'content-distribution': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'author-settings': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    tlp: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
  },
}

export const sectorOptions = [
  { id: 6, name: 'Banking & capital markets' },
  { id: 36, name: 'Pension' },
  { id: 18, name: 'Financial services' },
  { id: 24, name: 'Insurance' },
  { id: 20, name: 'Government & public services' },
  { id: 13, name: 'Diplomatic services' },
  { id: 15, name: 'Energy, utilities & mining' },
  { id: 34, name: 'Telecommunications' },
  { id: 33, name: 'Technology' },
  { id: 1, name: 'Academic/Research Institutes' },
  { id: 2, name: 'Aerospace, defence & security' },
  { id: 3, name: 'Agriculture' },
  { id: 4, name: 'Asset & wealth management' },
  { id: 5, name: 'Automotive' },
  { id: 7, name: 'Business and Professional Services' },
  { id: 8, name: 'Capital projects & infrastructure' },
  { id: 9, name: 'Charity/Not-for-profit' },
  { id: 10, name: 'Chemicals' },
  { id: 11, name: 'Commercial Aviation' },
  { id: 12, name: 'Commodities' },
  { id: 14, name: 'Education' },
  { id: 16, name: 'Engineering & construction' },
  { id: 17, name: 'Entertainment & media' },
  { id: 19, name: 'Forest, paper & packaging' },
  { id: 21, name: 'Healthcare' },
  { id: 22, name: 'Hospitality & leisure' },
  { id: 23, name: 'Industrial manufacturing' },
  { id: 25, name: 'IT Industry' },
  { id: 26, name: 'Legal' },
  { id: 27, name: 'Metals' },
  { id: 28, name: 'Pharmaceuticals & life sciences' },
  { id: 29, name: 'Private equity' },
  { id: 30, name: 'Retail & consumer' },
  { id: 31, name: 'Semiconductors' },
  { id: 32, name: 'Sovereign investment funds' },
  { id: 35, name: 'Transportation & logistics' },
]
