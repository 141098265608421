import { useQuery } from '@tanstack/react-query'
import { getForumThreads } from '../../api/forum/threads'

const token = () => {
  return localStorage.getItem('access_token')
}

export const useQueryGetForumThreads = (
  forumId: number,
  categoryId: number,
  page: number,
) => {
  return useQuery({
    queryKey: ['threads', forumId, categoryId, page],
    queryFn: () => getForumThreads(token() || '', forumId, categoryId, page),
    keepPreviousData: true,
  })
}
