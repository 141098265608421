import React, { useEffect, useRef } from 'react'
import parse from 'html-react-parser'
import DOMPurify from 'dompurify'

const TextSection = ({ section: { headline, content } }) => {
  const sectionRef = useRef(null)

  useEffect(() => {
    sectionRef.current &&
      [...sectionRef.current.querySelectorAll('a')].forEach((link) =>
        link.addEventListener('click', (event) => {
          event.preventDefault()
          window.open(event.target.href, '_blank', 'noopener,noreferrer')
        }),
      )
  }, [])

  return (
    <div>
      <h2>{headline}</h2>

      <div ref={sectionRef}>{parse(DOMPurify.sanitize(content))}</div>
    </div>
  )
}

export default TextSection
