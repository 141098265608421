import React, { useState } from 'react'
import { Button, CircularProgress, Dialog, Divider } from '@material-ui/core'

const ConfirmDeleteIoc = ({
  confirmDeleteOpen,
  setConfirmDeleteOpen,
  handleDeleteIoc,
  handleDeleteMultipleIoc,
}) => {
  const [isLoading, setLoading] = useState(false)
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={confirmDeleteOpen !== undefined}
      onClose={() => setConfirmDeleteOpen(undefined)}
    >
      <div style={{ padding: 24 }}>
        <h3>Confirm Delete</h3>

        <Divider
          style={{
            margin: '10px 0',
            border: 'none',
            backgroundColor: '#00000080',
          }}
        />

        <span>This action cannot be undone.</span>
      </div>

      <div
        style={{
          padding: '0 24px 16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => setConfirmDeleteOpen(undefined)}>Cancel</Button>

        <Button
          style={{
            backgroundColor: '#FF0033',
            color: '#fff',
            textTransform: 'capitalize',
            fontWeight: 700,
            borderRadius: 12,
            padding: '6px 20px',
            minWidth: 85,
          }}
          onClick={() => {
            setLoading(true)
            confirmDeleteOpen === true
              ? handleDeleteMultipleIoc()
              : handleDeleteIoc()
          }}
        >
          {isLoading ? (
            <CircularProgress
              style={{ height: 24, width: 24, color: '#fff' }}
            />
          ) : (
            'Delete'
          )}
        </Button>
      </div>
    </Dialog>
  )
}

export default ConfirmDeleteIoc
