import { useEffect, useState } from 'react'
import './LeftMenu.scss'
import { useHistory, useLocation } from 'react-router-dom'
import { IconButton, Tooltip } from '@material-ui/core'
import { useUserContext } from '../../context/userContext'
import { useViewStateContext } from '../../context/viewStateContext'
import useNotification from '../../hooks/useNotification'
import { ForumIcon } from '../../assets/icon-forum.js'

const LeftMenu = ({ changeModule }) => {
  const {
    leftMenuVisible,
    user: {
      forum_groups,
      modules: { view_mode },
      user_info: { is_comments_moderator },
    },
  } = useUserContext()
  const { workspaces, addWorkspace, linkAnalysisOpen } = useViewStateContext()
  const [currentModule, setCurrentModule] = useState(undefined)
  const location = useLocation()
  const history = useHistory()
  const { infoToast } = useNotification()
  const isForumIconToDisplay =
    view_mode.find((module) => module.name === 'Forum')?.name === 'Forum'

  const handleNavigateTo = (route) => {
    if (localStorage.getItem('forumURL')) {
      history.push(`${localStorage.getItem('forumURL')}`)
      return
    }

    if (forum_groups.length === 1) {
      history.push({
        pathname: `/app/forum/${forum_groups[0].id}`,
        state: {
          forumName: forum_groups[0].name,
        },
      })
    } else {
      history.push(`/app/${route}`)
    }
  }

  useEffect(() => {
    let current = workspaces?.find((item) => item.active)
    setCurrentModule(current)
  }, [workspaces])

  const handleChangeModule = (name, slug, has_access) => {
    if (!has_access) {
      infoToast({
        message:
          'You do not have access to this module or content. Please contact our sales team for more information.',
        keepOpen: true,
      })
      return false
    }

    if (
      linkAnalysisOpen &&
      currentModule.state === 'analysis' &&
      slug !== 'analysis'
    ) {
      addWorkspace(slug, undefined, name)
    } else {
      changeModule(slug, name)
    }
  }

  return (
    <div
      className='left-menu'
      style={{ display: leftMenuVisible ? 'block' : 'none' }}
    >
      {view_mode
        .filter(
          (mode) =>
            mode.name !== 'Chat' && mode.name !== 'Forum' && mode.has_access,
        )
        .map(({ name, slug, has_access }) => (
          <Tooltip arrow title={name} placement='right' key={slug}>
            <IconButton
              onClick={() => handleChangeModule(name, slug, has_access)}
              centerRipple={false}
              className={
                location.pathname.startsWith('/app/workspace') &&
                currentModule?.state === slug
                  ? 'left-menu__icon active'
                  : 'left-menu__icon'
              }
            >
              <span className={`icon-${slug}`} />
            </IconButton>
          </Tooltip>
        ))}

      {isForumIconToDisplay && (
        <Tooltip arrow title='Forum' placement='right'>
          <IconButton
            className={
              location.pathname.startsWith('/app/forum')
                ? 'left-menu__icon active'
                : 'left-menu__icon'
            }
            centerRipple={false}
            // disableRipple
            // size='medium'
            style={{ color: 'white' }}
            onClick={() => handleNavigateTo('forum')}
          >
            <ForumIcon />
          </IconButton>
        </Tooltip>
      )}
      {is_comments_moderator && (
        <Tooltip arrow title='Moderation' placement='right'>
          <IconButton
            onClick={() => history.push('/app/moderation')}
            centerRipple={false}
            className={
              location.pathname === '/app/moderation'
                ? 'left-menu__icon active'
                : 'left-menu__icon'
            }
          >
            <span className='icon-admin' />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

export default LeftMenu
