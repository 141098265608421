import { Button, Dialog, Divider } from '@material-ui/core'
import { Dispatch, SetStateAction } from 'react'

interface DeleteCategoryModalProps {
  confirmDeleteOpen: string | number | false
  setConfirmDeleteOpen: Dispatch<SetStateAction<string | number | false>>
  handleDeleteComment: ({ id }: { id: string | number | null }) => void
  id: number | string
}

const DeleteCategoryModal = ({
  confirmDeleteOpen,
  setConfirmDeleteOpen,
  handleDeleteComment,
  id,
}: DeleteCategoryModalProps) => {
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={!!confirmDeleteOpen}
      onClose={() => setConfirmDeleteOpen(false)}
    >
      <div style={{ padding: 24 }}>
        <h3>Confirm Delete</h3>

        <Divider
          style={{
            margin: '10px 0',
            border: 'none',
            backgroundColor: '#00000080',
          }}
        />

        <span>This action cannot be undone.</span>
      </div>

      <div
        style={{
          padding: '0 24px 16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>

        <Button
          style={{
            backgroundColor: '#FF0033',
            color: '#fff',
            textTransform: 'capitalize',
            fontWeight: 700,
            borderRadius: 12,
            padding: '6px 20px',
          }}
          onClick={() => handleDeleteComment({ id })}
        >
          Delete
        </Button>
      </div>
    </Dialog>
  )
}

export default DeleteCategoryModal
