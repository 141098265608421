import React, { useState, Fragment, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import {
  publishContent,
  saveDraft,
} from '../../../../../shared/services/api/createContent/createAlert'
import { saveProfile, saveAlert, saveReport } from '../../helpers/saveDraft'
import Spinner from '../../../../../shared/Spinner'
import { useGlobalContext } from '../../../../../context/globalContext'
import { useCreateManagementContext } from '../../../../../context/createManagementState'
import { cloneDeep, isEqual } from 'lodash'

const PublicationCheck = ({
  isOpen,
  setIsPublicationCheckActive,
  handleCancelCreate,
  type,
  setToastMessage,
  profileType,
}) => {
  const profileSlug =
    profileType === 'malware-tools' ? profileType : profileType + 's'
  const { createContentState } = useCreateManagementContext()
  const { handleGlobalState } = useGlobalContext()
  const [isLoading, setLoading] = useState(true)
  const [publicationData, setPublicationData] = useState(undefined)
  const [isSaving, setSaving] = useState(false)
  const [updateValue, setUpdateValue] = useState('insignificant')
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const handleSaveDraft = async (shouldPublish) => {
    shouldPublish && setSaving(true)
    const currentCreateState = cloneDeep(createContentState)

    if (
      !isEqual(
        currentCreateState?.content_distribution,
        createContentState?.dev_content['content-distribution']?.content,
      )
    ) {
      currentCreateState.content_distribution =
        createContentState?.dev_content['content-distribution']?.content
    }

    let data = {}
    if (type === 'alerts') {
      data = saveAlert(currentCreateState, shouldPublish)
    } else if (type === 'profiles' || type === 'scenarios') {
      data = saveProfile(currentCreateState, shouldPublish)
    } else if (type === 'reports') {
      data = saveReport(currentCreateState, shouldPublish)
    }

    const currentUsers = { ...data?.content_distribution?.users }

    Object.entries(currentUsers).forEach((user) => {
      const id = user[0]
      const value = user[1]
      if (value.can_view === false) {
        delete currentUsers[id]
      }
    })

    data.content_distribution.users = currentUsers

    try {
      const response = await saveDraft(token(), type, data.id, data)

      if (response.error) {
        setToastMessage(['error', response.message])
        setTimeout(() => setToastMessage(null), 5000)
        setLoading(false)
        return false
      }

      setPublicationData(response)

      if (shouldPublish) {
        publish()
      } else {
        setLoading(false)
      }
    } catch (err) {}
  }

  useEffect(() => {
    return () => {}
  }, [])

  const publish = async () => {
    setSaving(true)
    try {
      const data =
        createContentState.published === 1 && updateValue === 'insignificant'
          ? { do_not_update_published_at: true }
          : {}
      const response = await publishContent(
        token(),
        type,
        createContentState.id,
        data,
      )
      if (response.error) {
        let errors = []
        setIsPublicationCheckActive(false)
        Object.values(response.errors) &&
          Object.values(response.errors).forEach((er) => {
            errors = [...errors, er]
          })
        setToastMessage(['error', errors.join(', ')])
        return false
      }

      handleGlobalState(
        profileType ? profileSlug : type,
        createContentState.id,
        false,
        true,
      )
      setIsPublicationCheckActive(false)
      handleCancelCreate(response.message)
      setSaving(false)
    } catch (err) {}
  }

  useEffect(() => {
    handleSaveDraft()
  }, [])

  const handlePublishContent = () => {
    const shouldPublish = true
    handleSaveDraft(shouldPublish)
  }

  const styles = {
    left: {
      width: '35%',
      overflow: 'auto',
      marginRight: 15,
    },
    right: {
      width: '65%',
      display: 'flex',
      marginLeft: 15,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    tab: {
      textTransform: 'capitalize',
      minHeight: 16,
      fontSize: 12,
      maxWidth: 'unset',
      padding: '3px 6px',
    },
    headline: {
      marginTop: 5,
      textTransform: 'capitalize',
      pointerEvents: 'none',
      fontSize: 18,
      padding: '3px 6px',
      minHeight: 16,
      opacity: 1,
    },
    saveButton: {
      backgroundColor: isLoading ? '#797979' : '#3aaa35',
      color: '#fff',
      textTransform: 'capitalize',
      fontWeight: 700,
      borderRadius: 12,
      minWidth: 92,
      padding: '6px 20px',
    },
    distribution: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      gap: 12,
      justifyContent: 'center',
      maxHeight: 300,
      minHeight: 100,
      overflowX: 'auto',
    },
    distributionBox: {
      flexBasis: 'calc(50% - 14px)',
    },
  }

  const hideAuthorsData =
    createContentState.dev_hide_all_authors_data ||
    createContentState.dev_content['author-settings'].content
      .dev_hide_all_authors_data
  const showAuthorsData =
    createContentState.dev_show_authors_data ||
    createContentState.dev_content['author-settings'].content
      .dev_show_authors_data
  const groupNames =
    createContentState.dev_content['content-distribution'].content.groupNames

  return (
    <Dialog fullWidth maxWidth='md' open={isOpen}>
      <DialogTitle id='alert-dialog-title'>
        Publication security check
      </DialogTitle>

      <Divider style={{ margin: '0 24px' }} />

      {!publicationData || isLoading || isSaving ? (
        <Spinner padding={20} centerHorizontally size={30} color='006fff' />
      ) : (
        <DialogContent className='tag-list-dialog' style={styles.wrapper}>
          <div>
            <h3 style={{ marginBottom: 10 }}>Author Settings</h3>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <span>Author displayed as:</span>

              <span
                style={{
                  padding: '6px 10px',
                  backgroundColor: '#506185',
                  fontWeight: 500,
                  borderRadius: 4,
                  width: 'fit-content',
                  color: '#fff',
                  marginLeft: 20,
                }}
              >
                {!hideAuthorsData &&
                  !showAuthorsData &&
                  `${createContentState.author_organisation}`}

                {!hideAuthorsData &&
                  showAuthorsData &&
                  `${createContentState.author_name}, ${createContentState.author_organisation}`}

                {hideAuthorsData && !showAuthorsData && 'Withheld'}
              </span>
            </div>
          </div>

          <Divider style={{ margin: '20px 0' }} />

          <h3 style={{ marginBottom: 20 }}>Distribution Settings</h3>

          <div style={styles.distribution}>
            <div style={styles.distributionBox}>
              <h4>Selected Group</h4>

              <span>{(groupNames && Object.values(groupNames)) || ''}</span>
            </div>

            <div style={styles.distributionBox}>
              <h4>Who can view</h4>

              <div
                style={{ fontSize: '14px', color: '#283143' }}
                dangerouslySetInnerHTML={{
                  __html: publicationData.who_can_view,
                }}
              />
            </div>

            <div style={styles.distributionBox}>
              <h4>Who can edit</h4>

              <div
                style={{ fontSize: '14px', color: '#283143' }}
                dangerouslySetInnerHTML={{
                  __html: publicationData.who_can_edit,
                }}
              />
            </div>

            <div style={styles.distributionBox}>
              <h4>Who can publish</h4>

              <div
                style={{ fontSize: '14px', color: '#283143' }}
                dangerouslySetInnerHTML={{
                  __html: publicationData.who_can_publish,
                }}
              />
            </div>
          </div>

          <Divider style={{ margin: '20px 0' }} />

          <div>
            <h3 style={{ marginBottom: 20 }}>TLP Settings</h3>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 10,
                borderRadius: 12,
                backgroundColor: publicationData?.tlpPreview?.colour_code,
                color:
                  publicationData?.tlpPreview?.colour === 'white'
                    ? '#000'
                    : '#fff',
              }}
            >
              <h4>
                {`
                  ${publicationData?.tlpPreview?.label} 
                  ${publicationData?.tlpPreview?.classification_title || ''}
                  ${publicationData?.tlpPreview?.caveat || ''}
                `}
              </h4>

              <span style={{ fontSize: 13 }}>
                {`${publicationData?.tlpPreview?.description} - ${
                  publicationData?.tlpPreview?.classification_description || ''
                }`}
              </span>
            </div>
          </div>

          {createContentState.published === 1 && (
            <Fragment>
              <Divider style={{ margin: '20px 0' }} />

              <div>
                <h3>Update settings</h3>

                <RadioGroup
                  value={updateValue}
                  onChange={(event) => setUpdateValue(event.target.value)}
                  row
                >
                  <FormControlLabel
                    value='insignificant'
                    control={<Radio />}
                    label='Insignificant update'
                  />

                  <FormControlLabel
                    value='significant'
                    control={<Radio />}
                    label='Significant update'
                  />
                </RadioGroup>
              </div>
            </Fragment>
          )}
        </DialogContent>
      )}

      <Divider style={{ margin: '10px 20px' }} />

      <DialogActions
        style={{ padding: '0 24px 16px 24px', justifyContent: 'space-between' }}
      >
        <Button
          style={{ textTransform: 'capitalize' }}
          onClick={() => setIsPublicationCheckActive(false)}
        >
          Edit
        </Button>

        <Button
          style={styles.saveButton}
          disabled={isLoading}
          onClick={() => handlePublishContent()}
        >
          {isSaving ? (
            <Spinner centerHorizontally size={22.5} color='#fff' />
          ) : (
            'Publish'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PublicationCheck
