/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Checkbox, FormControlLabel, Switch } from '@material-ui/core'

const OrganisationUsers = ({
  organisations,
  organisationOptions,
  shownOrganisations,
  handleShownOrganisations,
  handleContentDistributionState,
  users,
  relevance,
}) => {
  const isShown = (id) => {
    return shownOrganisations.includes(id)
  }
  const checkboxes = [
    { name: 'View', slug: 'can_view' },
    { name: 'Edit', slug: 'can_edit' },
    { name: 'Publish', slug: 'can_publish' },
  ]

  const possibleUsers = (orgId) => {
    let newUsers = []

    organisationOptions.forEach((org) => {
      if (org.id === orgId) {
        org?.users?.forEach((user) => {
          if (users[user.id] && users[user.id].can_publish) {
            return false
          }

          user.name = user.first_name + ' ' + user.last_name
          if (!users[user.id]) {
            user.can_edit = false
            user.can_view = false
            user.can_publish = false
          } else {
            if (!users[user.id].can_view) {
              user.can_view = false
            } else {
              user.can_view = users[user.id].can_view
            }

            if (!users[user.id].can_edit) {
              user.can_edit = false
            } else {
              user.can_edit = users[user.id].can_edit
            }

            if (!users[user.id].can_publish) {
              user.can_publish = false
            } else {
              user.can_publish = users[user.id].can_publish
            }
          }

          newUsers = [...newUsers, user]
        })
      }
    })

    return newUsers
  }

  const availableOrganisations = () => {
    let orgs = []

    organisationOptions.forEach((org) => {
      Object.entries(organisations).forEach((orgId) => {
        const id = orgId[0] * 1
        const orgData = orgId[1]
        if (org.id === id) {
          org.include_new_users = orgData.include_new_users
          orgs = [...orgs, org]
        }
      })
    })

    return orgs
  }

  const handlePermissions = (user, option, value) => {
    if (option === 'can_publish' && value === true) {
      handleContentDistributionState('publishers', user.id, true)
    } else {
      const currentUsers = { ...users } || {}
      currentUsers[user.id] = { ...currentUsers[user.id], [option]: value }
      if (currentUsers[user.id].can_view === undefined) {
        currentUsers[user.id].can_view = true
      } else if (currentUsers[user.id].can_view === false) {
        currentUsers[user.id].can_edit = false
      }
      handleContentDistributionState('users', currentUsers)
    }
  }

  const handleEnabledPermissions = (user, option) => {
    const isViewChecked = users[user.id] ? users[user.id].can_view : false
    const isEditChecked = users[user.id] ? users[user.id].can_edit : false

    if (
      option === 'Publish' &&
      user.role_possibilities.publish_possibility &&
      isEditChecked
    ) {
      return false
    } else if (option === 'View' && user.role_possibilities.view_possibility) {
      return false
    } else
      return !(
        option === 'Edit' &&
        user.role_possibilities.edit_possibility &&
        isViewChecked
      )
  }

  const areUsersSelected = (org, slug) => {
    const currentUsers = possibleUsers(org.id)

    if (currentUsers && currentUsers.length > 0) {
      if (slug === 'can_view') {
        let areAllActive = true
        currentUsers.some((u) => {
          if (!users[u.id]) {
            areAllActive = false
            return false
          }

          if (
            users[u.id].can_view === false &&
            u.role_possibilities.view_possibility
          ) {
            areAllActive = false
          }
        })

        return areAllActive
      }

      if (slug === 'can_edit') {
        if (canBeClicked(org, slug)) {
          return false
        }

        let areAllActive = true
        const usersWithEditPossibility = currentUsers.filter(
          (i) => i.role_possibilities.edit_possibility,
        )
        usersWithEditPossibility.some((u) => {
          if (!u.can_edit) {
            areAllActive = false
          }
        })

        if (usersWithEditPossibility.length === 0) {
          areAllActive = false
        }

        return areAllActive
      }

      if (slug === 'can_publish') {
        // return Object.values(usersPerOrg) && Object.values(usersPerOrg).every(u => u.can_publish === true)
        return false
      }
    }
  }

  const canBeClicked = (org, slug) => {
    if (slug === 'can_edit') {
      return false
    }

    if (slug === 'can_view') {
      return false
      // return !possibleUsers(org.users).find(usr => usr.role_possibilities.view_possibility)
    }

    if (slug === 'can_publish') {
      return false
      // return !possibleUsers(org.users).find(usr => usr.role_possibilities.publish_possibility)
    }
  }

  const handleSetAll = (org, slug, value) => {
    const currentUsers = { ...users } || {}

    possibleUsers(org.id).forEach((usr) => {
      if (slug === 'can_view' && usr.role_possibilities.view_possibility) {
        if (currentUsers[usr.id] && currentUsers[usr.id].can_publish) {
          return false
        }

        if (value === true) {
          //when selecting all
          if (!currentUsers[usr.id]) {
            currentUsers[usr.id] = {}
          }

          currentUsers[usr.id].can_view = true
        } else {
          //when de-selecting
          if (!currentUsers[usr.id].can_publish) {
            currentUsers[usr.id].can_view = false
            currentUsers[usr.id].can_edit = false
            currentUsers[usr.id].can_publish = false
          }
        }
      }

      if (slug === 'can_edit' && usr.role_possibilities.edit_possibility) {
        if (!currentUsers[usr.id]) {
          return false
        }

        if (
          !currentUsers[usr.id].can_view ||
          currentUsers[usr.id].can_publish
        ) {
          return false
        }

        if (value === true) {
          if (!currentUsers[usr.id]) {
            currentUsers[usr.id] = {}
          }

          currentUsers[usr.id].can_edit = true
        } else {
          currentUsers[usr.id].can_edit = false
          currentUsers[usr.id].can_publish = false
        }
      }
    })

    if (slug === 'can_publish') {
      org?.users.forEach((user) => {
        if (
          user?.role_possibilities?.publish_possibility &&
          currentUsers[user.id]?.can_view === true &&
          currentUsers[user.id]?.can_edit === true
        ) {
          currentUsers[user.id].can_publish = true
        }
      })
    }

    handleContentDistributionState('users', currentUsers)
  }

  return (
    <>
      {availableOrganisations().map((organisation) => {
        return (
          <div
            key={organisation.id}
            className='associated'
            style={{ padding: '8px 0' }}
          >
            <div className='heading-organisation__users'>
              <span
                style={{ width: '100%', padding: '11px 0' }}
                onClick={() => handleShownOrganisations(organisation.id)}
              >
                {organisation.name}
              </span>

              <div style={{ minWidth: 230 }}>
                <FormControlLabel
                  checked={
                    relevance ? relevance.includes(organisation.id) : false
                  }
                  onChange={() =>
                    handleContentDistributionState('relevance', organisation.id)
                  }
                  control={<Switch color='primary' />}
                  label={
                    <span style={{ fontSize: 13 }}>Organisation specific</span>
                  }
                  labelPlacement='start'
                />

                <span
                  style={{
                    fontSize: 18,
                    transform: isShown(organisation.id) ? 'rotate(180deg)' : '',
                    marginLeft: 15,
                  }}
                  onClick={() => handleShownOrganisations(organisation.id)}
                  className='icon-down'
                />
              </div>
            </div>

            {isShown(organisation.id) && (
              <div className='user-wrapper'>
                {organisation?.users?.length === 0 && <div>No users found</div>}

                {organisation?.users?.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: 8,
                    }}
                  >
                    <FormControlLabel
                      onChange={(event) =>
                        handleContentDistributionState(
                          'organisation-include',
                          event.target.checked,
                          organisation.id,
                        )
                      }
                      // value={org.include_new_users}
                      control={
                        <Checkbox
                          checked={
                            organisation.include_new_users
                              ? organisation.include_new_users
                              : false
                          }
                          color='default'
                          icon={
                            <span
                              style={{ color: 'rgb(0 0 0 / 54%)' }}
                              className='icon-checkbox-empty'
                            />
                          }
                          checkedIcon={
                            <span
                              style={{ color: '#006bf5' }}
                              className='icon-checkbox-checked'
                            />
                          }
                        />
                      }
                      label='Include future users'
                      labelPlacement='end'
                    />

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span style={{ fontSize: 12 }}>Select/Deselect all</span>

                      {checkboxes.map((option) => (
                        <FormControlLabel
                          style={{ margin: '0 0 0 10px' }}
                          disabled={canBeClicked(organisation, option.slug)}
                          key={option.slug}
                          onChange={(event, value) =>
                            handleSetAll(organisation, option.slug, value)
                          }
                          label={
                            <span style={{ fontSize: 12 }}>{option.name}</span>
                          }
                          control={
                            <Checkbox
                              disableFocusRipple
                              disableRipple
                              checked={areUsersSelected(
                                organisation,
                                option.slug,
                              )}
                              checkedIcon={
                                <span
                                  style={{ color: '#006bf5' }}
                                  className='icon-checkbox-checked'
                                />
                              }
                              icon={<span className='icon-checkbox-empty' />}
                            />
                          }
                        />
                      ))}
                    </div>
                  </div>
                )}

                {possibleUsers(organisation.id).map((user) => {
                  return (
                    <div key={user.id}>
                      <div
                        style={{
                          padding: 8,
                          // borderTop: user === possibleUsers()[0] ? '1px solid #dddddd' : '',
                          borderBottom: '1px solid #dddddd',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>{`${user.first_name} ${user.last_name}`}</span>

                        <div>
                          {checkboxes.map((option) => (
                            <FormControlLabel
                              style={{ margin: '0 0 0 10px' }}
                              disabled={handleEnabledPermissions(
                                user,
                                option.name,
                              )}
                              key={option.slug}
                              onChange={(event, value) =>
                                handlePermissions(user, option.slug, value)
                              }
                              label={
                                <span style={{ fontSize: 12 }}>
                                  {option.name}
                                </span>
                              }
                              control={
                                <Checkbox
                                  disableFocusRipple
                                  disableRipple
                                  checked={
                                    (users[user.id] &&
                                      users[user.id][option.slug]) ||
                                    false
                                  }
                                  checkedIcon={
                                    <span
                                      style={{ color: '#006bf5' }}
                                      className='icon-checkbox-checked'
                                    />
                                  }
                                  icon={
                                    <span className='icon-checkbox-empty' />
                                  }
                                />
                              }
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

export default OrganisationUsers
