/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider } from '@material-ui/core'
import Froala from 'react-froala-wysiwyg'
import { configForum } from '../../../shared/FroalaEditorComponent/options'
import { Reply } from '@mui/icons-material'
import { useState } from 'react'
import {
  addNewThreadPost,
  editThreadPost,
} from '../../../shared/services/api/forum/singleThread'
import { useParams } from 'react-router-dom'
import useNotification from '../../../hooks/useNotification'
import parse from 'html-react-parser'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import he from 'he'

interface Props {
  setOpenReply: any
  repliedPost: string | any
  openReply: number | null | boolean
  page: number
  setPage: any
  postsNumber: number
  shouldGoLastPage: boolean
  lastPage: number
  isEdit: number | null
  editedContent: any | null
  setEditId: any
  fetchPreviewData: any
}

const token = () => {
  return localStorage.getItem('access_token') || ''
}

const NewPost = ({
  setOpenReply,
  repliedPost,
  openReply,
  page,
  setPage,
  postsNumber,
  shouldGoLastPage,
  lastPage,
  isEdit,
  editedContent,
  setEditId,
  fetchPreviewData,
}: Props) => {
  const [content, setContent] = useState<any>(isEdit ? editedContent : '')
  const { forumId, categoryId, threadId }: any = useParams()
  const { successToast, errorToast } = useNotification()
  const currentInstance = process.env.REACT_APP_CURRENT_INSTANCE

  const handlePreviewData = (previewData: any) => {
    const anotherInstanceLinks: { [key: string]: any } = {
      alert: [],
      profile: [],
      scenario: [],
      report: [],
    }

    previewData &&
      Object.entries(previewData)?.forEach((i: any) => {
        const module = i[0]
        const contents = i[1]
        Object.values(contents)?.map((content: any) => {
          const { eu_id, uk_id } = content
          const differentInstanceId = currentInstance === 'uk' ? eu_id : uk_id
          anotherInstanceLinks[module] = [
            ...anotherInstanceLinks[module],
            differentInstanceId?.toString(),
          ]
        })
      })

    return anotherInstanceLinks
  }

  const queryClient = useQueryClient()
  const { mutate: addPostMutation, isLoading: addPostMutationIsLoading } =
    useMutation({
      mutationFn: async () => {
        let msgParsed: any[] = []
        let isLink = false
        const internalLinks: { [key: string]: any } = {
          alert: [],
          profile: [],
          report: [],
          scenario: [],
        }
        const isValidURL = (url: string) => {
          const regex =
            /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
          return regex?.test(url)
        }
        content?.split(' ').forEach((msg: any) => {
          if (isValidURL(msg)) {
            const div = document.createElement('div')
            div.innerHTML = msg
            const regex = div.textContent || div.innerText || ''
            const query = new URL(he.unescape(regex))
            const id = query?.searchParams?.get('id')
            const type = query?.searchParams?.get('type')

            msgParsed = [
              ...msgParsed,
              `<span class='content-link'>${msg}</span>`,
            ]

            if (id && type) {
              isLink = true
              const finalModule =
                type === 'alert' || type === 'scenario' || type === 'report'
                  ? type
                  : 'profile'
              const newArray = internalLinks[finalModule]?.includes(id)
                ? internalLinks[finalModule]
                : [...internalLinks[finalModule], id]
              internalLinks[finalModule] = newArray
            }
          } else {
            msgParsed = [...msgParsed, msg]
          }
        })
        const data: any = {
          forum_id: forumId,
          category_id: categoryId,
          thread_id: threadId,
          content: isLink ? msgParsed.join(' ') : content,
          uk_links: {},
          eu_links: {},
        }

        if (isLink) {
          const previewData = await fetchPreviewData(internalLinks)
          const instanceData = handlePreviewData(previewData)
          data.uk_links =
            currentInstance === 'uk' ? internalLinks : instanceData
          data.eu_links =
            currentInstance === 'eu' ? internalLinks : instanceData
        }

        if (repliedPost) {
          data.quoted_post_id = openReply
        }
        let response: any
        if (isEdit) {
          response = await editThreadPost(
            token(),
            forumId,
            categoryId,
            threadId,
            isEdit,
            data,
          )
        } else {
          response = await addNewThreadPost(
            token(),
            forumId,
            categoryId,
            threadId,
            data,
          )
        }

        if (response?.error) {
          errorToast({ message: response?.message })
        } else {
          if (shouldGoLastPage) {
            setPage(
              Number.isInteger(postsNumber / 10) ? lastPage + 1 : lastPage,
            ) //if divided by 10 and not decimal, should set page + 1
          } else {
            const data: any = queryClient.getQueryData([
              'thread',
              forumId,
              categoryId,
              threadId,
              page,
            ])
            data.data.posts.items = [
              ...data.data.posts.items,
              response?.data?.post,
            ]
            queryClient.setQueryData(
              ['thread', forumId, categoryId, threadId, page],
              data,
            )
          }
          if (isEdit) {
            const data: any = queryClient.getQueryData([
              'thread',
              forumId,
              categoryId,
              threadId,
              page,
            ])
            data.data.posts.items.find(
              (post: any) => post.id === isEdit,
            ).content = response?.data?.post?.content
            queryClient.setQueryData(
              ['thread', forumId, categoryId, threadId, page],
              data,
            )
          }
          successToast({ message: response.message })
          setOpenReply(null)
          setEditId(null)
          setContent('')
        }
      },
    })

  return (
    <div className='forum-thread-new' style={{ position: 'sticky', bottom: 0 }}>
      {repliedPost ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <Reply />

          <div className='forum-thread-new-reply'>
            <span>{parse(repliedPost)}</span>
          </div>
        </div>
      ) : null}

      <Divider
        style={{
          margin: '5px 0',
          background: '#1c222f',
          width: '100%',
          height: 2,
        }}
      />
      <div className='forum-thread-new__wrapper'>
        <div>
          <Froala
            model={content}
            onModelChange={(text: any) => setContent(text)}
            tag='textarea'
            config={{ ...configForum, placeholderText: 'Enter post contents' }}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              setEditId(null)
              setOpenReply(null)
            }}
            disabled={addPostMutationIsLoading}
            style={{
              background: '#1a222e',
              textTransform: 'capitalize',
              color: '#fff',
              borderRadius: 10,
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Close
          </Button>

          <Button
            style={{
              background: '#0070ff',
              textTransform: 'capitalize',
              color: '#fff',
              borderRadius: 10,
              fontSize: 12,
              fontWeight: 600,
            }}
            startIcon={<Reply />}
            onClick={() => addPostMutation()}
            disabled={addPostMutationIsLoading}
          >
            {isEdit ? 'Save' : 'Reply'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NewPost
