import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const changePassword = (data, token) =>
  client.put(apiUrl + '/api/account/change-password', data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
