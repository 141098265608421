import { Checkbox, FormControlLabel } from '@material-ui/core'
import { IconButton, Tooltip } from '@mui/material'
import { useQueryCacheGetModules } from '../../../../shared/services/queries/admin/useQueryGetModules'

const roles = { 3: 'Publisher', 2: 'Editor', 1: 'Viewer' }

const SingleGroupUser = ({
  user,
  users,
  orgId,
  handleEditData,
  setPrivilegesModal,
  organisationOptions,
  orgModules,
  isGroupChatEnabled,
  isGroupForumEnabled,
}) => {
  const modules = useQueryCacheGetModules()
  let modulesToDisplay = modules?.list.filter((module) => module.id !== 6)

  if (!isGroupChatEnabled) {
    modulesToDisplay = [...modulesToDisplay].filter(
      (module) => module.id !== 12,
    )
  }

  if (!isGroupForumEnabled) {
    modulesToDisplay = [...modulesToDisplay].filter(
      (module) => module.id !== 13,
    )
  }

  return (
    <div className='user-item'>
      <div className='user-item__name'>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                users[orgId]?.find((u) => u.id === user.id) !== undefined ||
                false
              }
              onChange={() => {
                const userAccess = {
                  ...user.origin_organisation_privileges.module_access,
                }
                const newAccess = {}
                Object.entries(userAccess).forEach((module) => {
                  const moduleId = +module[0]
                  newAccess[moduleId] = { id: moduleId, role_id: 1 }
                })
                delete newAccess[6] //link analysis should not be added by default
                handleEditData(
                  'users_in_organisations',
                  {
                    id: user.id,
                    module_access: newAccess,
                  },
                  orgId,
                )

                const totalUsers =
                  organisationOptions.find((org) => org.id === orgId).users || 0

                handleEditData(
                  'handleIncludeNewUsers',
                  totalUsers.length === users[orgId]?.length,
                  orgId,
                )
              }}
              style={{ padding: 3, backgroundColor: 'transparent' }}
              color='primary'
              icon={
                <span
                  style={{ fontSize: 30 }}
                  className='icon-checkbox-empty'
                />
              }
              checkedIcon={
                <span
                  className='icon-checkbox-checked'
                  style={{ fontSize: 30, color: '#006fff' }}
                />
              }
            />
          }
          label={
            <span style={{ fontSize: 12 }}>
              <b>{`${user.first_name} ${user.last_name}`}</b>
            </span>
          }
        />

        {users[orgId]?.find((u) => u.id === user.id) && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 25,
              width: 'calc(75% + 15px)',
            }}
          >
            {modulesToDisplay
              ?.filter((module) => module.name !== 'Analysis')
              .map((module) => {
                const roleId = users[orgId]?.find((u) => u.id === user.id)
                  ?.module_access?.[module.id]?.role_id

                const userData = users[orgId]?.find((u) => u.id === user.id)
                const isChatEnabled = users[orgId]?.find(
                  (u) => u.id === user.id,
                )?.module_access[12]
                const isForumEnabled = users[orgId]?.find(
                  (u) => u.id === user.id,
                )?.module_access[13]
                const chatAccess = isChatEnabled
                  ? undefined
                  : {
                      id: 12,
                      role_id: 1,
                      is_extra_module: true,
                    }

                const forumAccess = isForumEnabled
                  ? undefined
                  : {
                      id: 13,
                      role_id: 1,
                      is_extra_module: true,
                    }
                return (
                  <div
                    key={module.id}
                    style={{
                      textAlign: 'center',
                      fontSize: 12,
                      fontWeight: 500,
                      width: '25%',
                      minWidth: 70,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <span
                      style={{
                        borderRadius: 12,
                        padding: '10px 25px',
                        backgroundColor:
                          roleId === 3 &&
                          module.name !== 'Chat' &&
                          module.name !== 'Forum'
                            ? 'rgb(255 0 51 / 30%)'
                            : roleId === 2 &&
                              module.name !== 'Chat' &&
                              module.name !== 'Forum'
                            ? 'rgb(255 191 0 / 30%)'
                            : roleId === 1 &&
                              module.name !== 'Chat' &&
                              module.name !== 'Forum' &&
                              'rgb(58 170 53 / 30%)',
                      }}
                    >
                      {(roles[roleId] &&
                      module.name !== 'Chat' &&
                      module.name !== 'Forum'
                        ? roles[roleId]
                        : null) ||
                        (module.name === 'Chat' &&
                          !userData.is_chat_enabled &&
                          'No personal access') ||
                        (module.name === 'Forum' &&
                          !userData.is_forum_enabled &&
                          'No personal access') ||
                        (module.name === 'Chat' && orgModules['12']?.id && (
                          <Checkbox
                            color='primary'
                            style={{
                              backgroundColor: 'transparent',
                              fontSize: 30,
                              color: '#006fff',
                            }}
                            onChange={() => {
                              handleEditData(
                                'users_in_organisations_chat',
                                {
                                  ...userData,
                                  module_access: {
                                    ...userData.module_access,
                                    12: chatAccess,
                                  },
                                },
                                orgId,
                              )
                            }}
                            checked={isChatEnabled ?? false}
                          />
                        )) ||
                        (module.name === 'Forum' && orgModules['13']?.id && (
                          <Checkbox
                            color='primary'
                            style={{
                              backgroundColor: 'transparent',
                              fontSize: 30,
                              color: '#006fff',
                            }}
                            onChange={() => {
                              handleEditData(
                                'users_in_organisations_chat',
                                {
                                  ...userData,
                                  module_access: {
                                    ...userData.module_access,
                                    13: forumAccess,
                                  },
                                },
                                orgId,
                              )
                            }}
                            checked={isForumEnabled ?? false}
                          />
                        )) ||
                        (module.name === 'Chat' &&
                          isGroupChatEnabled === false &&
                          'Group no access') ||
                        (module.name === 'Chat' &&
                          !orgModules['12']?.id &&
                          'Org no access') ||
                        (module.name === 'Forum' &&
                          isGroupForumEnabled === false &&
                          'Group no access') ||
                        (module.name === 'Forum' &&
                          !orgModules['13']?.id &&
                          'Org no access') ||
                        '-'}
                    </span>
                  </div>
                )
              })}

            <Tooltip placement='top' arrow title='Edit user privileges'>
              <IconButton
                style={{ padding: 4 }}
                onClick={() => setPrivilegesModal(['user', orgId, user.id])}
              >
                <span
                  style={{ fontSize: 28, color: '#006fff' }}
                  className='icon-edit'
                />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleGroupUser
