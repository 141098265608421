/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import debounce from 'lodash/debounce'
import { useCreateManagementContext } from '../../../../../context/createManagementState'
import pullAt from 'lodash/pullAt'
import { Add, Check, Close } from '@mui/icons-material'

const LatLong = ({}) => {
  const {
    createContentState: {
      dev_content: { lat_long },
    },
    handleCreateState,
  } = useCreateManagementContext() || []
  const [isLoading, setIsLoading] = useState(false)
  const [fields, setFields] = useState([])

  const handleChange = debounce((event, index) => {
    const slug = event.target.name
    const currentState = { ...lat_long } || {}

    if (!currentState.content[index]) {
      currentState.content[index] = {
        lat: '',
        long: '',
      }
    }
    currentState.content[index][slug] = event.target.value
    handleCreateState('lat_long', currentState)
  }, 250)

  useEffect(() => {
    !lat_long?.content?.length
      ? setFields([
          {
            lat: '',
            long: '',
          },
        ])
      : lat_long?.content && setFields(lat_long?.content)
  }, [lat_long.content])

  const allFieldsFilled = () => {
    return fields?.every(
      (item) => item?.lat?.length > 0 && item?.long?.length > 0,
    )
  }

  const checkLat = (lat) => {
    const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/

    return regexLat?.test(lat) && lat?.length > 6
  }

  const checkLong = (long) => {
    const regexLon =
      /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/

    return regexLon?.test(long) && long?.length > 6
  }

  return (
    <div className='content-section__content'>
      <Divider />

      {isLoading ? (
        <span>Loading</span>
      ) : (
        <div className='lat-long'>
          {fields?.map((field, index) => (
            <div style={{ position: 'relative' }} key={index}>
              {index !== 0 ? (
                <IconButton
                  onClick={() => {
                    setFields((state) => {
                      pullAt(state, index)
                      handleCreateState('lat_long', {
                        ...lat_long,
                        content: [...state],
                      })
                      return state
                    })
                    setIsLoading(true)
                    setTimeout(() => {
                      setIsLoading(false)
                    }, 1)
                  }}
                  style={{
                    padding: 4,
                    position: 'absolute',
                    right: 0,
                    color: '#fff',
                    top: '-7px',
                  }}
                >
                  <span className='icon-close' />
                </IconButton>
              ) : null}
              {field &&
                Object.keys(field).map((fd) => (
                  <Fragment key={fd}>
                    <h5>
                      {fd === 'lat' ? 'Latitude' : 'Longitude'} #{index + 1}
                    </h5>

                    <TextField
                      key={fd}
                      variant='outlined'
                      placeholder={`Enter ${
                        fd === 'lat' ? 'Latitude' : 'Longitude'
                      }`}
                      defaultValue={
                        (lat_long?.content[index] &&
                          lat_long?.content[index][fd]) ||
                        ''
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {fd === 'lat' &&
                              lat_long?.content[index] &&
                              checkLat(lat_long?.content[index][fd]) && (
                                <Check style={{ color: '#56df50' }} />
                              )}
                            {fd === 'lat' &&
                              lat_long?.content[index] &&
                              !checkLat(lat_long?.content[index][fd]) && (
                                <Close style={{ color: '#ff0033' }} />
                              )}
                            {fd === 'long' &&
                              lat_long?.content[index] &&
                              checkLong(lat_long?.content[index][fd]) && (
                                <Check style={{ color: '#56df50' }} />
                              )}
                            {fd === 'long' &&
                              lat_long?.content[index] &&
                              !checkLong(lat_long?.content[index][fd]) && (
                                <Close style={{ color: '#ff0033' }} />
                              )}
                          </InputAdornment>
                        ),
                      }}
                      name={fd === 'lat' ? 'lat' : 'long'}
                      fullWidth
                      style={{ margin: '10px 0' }}
                      // style={{ marginBottom: 20 }}
                      // inputProps={{ style: { padding: 15 } }}
                      onChange={(event) => handleChange(event, index)}
                    />
                  </Fragment>
                ))}
              <div
                style={{
                  margin: '10px 0',
                  width: '100%',
                  height: 1,
                  background: '#ccc',
                }}
              />
            </div>
          ))}
        </div>
      )}
      <Button
        disabled={!allFieldsFilled()}
        startIcon={<Add />}
        style={{
          color: '#fff',
          background: allFieldsFilled() ? '#006fff' : '#ccc',
          textTransform: 'unset',
        }}
        onClick={() => {
          setFields((currentFields) => {
            const nextFields = {
              lat: '',
              long: '',
            }
            return [...currentFields, nextFields]
          })
          setIsLoading(true)
          setTimeout(() => {
            setIsLoading(false)
          }, 1)
        }}
      >
        Add coordinates
      </Button>
    </div>
  )
}

export default LatLong
