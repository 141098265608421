import React, { useState, useEffect } from 'react'
import { Button, Chip, TextField } from '@material-ui/core'
import FullTagsList from './FullTagsList'
import { Autocomplete } from '@material-ui/lab'
import { tagsListPhrase } from '../../../../../shared/services/api/createContent/createAlert'
import { handleMitreTags } from '../../helpers/modifyMitreTags'
import Spinner from '../../../../../shared/Spinner'
import { useThrottle } from '../../../../../hooks/useThrottle'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const ConnectorsWithModal = ({
  data,
  internal_key,
  categories,
  tagTactics,
  devContent,
  tags,
  alert,
}) => {
  const {
    handleCreateState,
    mitreConfig: { configuration },
  } = useCreateManagementContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [fullListOpen, setFullListOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [tagsOptions, setTagsOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const filteredChosenElements = () =>
    tagsOptions?.filter((item) =>
      data?.content?.connectors?.some(
        (item2) => item.id === item2.id && item.name === item2.name,
      ),
    )

  const handleState = (value, isTemporary, shouldAdd) => {
    if (isTemporary) {
      let newState = [tagTactics, undefined, tags, devContent]
      newState[0] = {}
      newState[2] = []
      newState[3][internal_key].content.connectors = []
      value.forEach((item) => {
        const newMitreData = handleMitreTags(
          configuration,
          item.id,
          item.tactic_id,
          item,
          newState[0],
          undefined,
          newState[2],
          newState[3],
          internal_key,
          shouldAdd,
        )
        newState = newMitreData
      })

      handleCreateState('dev_content_mitre', newState, true, internal_key)
      return false
    } else {
      const newMitreData = handleMitreTags(
        configuration,
        value.id,
        value.tactic_id,
        value,
        tagTactics,
        undefined,
        tags,
        devContent,
        internal_key,
        shouldAdd,
      )

      handleCreateState('dev_content_mitre', newMitreData, true, internal_key)
    }
  }

  const getTagOptions = async () => {
    setLoading(true)
    try {
      const type = 'tag'
      const slug = '*'
      const response = await tagsListPhrase(token(), type, slug, inputValue)
      response?.list?.length > 0 && setTagsOptions(response.list)
      setLoading(false)
    } catch (err) {}
  }

  const delayedFetching = useThrottle(getTagOptions, 500)

  useEffect(() => {
    setTagsOptions([])
    inputValue === '' ? setLoading(false) : delayedFetching()
  }, [inputValue])

  return (
    <>
      <h5>Tags</h5>

      <div style={{ width: '100%' }} className='associated'>
        <div style={{ margin: '10px 0' }}>
          {data?.content?.connectors?.map((piece) => (
            <Chip
              style={{
                backgroundColor: '#FFF4C9',
                color: '#000',
                margin: 2,
                fontSize: 10,
              }}
              onClick={() => {}}
              onDelete={(event) => event.type === 'click' && handleState(piece)}
              label={piece.title}
              key={piece.id + '_' + piece.title}
              deleteIcon={
                <span
                  className='icon-close'
                  style={{
                    width: 'unset',
                    height: 'unset',
                    fontSize: 14,
                    padding: '4px 4px 4px 0',
                    color: '#000',
                  }}
                />
              }
            />
          ))}
        </div>

        <Autocomplete
          multiple
          blurOnSelect={false}
          filterSelectedOptions
          disableCloseOnSelect
          getOptionSelected={(option, value) =>
            option?.id === value?.id && option?.name === value?.name
          }
          getOptionLabel={(option) => option.title}
          options={
            alert
              ? tagsOptions
              : tagsOptions?.filter((i) => !i?.technique_id) || []
          }
          value={filteredChosenElements() || []}
          onChange={(event, value) => {
            const newValue = value[value.length - 1]

            newValue && handleState(newValue, undefined, true)
          }}
          inputValue={inputValue}
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setInputValue('')
            } else if (reason !== 'reset') {
              setInputValue(value)
            }
          }}
          renderTags={() => {
            return false
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Search tags'
              variant='outlined'
              className='tag-input'
              size='small'
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <Spinner centerHorizontally size={20} />
                    ) : null}

                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>

      <Button className='btn-tags' onClick={() => setFullListOpen(true)}>
        See full list of available tags
      </Button>

      {fullListOpen && (
        <FullTagsList
          fullListOpen={fullListOpen}
          setFullListOpen={setFullListOpen}
          categories={categories}
          data={data.content.connectors}
          handleState={handleState}
        />
      )}
    </>
  )
}

export default ConnectorsWithModal
