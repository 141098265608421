/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Button } from '@material-ui/core'
import { VisibilityOff } from '@material-ui/icons'

const ViewLayers = ({ data, updateMitre }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        cursor: 'pointer',
        marginBottom: 15,
        flexWrap: 'wrap',
        gap: 5,
      }}
    >
      {data.layers.length > 1 &&
        data.layers[1].mode === 2 &&
        data.layers.map((layer) => (
          <div
            style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}
            key={layer.id}
            onClick={() => {
              const currentState = [...data.layers]
              const currentItem = currentState.filter(
                (item) => item.id === layer.id,
              )[0]
              currentItem.$isVisible = !currentItem.$isVisible
              updateMitre(currentState)
            }}
          >
            <Button
              className={`merge-layer-view layer-colour--${layer.colour}`}
            >
              {layer.$isVisible ? (
                <span className='icon-eye' />
              ) : (
                <VisibilityOff
                  style={{ width: 20, height: 20, padding: 5, opacity: 0.5 }}
                />
              )}
            </Button>

            <h3>{layer.label}</h3>
          </div>
        ))}

      {data.layers.length > 1 &&
        data.layers[1].mode === 3 &&
        data.layers.map((layer) => (
          <Button
            style={{ opacity: !layer.$isActive && 0.5 }}
            className={`compare-layer-view layer-colour--${layer.colour}`}
            key={layer.id}
            onClick={() => {
              const currentState = [...data.layers]
              const currentItem = currentState.filter(
                (item) => item.id === layer.id,
              )[0]
              if (currentItem.$isActive) {
                currentItem.$isActive = false
              } else {
                currentState.forEach((item) => (item.$isActive = false))
                currentItem.$isActive = !currentItem.$isActive
              }
              updateMitre(currentState)
            }}
          >
            {layer.label}
          </Button>
        ))}
    </div>
  )
}

export default ViewLayers
