import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const notifications = (token) =>
  client.get(apiUrl + '/api/account/notifications-configuration', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const updateNotifications = (token, data) =>
  client.put(
    apiUrl + '/api/account/change-email-notifications-settings',
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const saveNotifications = (token, data) =>
  client.post(apiUrl + '/api/account/notifications-configuration', data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
