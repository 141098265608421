/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import './GlobalSearch.scss'
import {
  Divider,
  TextField,
  Button,
  InputAdornment,
  Tabs,
  Tab,
  Snackbar,
} from '@material-ui/core'
import { globalSearchFilterOptions } from '../../shared/services/api/search/search'
import GlobalFilters from './GlobalFilters'
import GlobalListing from './GlobalListing'
import MuiAlert from '@material-ui/lab/Alert'
import { formatGlobalSearch } from '../../shared/helpers/formatDate'
import { IconButton, Pagination, Tooltip } from '@mui/material'
import Spinner from '../../shared/Spinner'
import { Clear } from '@material-ui/icons'
import { useGlobalContext } from '../../context/globalContext'
import { isMobile } from 'react-device-detect'
import { allModules } from './data'
import { useUserContext } from '../../context/userContext'
import { useQueryClient } from '@tanstack/react-query'
import { useViewStateContext } from '../../context/viewStateContext'

const GlobalSearch = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { globalSearchFilters, setGlobalSearchFilters } = useGlobalContext()
  const {
    user: {
      modules: { view_mode },
    },
    leftMenuVisible,
  } = useUserContext()
  let possibleModules = []
  allModules.map((module) => {
    view_mode.map((m) => {
      if (module.name === m.name) possibleModules = [...possibleModules, module]
    })
  })
  const searchRef = useRef(null)
  const [toastMessage, setToastMessage] = useState(undefined)
  const [filterOptions, setFilterOptions] = useState({})
  const [orderBy, setOrderBy] = useState('default')
  const [searchFilterOpen, setSearchFilterOpen] = useState(!isMobile)
  const [currentTab, setCurrentTab] = useState(!isMobile)
  const moduleTypes = [
    { name: 'All', slug: 'all' },
    ...possibleModules,
    { name: 'IOCs', slug: 'ioc' },
  ]
  const { globalSearchResult } = useViewStateContext()
  const [skipCount, setSkipCount] = useState(true)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!globalSearchResult.list) {
      queryClient.getQueryState({
        queryKey: ['globalSearchResult'],
      })

      globalSearchFilters.page ? getFilterOptions(true) : getFilterOptions()
    }
  }, [])

  useEffect(() => {
    if (skipCount) setSkipCount(false)
    if (!skipCount || globalSearchFilters.fromDashboard) {
      delete globalSearchFilters.fromDashboard
      setGlobalSearchFilters(globalSearchFilters)
      globalSearchFilters.page ? getFilterOptions(true) : getFilterOptions()
    }
  }, [globalSearchFilters.currentTab, globalSearchFilters.page, orderBy])

  const getFilterOptions = async (isPage) => {
    try {
      const typeFromProfile =
        globalSearchFilters.currentTab === 'threat-actors' ||
        globalSearchFilters.currentTab === 'incidents' ||
        globalSearchFilters.currentTab === 'operations' ||
        globalSearchFilters.currentTab === 'malware-tools'
          ? 'profiles'
          : null
      const moduleType =
        typeFromProfile ||
        (globalSearchFilters.currentTab === 'all' ||
        !globalSearchFilters.currentTab
          ? 'alerts'
          : globalSearchFilters.currentTab)

      if (globalSearchFilters.currentTab === 'ioc') {
        const ignore = searchResult(isPage)
      } else {
        let response
        if (
          globalSearchFilters.types &&
          !globalSearchFilters.types[0].id &&
          globalSearchFilters.currentTab === 'alerts'
        ) {
          //when coming from dashboard
          response = await globalSearchFilterOptions(token(), moduleType)
          setFilterOptions(response)
        } else {
          response = globalSearchFilterOptions(token(), moduleType)
          response.then((data) => setFilterOptions(data))
        }

        searchResult(isPage)
      }
      // shouldDisplayNoResults && setToastMessage(['warning', 'No results found'])
    } catch (err) {}
  }

  const searchResult = async (isPage) => {
    try {
      const payload = {}

      if (globalSearchFilters.status) {
        payload.status = globalSearchFilters.status.join()
      }

      if (globalSearchFilters.types) {
        payload.types = globalSearchFilters.types.map((i) => i.id).join()
      }

      if (orderBy === 'default') {
        delete payload.orderBy
        delete globalSearchFilters.orderBy
      } else {
        payload.orderBy = orderBy
      }

      if (globalSearchFilters.frameworks) {
        payload.frameworks = globalSearchFilters.frameworks
          .map((i) => i.id)
          .join()
      }

      if (globalSearchFilters.sectors) {
        payload.sectors = globalSearchFilters.sectors.map((i) => i.id).join()
      }

      if (globalSearchFilters.relevance) {
        payload.relevance = globalSearchFilters.relevance.join()
      }

      if (globalSearchFilters.priority) {
        payload.priority = globalSearchFilters.priority.join()
      }

      if (globalSearchFilters.priority) {
        payload.priority = globalSearchFilters.priority.join()
      }

      if (globalSearchFilters?.dateRange) {
        payload.dateFrom = formatGlobalSearch(globalSearchFilters.dateRange[0])
        payload.dateTo = globalSearchFilters.dateRange[1]
          ? formatGlobalSearch(globalSearchFilters.dateRange[1])
          : formatGlobalSearch(Date.now())
      }

      if (globalSearchFilters.content_type) {
        payload.content_type = globalSearchFilters.content_type
          .map((item) => item.slug)
          .join()
      }

      if (globalSearchFilters.keywords) {
        payload.keywords = searchRef.current.value
      }

      if (globalSearchFilters.page && isPage) {
        payload.page = globalSearchFilters.page
      } else {
        delete payload.page
        handleFilters('page', '')
      }

      const response = await queryClient.fetchQuery({
        queryKey: ['globalSearchResult'],
      })

      response?.list?.data?.length === 0 &&
        setToastMessage(['warning', 'No results found'])
    } catch (err) {}
  }

  const handleChangeTab = (newValue) => {
    let currentFilters = { ...globalSearchFilters }
    const keywords = currentFilters.keywords
    currentFilters = {}
    if (keywords) {
      currentFilters.keywords = keywords
    }

    currentFilters.currentTab = newValue

    setGlobalSearchFilters(currentFilters)
    setOrderBy('default')
    setFilterOptions({})
    setCurrentTab(newValue)
  }

  const orderChange = async (value) => {
    setOrderBy(value)
    handleFilters('orderBy', value)
  }

  const handleResetFilters = () => {
    const tab = globalSearchFilters.currentTab
    setGlobalSearchFilters({ currentTab: tab || 'all' })
    setOrderBy('default')
  }

  useEffect(() => {
    window.history.replaceState(null, '')
  }, [])

  const handleFilters = (key, value) => {
    const currentState = { ...globalSearchFilters }
    if (
      key === 'keywords' ||
      key === 'dateRange' ||
      key === 'orderBy' ||
      key === 'types' ||
      key === 'sectors' ||
      key === 'frameworks' ||
      key === 'page'
    ) {
      currentState[key] = value
    } else {
      if (!currentState[key]) {
        currentState[key] = [value]
      } else {
        if (currentState[key].includes(value)) {
          currentState[key] = currentState[key].filter((item) => item !== value)
        } else {
          currentState[key] = [...currentState[key], value]
        }
      }
    }

    if (currentState.orderBy === 'default') {
      delete currentState.orderBy
    }

    if (currentState[key]?.length === 0) {
      delete currentState[key]
    }

    setGlobalSearchFilters(currentState)
  }

  return (
    <div
      className='global-search'
      style={{ width: leftMenuVisible ? null : '100%' }}
    >
      {toastMessage && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={8000}
          onClose={() => setToastMessage(false)}
          open={toastMessage !== undefined}
        >
          <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
            {toastMessage[1]}
          </MuiAlert>
        </Snackbar>
      )}

      {searchFilterOpen && (
        <div className='global-search__filters'>
          <GlobalFilters
            filters={globalSearchFilters || { currentTab: 'all' }}
            handleFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            filterOptions={filterOptions}
            orderBy={orderBy}
            orderChange={orderChange}
            searchFilterOpen={searchFilterOpen}
            setSearchFilterOpen={setSearchFilterOpen}
          />

          <Button
            color='primary'
            style={{
              color: '#fff',
              backgroundColor: '#006fff',
              marginTop: '20px',
              width: '100%',
              textTransform: 'capitalize',
              fontWeight: 700,
            }}
            onClick={() => {
              isMobile && searchFilterOpen && setSearchFilterOpen(false)

              searchResult()
            }}
            type='submit'
          >
            Filter results
          </Button>
        </div>
      )}

      <div className='global-search__content'>
        <div className='keywords'>
          {isMobile && (
            <Button
              onClick={() => setSearchFilterOpen(!searchFilterOpen)}
              className='mob-show-filters'
              endIcon={
                <span
                  style={{ transform: 'rotate(90deg)' }}
                  className='icon-expand-content'
                />
              }
            >
              Open filters
            </Button>
          )}

          <form
            onSubmit={(event) => {
              event.preventDefault()
              searchResult()
            }}
          >
            <TextField
              inputRef={searchRef}
              fullWidth
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              value={
                globalSearchFilters.keywords ? globalSearchFilters.keywords : ''
              }
              onChange={(event) => {
                const currentState = { ...globalSearchFilters }
                if (event.target.value === '') {
                  delete currentState.keywords
                } else {
                  currentState.keywords = event.target.value
                }
                setGlobalSearchFilters(currentState)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <span
                      className='icon-search'
                      style={{ color: '#fff', fontSize: 36 }}
                    />
                  </InputAdornment>
                ),
                endAdornment: globalSearchFilters.keywords && (
                  <Tooltip title='Clear' placement='left'>
                    <IconButton onClick={() => handleFilters('keywords', '')}>
                      <Clear style={{ color: '#fff' }} />
                    </IconButton>
                  </Tooltip>
                ),
              }}
              placeholder='Click and type to search all content'
              variant='standard'
            />
          </form>
        </div>

        <Divider style={{ margin: '0 -30px', backgroundColor: '#0000004D' }} />

        <Tabs
          value={globalSearchFilters.currentTab || 'all'}
          indicatorColor='primary'
          onChange={(event, newValue) =>
            newValue !== globalSearchFilters.currentTab &&
            handleChangeTab(newValue)
          }
        >
          {moduleTypes.map((type) => (
            <Tab key={type.name} label={type.name} value={type.slug} />
          ))}
        </Tabs>

        <Divider style={{ margin: '0 -30px', backgroundColor: '#0000004D' }} />

        {!globalSearchResult?.list?.data ? (
          <Spinner center size={30} border={1} />
        ) : (
          <GlobalListing
            data={globalSearchResult?.list?.data ?? []}
            filters={globalSearchFilters || { currentTab: 'all' }}
          />
        )}

        {globalSearchResult?.list?.data && (
          <div
            style={{
              position: 'absolute',
              bottom: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: 'calc(100% - 60px)',
            }}
          >
            <Pagination
              onChange={(event, value) => handleFilters('page', value)}
              page={globalSearchFilters.page || 1}
              color='primary'
              size='small'
              count={globalSearchResult?.list.last_page || 0}
              shape='rounded'
              hideNextButton
              hidePrevButton
            />

            <h5 style={{ color: '#fff' }}>
              Total results: {globalSearchResult?.list.total || ''}
            </h5>
          </div>
        )}
      </div>
    </div>
  )
}

export default GlobalSearch
