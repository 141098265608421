import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const account = (token, data) =>
  client.post(apiUrl + '/api/account', data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const accountVerification = (token, data) =>
  client.post(apiUrl + '/api/account-verification', data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const tutorial = (token, data) =>
  client.put(apiUrl + '/api/account/tutorial', data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const setAvatar = (token, data) =>
  client.post(apiUrl + '/api/account/upload/avatar', data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
