import React, { useEffect, useState, Fragment } from 'react'
import './NodeDetails.scss'
import Avatar from '@material-ui/core/Avatar'
import { IconButton, Button } from '@material-ui/core'
import globalIcon from './../../assets/icon-global.svg'
import sectorIcon from './../../assets/icon-sector.svg'
import organisationIcon from './../../assets/icon-organisation.svg'
import CloseIcon from '@material-ui/icons/Close'
import Accordion from '@material-ui/core/Accordion'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { nodeDetails } from '../../../../shared/services/api/linkAnalysis/linkAnalysis'
import Spinner from '../../../../shared/Spinner'
import { useViewStateContext } from '../../../../context/viewStateContext'

const NodeDetails = ({ selection, setDetailsShown }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [nodeData, setNodeData] = useState(null)
  const [expanded, setExpanded] = useState(['panel1'])
  const [contentType, setContentType] = useState(null)
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { addWorkspace, setLinkAnalysisState } = useViewStateContext()

  useEffect(() => {
    if (
      Object.values(selection)[0] === undefined ||
      Object.values(selection)[0].type === 'link' ||
      Object.values(selection)[0].type === 'report' ||
      Object.values(selection)[0].type === 'scenario' ||
      Object.keys(selection)[0].startsWith('_combonode') ||
      Object.keys(selection)[0].startsWith('_combolink')
    ) {
      setNodeData(undefined)
      return false
    }

    const id = Object.keys(selection)[0].split('_')[1]
    const type = Object.keys(selection)[0].split('_')[0]

    const loadNodeDetails = async (id, type) => {
      setIsLoading(true)

      try {
        const response = await nodeDetails(token(), type, id)

        setContentType(type)
        let data = Object.values(response)[0].data
        let subtype = Object.values(response)[0].subtype

        if (type === 'profile' && subtype) {
          data.details.subtype = subtype
        }

        setNodeData(data)
        setIsLoading(false)
      } catch (error) {}
    }

    loadNodeDetails(id, type)
  }, [selection])

  const handleChange = (panel) => () => {
    if (expanded.includes(panel)) {
      setExpanded(expanded.filter((item) => item !== panel))
    } else {
      setExpanded([...expanded, panel])
    }
  }

  const tagLayout = () => {
    return (
      <>
        {nodeData.fullName ? (
          <span
            style={{ fontSize: '24px', color: '#4e4e4e', marginBottom: '15px' }}
          >
            {nodeData.fullName}
          </span>
        ) : null}

        {nodeData.details.categories ? (
          <span
            style={{ fontSize: '24px', color: '#4e4e4e', marginBottom: '15px' }}
          >
            {nodeData.details.categories[0]}
          </span>
        ) : null}
      </>
    )
  }

  const openContent = () => {
    const id = Object.keys(selection)[0]?.split('_')[1] ?? nodeData.details.id
    const type = Object.keys(selection)[0]?.split('_')[0] ?? contentType

    addWorkspace(
      type === 'profile' ? nodeData?.details?.subtype : type,
      id,
      nodeData?.fullTitle,
      {},
    )
  }

  const profileLayout = () => {
    return (
      <Fragment>
        {nodeData.fullName ? (
          <span
            style={{ fontSize: '24px', color: '#4e4e4e', marginBottom: '15px' }}
          >
            {nodeData.fullName}
          </span>
        ) : null}

        {nodeData.details.publishedAt ? (
          <span style={{ fontSize: '11px' }}>
            <b>Published at:</b> {nodeData.details.publishedAt}
          </span>
        ) : null}

        {nodeData.details.author ? (
          <span style={{ fontSize: '11px' }}>
            <b>Author:</b> {nodeData.details.author}
          </span>
        ) : null}

        <div className='avatar-container'>
          <div style={{ display: 'inline-flex' }}>
            {nodeData.details.subtype === 'threat-actor' && (
              <div className='threat-level'>
                {nodeData.details.threatLevel !== undefined && (
                  <Avatar className={'level-' + nodeData.details.threatLevel}>
                    {nodeData.details.threatLevel}
                  </Avatar>
                )}

                {nodeData.details.threatLevelName !== undefined && (
                  <span style={{ fontSize: '11px' }}>
                    {nodeData.details.threatLevelName.length > 0
                      ? nodeData.details.threatLevelName
                      : 'None'}
                  </span>
                )}
              </div>
            )}

            {nodeData.details.relevanceName ? (
              <div className='relevance'>
                {nodeData.details.relevanceSlug === 'global' && (
                  <Avatar src={globalIcon} />
                )}

                {nodeData.details.relevanceSlug === 'sector' && (
                  <Avatar src={sectorIcon} />
                )}

                {nodeData.details.relevanceSlug === 'organisation' && (
                  <Avatar src={organisationIcon} />
                )}

                <span style={{ fontSize: '11px' }}>
                  {nodeData.details.relevanceName}
                </span>
              </div>
            ) : null}
          </div>

          <Button
            color='primary'
            variant='contained'
            onClick={openContent}
            style={{
              height: '35px',
              padding: '4px 14px',
              backgroundColor: '#006fff',
              textTransform: 'capitalize',
              borderRadius: 12,
            }}
          >
            Open content
          </Button>
        </div>

        {nodeData.details.overview && nodeData.details.overview !== '' ? (
          <div
            className='overview'
            style={{
              border: '1px solid #dedede',
              backgroundColor: '#fff',
              cursor: 'pointer',
              marginTop: '10px',
              borderRadius: 12,
            }}
          >
            <Accordion
              square
              expanded={expanded.includes('panel1')}
              className='node-details--accordion'
              style={{ boxShadow: 'none', padding: '10px', borderRadius: 12 }}
              onClick={handleChange('panel1')}
            >
              <div className='node-details--accordion_content'>
                <span>Overview</span>

                <ExpandMoreIcon
                  style={{
                    transform: expanded.includes('panel1')
                      ? 'rotate(180deg)'
                      : '',
                  }}
                />
              </div>

              <div>
                <span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: nodeData.details.overview,
                    }}
                  />
                </span>
              </div>
            </Accordion>
          </div>
        ) : null}
      </Fragment>
    )
  }

  const alertLayout = () => {
    return (
      <Fragment>
        {nodeData.fullName ? (
          <span
            style={{ fontSize: '24px', color: '#4e4e4e', marginBottom: '15px' }}
          >
            {nodeData.fullName}
          </span>
        ) : null}

        {nodeData.details.publishedAt ? (
          <span style={{ fontSize: '11px' }}>
            <b>Published at:</b> {nodeData.details.publishedAt}
          </span>
        ) : null}
        {nodeData.details.author ? (
          <span style={{ fontSize: '11px' }}>
            <b>Author:</b> {nodeData.details.author}
          </span>
        ) : null}

        <div className='avatar-container'>
          <div style={{ display: 'inline-flex' }}>
            <div className='threat-level'>
              {nodeData.details.threatLevel !== undefined && (
                <Avatar className={'level-' + nodeData.details.threatLevel}>
                  {nodeData.details.threatLevel}
                </Avatar>
              )}
              {nodeData.details.threatLevelName !== undefined && (
                <span style={{ fontSize: '11px' }}>
                  {nodeData.details.threatLevelName.length > 0
                    ? nodeData.details.threatLevelName
                    : 'None'}
                </span>
              )}
            </div>

            {nodeData.details.relevanceName ? (
              <div className='relevance'>
                {nodeData.details.relevanceSlug === 'global' && (
                  <Avatar src={globalIcon} />
                )}

                {nodeData.details.relevanceSlug === 'sector' && (
                  <Avatar src={sectorIcon} />
                )}

                {nodeData.details.relevanceSlug === 'organisation' && (
                  <Avatar src={organisationIcon} />
                )}

                <span style={{ fontSize: '11px' }}>
                  {nodeData.details.relevanceName}
                </span>
              </div>
            ) : null}
          </div>

          <Button
            color='primary'
            variant='contained'
            onClick={openContent}
            style={{
              height: '35px',
              padding: '4px 14px',
              backgroundColor: '#006fff',
              textTransform: 'capitalize',
              borderRadius: 12,
            }}
          >
            Open content
          </Button>
        </div>

        {nodeData.details.overview && nodeData.details.overview !== '' ? (
          <div
            className='overview'
            style={{
              border: '1px solid #dedede',
              backgroundColor: '#fff',
              cursor: 'pointer',
              marginTop: '10px',
              borderRadius: 12,
            }}
          >
            <Accordion
              square
              expanded={expanded.includes('panel1')}
              className='node-details--accordion'
              style={{ boxShadow: 'none', padding: '10px', borderRadius: 12 }}
              onClick={handleChange('panel1')}
            >
              <div className='node-details--accordion_content'>
                <span>Overview</span>
                <ExpandMoreIcon
                  style={{
                    transform: expanded.includes('panel1')
                      ? 'rotate(180deg)'
                      : '',
                  }}
                />
              </div>
              <div>
                <span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: nodeData.details.overview,
                    }}
                  />
                </span>
              </div>
            </Accordion>
          </div>
        ) : null}

        {nodeData.details.assessment && nodeData.details.assessment !== '' ? (
          <div
            className='assessment'
            style={{
              border: '1px solid #dedede',
              backgroundColor: '#fff',
              cursor: 'pointer',
              marginTop: '10px',
              borderRadius: 12,
            }}
          >
            <Accordion
              square
              expanded={expanded.includes('panel2')}
              className='node-details--accordion'
              style={{ boxShadow: 'none', padding: '10px', borderRadius: 12 }}
              onClick={handleChange('panel2')}
            >
              <div className='node-details--accordion_content'>
                <span>Assessment</span>
                <ExpandMoreIcon
                  style={{
                    transform: expanded.includes('panel2')
                      ? 'rotate(180deg)'
                      : '',
                  }}
                />
              </div>
              <div>
                <span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: nodeData.details.assessment,
                    }}
                  />
                </span>
              </div>
            </Accordion>
          </div>
        ) : null}

        {nodeData.details.recommendation &&
        nodeData.details.recommendation !== '' ? (
          <div
            className='recommendation'
            style={{
              border: '1px solid #dedede',
              backgroundColor: '#fff',
              cursor: 'pointer',
              marginTop: '10px',
              borderRadius: 12,
            }}
          >
            <Accordion
              square
              expanded={expanded.includes('panel3')}
              className='node-details--accordion'
              style={{ boxShadow: 'none', padding: '10px', borderRadius: 12 }}
              onClick={handleChange('panel3')}
            >
              <div className='node-details--accordion_content'>
                <span>Recommendation</span>
                <ExpandMoreIcon
                  style={{
                    transform: expanded.includes('panel3')
                      ? 'rotate(180deg)'
                      : '',
                  }}
                />
              </div>
              <div>
                <span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: nodeData.details.recommendation,
                    }}
                  />
                </span>
              </div>
            </Accordion>
          </div>
        ) : null}
      </Fragment>
    )
  }

  return (
    <div className='node-details--container'>
      {isLoading ? <Spinner size={30} center color='#006fff' /> : null}

      {!isLoading && (
        <IconButton
          className='node-details--close'
          onClick={() => {
            setDetailsShown(false)

            setLinkAnalysisState((current) => ({
              ...current,
              extras: {
                ...current.extras,
                detailsShown: false,
              },
            }))
          }}
          color='primary'
        >
          <CloseIcon />
        </IconButton>
      )}

      {!isLoading && !nodeData && (
        <div className='node-details'>Content unavailable</div>
      )}

      {!isLoading && nodeData ? (
        <div className='node-details'>
          {contentType === 'tag' && tagLayout()}
          {contentType === 'alert' && alertLayout()}
          {contentType === 'profile' && profileLayout()}
        </div>
      ) : null}
    </div>
  )
}

export default NodeDetails
