/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
} from '@material-ui/core'

export const SortableTab = (props) => {
  const [menu, setMenu] = useState(null)
  const {
    id,
    label,
    tab,
    setTab,
    isDragging,
    platforms,
    setSelectedPlatforms,
    selectedPlatforms,
  } = props
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id })

  const disableYaxis = (scale) => {
    if (scale && scale.y !== 0) {
      scale.y = 0
    }
    if (scale && scale.scaleX) {
      scale.scaleX = 1
    }

    return scale
  }

  const style = {
    height: 45,
    borderRadius: 0,
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: CSS.Transform.toString(disableYaxis(transform)),
    transition,
  }

  return (
    <>
      <Menu anchorEl={menu} open={menu !== null} onClose={() => setMenu(null)}>
        {platforms.map((item) => (
          <MenuItem key={item.id} style={{ padding: 0 }}>
            <FormControlLabel
              label={item.name}
              checked={
                (selectedPlatforms[tab] &&
                  !!selectedPlatforms[tab].includes(item.id)) ||
                false
              }
              onChange={() => {
                const currentPlatforms = { ...selectedPlatforms }
                if (!currentPlatforms[tab]) {
                  currentPlatforms[tab] = [item.id]
                } else if (currentPlatforms[tab].includes(item.id)) {
                  currentPlatforms[tab] = currentPlatforms[tab].filter(
                    (i) => i !== item.id,
                  )
                } else {
                  currentPlatforms[tab] = [...currentPlatforms[tab], item.id]
                }

                setSelectedPlatforms(currentPlatforms)
              }}
              className='sortable-tab'
              style={{ margin: 0, width: '100%', padding: '3px 10px' }}
              control={
                <Checkbox
                  disableRipple
                  style={{ padding: 0, marginRight: 5 }}
                  checkedIcon={<span className='icon-checkbox-checked' />}
                  icon={<span className='icon-checkbox-empty' />}
                />
              }
            />
          </MenuItem>
        ))}
      </Menu>

      <Button
        className={`MuiTab-root${tab === id ? ' Mui-selected' : ''}`}
        onClick={() => {
          if (tab !== id) {
            !isDragging && setTab(id)
          }
        }}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        endIcon={
          tab === id &&
          platforms.length > 0 && (
            <span
              className='icon-filter'
              onClick={(event) => setMenu(event.currentTarget)}
            />
          )
        }
      >
        {label}
      </Button>
    </>
  )
}
