export const config = {
  attribution: false,
  placeholder: '',
  toolbarSticky: false,
  // pastePlain: true,
  linkAlwaysBlank: true,
  events: {
    'paste.afterCleanup': (original_event) => {
      const clearStyle = original_event.replace(/<\s*p.*?>/g, '<p>')
      const anchorCleanedUp = clearStyle.replace(
        /<a.*?(href=\x22.*?\x22).*?>/g,
        '<a $1>',
      )
      const finalCleanup = anchorCleanedUp.replace(
        // /<(?!\/?(?:a|br)(?=>|\s.*>))\/?.*?>/g,
        /<\/?(?!a)(?!p)(?!br)\w*\b[^>]*>/g,
        '',
      )
      return finalCleanup
    },
  },
  key: 'OXC1lD4D3A16A11D8C7B5dNSWXa1c1MDe1CI1PLPFa1F1EESFKVlA6F6D5H5A1D3A9A3A5F6==',
  toolbarButtons: {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontFamily',
        'fontSize',
        'textColor',
        'backgroundColor',
        'inlineClass',
        'inlineStyle',
        'clearFormatting',
      ],
    },
    format: {
      buttons: ['paragraphFormat'],
    },
    alignment: {
      buttons: ['alignLeft', 'alignCenter', 'alignRight'],
    },
    indents: {
      buttons: ['outdent', 'indent'],
    },
    moreParagraph: {
      buttons: ['formatOL', 'formatUL', 'lineHeight'],
      buttonsVisible: 4,
    },
    moreRich: {
      buttons: ['insertLink', 'insertTable'],
    },
    moreMisc: {
      buttons: [
        'undo',
        'redo',
        'spellChecker',
        'spellCheck',
        'html',
        // "help"
      ],
      align: 'right',
      buttonsVisible: 5,
    },
  },
  pluginsEnabled: [
    'codeView',
    'spellchecker',
    'table',
    'spell',
    'quote',
    'save',
    // "quickInsert",
    'paragraphFormat',
    'paragraphStyle',
    'help',
    'draggable',
    'align',
    'link',
    'lists',
    'file',
    'image',
    'emoticons',
    'url',
    'video',
    'embedly',
    'colors',
    'entities',
    'inlineClass',
    'inlineStyle',
    // 'codeBeautif '
    'spellChecker',
    'imageTUI',
  ],
}

export const configForum = {
  attribution: false,
  placeholder: '',
  toolbarSticky: false,
  pastePlain: true,
  linkAlwaysBlank: true,
  key: 'OXC1lD4D3A16A11D8C7B5dNSWXa1c1MDe1CI1PLPFa1F1EESFKVlA6F6D5H5A1D3A9A3A5F6==',
  toolbarButtons: {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontFamily',
        'fontSize',
        'textColor',
        'backgroundColor',
        'inlineClass',
        'inlineStyle',
        'clearFormatting',
      ],
    },
    format: {
      buttons: ['paragraphFormat'],
    },
    alignment: {
      buttons: ['alignLeft', 'alignCenter', 'alignRight'],
    },
    indents: {
      buttons: ['outdent', 'indent'],
    },
    moreParagraph: {
      buttons: ['formatOL', 'formatUL', 'lineHeight'],
      buttonsVisible: 4,
    },
    moreRich: {
      buttons: ['insertLink', 'insertTable'],
    },
    moreMisc: {
      buttons: [
        'undo',
        'redo',
        'spellChecker',
        'spellCheck',
        'html',
        // "help"
      ],
      align: 'right',
      buttonsVisible: 5,
    },
  },
  pluginsEnabled: [
    // 'codeView',
    'spellchecker',
    'table',
    'spell',
    'quote',
    'save',
    // "quickInsert",
    'paragraphFormat',
    'paragraphStyle',
    'help',
    'draggable',
    'align',
    // 'link',
    'lists',
    'file',
    'image',
    'emoticons',
    // 'url',
    'video',
    'embedly',
    'colors',
    // 'entities',
    'inlineClass',
    'inlineStyle',
    // 'codeBeautif '
    'spellChecker',
    'imageTUI',
  ],
}
