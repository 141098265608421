import client from '../../../http/client'

import { apiUrl } from '../../../../config/index'

export const users = (token) =>
  client.get(apiUrl + `/api/users`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleUser = (token, id) =>
  client.get(apiUrl + `/api/users/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const addUser = (token, userData) =>
  client.post(apiUrl + `/api/users`, userData, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const editUser = (token, id, userData) =>
  client.put(apiUrl + `/api/users/${id}`, userData, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteUser = (token, id) =>
  client.remove(apiUrl + `/api/users/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
