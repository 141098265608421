import client from '../../../http/client'

import { apiUrl } from '../../../../config/index'

export const getContentExport = (token, data) =>
  client.get(
    apiUrl +
      `/api/content/download?${
        data.dateFrom ? 'dateFrom=' + data.dateFrom : ''
      }${data.dateTo ? '&dateTo=' + data.dateTo : ''}&module=${data.module}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const getLogsExport = (token, logType, data) =>
  client.get(
    apiUrl +
      `/logs/${logType}/download?${
        data.dateFrom ? 'dateFrom=' + data.dateFrom : ''
      }${data.dateTo ? '&dateTo=' + data.dateTo : ''}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
