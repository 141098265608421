import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const alertsCollection = (token, data) =>
  client.get(apiUrl + `/api/alerts/refine?${data}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const alertsOptions = (token) =>
  client.get(apiUrl + '/api/alerts/refine-sidebar-options', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleAlert = (token, id) =>
  client.get(apiUrl + `/api/alerts/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const updateStatus = (token, type, data) =>
  client.put(apiUrl + `/api/${type}/change-status-of-all`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const downloadPdf = (token, type, id, data) =>
  client.post(apiUrl + `/api/pdf/${type}/${id}/download`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const exportMitre = (token, module, contentId) =>
  client.post(
    apiUrl + `/api/mitre-navigator/export/${module}/${contentId}/download`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const exportIoc = (token, payload) =>
  client.get(apiUrl + `/api/ioc/export?${payload}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const allAlerts = (token) =>
  client.get(apiUrl + `/api/admin/alerts?get`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const filterSettings = (token, type) =>
  client.get(apiUrl + `/api/${type}/refine-sidebar-options`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const viewTimeLogging = (token, data) =>
  client.put(apiUrl + `/api/logs/views/update`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
