/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from 'react'
import { useChat } from '../../../../context/chatContext'
import { IconButton, Tooltip, useMediaQuery } from '@material-ui/core'
import './SingleConversation.scss'

import SingleMessageWrapper from './components/SingleMessageWrapper/SingleMessageWrapper'
import SingleConversationHeader from './components/SingleConversationHeader'
import { formatDate } from '../../../../shared/helpers/formatDate'
import InfiniteScroll from '../../../../shared/InfiniteScroll/InfiniteScroll'
import { MarkChatRead } from '@mui/icons-material'
import UserLeaving from '../../../SinglePiece/PieceContent/PieceTabs/TabContent/Content/UserLeaving'
import { isMobile } from 'react-device-detect'
import { useSingleConversation } from '../useSingleConversation'
import Mentions from './components/Mentions/Mentions'
import EditMessage from '../EditMessage/EditMessage'
import { unescape } from 'lodash'

export interface ChatData {
  readonly id: number
  readonly content: string
  readonly chat_id: number
  readonly external_link?: string
  readonly external_link_data?: any[]
  readonly created_at?: string
  readonly reported_at?: string
  readonly deleted_at?: string
  readonly edited_at?: Date
  readonly is_same_sender?: boolean
  readonly group_id?: number
  readonly isFirstMessage?: boolean
  readonly isLastDay?: boolean
  read_at?: string
  reactions: any[]
  hasBeenParsed?: boolean
  contentId?: number
  contentType?: 'alert' | 'scenario' | 'report' | 'profile'
  type: string
  readonly renderSeparator?: boolean
  uk_links?: any
  eu_links?: any
  mention_ids: number[] | []
  readonly author?: {
    readonly centralised_id: number
    readonly display_name: string
    readonly display_name_with_org: string
    readonly id: number
  }
  is_reported: number
}

const SingleConversation = ({}) => {
  const {
    activeChat,
    activeUserChat,
    chatOpen,
    people,
    groupChats,
    editedMessageId,
  } = useChat()
  const matches = useMediaQuery('(max-width:767px)')
  const {
    chatData,
    messageRef,
    showPreview,
    rootSentinelRef,
    totalUnread,
    hiddenUnreadMessages,
    canLoadNewer,
    canLoadOlder,
    isFetching,
    isBeingCached,
    isUserLeaving,
    highlightedId,
    scrollRef,
    handleAddReaction,
    handleSendMessage,
    handleInfiniteScroll,
    markAllAsRead,
    handleFocus,
    setHiddenUnreadMessages,
    setShowPreview,
    setIsUserLeaving,
    setChatData,
    isLoading,
    message,
    setMessage,
    mentionIds,
    setMentionIds,
    lockSend,
  } = useSingleConversation()

  const unreadNumber = () => {
    if (activeUserChat?.id) {
      return (
        people?.list.find(
          ({ central_user_id }) => central_user_id === activeUserChat?.id,
        )?.chat_settings?.total_unread_messages || 0
      )
    } else {
      return (
        groupChats?.list.find(({ id }) => id === activeChat?.id)?.chat_settings
          ?.total_unread_messages || 0
      )
    }
  }

  return (
    <div
      className='chat__single-conversation'
      style={{
        maxWidth: isMobile ? 'calc(100% - 76.5px)' : matches ? 300 : undefined,
        minWidth: isMobile ? 'calc(100% - 76.5px)' : matches ? 300 : undefined,
        display: chatOpen ? 'block' : 'none',
      }}
    >
      <div className='chat__single-conversation-wrapper'>
        <SingleConversationHeader />

        {unreadNumber() > 0 ? (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              zIndex: 10,
              top: 70,
            }}
          >
            <div
              style={{
                boxShadow: 'rgba(255, 255, 255, 0.4) 0px 1px 9px 1px',
                margin: '0 auto',
                borderRadius: 7,
                backgroundColor: '#283143',
              }}
            >
              <span
                style={{
                  padding: '6px 10px',
                  borderRadius: 6,
                  fontSize: 11,
                }}
              >
                {unreadNumber() + ' '}
                more messages
              </span>

              {unreadNumber() > 0 ? (
                <Tooltip title='Mark all as read' placement='top' arrow>
                  <IconButton
                    onClick={() => markAllAsRead()}
                    className='mark-read-icon'
                    style={{ padding: 6 }}
                  >
                    <MarkChatRead style={{ color: '#6FFF54', fontSize: 14 }} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          </div>
        ) : null}

        {editedMessageId ? (
          <EditMessage
            chatData={chatData}
            mentionIds={mentionIds}
            setMentionIds={setMentionIds}
            handleEditMessage={handleSendMessage}
          />
        ) : null}

        <div
          ref={messageRef}
          className='chat__single-conversation-main-content'
          onClick={(event) => handleFocus(event)}
        >
          <InfiniteScroll
            rootSentinelRef={rootSentinelRef}
            onLoadMore={handleInfiniteScroll}
            hasMore={chatData.length >= 20}
            data={chatData}
            chatId={activeUserChat?.id || activeChat?.id || null}
            chatType={activeUserChat ? 'direct' : 'group'}
            totalUnread={totalUnread}
            setHiddenUnreadMessages={setHiddenUnreadMessages}
            canLoadOlder={canLoadOlder}
            canLoadNewer={canLoadNewer}
            hiddenUnreadMessages={hiddenUnreadMessages}
            isFetching={isFetching}
          >
            {chatData.map(
              (
                {
                  contentId,
                  contentType,
                  id,
                  content,
                  author,
                  created_at,
                  isFirstMessage,
                  is_same_sender,
                  type,
                  external_link,
                  external_link_data,
                  reactions,
                  renderSeparator,
                  deleted_at,
                  is_reported,
                  mention_ids,
                },
                index: any,
              ) => {
                return (
                  <Fragment key={id}>
                    <SingleMessageWrapper
                      handleEditMessage={handleSendMessage}
                      is_reported={is_reported}
                      chatId={activeUserChat?.id || activeChat?.id || null}
                      chatType={activeUserChat ? 'direct' : 'group'}
                      showPreview={showPreview}
                      setShowPreview={setShowPreview}
                      id={id}
                      content={
                        is_reported === 1 || !author?.centralised_id
                          ? 'This message is hidden'
                          : content
                      }
                      external_link={external_link}
                      external_link_data={external_link_data}
                      type={type}
                      contentType={contentType}
                      contentId={contentId}
                      deletedAt={deleted_at}
                      author={author}
                      is_same_sender={is_same_sender}
                      created_at={created_at}
                      onAddReaction={handleAddReaction}
                      reactions={reactions}
                      isBeingCached={isBeingCached}
                      setChatData={setChatData}
                      setIsUserLeaving={setIsUserLeaving}
                      highlightedId={highlightedId}
                      scrollRef={scrollRef}
                      mention_ids={mention_ids}
                      setMentionIds={setMentionIds}
                    />

                    {isFirstMessage ? (
                      <div className='chat__single-conversation-separator-wrapper'>
                        <div className='chat__single-conversation-separator' />
                        <span className='chat__single-conversation-date'>
                          {formatDate(created_at)}
                        </span>
                        <div className='chat__single-conversation-separator' />
                      </div>
                    ) : null}

                    {renderSeparator ? (
                      <div className='chat__single-conversation-separator-wrapper-new'>
                        <div
                          className='chat__single-conversation-separator'
                          style={{ background: '#FF6F6A' }}
                        />
                        <span
                          className='chat__single-conversation-date'
                          style={{ color: '#FF6F6A' }}
                        >
                          New
                        </span>
                      </div>
                    ) : null}
                  </Fragment>
                )
              },
            )}
          </InfiniteScroll>
        </div>

        <Mentions
          isLoading={isLoading}
          message={message}
          handleSendMessage={handleSendMessage}
          setMessage={setMessage}
          setMentionIds={setMentionIds}
          lockSend={lockSend}
        />
      </div>

      {isUserLeaving ? (
        <UserLeaving
          isUserLeaving={unescape(isUserLeaving)}
          setIsUserLeaving={setIsUserLeaving}
        />
      ) : null}
    </div>
  )
}

export default SingleConversation
