import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const searchAllCollection = (token, query) =>
  client.get(
    apiUrl + `/api/admin/content${query.length > 0 ? '?' + query : ''}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const searchMyCollection = (token, query) =>
  client.get(
    apiUrl +
      `/api/admin/content?myContent=true${query.length > 0 ? '&' + query : ''}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const pieceDelete = (token, type, id) =>
  client.remove(apiUrl + `/api/admin/${type}/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const duplicateContent = (token, type, id) =>
  client.post(
    apiUrl + `/api/admin/${type}/${id}/duplicate`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const globalSearchAllContent = (token, query) =>
  client.get(apiUrl + `/api/search${query.length > 0 ? '?' + query : ''}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const globalSearchIOCContent = (token, query) =>
  client.get(apiUrl + `/api/ioc/refine${query.length > 0 ? '?' + query : ''}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const globalSearchModuleContent = (token, query, contentType) =>
  client.get(
    apiUrl + `/api/${contentType}/refine${query.length > 0 ? '?' + query : ''}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const globalSearchProfileContent = (token, query, profileType) =>
  client.get(
    apiUrl +
      `/api/profiles/refine${profileType ? '?types=' + profileType : '?'}${
        query.length > 0 ? '&' + query : ''
      }`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const globalSearchFilterOptions = (token, contentType) =>
  client.get(apiUrl + `/api/${contentType}/refine-sidebar-options`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const globalSProfilesContent = (token) =>
  client.get(apiUrl + `/api/profiles/refine`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
