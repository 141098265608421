export const saveScenario = (data, shouldPublish) => {
  const newState = { ...data }

  if (newState.dev_title && newState.dev_title.length > 0) {
    newState.dev_content['title'].is_complete = true
  }

  if (newState.dev_content['content-distribution'].content) {
    newState.content_distribution =
      newState.dev_content['content-distribution'].content
  }

  newState.relevance = {
    ...newState.relevance,
    organisations: newState?.content_distribution?.relevance || {},
  }
  newState.dev_content.title.is_accepted = true

  if (shouldPublish && newState.can_publish_or_deny) {
    Object.values(newState.dev_content) &&
      Object.values(newState.dev_content).forEach((section) => {
        if (section.is_complete) {
          section.is_accepted = true
        }
      })
    newState.dev_content['content-distribution'].content = []
  }

  return newState
}

export const saveReport = (data, shouldPublish) => {
  const newState = { ...data }

  if (newState.dev_title && newState.dev_title.length > 0) {
    newState.dev_content['title'].is_complete = true
  }

  if (newState.dev_content['content-distribution'].content) {
    newState.content_distribution =
      newState.dev_content['content-distribution'].content
  }

  newState.relevance = {
    ...newState.relevance,
    organisations: newState?.content_distribution?.relevance || {},
  }
  newState.dev_content.title.is_accepted = true

  if (newState.tags_dev) {
    delete newState.tags_dev
  }

  if (shouldPublish && newState.can_publish_or_deny) {
    Object.values(newState.dev_content) &&
      Object.values(newState.dev_content).forEach((section) => {
        if (section.is_complete) {
          section.is_accepted = true
        }
      })
    newState.dev_content['content-distribution'].content = []
  }

  return newState
}

export const saveProfile = (data, shouldPublish) => {
  const newState = { ...data }
  if (Object.keys(newState.dev_content['overview'].content.length > 0)) {
    newState.dev_content['overview'].content = Object.assign(
      {},
      newState.dev_content['overview'].content,
    )
  }

  if (newState.dev_title && newState.dev_title.length > 0) {
    newState.dev_content['title'].is_complete = true
  }

  if (newState.dev_content['content-distribution'].content) {
    newState.content_distribution =
      newState.dev_content['content-distribution'].content
  }

  newState.relevance = {
    ...newState.relevance,
    organisations: newState?.content_distribution?.relevance || {},
  }
  newState.dev_content.title.is_accepted = true

  if (shouldPublish && newState.can_publish_or_deny) {
    Object.values(newState.dev_content) &&
      Object.values(newState.dev_content).forEach((section) => {
        if (section.is_complete) {
          section.is_accepted = true
        }
      })

    newState.dev_content['content-distribution'].content = []
  }

  return newState
}

export const saveAlert = (data, shouldPublish) => {
  const newState = { ...data }
  if (Object.keys(newState.dev_content['overview'].content.length > 0)) {
    newState.dev_content['overview'].content = Object.assign(
      {},
      newState.dev_content['overview'].content,
    )
  }

  if (Object.keys(newState.dev_content['dates'].content.length > 0)) {
    newState.dev_content['dates'].content = Object.assign(
      {},
      newState.dev_content['dates'].content,
    )
  }

  if (Object.keys(newState.dev_content['assessment'].content.length > 0)) {
    newState.dev_content['assessment'].content = Object.assign(
      {},
      newState.dev_content['assessment'].content,
    )
  }

  if (Object.keys(newState.dev_content['recommendation'].content.length > 0)) {
    newState.dev_content['recommendation'].content = Object.assign(
      {},
      newState.dev_content['recommendation'].content,
    )
  }

  if (newState.dev_content['tags'].content.connectors) {
    // const tagsOutside = newState.dev_content['tags'].content.connectors.map(item => { return { id: item.id, content_section_id: 'tags_connectors' }})
    // newState.tags = tagsOutside
    newState.dev_content['tags'].content = Object.assign(
      {},
      newState.dev_content['tags'].content,
    )
  }

  if (newState.tags_dev) {
    delete newState.tags_dev
  }

  if (newState.dev_title && newState.dev_title.length > 0) {
    newState.dev_content['title'].is_complete = true
  }

  if (newState.dev_content['content-distribution'].content) {
    newState.content_distribution =
      newState.dev_content['content-distribution'].content
  }

  newState.relevance = {
    ...newState.relevance,
    organisations: newState?.content_distribution?.relevance || {},
  }
  newState.dev_content.title.is_accepted = true

  if (shouldPublish && newState.can_publish_or_deny) {
    Object.values(newState.dev_content) &&
      Object.values(newState.dev_content).forEach((section) => {
        if (section.is_complete) {
          section.is_accepted = true
        }
      })
    newState.dev_content['content-distribution'].content = []
  }

  return newState
}
