/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, MenuItem, Select, TextField } from '@material-ui/core'
import Froala from 'react-froala-wysiwyg'
import { config } from '../../../shared/FroalaEditorComponent/options'
import { Reply } from '@mui/icons-material'
import { createForumThread } from '../../../shared/services/api/forum/categories'
import { useState } from 'react'
import useNotification from '../../../hooks/useNotification'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useQueryCacheGetCategories } from '../../../shared/services/queries/forum/useQueryGetCategories'
import { QueryClient, useMutation } from '@tanstack/react-query'
interface Category {
  id: number
  name: string
  color?: string
  description: string
  updated_at?: string
}

const NewThread = ({}) => {
  const queryClient = new QueryClient()
  const history = useHistory()
  const { categoryId }: any = useParams()
  const location: {
    state: { category: string | number; forumId: string | number }
  } = useLocation()
  const [selectedCategory, setSelectedCategory] = useState<string | number>(
    location?.state?.category ? +location?.state?.category : '',
  )
  const [title, setTitle] = useState<string>('')
  const [threadContents, setThreadContents] = useState<any>('')
  const { successToast, errorToast } = useNotification()
  const token = () => {
    return localStorage.getItem('access_token') || ''
  }

  const categories = useQueryCacheGetCategories(+location?.state?.forumId) as {
    data: { categories: Category[] }
  }

  const { mutate: createNewThread, isLoading: createForumThreadIsLoading } =
    useMutation({
      mutationFn: createForumThread,
      onSuccess: () => {
        successToast({
          message: 'Thread created successfully',
        })

        if (categoryId) {
          history.push(
            `/app/forum/${location?.state?.forumId}/${selectedCategory}`,
          )
        } else {
          history.push(`/app/forum/${location?.state?.forumId}`)
        }
      },
      onError: (error) => {
        errorToast({ message: error })
      },
      onSettled: () => {
        queryClient.invalidateQueries(['categories', +location?.state?.forumId])
      },
    })

  return (
    <div className='forum-thread-new'>
      <div style={{ width: '100%' }}>
        <div
          style={{
            padding: '4px 12px',
            background: 'transparent',
            textTransform: 'capitalize',
            color: '#fff',
            borderRadius: 10,
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          Create a new thread
        </div>

        <Divider style={{ margin: '10px 0', background: '#1c222f' }} />
      </div>

      <div className='forum-thread-new__wrapper'>
        <div>
          <h5 style={{ marginBottom: 10, color: '#000' }}>Title</h5>

          <TextField
            variant='outlined'
            placeholder='Thread title'
            fullWidth
            value={title}
            // disabled={slug === 'general'}
            onChange={(event) => setTitle(event.target.value)}
            inputProps={{
              style: {
                maxWidth: 'unset',
                padding: '14px 15px',
                fontSize: 14,
                cursor: 'text',
              },
            }}
          />
        </div>

        <div>
          <h5 style={{ marginBottom: 10, color: '#000' }}>Category</h5>
          <Select
            onChange={(event) =>
              setSelectedCategory(event.target?.value as string)
            }
            value={selectedCategory}
            className='chat-related-select'
            displayEmpty
            variant='outlined'
            renderValue={
              selectedCategory === ''
                ? () => <span style={{ color: '#aaa' }}>Select category</span>
                : undefined
            }
          >
            {categories?.data?.categories?.map(({ id, name }) => (
              <MenuItem
                key={id}
                style={{ fontSize: '13px', textTransform: 'lowercase' }}
                value={id}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div>
          <h5 style={{ marginBottom: 10, color: '#000' }}>Content</h5>

          <Froala
            model={threadContents}
            onModelChange={(text: any) => setThreadContents(text)}
            tag='textarea'
            config={{ ...config, placeholderText: 'Enter thread contents' }}
            // onManualControllerReady={(editor) => {
            //   editor?.initialize()
            //   setTimeout(() => setIsFroalaInitialized(true))
            // }}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => history.goBack()}
            style={{
              background: '#1a222e',
              textTransform: 'capitalize',
              color: '#fff',
              borderRadius: 10,
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Close
          </Button>

          <Button
            style={{
              background: '#0070ff',
              textTransform: 'capitalize',
              color: '#fff',
              borderRadius: 10,
              fontSize: 12,
              fontWeight: 600,
            }}
            disabled={
              !title ||
              !selectedCategory ||
              !threadContents ||
              createForumThreadIsLoading
            }
            startIcon={<Reply />}
            onClick={() =>
              createNewThread({
                token: token(),
                forumId: location?.state?.forumId,
                categoryId: selectedCategory,
                data: {
                  category_id: selectedCategory,
                  title: title,
                  content: threadContents,
                  is_pinned: false,
                  forum_id: location?.state?.forumId,
                },
              })
            }
          >
            Create thread
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NewThread
