import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const profilesCollection = (token, data) =>
  client.get(apiUrl + `/api/profiles/refine?${data}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const profilesOptions = (token) =>
  client.get(apiUrl + '/api/profiles/refine-sidebar-options', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleProfile = (token, id) =>
  client.get(apiUrl + `/api/profiles/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
