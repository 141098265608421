import React, { useState, useEffect } from 'react'
import {
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core'
import OrganisationUsers from './chunks/OrganisationUsers'
import ConfirmChangeGroup from './chunks/ConfirmChangeGroup'
import Associations from './chunks/Associations'
import {
  allowedGroupOptions,
  allowedGroups,
} from '../../../../../shared/services/api/createContent/createAlert'
import Spinner from '../../../../../shared/Spinner'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const ContentDistribution = ({ data, internal_key, type }) => {
  const { handleCreateState } = useCreateManagementContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [groups, setGroups] = useState(undefined)
  const [groupOptions, setGroupOptions] = useState(undefined)
  const [isLoading, setLoading] = useState(false)
  const [organisationOptions, setOrganisationOptions] = useState(undefined)
  const [publisherOptions, setPublisherOptions] = useState(undefined)
  const [externalPublisherOptions, setExternalPublisherOptions] =
    useState(undefined)
  const isCreateNew = Object.keys(data.content).length === 0
  const [confirmChangeGroup, setConfirmChangeGroup] = useState(false)

  const handleContentDistributionState = (
    prop,
    value,
    fromAssociations,
    initLoad,
  ) => {
    const currentState = { ...data }
    let newOrgState = {}

    if (
      (prop === 'publishers' || prop === 'organisations') &&
      fromAssociations
    ) {
      if (prop === 'organisations') {
        value.forEach((org) => {
          newOrgState[org.id] = org
        })

        const newOrg = value[value.length - 1]

        newOrg?.users.forEach((user) => {
          if (!currentState?.content?.users[user.id]) {
            currentState.content.users[user.id] = { id: user.id }
          }

          if (user.role_possibilities.view_possibility) {
            currentState.content.users[user.id].can_view = true
          }

          if (user.role_possibilities.edit_possibility && newOrg.is_origin) {
            currentState.content.users[user.id].can_edit = true
          }

          // if (user.role_possibilities.publish_possibility) {
          //   currentState.content.users[user.id].can_publish = true
          // }
        })
        currentState.content[prop] = newOrgState
      } else {
        if (!currentState.content.users[value]) {
          currentState.content.users[value] = { id: value }
        }

        currentState.content.users[value].can_view = true
        currentState.content.users[value].can_edit = true
        currentState.content.users[value].can_publish = true
      }
    } else if (
      (prop === 'publishers' || prop === 'organisations') &&
      !fromAssociations
    ) {
      if (prop === 'publishers') {
        currentState.content[prop] = [...currentState.content[prop], value]
      } else {
        currentState.content[prop] = value
      }
    }

    if (prop === 'group-change') {
      currentState.content.shownOrganisations = []
      currentState.content.organisations = []
      currentState.content.publishers = []
      currentState.content.users = {}
    }

    if (prop === 'groups') {
      currentState.content.groups = {
        [value]: { include_new_organisations: true },
      }
      currentState.content.groupNames = { [value]: fromAssociations }
    }

    if (prop === 'group-include') {
      Object.values(currentState.content.groups)[0].include_new_organisations =
        value
    }

    if (prop === 'organisation-include') {
      const id = fromAssociations
      currentState.content.organisations[id].include_new_users = value
    }

    if (prop === 'users' || prop === 'shownOrganisations') {
      currentState.content[prop] = value
    }

    if (prop === 'relevance') {
      const relevance = currentState.content.relevance || []
      currentState.content.relevance = relevance.includes(value)
        ? relevance.filter((item) => item !== value)
        : [...relevance, value]
    }

    if (!initLoad) {
      currentState.is_accepted = false
      currentState.is_rejected = false
      currentState.is_complete = false
    }

    handleCreateState(internal_key, currentState)
  }

  const getAllowedGroups = async () => {
    try {
      const response = await allowedGroups(token(), type)

      setGroups(response.list)

      if (!isCreateNew) {
        const groupId =
          data.content.groups && Object.keys(data.content.groups)[0]
        getGroupOptions(groupId)
      } else {
        setLoading(false)
      }
    } catch (err) {}
  }

  const getGroupOptions = async (id, isFromChangeGroup) => {
    setLoading(true)
    try {
      const response = await allowedGroupOptions(token(), type, id)
      // response.publishers.internal = Object.entries(response.publishers.internal).map(key => {
      //   return { id: key[0] * 1, name: key[1] }
      // })
      response.organisations_from_selected_group.forEach((org) => {
        org.include_new_users = true
      })
      //
      // const chosenOrganisations = data.content.organisations && data.content.organisations.length > 0 ? data.content.organisations : response.organisations_from_selected_group
      // const chosenPublishers = data.content.publishers && data.content.publishers.length > 0 ? data.content.publishers : response.publishers.internal
      // const filteredPublishers = data.content.users.length > 0 ? chosenPublishers.filter(publisher => data.content.users[publisher.id].can_publish) : chosenPublishers
      //
      setPublisherOptions(response.publishers.internal)
      setExternalPublisherOptions(response.publishers.external)
      setOrganisationOptions(response.organisations_from_selected_group)

      if (isFromChangeGroup) {
        let newOrganisations = {}
        let newUsers = {}
        response.organisations_from_selected_group.forEach((org) => {
          org?.users.forEach((user) => {
            newUsers[user.id] = {
              can_view: !!user.role_possibilities.view_possibility,
              can_edit:
                !!user.role_possibilities.edit_possibility && org.is_origin,
              can_publish:
                !!user.role_possibilities.publish_possibility && org.is_origin,
            }
          })

          newOrganisations[org.id] = {
            id: org.id,
            include_new_users: true,
            is_origin: org.is_origin,
            is_selected: true,
            name: org.name,
          }
        })
        handleContentDistributionState('users', newUsers)
        handleContentDistributionState('organisations', newOrganisations)
      } else {
        response.organisations_from_selected_group.forEach((org) => {
          if (org.is_origin === true) {
            data.content.organisations[org.id].is_origin = true
            const currentOrgs = Object.values(data.content.organisations)
            const filteredOrgs = currentOrgs.filter((i) => i.id === org.id)
            if (filteredOrgs) {
              filteredOrgs.is_origin = true
            }

            const initLoad = true
            handleContentDistributionState(
              'organisations',
              data.content.organisations,
              undefined,
              initLoad,
            )
          }
        })
      }

      setGroupOptions(response)
      setLoading(false)

      return response
    } catch (err) {}
  }

  useEffect(() => {
    setLoading(true)
    data.is_expanded && getAllowedGroups()
  }, [data && data.is_expanded])

  const handleChangeGroup = (id, name) => {
    const currentId = data.content.groups
    if (currentId === undefined) {
      handleContentDistributionState('group-change', [])

      handleContentDistributionState('groups', id, name)
      getGroupOptions(id, true)

      return false
    } else {
      if (id == Object.keys(currentId)[0]) {
        return false
      }
      setConfirmChangeGroup([true, id, name])
    }
  }

  const handleShownOrganisations = (org) => {
    const shownOrganisations =
      data.content && data.content.shownOrganisations
        ? [...data.content.shownOrganisations]
        : []
    shownOrganisations.includes(org)
      ? handleContentDistributionState(
          'shownOrganisations',
          shownOrganisations.filter((val) => val !== org),
        )
      : handleContentDistributionState('shownOrganisations', [
          ...shownOrganisations,
          org,
        ])
  }

  const handleDeleteAssociation = (type, id) => {
    if (type === 'publishers') {
      const currentUsers = { ...data?.content?.users }

      currentUsers[id].can_edit = false
      currentUsers[id].can_publish = false
      handleContentDistributionState('users', currentUsers)
    } else if (type === 'organisations') {
      const deletedOrg = data.content.organisations[id]
      deletedOrg.users = organisationOptions?.find(
        (org) => org.id === +id,
      )?.users
      const currentUsers = { ...data?.content?.users }
      deletedOrg?.users?.forEach((user) => {
        if (currentUsers[user.id]) {
          currentUsers[user.id].can_edit = false
          currentUsers[user.id].can_view = false
          currentUsers[user.id].can_publish = false
        }
      })
      handleContentDistributionState('users', currentUsers)

      const currentOrganisations = { ...data.content.organisations }
      delete currentOrganisations[id]
      handleContentDistributionState('organisations', currentOrganisations)
      handleContentDistributionState('group-include', false)
    }
  }

  const handleSelectAll = () => {
    const currentUsers = { ...data?.content?.users }
    if (
      Object.keys(data.content.organisations).length !==
      organisationOptions.length
    ) {
      let newOrgs = {}
      organisationOptions.forEach((org) => {
        if (!data.content.organisations[org.id]) {
          newOrgs[org.id] = {
            name: org.name,
            id: org.id,
            is_selected: true,
            is_origin: org.is_origin,
            include_new_users: org.include_new_users || false,
          }
        }

        !org.is_origin &&
          org?.users.forEach((user) => {
            if (!currentUsers[user.id]) {
              currentUsers[user.id] = { id: user.id }
            }

            if (currentUsers[user.id]) {
              currentUsers[user.id].can_view = true
              currentUsers[user.id].can_edit = false
              currentUsers[user.id].can_publish = false
            }
          })
      })

      handleContentDistributionState('users', currentUsers)
      handleContentDistributionState('organisations', {
        ...data.content.organisations,
        ...newOrgs,
      })
    } else {
      let originOrgs = {}
      Object.entries(data.content.organisations).forEach((org) => {
        const orgData = org[1]
        if (orgData.is_origin) {
          originOrgs[org[0]] = orgData
        }
      })

      organisationOptions.forEach((org) => {
        !org.is_origin &&
          org?.users.forEach((user) => {
            if (!currentUsers[user.id]) {
              currentUsers[user.id] = { id: user.id }
            }

            if (currentUsers[user.id]) {
              currentUsers[user.id].can_view = false
              currentUsers[user.id].can_edit = false
              currentUsers[user.id].can_publish = false
            }
          })
      })

      handleContentDistributionState('users', currentUsers)
      handleContentDistributionState('organisations', originOrgs)
      handleContentDistributionState('group-include', false)
    }
  }

  return (
    <div className='middle'>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4>Select a group</h4>

          {groups && !isLoading && data.content && data.content.groups && (
            <FormControlLabel
              checked={
                data.content && data.content.groups
                  ? Object.values(data.content.groups)[0]
                      .include_new_organisations
                  : true
              }
              onChange={(event) =>
                handleContentDistributionState(
                  'group-include',
                  event.target.checked,
                )
              }
              control={<Switch color='primary' />}
              label='Include new organisations'
              labelPlacement='start'
            />
          )}
        </div>

        {groups && !isLoading && (
          <FormControl
            style={{ position: 'relative', marginTop: 20 }}
            fullWidth
          >
            <Select
              displayEmpty
              renderValue={
                !data.content['groups']
                  ? () => <div style={{ color: '#aaa' }}>Select group</div>
                  : undefined
              }
              value={
                isCreateNew
                  ? ''
                  : data.content.groups &&
                    Object.keys(data.content.groups)[0] * 1
              }
              variant='outlined'
              onChange={(event) =>
                handleChangeGroup(
                  event.target.value,
                  event.currentTarget.dataset.name,
                )
              }
            >
              {groups
                .filter((group) => group.is_origin_group)
                .map((group) => (
                  <MenuItem
                    data-name={group.name}
                    key={group.id}
                    value={group.id}
                  >
                    {group.name}
                  </MenuItem>
                ))}

              {groups
                .filter((group) => !group.is_origin_group)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((group) => (
                  <MenuItem
                    data-name={group.name}
                    key={group.id}
                    value={group.id}
                  >
                    {group.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        {confirmChangeGroup && (
          <ConfirmChangeGroup
            confirmChangeGroup={confirmChangeGroup}
            setConfirmChangeGroup={setConfirmChangeGroup}
            handleContentDistributionState={handleContentDistributionState}
            getGroupOptions={getGroupOptions}
          />
        )}

        {groupOptions && !isLoading && <Divider style={{ margin: '20px 0' }} />}
      </div>

      {isLoading && <Spinner size={30} centerHorizontally />}

      {organisationOptions && !isLoading && data.content && (
        <>
          <Associations
            title='Select Organisation'
            chosenElements={data.content.organisations}
            options={organisationOptions}
            isLoading={isLoading}
            handleDeleteAssociation={handleDeleteAssociation}
            handleContentDistributionState={handleContentDistributionState}
            slug='organisations'
            handleSelectAll={handleSelectAll}
            organisationOptions={organisationOptions}
          />

          <Divider style={{ margin: 20 }} />
        </>
      )}

      {groupOptions && !isLoading && publisherOptions && data.content && (
        <>
          <Associations
            title='Select Internal Publishers'
            chosenElements={data?.content?.users}
            options={publisherOptions}
            isLoading={isLoading}
            handleDeleteAssociation={handleDeleteAssociation}
            handleContentDistributionState={handleContentDistributionState}
            slug='publishers'
            organisationOptions={organisationOptions}
            externalPub={externalPublisherOptions}
          />

          <Divider style={{ margin: 20 }} />
        </>
      )}

      {groupOptions &&
        !isLoading &&
        Object.keys(externalPublisherOptions).length > 0 &&
        data.content && (
          <>
            <Associations
              title='Select External Publishers'
              chosenElements={data?.content?.users}
              options={externalPublisherOptions}
              isLoading={isLoading}
              handleDeleteAssociation={handleDeleteAssociation}
              handleContentDistributionState={handleContentDistributionState}
              slug='publishers'
              organisationOptions={organisationOptions}
              isExternal
              currentOrganisations={data.content.organisations}
            />

            <Divider style={{ margin: 20 }} />
          </>
        )}

      {groupOptions && !isLoading && data.content.organisations && (
        <div>
          <h4>Content Permissions</h4>

          <OrganisationUsers
            organisations={data.content.organisations}
            organisationOptions={organisationOptions}
            shownOrganisations={data.content.shownOrganisations || []}
            handleShownOrganisations={handleShownOrganisations}
            handleContentDistributionState={handleContentDistributionState}
            users={data?.content?.users}
            relevance={data.content.relevance}
            handleChangeGroup={handleChangeGroup}
            setConfirmChangeGroup={setConfirmChangeGroup}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  )
}

export default ContentDistribution
