import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useUserContext } from '../../../context/userContext'

const adminRoutes = [
  {
    route: '/app/admin/instances',
    icon: 'icon-scenario',
    name: 'Instances & Organisations',
    isRotated: true,
  },
  {
    route: '/app/admin/system-settings/groups',
    icon: 'icon-settings',
    name: 'System Settings',
  },
  {
    route: '/app/admin/logs/login-history',
    icon: 'icon-logs path2',
    name: 'Logs',
  },
  //   {
  //     route: '/app/admin/knowledge-base',
  //     icon: 'icon-report',
  //     name: 'Knowledge Base',
  //   },
  {
    route: '/app/admin/content-exports',
    icon: 'icon-report',
    name: 'Content Exports',
  },
  {
    route: '/app/admin/forum/categories',
    icon: 'icon-report',
    name: 'Forum Module',
  },
]

const NavItem = ({ name, route, isRotated, icon }) => {
  const location = useLocation()
  const returnRoute = () => {
    if (name === 'Logs' || name === 'System Settings') {
      return route.split('/').slice(0, -1).join('/')
    }

    return route
  }

  return (
    <NavLink
      to={name === 'Knowledge Base' ? route + '/all' : route}
      isActive={() => location.pathname.startsWith(returnRoute())}
    >
      <span
        style={{ transform: isRotated ? 'rotate(180deg)' : null, fontSize: 32 }}
        className={icon}
      />

      <h3>{name}</h3>
    </NavLink>
  )
}

NavItem.propTypes = {
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  isRotated: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
}

NavItem.displayName = 'NavItem'

const AdminLayout = () => {
  const {
    user: { is_admin },
  } = useUserContext()

  if (is_admin) {
    return (
      <>
        {adminRoutes.map(({ route, icon, name, isRotated }) => (
          <NavItem
            key={route}
            route={route}
            icon={icon}
            isRotated={isRotated || false}
            name={name}
          />
        ))}
      </>
    )
  }

  return null
}

AdminLayout.displayName = 'AdminLayout'

export default AdminLayout
