import { useQuery } from '@tanstack/react-query'
import { getSingleThreadData } from '../../api/forum/singleThread'

const token = () => {
  return localStorage.getItem('access_token')
}

export const useQueryGetSingleThreadData = (
  forumId: number,
  categoryId: number,
  threadId: number,
  page: number,
) => {
  return useQuery({
    queryKey: ['thread', forumId, categoryId, threadId, page],
    queryFn: () =>
      getSingleThreadData(token() || '', forumId, categoryId, threadId, page),
    keepPreviousData: true,
  })
}
