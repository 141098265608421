import client from './../../http/client'

import { apiUrl } from '../../../config/index'

const token = () => {
  return localStorage.getItem('access_token')
}

export const getReportedResource = (type) =>
  client.get(apiUrl + `/api/moderation-reports/${type}`, {
    headers: {
      Authorization: 'Bearer ' + token(),
    },
  })

export const deleteReportedResource = ({ id, type }) =>
  client.remove(apiUrl + `/api/moderation-reports/${type}/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token(),
    },
  })

export const reportedResourceStatus = ({ id, content, type }) =>
  client.put(
    apiUrl + `/api/moderation-reports/${type}/${id}`,
    { status: content },
    {
      headers: {
        Authorization: 'Bearer ' + token(),
      },
    },
  )

export const reportedResourceThread = (token, id, type) =>
  client.get(apiUrl + `/api/moderation-reports/${type}/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
