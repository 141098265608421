import { useState } from 'react'
import useNotification from '../../../hooks/useNotification'
import {
  deleteReportedResource,
  reportedResourceStatus,
} from '../../../shared/services/api/moderation/moderation'
import { useQueryGetReportedResources } from '../../../shared/services/queries/moderation/useQueryGetReportedResources'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useReportedResource = (type) => {
  const [resourceModal, setResourceModal] = useState(null)
  const [deleteModal, setDeleteModal] = useState(undefined)
  const { successToast } = useNotification()
  const [isDeleting, setDeleting] = useState(false)
  const queryClient = useQueryClient()

  const { data } = useQueryGetReportedResources(type)
  const { mutate: deleteReportedResourceMutation } = useMutation({
    mutationFn: deleteReportedResource,
    onSuccess: () => {
      setDeleting(false)
      setDeleteModal(undefined)
      queryClient.invalidateQueries(['moderation', type])
    },
  })

  const { mutate: changeStatusMutation } = useMutation({
    mutationFn: reportedResourceStatus,
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries(['moderation', type])
      message !== 'Comment report has been updated' &&
        message !== 'In progress' &&
        successToast({ message })
    },
  })

  return {
    data,
    deleteReportedResourceMutation,
    changeStatusMutation,
    resourceModal,
    setResourceModal,
    deleteModal,
    setDeleteModal,
    isDeleting,
    setDeleting,
  }
}
