/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { cloneDeep, unescape, uniq } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useChat } from '../../../../context/chatContext'
import { Mention, MentionsInput } from 'react-mentions'
import { Button } from '@material-ui/core'

interface Props {
  readonly mentionIds: number[] | []
  setMentionIds: Dispatch<SetStateAction<any>>
  chatData: any
  handleEditMessage: (msg: string) => void
}

interface UserInfoCopy {
  id: number
  display: string
  central_user_id: number
  display_name: string
  app_id: number
  organisation_name: string
  avatar: string
  chat_settings: {
    chat_email_notify_interval: string
    chat_email_notify_mute: boolean
    total_unread_messages: number
  }
}

const EditMessage = ({
  mentionIds,
  setMentionIds,
  chatData,
  handleEditMessage,
}: Props) => {
  const [peopleCopy, setPeopleCopy] = useState<UserInfoCopy[] | []>([])
  const {
    people,
    setEditedMessageId,
    activeUserChat,
    activeChat,
    editedMessageId,
  } = useChat()
  const [editedContent, setEditedContent] = useState<any>('')

  useEffect(() => {
    const ids = chatData?.find((i: any) => i.id === editedMessageId)
      ?.mention_ids
    setMentionIds(ids)
    let content = chatData?.find((i: any) => i.id === editedMessageId)?.content
    ids?.forEach((id: any) => {
      const personName = people?.list?.find(
        (i: any) => i.central_user_id === id,
      )?.display_name

      content = content?.replaceAll(
        `<span class="content-mention">${personName}</span>`,
        `{${personName}}`,
      )
    })
    setEditedContent(unescape(content))
  }, [])

  useEffect(() => {
    const copy = cloneDeep(people?.list) as UserInfoCopy[]
    copy?.map((p) => {
      if (p?.central_user_id) {
        p.id = p?.central_user_id
      }

      if (p?.display_name) {
        p.display = p?.display_name
      }
      return p
    })
    setPeopleCopy(copy)
  }, [people])

  const handleChange = (event: any) => {
    const value = event.target.value
    const regex = /[^{}]+(?=})/g
    const mentions = value.match(regex)
    if (mentions) {
      const ids = mentions?.map((mention: string) => {
        const peopleCopy = cloneDeep(people?.list)
        const id = peopleCopy?.find(
          ({ display_name }) => display_name === mention,
        )?.central_user_id
        return id
      })
      const finalIds = uniq(ids)
      setMentionIds(finalIds)
    } else {
      setMentionIds([])
    }
    setEditedContent(value)
  }

  return (
    <form
      className='form-edit'
      style={{
        width: 'auto',
        zIndex: 99,
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: '350px',
        maxWidth: 400,
        background: '#1b222e',
        borderRadius: 10,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'fit-content',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 30px 13px',
        padding: 20,
      }}
      onSubmit={(event) => {
        event.preventDefault()
        handleEditMessage(editedContent ?? '')
      }}
    >
      <MentionsInput
        allowSpaceInQuery
        className='mention-input'
        value={editedContent?.replaceAll(/<[^>]*>/g, '') || ''}
        placeholder='Message'
        singleLine
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        forceSuggestionsAboveCursor
        // disabled={isLoading}
        onChange={handleChange}
      >
        <Mention
          className='mention-wrapper'
          trigger='@'
          appendSpaceOnAdd
          displayTransform={(id: any, display: any) => `${display}`}
          data={activeChat?.id && !activeUserChat?.id ? peopleCopy : []}
          markup='{__display__}'
        />
      </MentionsInput>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: 12,
          marginTop: 8,
        }}
      >
        <Button
          // disabled={isLoading}
          onClick={() => setEditedMessageId(null)}
          type='button'
          style={{
            width: '120px',
            fontSize: 13,
            fontWeight: 600,
            borderRadius: '6px',
            backgroundColor: '#fff',
            textTransform: 'unset',
          }}
        >
          Cancel
        </Button>
        <Button
          // disabled={isLoading}
          type='submit'
          style={{
            width: '120px',
            fontSize: 13,
            fontWeight: 600,
            borderRadius: '6px',
            backgroundColor: '#fff',
            textTransform: 'unset',
          }}
        >
          Edit
        </Button>
      </div>
    </form>
  )
}

export default EditMessage
