export const ForumIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24.819'
    height='22.69'
    viewBox='0 0 24.819 22.69'
    fill='#fff'
  >
    <path d='M9.556 0c.072 0 .144.01.215.013a6.4 6.4 0 0 1 3.44 1.09 4.4 4.4 0 0 1 1.575 1.816 3.52 3.52 0 0 1-.028 3.1 4.4 4.4 0 0 1-1.4 1.646 6.1 6.1 0 0 1-2.493 1.074 7 7 0 0 1-2.644.012 6.2 6.2 0 0 1-2.217-.856.15.15 0 0 0-.15-.017 7.5 7.5 0 0 0-1.765.963.345.345 0 0 1-.388.057.25.25 0 0 1-.15-.238 5 5 0 0 1 .462-2.064 4 4 0 0 1 .269-.431.085.085 0 0 0 .008-.1 3.58 3.58 0 0 1 .033-3.314 4.7 4.7 0 0 1 1.736-1.8A6.24 6.24 0 0 1 8.656.062c.248-.029.5-.035.749-.052L9.456 0Z' />
    <path d='m0 21.09.019-.172a1.9 1.9 0 0 1 .512-1.05 4 4 0 0 1 1.151-.856 9.2 9.2 0 0 1 2.389-.83 9.4 9.4 0 0 1 1.637-.213 7.3 7.3 0 0 1 1.25.073 11 11 0 0 1 1.9.435 6.9 6.9 0 0 1 1.867.893 2.8 2.8 0 0 1 .889.952 1.65 1.65 0 0 1 .2.79v1.58H0v-1.6' />
    <path d='M24.816 22.686h-11.8l-.007-.026c0-.539-.006-1.079 0-1.618a1.7 1.7 0 0 1 .4-1.027 3.6 3.6 0 0 1 1.023-.861 8 8 0 0 1 2.036-.825 10 10 0 0 1 2.165-.355 7.5 7.5 0 0 1 1.52.1 10.6 10.6 0 0 1 2.112.547 6.2 6.2 0 0 1 1.55.814 2.7 2.7 0 0 1 .819.919 1.7 1.7 0 0 1 .188.788v1.547' />
    <path d='M13.813 8.795c.162-.123.316-.233.463-.352a5.56 5.56 0 0 0 1.655-2.146 4.73 4.73 0 0 0 .193-3.175c0-.015-.007-.031-.012-.055.106-.017.209-.037.312-.051a5.36 5.36 0 0 1 2.9.38 4.07 4.07 0 0 1 1.7 1.323 2.97 2.97 0 0 1 .6 1.727 2.9 2.9 0 0 1-.406 1.543.13.13 0 0 0 0 .152 3.3 3.3 0 0 1 .342 1.191l.017.175a.27.27 0 0 1-.126.287.32.32 0 0 1-.34 0 1 1 0 0 1-.081-.053 5.7 5.7 0 0 0-.921-.508.1.1 0 0 0-.105.009 5 5 0 0 1-2.475.791 5.3 5.3 0 0 1-2.229-.331 4.4 4.4 0 0 1-1.441-.875l-.045-.037' />
    <path d='M18.916 16.781a2.954 2.954 0 1 1 2.948-2.984 2.956 2.956 0 0 1-2.948 2.984' />
    <path d='M5.925 16.78a2.954 2.954 0 1 1 2.939-3.057 2.954 2.954 0 0 1-2.939 3.057' />
    <path d='M12.386 15.6a2.362 2.362 0 1 1 2.386-2.354 2.356 2.356 0 0 1-2.389 2.354' />
    <path d='M15.211 17.483a6.1 6.1 0 0 0-2.8 1.881 4.6 4.6 0 0 0-1.261-1.136 8.2 8.2 0 0 0-1.543-.745c.071-.035.128-.064.186-.091a7 7 0 0 1 1.828-.543 5.7 5.7 0 0 1 2.789.3c.252.084.493.2.739.3.018.008.035.019.064.034' />
  </svg>
)
