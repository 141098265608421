/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Switch,
  Route,
  useHistory,
  NavLink,
  useLocation,
} from 'react-router-dom'
import NewThread from './NewThread'
import './Forum.scss'
import { Button, Divider } from '@material-ui/core'
import SingleThread from './SingleThread'
import Categories from './Categories'
import Threads from './Threads'
import { useUserContext } from '../../context/userContext'
import SearchedThreads from './SearchedThreads'
import { useEffect, useRef } from 'react'
import NotFoundPage from '../../shared/NotFoundPage'

const Forum = () => {
  const { user } = useUserContext()
  const history = useHistory()
  const location = useLocation()
  const isNotFirstRender = useRef(false)

  useEffect(() => {
    isNotFirstRender.current &&
      localStorage.setItem('forumURL', location.pathname)

    isNotFirstRender.current = true
  }, [location.pathname])

  useEffect(() => {
    if (user?.forum_groups.length === 1 && location.pathname === '/app/forum') {
      return history.push({
        pathname: `/app/forum/${user?.forum_groups[0].id}`,
        state: {
          forumName: user.forum_groups[0].name,
        },
      })
    }
  }, [user?.forum_groups])

  return !user?.is_forum_enabled ? (
    <NotFoundPage />
  ) : (
    <div className='forum'>
      <div
        style={{
          width: '80%',
          color: '#fff',
        }}
      >
        <Switch>
          <Route exact path='/app/forum'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '100px',
              }}
            >
              <h1>ThreatMatch Forum</h1>

              <div
                style={{
                  marginTop: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexWrap: 'wrap',
                  gap: '20px',
                }}
              >
                <Divider />

                {user?.forum_groups.map((forum) => (
                  <NavLink
                    style={{
                      flexBasis: '20%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #283143',
                      background: '#283143',
                      borderRadius: '12px',
                      padding: '32px',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                    key={forum.id}
                    to={{
                      pathname: `forum/${forum.id}`,
                      state: {
                        forumName: forum.name,
                      },
                    }}
                  >
                    <div>{forum.name}</div>
                  </NavLink>
                ))}
              </div>
            </div>
            {/* <Categories /> */}

            {/* <Threads /> */}
          </Route>
          <Route exact path='/app/forum/thread-new' component={NewThread} />

          <Route
            exact
            path='/app/forum/:forumId/search/:keyword'
            component={SearchedThreads}
          />

          <Route exact path='/app/forum/:forumId' component={Categories} />

          <Route
            exact
            path='/app/forum/:forumId/:categoryId'
            component={Threads}
          />

          <Route
            exact
            path='/app/forum/:forumId/:categoryId/:threadId'
            component={SingleThread}
          />
        </Switch>
      </div>
    </div>
  )
}

export default Forum
