import React from 'react'
import { Button, Chip, CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const Associations = ({
  title,
  chosenElements,
  options,
  isLoading,
  handleDeleteAssociation,
  slug,
  handleContentDistributionState,
  handleSelectAll,
  organisationOptions,
  isExternal,
  externalPub,
  currentOrganisations,
}) => {
  let externalPublishers = {}
  let possibleExternalPublishers = []
  if (isExternal) {
    chosenElements &&
      Object.values(chosenElements).forEach((user) => {
        if (options[user.id]) {
          externalPublishers[user.id] = user
        }
      })
  }

  let newUsers = []
  let possiblePublishers = []
  if (slug === 'publishers') {
    const obj = isExternal ? externalPublishers : chosenElements
    Object.entries(obj).forEach((user) => {
      const id = user[0] * 1
      const userPermissions = user[1]
      userPermissions.id = id
      newUsers = [...newUsers, userPermissions]
    })

    organisationOptions.forEach((org) => {
      org?.users?.forEach((user) => {
        if (isExternal) {
          if (
            options[user.id] &&
            user.role_possibilities.publish_possibility === true &&
            currentOrganisations[org.id]
          ) {
            const newUser = {
              name: user.first_name + ' ' + user.last_name,
              id: user.id,
            }
            possibleExternalPublishers = [
              ...possibleExternalPublishers,
              newUser,
            ]
          }
        } else {
          if (
            !externalPub[user.id] &&
            user.role_possibilities.publish_possibility === true
          ) {
            const newUser = {
              name: user.first_name + ' ' + user.last_name,
              id: user.id,
            }
            possiblePublishers = [...possiblePublishers, newUser]
          }
        }
      })
    })
  }

  const chosenObj =
    slug === 'organisations'
      ? Object.values(chosenElements)
      : newUsers.filter((i) => i.can_publish === true)

  const chosenMapped = () => {
    if (slug === 'organisations') {
      return chosenObj
    } else {
      let mappedPublishers = []
      const publishersArray = isExternal
        ? possibleExternalPublishers
        : possiblePublishers.filter((u) => !externalPub[u.id])
      publishersArray.forEach((pub) => {
        if (
          chosenElements[pub.id] &&
          chosenElements[pub.id].can_publish &&
          chosenElements[pub.id].can_publish === true
        ) {
          mappedPublishers = [...mappedPublishers, pub]
        }
      })
      return mappedPublishers
    }
  }

  let placeholder = ''
  if (slug === 'organisations') {
    placeholder = 'Select organisation'
  } else if (slug === 'publishers' && !isExternal) {
    placeholder = 'Select internal publishers'
  } else if (slug === 'publishers' && isExternal) {
    placeholder = 'Select external publishers'
  } else placeholder = 'Select Profiles'

  const userOrg = (userId) => {
    let orgName = ''
    organisationOptions.some((org) => {
      org?.users?.some((user) => {
        if (user.id === userId) {
          orgName = org.name
        }
      })
    })
    return ` - ${orgName}`
  }

  return (
    <div className='associated'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4>{title}</h4>

        {slug === 'organisations' && (
          <Button
            onClick={() => handleSelectAll()}
            style={{
              fontSize: 13,
              padding: '2px 12px',
              textTransform: 'capitalize',
            }}
          >
            {chosenObj?.length !== options.length
              ? 'Select All'
              : 'Deselect all'}
          </Button>
        )}
      </div>

      <div style={{ margin: '10px 0' }}>
        {chosenMapped().map((piece) => (
          <Chip
            style={{
              backgroundColor: '#ffc9c9',
              color: '#000',
              margin: 2,
              fontSize: 11,
            }}
            onClick={() => {}}
            disabled={
              slug === 'organisations'
                ? piece.is_origin
                : !isExternal && chosenMapped().length <= 1
            } //at least one publisher is obligatory
            onDelete={() => handleDeleteAssociation(slug, piece.id)}
            label={isExternal ? piece.name + userOrg(piece.id) : piece.name}
            key={piece.id}
            deleteIcon={
              <span
                className='icon-close'
                style={{
                  width: 'unset',
                  height: 'unset',
                  fontSize: 14,
                  padding: '4px 4px 4px 0',
                  color: '#000',
                }}
              />
            }
          />
        ))}
      </div>

      <Autocomplete
        multiple
        blurOnSelect
        disableClearable
        filterSelectedOptions
        getOptionSelected={(option, value) => +option.id === +value.id}
        getOptionLabel={(option) => option.name}
        options={
          slug === 'organisations'
            ? options
            : isExternal
            ? possibleExternalPublishers
            : possiblePublishers || []
        }
        value={chosenObj}
        onChange={(event, value) => {
          const { key } = event

          if (key === 'Backspace' || key === 'Delete') {
            return false
          }

          if (slug === 'publishers') {
            const newValue = value[value.length - 1].id
            handleContentDistributionState(slug, newValue, true)
          } else {
            handleContentDistributionState(slug, value, true)
          }
        }}
        renderTags={() => {
          return false
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant='outlined'
            className='tag-input'
            size='small'
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress size={20} /> : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  )
}

export default Associations
