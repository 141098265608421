import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import './Button.scss'

const BaseButton = ({
  className,
  children,
  disabled = false,
  type = 'submit',
  onClick,
  isSquared,
  isDarkFontColor,
  background,
  icon,
}) => {
  const getBackgroundColor = () => {
    switch (background) {
      case 'green':
        return '#3AAA35'
      case 'lightBlue':
        return '#6180A8'
      case 'orange':
        return '#DC722B'
      case 'white':
        return '#FFF'
      case 'navy':
        return '#273042'
      case 'transparent':
        return 'transparent'
      case 'red':
        return '#ff0033'
      default:
        return '#006FFF'
    }
  }

  return (
    <Button
      startIcon={icon}
      className={`button ${className}`}
      style={{
        borderRadius: isSquared ? '0' : '12px',
        color: isDarkFontColor ? '#212935' : '#fff',
        backgroundColor: disabled ? '#BABABA' : getBackgroundColor(),
      }}
      disabled={disabled}
      onClick={onClick}
      variant='contained'
      type={type}
      color='primary'
    >
      {children}
    </Button>
  )
}

BaseButton.defaultProps = {
  type: 'submit',
  isSquared: false,
  isDarkFontColor: false,
  background: 'blue',
  className: '',
  disabled: false,
  icon: null,
}

BaseButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isSquared: PropTypes.bool,
  isDarkFontColor: PropTypes.bool,
  background: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
}

export default BaseButton
