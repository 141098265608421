/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useQuery } from '@tanstack/react-query'
import { getListAnalysis } from '../../api/linkAnalysis/linkAnalysis'

const token = () => {
  return localStorage.getItem('access_token')
}

export const useQueryGetListAnalysis = (page: number, searchPhrase: string) => {
  return useQuery({
    queryKey: ['link-analysis', { page, searchPhrase }],
    queryFn: () => getListAnalysis(token(), { page, keywords: searchPhrase }),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}
