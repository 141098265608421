/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import './GlobalSearch.scss'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core'
import 'flatpickr/dist/themes/material_green.css'
import { Avatar, Divider } from '@mui/material'
import GlobalAutocomplete from './GlobalAutocomplete'
import OrderBy from '../Profiles/Filters/OrderBy'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import DateRangePickerComponent from '../../shared/DateRangePicker/DateRangePicker'

/**
 * GlobalFilters
 * @param {object} filters - current filters applied
 * @param {function} handleFilters - handling the state of filters
 * @param {string} currentTab - current module tab
 * @param {function} handleResetFilters - reseting filters
 * @param {object} filterOptions - filter autocomplete options
 * @param {string} orderBy - orderBy filter
 * @param {function} orderChange - handling orderBy state
 * @param {boolean} searchFilterOpen - are filters open
 * @param {function} setSearchFilterOpen - setting filters open state
 */

const statusCheckboxes = [
  { name: 'Read', slug: 'read' },
  { name: 'Unread', slug: 'unread' },
  { name: 'Followed', slug: 'flagged' },
]
const relevanceCheckboxes = ['organisation', 'sector', 'global']
const levels = [1, 2, 3, 4, 5]

const GlobalFilters = ({
  filters,
  handleFilters,
  handleResetFilters,
  filterOptions,
  orderBy,
  orderChange,
  searchFilterOpen,
  setSearchFilterOpen,
}) => {
  const returnClass = (level) => {
    if (
      filters.currentTab === 'alerts' ||
      filters.currentTab === 'threat-actors'
    ) {
      return filters?.priority?.includes(level)
        ? `level-${level}`
        : `level-${level} inactive`
    }
  }

  return (
    <>
      <div
        style={{
          height: 84,
          display: isMobile ? 'flex' : null,
          justifyContent: isMobile ? 'space-between' : null,
        }}
      >
        <h3 style={{ padding: '30px 0' }}>Search all content</h3>

        {isMobile ? (
          <Button
            onClick={() => setSearchFilterOpen(!searchFilterOpen)}
            style={{ textTransform: 'capitalize', color: '#fff' }}
            endIcon={
              <span
                style={{ transform: 'rotate(270deg)' }}
                className='icon-expand-content'
              />
            }
          >
            Hide filters
          </Button>
        ) : null}
      </div>

      <Divider
        style={{
          margin: '0 -30px',
          backgroundColor: '#0000004D',
          border: 'none',
        }}
      />

      <div
        style={{
          margin: '12px 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span
          onClick={handleResetFilters}
          style={{
            color:
              Object.keys(filters).length > 1 ||
              (Object.keys(filters).length === 1 && !filters.currentTab)
                ? '#fff'
                : '#8d9197',
            cursor: 'pointer',
          }}
        >
          Reset filters
        </span>

        <OrderBy orderBy={orderBy} orderChange={orderChange} />
      </div>

      <Divider
        style={{
          margin: '0 -30px',
          backgroundColor: '#0000004D',
          border: 'none',
        }}
      />

      {(filters.currentTab === 'alerts' ||
        filters.currentTab === 'threat-actors') && (
        <div className='threat-level'>
          <h4>
            {filters.currentTab === 'alerts' ? 'Threat severity' : 'Capability'}
          </h4>

          <div className='priority'>
            {levels.map((level) => (
              <Avatar
                key={level}
                onClick={() => handleFilters('priority', level)}
                className={returnClass(level)}
              >
                {level}
              </Avatar>
            ))}
          </div>
        </div>
      )}

      <div className='status'>
        <h4>Status</h4>

        <FormGroup row className='status-checkboxes'>
          {statusCheckboxes.map((item) => (
            <FormControlLabel
              key={item.slug}
              control={
                <Checkbox
                  checked={
                    filters.status ? filters.status.includes(item.slug) : false
                  }
                  onChange={() => handleFilters('status', item.slug)}
                  color='default'
                  icon={<span className='icon-checkbox-empty' />}
                  checkedIcon={<span className='icon-checkbox-checked' />}
                />
              }
              label={item.name}
            />
          ))}
        </FormGroup>
      </div>

      <div className='relevance'>
        <h4>Relevance</h4>

        <FormGroup row className='relevance-checkboxes'>
          {relevanceCheckboxes.map((item) => (
            <FormControlLabel
              key={item}
              onChange={(event) => {
                const { name } = event.target
                handleFilters('relevance', name)
              }}
              control={
                <Checkbox
                  checked={
                    filters.relevance ? filters.relevance.includes(item) : false
                  }
                  name={item}
                  color='default'
                  value={item}
                  icon={<span className='icon-checkbox-empty' />}
                  checkedIcon={<span className='icon-checkbox-checked' />}
                />
              }
              label={item}
            />
          ))}
        </FormGroup>
      </div>

      {(filters.currentTab === 'alerts' ||
        filters.currentTab === 'reports' ||
        filters.currentTab === 'scenarios') && (
        <div className='alert-types'>
          <h4>Types</h4>

          <GlobalAutocomplete
            filters={filters}
            handleFilters={handleFilters}
            filterOptions={filterOptions}
            type='types'
          />
        </div>
      )}

      <div>
        <DateRangePickerComponent
          selectedDateRange={filters?.dateRange || []}
          handleFilters={handleFilters}
        />
      </div>

      {filters.currentTab !== 'ioc' && (
        <div className='sector'>
          <h4>Sectors</h4>

          <GlobalAutocomplete
            filters={filters}
            handleFilters={handleFilters}
            filterOptions={filterOptions}
            type='sectors'
          />
        </div>
      )}

      {filters.currentTab === 'scenarios' && (
        <div className='sector'>
          <h4>Frameworks</h4>

          <GlobalAutocomplete
            filters={filters}
            handleFilters={handleFilters}
            filterOptions={filterOptions}
            type='frameworks'
          />
        </div>
      )}
    </>
  )
}

GlobalFilters.displayName = 'GlobalFilters'

/**
 * The properties.
 * @type {Object}
 */

GlobalFilters.propTypes = {
  filters: PropTypes.instanceOf(Object).isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleResetFilters: PropTypes.func.isRequired,
  filterOptions: PropTypes.instanceOf(Object).isRequired,
  orderBy: PropTypes.string.isRequired,
  orderChange: PropTypes.func.isRequired,
  searchFilterOpen: PropTypes.bool.isRequired,
  setSearchFilterOpen: PropTypes.func.isRequired,
}

export default GlobalFilters
