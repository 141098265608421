import { Snackbar } from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import {
  groupList,
  organisationUsers,
  sectorsList,
  singleOrganisation,
} from '../../../../shared/services/api/instances/instances'
import './EditOrganisation.scss'
import { initialTlp, initialState } from './data'
import OrganisationNotes from './sections/OrganisationNotes'
import OrganisationGroups from './sections/OrganisationGroups/OrganisationGroups'
import OrganisationUsers from './sections/OrganisationUsers/OrganisationUsers'
import OrganisationSectors from './sections/OrganisationSectors'
import OrganisationModuleAccess from './sections/OrganisationModuleAccess'
import Spinner from '../../../../shared/Spinner'
import { Button } from '@mui/material'
import {
  addNewOrganisation,
  editOrganisation,
  fetchAvailableGroups,
} from '../../../../shared/services/api/editOrganisation/editOrganisation'
import MuiAlert from '@material-ui/lab/Alert'
import OrganisationSettings from './sections/OrganisationSettings'
import OrganisationMisp from './sections/OrganisationMisp'
import cloneDeep from 'lodash/cloneDeep'

const EditOrganisation = ({ setEditMode, instanceId }) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { id } = useParams()
  const titleRefs = useRef({})
  const descriptionRefs = useRef({})
  const titleInput = useRef(null)
  const notesEditorRef = useRef(null)
  const isCreateNew = id === 'create'
  const [organisationState, setOrganisationState] = useState(
    isCreateNew ? cloneDeep(initialState) : {},
  )
  const [allGroups, setAllGroups] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [sectorsOptions, setSectorsOptions] = useState([])
  const [isSaving, setSaving] = useState(false)
  const [availableUserGroups, setAvailableUserGroups] = useState({})
  const [usersLoading, setUsersLoading] = useState(false)
  const [toastMessage, setToastMessage] = useState(undefined)
  const history = useHistory()
  const location = useLocation()

  const saveOrganisation = async (saveBeforeEditUser) => {
    setSaving(true)
    try {
      let response
      const orgData = { ...organisationState }
      orgData.default_group_settings.include_new_users = true
      delete orgData.users

      if (isCreateNew) {
        response = await addNewOrganisation(token(), instanceId, orgData)

        const currentPath = location.pathname.split('/').slice(0, -1).join('/')

        response.data && history.push(currentPath + '/' + response.data.id)
      } else {
        response = await editOrganisation(token(), instanceId, id, orgData)
      }

      if (response.error) {
        let errors = []
        Object.values(response.errors) &&
          Object.values(response.errors).forEach((er) => {
            errors = [...errors, er]
          })
        setToastMessage(['error', errors.join(', ')])
        setSaving(false)
        return false
      }

      setSaving(false)
      setToastMessage(['success', response.message])

      if (saveBeforeEditUser) {
        return true
      } else {
        history.push(`/app/admin/instances/${instanceId}/organisations`)
      }
    } catch (err) {}
  }

  const getOrganisationData = async () => {
    try {
      const response = await singleOrganisation(token(), instanceId, id)
      const sections = {
        id: response.data.id,
        name: response.data.name,
        sectors: response.data.sectors,
        notes: response.data.notes,
        other_groups_settings: response.data.other_groups_settings,
        modules: response.data.modules,
        misp_instance: response.data.misp_instance,
        is_chat_enabled: response.data.is_chat_enabled,
        dont_show_authors: response.data.dont_show_authors,
        is_pdf_upload_enabled: response.data.is_pdf_upload_enabled,
        is_notification_system_enabled:
          response.data.is_notification_system_enabled,
        default_group_settings: {
          include_new_users:
            response.data.default_group_settings.include_new_users,
          make_anonymous: response.data.default_group_settings.make_anonymous,
        },
        tlp_classifications: response.data.tlp_classifications || initialTlp,
      }
      getOrganisationUsers(sections)
    } catch (err) {}
  }

  const getSectorsOptions = async () => {
    try {
      const response = await sectorsList(token())
      setSectorsOptions(response.list)

      !isCreateNew ? await getAvailableUserGroups() : setLoading(false)
    } catch (err) {}
  }

  const getOrganisationUsers = async (data, fetchingNew, payload) => {
    setUsersLoading(true)
    const expandedUsers = {}
    data?.users?.data?.forEach(({ id, is_expanded }) => {
      expandedUsers[id] = { is_expanded: is_expanded || false }
    })

    try {
      const response = await organisationUsers(token(), instanceId, id, payload)
      response?.list?.data?.forEach(
        (user) => (user.is_expanded = expandedUsers[user.id]?.is_expanded),
      )
      const newState = { ...data, users: response?.list || [] }

      setOrganisationState(newState)
      setUsersLoading(false)
      !fetchingNew && (await getAllGroups())
    } catch (err) {}
  }

  const fetchNewUsers = async (payload) => {
    const query = { ...payload }
    if (query.page === 1) delete query.page
    if (query.search === '') delete query.search
    await getOrganisationUsers(
      organisationState,
      true,
      new URLSearchParams(query).toString(),
    )
  }

  const getAllGroups = async () => {
    try {
      const response = await groupList(token())
      setAllGroups(response.list)

      getSectorsOptions()
    } catch (err) {
      setLoading(false)
    }
  }

  const getAvailableUserGroups = async () => {
    try {
      const response = await fetchAvailableGroups(token(), instanceId, id)
      setAvailableUserGroups(response)

      setLoading(false)
    } catch (err) {}
  }

  const handleOrganisationState = (prop, value, option, groupOptionValue) => {
    const currentState = { ...organisationState }

    if (prop === 'modules') {
      if (currentState[prop].includes(value)) {
        currentState[prop] = currentState[prop].filter((item) => item !== value)
      } else {
        currentState[prop] = [...currentState[prop], value]
      }
    } else if (prop === 'name' || prop === 'notes') {
      currentState[prop] = value
    } else if (prop === 'settings') {
      if (
        option === 'is_notification_system_enabled' ||
        option === 'dont_show_authors' ||
        option === 'is_pdf_upload_enabled' ||
        option === 'is_chat_enabled'
      ) {
        currentState[option] = value
      } else {
        currentState.default_group_settings[option] = value
      }
    } else if (prop === 'tlp_classifications') {
      const cols = ['clear', 'green', 'amber', 'amber_strict', 'red']
      cols.forEach((color) => {
        currentState[prop][color] = {
          title: titleRefs.current[color].value,
          description: descriptionRefs.current[color].value,
        }
      })
    } else if (prop === 'sectors') {
      if (value.id) {
        currentState[prop] = [...currentState[prop], value.id]
      } else {
        currentState[prop] = value
      }
    } else if (prop === 'other_groups_settings') {
      if (groupOptionValue === undefined) {
        if (value.id) {
          currentState[prop] = { ...currentState[prop], [value.id]: value }
        } else {
          delete currentState[prop][value]
        }
      } else {
        currentState[prop][value].settings[option] = groupOptionValue
      }
    } else if (prop === 'users') {
      const currentUser = currentState[prop].data.find(
        (user) => user.id === value,
      )
      currentUser.is_expanded = !currentUser.is_expanded
    } else if (prop === 'misp_instance') {
      currentState[prop][option] = value
    }

    setOrganisationState(currentState)
  }

  const allUsersExpanded = () =>
    organisationState?.users?.data?.every((user) => user.is_expanded)

  const handleAllUsers = () => {
    const currentState = { ...organisationState }

    if (allUsersExpanded()) {
      currentState?.users?.data?.forEach((user) => (user.is_expanded = false))
    } else {
      currentState?.users?.data?.forEach((user) => (user.is_expanded = true))
    }
    setOrganisationState(currentState)
  }

  useEffect(() => {
    setEditMode(true)
    isCreateNew ? getAllGroups() : getOrganisationData()
  }, [])

  useEffect(() => {
    if (isCreateNew && organisationState?.name?.length > 0) {
      saveOrganisation(true)
    }
  }, [organisationState.name])

  return (
    <div className='edit-organisation'>
      {isLoading && <Spinner center size={50} border={1} />}

      {toastMessage && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setToastMessage(undefined)}
          open={toastMessage !== undefined}
        >
          <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
            {toastMessage[1]}
          </MuiAlert>
        </Snackbar>
      )}

      {!isLoading && (
        <>
          <div
            className='edit-organisation__left-header'
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 20,
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ fontSize: 34 }} className='icon-office' />

              <h2>
                {isCreateNew
                  ? 'Create New Organisation'
                  : `Edit Organisation - ${organisationState.name}`}
              </h2>
            </div>

            <div>
              <Button
                className='finish-new'
                onClick={() => saveOrganisation(true)}
                disabled={isSaving}
                style={{
                  minWidth: 90,
                  backgroundColor: '#6180a8',
                  marginRight: 20,
                }}
              >
                {isSaving ? (
                  <Spinner centerHorizontally size={22.5} color='#fff' />
                ) : (
                  'Save'
                )}
              </Button>

              <Button
                className='finish-new'
                onClick={() => saveOrganisation()}
                disabled={isSaving}
                style={{ minWidth: 158 }}
              >
                {isSaving ? (
                  <Spinner centerHorizontally size={22.5} color='#fff' />
                ) : isCreateNew ? (
                  'Create & Close'
                ) : (
                  'Save & Close'
                )}
              </Button>
            </div>
          </div>

          <div className='edit-organisation__container'>
            <div className='edit-organisation__left'>
              <div className='edit-organisation__left-content'>
                <OrganisationNotes
                  name={organisationState.name}
                  notesEditorRef={notesEditorRef}
                  text={organisationState.notes}
                  titleInput={titleInput}
                  handleOrganisationState={handleOrganisationState}
                />

                <OrganisationSectors
                  sectorsOptions={sectorsOptions}
                  sectors={organisationState.sectors}
                  handleOrganisationState={handleOrganisationState}
                />

                <div style={{ display: 'flex', gap: 15 }}>
                  <OrganisationSettings
                    default_group_settings={{
                      ...organisationState.default_group_settings,
                      is_pdf_upload_enabled:
                        organisationState.is_pdf_upload_enabled,
                      dont_show_authors: organisationState.dont_show_authors,
                      is_notification_system_enabled:
                        organisationState.is_notification_system_enabled,
                      is_chat_enabled: organisationState.is_chat_enabled,
                    }}
                    tlp_state={organisationState.tlp_classifications}
                    handleOrganisationState={handleOrganisationState}
                    titleRefs={titleRefs}
                    descriptionRefs={descriptionRefs}
                  />

                  <OrganisationModuleAccess
                    modules={organisationState.modules}
                    handleOrganisationState={handleOrganisationState}
                  />

                  <OrganisationMisp
                    data={organisationState.misp_instance}
                    handleOrganisationState={handleOrganisationState}
                    options={organisationState.users?.data || []}
                  />
                </div>
              </div>
            </div>

            <div className='edit-organisation__right'>
              <div className='edit-organisation__right-content'>
                <OrganisationGroups
                  groups={organisationState.other_groups_settings || {}}
                  allGroups={allGroups}
                  handleOrganisationState={handleOrganisationState}
                  saveOrganisation={saveOrganisation}
                  fetchNewUsers={fetchNewUsers}
                  getAvailableUserGroups={getAvailableUserGroups}
                />

                <OrganisationUsers
                  users={organisationState.users || []}
                  handleOrganisationState={handleOrganisationState}
                  instanceId={instanceId}
                  orgId={id}
                  isOrganisationChatEnabled={organisationState.modules.includes(
                    12,
                  )}
                  isOrganisationForumEnabled={organisationState.modules.includes(
                    13,
                  )}
                  availableUserGroups={availableUserGroups}
                  fetchNewUsers={fetchNewUsers}
                  usersLoading={usersLoading}
                  setToastMessage={setToastMessage}
                  groups={
                    allGroups?.filter(
                      ({ id }) =>
                        organisationState?.other_groups_settings &&
                        Object.keys(organisationState.other_groups_settings)
                          .map((i) => +i)
                          .includes(id),
                    ) || []
                  }
                  saveOrganisation={saveOrganisation}
                  getAvailableUserGroups={getAvailableUserGroups}
                  groupsIncludeNewUsers={
                    organisationState.other_groups_settings
                  }
                  notificationsEnabled={
                    organisationState.is_notification_system_enabled
                  }
                  chosenModules={organisationState.modules || []}
                  handleAllUsers={handleAllUsers}
                  allUsersExpanded={allUsersExpanded}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default EditOrganisation
