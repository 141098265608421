/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import './WorkspaceTabs.scss'
import Button from '@material-ui/core/Button'
import { Tooltip } from '@material-ui/core'
import { useGlobalContext } from '../../context/globalContext'
import { useViewStateContext } from '../../context/viewStateContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useHover } from '../../hooks/useHover'

const WorkspaceTabs = ({ item }) => {
  const { linkAnalysisOpen, workspaces, setWorkspaces } = useViewStateContext()
  const isWorkspace = location.pathname.startsWith('/app/workspace')
  const history = useHistory()
  const [ref, hovered] = useHover()
  const { setIsModalOpen, confirmAutosaveModal, setConfirmAutosaveModal } =
    useGlobalContext()

  return (
    <Button
      disableRipple
      variant='contained'
      key={item.id}
      value={item.id}
      color='default'
      className={isWorkspace && item.active ? 'tab active' : 'tab'}
      onClick={() => {
        const currentState = [...workspaces]

        currentState.forEach((w) => (w.active = false))
        currentState.find((w) => w.id === item.id).active = true
        setWorkspaces(currentState)
        location.pathname !== '/app/workspace' && history.push('/app/workspace')
        confirmAutosaveModal === 'change' && setConfirmAutosaveModal(true)
      }}
      endIcon={
        item.id !== 0 && (
          <span
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()

              if (item.state === 'dashboard') return false

              if (linkAnalysisOpen && item.state === 'analysis') {
                setIsModalOpen(true)
              } else {
                let currentActive = workspaces.filter((item) => item.active)
                let newWorkspaces = [...workspaces]

                if (item.id === currentActive[0]?.id) {
                  newWorkspaces.forEach((w) => {
                    w.active = false
                    if (w.id === 0) {
                      w.active = true
                    }
                  })
                }

                newWorkspaces = workspaces.filter((w) => w.id !== item.id)
                setWorkspaces(newWorkspaces)
              }
            }}
            className='icon-close'
          />
        )
      }
    >
      <Tooltip
        title={
          ref.current && ref.current.scrollWidth > ref.current.clientWidth
            ? item.name
            : ''
        }
        arrow
      >
        <div
          ref={ref}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            alignItems: 'center',
          }}
        >
          {item.id !== 0 && (
            <span
              className={`icon-type icon-${
                !item.state || item.state.length === 0 ? 'eye' : item.state
              }`}
              style={{ fontSize: 17, marginRight: 22 }}
            />
          )}

          <span>{item.name}</span>
        </div>
      </Tooltip>
    </Button>
  )
}

export default WorkspaceTabs
