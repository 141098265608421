/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { Divider, InputAdornment, OutlinedInput } from '@material-ui/core'
import SingleForumSetup from './SingleForumSetup'
import Spinner from '../../../shared/Spinner'
import { useQueryGetForumCategoriesAdmin } from '../../../shared/services/queries/forum-admin/useQueryGetForumCategories'
import { useQueryGetAdminForums } from '../../../shared/services/queries/forum-admin/useQueryGetAdminForums'

interface CategoryData {
  color: string
  created_at: number
  description: string
  id: number
  name: string
  pivot: { forum_id: number; category_id: number }
  slug: string
  updated_at: number
  threads_count: number
}
interface ForumData {
  categories: CategoryData[]
  created_at: string
  deleted_at: string | null
  id: number
  is_enabled: boolean
  last_post_at: string
  name: string
  total_posts: number
  total_threads: number
  updated_at: string
}

const ForumsSetup = () => {
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const categories = useQueryGetForumCategoriesAdmin()
  const forums = useQueryGetAdminForums()

  return (
    <div className='login-history'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Assign Categories to Forums</h2>
      </div>

      <Divider />

      <div style={{ width: 'calc(100% - 170px)', marginBottom: 20 }}>
        <h5 style={{ position: 'absolute', top: '-20px' }}>Search</h5>

        <div>
          <OutlinedInput
            // variant='outlined'
            placeholder='Search'
            value={searchPhrase}
            inputProps={{
              style: { padding: '14px 14px 14px 0' },
            }}
            onChange={(event) => setSearchPhrase(event.target.value)}
            startAdornment={
              <InputAdornment position='start'>
                <span style={{ fontSize: 26 }} className='icon-search' />
              </InputAdornment>
            }
          />
        </div>
      </div>

      <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
        {forums.isLoading ? (
          <Spinner centerHorizontally size={30} border={1} />
        ) : null}

        {forums?.data?.data?.forums
          ?.filter(
            ({ name }: { name: string }) =>
              name?.toLowerCase().includes(searchPhrase?.toLowerCase()),
          )
          .map((forum: ForumData) => (
            <SingleForumSetup
              key={forum?.id}
              data={forum}
              categoriesOptions={categories?.data?.data?.categories}
            />
          ))}
      </div>
    </div>
  )
}

export default ForumsSetup
