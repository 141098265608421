export const iocTypes = [
  'md5',
  'sha-1',
  'sha-256',
  'sha-512',
  'ssdeep',
  'tlsh',
  'filename',
  'mac address',
  'ipv4 address',
  'ipv6 address',
  'domain',
  'hostname',
  'email address',
  'email message',
  'email subject',
  'url',
  'uri',
  'http method',
  'user-agent',
  'mutex',
  'text',
  'other',
]
