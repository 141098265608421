import React from 'react'
import Spin from 'react-cssfx-loading/lib/Spin'
import './Spinner.scss'

interface SpinnerProps {
  readonly center?: boolean
  readonly centerHorizontally?: boolean
  readonly padding?: number
  readonly size?: number
  readonly color?: string
  readonly border?: number
}

const Spinner = ({
  center,
  centerHorizontally,
  padding,
  size = 30,
  border,
  color,
}: SpinnerProps) => {
  return (
    <div
      style={{ padding: padding && padding }}
      className={`loader-spinner${center ? ' center' : ''}${
        centerHorizontally ? ' center-horizontally' : ''
      }`}
    >
      <Spin
        width={size + 'px'}
        height={size + 'px'}
        style={{ borderWidth: border || 1, borderTopColor: color && color }}
      />
    </div>
  )
}

// Spinner.defaultProps = {
//   center: false,
//   centerHorizontally: false,
//   padding: 0,
//   size: 30,
//   color: 'red',
//   border: 1,
// } as Partial<SpinnerProps>;

export default Spinner
