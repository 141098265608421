import { IconButton, Button, Tooltip } from '@mui/material'
import GroupsTable from './OrganisationUsers/GroupsTable'
import { modules, roles } from './OrganisationUsers/data'
import { Announcement, DeleteForever, Send } from '@material-ui/icons'

const SingleUser = ({
  user,
  setUserDialogOpen,
  handleExpandedUsers,
  groupsList,
  defaultGroupId,
  setConfirmDelete,
  handleResendLink,
  groups,
  saveOrganisation,
}) => {
  return (
    <>
      <tr>
        <td>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                startIcon={
                  <span
                    style={{
                      fontSize: 13,
                      verticalAlign: 'middle',
                      display: 'inline-block',
                      transform: user.is_expanded ? 'rotate(180deg)' : '',
                    }}
                    className='icon-down'
                  />
                }
                variant='primary'
                disableRipple
                onClick={() => handleExpandedUsers(user.id)}
                style={{
                  textAlign: 'left',
                  textTransform: 'capitalize',
                  color: '#000',
                  borderRadius: 12,
                  fontSize: 12,
                  padding: '6px 12px',
                }}
              >
                <span>{`${user.first_name} ${user.last_name}`}</span>
              </Button>

              {!user.is_account_activated && (
                <Tooltip
                  placement='top'
                  arrow
                  title='User has not activated their account yet'
                >
                  <Announcement
                    style={{ color: 'red', fontSize: 18, marginLeft: 6 }}
                  />
                </Tooltip>
              )}
            </div>

            <span style={{ marginLeft: 30, fontSize: 9, fontWeight: 500 }}>
              Last login: {user.last_login_at || '-'}{' '}
            </span>
          </div>
        </td>

        {modules.map((module) => {
          let doesModulePermissionExist =
            user?.privileges[defaultGroupId]?.module_access[module.id]

          if (doesModulePermissionExist?.role_id === 9999) {
            doesModulePermissionExist.role_id = 1
          }

          const roleName =
            doesModulePermissionExist &&
            roles.find((role) => role.id === doesModulePermissionExist.role_id)
          return (
            <td
              style={{ textAlign: 'center', fontSize: 12, fontWeight: 500 }}
              key={module.id}
            >
              <span
                style={{
                  padding: '8px 16px',
                  borderRadius: 12,
                  backgroundColor:
                    roleName?.id === 3
                      ? 'rgb(255 0 51 / 30%)'
                      : roleName?.id === 2
                      ? 'rgb(255 191 0 / 30%)'
                      : roleName?.id === 1 && 'rgb(58 170 53 / 30%)',
                }}
              >
                {roleName?.name || '-'}
              </span>
            </td>
          )
        })}

        <td style={{ textAlign: 'center', paddingRight: 8 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {!user.is_account_activated && (
              <Tooltip placement='top' arrow title='Resend activation link'>
                <IconButton
                  onClick={() => handleResendLink(user.id)}
                  style={{ padding: 7 }}
                >
                  <Send style={{ fontSize: 19, color: '#006fff' }} />
                </IconButton>
              </Tooltip>
            )}

            <Button
              variant='default'
              disableRipple
              startIcon={
                <span style={{ fontSize: 16 }} className='icon-edit' />
              }
              style={{
                textTransform: 'capitalize',
                padding: '5px 18px',
                borderRadius: 12,
                fontSize: 13,
                backgroundColor: '#006fff',
                color: '#fff',
                margin: '0 4px',
              }}
              onClick={async () => {
                const isSaved = await saveOrganisation(true)

                isSaved && setUserDialogOpen(user.id)
              }}
            >
              Edit
            </Button>

            <Tooltip placement='top' arrow title='Delete user'>
              <IconButton
                onClick={() => setConfirmDelete(user.id)}
                style={{ padding: 7 }}
              >
                <DeleteForever style={{ fontSize: 19, color: '#ff0000' }} />
              </IconButton>
            </Tooltip>
          </div>
        </td>
      </tr>

      {user.is_expanded && (
        <GroupsTable
          privileges={user.privileges}
          groupsList={groupsList.filter((group) => group.id !== defaultGroupId)}
          defaultGroupId={defaultGroupId}
          chosenGroups={groups || []}
        />
      )}
    </>
  )
}

export default SingleUser
