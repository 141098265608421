/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { apiUrl } from '../../../../../shared/config/index'
import { Button, Dialog, IconButton, TextField } from '@mui/material'

export const SortableImage = (props) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [hoverIndex, setHoverIndex] = useState(null)
  const sortable = useSortable({ id: props.url })
  const [captionDialog, setCaptionDialog] = useState(false)
  const captionRef = useRef(null)
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable

  const disableYaxis = (scale) => {
    if (scale && scale.y !== 0) {
      scale.y = 0
    }
    return scale
  }

  const inlineStyles = {
    transformOrigin: '0 0',
    minWidth: 250,
    maxWidth: '30%',
    height: 200,
    cursor: isDragging ? 'grabbing' : 'grab',
    gridRowStart: props.index === 0 ? 'span 2' : null,
    gridColumnStart: props.index === 0 ? 'span 2' : null,
    backgroundImage: `url(${apiUrl}/storage/module/content/pages/${
      props.url
    }?access_token=${token()})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'grey',
    boxShadow: '0px 4px 10px #00000029',
    position: 'relative',
    margin: 4,
    transform: CSS.Transform.toString(disableYaxis(transform)),
    transition,
  }

  return (
    <div
      onMouseEnter={() => setHoverIndex(props.index)}
      onMouseLeave={() => setHoverIndex(null)}
      className='sortable-image'
      ref={setNodeRef}
      style={inlineStyles}
      {...attributes}
      {...listeners}
    >
      {props.index === hoverIndex && (
        <div
          style={{
            right: 0,
            marginRight: '-15px',
            marginTop: '-15px',
            color: '#fd5068',
            position: 'absolute',
            fontSize: 42,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <IconButton
            onClick={() => setCaptionDialog(true)}
            style={{ padding: 0 }}
          >
            <span
              className='icon-edit'
              style={{
                color: '#fff',
                backgroundColor: '#fd5068',
                borderRadius: 20,
                padding: 2,
                fontSize: 22,
              }}
            />
          </IconButton>

          <span
            onClick={() => props.handleDeleteImage(props.index)}
            className='icon-circle-close'
            style={{ zIndex: 2 }}
          />

          <div
            style={{
              backgroundColor: '#fff',
              height: 15,
              width: 15,
              position: 'absolute',
              top: 13,
              left: 40,
            }}
          />
        </div>
      )}

      {captionDialog && (
        <Dialog open={captionDialog}>
          <div style={{ padding: '20px 25px' }}>
            <h4>Image Caption</h4>

            <TextField
              inputRef={captionRef}
              placeholder='Set image caption'
              variant='outlined'
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              defaultValue={props.caption}
              style={{ margin: '20px 0' }}
            />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={() => {
                  setHoverIndex(null)
                  setCaptionDialog(false)
                }}
                style={{ textTransform: 'capitalize', borderRadius: 12 }}
              >
                Cancel
              </Button>

              <Button
                onClick={() => {
                  props.handleCaption(hoverIndex, captionRef.current.value)
                  setCaptionDialog(false)
                }}
                style={{ textTransform: 'capitalize', borderRadius: 12 }}
              >
                Save
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  )
}
