import {
  cloneDeep,
  isArray,
  isObject,
  omit,
  transform,
  isBoolean,
} from 'lodash'
import isEqual from 'lodash/isEqual'
import {
  saveDraft,
  sendForApproval,
  denyContent,
} from '../../../../shared/services/api/createContent/createAlert'

const difference = (origObj, newObj) => {
  const changes = (newObj, origObj) => {
    let arrayIndexCounter = 0
    return transform(newObj, (result, value, key) => {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value
      }
    })
  }
  return changes(newObj, origObj)
}

export const saveDraftHandler = async (
  type,
  data,
  setSaving,
  setDenying,
  actionName,
  handleCancelCreate,
  setSendingForApproval,
  infoToast,
  successToast,
  errorToast,
) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const contentState = cloneDeep(data)
  infoToast({ message: 'Saving' })
  actionName === 'approval' && setSendingForApproval(true)
  actionName === 'deny' && setDenying(true)
  actionName === undefined && setSaving(true)

  // if (!isDistributionOrRelevanceChanged) {
  //   delete contentState.relevance
  //   delete contentState.content_distribution
  // }

  try {
    const response = await saveDraft(
      token(),
      type,
      contentState.id,
      contentState,
    )

    if (response.error) {
      errorToast({ message: response.message })
      setSaving(false)
      return false
    }

    if (actionName === 'approval') {
      await approveContent(
        type,
        contentState.id,
        handleCancelCreate,
        setSendingForApproval,
        errorToast,
      )
      setSaving(false)
    } else if (actionName === 'deny') {
      await denyContentHandler(
        type,
        contentState.id,
        setDenying,
        handleCancelCreate,
      )
      setSaving(false)
    } else {
      successToast({ message: response.message })
      setSaving(false)
    }

    return {
      statusName: response.statusName,
      status_id: response.status_id,
      visibilityIcon: response.visibilityIcon,
      visibilityName: response.visibilityName,
      tlpPreview: response?.tlpPreview,
      who_can_view: response?.who_can_view,
      who_can_edit: response?.who_can_edit,
      who_can_publish: response?.who_can_publish,
    }
  } catch (err) {
    console.log(err)
  }
}

export const denyContentHandler = async (
  type,
  id,
  setDenying,
  handleCancelCreate,
) => {
  try {
    const token = () => {
      return localStorage.getItem('access_token')
    }
    const response = await denyContent(token(), type, id)
    setDenying(false)
    handleCancelCreate(response.message)
  } catch (err) {}
}

export const approveContent = async (
  type,
  id,
  handleCancelCreate,
  setSendingForApproval,
  errorToast,
) => {
  try {
    const token = () => {
      return localStorage.getItem('access_token')
    }
    const response = await sendForApproval(token(), type, id)

    if (response.error) {
      let error = []
      if (response.errors && Object.values(response.errors)) {
        Object.values(response.errors).forEach(
          (err) => (error = [...error, err]),
        )
      } else {
        error = response.message
      }
      errorToast({ message: error })
      // setToastMessage(['error', error])
      setSendingForApproval(false)
      return false
    }

    setSendingForApproval(false)
    handleCancelCreate(response.message)
  } catch (err) {
    console.log(err)
  }
}

export const changesMade = async (
  isContentBeingEdited,
  actionValue,
  actionName,
  createContentState,
  dirtyCreateState,
) => {
  const createStateDeepClone = cloneDeep(createContentState)
  const dirtyCreateStateDeepClone = cloneDeep(dirtyCreateState)
  const diff = await difference(dirtyCreateStateDeepClone, createStateDeepClone)
  if (!isContentBeingEdited[1]) return false

  if (actionName === 'titleChange') {
    if (actionValue === '') return false
    if (actionValue === dirtyCreateStateDeepClone.dev_title) return false
    createStateDeepClone.dev_title = actionValue
  } else {
    delete diff?.dev_content?.title
    if (
      Object.keys(diff).length === 0 &&
      isEqual(createStateDeepClone, dirtyCreateStateDeepClone)
    )
      return false //if no changes in the entire object
    if (
      !isEqual(
        omit(createStateDeepClone, 'dev_content'),
        omit(dirtyCreateStateDeepClone, 'dev_content'),
      )
    )
      return true //if changes happen outside dev_content
    if (diff?.dev_content) {
      //if changes happen in dev_content
      let changedContent = {}
      Object.values(diff?.dev_content)?.forEach((section) => {
        if (section?.is_accepted || section?.is_rejected) {
          changedContent = section
        } else if (Object.keys(section)?.length) {
          if (section?.content && Object.keys(section.content)?.length) {
            changedContent = section.content
          }
        }
      })

      if (
        !Object.keys(changedContent)?.length ||
        (changedContent.content &&
          Object.keys(changedContent.content).length === 0)
      )
        return false
      const isSectionEvent =
        !changedContent.content &&
        (isBoolean(changedContent?.is_accepted) ||
          isBoolean(changedContent?.is_rejected))
      if (isSectionEvent) return true
      const isDevContentEqual = isEqual(
        createStateDeepClone.dev_content,
        dirtyCreateStateDeepClone.dev_content,
      )

      if (!isDevContentEqual) return true //checking equality
    }

    if (!isEqual(createStateDeepClone, dirtyCreateStateDeepClone)) return true
  }

  return true
}
