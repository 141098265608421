import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import './KnowledgeBaseCategory.scss'
import {
  KnowledgeBaseList,
  KnowledgeBaseTopBar,
  KnowledgeBaseView,
} from '../components'
import { useKnowledgeBaseContext } from '../../../context/knowledgeBaseContext'
import { useViewStateContext } from '../../../context/viewStateContext'

const KnowledgeBaseCategory = () => {
  const { category } = useParams()
  const { categories, setActiveLink } = useKnowledgeBaseContext()
  const { setKnowledgebaseLink } = useViewStateContext()

  useEffect(() => {
    setActiveLink(category)
    setKnowledgebaseLink(`knowledge-base/${category}`)
  }, [category])

  return (
    <div className='knowledge-base-category'>
      <KnowledgeBaseView />

      <div className='knowledge-base-category__content-wrapper'>
        {categories.length > 0 && <KnowledgeBaseTopBar />}

        <KnowledgeBaseList />
      </div>
    </div>
  )
}

export default KnowledgeBaseCategory
