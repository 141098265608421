import React, { createContext, useContext, useState } from 'react'
import { createStateHandler } from '../pages/Home/helpers'
import { useGlobalContext } from './globalContext'

const CreateManagementContext = createContext({
  createContentState: {},
  handleCreateState: () => {},
  setCreateContentState: () => {},
  mitreConfig: {},
  setMitreConfig: () => {},
})

// eslint-disable-next-line react/prop-types
export const CreateManagementContextProvider = ({ children }) => {
  const [createContentState, setCreateContentState] = useState({})
  const {
    setConfirmAutosaveModal,
    isContentBeingEdited,
    setIsContentBeingEdited,
  } = useGlobalContext()
  const [mitreConfig, setMitreConfig] = useState({})

  const handleCreateState = (
    section,
    data,
    isOutsideDevContent,
    isExpandedEvent,
  ) => {
    const currentState = { ...createContentState }

    createStateHandler(
      currentState,
      section,
      data,
      isOutsideDevContent,
      isExpandedEvent,
      isContentBeingEdited,
      setIsContentBeingEdited,
      setCreateContentState,
    )
    setConfirmAutosaveModal('change')
  }

  const props = {
    createContentState,
    handleCreateState,
    setCreateContentState,
    mitreConfig,
    setMitreConfig,
  }
  const Provider = CreateManagementContext.Provider

  return <Provider value={props}>{children}</Provider>
}

export const useCreateManagementContext = () => {
  return useContext(CreateManagementContext)
}
