import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import './KnowledgeBase.scss'
import { useKnowledgeBaseContext } from '../../context/knowledgeBaseContext'
import { getFilteredArticles } from '../../shared/services/api/knowledgebase/knowledgebase'
import { Autocomplete, TextField } from '@mui/material'

const token = () => {
  return localStorage.getItem('access_token')
}

const fetchSearchedArticles = async (keyword) => {
  try {
    const response = await getFilteredArticles(token(), keyword)

    return response
  } catch (err) {
    // setLoading(false)
    console.log(err)
  }
}

const AutocompleteSearch = ({ isOutlined }) => {
  const { categories } = useKnowledgeBaseContext()
  const [searchedArticles, setSearchArticles] = useState(null)
  const history = useHistory()

  const handleInputChange = (event) => {
    const articleTitle = event.target.textContent
    const articleSlug = searchedArticles.articles.find(
      (article) => article.title === articleTitle,
    )?.slug
    let slug

    // const isArtcileWithoutSubcategory = categories
    //   .find((category) => category.slug === activeLink.split('/')[0])
    //   ?.articles.find((articleItem) => articleItem.slug === articleSlug)

    categories.find((category) => {
      const findCategory = category?.articles.find(
        (articleItem) => articleItem.slug === articleSlug,
      )
      if (findCategory) {
        slug = `${category.slug}/${articleSlug}`
      }
    })

    // else {
    //   const findCategory = category?.child_categories.forEach(
    //     (subcategory) => {
    //       const findSubcategory = subcategory?.articles?.find(
    //         (articleItem) => articleItem.slug === articleSlug
    //       )

    //       if (findSubcategory) {
    //         slug = `${category.slug}/${subcategory.slug}/${articleSlug}`
    //       }
    //     }
    //   )
    // }

    history.push(`/app/knowledge-base/${slug}`)
  }

  useEffect(() => {
    ;(async () => {
      setSearchArticles(await fetchSearchedArticles(''))
    })()
  }, [])

  return (
    <Autocomplete
      style={{ position: 'relative', width: isOutlined ? '50%' : '' }}
      getOptionLabel={(option) => option}
      options={
        searchedArticles?.articles?.map((article) => article.title) ?? ['']
      }
      onChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Search by keyword    '
          variant={isOutlined ? 'outlined' : 'standard'}
          className='tag-input'
          size='small'
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <span className='icon-search' style={{ fontSize: '25px' }} />
            ),
          }}
        />
      )}
    />
  )
}

export default AutocompleteSearch
