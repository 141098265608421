/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import MuiDatePicker from './MuiDatePicker'
import { formatGlobalSearch } from '../helpers/formatDate'

const DateRangePicker = ({ selectedDateRange, handleFilters, inline }) => {
  const onDateRangeChange = (dateInput, value) => {
    handleFilters(inline || 'dateRange', {
      ...selectedDateRange,
      [dateInput]: value ? formatGlobalSearch(value) : '',
    })
  }

  return (
    <div style={{ display: inline ? 'flex' : '', gap: inline ? 20 : 0 }}>
      <div>
        <h4
          style={{
            color: 'white',
            marginTop: inline ? 0 : 16,
            marginBottom: -8,
          }}
        >
          Start date:
        </h4>

        <MuiDatePicker
          enableFutureDate={false}
          initialDate={selectedDateRange?.from}
          maxDate={selectedDateRange?.to}
          value={selectedDateRange?.from}
          onDateRangeChange={onDateRangeChange}
          dateLabel='from'
        />
      </div>

      <div>
        <h4
          style={{
            color: 'white',
            marginTop: inline ? 0 : 16,
            marginBottom: -8,
          }}
        >
          End date:
        </h4>

        <MuiDatePicker
          initialDate={selectedDateRange?.to}
          minDate={selectedDateRange?.from}
          enableFutureDate
          enablePastDate
          onDateRangeChange={onDateRangeChange}
          dateLabel='to'
        />
      </div>
    </div>
  )
}

export default DateRangePicker
