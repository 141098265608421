import React, { useEffect, useState, Fragment } from 'react'
import {
  Button,
  Checkbox,
  Select,
  FormControl,
  MenuItem,
  TextField,
} from '@material-ui/core'
import Spinner from '../../../../shared/Spinner'
import { useHistory, useParams } from 'react-router-dom'
import {
  singleFramework,
  addFramework,
  editFramework,
  frameworkTactics,
} from '../../../../shared/services/api/admin/settings/scenarioConfigFrameworks'
import cloneDeep from 'lodash/cloneDeep'
import useNotification from '../../../../hooks/useNotification'

const EditScenarioFramework = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const history = useHistory()
  const { id } = useParams()
  const [isLoading, setLoading] = useState(true)
  const [tactics, setTactics] = useState([])
  const [data, setData] = useState({})
  const [isAdding, setAdding] = useState(false)
  const { successToast, errorToast } = useNotification()
  const stageFields = [
    { name: 'Name', slug: 'name' },
    {
      name: 'Assign Enterprise Categories',
      slug: 'tactics',
      tacticType: 'Enterprise',
    },
    { name: 'Assign ICS Categories', slug: 'tactics', tacticType: 'ICS' },
    { name: 'Assign Mobile Categories', slug: 'tactics', tacticType: 'Mobile' },
    { name: 'Tooltip Text', slug: 'tooltip' },
  ]

  const MenuProps = {
    PaperProps: { style: { width: 250, maxWidth: 250 } },
  }

  const getFrameworkDetails = async () => {
    try {
      const response = await singleFramework(token(), id)
      setData(response.data)

      getFrameworkTactics()
    } catch (err) {}
  }

  const getFrameworkTactics = async () => {
    try {
      const response = await frameworkTactics(token())
      setTactics(response.list)
      setLoading(false)
    } catch (err) {}
  }

  const submitFrameworkData = async () => {
    setAdding(true)
    try {
      let response = ''
      if (id === 'new') {
        response = await addFramework(token(), data)
      } else {
        response = await editFramework(token(), id, data)
      }

      if (response.error) {
        errorToast({ message: response.message })
        setAdding(false)

        return false
      }

      successToast({ message: response.message })
      setAdding(false)

      if (id === 'new') {
        history.push(
          '/app/admin/system-settings/scenario-configuration/frameworks',
        )
      }
    } catch {}
  }

  useEffect(() => {
    if (id !== 'new') {
      getFrameworkDetails()
    } else {
      getFrameworkTactics()
    }
  }, [])

  const handleChangeFrameworkData = (stageIndex, stageProp, stageValue) => {
    const currentData = { ...data }

    if (stageProp) {
      if (currentData.stages === undefined) {
        currentData.stages = []
      }

      const currentStage = currentData.stages[stageIndex]

      if (stageProp === 'tactics') {
        if (currentStage[stageProp].includes(stageValue)) {
          currentStage[stageProp] = currentStage[stageProp].filter(
            (stage) => stage !== stageValue,
          )
        } else {
          currentStage[stageProp] = [...currentStage[stageProp], stageValue]
        }
      } else {
        currentStage[stageProp] = stageValue
      }
    } else {
      currentData.name = stageIndex
    }

    setData(currentData)
  }

  const currentTactic = (name) => {
    return tactics.find((t) => t.name === name).tactics
  }

  const returnValue = (currentTactic, chosenTactics) => {
    let objFromArr = []
    chosenTactics.forEach((tactic) => {
      const isTactic = currentTactic.find((t) => t.id === tactic)
      if (isTactic) {
        objFromArr = [...objFromArr, { id: tactic, name: isTactic.name }]
      }
    })

    return objFromArr
  }

  const usedTacticsInOtherStages = (index) => {
    const currentState = cloneDeep(data)
    let allTactics = []
    currentState?.stages?.splice(index, 1)

    currentState?.stages?.forEach((stage) => {
      allTactics = [...allTactics, ...stage.tactics]
    })
    return allTactics
  }

  return isLoading ? (
    <Spinner centerHorizontally padding={20} size={30} />
  ) : (
    <div className='edit-admin'>
      <h2 style={{ marginBottom: 10 }}>
        {id === 'new' ? 'Add Framework' : 'Edit Framework'}
      </h2>

      <div className='edit-admin__form'>
        <div style={{ width: '100%', margin: '6px 0' }}>
          <h5 style={{ marginBottom: 5 }}>Name</h5>

          <TextField
            fullWidth
            variant='outlined'
            placeholder='Name'
            value={data.name || ''}
            required
            onChange={(event) => handleChangeFrameworkData(event.target.value)}
          />
        </div>

        <div style={{ padding: 10, border: '1px solid #ededed' }}>
          <h3 style={{ marginBottom: 20 }}>Stages</h3>

          {data?.stages?.map((stage, index) => (
            <div key={index}>
              <div
                style={{
                  backgroundColor: '#006fff',
                  padding: '8px 12px',
                  borderRadius: 8,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3 style={{ fontWeight: 400, color: '#fff' }}>
                  Stage: <b>{stage.name}</b>
                </h3>

                <Button
                  onClick={() => {
                    const currentState = { ...data }

                    currentState.stages.splice(index, 1)
                    setData(currentState)
                  }}
                  style={{
                    color: '#fff',
                    minWidth: 'unset',
                    padding: '8px 10px',
                  }}
                  startIcon={<span className='icon-close' />}
                />
              </div>

              <div
                style={{
                  padding: 16,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {stageFields.map((field) => {
                  if (field.slug === 'name') {
                    return (
                      <Fragment key={field.name}>
                        <h5 style={{ marginBottom: 5 }}>{field.name}</h5>

                        <TextField
                          value={stage.name || ''}
                          variant='outlined'
                          placeholder='Name'
                          onChange={(event) =>
                            handleChangeFrameworkData(
                              index,
                              'name',
                              event.target.value,
                            )
                          }
                        />
                      </Fragment>
                    )
                  } else if (field.slug === 'tooltip') {
                    return (
                      <Fragment key={field.name}>
                        <h5 style={{ margin: '15px 0 5px 0' }}>{field.name}</h5>

                        <TextField
                          multiline
                          value={stage.tooltip || ''}
                          variant='outlined'
                          rows={4}
                          placeholder='Tooltip'
                          onChange={(event) =>
                            handleChangeFrameworkData(
                              index,
                              'tooltip',
                              event.target.value,
                            )
                          }
                        />
                      </Fragment>
                    )
                  } else if (field.slug === 'tactics') {
                    return (
                      <Fragment key={field.name}>
                        <h5 style={{ margin: '15px 0 5px 0' }}>{field.name}</h5>

                        <FormControl>
                          <Select
                            multiple
                            displayEmpty
                            className='type-select'
                            // renderValue={params.value === '' ? () => <span style={{ color: '#aaa' }}>Select reason</span> : undefined}
                            value={returnValue(
                              currentTactic(field.tacticType),
                              stage.tactics,
                            )}
                            onChange={(event) => {
                              const newValue = event.target.value.pop()
                              handleChangeFrameworkData(
                                index,
                                'tactics',
                                newValue,
                              )
                            }}
                            variant='outlined'
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return (
                                  <span style={{ color: '#aaa' }}>
                                    Assign categories
                                  </span>
                                )
                              } else {
                                let string = ''
                                selected.forEach((item, index) => {
                                  if (index + 1 === selected.length) {
                                    string = string + item.name
                                  } else {
                                    string = string + item.name + ', '
                                  }
                                })
                                return string
                              }
                            }}
                            MenuProps={MenuProps}
                          >
                            {currentTactic(field.tacticType)
                              .filter(
                                (tactic) =>
                                  !usedTacticsInOtherStages(index).includes(
                                    tactic.id,
                                  ),
                              )
                              .map((tactic) => (
                                <MenuItem
                                  key={tactic.id}
                                  value={tactic.id}
                                  style={{ padding: 2 }}
                                  disableRipple
                                >
                                  <Checkbox
                                    style={{ padding: 4 }}
                                    checked={stage.tactics.includes(tactic.id)}
                                    icon={
                                      <span className='icon-checkbox-empty' />
                                    }
                                    checkedIcon={
                                      <span className='icon-checkbox-checked' />
                                    }
                                  />

                                  <span style={{ fontSize: 12 }}>
                                    {tactic.name}
                                  </span>
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Fragment>
                    )
                  }
                })}
              </div>
            </div>
          ))}

          <Button
            onClick={() => {
              const currentState = { ...data }
              if (!currentState.stages) {
                currentState.stages = []
              }

              currentState.stages = [
                ...currentState.stages,
                { tactics: [], name: '', tooltip: '' },
              ]
              setData(currentState)
            }}
            className='create-new'
          >
            Add stage
          </Button>
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <Button
          onClick={() => submitFrameworkData()}
          style={{ minWidth: 180 }}
          className='create-new'
        >
          {isAdding ? (
            <Spinner size={22} border={1} color='#fff' />
          ) : id === 'new' ? (
            'Add New Framework'
          ) : (
            'Save Framework'
          )}
        </Button>

        <Button
          style={{
            textTransform: 'capitalize',
            marginLeft: 20,
            borderRadius: 12,
            padding: '6px 16px',
          }}
          onClick={() =>
            history.push(
              '/app/admin/system-settings/scenario-configuration/frameworks',
            )
          }
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default EditScenarioFramework
