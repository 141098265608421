import React, { useState, useEffect } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import './CreateContent.scss'
import { Button, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Alert from './ContentTypes/Alert'
import Profile from './ContentTypes/Profile'
import Scenario from './ContentTypes/Scenario/Scenario'
import Report from './ContentTypes/Report/Report'
import { useGlobalContext } from '../../context/globalContext'
import useNotification from '../../hooks/useNotification'
import { useCreateManagementContext } from '../../context/createManagementState'
import { useUserContext } from '../../context/userContext'
import { useViewStateContext } from '../../context/viewStateContext'

const CreateContent = ({ configuration }) => {
  const { isContentBeingEdited, setIsContentBeingEdited } = useGlobalContext()

  const { addWorkspace, workspaces } = useViewStateContext()

  const {
    user: {
      modules: { create_mode },
    },
  } = useUserContext()

  const { setCreateContentState, createContentState } =
    useCreateManagementContext()

  const { warningToast, errorToast, infoToast } = useNotification()
  const history = useHistory()
  const location = useLocation()
  const [afterPublicationMessage, setAfterPublicationMessage] = useState(false)
  const modulesTop = ['alert', 'scenario', 'report']
  const modulesNumberTop = create_mode.filter((menuItem) => {
    return modulesTop.includes(menuItem.slug)
  })
  const modulesBot = ['threat-actor', 'incident', 'operation', 'malware-tools']
  const modulesNumberBot = create_mode.filter((menuItem) => {
    return modulesBot.includes(menuItem.slug)
  })

  useEffect(() => {
    if (location.pathname === '/app/content-create' && isContentBeingEdited) {
      history.push(`/app/content-create/alert/${isContentBeingEdited}`)
    } else if (
      location.pathname === '/app/content-create' &&
      isContentBeingEdited === null
    ) {
      setIsContentBeingEdited(false)
      location.state && setAfterPublicationMessage(location.state.message)
    }
  }, [location.pathname])

  const profileTypes = [
    { name: 'Threat Alert', slug: 'threat-actor' },
    { name: 'Incidents', slug: 'incident' },
    { name: 'Operations', slug: 'operation' },
    { name: 'Malware & Tools', slug: 'malware-tools' },
  ]

  const isContentAlreadyEdited = JSON.parse(
    localStorage.getItem('isContentEdited'),
  )

  const handleTabClosing = () => {
    if (!isContentAlreadyEdited) {
      localStorage.setItem('isContentEdited', null)
    }
  }

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing)

    return () => window.removeEventListener('unload', handleTabClosing)
  }, [])

  useEffect(() => {
    let intervalId

    if (
      isContentAlreadyEdited &&
      Number(isContentAlreadyEdited) !== isContentBeingEdited[1]
    ) {
      intervalId = setTimeout(
        () => (window.location.href = '/app/workspace'),
        2000,
      )
      errorToast({
        message: 'You may have one content editor open per user session.',
      })
    }

    return () => {
      localStorage.setItem('isContentEdited', null)
      clearTimeout(intervalId)
    }
  }, [])

  return (
    <div className='create-content'>
      <Switch>
        <Route exact path='/app/content-create'>
          <div className='create-content__inner'>
            {afterPublicationMessage && (
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                onClose={() => setAfterPublicationMessage(false)}
                open={afterPublicationMessage !== false}
              >
                <MuiAlert elevation={6} variant='filled' severity='success'>
                  {afterPublicationMessage}
                </MuiAlert>
              </Snackbar>
            )}

            <div
              className='create-content__inner-top'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 30,
              }}
            >
              <h1>Create content</h1>
            </div>

            <Button
              onClick={() => history.push('/app/content-management')}
              style={{
                textTransform: 'capitalize',
                position: 'absolute',
                left: 30,
                top: 20,
              }}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>

            <div
              className={`create-content__inner-bottom row-${modulesNumberTop.length}`}
            >
              {create_mode.map((item) => {
                if (modulesTop.includes(item.slug))
                  return (
                    <Button
                      disableRipple
                      onClick={() => {
                        if (!item.has_access) {
                          infoToast({
                            message:
                              'You do not have access to this module or content. Please contact our sales team for more information.',
                            keepOpen: true,
                          })

                          return false
                        }

                        if (item.slug === 'analysis') {
                          history.push(`/app/workspace`)
                        } else {
                          !isContentBeingEdited &&
                            Object.keys(createContentState)?.length &&
                            setCreateContentState({})

                          history.push(`content-create/${item.slug}/new`)
                        }
                      }}
                      key={item.slug}
                    >
                      <span
                        style={{ color: '#006fff', fontSize: 48 }}
                        className={`icon-${item.slug}`}
                      />

                      <span>{item.name}</span>
                    </Button>
                  )
              })}
            </div>

            <div
              className={`create-content__inner-bottom row-${modulesNumberBot.length}`}
            >
              {create_mode.map((item) => {
                if (modulesBot.includes(item.slug))
                  return (
                    <Button
                      disableRipple
                      onClick={() => {
                        if (!item.has_access) {
                          infoToast({
                            message:
                              'You do not have access to this module or content. Please contact our sales team for more information.',
                            keepOpen: true,
                          })

                          return false
                        }
                        !isContentBeingEdited &&
                          Object.keys(createContentState)?.length &&
                          setCreateContentState({})
                        history.push(`content-create/${item.slug}/new`)
                      }}
                      key={item.slug}
                    >
                      <span
                        style={{ color: '#006fff', fontSize: 48 }}
                        className={`icon-${item.slug}`}
                      />

                      <span>{item.name}</span>
                    </Button>
                  )
              })}

              {create_mode.find((mod) => mod.slug === 'analysis') && (
                <Button
                  disableRipple
                  onClick={() => {
                    const linkAnalysisAlreadyOpen = workspaces.find(
                      (workspace) => workspace.state === 'analysis',
                    )

                    linkAnalysisAlreadyOpen
                      ? warningToast({
                          message:
                            'A link analysis workspace is currently open. Please close it before opening a new one',
                        })
                      : addWorkspace(
                          'analysis',
                          null,
                          'Create link analysis',
                          {},
                        )
                  }}
                >
                  <span
                    style={{ color: '#006fff', fontSize: 48 }}
                    className='icon-analysis'
                  />

                  <span>Link Analysis</span>
                </Button>
              )}
            </div>
          </div>
        </Route>

        <Route exact path='/app/content-create/alert/:id'>
          <Alert
            configuration={configuration?.alert?.types[0]?.schema}
            alertTypes={configuration?.alert?.types}
          />
        </Route>

        {profileTypes.map((type) => (
          <Route
            key={type.slug}
            exact
            path={`/app/content-create/${type.slug}/:id`}
          >
            <Profile
              configuration={configuration?.profile?.types?.find(
                (item) => item.slug === type.slug,
              )}
            />
          </Route>
        ))}

        <Route exact path='/app/content-create/scenario/:id'>
          <Scenario configuration={configuration?.scenario} />
        </Route>

        <Route exact path='/app/content-create/report/:id'>
          <Report configuration={configuration?.report} />
        </Route>
      </Switch>
    </div>
  )
}

export default CreateContent
