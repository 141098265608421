import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TextField } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import '../SystemSettingsList.scss'
import Spinner from '../../../../shared/Spinner'
import ConfirmDelete from '../../../ContentManage/ConfirmDelete'
import {
  deleteSingleItem,
  list,
  reorderFrameworks,
} from '../../../../shared/services/api/admin/settings/systemSettings'
import SingleFramework from './SingleFramework'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import useNotification from '../../../../hooks/useNotification'
import { useAdmin } from '../../../../context/adminContext'

const ScenarioFrameworkList = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const location = useLocation()
  const [isLoading, setLoading] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(undefined)
  const [isDeleting, setDeleting] = useState(false)
  const tabUrl = 'scenario-configuration/frameworks'
  const {
    adminState: { frameworks },
    setAdminState,
  } = useAdmin()
  const [keyword, setKeyword] = useState(
    Object.fromEntries(new URLSearchParams(frameworks?.previousQuery))
      ?.keyword || '',
  )
  const [page, setPage] = useState(
    +Object.fromEntries(new URLSearchParams(frameworks?.previousQuery))?.page ||
      1,
  )
  const { successToast, errorToast, warningToast } = useNotification()
  const frameworkOrder = () =>
    frameworks?.data?.map((framework) => framework.id)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const getList = async (forceReload) => {
    try {
      const query = new URLSearchParams({
        keyword: keyword,
        page: page,
      }).toString()

      if (frameworks?.previousQuery === query && !forceReload) return false
      setLoading(true)
      const response = await list(token(), tabUrl, query)

      if (response?.list?.data?.length === 0) {
        warningToast({ message: 'No results found.' })
      }

      response.list.previousQuery = query
      setAdminState((currentState) => ({
        ...currentState,
        frameworks: response.list,
      }))
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDeleteType = async (id) => {
    setDeleting(true)
    try {
      const response = await deleteSingleItem(token(), tabUrl, id)

      if (response.error) {
        errorToast({ message: response.message })
        setDeleting(false)
        return false
      }

      successToast({ message: response.message })
      await getList(true)
      setDeleting(false)
      setConfirmDelete(undefined)
    } catch {}
  }

  const triggerReorder = async () => {
    try {
      const response = await reorderFrameworks(token(), {
        ids: frameworkOrder(),
      })

      successToast({ message: response.message })
    } catch {}
  }

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = frameworks?.data?.findIndex(
        (item) => item.id === active.id,
      )
      const newIndex = frameworks?.data?.findIndex(
        (item) => item.id === over.id,
      )

      frameworks.data = arrayMove(frameworks?.data, oldIndex, newIndex)
      setAdminState((currentState) => ({
        ...currentState,
        frameworks: frameworks,
      }))
      triggerReorder()
    }
  }

  useEffect(() => {
    if (location.pathname === `/app/admin/system-settings/${tabUrl}`) {
      getList()
    }
  }, [page])

  return (
    <>
      {confirmDelete !== undefined && (
        <ConfirmDelete
          isDeleting={isDeleting}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
          handleDeletePiece={handleDeleteType}
        />
      )}

      {isLoading && <Spinner centerHorizontally padding={20} size={30} />}

      {!isLoading && (
        <>
          <div className='groups-list'>
            <form
              onSubmit={(event) => {
                event.preventDefault()
                setPage(1)
                getList()
              }}
            >
              <TextField
                variant='outlined'
                placeholder='Search'
                InputProps={{
                  startAdornment: (
                    <span style={{ fontSize: 22 }} className='icon-search' />
                  ),
                }}
                value={keyword}
                onChange={(event) => setKeyword(event.target.value)}
              />
            </form>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#e0e0e0',
                padding: 16,
                borderRadius: '10px',
              }}
            >
              <span style={{ width: '80%' }}>Name</span>

              <span style={{ width: '20%', textAlign: 'right' }}>Options</span>
            </div>

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={frameworks?.data || []}
                strategy={rectSortingStrategy}
              >
                {frameworks?.data?.map(({ id, name }) => (
                  <SingleFramework
                    key={id}
                    id={id}
                    name={name}
                    setConfirmDelete={setConfirmDelete}
                    tabUrl={tabUrl}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>

          <Pagination
            onChange={(event, value) => setPage(value)}
            style={{ marginTop: 20 }}
            page={page}
            count={frameworks?.last_page}
            shape='rounded'
            hideNextButton
            hidePrevButton
            color='primary'
          />
        </>
      )}
    </>
  )
}

export default ScenarioFrameworkList
