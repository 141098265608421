/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react'
import './Filters.scss'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Avatar,
  Button,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DateRangePicker from '../../../shared/DateRangePicker/DateRangePicker'
import { useViewStateContext } from '../../../context/viewStateContext'

const Filters = ({
  keywordsInput,
  handleFilters,
  handleSubmit,
  filterOptions,
  typeId,
  setOrderBy,
  setFiltersOpen,
}) => {
  const statusCheckboxes = [
    { name: 'Read', slug: 'read' },
    { name: 'Unread', slug: 'unread' },
    { name: 'Followed', slug: 'flagged' },
  ]
  const relevanceCheckboxes = ['organisation', 'sector', 'global']
  const levels = [1, 2, 3, 4, 5]
  const { setWorkspaces, workspaces } = useViewStateContext()
  const currentModule = workspaces?.find((w) => w?.active === true)
  const filters = currentModule?.filters && Object.keys(currentModule.filters)
  const hasOnlyPage = filters?.length === 1 && filters[0] === 'page'
  const isFiltersResetable = () =>
    filters?.length > 0 &&
    !hasOnlyPage &&
    currentModule?.filters?.orderBy !== 'default'
  const profileTypes = [
    { name: 'Threat Actor', id: 1 },
    { name: 'Country', id: 2 },
    { name: 'Incident', id: 3 },
    { name: 'Operation', id: 4 },
    { name: 'Malware & Tools', id: 5 },
  ]
  const currentProfileType = profileTypes.filter((profile) => {
    if (profile.id === typeId) return profile
  })

  useEffect(() => {
    currentModule?.filters?.keywords &&
      handleFilters('keywords', currentModule.filters.keywords)
  }, [currentModule?.id])

  const handleResetFilters = () => {
    setOrderBy('default')
    const currentState = [...workspaces]
    const currentWorkspace = currentState.find(
      (workspace) => workspace.id === currentModule.id,
    )

    currentWorkspace.filters = { page: currentModule?.filters?.page || 1 }
    setWorkspaces(currentState)
  }

  return (
    <div className='filters-main'>
      <div className='filters-main__reset'>
        <span style={{ color: '#fff' }}>
          Search{' '}
          {currentProfileType[0].name === 'Malware & Tools'
            ? currentProfileType[0].name
            : currentProfileType[0].name + 's'}
        </span>

        <span
          onClick={handleResetFilters}
          onKeyDown={handleResetFilters}
          style={{
            color: isFiltersResetable() ? '#fff' : '#8d9197',
            cursor: 'pointer',
          }}
        >
          Reset filters
        </span>
      </div>

      <Divider style={{ margin: '20px 0' }} />

      <div className='keywords'>
        <h4>Keywords</h4>

        <TextField
          inputRef={keywordsInput}
          value={currentModule?.filters?.keywords || ''}
          onChange={(event) => handleFilters('keywords', event.target.value)}
          onKeyPress={(event) => event.key === 'Enter' && handleSubmit()}
          placeholder='Keywords'
          variant='outlined'
          type='search'
        />
      </div>

      <div className='status'>
        <h4>Status</h4>

        <FormGroup row className='status-checkboxes'>
          {statusCheckboxes.map((item) => (
            <FormControlLabel
              key={item.slug}
              control={
                <Checkbox
                  checked={
                    currentModule?.filters?.status?.includes(item.slug) || false
                  }
                  onChange={() => handleFilters('status', item.slug)}
                  icon={<span className='icon-checkbox-empty' />}
                  checkedIcon={<span className='icon-checkbox-checked' />}
                />
              }
              label={item.name}
            />
          ))}
        </FormGroup>
      </div>

      {(typeId === 1 || typeId === 2) && (
        <div className='threat-level'>
          <h4>Capability</h4>

          <div className='priority'>
            {levels.map((level) => (
              <Avatar
                key={level}
                onClick={() => handleFilters('priority', level)}
                className={
                  currentModule?.filters?.priority?.includes(level)
                    ? `level-${level}`
                    : `level-${level} inactive`
                }
              >
                {level}
              </Avatar>
            ))}
          </div>
        </div>
      )}

      <div className='relevance'>
        <h4>Relevance</h4>

        <FormGroup row className='relevance-checkboxes'>
          {relevanceCheckboxes.map((item) => (
            <FormControlLabel
              key={item}
              onChange={(event) =>
                handleFilters('relevance', event.target.name)
              }
              control={
                <Checkbox
                  checked={
                    currentModule?.filters?.relevance?.includes(item) || false
                  }
                  name={item}
                  value={item}
                  icon={<span className='icon-checkbox-empty' />}
                  checkedIcon={<span className='icon-checkbox-checked' />}
                />
              }
              label={item}
            />
          ))}
        </FormGroup>
      </div>

      <div>
        <DateRangePicker
          selectedDateRange={currentModule?.filters?.dateRange || []}
          handleFilters={handleFilters}
        />
      </div>

      <div className='sector'>
        <h4>Sectors</h4>

        <Autocomplete
          multiple
          value={currentModule?.filters?.sectors || []}
          options={filterOptions?.list || []}
          getOptionSelected={(option, value) => option.id === value.id}
          disableCloseOnSelect
          onChange={(e, newValue) => handleFilters('sectors', newValue)}
          getOptionLabel={(option) => option.name}
          renderOption={(option, props) => {
            return (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minHeight: 30,
                }}
                className='sector-checkbox'
              >
                <div>
                  <Checkbox
                    style={{ padding: 0, marginRight: 10 }}
                    checked={props.selected}
                    icon={<span className='icon-checkbox-empty' />}
                    checkedIcon={<span className='icon-checkbox-checked' />}
                  />

                  <span>{option.name}</span>
                </div>
              </div>
            )
          }}
          renderInput={(params) => {
            params.InputProps.startAdornment = params.InputProps.startAdornment
              ? `Sectors Selected: ${params.InputProps.startAdornment.length}`
              : ''
            return (
              <TextField
                {...params}
                style={{ color: '#fff' }}
                variant='outlined'
                placeholder='Search Sectors'
              />
            )
          }}
        />
      </div>

      <Button
        color='primary'
        style={{
          color: '#fff',
          backgroundColor: '#006fff',
          marginTop: '20px',
          width: '100%',
          textTransform: 'capitalize',
          fontWeight: 700,
        }}
        onClick={() => {
          handleSubmit()
          const { innerWidth: width } = window

          width < 1200 && setFiltersOpen(false)
        }}
        type='submit'
      >
        Filter results
      </Button>
    </div>
  )
}

export default Filters
