import cloneDeep from 'lodash/cloneDeep'
import { isMobile } from 'react-device-detect'

export const globalStateHandler = async (
  currentState,
  data,
  moduleName,
  shouldOverwrite,
  shouldDeletePiece,
) => {
  const newState = cloneDeep(currentState)
  const readItems = newState[moduleName]?.readItems
  const existingPieceData = readItems?.find((item) => item.id === data.id)
  let newReadItems = []

  if (shouldDeletePiece) {
    return readItems?.filter((item) => item.id !== data)
  } else {
    if (shouldOverwrite) {
      const itemsWithoutOverwritten = readItems?.filter(
        (item) => item.id !== data.id,
      )
      newReadItems = !itemsWithoutOverwritten
        ? [data]
        : [...itemsWithoutOverwritten, data]
    } else {
      if (existingPieceData) return false
      newReadItems = [...newState[moduleName].readItems, data]
    }

    newState[moduleName] = { ...newState[moduleName], readItems: newReadItems }
  }

  return newReadItems
}

export const workspaceHandler = (newItems, index, key, data, filterKey) => {
  newItems.forEach((item) => {
    if (item.id === index) {
      if (
        key === 'list' ||
        key === 'currentPiece' ||
        key === 'currentTab' ||
        key === 'filtersOpen' ||
        key === 'scrollPosition'
      ) {
        item[key] = data

        if (key === 'currentPiece') {
          item.scrollPosition = 0
          item.currentTab = 'content'
        }
      } else if (key === 'filters') {
        if (filterKey === 'reset') {
          item.filters = {}
          item.filters['page'] = data
          return false
        }

        if (data.length === 0) {
          delete item.filters[filterKey]
        } else {
          item.filters[filterKey] = data
        }
      } else if (key === 'isNew') {
        delete item.isNew
      }
    } else if (key === 'active') {
      item.active = item.id === data
    }
  })
  return newItems
}

export const addWorkspaceHandler = (
  type,
  id,
  name,
  fromDashboard,
  workspaceIdCount,
  globalSearchFilters,
  workspaces,
  openInNewTab,
) => {
  const isCreateLinkAnalysis = name === 'Create link analysis'
  const hasSameId = workspaces.find(
    (workspace) => workspace?.id === workspaceIdCount + 1,
  )
  const newWorkspace = {
    name: name || 'New workspace',
    id: hasSameId ? workspaceIdCount + 2 : workspaceIdCount + 1,
    state: type,
    active: openInNewTab === true ? false : true,
    filters: globalSearchFilters || {},
    currentPiece: id || null,
    currentTab: isCreateLinkAnalysis
      ? 'create'
      : type === 'analysis'
      ? 'view'
      : fromDashboard && !globalSearchFilters
      ? 'comments'
      : 'content',
  }

  if (id === undefined) {
    newWorkspace.isNew = !isMobile //for loading the first piece of content when opening a module
  }
  return newWorkspace
}

const resetSection = (data) => {
  data.is_complete = false
  data.is_accepted = false
  data.is_rejected = false
  return data
}

export const createStateHandler = (
  currentState,
  section,
  data,
  isOutsideDevContent,
  isExpandedEvent,
  isContentBeingEdited,
  setIsContentBeingEdited,
  setCreateContentState,
) => {
  if (isOutsideDevContent) {
    //when a changed property is not in dev_content
    if (section === 'dev_title') {
      currentState[section] = data
      setCreateContentState(currentState)
      return false
    } else if (section === 'author-settings') {
      //case for author settings, where a single radio button changes other property
      Object.entries(data).forEach((setting) => {
        currentState[setting[0]] = setting[1]
      })
    } else if (section === 'dev_content') {
      //case for changing framework in scenarios when isContentBeingEdited and dev_type_id in reports
      if (
        isContentBeingEdited[0] === 'report' ||
        !currentState.dev_framework_id
      ) {
        currentState.dev_type_id = data[0]
        currentState.dev_content = data[1]
      } else {
        currentState.dev_framework_id = data[0]
        currentState.dev_content = data[1]
      }

      setCreateContentState(currentState)
      return false
    } else if (section === 'dev_content_mitre') {
      const tagData = data[0]
      const tagsDevData = data[1]
      const tagsData = data[2]
      const devContentData = data[3]
      const sectionKey = isExpandedEvent || 'tags'

      if (currentState?.dev_content[sectionKey]?.is_complete) {
        currentState.dev_content[sectionKey] = resetSection(
          currentState.dev_content[sectionKey],
        )
      }

      currentState.tag_tactics = tagData
      currentState.dev_content = devContentData
      currentState.tags_dev = tagsDevData
      currentState.tags = tagsData

      !isContentBeingEdited && setIsContentBeingEdited(true)
      setCreateContentState(currentState)

      return false
    } else if (section === 'mitre_navigator') {
      currentState.mitre_navigator_data = data
      setCreateContentState(currentState)

      return false
    } else if (section === 'related') {
      const currentData = {
        ...currentState?.dev_content['associated-profiles'],
      }

      currentState[section] = data
      if (currentData?.is_complete) {
        currentState.dev_content['associated-profiles'] =
          resetSection(currentData)
      }
    } else if (section === 'related_alerts') {
      const currentData = { ...currentState?.dev_content['associated-alerts'] }

      currentState[section] = data
      if (currentData?.is_complete) {
        currentState.dev_content['associated-alerts'] =
          resetSection(currentData)
      }
    } else {
      //all others cases
      currentState[section] = data
      if (
        section === 'dev_framework_id' ||
        section === 'dev_asset_type' ||
        section === 'dev_asset_name'
      ) {
        //scenarios case, where changing state shouldn't update isContentBeingEdited
        setCreateContentState(currentState)

        return false
      }
    }
  } else {
    //when a changed property is in dev_content
    if (data.content.text !== undefined && data.content.text === '') {
      delete data.content.text
    }
    if (
      data.content.connectors !== undefined &&
      data.content.connectors.length === 0
    ) {
      delete data.content.connectors
    }
    data.content =
      section === 'lat_long' ? [...data.content] : { ...data.content }

    currentState.dev_content[section] = data
  }

  setCreateContentState(currentState)

  if (isContentBeingEdited === false) {
    if (
      Object.keys(currentState).length === 1 &&
      Object.keys(currentState)[0] === 'dev_type_id'
    ) {
      //reports case
      return false
    }

    if (isOutsideDevContent) {
      //changes to outside dev_content are listened to immediately
      setIsContentBeingEdited(true)
    } else {
      //changes within dev_content listen to content prop rather than the entire object
      section &&
        Object.keys(data.content).length > 0 &&
        setIsContentBeingEdited(true)
    }
  } else {
    return false
  }
}
