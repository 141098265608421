/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { closestCenter, DndContext } from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

import ArticleSection from '../sections/ArticleSection'
import Button from '../../../../shared/components/Button'

const NEW_CONTENT = [
  { name: 'Text', slug: 'text' },
  { name: 'Standout Strip', slug: 'button' },
]

const ArticleMainContent = ({
  data,
  handleAddSection,
  handleDeleteSection,
  handleDragEnd,
  handleSectionState,
  handleState,
  isAddingContent,
  sensors,
  setAddingContent,
}) => {
  return (
    <div className='knowledge-base__article-content-left'>
      <div className='knowledge-base__article-content-left-title'>
        <TextField
          defaultValue={data?.title || ''}
          onBlur={(event) => handleState('title', event.target.value)}
          fullWidth
          placeholder='Enter content title here'
          variant='outlined'
        />
      </div>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={
            [...data?.content].map((item, index) => index + '_' + item.type) ||
            []
          }
          strategy={rectSortingStrategy}
        >
          <div className='knowledge-base__article-section-wrapper'>
            {data?.content?.map((section, index) => (
              <ArticleSection
                key={index + '_' + section.type}
                content={section}
                handleSectionState={handleSectionState}
                index={index}
                id={index + '_' + section.type}
                handleDeleteSection={handleDeleteSection}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>

      {data.type === 'text' && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Button
            className='knowledge-base__article-add'
            onClick={() => setAddingContent(!isAddingContent)}
          >
            <span>Add content</span>

            <span
              className='icon-circle-add'
              style={{ fontSize: '20px', marginLeft: '3px' }}
            />
          </Button>

          {isAddingContent && (
            <div style={{ display: 'flex' }}>
              {NEW_CONTENT.map((item) => (
                <div
                  onClick={() => handleAddSection(item.slug)}
                  className='knowledge-base__article-new-section'
                  key={item.slug}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {/* {data.type === 'video' && <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Button
            className='knowledge-base__article-add'
            onClick={() => handleAddSection('text')}
          >
            <span>Add text content</span>

            <span className='icon-circle-add' style={{ fontSize: '20px', marginLeft: '3px'}} />

          </Button>
        </div> } */}
    </div>
  )
}

ArticleMainContent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  handleAddSection: PropTypes.func.isRequired,
  handleDeleteSection: PropTypes.func.isRequired,
  handleDragEnd: PropTypes.func.isRequired,
  handleSectionState: PropTypes.func.isRequired,
  handleState: PropTypes.func.isRequired,
  isAddingContent: PropTypes.bool.isRequired,
  sensors: PropTypes.instanceOf(Array).isRequired,
  setAddingContent: PropTypes.func.isRequired,
}

export default ArticleMainContent
