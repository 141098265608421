import React, { useEffect, Fragment } from 'react'
import { useLocation, NavLink, Route } from 'react-router-dom'
import './Logs.scss'
import Signings from './LogsTabs/Signings'
import PdfDownloads from './LogsTabs/PdfDownloads'
import Operations from './LogsTabs/Operations'
import { cancelRequests } from '../../shared/services/http/client'
import ApiUsage from './LogsTabs/ApiUsage'
import ViewLogs from './LogsTabs/ViewLogs'

const Logs = () => {
  const location = useLocation()
  const logsModules = [
    { name: 'Login History', slug: 'login-history', icon: 'icon-exit' },
    { name: 'PDF Downloads', slug: 'pdf-downloads', icon: 'icon-office' },
    { name: 'Operations', slug: 'operations', icon: 'icon-operation' },
    { name: 'API Usage', slug: 'api-usage', icon: 'icon-logs path2' },
    { name: 'Content view', slug: 'content-view', icon: 'icon-logs path2' },
  ]

  useEffect(() => {
    return () => {
      cancelRequests()
    }
  })

  return (
    <div className='logs'>
      <div className='account-tabs__wrapper'>
        <h2>Logs</h2>

        <div className='tab-group'>
          {logsModules.map((log) => (
            <Fragment key={log.slug}>
              <NavLink
                isActive={() =>
                  location.pathname.startsWith(`/app/admin/logs/${log.slug}`)
                }
                to={`/app/admin/logs/${log.slug}`}
              >
                <span className={log.icon} />

                {log.name}
              </NavLink>
            </Fragment>
          ))}
        </div>
      </div>

      <div className='account-tabs__container'>
        <Route path='/app/admin/logs/login-history' component={Signings} />

        <Route
          exact
          path='/app/admin/logs/pdf-downloads'
          component={PdfDownloads}
        />

        <Route exact path='/app/admin/logs/operations' component={Operations} />

        <Route path='/app/admin/logs/api-usage' component={ApiUsage} />

        <Route path='/app/admin/logs/content-view' component={ViewLogs} />
      </div>
    </div>
  )
}

export default Logs
