/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import { useQueryCacheState } from '../../useQueryCacheState'
import { notifications } from '../../../api/notifications/notifications'

const token = () => {
  return localStorage.getItem('access_token')
}

export const useQueryGetNotifications = () => {
  return useQuery({
    queryKey: ['user-notifications'],
    queryFn: () => notifications(token()),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}

export const useQueryCacheGetNotifications = () =>
  useQueryCacheState('user-notifications')
