import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const getCategoriesAdmin = (token) =>
  client.get(apiUrl + `/api/knowledgebase/admin/category`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getSingleAdminArticle = (token, id) =>
  client.get(apiUrl + `/api/knowledgebase/admin/article/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const createAdminArticle = (token, data) =>
  client.post(apiUrl + `/api/knowledgebase/admin/article`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const saveAdminArticle = (token, id, data) =>
  client.put(apiUrl + `/api/knowledgebase/admin/article/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteArticleAdmin = (token, id) =>
  client.remove(apiUrl + `/api/knowledgebase/admin/article/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getArticlesAdmin = (token) =>
  client.get(apiUrl + `/api/knowledgebase/admin/article?`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getCategories = (token) =>
  client.get(apiUrl + `/api/knowledgebase/category`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const createCategory = (token, data) =>
  client.post(apiUrl + `/api/knowledgebase/admin/category`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const updateCategory = (token, id, data) =>
  client.put(apiUrl + `/api/knowledgebase/admin/category/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteCategory = (token, id) =>
  client.remove(apiUrl + `/api/knowledgebase/admin/category/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getSingleArticle = (token, id) =>
  client.get(apiUrl + `/api/knowledgebase/article/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getFilteredArticles = (token, searchPhase) =>
  client.get(apiUrl + `/api/knowledgebase/article?search=${searchPhase}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
