import React, { useEffect, useState } from 'react'
import {
  singleUser,
  addUser,
  editUser,
} from '../../../../shared/services/api/admin/users/users'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import Spinner from '../../../../shared/Spinner'
import { useHistory, useParams } from 'react-router-dom'
import { TextField } from '@mui/material'
import useNotification from '../../../../hooks/useNotification'

const EditAdminUser = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const history = useHistory()
  const { id } = useParams()
  const isCreateNew = id === 'new'
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState({ is_two_factor_enabled: true })
  const [isAdding, setAdding] = useState(false)
  const { successToast, errorToast } = useNotification()

  const fields = [
    { name: 'Username', slug: 'username' },
    { name: 'First Name', slug: 'first_name' },
    { name: 'Last Name', slug: 'last_name' },
    { name: 'Email', slug: 'email' },
    {
      name: 'Two Factor Authentication Enabled',
      slug: 'is_two_factor_enabled',
    },
  ]

  const getAdminDetails = async () => {
    try {
      const response = await singleUser(token(), id)
      setData(response.data)
      setLoading(false)
    } catch (err) {}
  }

  const submitAdminData = async () => {
    setAdding(true)
    try {
      let response = ''
      if (id === 'new') {
        response = await addUser(token(), data)
      } else {
        response = await editUser(token(), id, data)
      }

      if (response.error) {
        errorToast({ message: response.message })
        setAdding(false)
        return false
      }

      successToast({ message: response.message })
      setAdding(false)

      if (id === 'new') {
        history.push('/app/admin/system-settings/users')
      }
    } catch {}
  }

  useEffect(() => {
    if (id !== 'new') {
      setLoading(true)
      getAdminDetails()
    }
  }, [])

  const handleChangeAdminData = (prop, value) => {
    const currentData = { ...data }

    currentData[prop] = value
    setData(currentData)
  }

  return isLoading ? (
    <Spinner centerHorizontally padding={20} size={30} />
  ) : (
    <div className='edit-admin'>
      <h2 style={{ marginBottom: 10 }}>
        {isCreateNew ? 'Add New Admin' : 'Edit Admin'}
      </h2>

      <div className='edit-admin__form'>
        {fields.map((field) => {
          return field.slug === 'is_two_factor_enabled' ? (
            <FormControlLabel
              key={field.slug}
              onChange={() =>
                handleChangeAdminData(field.slug, !data[field.slug])
              }
              label={field.name}
              control={
                <Checkbox
                  checked={data[field.slug] || false}
                  style={{ padding: 4, color: '#006fff' }}
                  icon={<span className='icon-checkbox-empty' />}
                  checkedIcon={<span className='icon-checkbox-checked' />}
                />
              }
            />
          ) : (
            <div key={field.slug} style={{ width: '100%', margin: '6px 0' }}>
              <h5 style={{ marginBottom: 5 }}>{field.name}</h5>

              <TextField
                fullWidth
                variant='outlined'
                placeholder={field.name}
                value={data[field.slug] || ''}
                required
                onChange={(event) =>
                  handleChangeAdminData(field.slug, event.target.value)
                }
              />
            </div>
          )
        })}
      </div>

      <div style={{ marginTop: 20 }}>
        <Button
          onClick={() => submitAdminData()}
          style={{ minWidth: 132 }}
          className='create-new'
        >
          {isAdding ? (
            <Spinner size={22.5} border={1} color='#fff' />
          ) : id === 'new' ? (
            'Add New User'
          ) : (
            'Edit User'
          )}
        </Button>

        <Button
          style={{
            textTransform: 'capitalize',
            marginLeft: 20,
            borderRadius: 12,
            padding: '6px 16px',
          }}
          onClick={() => history.push('/app/admin/system-settings/users')}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default EditAdminUser
