/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import React, { useState, Fragment } from 'react'
import ContentDatepicker from '../../shared/Components/ContentDatepicker'
import Gallery from '../../shared/Components/Gallery'
import CreateContentHeader from '../../shared/Components/microcomponents/CreateContentHeader'
import ConnectorsWithModal from '../../shared/Components/ConnectorsWithModal'
import AssociatedProfiles from '../../shared/Components/AssociatedProfiles'
import AssociatedAlerts from '../../shared/Components/AssociatedAlerts'
import ContentDistribution from '../../shared/Components/ContentDistribution'
import Severity from '../../shared/Components/Severity'
import SectorRelevance from '../../shared/Components/SectorRelevance'
import PdfSettings from '../../shared/Components/PdfSettings'
import Tlp from '../../shared/Components/Tlp'
import AuthorSettings from '../../shared/Components/AuthorSettings'
import Ioc from '../../shared/Components/Ioc'
import ConfirmDelete from '../../../ContentManage/ConfirmDelete'
import MitreTags from '../../shared/Components/MitreTags'
import { useGlobalContext } from '../../../../context/globalContext'
import { useCreateManagementContext } from '../../../../context/createManagementState'
import FroalaEditorComponent from '../../../../shared/FroalaEditorComponent/FroalaEditorComponent'

const DateComponent = ({ fields, data }) => {
  return (
    <div>
      <h5>Dates</h5>

      <div className='datepicker'>
        {fields.map((field) => (
          <ContentDatepicker
            key={field.name}
            data={data}
            name={field.name}
            label={field.label || 'Select date'}
          />
        ))}
      </div>

      <Divider style={{ margin: '20px 0' }} />
    </div>
  )
}

const HeadlineComponent = ({
  text,
  handleCreateState,
  internal_key,
  sectionData,
  subSectionIndex,
}) => {
  return (
    <>
      <h5 style={{ marginBottom: 5 }}>Headline</h5>

      <TextField
        variant='outlined'
        placeholder='Enter Headline'
        defaultValue={text || ''}
        fullWidth
        style={{ marginBottom: 20 }}
        inputProps={{ style: { padding: 15 } }}
        onChange={(event) => {
          const currentSectionState = { ...sectionData }
          if (subSectionIndex !== undefined) {
            currentSectionState.content.free_content[
              subSectionIndex
            ].element.headline = event.target.value
          } else {
            currentSectionState.content.additional_viewer_headline =
              event.target.value
          }
          handleCreateState(internal_key, currentSectionState)
        }}
      />
    </>
  )
}

const FreeContentComponent = ({
  data,
  handleCreateState,
  internal_key,
  setToastMessage,
}) => {
  const [isAddingContent, setAddingContent] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(undefined)
  const [isDeleting, setDeleting] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const newContent = [
    { name: 'Gallery', slug: 'gallery' },
    { name: 'Text', slug: 'text' },
  ]

  const handleAddContent = (slug) => {
    const currentState = { ...data }
    const newItem = { type: slug, element: { headline: '' } }
    if (slug === 'text') {
      newItem.element.text = ''
    } else if (slug === 'gallery') {
      newItem.element.gallery = []
      newItem.element['gallery-imagesPerRow'] = ''
      newItem.element.layout = ''
    } else if (slug === 'external-links-list') {
      newItem.element.links = []
    }

    if (currentState.content.free_content === undefined) {
      currentState.content.free_content = []
    }

    currentState.content.free_content = [
      ...currentState.content.free_content,
      newItem,
    ]
    handleCreateState(internal_key, currentState)
    setAddingContent(false)
  }

  const handleDeletePiece = (index) => {
    const currentState = { ...data }
    setDeleting(true)
    currentState.content.free_content.splice(index, 1)
    handleCreateState(internal_key, currentState)
    setConfirmDelete(undefined)
    setTimeout(() => setDeleting(false))
  }

  const handleGallery = (prop, newValue, subSectionIndex) => {
    const currentState = { ...data }
    currentState.content.free_content[subSectionIndex].element[prop] = newValue
    handleCreateState(internal_key, currentState)
  }

  return (
    <div className='free-content' style={{ margin: '20px 0' }}>
      <h4>Content</h4>

      {isLoading && (
        <Backdrop open={isLoading} style={{ zIndex: 9 }}>
          <CircularProgress />
        </Backdrop>
      )}

      {confirmDelete !== undefined && (
        <ConfirmDelete
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
          isDeleting={false}
          handleDeletePiece={handleDeletePiece}
        />
      )}

      {!isDeleting &&
        data.content.free_content &&
        data.content.free_content.map((subSection, index) => (
          <div
            key={index}
            style={{
              margin: '15px 0px',
              border: '3px dashed rgb(237, 237, 237)',
            }}
          >
            {subSection.type === 'text' && (
              <div style={{ padding: 16 }}>
                <HeadlineComponent
                  text={
                    (subSection.element && subSection.element.headline) || ''
                  }
                  subSectionIndex={index}
                  sectionData={data}
                  handleCreateState={handleCreateState}
                />

                <FroalaEditorComponent
                  label='Text'
                  internal_key={internal_key}
                  textKey='text'
                  reportSubBlockIndex={index}
                />
              </div>
            )}

            {subSection.type === 'gallery' && (
              <div style={{ padding: 16 }}>
                <HeadlineComponent
                  text={
                    (subSection.element && subSection.element.headline) || ''
                  }
                  subSectionIndex={index}
                  sectionData={data}
                  handleCreateState={handleCreateState}
                />

                <h4>Gallery</h4>

                <Gallery
                  data={subSection.element}
                  setLoading={setLoading}
                  setToastMessage={setToastMessage}
                  subSectionIndex={index}
                  internal_key={internal_key}
                  reportsGallery
                  handleGallery={handleGallery}
                />
              </div>
            )}

            {subSection.type === 'external-links-list' && (
              <div style={{ padding: 16 }}>
                <HeadlineComponent
                  text={
                    (subSection.element && subSection.element.headline) || ''
                  }
                />

                <h4>Links list</h4>
              </div>
            )}

            <Button
              onClick={() => setConfirmDelete(index)}
              className='free-content__delete'
            >
              Delete Sub-section
            </Button>
          </div>
        ))}

      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Button
          className='free-content__add'
          onClick={() => setAddingContent(!isAddingContent)}
          endIcon={<span className='icon-circle-add' />}
        >
          Add content
        </Button>

        {isAddingContent && (
          <div style={{ display: 'flex' }}>
            {newContent.map((item) => (
              <div
                onClick={() => handleAddContent(item.slug)}
                onKeyDown={() => handleAddContent(item.slug)}
                className='free-content__new'
                key={item.slug}
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const ContentSection = ({
  configuration,
  handleCompleteSections,
  handleExpandedSections,
  handleSectionStatus,
  setToastMessage,
  handleAutosave,
  isSaving,
}) => {
  const { isContentBeingEdited } = useGlobalContext()
  const { createContentState, handleCreateState } = useCreateManagementContext()
  configuration.forEach((section) => {
    const hasRequiredText =
      section?.headline?.split(' ')?.slice(-1)[0] === '(required)'
    if (hasRequiredText) {
      section.headline = section?.headline?.split(' ')?.slice(0, -1).join(' ')
    }
  })
  const [isLoading, setLoading] = useState(false)

  const returnDataPerSection = (section) => {
    if (isSaving) return true
    if (section === 'overview') {
      return !createContentState.dev_content.overview.content.text //&& Object.keys(state.dev_content.overview.content.text)
    } else if (section === 'tlp') {
      return (
        !createContentState.dev_tlp ||
        !createContentState.dev_tlp.colour ||
        createContentState.dev_tlp.colour.length === 0
      )
    } else if (section === 'content-distribution') {
      const isOriginOrg =
        isContentBeingEdited[1] &&
        createContentState.dev_content[section]?.content?.organisations &&
        Object.values(
          createContentState.dev_content[section]?.content?.organisations,
        ).find((org) => org.is_origin)
      return !isOriginOrg
    } else if (section === 'update-summary') {
      return (
        createContentState.dev_update_summary &&
        !createContentState.dev_update_summary.length
      )
    } else if (section === 'tags') {
      return (
        !createContentState.dev_content[section].content.connectors ||
        createContentState.dev_content[section].content.connectors.length === 0
      )
    } else if (section === 'mitre-tags') {
      return (
        !createContentState.tag_tactics ||
        Object.keys(createContentState.tag_tactics).length === 0
      )
    } else if (section === 'ioc') {
      return (
        !createContentState.ioc ||
        !createContentState.ioc.data ||
        createContentState.ioc.data.length === 0
      )
    } else if (section === 'associated-profiles') {
      return (
        !createContentState.related || createContentState.related.length === 0
      )
    } else if (section === 'associated-alerts') {
      return (
        !createContentState.related_alerts ||
        createContentState.related_alerts.length === 0
      )
    } else if (section === 'dates') {
      if (createContentState.dev_additional_dates) {
        return Object.keys(createContentState.dev_additional_dates).length === 0
      } else return true
    } else if (
      section === 'executive-summary' ||
      section === 'content' ||
      section === 'assessment' ||
      section === 'source-and-assessment'
    ) {
      return (
        !createContentState.dev_content[section].content.text &&
        (!createContentState.dev_content[section].content
          .additional_viewer_headline ||
          createContentState.dev_content[section].content
            .additional_viewer_headline === '') &&
        (!createContentState.dev_content[section].content.free_content ||
          createContentState.dev_content[section].content.free_content
            .length === 0)
      )
    } else if (section === 'irs-answered') {
      return !createContentState?.dev_content[section]?.content?.text
    } else if (section === 'sector-relevance') {
      return (
        !createContentState.relevance ||
        (createContentState.relevance &&
          createContentState.relevance.sectors &&
          createContentState.relevance.sectors.length === 0)
      )
    }
  }

  const shouldRender = ({ internal_key, is_sidebar_section }) => {
    if (
      internal_key === 'update-summary' &&
      createContentState?.published !== 1
    ) {
      return false
    }
    return (
      createContentState?.dev_content[internal_key] &&
      !is_sidebar_section &&
      internal_key !== 'title'
    )
  }

  return (
    <div className='create-alert__content-container'>
      {isLoading && (
        <Backdrop open={isLoading} style={{ zIndex: 9 }}>
          <CircularProgress />
        </Backdrop>
      )}

      <div className='create-alert__left'>
        <div className='content-title'>
          <TextField
            value={createContentState?.dev_title || ''}
            onChange={(event) =>
              handleCreateState('dev_title', event.target.value, true)
            }
            onBlur={async (event) =>
              !isSaving &&
              (await handleAutosave('titleChange', event.target.value))
            }
            placeholder='Enter content title here'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span className='label-required'>Required</span>
                </InputAdornment>
              ),
            }}
            variant='outlined'
          />
        </div>

        {configuration.map(
          (section) =>
            shouldRender(section) && (
              <div key={section.internal_key} className='content-section'>
                <CreateContentHeader
                  handleCompleteSections={handleCompleteSections}
                  handleExpandedSections={handleExpandedSections}
                  sectionData={
                    createContentState.dev_content[section.internal_key]
                  }
                  sectionSettings={section}
                  shouldBeDisabled={returnDataPerSection(section.internal_key)}
                  status={createContentState.status_id}
                  isManager={createContentState.can_publish_or_deny}
                  handleSectionStatus={handleSectionStatus}
                />

                {createContentState.dev_content[section.internal_key]
                  .is_expanded && (
                  <div className='content-section__content'>
                    {Object.entries(section.columns).map((component) => {
                      const type = component[1].type

                      return (
                        <Fragment key={component[0]}>
                          {type === 'tinymce' && (
                            <FroalaEditorComponent
                              label={component[1].label} //section.internal_key === 'dates' ? 'Date assessment' : 'Text'}
                              internal_key={section.internal_key}
                              textKey={component[0]}
                            />
                          )}

                          {type === 'headline' && (
                            <HeadlineComponent
                              text={
                                createContentState.dev_content[
                                  section.internal_key
                                ].content.additional_viewer_headline
                              }
                              handleCreateState={handleCreateState}
                              internal_key={section.internal_key}
                              sectionData={
                                createContentState.dev_content[
                                  section.internal_key
                                ]
                              }
                            />
                          )}

                          {component[0] === 'free_content' && (
                            <FreeContentComponent
                              data={
                                createContentState.dev_content[
                                  section.internal_key
                                ]
                              }
                              handleCreateState={handleCreateState}
                              internal_key={section.internal_key}
                              setToastMessage={setToastMessage}
                            />
                          )}

                          {type === 'datepicker' && (
                            <DateComponent
                              fields={component[1].fields}
                              data={createContentState.dev_additional_dates}
                            />
                          )}

                          {type === 'ioc' && (
                            <Ioc
                              data={createContentState.ioc || []}
                              id={createContentState.id}
                              type='report'
                            />
                          )}

                          {type ===
                            'mitre-navigator-tags-selection-summary' && (
                            <MitreTags
                              tagTactics={createContentState.tag_tactics || []}
                              devContent={createContentState.dev_content}
                              tags={createContentState.tags || []}
                              devTags={createContentState.tags_dev || []}
                              type='report'
                            />
                          )}

                          {type === 'connectors' && (
                            <ConnectorsWithModal
                              data={
                                createContentState.dev_content[
                                  section.internal_key
                                ]
                              }
                              internal_key={section.internal_key}
                              categories={
                                component[1].tags_search_popup_tag_categories
                              }
                              categorySlug={component[1].categorySlug}
                              tagTactics={createContentState.tag_tactics || []}
                              devTags={createContentState.tags_dev || []}
                              devContent={createContentState.dev_content}
                              tags={createContentState.tags || []}
                              alert
                            />
                          )}

                          {type === 'associated-profiles' && (
                            <AssociatedProfiles
                              isExpanded={
                                createContentState.dev_content[
                                  section.internal_key
                                ].is_expanded
                              }
                            />
                          )}

                          {type === 'associated-alerts' && (
                            <AssociatedAlerts
                              isExpanded={
                                createContentState.dev_content[
                                  section.internal_key
                                ].is_expanded
                              }
                            />
                          )}

                          {type === 'content-distribution' &&
                            isContentBeingEdited && (
                              <ContentDistribution
                                data={
                                  createContentState.dev_content[
                                    section.internal_key
                                  ]
                                }
                                internal_key={section.internal_key}
                                type='report'
                              />
                            )}

                          {type === 'tinymce-table-column-name-binded' &&
                            createContentState.published === 1 && (
                              <FroalaEditorComponent
                                label='Update summary'
                                internal_key={section.internal_key}
                                textKey='text'
                              />
                            )}
                        </Fragment>
                      )
                    })}
                  </div>
                )}
              </div>
            ),
        )}
      </div>

      <div className='create-alert__right'>
        {configuration.map(
          (section) =>
            createContentState.dev_content[section.internal_key] &&
            section.is_sidebar_section && (
              <div key={section.internal_key} className='content-section'>
                <CreateContentHeader
                  handleCompleteSections={handleCompleteSections}
                  handleExpandedSections={handleExpandedSections}
                  sectionData={
                    createContentState.dev_content[section.internal_key]
                  }
                  sectionSettings={section}
                  shouldBeDisabled={returnDataPerSection(section.internal_key)}
                  status={createContentState.status_id}
                  isManager={createContentState.can_publish_or_deny}
                  handleSectionStatus={handleSectionStatus}
                />

                {((createContentState.can_publish_or_deny &&
                  !createContentState.dev_content[section.internal_key]
                    .is_rejected &&
                  !createContentState.dev_content[section.internal_key]
                    .is_accepted) ||
                  (!createContentState.can_publish_or_deny &&
                    !createContentState.dev_content[section.internal_key]
                      .is_complete)) &&
                  Object.values(section.columns).map((component) => (
                    <Fragment key={component.type}>
                      {component.type === 'severity' && <Severity />}

                      {component.type === 'sector-relevance' && (
                        <SectorRelevance
                          data={{
                            sectors: createContentState.relevance
                              ? createContentState.relevance.sectors
                              : [],
                            sector_priorities: createContentState.relevance
                              ? createContentState.relevance.sector_priorities
                              : [],
                          }}
                          type='report'
                        />
                      )}

                      {component.type === 'pdf-settings' && (
                        <PdfSettings
                          data={{
                            dev_are_numbered_headings_enabled:
                              createContentState.dev_are_numbered_headings_enabled,
                            dev_is_table_included_in_pdf:
                              createContentState.dev_is_table_included_in_pdf,
                            dev_pdf_cover_enabled:
                              createContentState.dev_pdf_cover_enabled,
                          }}
                          internal_key={section.internal_key}
                        />
                      )}

                      {component.type === 'tlp' && isContentBeingEdited && (
                        <Tlp internal_key={section.internal_key} />
                      )}

                      {component.type === 'author-settings' &&
                        isContentBeingEdited && (
                          <AuthorSettings
                            data={{
                              dev_hide_all_authors_data:
                                createContentState.dev_hide_all_authors_data,
                              dev_show_authors_data:
                                createContentState.dev_show_authors_data,
                              dont_show_authors:
                                createContentState.dont_show_authors,
                              author_organisation:
                                createContentState.author_organisation,
                              author_name: createContentState.author_name,
                            }}
                            internal_key={section.internal_key}
                          />
                        )}
                    </Fragment>
                  ))}
              </div>
            ),
        )}
      </div>
    </div>
  )
}

export default ContentSection
