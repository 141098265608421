/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  TextField,
} from '@material-ui/core'
import { SketchPicker } from 'react-color'
import { Delete, KeyboardArrowDown } from '@mui/icons-material'
import { isEqual } from 'lodash'
import { formatDate } from '../../../shared/helpers/formatDate'
import useNotification from '../../../hooks/useNotification'
import {
  createForumCategory,
  updateForumCategory,
} from '../../../shared/services/api/admin/forum/categories'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Data {
  id?: number | string | null
  name?: string
  color?: string
  description: string
  updated_at?: string
  slug?: string
}
interface Props {
  data: Data
  newCategory: boolean
  setConfirmDelete?: Dispatch<SetStateAction<string | number | false>>
}

const SingleForumCategory = ({
  newCategory,
  data,
  setConfirmDelete,
}: Props) => {
  const [originalCategoryData, setOriginalCategoryData] = useState<Data>(
    cloneDeep(data),
  )
  const [categoryData, setDategoryData] = useState<Data>(data)
  const { id, name, color, description, updated_at, slug } = categoryData
  const [hasBeenEdited, setHasBeenEdited] = useState<boolean>(false)
  const [isExpanded, setExpanded] = useState<boolean>(newCategory)
  const { successToast, errorToast } = useNotification()
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const { mutate: createForumCategoryMutation } = useMutation({
    mutationFn: createForumCategory,
  })
  const { mutate: updateForumCategoryMutation } = useMutation({
    mutationFn: updateForumCategory,
  })

  useEffect(() => {
    if (!newCategory && !isEqual(originalCategoryData, categoryData)) {
      setHasBeenEdited(true)
    }
  }, [categoryData])

  const handleState = (property: string, text: string) => {
    setDategoryData((prevState) => {
      return {
        ...prevState,
        [property]: text,
      }
    })
  }

  const handleUpdateCategory = async () => {
    const data = { ...categoryData }
    let response: any
    try {
      if (newCategory) {
        delete data?.id
        delete data?.updated_at
        createForumCategoryMutation(data)
      } else if (data?.id) {
        updateForumCategoryMutation({ categoryId: data?.id, data })
      }

      if (response?.error) {
        errorToast({ message: 'Something went wrong' })
      } else {
        successToast({
          message: newCategory
            ? 'Category created successfully'
            : 'Category updated successfully',
        })
      }
      queryClient.invalidateQueries({
        queryKey: ['categories-admin'],
      })

      setOriginalCategoryData(categoryData)
      setHasBeenEdited(false)
    } catch (err) {}
  }

  const shouldBlockSubmit = () => {
    return (
      newCategory &&
      (categoryData?.name === '' ||
        categoryData?.color === '' ||
        categoryData?.description === '')
    )
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleUpdateCategory()
      }}
      style={{
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1607843137)',
        borderRadius: 12,
        backgroundColor: '#fff',
        padding: 15,
        border: '1px solid #dddddd',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {!newCategory ? (
            <IconButton
              onClick={() => setExpanded(!isExpanded)}
              style={{ borderRadius: 0, color: '#000', padding: 3 }}
            >
              <KeyboardArrowDown
                style={{ transform: isExpanded ? 'rotate(180deg)' : undefined }}
              />
            </IconButton>
          ) : null}
          <div
            style={{
              width: 20,
              height: 20,
              background: color,
              border: '2px solid #000',
            }}
          />
          <h3>{name}</h3>
        </div>

        {!newCategory ? (
          <span style={{ fontSize: 12, color: '#888' }}>
            Last updated: {formatDate(updated_at)}
          </span>
        ) : null}
      </div>

      {isExpanded ? (
        <>
          <Divider />
          <div style={{ display: 'flex', gap: 10 }}>
            <div style={{ width: 'calc(100% - 120px)' }}>
              <h5 style={{ marginBottom: 10 }}>Category Name</h5>

              <TextField
                variant='outlined'
                placeholder='Enter category name'
                fullWidth
                value={name || ''}
                disabled={slug === 'general'}
                onChange={(event) => {
                  handleState('name', event.target.value)
                }}
                inputProps={{
                  style: {
                    maxWidth: 'unset',
                    padding: '12px 14px',
                    fontSize: 13,
                    cursor: 'text',
                  },
                }}
              />
            </div>

            <div style={{ width: 120 }}>
              <h5 style={{ marginBottom: 10 }}>Category color</h5>

              <Button
                onClick={() => setColorPickerOpen(!colorPickerOpen)}
                startIcon={
                  <span style={{ width: 20, height: 20, background: color }} />
                }
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.26)',
                  minWidth: 120,
                }}
              >
                {color}
              </Button>

              {colorPickerOpen ? (
                <ClickAwayListener
                  onClickAway={() => setColorPickerOpen(false)}
                >
                  <SketchPicker
                    className='colour-picker'
                    color={color || ''}
                    onChange={(value: any) => handleState('color', value?.hex)}
                  />
                </ClickAwayListener>
              ) : null}
            </div>
          </div>
          <Divider />
          <h5>Category Description</h5>
          <TextField
            variant='outlined'
            placeholder='Enter category name'
            fullWidth
            value={description || ''}
            onChange={(event) => {
              handleState('description', event.target.value)
            }}
            inputProps={{
              style: {
                maxWidth: 'unset',
                cursor: 'text',
                padding: '12px 14px',
                fontSize: 13,
              },
            }}
          />
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {!newCategory ? (
              <Button
                onClick={() => setConfirmDelete && id && setConfirmDelete(id)}
                disabled={slug === 'general'}
                style={{
                  backgroundColor: '#e0e0e0',
                  textTransform: 'capitalize',
                }}
                startIcon={<Delete />}
              >
                Delete category
              </Button>
            ) : null}

            {hasBeenEdited || newCategory ? (
              <div style={{ display: 'flex', gap: 15 }}>
                <Button
                  onClick={() => {
                    setHasBeenEdited(false)
                    setDategoryData(originalCategoryData)
                  }}
                  style={{
                    backgroundColor: '#e0e0e0',
                    textTransform: 'capitalize',
                  }}
                >
                  Cancel changes
                </Button>

                <Button
                  disabled={shouldBlockSubmit()}
                  type='submit'
                  // onClick={() => handleUpdateCategory()}
                  style={{
                    backgroundColor: shouldBlockSubmit()
                      ? '#bababa'
                      : '#006fff',
                    textTransform: 'capitalize',
                    color: '#fff',
                  }}
                >
                  {newCategory ? 'Create new Category' : 'Save changes'}
                </Button>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </form>
  )
}

export default SingleForumCategory
