import React, { Fragment } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { useUserContext } from '../../../../../../../context/userContext'

const HoverMenu = ({
  setMenuHoverAnchor,
  handleCommentMenuAction,
  menuHoverAnchor,
  handleMenuHoverClose,
  commentId,
  subcomment,
  canEdit,
  canDelete,
  authorId,
  isReported,
  isDeleted,
}) => {
  const {
    user: {
      user_info: { id },
    },
  } = useUserContext()
  const replyMenuItem = { name: 'Reply' }
  const editMenuItem = { name: 'Edit' }
  const deleteMenuItem = { name: 'Delete' }
  const reportMenuItem = { name: 'Report' }

  let commentsMenu = []

  if (!subcomment && !isDeleted) {
    commentsMenu = [...commentsMenu, replyMenuItem]
  }

  if (canEdit && !isReported && !isDeleted) {
    commentsMenu = [...commentsMenu, editMenuItem]
  }

  if (canDelete && !isReported && !isDeleted) {
    commentsMenu = [...commentsMenu, deleteMenuItem]
  }

  if (id !== authorId && !isDeleted && !isReported) {
    commentsMenu = [...commentsMenu, reportMenuItem]
  }

  return (
    <Fragment>
      <IconButton
        style={{ borderRadius: 6, padding: 0 }}
        onClick={(event) => setMenuHoverAnchor(event.currentTarget)}
        size='small'
      >
        <span className='icon-more' />
      </IconButton>

      <Menu
        anchorEl={menuHoverAnchor}
        keepMounted
        open={Boolean(menuHoverAnchor)}
        onClose={handleMenuHoverClose}
      >
        {commentsMenu.length === 0 && (
          <div style={{ padding: '5px 12px', fontSize: 12 }}>
            No actions available
          </div>
        )}

        {commentsMenu.map((item) => (
          <MenuItem
            onClick={() => handleCommentMenuAction(item.name, commentId)}
            key={item.name}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  )
}

export default HoverMenu
