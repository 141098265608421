import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { SnackbarProvider } from 'notistack'
import { MsalProvider } from '@azure/msal-react'
import {
  BrowserCacheLocation,
  PublicClientApplication,
  UrlString,
} from '@azure/msal-browser'

Sentry.init({
  dsn: 'https://3567bdc36368497ca41a2d2d7b785a28@o1223727.ingest.sentry.io/6378535',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV !== 'development',
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null
    }
    return event
  },
})

const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    redirectUri: '',
    postLogoutRedirectUri: '/app/login',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
})

const isAuthPopup = () => {
  const hash = window.location.hash
  return UrlString.hashContainsKnownProperties(hash)
}

if (isAuthPopup()) {
  ReactDOM.render(null, document.getElementById('app'))
} else {
  ReactDOM.render(
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <MsalProvider instance={pca}>
          <App />
        </MsalProvider>
      </SnackbarProvider>
    </BrowserRouter>,
    document.getElementById('app'),
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
