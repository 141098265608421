import { Button, Dialog } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { tosContent } from './tosData'

/**
 * TosModal
 * @param {bool} isTosActive - is terms modal active
 * @param {func} acceptTerms - accepts the terms
 */

const TosModal = ({ isTosActive, acceptTerms }) => {
  return (
    <Dialog
      style={{ zIndex: 9999 }}
      fullWidth
      maxWidth='md'
      open={isTosActive === true}
      PaperProps={{ style: { maxHeight: '60vh' } }}
    >
      <div
        style={{
          padding: '35px 40px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2 style={{ textDecoration: 'underline', marginBottom: 20 }}>
          ThreatMatch Terms of Use
        </h2>

        <span>
          These Terms of Use (<b>&apos;Terms&apos;</b>) apply to the use of the
          THREATMATCH service. You must read and accept these Terms before you
          access and use THREATMATCH. By accessing and using THREATMATCH you
          agree that you and the Company will be bound by the terms and
          conditions set out below.
        </span>

        <div dangerouslySetInnerHTML={{ __html: tosContent.content_text }} />

        <span style={{ margin: '20px 0' }}>
          To see SecAlliance Privacy Policy, please click{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href='https://www.secalliance.com/privacy-policy'
            target='_blank'
            rel='noopener noreferrer'
          >
            here
          </a>
          .
        </span>

        <Button
          onClick={() => acceptTerms()}
          style={{
            textTransform: 'capitalize',
            backgroundColor: '#39c333',
            color: '#fff',
            fontWeight: 800,
            padding: '8px 26px',
            borderRadius: 12,
          }}
        >
          I accept the ThreatMatch Terms of Use
        </Button>
      </div>
    </Dialog>
  )
}

TosModal.displayName = 'TosModal'

/**
 * The properties.
 * @type {Object}
 */

TosModal.propTypes = {
  isTosActive: PropTypes.bool.isRequired,
  acceptTerms: PropTypes.func.isRequired,
}

export default TosModal
