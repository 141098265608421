/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { modules, roles } from './data'
import { MenuItem, Select } from '@material-ui/core'
import { Tooltip } from '@mui/material'

const GroupsTable = ({
  privileges,
  defaultGroupId,
  handleUserPermissions,
  chosenGroups,
  chosenModules,
  handleCycleUserPermissions,
}) => {
  return chosenGroups.map(({ id, name }) => {
    const permissions = privileges && privileges[id]?.module_access
    const isDefaultGroup = defaultGroupId === id

    return (
      <tr key={id} style={{ fontSize: 13, textAlign: 'center' }}>
        <td
          style={{
            width: 60,
            maxWidth: 60,
            fontWeight: isDefaultGroup ? 600 : 400,
            fontSize: 14,
            color: isDefaultGroup ? '#000' : '#222222',
            paddingLeft: !handleUserPermissions ? 30 : null,
            cursor: 'pointer',
          }}
        >
          <Tooltip
            arrow
            placement='top'
            title={
              !handleCycleUserPermissions
                ? ''
                : isDefaultGroup
                ? 'This group will drive the maximum privilege level for any groups for this user'
                : 'Click to cycle through roles'
            }
          >
            <span
              onClick={() =>
                handleCycleUserPermissions &&
                !isDefaultGroup &&
                handleCycleUserPermissions(id)
              }
            >{`${name}${(isDefaultGroup && ' *') || ''}`}</span>
          </Tooltip>
        </td>

        {modules.map((module) => {
          const doesModulePermissionExist =
            permissions && permissions[module.id]

          if (doesModulePermissionExist?.role_id === 9999) {
            doesModulePermissionExist.role_id = 1
          }

          const roleName =
            doesModulePermissionExist &&
            roles.find((role) => role.id === doesModulePermissionExist.role_id)
          const isModuleAvailable =
            (privileges[defaultGroupId]?.module_access &&
              privileges[defaultGroupId].module_access[module.id]) ||
            false
          const availableRoles = roles.filter(
            (role) => role.id <= isModuleAvailable.role_id,
          )
          const isModuleAvailableAtOrgLevel = chosenModules?.includes(module.id)

          return (
            <td
              style={{
                color: '#444444',
                fontSize: 12,
                fontWeight: 400,
                width: 60,
                maxWidth: 60,
              }}
              key={module.id}
            >
              {!handleUserPermissions ? (
                <span
                  style={{
                    padding: '6px 16px',
                    borderRadius: 12,
                    backgroundColor: !isModuleAvailable
                      ? null
                      : roleName?.id === 3
                      ? 'rgb(255 0 51 / 30%)'
                      : roleName?.id === 2
                      ? 'rgb(255 191 0 / 30%)'
                      : roleName?.id === 1 && 'rgb(58 170 53 / 30%)',
                  }}
                >
                  {(isModuleAvailable && roleName?.name) || '-'}
                </span>
              ) : isModuleAvailableAtOrgLevel &&
                (isDefaultGroup || (!isDefaultGroup && isModuleAvailable)) ? (
                <Select
                  value={(doesModulePermissionExist && roleName?.id) || ''}
                  style={{
                    fontWeight: isDefaultGroup ? 600 : 400,
                    borderRadius: 12,
                    backgroundColor:
                      roleName?.id === 3
                        ? 'rgb(255 0 51 / 30%)'
                        : roleName?.id === 2
                        ? 'rgb(255 191 0 / 30%)'
                        : roleName?.id === 1 && 'rgb(58 170 53 / 30%)',
                  }}
                  variant='outlined'
                  displayEmpty
                  disabled={!isModuleAvailableAtOrgLevel}
                  className='admin-role-select'
                  IconComponent={() => (
                    <span
                      style={{
                        position: 'absolute',
                        right: 7,
                        transition: 'all .2s ease',
                        fontSize: 12,
                      }}
                      className='icon-down'
                    />
                  )}
                  renderValue={
                    doesModulePermissionExist === undefined
                      ? () => <span style={{ color: '#aaa' }}>No access</span>
                      : null
                  }
                  onChange={(event) =>
                    handleUserPermissions &&
                    handleUserPermissions(
                      id,
                      module.id,
                      event.target.value,
                      isDefaultGroup,
                    )
                  }
                  onClose={() =>
                    setTimeout(() => document.activeElement.blur(), 0)
                  }
                >
                  {isDefaultGroup &&
                    roles.map(({ id, name }) => (
                      <MenuItem
                        style={{
                          color:
                            id === 3
                              ? '#ff0033'
                              : id === 2
                              ? '#ffbf00'
                              : id === 1 && '#3aaa35',
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        key={id}
                        value={id}
                      >
                        {name}
                      </MenuItem>
                    ))}

                  {!isDefaultGroup &&
                    availableRoles.map(({ id, name }) => (
                      <MenuItem
                        style={{
                          color:
                            id === 3
                              ? '#ff0033'
                              : id === 2
                              ? '#ffbf00'
                              : id === 1 && '#3aaa35',
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        key={id}
                        value={id}
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <span> - </span>
              )}
            </td>
          )
        })}

        {!handleUserPermissions && <td />}
      </tr>
    )
  })
}

export default GroupsTable
