/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ClickAwayListener, Divider } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Dispatch, SetStateAction } from 'react'

interface ReactionPopupProps {
  reactions: any[]
  setIsReactionPopupOpen: Dispatch<SetStateAction<boolean>>
  readonly isSender: boolean
}

export const ReactionPopup = ({
  reactions,
  setIsReactionPopupOpen,
  isSender,
}: ReactionPopupProps) => {
  return (
    <ClickAwayListener onClickAway={() => setIsReactionPopupOpen(false)}>
      <div
        style={{
          backgroundColor: '#283143',
          border: '1px solid #666',
          color: '#fff',
          width: '350px',
          minHeight: '100px',
          height: 'auto',
          borderRadius: '12px',
          position: 'absolute',
          bottom: 50,
          zIndex: 10,
          left: isSender ? '' : 0,
          right: isSender ? 0 : '',
          boxShadow: '0px 0px 33px 10px rgba(66, 68, 90, 1)',
        }}
      >
        <div
          style={{
            paddingTop: '12px',
            position: 'relative',
          }}
        >
          <h4
            style={{
              textAlign: 'center',
            }}
          >
            Reactions for message
          </h4>

          <Close
            style={{
              position: 'absolute',
              right: '12px',
              bottom: '0',
              cursor: 'pointer',
            }}
            onClick={() => setIsReactionPopupOpen(false)}
          />
        </div>
        <Divider style={{ width: '100%', marginTop: 12 }} />
        {reactions.map((reaction) => (
          <div
            key={reaction.id}
            style={{
              display: 'flex',
              paddingLeft: '12px',
              paddingTop: '12px',
              gap: '8px',
              fontSize: 14,
            }}
          >
            <span>{reaction.author.display_name}</span>
            <span>{reaction.content}</span>
          </div>
        ))}
      </div>
    </ClickAwayListener>
  )
}
