import { useState } from 'react'
import {
  Button,
  Divider,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import LinkIcon from '@material-ui/icons/Link'
import { useGlobalContext } from '../../../../context/globalContext'
import { isMobile } from 'react-device-detect'
import { ArrowRightAlt, Map } from '@material-ui/icons'
import PdfPasswordDialog from './PdfPasswordDialog'
import { nodeDetails } from '../../../../shared/services/api/linkAnalysis/linkAnalysis'
import Spinner from '../../../../shared/Spinner'
import useNotification from '../../../../hooks/useNotification'
import { useUserContext } from '../../../../context/userContext'
import { useViewStateContext } from '../../../../context/viewStateContext'

const ContentTopBar = ({
  data,
  handleMarkStatus,
  handleCopyPieceLink,
  type,
  setToastMessage,
  resetPiece,
}) => {
  const { isContentBeingEdited } = useGlobalContext()
  const {
    user: {
      modules: { view_mode },
    },
  } = useUserContext()
  const {
    workspaces,
    addWorkspace,
    setLinkAnalysisState,
    setLinkAnalysisOpen,
  } = useViewStateContext()
  const [passwordDialog, setPasswordDialog] = useState(false)
  const history = useHistory()
  const { warningToast } = useNotification()
  const [isLinkAnalysisLoading, setLinkAnalysisLoading] = useState(false)
  const [isLinkAnalysisMapLoading, setLinkAnalysisMapLoading] = useState(false)

  const token = () => {
    return localStorage.getItem('access_token')
  }
  const isBeingEdited =
    isContentBeingEdited && typeof isContentBeingEdited[1] === 'number'
  const [menuOpen, setMenuOpen] = useState(undefined)
  const scenarioPdfOptions = [
    { name: 'Framework Narrative', id: 1 },
    { name: 'Framework Narrative + TTP Tags', id: 2 },
    { name: 'Framework Narrative + Mitre Navigator', id: 3 },
    { name: 'Framework Narrative + TTP Tags + Mitre Navigator', id: 4 },
    { name: 'Mitre Navigator', id: 6 },
    { name: 'Mitre Navigator (only selected techniques)', id: 8 },
  ]

  const handleOpenLinkAnalysis = async (isMapActive) => {
    const linkAnalysisAlreadyOpen = workspaces.find(
      (workspace) => workspace.state === 'analysis',
    )

    if (linkAnalysisAlreadyOpen) {
      warningToast({
        message:
          'A link analysis workspace is currently open. Please close it before opening a new one',
      })

      return false
    }

    try {
      isMapActive
        ? setLinkAnalysisMapLoading(true)
        : setLinkAnalysisLoading(true)
      const response = await nodeDetails(token(), type, data.id)

      if (response.error) {
        setLinkAnalysisLoading(false)
        setLinkAnalysisMapLoading(false)
        warningToast({ message: response.message })

        return false
      }

      isMapActive
        ? setLinkAnalysisMapLoading(false)
        : setLinkAnalysisLoading(false)
      setLinkAnalysisState((current) => ({
        ...current,
        currentChart: { items: response },
        isMapActive,
      }))
      addWorkspace('analysis', null, 'Create link analysis', {})
      setLinkAnalysisOpen(true)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='single-piece__header-inner' style={{ width: '95%' }}>
      <PdfPasswordDialog
        passwordDialog={passwordDialog}
        setPasswordDialog={setPasswordDialog}
        setToastMessage={setToastMessage}
        type={type}
        id={data.id}
      />

      <div className='single-piece__header-left' style={{ display: 'flex' }}>
        {isMobile && (
          <>
            <Tooltip arrow title='Back' placement='right'>
              <IconButton
                onClick={() => resetPiece()}
                style={{ padding: 2, margin: 0, alignItems: 'center' }}
              >
                <ArrowRightAlt
                  style={{
                    fontSize: 32,
                    color: '#000',
                    transform: 'rotate(180deg)',
                  }}
                />
              </IconButton>
            </Tooltip>

            <Divider className='mobile-divider' />
          </>
        )}

        <Button
          onClick={() => handleMarkStatus('unread')}
          variant='contained'
          style={{ fontSize: 12, padding: '3px 9px' }}
          color='default'
          startIcon={
            <span
              className='icon-mail'
              style={{ color: '#283143', fontSize: 20 }}
            />
          }
        >
          <span>Mark as unread</span>
        </Button>

        <Button
          variant='contained'
          color='default'
          onClick={() =>
            handleMarkStatus(data.is_flagged ? 'unflagged' : 'flagged')
          }
          // onClick={() => handleMarkStatus(data.is_flagged ? 'unflagged' : 'flagged')}
          style={{ fontSize: 12, padding: '3px 9px' }}
          startIcon={
            <span
              className='icon-flag'
              style={{ fontSize: 20, color: data.is_flagged ? 'red' : 'black' }}
            />
          }
        >
          <span>{data.is_flagged ? 'Unfollow' : 'Follow'}</span>
        </Button>

        <Button
          onClick={() =>
            handleCopyPieceLink(type === 'profile' ? data.typeSlug : type)
          }
          variant='contained'
          color='default'
          style={{ fontSize: 12, padding: '3px 9px' }}
          startIcon={<LinkIcon style={{ fontSize: 20 }} />}
        >
          <span>Copy link</span>
        </Button>

        {data.is_editable && !isMobile && (
          <Button
            onClick={() => {
              if (isBeingEdited) {
                setToastMessage([
                  'warning',
                  'A content is currently being edited. Exit Create Content Mode.',
                ])
              } else {
                history.push(
                  `/app/content-create/${
                    type === 'profile' ? data.typeSlug : type
                  }/${data.id}`,
                )
                // if (localStorage.getItem('editModeActive') == '1') {
                //   setToastMessage(['warning', 'A content is currently being edited in another browser tab.'])
                //   return false
                // } else {
                //   history.push(`/app/content-create/${type === 'profile' ? data.typeSlug : type}/${data.id}`)
                // }
              }
            }}
            variant='contained'
            style={{
              fontSize: 12,
              padding: '3px 9px',
            }}
            color='default'
            startIcon={<span style={{ fontSize: 20 }} className='icon-edit' />}
          >
            <span>Edit</span>
          </Button>
        )}
      </div>

      <div className='single-piece__header-right'>
        {view_mode.find((mod) => mod.slug === 'analysis' && mod.has_access) &&
          data.content.coordinates && (
            <Button
              startIcon={
                isLinkAnalysisMapLoading ? (
                  <Spinner centerHorizontally size={17.5} />
                ) : (
                  <Map />
                )
              }
              onClick={() => handleOpenLinkAnalysis(true)}
            >
              <span style={{ fontSize: 11 }}>Map</span>
            </Button>
          )}
        {view_mode.find((mod) => mod.slug === 'analysis' && mod.has_access) && (
          <Button
            startIcon={
              isLinkAnalysisLoading ? (
                <Spinner centerHorizontally size={17.5} />
              ) : (
                <span className='icon-analysis' style={{ color: '#283143' }} />
              )
            }
            onClick={() => handleOpenLinkAnalysis(false)}
          >
            <span style={{ fontSize: 11 }}>Link analysis</span>
          </Button>
        )}

        {type === 'scenario' ? (
          <>
            <Button
              startIcon={
                <span
                  className='icon-page'
                  style={{ fontSize: 22, color: '#283143' }}
                />
              }
              onClick={(event) => setMenuOpen(event.currentTarget)}
            >
              <span style={{ fontSize: 11 }}>View PDF</span>
            </Button>

            <Menu
              anchorEl={menuOpen !== undefined && menuOpen}
              open={menuOpen !== undefined}
              onClose={() => setMenuOpen(undefined)}
            >
              {scenarioPdfOptions.map((i) => (
                <MenuItem
                  key={i.id}
                  onClick={() => {
                    setPasswordDialog([true, i.id])
                    setMenuOpen(undefined)
                  }}
                >
                  {i.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <Button
            startIcon={
              <span
                className='icon-page'
                style={{ fontSize: 22, color: '#283143' }}
              />
            }
            onClick={() => setPasswordDialog(true)}
          >
            <span style={{ fontSize: 11 }}>View PDF</span>
          </Button>
        )}

        <Button
          startIcon={
            <span
              className='icon-message'
              style={{ fontSize: 22, color: '#283143' }}
            />
          }
          onClick={() =>
            history.push({
              pathname: '/app/support',
              state: {
                type: 'rfi',
                rfi_category: 1,
                rfi_entity_title: data.title,
                redirect_url: '/app/workspace',
              },
            })
          }
        >
          <span style={{ fontSize: 11 }}>Ask an analyst</span>
        </Button>
      </div>
    </div>
  )
}

export default ContentTopBar
