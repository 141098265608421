import React, { useEffect, useState } from 'react'
import { Button, Divider } from '@material-ui/core'
import { Tab, Tabs } from '@mui/material'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import SystemSettingsList from './SystemSettingsList'
import EditScenarioType from './EditLayouts/EditScenarioType'
import EditScenarioFramework from './EditLayouts/EditScenarioFramework'
import EditTag from './EditLayouts/EditTag'
import EditTagCategory from './EditLayouts/EditTagCategory'
import ScenarioFrameworkList from './ScenarioFrameworkList/ScenarioFrameworkList'

const TabWrapper = ({ tabUrl, title, tabs }) => {
  const [tab, setTab] = useState(tabs[0])
  const location = useLocation()
  const history = useHistory()
  const isView = () =>
    location.pathname === `/app/admin/system-settings/${tabUrl}/${tab}`

  const tabHandler = (newTab) => {
    if (newTab !== tab) {
      if (tabs.includes(newTab)) {
        setTab(newTab)
      } else if (!isNaN(newTab)) {
        setTab(path.slice(-2)[0])
      }
    }
    history.push(`/app/admin/system-settings/${tabUrl}/${newTab}`)
  }

  useEffect(() => {
    const path = location.pathname.split('/')
    const type = path.slice(-1)[0]

    type !== tab && setTab(type)
  }, [location.pathname])

  return (
    <>
      {isView() && (
        <>
          <div className='groups-header'>
            <h2>{title || ''}</h2>

            <Button
              onClick={() =>
                history.push(`/app/admin/system-settings/${tabUrl}/${tab}/new`)
              }
              variant='contained'
              className='create-new'
              color='primary'
            >
              Create New{' '}
              {tab === 'tag-categories' ? 'Category' : tab.slice(0, -1)}
            </Button>
          </div>

          <Divider style={{ margin: '20px 0 10px 0' }} />

          <Tabs
            value={tab}
            style={{ minHeight: 32, marginBottom: 20 }}
            onChange={(event, newValue) => tabHandler(newValue)}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab}
                value={tab}
                label={tab === 'tag-categories' ? 'Tag Categories' : tab}
                className='tab-scenario'
              />
            ))}
          </Tabs>
        </>
      )}

      <Switch>
        {tabs.map(
          (singleTab) =>
            singleTab !== 'frameworks' && (
              <Route
                key={{ singleTab }}
                path={`/app/admin/system-settings/${tabUrl}/${singleTab}`}
              >
                <SystemSettingsList
                  tabUrl={tabUrl + '/' + singleTab}
                  isTags={tabUrl === 'tags'}
                />
              </Route>
            ),
        )}

        <Route
          exact
          path='/app/admin/system-settings/scenario-configuration/frameworks'
          component={ScenarioFrameworkList}
        />
      </Switch>

      <Route
        exact
        path='/app/admin/system-settings/tags/tags/:id'
        component={EditTag}
      />

      <Route
        exact
        path='/app/admin/system-settings/tags/tag-categories/:id'
        component={EditTagCategory}
      />

      <Route
        exact
        path='/app/admin/system-settings/scenario-configuration/types/:id'
        component={EditScenarioType}
      />

      <Route
        exact
        path='/app/admin/system-settings/scenario-configuration/frameworks/:id'
        component={EditScenarioFramework}
      />
    </>
  )
}

export default TabWrapper
