import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import Spinner from '../../../../shared/Spinner'
import React, { useState } from 'react'
import { downloadPdf } from '../../../../shared/services/api/alerts/alerts'
import useNotification from '../../../../hooks/useNotification'
import {
  hasNumber,
  hasMixed,
  hasSpecial,
} from '../../../Account/AccountTabs/Tabs/ChangePassword/PasswordStrength'

const PdfPasswordDialog = ({
  setToastMessage,
  passwordDialog,
  setPasswordDialog,
  type,
  id,
}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const { errorToast, warningToast } = useNotification()
  const [noPasswordChecked, setNoPasswordChecked] = useState(false)

  const handleChange = (e) => {
    const specialChars = `[!#@$%^&*)(+=._-]`
    const lastCharacter = e.target.value.slice(-1)
    const hasAlphanumeric = (value) => new RegExp(/^[a-z0-9]+$/i).test(value)

    if (hasAlphanumeric(lastCharacter)) {
      setPassword(e.target.value)
    } else if (specialChars.includes(lastCharacter)) {
      setPassword(e.target.value)
    } else {
      warningToast({ message: `${lastCharacter} is not a valid character` })

      return false
    }
  }

  const setPdfPassword = async () => {
    if (
      !noPasswordChecked &&
      (!password.length > 8 ||
        !hasNumber(password) ||
        !hasMixed(password) ||
        !hasSpecial(password))
    ) {
      errorToast({
        message:
          'Password should have minimum 8 characters, at least one lowercase letter, one uppercase letter, one number and one special character ([!#@$%^&*)(+=._-])',
      })
      return
    }

    setIsLoading(true)

    try {
      const data =
        type === 'scenario'
          ? { password: password, scenarioPDFTypeId: passwordDialog[1] }
          : { password: password }
      if (noPasswordChecked) delete data.password
      const response = await downloadPdf(token(), type || 'report', id, data)

      if (response.error) {
        setToastMessage(['error', response.message])
        setIsLoading(false)

        return false
      }
      const url = `${response}?access_token=${token()}`
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null

      setIsLoading(false)
      setPasswordDialog(false)
    } catch (error) {}
  }

  return (
    <Dialog fullWidth maxWidth='sm' open={passwordDialog !== false}>
      <DialogTitle>Set a Password for the PDF</DialogTitle>

      <Divider style={{ margin: '10px 24px' }} />

      <form
        onSubmit={(event) => {
          event.preventDefault()
          setPdfPassword()
        }}
      >
        <div style={{ padding: '8px 24px' }}>
          {!noPasswordChecked && (
            <>
              <h5 style={{ marginBottom: '12px' }}>Set password</h5>

              <TextField
                onChange={(event) => handleChange(event)}
                value={password}
                variant='outlined'
                required
                style={{ width: '100%' }}
                inputProps={{ style: { padding: 14 } }}
                type='password'
                placeholder='Password'
                id='password'
              />
            </>
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={noPasswordChecked}
                onChange={() => setNoPasswordChecked(!noPasswordChecked)}
                icon={<span className='icon-checkbox-empty' />}
                checkedIcon={<span className='icon-checkbox-checked' />}
              />
            }
            label='No Password'
          />
        </div>

        <DialogActions
          style={{
            padding: '16px 24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={() => setPasswordDialog(false)}>Cancel</Button>

          <Button
            type='submit'
            style={{
              backgroundColor: 'rgb(0, 111, 255)',
              color: 'rgb(255, 255, 255)',
              textTransform: 'capitalize',
              fontWeight: 700,
              borderRadius: 12,
              padding: '6px 20px',
              minWidth: 146,
            }}
          >
            {isLoading ? (
              <Spinner centerHorizontally color='#fff' size={22.5} />
            ) : (
              'Download PDF'
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default PdfPasswordDialog
