import { Button, Dialog, Divider } from '@material-ui/core'
import React from 'react'

const UserLeaving = ({ isUserLeaving, setIsUserLeaving }) => {
  const handleOpenExternalPage = () => {
    if (isUserLeaving) {
      setIsUserLeaving(undefined)
      const newWindow = window.open(
        isUserLeaving,
        '_blank',
        'noopener,noreferrer',
      )
      if (newWindow) newWindow.opener = null
    }
  }

  return (
    <Dialog open={isUserLeaving.length > 0}>
      <div style={{ padding: '15px 20px' }}>
        <h3>Confirm leave</h3>

        <Divider style={{ margin: '20px 0' }} />

        <span>
          Confirm redirecting to an external url:{' '}
          <span style={{ textDecoration: 'underline', wordBreak: 'break-all' }}>
            {isUserLeaving}
          </span>
        </span>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <Button
            style={{ textTransform: 'capitalize' }}
            onClick={() => setIsUserLeaving(undefined)}
          >
            Return
          </Button>

          <Button
            style={{
              backgroundColor: '#006fff',
              textTransform: 'capitalize',
              color: '#fff',
              borderRadius: 12,
              padding: '6px 16px',
            }}
            onClick={() => handleOpenExternalPage()}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default UserLeaving
