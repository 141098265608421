/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@material-ui/core'
import { Mention, MentionsInput } from 'react-mentions'
import { useChat } from '../../../../../../context/chatContext'
import { Send } from '@mui/icons-material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { cloneDeep, uniq } from 'lodash'

interface UserInfoCopy {
  id: number
  display: string
  central_user_id: number
  display_name: string
  app_id: number
  organisation_name: string
  avatar: string
  chat_settings: {
    chat_email_notify_interval: string
    chat_email_notify_mute: boolean
    total_unread_messages: number
  }
}

interface Props {
  readonly isLoading: boolean
  readonly message: string
  handleSendMessage: (arg0: string) => void
  setMessage: Dispatch<SetStateAction<string>>
  setMentionIds: Dispatch<SetStateAction<any>>
  lockSend: boolean
}
const Mentions = ({
  isLoading,
  message,
  handleSendMessage,
  setMessage,
  setMentionIds,
  lockSend,
}: Props) => {
  const { people, activeUserChat, activeChat } = useChat()
  const [peopleCopy, setPeopleCopy] = useState<UserInfoCopy[] | []>([])

  useEffect(() => {
    const copy = cloneDeep(people?.list) as UserInfoCopy[]
    copy?.map((p) => {
      if (p?.central_user_id) {
        p.id = p?.central_user_id
      }

      if (p?.display_name) {
        p.display = p?.display_name
      }
      return p
    })
    setPeopleCopy(copy)
  }, [people])

  const handleChange = (event: any) => {
    const value = event.target.value
    const regex = /[^{}]+(?=})/g
    const mentions = value.match(regex)
    if (mentions) {
      const ids = mentions?.map((mention: string) => {
        const peopleCopy = cloneDeep(people?.list)
        const id = peopleCopy?.find(
          ({ display_name }) => display_name === mention,
        )?.central_user_id
        return id
      })
      const finalIds = uniq(ids)
      setMentionIds(finalIds)
    } else {
      setMentionIds([])
    }
    setMessage(value)
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        !isLoading && handleSendMessage(message)
      }}
    >
      <div className='mentions-wrapper'>
        <MentionsInput
          allowSpaceInQuery
          className='mention-input'
          value={message}
          placeholder='Message'
          singleLine
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          forceSuggestionsAboveCursor
          disabled={lockSend}
          onChange={handleChange}
        >
          <Mention
            className='mention-wrapper'
            trigger='@'
            appendSpaceOnAdd
            displayTransform={(id, display) => `${display}`}
            data={activeChat?.id && !activeUserChat?.id ? peopleCopy : []}
            markup='{__display__}'
          />
        </MentionsInput>

        <Button
          disabled={isLoading || lockSend}
          type='submit'
          startIcon={<Send />}
          style={{
            fontSize: 13,
            fontWeight: 600,
            borderRadius: '6px',
            backgroundColor: '#fff',
            textTransform: 'unset',
            position: 'absolute',
            top: 7,
            right: 7,
            padding: '4.5px 8px',
            width: 80,
          }}
        >
          Send
        </Button>
      </div>
    </form>
  )
}

export default Mentions
