/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import {
  followThread,
  unfollowThread,
  pinThread,
  unpinThread,
} from '../../../shared/services/api/forum/singleThread'
import parse from 'html-react-parser'
import SinglePost from './SinglePost'
import './SingleThread.scss'
import { Button, Divider } from '@material-ui/core'
import { Add, Check, Notifications, PushPin } from '@mui/icons-material'
import Spinner from '../../../shared/Spinner'
import { Pagination } from '@mui/material'
import useNotification from '../../../hooks/useNotification'
import { useUserContext } from '../../../context/userContext'
import { apiUrl } from '../../../shared/config'
import client from '../../../shared/services/http/client'
import { merge } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useQueryGetSingleThreadData } from '../../../shared/services/queries/forum/useQueryGetSingleThreadData'
import NewPost from './NewPost'

const initCached = {
  alert: {},
  profile: {},
  scenario: {},
  report: {},
}

interface ContentPreview {
  author?: string
  centralised_id?: number
  content_label?: string
  eu_id?: number | null
  id?: number
  known_as?: string
  priority?: number
  priorityName?: string
  read?: boolean
  title?: string
  uk_id?: number | null
  updated_at?: string
}

interface InitCached {
  [key: string]: { [key: number]: ContentPreview }
}
{
  alert: {
  }
  profile: {
  }
  scenario: {
  }
  report: {
  }
}

const token = () => {
  return localStorage.getItem('access_token') || ''
}

const isValidURL = (url: string) => {
  const regex =
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
  return regex?.test(url)
}

const SingleThread = () => {
  const [cachedPiecesOfContent, setCachedPiecesOfContent] =
    useState<InitCached>(initCached)
  const { forumId, categoryId, threadId }: any = useParams()
  const [openReply, setOpenReply] = useState<null | number | boolean>(null)
  const [page, setPage] = useState<number>(1)
  const { successToast } = useNotification()
  const { user } = useUserContext()
  const params: { forumId: string; categoryId: string } = useParams()
  const [editId, setEditId] = useState<number | null>(null)
  const [isBeingCached, setIsBeingCached] = useState<boolean>(false)
  const currentInstance = process.env.REACT_APP_CURRENT_INSTANCE
  const location: { state: { categoryName: string; categoryColor: string } } =
    useLocation()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQueryGetSingleThreadData(
    forumId,
    categoryId,
    threadId,
    page,
  )
  const { mutate: unfollowThreadMutation } = useMutation({
    mutationFn: unfollowThread,
    onSuccess: () => {
      successToast({ message: 'Thread unfollowed successfully' })
      const data: any = queryClient.getQueryData([
        'thread',
        forumId,
        categoryId,
        threadId,
        page,
      ])
      data.data.thread.is_followed = false
      queryClient.setQueryData(
        ['thread', forumId, categoryId, threadId, page],
        data,
      )
    },
  })
  const { mutate: followThreadMutation } = useMutation({
    mutationFn: followThread,
    onSuccess: () => {
      successToast({ message: 'Thread followed successfully' })
      const data: any = queryClient.getQueryData([
        'thread',
        forumId,
        categoryId,
        threadId,
        page,
      ])
      data.data.thread.is_followed = true
      queryClient.setQueryData(
        ['thread', forumId, categoryId, threadId, page],
        data,
      )
    },
  })
  const { mutate: unpinThreadMutation } = useMutation({
    mutationFn: unpinThread,
    onSuccess: () => {
      successToast({ message: 'Thread unpinned successfully' })
      const data: any = queryClient.getQueryData([
        'thread',
        forumId,
        categoryId,
        threadId,
        page,
      ])
      data.data.thread.is_pinned = false
      queryClient.setQueryData(
        ['thread', forumId, categoryId, threadId, page],
        data,
      )
    },
  })
  const { mutate: pinThreadMutation } = useMutation({
    mutationFn: pinThread,
    onSuccess: () => {
      successToast({ message: 'Thread pinned successfully' })
      const data: any = queryClient.getQueryData([
        'thread',
        forumId,
        categoryId,
        threadId,
        page,
      ])
      data.data.thread.is_pinned = true
      queryClient.setQueryData(
        ['thread', forumId, categoryId, threadId, page],
        data,
      )
    },
  })
  const handleFollowThread = async () => {
    if (data?.data.thread?.is_followed) {
      try {
        unfollowThreadMutation({ forumId, categoryId, threadId })
      } catch (err) {}
    } else {
      try {
        followThreadMutation({
          forumId,
          categoryId,
          threadId,
        })
      } catch (err) {}
    }
  }
  const handlePinThread = async () => {
    if (data?.data.thread?.is_pinned) {
      try {
        unpinThreadMutation({
          forumId,
          categoryId,
          threadId,
        })
      } catch (err) {}
    } else {
      try {
        pinThreadMutation({ forumId, categoryId, threadId })
      } catch (err) {}
    }
  }

  useEffect(() => {
    if (location.state?.categoryName) {
      localStorage.setItem('categoryName', location.state.categoryName)
      localStorage.setItem('categoryColor', location.state.categoryColor)
    }
  }, [params.categoryId])

  const fetchPreviewData = async (contentList: { [key: string]: any }) => {
    try {
      setIsBeingCached(true)
      const data = {
        modules: contentList,
      }
      const response = await client.post(
        apiUrl + '/api/chat/preview/contents',
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token(),
          },
        },
      )

      setTimeout(() => {
        setCachedPiecesOfContent((currentState: any) => {
          return merge(currentState, response.data)
        })
        setIsBeingCached(false)
      })

      return response.data
    } catch {}
  }

  useEffect(() => {
    if (data?.data.posts?.items) {
      const currentState = [...data?.data.posts.items]
      const finalLinks: { [key: string]: any } = {
        alert: [],
        profile: [],
        scenario: [],
        report: [],
      }
      currentState?.forEach((msg) => {
        const curLinks = currentInstance === 'uk' ? msg.uk_links : msg.eu_links
        if (curLinks && Object.keys(curLinks).length > 0) {
          Object.entries(curLinks).forEach((i: any) => {
            const module = i[0]
            const contents = i[1]
            contents?.forEach((e: any) => {
              if (!e?.id) {
                finalLinks[module] = [...finalLinks[module], e]
              }
            })
          })
        }
      })
      currentState?.forEach((msg) => {
        if (msg?.type === 'LINK' && !msg?.hasBeenParsed) {
          const strippedLink = msg?.content?.replaceAll(/<\/?[^>]+(>|$)/gi, '')
          let links: any[] = []

          strippedLink?.split(' ')?.forEach((mes: any) => {
            if (isValidURL(mes)) {
              links = [...links, parse(mes)]
            }
          })

          links?.forEach((link) => {
            const query = new URL(link)
            const id = query?.searchParams?.get('id')
            const type = query?.searchParams?.get('type')?.toLowerCase() || ''
            if (!id || !type) return false
            const finalModule =
              type === 'alert' || type === 'scenario' || type === 'report'
                ? type
                : 'profile'

            msg.contentId = Number(id)
            msg.contentType = finalModule
            msg.hasBeenParsed = true
          })
        }
      })

      let numberOfContents = 0
      Object.values(finalLinks)?.forEach((e: any) => {
        numberOfContents = numberOfContents + +e.length
      })
      numberOfContents > 0 && fetchPreviewData(finalLinks)
    }

    return () => {}
  }, [data?.data.posts])

  return (
    <div className='forum-single-thread'>
      <header className='forum__header'>
        <div
          style={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <NavLink to={`/app/forum/${params?.forumId}`}>
            <h2
              style={{
                textDecoration: 'underline',
                fontSize: '18px',
                color: '#fff',
                fontWeight: 400,
              }}
            >
              Categories
            </h2>
          </NavLink>

          <span>&gt;</span>
          <NavLink
            to={{
              pathname: `/app/forum/${params?.forumId}/${params.categoryId}`,
              state: {
                categoryName: location?.state?.categoryName,
                categoryColor: location.state?.categoryColor,
              },
            }}
            style={{
              fontSize: '18px',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              textDecoration: 'underline',
            }}
          >
            <div
              style={{
                width: '12px',
                height: '12px',
                background:
                  location.state?.categoryColor ||
                  (localStorage.getItem('categoryColor') as string) ||
                  data?.data.thread?.category?.color,
              }}
            />
            <span>
              {location.state?.categoryName ||
                localStorage.getItem('categoryName') ||
                data?.data.thread?.category?.name}
            </span>
          </NavLink>
          <span>&gt;</span>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
            {data?.data.thread?.title && parse(data?.data.thread?.title)}
          </span>
        </div>
      </header>
      <div className='forum-single-thread__title'>
        <h2
          style={{
            color: data?.data.thread?.is_reported ? '#ccc' : '#fff',
            fontStyle: data?.data.thread?.is_reported ? 'italic' : 'unset',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {data?.data.thread?.is_pinned ? (
            <div
              style={{
                border: '1px solid #006FFF',
                borderRadius: 6,
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#283142',
                marginRight: 10,
              }}
            >
              <PushPin
                style={{
                  fontSize: 16,
                  color: '#fff',
                  transform: 'rotate(315deg)',
                }}
              />
            </div>
          ) : null}
          {data?.data.thread?.is_reported
            ? 'This content is hidden'
            : data?.data.thread?.title && parse(data?.data.thread?.title)}
        </h2>
        <div style={{ display: 'flex', gap: 10 }}>
          {user?.is_community_admin ? (
            <Button
              onClick={() => handlePinThread()}
              className={data?.data.thread?.is_pinned ? 'ico-unpin' : ''}
              style={{
                textTransform: 'capitalize',
                color: '#fff',
                border: '1px solid #006fff',
                backgroundColor: data?.data.thread?.is_pinned
                  ? '#006fff'
                  : '#273042',
                fontSize: 13,
                padding: '6px 16px',
                borderRadius: 8,
              }}
              startIcon={<PushPin style={{ transform: 'rotate(315deg)' }} />}
            >
              {data?.data.thread?.is_pinned ? 'Unpin thread' : 'Pin thread'}
            </Button>
          ) : null}
          <Button
            onClick={() => handleFollowThread()}
            style={{
              textTransform: 'capitalize',
              color: '#fff',
              border: '1px solid #006fff',
              backgroundColor: data?.data.thread?.is_followed
                ? '#006fff'
                : '#273042',
              fontSize: 13,
              padding: '6px 16px',
              borderRadius: 8,
            }}
            startIcon={
              data?.data.thread?.is_followed ? <Check /> : <Notifications />
            }
          >
            {data?.data.thread?.is_followed ? 'Following' : 'Follow'}
          </Button>
        </div>
      </div>
      <div className='forum-single-thread__posts-wrapper'>
        {isLoading ? (
          <Spinner centerHorizontally size={30} />
        ) : (
          <>
            {data?.data.posts?.items?.map((post: any) => (
              <Fragment key={post?.id}>
                <SinglePost
                  page={page}
                  data={post}
                  setOpenReply={setOpenReply}
                  setEditId={setEditId}
                  cachedPiecesOfContent={cachedPiecesOfContent}
                  isBeingCached={isBeingCached}
                  threadAuthorId={data?.data.thread?.author?.centralised_id}
                />
                <Divider
                  style={{
                    margin: '20px -20px',
                    height: 2,
                    backgroundColor: '#131821',
                  }}
                />
              </Fragment>
            ))}
          </>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Pagination
            onChange={(event: any, value: any) => setPage(value)}
            page={page}
            color='primary'
            style={{ marginTop: 10 }}
            disabled={isLoading}
            count={data?.data?.posts?.lastPage}
            shape='rounded'
            hideNextButton
            hidePrevButton
          />
          <Button
            onClick={() => setOpenReply(true)}
            style={{
              textTransform: 'capitalize',
              color: '#fff',
              // border: '1px solid #006fff',
              backgroundColor: '#006fff',
              fontSize: 13,
              padding: '6px 16px',
              borderRadius: 8,
            }}
            startIcon={<Add />}
          >
            Add new post
          </Button>
        </div>
      </div>
      {openReply || editId ? (
        <NewPost
          openReply={openReply}
          isEdit={editId}
          setEditId={setEditId}
          editedContent={
            data?.data.posts?.items?.find(({ id }: any) => id === editId)
              ?.content || null
          }
          setOpenReply={setOpenReply}
          page={page}
          // setPosts={setPosts}
          setPage={setPage}
          postsNumber={data?.data.posts?.total}
          lastPage={data?.data.posts?.lastPage}
          shouldGoLastPage={
            editId ? true : data?.data.posts?.items.length >= 10
          }
          fetchPreviewData={fetchPreviewData}
          repliedPost={
            data?.data.posts?.items.find(({ id }: any) => id === openReply)
              ?.content || null
          }
        />
      ) : null}
    </div>
  )
}
export default SingleThread
