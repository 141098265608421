import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Dialog, DialogContent, Button } from '@material-ui/core'

const DeleteModal = ({ isModalOpen, setIsModalOpen, onDelete }) => {
  const [modalType, id, parentId] = isModalOpen

  return (
    <Dialog
      id='popup-blur'
      open={!!isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <div style={{ width: '458px', padding: '10px 30px' }}>
        <div
          style={{
            padding: '20px 0',
            width: '100%',
            backgroundColor: '#fff',
          }}
          id='form-dialog-title'
        >
          <h3 style={{ color: '#000', paddingLeft: '20px' }}>
            Warning! Are you sure you want to delete?
          </h3>

          <Divider style={{ width: '100%', marginTop: 30 }} />
        </div>

        <DialogContent style={{ padding: 0 }}>
          <div
            style={{
              fontSize: '16px',
              color: '#212935',
              marginBottom: '24px',
              padding: '0 5px',
            }}
          >
            {modalType === 'category'
              ? 'Deleting this category will delete all the resources attached to it.'
              : 'This resource will be permanently deleted.'}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'capitalize',
                textDecoration: 'underline',
                borderRadius: '10px',
                marginTop: '10px',
                fontSize: '14px',
                fontWeight: 700,
              }}
              onClick={() => {
                onDelete(id, parentId)
                setIsModalOpen(false)
              }}
              variant='text'
              type='button'
              color='#212935'
            >
              Delete
            </Button>

            <Button
              style={{
                minWidth: 56,
                textTransform: 'capitalize',
                backgroundColor: '#006BF5',
                borderRadius: '12px',
                marginTop: '10px',
              }}
              onClick={() => setIsModalOpen(false)}
              variant='contained'
              type='button'
              color='primary'
            >
              Go back
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

DeleteModal.propTypes = {
  isModalOpen: PropTypes.instanceOf(Array).isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

DeleteModal.displayName = 'DeleteModal'

export default DeleteModal
