/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar } from '@material-ui/core'
import { useUserContext } from '../../../../../../context/userContext'
import { useChat } from '../../../../../../context/chatContext'
import { apiUrl } from '../../../../../../shared/config'
import SingleMessage from './SingleMessage'
import { Dispatch, SetStateAction, useState } from 'react'
import PreviewItem from '../PreviewItem'
import { ChatData } from '../../SingleConversation'
interface ExternalLinkData {
  readonly description: string
  readonly image: string
  readonly title: string
  readonly url: string
}

interface AuthorData {
  readonly centralised_id: number
  readonly display_name: string
}

interface Props {
  readonly showPreview: any
  readonly setShowPreview: Dispatch<any>
  readonly id: number | undefined
  readonly content: string | undefined
  readonly external_link?: string | null | undefined
  readonly external_link_data?: ExternalLinkData[] | null | undefined
  readonly type?: string | undefined
  readonly contentType?: string | undefined
  readonly contentId?: number | string | undefined
  readonly author: AuthorData | undefined
  readonly created_at?: string
  readonly is_same_sender?: boolean
  readonly onAddReaction: (id?: number, emoticon?: string) => void
  readonly reactions: any[]
  readonly chatId: number | null
  readonly chatType: 'direct' | 'group'
  readonly isBeingCached?: boolean
  readonly setChatData: Dispatch<SetStateAction<[] | ChatData[]>>
  readonly deletedAt?: string
  readonly setIsUserLeaving: Dispatch<string>
  readonly highlightedId: string | null
  is_reported: number
  handleEditMessage: (msg: string) => void
  readonly scrollRef?: any
  readonly mention_ids: number[] | []
  setMentionIds: Dispatch<SetStateAction<any>>
}

const SingleMessageWrapper = ({
  handleEditMessage,
  is_reported,
  content,
  contentId,
  contentType,
  chatId,
  id,
  author,
  showPreview,
  setShowPreview,
  created_at,
  is_same_sender,
  external_link,
  external_link_data,
  onAddReaction,
  type,
  chatType,
  reactions,
  isBeingCached,
  setChatData,
  deletedAt,
  setIsUserLeaving,
  highlightedId,
  scrollRef,
  mention_ids,
  setMentionIds,
}: Props) => {
  const { people } = useChat()
  const { user } = useUserContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [hoveredMessageId, setHoveredMessageId] = useState<null | number>(null)
  const [editedContent, setEditedContent] = useState(content ?? null)

  const returnAvatar = (centralisedId: number | undefined) => {
    const senderUserId = centralisedId
    const selfAvatar =
      senderUserId === user?.user_info?.central_user_id
        ? user?.user_info?.avatar
        : null
    const hasAvatar =
      selfAvatar ||
      people?.list?.find(
        ({ central_user_id }) => central_user_id === senderUserId,
      )?.avatar ||
      null

    return hasAvatar ? <UserAvatar avatarUrl={hasAvatar} /> : <DummyAvatar />
  }

  const DummyAvatar = () => (
    <span style={{ fontSize: 26 }} className='icon-user' />
  )

  const UserAvatar = ({ avatarUrl }: any) => (
    <img
      src={`${apiUrl}/storage/module/user/avatar/${avatarUrl}?access_token=${token()}`}
      alt={avatarUrl}
      style={{
        width: 40,
        height: 40,
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    />
  )

  const returnMarginRight = (
    isSameSender: boolean | undefined,
    selfId: number | undefined,
    authorId: number | undefined,
  ) => {
    if (isSameSender) {
      if (selfId === authorId) {
        return '0 48px 0 50px'
      } else {
        return '0 60px 0 48px'
      }
    } else {
      if (selfId === authorId) {
        return '0 0 0 50px'
      } else {
        return '0 60px 0 0'
      }
    }
  }

  return (
    <div
      className='chat__single-conversation-message-container'
      id={`id_${id}`}
      // ref={ref}
      style={{
        width: 'fit-content',
        marginTop: is_same_sender ? 0 : 12,
        alignSelf:
          user?.user_info?.central_user_id === author?.centralised_id
            ? 'flex-end'
            : 'flex-start',
      }}
      onMouseEnter={() => setHoveredMessageId(id ?? null)}
      onMouseLeave={() => setHoveredMessageId(null)}
    >
      {!is_same_sender
        ? user?.user_info?.central_user_id !== author?.centralised_id && (
            <span
              style={{
                fontWeight: 600,
                fontSize: 11,
                marginLeft: 60,
                marginBottom: 3,
              }}
            >
              {author?.display_name}
            </span>
          )
        : null}

      <div
        className='chat__single-conversation-message-wrapper'
        ref={highlightedId === id + '' ? scrollRef : null}
        style={{
          marginBottom: '28px',
          flexFlow: 'row-reverse',
          maxWidth: is_same_sender ? 'calc(100% - 48px)' : '100%',
          margin: returnMarginRight(
            is_same_sender,
            user?.user_info?.central_user_id,
            author?.centralised_id,
          ),
          alignSelf:
            user?.user_info?.central_user_id === author?.centralised_id
              ? 'flex-end'
              : 'flex-start',
        }}
      >
        {!is_same_sender ? (
          <Avatar
            className='chat-avatar'
            style={{
              order:
                user?.user_info?.central_user_id === author?.centralised_id
                  ? 'undefined'
                  : 1,
            }}
          >
            {returnAvatar(author?.centralised_id)}
          </Avatar>
        ) : null}
        <SingleMessage
          is_reported={is_reported}
          hoveredMessageId={hoveredMessageId}
          setHoveredMessageId={setHoveredMessageId}
          userId={author?.centralised_id}
          setShowPreview={setShowPreview}
          id={id}
          chatId={chatId}
          chatType={chatType}
          content={content}
          created_at={created_at}
          external_link={external_link}
          external_link_data={external_link_data}
          type={type}
          contentType={contentType}
          contentId={contentId}
          onAddReaction={onAddReaction}
          reactions={reactions}
          isBeingCached={isBeingCached}
          showPreview={showPreview}
          setChatData={setChatData}
          deletedAt={deletedAt}
          setIsUserLeaving={setIsUserLeaving}
          highlightedId={highlightedId}
          mention_ids={mention_ids}
        />
      </div>

      {type === 'LINK' &&
      contentType &&
      contentId &&
      showPreview !== null &&
      id === showPreview[2] ? (
        <PreviewItem
          previewModule={showPreview[0]}
          previewId={showPreview[1]}
        />
      ) : null}
    </div>
  )
}

export default SingleMessageWrapper
