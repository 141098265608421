/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  TextField,
} from '@material-ui/core'
import { formatDate } from '../../../shared/helpers/formatDate'
import { Add, Delete, KeyboardArrowDown } from '@mui/icons-material'
import parse from 'html-react-parser'
import { useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { assignCategoriesToForums } from '../../../shared/services/api/admin/forum/forums'
import useNotification from '../../../hooks/useNotification'
import ThreadWarningModal from './ThreadWarningModal'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface CategoryData {
  color: string
  created_at: number
  description: string
  id: number
  name: string
  pivot: { forum_id: number; category_id: number }
  slug: string
  updated_at: number
  threads_count: number
}

interface ForumData {
  categories: CategoryData[]
  created_at: string
  deleted_at: string | null
  id: number
  is_enabled: boolean
  last_post_at: string
  name: string
  total_posts: number
  total_threads: number
  updated_at: string
}
interface Category {
  id: number
  name: string
  color?: string
  description: string
  updated_at?: string | number
}
interface Props {
  data: ForumData
  categoriesOptions: Category[]
}

const SingleForumSetup = ({ data, categoriesOptions }: Props) => {
  const [isExpanded, setExpanded] = useState<boolean>(false)
  const [categoriesOpen, setCategoriesOpen] = useState<any>(null)
  const [forumData, setForumData] = useState<ForumData>(data)
  const { id, name, updated_at, categories } = forumData
  const { successToast, errorToast } = useNotification()
  const [threadWarningOpen, setThreadWarningOpen] = useState<boolean | number>(
    false,
  )
  const queryClient = useQueryClient()

  const { mutate: assignCategoriesToForumsMutation } = useMutation({
    mutationFn: assignCategoriesToForums,
    onSuccess: () => {
      successToast({ message: 'Categories assigned successfully' })
      queryClient.invalidateQueries({
        queryKey: ['forums-admin'],
      })
    },
  })

  const handleSaveCategories = async () => {
    try {
      const payload = {
        categories_ids: [...categories]?.map(({ id }) => id),
      }

      assignCategoriesToForumsMutation({
        forumId: id,
        payload,
      })
    } catch (err) {}
  }
  const handleCategories = (value: any) => {
    setForumData((prevState) => {
      return {
        ...prevState,
        categories: value,
      }
    })
  }

  const handleRemoveCategory = () => {
    handleCategories(categories?.filter((i) => i.id !== threadWarningOpen))
    setThreadWarningOpen(false)
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleSaveCategories()
      }}
      style={{
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1607843137)',
        borderRadius: 12,
        backgroundColor: '#fff',
        padding: 15,
        border: '1px solid #dddddd',
      }}
      className='single-forum-setup'
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <IconButton
            onClick={() => setExpanded(!isExpanded)}
            style={{ borderRadius: 0, color: '#000', padding: 3 }}
          >
            <KeyboardArrowDown
              style={{ transform: isExpanded ? 'rotate(180deg)' : undefined }}
            />
          </IconButton>
          <h3>{name}</h3>
        </div>

        <span style={{ fontSize: 12, color: '#888' }}>
          Last updated: {formatDate(updated_at)}
        </span>
      </div>

      {isExpanded ? (
        <>
          <Divider />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '0 10px 10px 10px',
              flexDirection: 'column',
              gap: 6,
            }}
          >
            {categories?.map(
              ({ color, id, name, description, slug, threads_count }) => (
                <div
                  key={id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        background: color,
                        border: '2px solid #000',
                        marginRight: 10,
                      }}
                    />
                    <h2 style={{ marginRight: 6 }}>{name}</h2>
                    <span style={{ fontSize: 13, color: '#606060' }}>
                      {description && parse(description)}
                    </span>
                  </div>

                  <div>
                    <Button
                      style={{
                        textTransform: 'capitalize',
                        background: 'rgb(224, 224, 224)',
                      }}
                      onClick={() =>
                        threads_count > 0
                          ? setThreadWarningOpen(id)
                          : handleCategories(
                              categories?.filter((i) => i.slug !== slug),
                            )
                      }
                      disabled={slug === 'general'}
                      startIcon={<Delete />}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ),
            )}
          </div>
          <Button
            style={{
              textTransform: 'unset',
            }}
            onClick={() => setCategoriesOpen(!categoriesOpen)}
            startIcon={<Add />}
          >
            Assign new category
          </Button>

          {threadWarningOpen !== false ? (
            <ThreadWarningModal
              confirmDeleteOpen={threadWarningOpen}
              setConfirmDeleteOpen={setThreadWarningOpen}
              handleDelete={handleRemoveCategory}
            />
          ) : null}

          {categoriesOpen ? (
            <Autocomplete
              multiple
              value={categories || []}
              options={categoriesOptions || []}
              getOptionSelected={(option: any, value: any) =>
                option.id === value.id
              }
              getOptionDisabled={(option: any) =>
                option === categoriesOptions[0]
              }
              disableCloseOnSelect
              onChange={(event: any, value: any) => handleCategories(value)}
              getOptionLabel={(option: any) => option.name}
              renderTags={() => {
                return false
              }}
              renderOption={(option: any, props: any) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      minHeight: 30,
                    }}
                    className='sector-checkbox'
                  >
                    <div>
                      <Checkbox
                        style={{ padding: 0, marginRight: 10 }}
                        checked={props.selected}
                        disabled={props.selected}
                        // disabled={option?.slug === 'general'}
                        icon={<span className='icon-checkbox-empty' />}
                        checkedIcon={<span className='icon-checkbox-checked' />}
                      />

                      <span style={{ fontSize: 13 }}>{option.name}</span>
                    </div>
                  </div>
                )
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    style={{ color: '#fff' }}
                    variant='outlined'
                    placeholder='Search Categories'
                  />
                )
              }}
            />
          ) : null}

          <Divider />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', gap: 15 }}>
              <Button
                type='submit'
                style={{
                  backgroundColor: '#006fff',
                  textTransform: 'capitalize',
                  color: '#fff',
                }}
              >
                Save changes
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </form>
  )
}

export default SingleForumSetup
