import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'

import './Login.scss'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { login } from '../../shared/services/api/login/login'
import Spinner from '../../shared/Spinner'
import useNotification from '../../hooks/useNotification'

const userFields = [
  { type: 'username', label: 'Username' },
  { type: 'password', label: 'Password' },
]

const Login = ({ setToastMessage, getValues, register, handleSubmit }) => {
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { instance } = useMsal()
  const { errorToast } = useNotification()

  const loginTMViaAzure = async (accessTokenMicrosoft) => {
    try {
      const response = await login({
        sso_provider: 'azure',
        sso_access_token: accessTokenMicrosoft,
      })

      const {
        has_confirmed_scanning_two_factor_secret,
        is_two_factor_enabled,
        image_url,
        secret,
        expires_in,
        refresh_token_expire_in,
        access_token,
        refresh_token,
        is_admin,
        message,
      } = response

      if (message) {
        return errorToast({ message })
      }

      await instance.logoutRedirect({
        onRedirectNavigate: () => {
          // Return false if you would like to stop navigation after local logout
          return false
        },
      })

      if (has_confirmed_scanning_two_factor_secret === false) {
        history.push({
          pathname: '/app/auth/two-factor-authentication-confirm',
          state: {
            imageData: image_url,
            secret: secret,
            sso_access_token: accessTokenMicrosoft,
          },
        })
      } else if (is_two_factor_enabled) {
        history.push({
          pathname: '/app/auth/two-factor-authentication',
          state: { sso_access_token: accessTokenMicrosoft },
        })
      } else if (access_token && refresh_token) {
        const contentUrl = localStorage.getItem('targetUrl')
        localStorage.setItem('auth', 'true')
        localStorage.setItem('access_token', access_token)
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem(
          'expires_in',
          Math.round(new Date() / 1000) + expires_in,
        )
        localStorage.setItem(
          'refresh_token_expire_in',
          Math.round(new Date() / 1000) + refresh_token_expire_in,
        )
        localStorage.removeItem('targetUrl')

        is_admin
          ? history.push('/app/admin')
          : history.push(`/app/workspace${contentUrl || ''}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSignInSSO = async () => {
    try {
      // await instance.handleRedirectPromise()

      const { accessToken } = await instance.loginPopup({
        scopes: ['User.Read'],
      })

      if (accessToken) {
        await loginTMViaAzure(accessToken)
      }
    } catch (error) {
      console.log(error, 'er')
      localStorage.clear()
    }
  }

  const onSubmit = async () => {
    setIsLoading(true)

    try {
      const data = { ...getValues(), noAuthorization: true }

      const response = await login(data)

      if (response.error) {
        setIsLoading(false)
        setToastMessage(['error', response.message])
        return false
      }

      setIsLoading(false)

      const {
        has_confirmed_scanning_two_factor_secret,
        is_two_factor_enabled,
        image_url,
        secret,
        access_token,
        refresh_token,
        expires_in,
        is_admin,
        refresh_token_expire_in,
      } = response

      if (has_confirmed_scanning_two_factor_secret === false) {
        history.push({
          pathname: '/app/auth/two-factor-authentication-confirm',
          state: { imageData: image_url, secret: secret },
        })
      } else if (is_two_factor_enabled) {
        history.push('/app/auth/two-factor-authentication')
      } else if (access_token && refresh_token) {
        const contentUrl = localStorage.getItem('targetUrl')
        localStorage.setItem('auth', 'true')
        localStorage.setItem('access_token', access_token)
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem(
          'expires_in',
          Math.round(new Date() / 1000) + expires_in,
        )
        localStorage.setItem(
          'refresh_token_expire_in',
          Math.round(new Date() / 1000) + refresh_token_expire_in,
        )
        localStorage.removeItem('targetUrl')

        is_admin
          ? history.push('/app/admin')
          : history.push(`/app/workspace${contentUrl || ''}`)
      }
    } catch (error) {}
  }

  const handleForgotPassword = (event) => {
    event.preventDefault()
    history.push('/app/auth/forgot-password')
  }

  useEffect(() => {
    location.state &&
      location.state.message &&
      setToastMessage(['success', location.state.message])
    window.history.replaceState(null, '')
  }, [])

  return (
    <Box className='login-panel'>
      <h4 className='headline'>Login to your account</h4>

      <form className='login-form'>
        {userFields.map(({ type, label }) => (
          <TextField
            key={label}
            variant='outlined'
            fullWidth
            type={type}
            label={label}
            {...register(type, { required: 'This is required.' })}
          />
        ))}

        <Box className='form-button'>
          <Button
            onClick={(event) => handleForgotPassword(event)}
            variant='contained'
            className='form-button--password'
          >
            Forgot Password?
          </Button>

          <Button
            color='default'
            type='button'
            variant='contained'
            className='form-button--submit'
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? (
              <Spinner centerHorizontally size={23} color='#fff' />
            ) : (
              <span>LOGIN</span>
            )}
          </Button>

          <Button
            color='default'
            type='button'
            variant='contained'
            className='form-button--submit'
            onClick={handleSignInSSO}
          >
            LOGIN WITH MICROSOFT
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default Login
