import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const allowed = (token) =>
  client.get(apiUrl + '/api/developers-platform/documentation', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const docData = (token) =>
  client.get(apiUrl + `/documentation.json?access_token=${token}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
