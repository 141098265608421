import React from 'react'
import ModuleListing from '../../../../../ModuleListing'

const AssociatedAlerts = ({ items }) => {
  return (
    <div className='associated-content'>
      <ModuleListing associated='alerts' items={items} />
    </div>
  )
}

export default AssociatedAlerts
