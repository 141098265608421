import { Avatar } from '@material-ui/core'
import { formatDate } from '../../../../../shared/helpers/formatDate'

const PreviewHeading = ({ data }) => {
  return (
    <div className='single-piece__details-heading' style={{ width: '95%' }}>
      {data.dev_tlp && (
        <div
          style={{
            textAlign: 'center',
            backgroundColor:
              data.dev_tlp.colour === 'clear'
                ? '#fff'
                : data.dev_tlp.colour_code,
            color: data.dev_tlp.colour === 'clear' ? '#000' : null,
          }}
          className='tlp'
        >
          <span style={{ fontWeight: 700 }}>
            {data.dev_tlp.label}

            {data.dev_tlp.classification_title &&
              ` - ${data.dev_tlp.classification_title}`}

            {data.dev_tlp.caveat && ` - ${data.dev_tlp.caveat.toUpperCase()}`}
          </span>

          <span>
            {data.dev_tlp.description}

            {data.dev_tlp.classification_description &&
              ` - ${data.dev_tlp.classification_description}`}
          </span>
        </div>
      )}

      <div className='single-piece__details-title'>
        <div className='title'>{data.dev_title}</div>

        <div className='avatars'>
          {data.dev_priority && (
            <div className='priority'>
              <Avatar className={`level-${data.dev_priority}`}>
                {data.dev_priority}
              </Avatar>

              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                {data.priorityName}
              </span>
            </div>
          )}

          {data?.dev_priority > 0 && (
            <div className='priority'>
              <Avatar className={`level-${data.dev_priority}`}>
                {data.dev_priority}
              </Avatar>

              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                {data.capabilityName}
              </span>
            </div>
          )}

          {data.dev_typeName && (
            <div className='priority'>
              <Avatar style={{ color: '#666666' }} className='type'>
                <span
                  style={{ fontSize: 30 }}
                  className={`icon-${data.dev_typeSlug}`}
                />
              </Avatar>

              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                {data.dev_typeName}
              </span>
            </div>
          )}

          {data.dev_type && (
            <div className='priority'>
              <Avatar style={{ color: '#666666' }} className='type'>
                {data.dev_type.acronym}
              </Avatar>

              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                {data.dev_type.name}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className='relevance'>
        <b>Relevance: </b>

        {data.text_relevance}
      </div>

      <div className='publish-data'>
        {data.published_at && (
          <span>
            <b>Published: </b>

            {formatDate(data.published_at)}
          </span>
        )}

        {/* {data.published_updated_at && data.published_updated_at !== data.published_at &&
        <span>
          <b>Updated:</b> {data.published_updated_at}
        </span>} */}

        {data.updated_at && data.updated_at !== data.published_at && (
          <span>
            <b>Updated: </b>

            {formatDate(data.updated_at)}
          </span>
        )}

        <span>
          <b>Author: </b>

          {data.author}
        </span>
      </div>
    </div>
  )
}

export default PreviewHeading
