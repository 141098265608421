import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const contentList = (token, keyword) =>
  client.get(
    apiUrl + `/api/link-analysis/search?keyword=${keyword}&nopagination=true`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const saveAnalysis = (token, id, payload) =>
  client.put(apiUrl + `/api/analysis/${id}`, payload, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const nodeDetails = (token, type, id) =>
  client.get(apiUrl + `/api/link-analysis/node?content_type=${type}&id=${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const expandAllAssociations = (token, id, contentType) =>
  client.get(
    apiUrl +
      `/api/link-analysis/node/links?id=${id}&content_type=${contentType}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const expandProfileOrTag = (
  token,
  id,
  contentType,
  linkedType,
  linkedSubtype,
) =>
  client.get(
    apiUrl +
      `/api/link-analysis/node/links?id=${id}&content_type=${contentType}&linked_type=${linkedType}&linked_subtype=${linkedSubtype}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const expandOther = (token, id, contentType, linkedType) =>
  client.get(
    apiUrl +
      `/api/link-analysis/node/links?id=${id}&content_type=${contentType}&linked_type=${linkedType}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const nodeContent = (token, node_id, type) =>
  client.get(
    apiUrl +
      `/api/link-analysis/node/links?id=${node_id}&content_type=${type}&linked_type=content`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const getListAnalysis = (token, payload) => {
  if (!payload.keywords) delete payload.keywords
  const query = new URLSearchParams(payload).toString()
  return client.get(
    apiUrl + `/api/analysis?${query}`,
    // `/api/analysis?${keyword ? `keywords=${keyword}` : ''}${keyword && status ? `&` : ''}${status ? `status=${status}` : ''}${page ? `&` : ''}${page ? `page=${page}` : ''}`
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
}

export const getSummaryAnalysis = (token) =>
  client.get(apiUrl + `/api/analysis/summary`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const fetchInitialPiece = (token, moduleName, entityId) =>
  client.get(
    apiUrl +
      `/api/link-analysis/node?content_type=${moduleName}&id=${entityId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const fetchAnalysis = (token, id) =>
  client.get(apiUrl + `/api/analysis/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const createAnalysis = (token, data) =>
  client.post(apiUrl + `/api/analysis`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteAnalysis = ({ token, id }) =>
  client.remove(apiUrl + `/api/analysis/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
