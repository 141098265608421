import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import uniqBy from 'lodash/uniqBy'

import {
  getArticlesAdmin,
  getCategoriesAdmin,
  createCategory,
  updateCategory,
  deleteCategory,
  deleteArticleAdmin,
} from '../../shared/services/api/knowledgebase/knowledgebase'
import useNotification from '../../hooks/useNotification'

const useKnowledgeBaseAdmin = () => {
  const [categories, setCategories] = useState([])
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { successToast, errorToast } = useNotification()

  const [isLoading, setLoading] = useState(true)
  const [articles, setArticles] = useState([])
  const location = useLocation()
  const history = useHistory()
  const slug = location.pathname.split('/').at(-1)
  const isCategorySlug = !!categories?.find(
    (category) => category.slug === slug,
  )

  const findCategoryIndex = (categories, id) =>
    [...categories].findIndex((category) => category.id === Number(id))

  const findSubcategoryIndex = (categories, categoryIndex, id) =>
    categories[categoryIndex]?.child_categories.findIndex(
      (subcategory) => subcategory.id === id,
    )

  const handleSubmit = async (event) => {
    event.preventDefault()

    let currentState = [...categories]
    const modifiedData = {
      title: titleInput?.title,
      icon: isCategorySlug ? titleInput?.icon : undefined,
    }

    const { data: categoryResponse, message } = await updateCategory(
      token(),
      titleInput.id,
      modifiedData,
    )

    if (!categoryResponse?.parent_id) {
      currentState = currentState.map((category) =>
        category.id === titleInput.id
          ? { ...category, ...categoryResponse }
          : category,
      )
      setCategories(currentState)
      history.push(`/app/admin/knowledge-base/${categoryResponse.slug}`)
    } else {
      const categoryIndex = findCategoryIndex(
        currentState,
        titleInput.parent_id,
      )
      const subcategoryIndex = findSubcategoryIndex(
        currentState,
        categoryIndex,
        titleInput.id,
      )

      if (currentState[categoryIndex]?.child_categories) {
        currentState[categoryIndex].child_categories[subcategoryIndex] = {
          ...titleInput,
          ...categoryResponse,
        }
      }

      setCategories(currentState)
      history.push(
        `/app/admin/knowledge-base/${currentState[categoryIndex].slug}/${categoryResponse.slug}`,
      )
    }

    successToast({ message })
  }

  const handleDeleteArticle = async (id) => {
    try {
      const [message] = await deleteArticleAdmin(token(), id)

      setArticles([])
      setLoading(true)
      fetchArticles()
      successToast({ message })
    } catch (error) {}
  }

  const fetchCategories = async () => {
    try {
      const response = await getCategoriesAdmin(token())
      if (response.error) {
        setLoading(false)
        return false
      }

      setCategories([
        { title: 'All content', slug: 'all' },
        ...response.categories,
      ])

      fetchArticles()
    } catch (err) {}
  }

  const fetchArticles = async () => {
    try {
      const response = await getArticlesAdmin(token())
      if (response.error) {
        setLoading(false)
        return false
      }

      setArticles(response.articles)
      setLoading(false)
    } catch (err) {}
  }

  const handleCurrentTab = (value) => {
    let slug = location.pathname.split('/').at(-1)
    const currentState = [...categories]

    const isCategorySlug = !!currentState.find(
      (category) => category.slug === slug,
    )

    if (isCategorySlug) {
      const currentCategory = currentState.find(
        (category) => category.slug === slug,
      )
      currentCategory.currentTab = value
    } else {
      currentState?.forEach((category) => {
        category.child_categories?.forEach((subcategory) => {
          if (subcategory.slug === slug) {
            subcategory.currentTab = value
          }
        })
      })
    }

    setCategories(currentState)
  }

  const handleExpandCategories = (event, id) => {
    event.preventDefault()
    const currentState = [...categories]
    const currentCategory = currentState.find((category) => category.id === id)
    currentCategory.isExpanded = !currentCategory.isExpanded

    setCategories(currentState)
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  const getAllFilteredArticles = () => {
    let possibleArticles = []
    let categorySlug = location.pathname.split('/').pop()

    if (categorySlug === 'all' || categorySlug === 'knowledge-base') {
      return articles
    }

    const isSlugCategory = !!categories.find(
      (category) => category.slug === categorySlug,
    )
    let possibleSubcategories = []

    categories.map((category) => {
      category.slug === categorySlug &&
        category.child_categories &&
        category.child_categories.map((sub) => {
          possibleSubcategories = [...possibleSubcategories, sub.slug]
        })
    })
    categorySlug = [categorySlug]

    if (isSlugCategory) {
      categorySlug = [...categorySlug, ...possibleSubcategories]
    }

    articles.forEach((article) => {
      article.categories.forEach((category) => {
        if (categorySlug.includes(category.slug)) {
          possibleArticles = [...possibleArticles, article]
        }
      })
    })

    return uniqBy(possibleArticles, 'id')
  }

  const getCurrentTabValue = () => {
    const slug = location.pathname.split('/').at(-1)

    const isSlugCategory = !!categories.find(
      (category) => category.slug === slug,
    )

    if (isSlugCategory || slug === 'all') {
      return (
        categories.find((category) => category.slug === slug)?.currentTab ||
        'resources'
      )
    } else {
      let subcategoryActiveTab
      categories?.forEach((category) => {
        category.child_categories?.forEach((subcategory) => {
          if (subcategory.slug === slug) {
            subcategoryActiveTab = subcategory?.currentTab || 'resources'
          }
        })
      })

      return subcategoryActiveTab
    }
  }

  const getCurrentInputTitle = () => {
    const slug = location.pathname.split('/').at(-1)
    let result
    const isCategorySlug = !!categories.find(
      (category) => category.slug === slug,
    )

    if (isCategorySlug === true) {
      result = categories.find((category) => category.slug === slug)
    } else {
      categories?.forEach((category) => {
        category.child_categories?.forEach((subcategory) => {
          if (subcategory.slug === slug) {
            result = subcategory
          } else {
            return 'resources'
          }
        })
      })
    }

    return result ?? {}
  }

  const handleAddCategory = async (categoryToAdd) => {
    try {
      const { data: categoryResponse, message } = await createCategory(
        token(),
        categoryToAdd,
      )
      let currentState = [...categories]

      if (!categoryResponse.parent_id) {
        currentState = [
          ...currentState,
          { ...categoryResponse, child_categories: [] },
        ]
        setCategories(currentState)
      } else {
        currentState = currentState.map((category) =>
          category.id === categoryToAdd.parent_id
            ? {
                ...category,
                child_categories: [
                  ...category.child_categories,
                  categoryResponse,
                ],
              }
            : category,
        )
        setCategories(currentState)
      }

      setIsCreateModalOpen(false)
      successToast({ message })
    } catch ({ message }) {
      errorToast({ message })
    }
  }

  const handleDeleteCategory = async (idToDelete, parentId) => {
    try {
      const { message, error } = await deleteCategory(token(), idToDelete)

      if (error) {
        errorToast({ message })
        return
      }

      let currentState = [...categories]

      if (!parentId) {
        currentState = currentState.filter(
          (category) => category.id !== idToDelete,
        )
        setCategories(currentState)
      } else {
        const categoryIndex = findCategoryIndex(currentState, parentId)
        const subcategoryIndex = findSubcategoryIndex(
          currentState,
          categoryIndex,
          idToDelete,
        )
        currentState[categoryIndex]?.child_categories.splice(
          subcategoryIndex,
          1,
        )
        setCategories(currentState)
      }

      successToast({ message })
      history.push('/app/admin/knowledge-base/all')
      setIsDeleteModalOpen(false)
    } catch (error) {
      errorToast({ message: error.message })
    }
  }

  const [titleInput, setTitleInput] = useState(getCurrentInputTitle())

  useEffect(() => {
    setTitleInput(getCurrentInputTitle())
  }, [getCurrentInputTitle()?.title])

  return {
    categories,
    getAllFilteredArticles,
    getCurrentInputTitle,
    getCurrentTabValue,
    handleAddCategory,
    handleCurrentTab,
    handleDeleteArticle,
    handleDeleteCategory,
    handleExpandCategories,
    handleSubmit,
    isCategorySlug,
    isCreateModalOpen,
    isDeleteModalOpen,
    isLoading,
    setCategories,
    setIsCreateModalOpen,
    setIsDeleteModalOpen,
    setTitleInput,
    slug,
    titleInput,
  }
}

export default useKnowledgeBaseAdmin
