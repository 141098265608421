import client from '../../../http/client'

import { apiUrl } from '../../../../config/index'

export const singleFramework = (token, id) =>
  client.get(apiUrl + `/api/scenario-configuration/frameworks/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const addFramework = (token, frameworkData) =>
  client.post(
    apiUrl + `/api/scenario-configuration/frameworks`,
    frameworkData,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const editFramework = (token, id, frameworkData) =>
  client.put(
    apiUrl + `/api/scenario-configuration/frameworks/${id}`,
    frameworkData,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const deleteFramework = (token, id) =>
  client.remove(apiUrl + `/api/scenario-configuration/frameworks/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const frameworkTactics = (token) =>
  client.get(apiUrl + `/api/scenario-configuration/tactics?nopagination`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
