/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useEffect, Fragment } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getPdfLogs } from '../../../../../shared/services/api/logs/logs'
import Pagination from '@material-ui/lab/Pagination'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from '@material-ui/core'
import Spinner from '../../../../../shared/Spinner'
import { formatGlobalSearch } from '../../../../../shared/helpers/formatDate'
import { apiUrl } from '../../../../../shared/config'
import useNotification from '../../../../../hooks/useNotification'
import { useAdmin } from '../../../../../context/adminContext'
import { Info } from '@material-ui/icons'
import { getLogsExport } from '../../../../../shared/services/api/admin/contentExport/contentExport'

const columnData = [
  { id: 'Log Hash' },
  { id: 'Module' },
  { id: 'Title' },
  { id: 'Organisation Name' },
  { id: 'User' },
  { id: 'Download time' },
]

const PdfLogs = () => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setIsLoading] = useState(false)
  const {
    adminState: { pdfLogs },
    setAdminState,
  } = useAdmin()
  const [page, setPage] = useState(
    +Object.fromEntries(new URLSearchParams(pdfLogs?.previousQuery))?.page || 1,
  )
  const [keyword, setKeyword] = useState(
    Object.fromEntries(new URLSearchParams(pdfLogs?.previousQuery))?.keyword ||
      '',
  )
  const [columns, setColumns] = useState(columnData)
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date())
  const [selectedDateTo, setSelectedDateTo] = useState(new Date())
  const { successToast, errorToast, warningToast } = useNotification()

  const exportLogs = async () => {
    const data = {
      dateFrom: formatGlobalSearch(selectedDateFrom),
      dateTo: formatGlobalSearch(selectedDateTo),
    }
    const response = await getLogsExport(token(), 'pdfs', data)

    if (response?.error) {
      errorToast({ message: response?.message })
    } else {
      successToast({ message: response?.message })
    }
  }

  const fetchPdfDownloads = async (forceReload) => {
    try {
      const query = new URLSearchParams({
        keyword: keyword,
        page: page,
      }).toString()

      if (pdfLogs?.previousQuery === query && !forceReload) return false
      setIsLoading(true)
      const response = await getPdfLogs(token(), query)

      if (response?.list?.data?.length === 0) {
        warningToast({ message: 'No results found.' })
      }

      response.list.previousQuery = query
      setAdminState((currentState) => ({
        ...currentState,
        pdfLogs: response.list,
      }))
      setIsLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    const ignore = fetchPdfDownloads()
  }, [page])

  const handleSelectedDateFrom = (value) => {
    setSelectedDateFrom(value)
    value > selectedDateTo && setSelectedDateTo(value)
  }

  return (
    <div className='pdf-logs'>
      <div className='signings-header__datepicker'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <h5>Search</h5>

            <form
              onSubmit={(event) => {
                event.preventDefault()
                setPage(1)
                const ignore = fetchPdfDownloads(true)
              }}
            >
              <OutlinedInput
                variant='outlined'
                placeholder='Search'
                value={keyword}
                onChange={(event) => setKeyword(event.target.value)}
                startAdornment={
                  <InputAdornment position='start'>
                    <span style={{ fontSize: 26 }} className='icon-search' />
                  </InputAdornment>
                }
              />
            </form>
          </div>

          <div>
            <div>
              <h5>Date from</h5>

              <DatePicker
                disableToolbar
                autoOk
                variant='inline'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                value={selectedDateFrom}
                onChange={(value) => handleSelectedDateFrom(value)}
                InputProps={{ endAdornment: <span className='icon-down' /> }}
              />
            </div>

            <div>
              <h5>Date to</h5>

              <DatePicker
                disableToolbar
                autoOk
                InputProps={{ endAdornment: <span className='icon-down' /> }}
                variant='inline'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                minDate={selectedDateFrom}
                value={selectedDateTo}
                onChange={setSelectedDateTo}
              />
            </div>

            <Button onClick={() => exportLogs()} size='large'>
              Export
            </Button>
          </div>
        </MuiPickersUtilsProvider>
      </div>

      {isLoading ? (
        <Spinner centerHorizontally padding={20} size={30} />
      ) : (
        <Fragment>
          <table>
            <thead>
              <tr>
                {columns.map((item) => (
                  <th key={item.id}>{item.id}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {pdfLogs?.data?.map((log) => {
                const {
                  id,
                  log_hash,
                  module: { name },
                  user,
                  username,
                  email,
                  title,
                  organisation_name,
                  downloaded_at_date,
                  downloaded_at_time,
                } = log

                return (
                  <tr key={id}>
                    <td>{log_hash}</td>

                    <td>{name}</td>

                    <td>{title}</td>

                    <td>{organisation_name || '-'}</td>

                    <td>
                      {user ? (
                        username
                      ) : (
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          User deleted
                          <Tooltip placement='top' title={email} arrow>
                            <Info style={{ marginLeft: 6, fontSize: 17.5 }} />
                          </Tooltip>
                        </span>
                      )}
                    </td>

                    <td style={{ fontSize: 11, fontWeight: 600 }}>
                      {downloaded_at_date + ' ' + downloaded_at_time}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <Pagination
            onChange={(event, value) => setPage(value)}
            page={page}
            count={pdfLogs?.last_page}
            shape='rounded'
            hideNextButton
            hidePrevButton
            color='primary'
          />
        </Fragment>
      )}
    </div>
  )
}

export default PdfLogs
