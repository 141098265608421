import React from 'react'
import { FormControlLabel, Tooltip } from '@mui/material'
import { Checkbox, Divider, MenuItem, Select } from '@material-ui/core'
import { useCreateManagementContext } from '../../../../context/createManagementState'

const AlertType = ({
  isTemplateSet,
  setTemplate,
  handleAutosave,
  handleChangeTemplate,
  alertTypes,
}) => {
  const {
    createContentState: { dev_type_id },
  } = useCreateManagementContext()
  return (
    <div className='content-section'>
      <div
        className='content-section__header'
        style={{
          borderRadius: isTemplateSet ? 12 : '12px 12px 0 0',
          backgroundColor: isTemplateSet ? '#3aaa35' : null,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4>Threat Alert Type</h4>

          <span className='label-required__sidebar' style={{ marginLeft: 10 }}>
            Required
          </span>
        </div>

        <div className='select-template-checkbox'>
          <Tooltip arrow placement='top' title='Mark as complete'>
            <FormControlLabel
              label=''
              onChange={() => {
                const nextState = !isTemplateSet
                setTemplate(nextState)
                nextState && handleAutosave()
              }}
              control={
                <Checkbox
                  checked={isTemplateSet}
                  className='cb-complete'
                  disableRipple
                  icon={<span className='icon-checkbox-empty' />}
                  checkedIcon={<span className='icon-checkbox-checked' />}
                  disabled={!dev_type_id}
                />
              }
            />
          </Tooltip>
        </div>
      </div>

      {!isTemplateSet && (
        <div className='content-section__content'>
          <Divider />

          <div className='select-template'>
            <Select
              onChange={async (event) => {
                await handleChangeTemplate(event.target.value)
                setTemplate(false)
              }}
              displayEmpty
              renderValue={
                !dev_type_id
                  ? () => <span style={{ color: '#fff' }}>Select type</span>
                  : null
              }
              value={dev_type_id || alertTypes[0].id}
              variant='outlined'
            >
              {alertTypes.map((type) => (
                <MenuItem key={type.name} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      )}
    </div>
  )
}

export default AlertType
