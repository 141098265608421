import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'

import { TEMPLATES } from './templates'
import {
  createAdminArticle,
  getCategoriesAdmin,
  getSingleAdminArticle,
  saveAdminArticle,
} from '../../../shared/services/api/knowledgebase/knowledgebase'
import useNotification from '../../../hooks/useNotification'
import cloneDeep from 'lodash/cloneDeep'

const useKnowledgeBaseAdminArticle = () => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { id } = useParams()
  const isCreateNew = id === 'create'
  const [data, setData] = useState(isCreateNew ? cloneDeep(TEMPLATES.text) : {})
  const [isLoading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
  const [isAddingContent, setAddingContent] = useState(false)
  const { successToast, errorToast } = useNotification()
  const history = useHistory()

  const saveArticle = async ({ is_published }) => {
    const videoType = data.content.filter((item) => item.type === 'video')[0]
      ?.url

    try {
      const payload = {
        type: videoType ? 'video' : 'text',
        title: data.title,
        categories: data.categories || [],
        content: JSON.stringify(data.content),
        is_published,
      }

      let response

      if (isCreateNew) {
        response = await createAdminArticle(token(), payload)

        if (!response.error) {
          successToast({ message: response.message })
          history.push(`/app/admin/knowledge-base/article/${response.data.id}`)
          return
        }
      } else {
        response = await saveAdminArticle(token(), data.id, payload)
      }

      if (response.error) {
        let errors = []
        Object.values(response.errors) &&
          Object.values(response.errors).forEach((er) => {
            errors = [...errors, er]
          })

        errorToast({ message: errors.join(' ') })
        return
      }

      successToast({ message: response.message })
    } catch (err) {}
  }

  const fetchCategories = async () => {
    try {
      const response = await getCategoriesAdmin(token())
      if (response.error) {
        setLoading(false)
        return false
      }

      setCategories(response.categories)

      if (isCreateNew) {
        setLoading(false)
      } else {
        fetchArticle()
      }
    } catch (err) {}
  }

  const fetchArticle = async () => {
    try {
      if (id !== 'create') {
        const response = await getSingleAdminArticle(token(), id)
        response.content = JSON.parse(response.content)

        setData(response)
        setLoading(false)
      }
    } catch (err) {}
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    if (id) {
      fetchArticle()
    }
  }, [id])

  const handleState = (key, value) => {
    const currentState = { ...data }

    currentState[key] = value

    if (key === 'type') {
      const newType = cloneDeep(TEMPLATES)[value].content
      currentState.content = newType
    }

    setData(currentState)
  }

  const handleSectionState = (index, key, value) => {
    const currentState = { ...data }

    currentState.content[index][key] = value
    setData(currentState)
  }

  const handleDeleteSection = (index) => {
    const currentState = { ...data }

    currentState.content.splice(index, 1)
    setData(currentState)
  }

  const handleExpandCategories = (event, id) => {
    event.preventDefault()
    const currentState = [...categories]
    const currentCategory = currentState.find((category) => category.id === id)
    currentCategory.isExpanded = !currentCategory.isExpanded

    setCategories(currentState)
  }

  const handleCategories = (id) => {
    const currentState = { ...data }

    if (currentState.categories?.includes(id)) {
      currentState.categories = currentState.categories.filter(
        (category) => category !== id,
      )
    } else {
      currentState.categories = [...(currentState.categories || []), id]
    }

    setData(currentState)
  }

  const handleAddSection = (type) => {
    const currentState = { ...data }
    const layoutTypeSection = cloneDeep(TEMPLATES)[
      currentState.type
    ].content.find((item) => item.type === type)

    currentState.content = [...currentState.content, layoutTypeSection]
    setData(currentState)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event
    const currentState = { ...data }

    if (active.id !== over.id) {
      const oldIndex = active.id.split('_')[0]
      const newIndex = over.id.split('_')[0]

      currentState.content = arrayMove(currentState.content, oldIndex, newIndex)
      setData(currentState)
    }
  }

  return {
    categories,
    data,
    handleAddSection,
    handleCategories,
    handleDeleteSection,
    handleDragEnd,
    handleExpandCategories,
    handleSectionState,
    handleState,
    isAddingContent,
    isCreateNew,
    isLoading,
    sensors,
    saveArticle,
    setAddingContent,
  }
}

export default useKnowledgeBaseAdminArticle
