import React from 'react'
import { NavLink } from 'react-router-dom'

import './KnowledgeBaseList.scss'
import { useKnowledgeBaseContext } from '../../../context/knowledgeBaseContext'

const KnowledgeBaseListButton = ({ article }) => {
  const { activeLink } = useKnowledgeBaseContext()

  return (
    <div className='knowledge-base-list__article-item'>
      <span
        className={`icon-${article.type === 'text' ? 'page' : 'play'}`}
        style={{ fontSize: '30px', color: '#ccc' }}
      />

      <NavLink
        className='knowledge-base-list__article-link'
        to={`${activeLink}/${article.slug}`}
      >
        {article.title}
      </NavLink>
    </div>
  )
}

KnowledgeBaseListButton.propTypes = {}

export default KnowledgeBaseListButton
