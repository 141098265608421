import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import Spinner from '../../../../shared/Spinner'
import { useHistory, useParams } from 'react-router-dom'
import { TextField } from '@mui/material'
import {
  singleType,
  addType,
  editType,
} from '../../../../shared/services/api/admin/settings/scenarioConfig'
import useNotification from '../../../../hooks/useNotification'

const EditScenarioType = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const history = useHistory()
  const { id } = useParams()
  const isCreateNew = id === 'new'
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [isSaving, setSaving] = useState(false)
  const { successToast, errorToast } = useNotification()

  const getTypeDetails = async () => {
    try {
      const response = await singleType(token(), id)
      setData(response.data)
      setLoading(false)
    } catch (err) {}
  }

  const submitTypeData = async () => {
    setSaving(true)
    try {
      let response = ''
      if (isCreateNew) {
        response = await addType(token(), data)
      } else {
        response = await editType(token(), id, data)
      }

      if (response.error) {
        errorToast({ message: response.message })
        setLoading(false)
        setSaving(false)

        return false
      }

      successToast({ message: response.message })
      setLoading(false)
      setSaving(false)

      isCreateNew &&
        history.push('/app/admin/system-settings/scenario-configuration/types')
    } catch {}
  }

  useEffect(() => {
    if (id !== 'new') {
      setLoading(true)
      getTypeDetails()
    }
  }, [])

  const handleChangeTypeData = (prop, value) => {
    const currentData = { ...data }

    currentData[prop] = value
    setData(currentData)
  }

  return isLoading ? (
    <Spinner centerHorizontally padding={20} size={30} />
  ) : (
    <div className='edit-admin'>
      <h2 style={{ marginBottom: 10 }}>
        {isCreateNew ? 'Create new type' : 'Edit Type'}
      </h2>

      <div className='edit-admin__form'>
        <div style={{ width: '100%', margin: '6px 0' }}>
          <h5 style={{ marginBottom: 5 }}>Name</h5>

          <TextField
            fullWidth
            variant='outlined'
            placeholder='Name'
            value={data.name || ''}
            required
            onChange={(event) =>
              handleChangeTypeData('name', event.target.value)
            }
          />
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <Button
          onClick={() => submitTypeData()}
          disabled={isSaving}
          className='create-new'
        >
          {isSaving ? (
            <Spinner centerHorizontally size={22.5} color='#fff' />
          ) : isCreateNew ? (
            'Add New Type'
          ) : (
            'Edit Type'
          )}
        </Button>

        <Button
          style={{
            textTransform: 'capitalize',
            marginLeft: 20,
            borderRadius: 12,
            padding: '6px 16px',
          }}
          onClick={() =>
            history.push(
              '/app/admin/system-settings/scenario-configuration/types',
            )
          }
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default EditScenarioType
