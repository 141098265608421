import moment from 'moment'

export const formatDate = (date) => {
  return moment(new Date(date)).format('Do MMMM YYYY HH:mm')
}

export const formatIoc = (date) => {
  return moment(new Date(date)).format('DD-MM-YYYY')
}

export const formatGlobalSearch = (date) => {
  return moment(new Date(date)).format('YYYY-MM-DD')
}

export const formatCreateListingDate = (date) => {
  return moment(new Date(date)).format('DD.MM.YYYY')
}

export const formatCreate = (date) => {
  return moment(new Date(date)).format('DD-MM-YYYY HH:mm')
}

export const formatChatMsgDate = (date) => {
  return moment(new Date(date)).format('HH:mm')
}

export const formatCreatedAtMessage = (date) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}
