import React, { Fragment } from 'react'
import './TabList.scss'
import PropTypes from 'prop-types'
import { Divider, Button } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useUserContext } from '../../../../../context/userContext'

/**
 * TabList
 * @param {function} setIsPopupOpen - setting account popup open state
 */

const TabList = ({ setIsPopupOpen }) => {
  const { handleLogout } = useUserContext()

  const settingsOptions = [
    {
      name: 'Notification Preferences',
      icon: 'icon-bell',
      slug: 'notifications',
    },
    {
      name: 'API',
      icon: 'icon-developers-platform',
      slug: 'api',
      renderDivider: true,
    },
    { name: 'Your Details', icon: 'icon-user', slug: 'user-details' },
    { name: 'Change Password', icon: 'icon-keyboard', slug: 'change-password' },
    {
      name: 'Login history',
      icon: 'icon-history',
      slug: 'login-history',
      renderDivider: true,
    },
  ]

  return (
    <div className='account-tabs__wrapper'>
      <h2>Settings</h2>

      <div className='tab-group'>
        {settingsOptions.map(({ name, icon, slug, renderDivider }) => (
          <Fragment key={slug}>
            <NavLink
              to={`/app/settings/${slug}`}
              onClick={() => slug === 'change-password' && setIsPopupOpen(true)}
              isActive={() =>
                location.pathname.startsWith(`/app/settings/${slug}`)
              }
            >
              <span className={icon} />

              {name}
            </NavLink>

            {renderDivider && <Divider />}
          </Fragment>
        ))}

        {!isMobile && (
          <Button
            onClick={() => handleLogout()}
            startIcon={
              <span style={{ marginRight: 5 }} className='icon-exit' />
            }
          >
            Logout
          </Button>
        )}
      </div>
    </div>
  )
}

TabList.displayName = 'TabList'

/**
 * The properties.
 * @type {Object}
 */

TabList.propTypes = {
  setIsPopupOpen: PropTypes.func.isRequired,
}

export default TabList
