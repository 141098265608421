/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { styles } from '@material-ui/pickers/views/Calendar/Calendar'
import React from 'react'

const Status = ({ visibility, status, view }) => {
  const returnStatusColor = () => {
    if (visibility === 'Not Published' || status === 'Denied') {
      return '#FF0033'
    } else if (visibility === 'Published' || status === 'Approved') {
      return '#3AAA35'
    } else if (status === 'Draft') {
      return '#6180A8'
    } else if (status === 'Awaiting Approval' || status === 'Update Request') {
      return 'yellow'
    } else if (status === 'Unpublished Changes') {
      return 'orange'
    }
  }
  const styles = {
    status: {
      display: 'block',
      backgroundColor: returnStatusColor(),
      borderRadius: '50%',
      height: view ? 12 : 15,
      width: view ? 12 : 15,
      margin: view ? '0 6px 0 0' : '0 20px 0 5px',
      minHeight: view ? 12 : 15,
      minWidth: view ? 12 : 15,
    },
  }
  return <div style={styles.status} />
}

export default Status
