/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { Dispatch, SetStateAction, useState } from 'react'
import { useChat } from '../../../../context/chatContext'
import { Avatar, IconButton, Tooltip } from '@material-ui/core'
import { apiUrl } from '../../../../shared/config'
import { MoreVert, NotificationsOff } from '@mui/icons-material'
import NotificationSettingsMenu from './NotificationSettingsMenu'
import { changeNotificationSettings } from '../../../../shared/services/api/chat/chat'

/* eslint-disable jsx-a11y/click-events-have-key-events */
interface ChatSettings {
  readonly chat_email_notify_interval: string
  readonly chat_email_notify_mute: boolean
  readonly total_unread_messages: number
}

interface Props {
  readonly display_name: string
  readonly central_user_id: number
  readonly avatar: string
  readonly chat_settings: ChatSettings
  setSearchOpen: Dispatch<SetStateAction<boolean>>
}

interface HoverItem {
  userId?: number
  groupId?: number
}

const SingleUser = ({
  display_name,
  central_user_id,
  avatar,
  chat_settings: {
    chat_email_notify_interval,
    chat_email_notify_mute,
    total_unread_messages,
  },
  setSearchOpen,
}: Props) => {
  const {
    setGroupChats,
    setActiveChat,
    activeUserChat,
    setActiveUserChat,
    setPeople,
    setChatOpen,
  } = useChat()
  const [openSettingId, setOpenSettingId] = useState<null | HoverItem>(null)
  // const [hoverChatId, setHoverChatId] = useState<null | HoverItem>(null)
  const [settingsAnchor, setSettingsAnchor] =
    useState<null | HTMLButtonElement>(null)
  const token = () => {
    return localStorage.getItem('access_token')
  }

  const handleContextMenu = (event: any, id: number) => {
    event.preventDefault()
    setOpenSettingId({ userId: id })
    setSettingsAnchor(event.currentTarget)
  }

  const handleSetActiveChat = (chatInfo: { id: number; name: string }) => {
    setActiveChat(chatInfo)
    setActiveUserChat(null)
    setChatOpen(true)
  }

  const handleChangeNotificationsSettings = async (
    chatType: string,
    chatId: number,
    value: string | boolean,
  ) => {
    const isMute = (val: any) => 'boolean' === typeof val

    const data = {
      chat_id: chatId,
      chat_type: chatType,
      chat_email_notify_interval: isMute(value) ? '2 HOURS' : value,
      chat_email_notify_mute: isMute(value) && value,
    }

    const handleStateData = (state: any, stateType: string) => {
      const newState = state?.list?.find((i: any) => i[stateType] === chatId)
        ?.chat_settings
      if (!newState) return state
      if (isMute(value)) {
        newState.chat_email_notify_mute = !!value
      } else {
        newState.chat_email_notify_interval = value.toString()
        newState.chat_email_notify_mute = false
      }
      return state
    }

    if (chatType === 'direct') {
      setPeople((currentState) =>
        handleStateData(currentState, 'central_user_id'),
      )
    } else {
      setGroupChats((currentState) => handleStateData(currentState, 'id'))
    }
    await changeNotificationSettings(token(), data)
    setOpenSettingId(null)
    setSettingsAnchor(null)
  }

  const peopleMessageItemClass = (unreadMessages: number, id: number) =>
    `${unreadMessages > 0 ? ' item-unread' : ''}${
      id === activeUserChat?.id ? ' item-active' : ''
    }`

  return (
    <div
      style={{
        justifyContent: 'space-between',
        borderTop: '1px solid #dedede',
      }}
      className={`chat__conversations-messages-people-item${peopleMessageItemClass(
        0,
        central_user_id,
      )}`}
      onClick={(event) => {
        const targetTag = (event.target as HTMLElement)?.tagName
        if (
          openSettingId !== null &&
          (targetTag === 'DIV' || targetTag === 'LI')
        ) {
          return false
        }
        setActiveUserChat({
          id: central_user_id,
          name: display_name,
        })
        setChatOpen(true)
        setSearchOpen(false)
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <Avatar
          style={{
            height: 36,
            width: 36,
            backgroundColor: undefined,
          }}
          className='chat-avatar'
        >
          {avatar ? (
            <img
              src={`${apiUrl}/storage/module/user/avatar/${avatar}?access_token=${token()}`}
              alt={avatar}
              style={{
                width: 40,
                height: 40,
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          ) : (
            <span
              style={{
                fontSize: 15,
                fontWeight: 600,
              }}
            >
              {display_name
                ?.split(' ')
                ?.slice(0, 2)
                ?.map((str) => str[0])
                ?.join('')}
            </span>
            // <span style={{ fontSize: 26 }} className='icon-user' />
          )}
        </Avatar>

        <div style={{ color: '#000', fontSize: 14, fontWeight: 600 }}>
          <span>{display_name}</span>
        </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        {chat_email_notify_mute ? (
          <Tooltip arrow placement='top' title='Conversation is muted'>
            <NotificationsOff
              style={{
                fontSize: 24,
                color: '#ff6570',
              }}
            />
          </Tooltip>
        ) : null}

        <IconButton
          onClick={(event) => {
            event.stopPropagation()
            handleContextMenu(event, central_user_id)
          }}
          style={{ padding: 6 }}
        >
          <MoreVert />
        </IconButton>
      </div>

      {openSettingId?.userId === central_user_id ? (
        <NotificationSettingsMenu
          settingsAnchor={settingsAnchor}
          setOpenSettingId={setOpenSettingId}
          // setHoverChatId={setHoverChatId}
          chat_email_notify_mute={chat_email_notify_mute}
          chat_email_notify_interval={chat_email_notify_interval}
          handleChangeNotificationsSettings={handleChangeNotificationsSettings}
          chatType='direct'
          chatId={central_user_id}
        />
      ) : null}

      {total_unread_messages > 0 ? (
        <span className='chat-avatar-unread'>{total_unread_messages}</span>
      ) : null}
    </div>
  )
}

export default SingleUser
