import { getForumSearchedThreads } from '../../api/forum/threads'
import { useQuery } from '@tanstack/react-query'

const token = () => {
  return localStorage.getItem('access_token')
}

export const useQueryGetForumSearchedThreads = (
  keyword: string,
  forumId: string,
  page: number,
) => {
  return useQuery({
    queryKey: ['searchThread', forumId, keyword, page],
    queryFn: () =>
      getForumSearchedThreads(token() || '', keyword, forumId, page),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}
