/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import './DistributionTab.scss'
import { useHistory } from 'react-router-dom'
import subDays from 'date-fns/subDays'
import PropTypes from 'prop-types'
import { useGlobalContext } from '../../../context/globalContext'

/**
 * DistributionTab
 * @param {object} data - distribution data
 * @param {string} tab - current chart tab
 */

const DistributionTab = ({ data, tab }) => {
  const history = useHistory()
  const { setGlobalSearchFilters } = useGlobalContext()

  return (
    <div className='distribution-tab'>
      {Object.values(data).map((type) => (
        <div key={type.name}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setGlobalSearchFilters({
                fromDashboard: true,
                relevance: [tab],
                currentTab: type.slug,
                dateRange: [subDays(new Date(), 30), new Date()],
              })
              history.push('/app/search')
            }}
          >
            <span className='distribution-tab__count'>{type.count}</span>

            <span className='distribution-tab__name'>{type.name}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

DistributionTab.displayName = 'DistributionTab'

/**
 * The properties.
 * @type {Object}
 */

DistributionTab.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  tab: PropTypes.string.isRequired,
}

export default DistributionTab
