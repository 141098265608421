import React, { useEffect, useRef, useState } from 'react'
import {
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core'
import './SingleOrganisation.scss'
import { Button } from '@mui/material'
import Spinner from '../../../../../../shared/Spinner'

const groupSettingsOptions = [
  { slug: 'include_new_users', name: 'Include New Users' },
  { slug: 'make_anonymous', name: 'Make Anonymous' },
]

const SingleOrganisation = ({
  organisation,
  handleOrganisationState,
  saveOrganisation,
  groups,
  fetchNewUsers,
}) => {
  const { id, name, is_community_group } = organisation
  const [isSaving, setSaving] = useState(false)
  let ref = useRef(null)

  useEffect(() => {
    groups[id].settings?.renderSettings &&
      groups[id].settings.renderSettings[1] &&
      handleOrganisationState('other_groups_settings', id, 'renderSettings', [
        true,
        true,
      ])
  }, [])

  return (
    <>
      <Chip
        ref={ref}
        style={{
          backgroundColor: is_community_group ? '#bdf2ff' : '#FFF4C9',
          color: '#000',
          margin: 2,
          fontSize: 10,
        }}
        onClick={() =>
          handleOrganisationState(
            'other_groups_settings',
            id,
            'renderSettings',
            true,
          )
        }
        onDelete={() =>
          handleOrganisationState('other_groups_settings', id, 'delete')
        }
        label={name}
        key={id}
        deleteIcon={
          <span
            className='icon-close'
            style={{
              width: 'unset',
              height: 'unset',
              fontSize: 14,
              padding: '4px 4px 4px 0',
              color: '#000',
            }}
          />
        }
      />

      {groups[id].settings.renderSettings && ref.current && (
        <Menu
          anchorEl={ref.current || null}
          open={
            groups[id].settings.renderSettings[1] ||
            groups[id].settings.renderSettings
          }
          onClose={() =>
            !groups[id].settings.renderSettings[1] &&
            handleOrganisationState(
              'other_groups_settings',
              id,
              'renderSettings',
              false,
            )
          }
        >
          <div
            style={{
              padding: '6px 6px 6px 16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h4 style={{ marginRight: 10 }}>Group: {name}</h4>

            {groups[id].settings.renderSettings[1] ? (
              <Button
                onClick={async () => {
                  setSaving(true)
                  const isSaved = await saveOrganisation(true)

                  if (isSaved) {
                    fetchNewUsers()
                    handleOrganisationState(
                      'other_groups_settings',
                      id,
                      'renderSettings',
                      false,
                    )
                    setSaving(false)
                  } else {
                    handleOrganisationState(
                      'other_groups_settings',
                      id,
                      'renderSettings',
                      false,
                    )
                    setSaving(false)
                  }
                }}
                style={{
                  textTransform: 'capitalize',
                  padding: '6px 16px',
                  borderRadius: 12,
                  backgroundColor: '#39c333',
                  color: '#fff',
                  minWidth: 80,
                }}
              >
                {isSaving ? (
                  <Spinner centerHorizontally size={22.5} color='#fff' />
                ) : (
                  <span>Accept</span>
                )}
              </Button>
            ) : (
              <IconButton
                onClick={() =>
                  handleOrganisationState(
                    'other_groups_settings',
                    id,
                    'renderSettings',
                    false,
                  )
                }
                style={{ padding: 4, color: '#000000de' }}
              >
                <span className='icon-close' />
              </IconButton>
            )}
          </div>

          {groupSettingsOptions.map(({ name, slug }) => (
            <MenuItem key={slug}>
              <FormControlLabel
                onChange={(event) =>
                  handleOrganisationState(
                    'other_groups_settings',
                    id,
                    slug,
                    event.target.checked,
                  )
                }
                label={name}
                control={
                  <Checkbox
                    color='default'
                    checked={groups[id].settings[slug] || false}
                    style={{ padding: 3, color: '#fff', fontSize: 26 }}
                    icon={
                      <span
                        style={{ color: '#000' }}
                        className='icon-checkbox-empty'
                      />
                    }
                    checkedIcon={
                      <span
                        style={{ color: '#006bf5' }}
                        className='icon-checkbox-checked'
                      />
                    }
                  />
                }
              />
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

export default SingleOrganisation
