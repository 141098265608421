import React, { useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  MenuItem,
  Select,
} from '@material-ui/core'
import TextField from '@mui/material/TextField'
import { iocTypes } from './data'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const IocDialog = ({ addIocDialog, setIocDialog, addIocRecord, id, type }) => {
  const [isAdding, setAdding] = useState(false)
  const [data, setData] = useState({
    content_id: id,
    module: type,
    ioc: {
      value: '',
      type: '',
    },
  })

  const handleSetData = (prop, value) => {
    const currentState = { ...data }
    currentState.ioc[prop] = value
    setData(currentState)
  }

  const handlePublishIoc = () => {
    setAdding(true)
    addIocRecord(data)
  }

  return (
    <Dialog
      open={addIocDialog}
      onClose={() => setIocDialog(false)}
      fullWidth
      maxWidth='sm'
      className='ioc-dialog'
    >
      <div style={{ padding: 20 }}>
        <h3>Add IOC Record</h3>

        <Divider style={{ margin: '15px 0' }} />

        <div>
          <div>
            <h5 style={{ marginBottom: 10 }}>Data</h5>

            <TextField
              fullWidth
              inputProps={{ style: { padding: '12px 15px', fontSize: 14 } }}
              onChange={(event) => handleSetData('value', event.target.value)}
              variant='outlined'
              // defaultValue={data.search ? data.search : ''}
              // onChange={event => requestSearch(event.target.value)}
              placeholder='Data'
            />
          </div>

          <div>
            <h5 style={{ marginBottom: 10, marginTop: 20 }}>IOC Type</h5>

            <Select
              value={data.ioc.type}
              variant='outlined'
              displayEmpty
              inputProps={{ style: { fontSize: 14 } }}
              fullWidth
              renderValue={
                data.ioc.type === ''
                  ? () => <span style={{ color: '#aaa' }}>Select IOC Type</span>
                  : undefined
              }
              onChange={(event) => {
                event.stopPropagation()
                handleSetData('type', event.target.value)
                // handleChange(params.id, event.target.value)
              }}
              onClose={() => {
                setTimeout(() => {
                  document.activeElement.blur()
                }, 0)
              }}
            >
              {iocTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div>
            <h5 style={{ marginBottom: 10, marginTop: 20 }}>Date</h5>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disableToolbar
                autoOk
                fullWidth
                variant='inline'
                inputVariant='outlined'
                placeholder='Pick Date'
                inputProps={{ style: { padding: '12px 15px', fontSize: 14 } }}
                format='dd/MM/yyyy'
                // defaultValue={null}
                value={data.ioc.date || null}
                onChange={(value) => handleSetData('date', value)}
                style={{ marginRight: 20 }}
                InputProps={{
                  endAdornment: <span className='icon-down' />,
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div>
            <h5 style={{ marginBottom: 10, marginTop: 20 }}>Comments</h5>

            <TextField
              fullWidth
              variant='outlined'
              inputProps={{ style: { fontSize: 14 } }}
              // defaultValue={data.search ? data.search : ''}
              // onChange={event => requestSearch(event.target.value)}
              placeholder='Comments'
              multiline
              onChange={(event) => handleSetData('comment', event.target.value)}
            />
          </div>
        </div>

        <div
          style={{
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            style={{
              textTransform: 'capitalize',
              fontWeight: 700,
              borderRadius: 12,
              padding: '6px 20px',
              color: '#000',
            }}
            onClick={() => setIocDialog(false)}
          >
            Cancel
          </Button>

          <Button
            style={{
              backgroundColor:
                data.ioc.value.length > 0 && data.ioc.type.length > 0
                  ? '#3aaa35'
                  : '#ccc',
              color: '#fff',
              textTransform: 'capitalize',
              fontWeight: 700,
              borderRadius: 12,
              padding: '6px 20px',
              minWidth: 92,
            }}
            disabled={data.ioc.value.length === 0 || data.ioc.type.length === 0}
            onClick={() => handlePublishIoc()}
          >
            {isAdding ? (
              <CircularProgress
                style={{ height: 24, width: 24, color: '#fff' }}
              />
            ) : (
              'Publish'
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default IocDialog
