import React, { useState, useEffect } from 'react'
import AssociatedChips from '../microcomponents/AssociatedChips'
import { getAssociatedAlerts } from '../../../../../shared/services/api/createContent/createAlert'
import Spinner from '../../../../../shared/Spinner'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const AssociatedAlerts = ({ isExpanded }) => {
  const {
    handleCreateState,
    createContentState: { related_alerts },
  } = useCreateManagementContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [associatedAlertsOptions, setAssociatedAlertsOptions] = useState({})
  const [isLoading, setLoading] = useState(true)

  const handleState = (value, shouldDelete) => {
    let currentState = (related_alerts && [...related_alerts]) || []

    if (shouldDelete) {
      currentState = currentState.filter((item) => item.id !== value)
    } else {
      if (value?.associated_alert) {
        const { associated_alert } = value
        currentState = currentState
          ? [...currentState, associated_alert]
          : [associated_alert]
      }
    }
    handleCreateState('related_alerts', currentState, true)
  }

  useEffect(() => {
    return () => {}
  }, [])

  const getAssociatiations = async () => {
    setLoading(true)
    try {
      const response = await getAssociatedAlerts(token())
      setAssociatedAlertsOptions(response.list)
      setLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    isExpanded && getAssociatiations()
  }, [isExpanded])

  return (
    <div className='middle'>
      {isLoading ? (
        <Spinner centerHorizontally size={30} border={1} />
      ) : (
        <div className='tags-modules'>
          <AssociatedChips
            title='Alerts'
            chosenElements={related_alerts || []}
            options={associatedAlertsOptions}
            isLoading={isLoading}
            handleState={handleState}
          />
        </div>
      )}
    </div>
  )
}

export default AssociatedAlerts
