import client from '../../../http/client'

import { apiUrl } from '../../../../config/index'

export const scenarioTypes = (token) =>
  client.get(apiUrl + `/api/scenario-configuration/types`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const scenarioFrameworks = (token) =>
  client.get(apiUrl + `/api/scenario-configuration/frameworks`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleType = (token, id) =>
  client.get(apiUrl + `/api/scenario-configuration/types/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const addType = (token, typeData) =>
  client.post(apiUrl + `/api/scenario-configuration/types`, typeData, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const editType = (token, id, typeData) =>
  client.put(apiUrl + `/api/scenario-configuration/types/${id}`, typeData, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteType = (token, id) =>
  client.remove(apiUrl + `/api/scenario-configuration/types/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
