import { Menu, MenuItem, Tooltip } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { formatCreate } from '../../shared/helpers/formatDate'
import './PublisherArea.scss'
import { IconButton, LinearProgress } from '@mui/material'
import { typeNames } from '../../shared/helpers/typeNames'
import Status from '../CreateContent/shared/Components/microcomponents/Status'
import GlobalToast from '../../shared/GlobalToast/GlobalToast'
import { ContentCopy } from '@mui/icons-material'
import { useGlobalContext } from '../../context/globalContext'
import Spinner from '../../shared/Spinner'
import useNotification from '../../hooks/useNotification'

const PublisherArea = ({
  data,
  setConfirmDelete,
  handleDuplicateContent,
  isDuplicating,
  isLoading,
}) => {
  const { isContentBeingEdited, toastMessage, setToastMessage } =
    useGlobalContext()
  const history = useHistory()
  const columns = [
    { name: 'Type', width: '5%' },
    { name: 'Name', width: '40%' },
    { name: 'Author', width: '15%' },
    { name: 'Updated', width: '10%' },
    { name: 'Status', width: '10%' },
    { name: 'Visibility', width: '10%' },
    { name: 'Distribution', width: '5%' },
    { name: 'Options', width: '5%' },
  ]
  const isContentEdited = JSON.parse(localStorage.getItem('isContentEdited'))
  const isBeingEdited =
    isContentBeingEdited && typeof isContentBeingEdited[1] === 'number'
  const [moreOptionsMenu, SetMoreOptionsMenu] = useState(null)
  const { errorToast } = useNotification()

  return (
    <div className='publisher-area'>
      <div className='publisher-area__inner'>
        <table>
          <thead>
            <tr>
              {columns.map((item) => (
                <th style={{ width: item.width }} key={item.name}>
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>

          {data && (
            <tbody>
              {data.map((piece) => {
                const {
                  id,
                  contentType,
                  dev_title,
                  author,
                  updated_at,
                  created_at,
                  statusName,
                  visibilityName,
                  main_content_type,
                  assignment,
                } = piece

                return (
                  <tr key={id}>
                    <td>
                      <Tooltip
                        title={
                          (contentType &&
                            contentType.length > 0 &&
                            typeNames[contentType].name) ||
                          ''
                        }
                        placement='top'
                        arrow
                      >
                        <span
                          style={{ fontSize: 30 }}
                          className={`icon-${contentType}`}
                        />
                      </Tooltip>
                    </td>

                    <td>{dev_title?.replace(/&amp;/g, '&')}</td>

                    <td>{author}</td>

                    <td>{formatCreate(updated_at || created_at)}</td>

                    <td>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Status status={statusName} view />

                        <span
                          style={{
                            textAlign: 'center',
                            maxWidth: 'min-content',
                          }}
                        >
                          {statusName}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Status visibility={visibilityName} view />

                        <span
                          style={{ textAlign: 'left', maxWidth: 'max-content' }}
                        >
                          {visibilityName}
                        </span>
                      </div>
                    </td>

                    <td>{assignment}</td>

                    <td>
                      <IconButton
                        style={{ borderRadius: 6, padding: 6, color: '#fff' }}
                        className='more-options'
                        onClick={(event) =>
                          SetMoreOptionsMenu([
                            event.currentTarget,
                            contentType,
                            main_content_type,
                            id,
                          ])
                        }
                        size='small'
                      >
                        <span className='icon-more' />
                      </IconButton>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          )}
        </table>

        {moreOptionsMenu && (
          <Menu
            anchorEl={moreOptionsMenu[0]}
            keepMounted
            open={moreOptionsMenu !== null}
            onClose={() => SetMoreOptionsMenu(null)}
          >
            <MenuItem
              style={{ minWidth: 120, height: 32 }}
              onClick={() => {
                if (isContentEdited) {
                  errorToast({
                    message:
                      'You may have one content editor open per user session.',
                  })
                  return
                }

                if (isBeingEdited) {
                  setToastMessage([
                    'warning',
                    'A content is currently being edited. Exit Create Content Mode.',
                  ])
                  SetMoreOptionsMenu(null)
                  setTimeout(() => setToastMessage(null), 3000)
                } else
                  history.push(
                    `/app/content-create/${moreOptionsMenu[1]}/${moreOptionsMenu[3]}`,
                  )
              }}
            >
              <span
                className='icon-edit'
                style={{ color: '#000', fontSize: 16, marginRight: 10 }}
              />

              <span style={{ fontSize: 13, fontWeight: 600 }}>Edit</span>
            </MenuItem>

            <MenuItem
              style={{ minWidth: 120, height: 32 }}
              onClick={() => {
                if (isBeingEdited) {
                  setToastMessage([
                    'warning',
                    'A content is currently being edited. Exit Create Content Mode.',
                  ])
                  SetMoreOptionsMenu(null)
                  setTimeout(() => setToastMessage(null), 3000)
                } else
                  handleDuplicateContent(moreOptionsMenu[1], moreOptionsMenu[3])
              }}
            >
              <ContentCopy
                style={{ color: '#000', fontSize: 16, marginRight: 10 }}
              />

              {isDuplicating ? (
                <Spinner size={18} color='#000' center />
              ) : (
                <span style={{ fontSize: 13, fontWeight: 600 }}>Duplicate</span>
              )}
            </MenuItem>

            <MenuItem
              style={{ minWidth: 120, height: 32 }}
              onClick={() => {
                setConfirmDelete([moreOptionsMenu[3], `${moreOptionsMenu[2]}s`])
                SetMoreOptionsMenu(null)
              }}
            >
              <Delete
                style={{ color: '#000', fontSize: 16, marginRight: 10 }}
              />

              <span style={{ fontSize: 13, fontWeight: 600 }}>Delete</span>
            </MenuItem>
          </Menu>
        )}

        {toastMessage && <GlobalToast time={4000} />}

        {isLoading && <LinearProgress style={{ width: '100%' }} />}
      </div>
    </div>
  )
}

export default PublisherArea
