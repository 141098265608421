import { useState } from 'react'

export const useThrottle = (fn, delay) => {
  const [timeout, saveTimeout] = useState(null)

  const throttledFunc = function () {
    timeout && clearTimeout(timeout)

    const newTimeout = setTimeout(() => {
      fn(...arguments)
      if (newTimeout === timeout) {
        saveTimeout(null)
      }
    }, delay)

    saveTimeout(newTimeout)
  }

  return throttledFunc
}
