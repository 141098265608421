export const options = [
  { id: 6, name: 'Banking & capital markets' },
  { id: 36, name: 'Pension' },
  { id: 18, name: 'Financial services' },
  { id: 24, name: 'Insurance' },
  { id: 20, name: 'Government & public services' },
  { id: 13, name: 'Diplomatic services' },
  { id: 15, name: 'Energy, utilities & mining' },
  { id: 34, name: 'Telecommunications' },
  { id: 33, name: 'Technology' },
  { id: 1, name: 'Academic/Research Institutes' },
  { id: 2, name: 'Aerospace, defence & security' },
  { id: 3, name: 'Agriculture' },
  { id: 4, name: 'Asset & wealth management' },
  { id: 5, name: 'Automotive' },
  { id: 7, name: 'Business and Professional Services' },
  { id: 8, name: 'Capital projects & infrastructure' },
  { id: 9, name: 'Charity/Not-for-profit' },
  { id: 10, name: 'Chemicals' },
  { id: 11, name: 'Commercial Aviation' },
  { id: 12, name: 'Commodities' },
  { id: 14, name: 'Education' },
  { id: 16, name: 'Engineering & construction' },
  { id: 17, name: 'Entertainment & media' },
  { id: 19, name: 'Forest, paper & packaging' },
  { id: 21, name: 'Healthcare' },
  { id: 22, name: 'Hospitality & leisure' },
  { id: 23, name: 'Industrial manufacturing' },
  { id: 25, name: 'IT Industry' },
  { id: 26, name: 'Legal' },
  { id: 27, name: 'Metals' },
  { id: 28, name: 'Pharmaceuticals & life sciences' },
  { id: 29, name: 'Private equity' },
  { id: 30, name: 'Retail & consumer' },
  { id: 31, name: 'Semiconductors' },
  { id: 32, name: 'Sovereign investment funds' },
  { id: 35, name: 'Transportation & logistics' },
]

export const moduleTypes = [
  { id: 2, name: 'Alerts' },
  { id: 3, name: 'Profiles' },
  { id: 4, name: 'Scenarios' },
  { id: 5, name: 'Reports' },
  { id: 12, name: 'Chat' },
  { id: 13, name: 'Forum' },
]

export const tlpColours = [
  { colour_name: 'CLEAR', name: 'clear', color: '#000' },
  { colour_name: 'GREEN', name: 'green', color: '#3aaa35' },
  { colour_name: 'AMBER', name: 'amber', color: '#ffc000' },
  { colour_name: 'AMBER+STRICT', name: 'amber_strict', color: '#ffc000' },
  { colour_name: 'RED', name: 'red', color: '#ff0033' },
]

export const roles = [
  { id: 3, name: 'Publisher' },
  { id: 2, name: 'Editor' },
  { id: 1, name: 'Viewer' },
]

export const initialTlp = {
  white: {
    title: '',
    description: '',
  },
  green: {
    title: '',
    description: '',
  },
  amber: {
    title: '',
    description: '',
  },
  red: {
    title: '',
    description: '',
  },
}

export const misc = [
  {
    option: 'is_notification_system_enabled',
    title: 'Email Notifications Enabled',
  },
  {
    option: 'dont_show_authors',
    title: 'Hide Authors',
  },
]

export const initialState = {
  name: '',
  dont_show_authors: false,
  is_notification_system_enabled: true,
  is_pdf_upload_enabled: false,
  default_group_settings: {
    include_new_users: false,
    make_anonymous: false,
  },
  misp_instance: {
    access_key: '',
    default_creator_id: null,
    domain: '',
  },
  groups: [],
  users: [],
  modules: [2, 3, 4, 5],
  notes: '',
  sectors: [],
  tlp_classifications: {
    white: {
      title: '',
      description: '',
    },
    green: {
      title: '',
      description: '',
    },
    amber: {
      title: '',
      description: '',
    },
    red: {
      title: '',
      description: '',
    },
  },
}
