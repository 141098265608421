import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const login = (data) => client.post(apiUrl + '/api/auth/login', data)

export const login2FA = (data) =>
  client.post(apiUrl + '/api/auth/two-factor', data)

export const forgotPassword = (data) =>
  client.post(apiUrl + '/api/auth/password/recover', data)

export const logout = (token) =>
  client.post(
    apiUrl + '/api/auth/logout',
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const resetPassword = (token, hash, id, data) =>
  client.post(apiUrl + `/api/auth/password/${hash}/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const isLinkValid = (token, hash, id) =>
  client.get(apiUrl + `/api/auth/activate/${hash}/${id}?noAuthorization=true`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const activate = (token, hash, id, data) =>
  client.post(apiUrl + `/api/auth/activate/${hash}/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const confirmToken = (data) =>
  client.post(apiUrl + `/api/auth/two-factor/confirm`, data)
