import React, { Fragment } from 'react'
import './UserSettings.scss'
import PropTypes from 'prop-types'
import { Divider, Button } from '@material-ui/core'
import { useUserContext } from '../../../context/userContext'

/**
 * UserSettings
 * @param {function} handleTabChange - changing settings tab
 * @param {boolean} isAdmin - is admin mode
 */

const UserSettings = ({ handleTabChange, isAdmin }) => {
  const { handleLogout } = useUserContext()

  const settingsOptions = [
    {
      name: 'Notification Preferences',
      icon: 'icon-bell',
      slug: 'notifications',
    },
    {
      name: 'API',
      icon: 'icon-developers-platform',
      slug: 'api',
      renderDivider: true,
    },
    { name: 'Your Details', icon: 'icon-user', slug: 'user-details' },
    { name: 'Change Password', icon: 'icon-keyboard', slug: 'change-password' },
    {
      name: 'Login history',
      icon: 'icon-history',
      slug: 'login-history',
      renderDivider: true,
    },
  ]

  return (
    <div className='setting-tabs'>
      {!isAdmin &&
        settingsOptions.map(({ name, icon, slug, renderDivider }) => (
          <Fragment key={slug}>
            <Button
              variant='contained'
              onClick={() => handleTabChange(slug)}
              startIcon={<span className={icon} />}
            >
              {name}
            </Button>

            {renderDivider && <Divider />}
          </Fragment>
        ))}

      <Button
        variant='contained'
        onClick={() => handleLogout()}
        startIcon={<span className='icon-exit' />}
      >
        Logout
      </Button>
    </div>
  )
}

UserSettings.displayName = 'UserSettings'

/**
 * The properties.
 * @type {Object}
 */

UserSettings.propTypes = {
  handleTabChange: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
}

export default UserSettings
