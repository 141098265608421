import React from 'react'
import ModuleListing from '../../../../../ModuleListing'

const AssociatedProfiles = ({ items, setActivePiece, type }) => {
  return (
    <div className='associated-content'>
      <ModuleListing
        associated='profiles'
        items={items}
        setActivePiece={setActivePiece}
        type={type}
      />
    </div>
  )
}

export default AssociatedProfiles
