import React, { createContext, useContext, useEffect, useState } from 'react'
import { getCategories } from '../shared/services/api/knowledgebase/knowledgebase'

const token = () => {
  return localStorage.getItem('access_token')
}

const fetchCategories = async () => {
  try {
    const { categories } = await getCategories(token())

    return categories
  } catch (err) {
    // setLoading(false)
    console.log(err)
  }
}

export const KnowledgeBaseContext = createContext({
  activeLink: '',
  categories: [],
  categoriesShown: true,
  expandedCategories: [],
})

// eslint-disable-next-line react/prop-types
export const KnowledgeBaseContextProvider = ({ children }) => {
  const [activeLink, setActiveLink] = useState('')
  const [categories, setCategories] = useState([])
  const [categoriesShown, setCategoriesShown] = useState([])
  const [expandedCategories, setExpandedCategories] = useState([])

  useEffect(() => {
    ;(async () => {
      setCategories(await fetchCategories())
    })()
  }, [])

  const props = {
    activeLink,
    categories,
    categoriesShown,
    expandedCategories,
    setActiveLink,
    setCategoriesShown,
    setExpandedCategories,
  }

  const Provider = KnowledgeBaseContext.Provider

  return <Provider value={props}>{children}</Provider>
}

export const useKnowledgeBaseContext = () => {
  return useContext(KnowledgeBaseContext)
}
