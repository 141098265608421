/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import './Chat.scss'
import ConversationList from './components/ConversationList'
import SingleConversation from './components/SingleConversation'
import { useChat } from '../../context/chatContext'
import { QuestionAnswer } from '@mui/icons-material'
import { isMobile } from 'react-device-detect'
import { useUserContext } from '../../context/userContext'
import { LinearProgress } from '@material-ui/core'
import Spinner from '../../shared/Spinner'

const Chat = ({}) => {
  const {
    activeChat,
    chatOpen,
    people,
    setQueryData,
    setActiveChat,
    setActiveUserChat,
    setEmailFetchState,
    emailFetchState,
  } = useChat()
  const { user } = useUserContext()

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const groupId = query?.get('group_id')
    const chatId = query?.get('chat_id')
    if (chatId && !activeChat?.id) {
      setEmailFetchState('started')
      const msgId = query?.get('message_id')
      setQueryData({
        group_id: groupId || null,
        chat_id: chatId || null,
        message_id: msgId || null,
      })
      const group = user?.chat_groups?.find(
        ({ id }) => id === (groupId ? +groupId : +chatId), //if direct, group_id, if group, set chat_id
      )
      if (group?.id && group?.name && !activeChat?.id) {
        setActiveChat({
          id: group?.id,
          name: group?.name,
        })
      }
    } else if (groupId && chatId && people?.list?.length > 0) {
      setEmailFetchState('started')
      //is direct message
      const user = people?.list?.find(
        ({ central_user_id }) => central_user_id === +chatId,
      )
      if (user) {
        const { central_user_id, display_name } = user
        setActiveUserChat({
          id: central_user_id,
          name: display_name,
        })
      }
    }

    if (!groupId && !chatId && !activeChat?.id) {
      if (
        !activeChat &&
        user?.chat_groups[0]?.id &&
        user?.chat_groups[0]?.name
      ) {
        setActiveChat({
          id: user?.chat_groups[0]?.id,
          name: user?.chat_groups[0]?.name,
        })
      }
    }
  }, [people, user, activeChat])

  const chatWidth = () => {
    if (isMobile && chatOpen) {
      return undefined
    } else if (isMobile && !chatOpen) {
      return 'none'
    }
    return undefined
  }

  return (
    <div
      style={{
        width: isMobile ? '100%' : undefined,
        display: chatWidth(),
        pointerEvents: emailFetchState === 'started' ? 'none' : 'all',
      }}
      className='chat'
    >
      <ConversationList />

      {activeChat && <SingleConversation />}

      {emailFetchState === 'started' ? (
        <div style={{ position: 'absolute', zIndex: 11, left: 12, top: 9 }}>
          <Spinner size={40} border={4} />
        </div>
      ) : null}

      {!activeChat && chatOpen && (
        <div
          style={{
            width: isMobile ? 'calc(100% - 76.5px)' : 495.5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 15,
          }}
        >
          <QuestionAnswer style={{ fontSize: 150 }} />
        </div>
      )}
    </div>
  )
}

export default Chat
