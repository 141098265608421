/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import {
  Button,
  Divider,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core'
import SingleForumCategory from './SingleForumCategory'
import { useHistory } from 'react-router-dom'
import { deleteForumCategory } from '../../../shared/services/api/admin/forum/categories'
import useNotification from '../../../hooks/useNotification'
import Spinner from '../../../shared/Spinner'
import { useQueryGetForumCategoriesAdmin } from '../../../shared/services/queries/forum-admin/useQueryGetForumCategories'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import DeleteCategoryModal from './DeleteCategoryModal'

interface Category {
  id: number
  name: string
  color?: string
  description: string
  updated_at?: string
  slug?: string
}

const ForumCategories = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const { successToast } = useNotification()
  const [confirmDelete, setConfirmDelete] = useState<string | number | false>(
    false,
  )
  const categories = useQueryGetForumCategoriesAdmin()
  const { mutate: deleteCategoryMutation } = useMutation({
    mutationFn: deleteForumCategory,
    onSuccess: () => {
      setConfirmDelete(false)
      successToast({ message: 'Category deleted successfully' })
      queryClient.invalidateQueries(['categories-admin'])
    },
  })

  return (
    <div className='login-history'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Categories</h2>
      </div>

      <Divider />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
          gap: 15,
          marginTop: 40,
          marginBottom: 20,
        }}
      >
        <div style={{ width: 'calc(100% - 180px)' }}>
          <h5 style={{ position: 'absolute', top: '-20px' }}>Search</h5>

          <form
            onSubmit={(event) => {
              event.preventDefault()
            }}
          >
            <OutlinedInput
              // variant='outlined'
              placeholder='Search'
              value={searchPhrase}
              inputProps={{
                style: { padding: '14px 14px 14px 0' },
              }}
              onChange={(event) => setSearchPhrase(event.target.value)}
              startAdornment={
                <InputAdornment position='start'>
                  <span style={{ fontSize: 26 }} className='icon-search' />
                </InputAdornment>
              }
            />
          </form>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            minWidth: 170,
            gap: 15,
          }}
        >
          <Button
            style={{
              backgroundColor: '#006fff',
              textTransform: 'capitalize',
              color: '#fff',
              minHeight: 47,
            }}
            onClick={() => history.push('/app/admin/forum/category-new')}
          >
            Create New Category
          </Button>
        </div>
      </div>

      {categories.isLoading ? (
        <Spinner centerHorizontally size={30} border={1} />
      ) : null}

      {confirmDelete !== false && (
        <DeleteCategoryModal
          confirmDeleteOpen={confirmDelete}
          setConfirmDeleteOpen={setConfirmDelete}
          handleDeleteComment={deleteCategoryMutation}
          id={confirmDelete}
        />
      )}

      <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
        {categories?.data?.data?.categories
          ?.filter(
            ({ name }: { name: string }) =>
              name?.toLowerCase().includes(searchPhrase?.toLowerCase()),
          )
          .map(
            (category: {
              id: number
              name: string
              color?: string
              description: string
              updated_at?: string
              slug?: string
            }) => (
              <SingleForumCategory
                newCategory={false}
                key={category.id}
                data={category}
                setConfirmDelete={setConfirmDelete}
              />
            ),
          )}
      </div>
    </div>
  )
}

export default ForumCategories
