import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import UserDetails from './Tabs/UserDetails'
import ChangePassword from './Tabs/ChangePassword'
import NotificationPreferences from './Tabs/NotificationPreferences'
import LoginHistory from './Tabs/LoginHistory'
import Developers from '../../Developers'
import TabList from './Tabs/TabList/'
import { cancelRequests } from '../../../shared/services/http/client'
import AccountPopup from '../AccountPopup'
import { useUserContext } from '../../../context/userContext'

/**
 * AccountTabs
 * @param {object} userData - user details
 * @param {function} setUserData - update user details
 * @param {boolean} isPopupOpen - is account modal open
 * @param {function} setIsPopupOpen - setting account popup open state
 * @param {boolean} isAuthenticated - is authenticated
 * @param {function} setAuthenticated - setting authenticated state
 */

const AccountTabs = ({
  userData,
  isPopupOpen,
  setIsPopupOpen,
  isAuthenticated,
  setAuthenticated,
}) => {
  const {
    user: { is_admin },
  } = useUserContext()

  useEffect(() => {
    return () => {
      cancelRequests()
    }
  }, [])

  return (
    <div className='account-tabs'>
      <TabList setIsPopupOpen={setIsPopupOpen} />

      <div className='account-tabs__container'>
        <Route
          exact
          path={
            is_admin
              ? '/app/admin/settings/notifications'
              : '/app/settings/notifications'
          }
        >
          <NotificationPreferences />
        </Route>

        {!is_admin && (
          <Route exact path='/app/settings/api' component={Developers} />
        )}

        <Route
          exact
          path={
            is_admin
              ? '/app/admin/settings/user-details'
              : '/app/settings/user-details'
          }
        >
          <UserDetails userData={userData} />
        </Route>

        <Route
          path={
            is_admin
              ? '/app/admin/settings/change-password'
              : '/app/settings/change-password'
          }
        >
          {isAuthenticated ? (
            <ChangePassword setAuthenticated={setAuthenticated} />
          ) : (
            <AccountPopup
              isPopupOpen={isPopupOpen}
              setIsPopupOpen={setIsPopupOpen}
              setAuthenticated={setAuthenticated}
            />
          )}
        </Route>

        <Route
          exact
          path={
            is_admin
              ? '/app/admin/settings/login-history'
              : '/app/settings/login-history'
          }
          component={LoginHistory}
        />
      </div>
    </div>
  )
}

AccountTabs.displayName = 'AccountTabs'

/**
 * The properties.
 * @type {Object}
 */

AccountTabs.propTypes = {
  userData: PropTypes.instanceOf(Object).isRequired,
  // setUserData: PropTypes.func.isRequired,
  isPopupOpen: PropTypes.bool.isRequired,
  setIsPopupOpen: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  setAuthenticated: PropTypes.func.isRequired,
}

export default AccountTabs
