/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useState, useEffect, Fragment, useRef } from 'react'
import {
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core'
import {
  addComment,
  deleteComment,
  editComment,
  reportComment,
} from '../../../../../../shared/services/api/comments/comments'
import EditEditor from './Components/EditEditor'
import ReplyEditor from './Components/ReplyEditor'
import ConfirmDeleteComment from './Components/ConfirmDeleteComment'
import HoverMenu from './Components/HoverMenu'
import Subcomments from './Components/Subcomments'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { formatDate } from '../../../../../../shared/helpers/formatDate'
import { useGlobalContext } from '../../../../../../context/globalContext'
import { useUserContext } from '../../../../../../context/userContext'
import ReportModal from './Components/ReportModal'
import FroalaEditorComponent from '../../../../../../shared/FroalaEditorComponent/FroalaEditorComponent'
import useNotification from '../../../../../../hooks/useNotification'

const Comments = ({
  data,
  activePiece,
  type,
  contentData,
  currentModule,
  setData,
}) => {
  const { handleGlobalState } = useGlobalContext()
  const {
    user: { organisation_name, comments_access_groups },
  } = useUserContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [distributionAnchor, setDistributionAnchor] = useState(null)
  const [commentEditorOpen, setCommentEditorOpen] = useState(false)
  const [replyEditorOpen, setReplyEditorOpen] = useState(false)
  const [editEditorOpen, setEditEditorOpen] = useState(false)
  const [isEditorTouched, setIsEditorTouched] = useState('')
  const editorRef = useRef(null)
  const distributionOptions = [
    ...comments_access_groups[type],
    { name: 'Only me', id: 1, alwaysDisplay: true },
    { name: 'Content author', id: 2, alwaysDisplay: true },
    { name: 'My organisation', id: 3, alwaysDisplay: true },
  ]
  const [distributionId, setDistributionId] = useState(
    distributionOptions[0]?.id,
  )
  const [menuHoverAnchor, setMenuHoverAnchor] = useState(null)
  const [hoveredId, setHoveredId] = useState({})
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [showReply, setShowReply] = useState([])
  const [reportDialog, setReportDialog] = useState(undefined)
  const [isAdding, setIsAdding] = useState(false)
  const handleDistributionClose = () => setDistributionAnchor(null)
  const handleMenuHoverClose = () => setMenuHoverAnchor(null)
  const handleCloseEditors = () => {
    setIsEditorTouched('')
    setCommentEditorOpen(false)
    setReplyEditorOpen(false)
    setEditEditorOpen(false)
  }
  const { errorToast, successToast } = useNotification()

  const handleCommentMenuAction = (action, id) => {
    setMenuHoverAnchor(null)
    handleCloseEditors()

    if (action === 'Delete') {
      setConfirmDeleteOpen(true)
    } else if (action === 'Reply') {
      setReplyEditorOpen([true, id])
    } else if (action === 'Edit') {
      setEditEditorOpen([true, id])
    } else if (action === 'Report') {
      setReportDialog(id)
    }
  }

  const report = async (reportReason) => {
    try {
      const data = { comment_id: reportDialog, reason: reportReason }
      const response = await reportComment(
        token(),
        type + 's',
        activePiece,
        data,
      )

      if (response.error) {
        errorToast({
          message: response.message,
        })
        setReportDialog(undefined)
        return false
      }

      successToast({
        message: response.message,
      })
      setReportDialog(undefined)

      const currentState = { ...contentData }

      if (response.comment_data.parent_id === null) {
        const editedComment = currentState.comments.find(
          (comment) => comment.id === response.comment_data.id,
        )
        editedComment.is_reported = true
        editedComment.content = response.comment_data.content
      } else {
        const parentComment = currentState.comments.find(
          (parent) => parent.id === response.comment_data.parent_id,
        )
        const editedSubcomment = parentComment.subcomments.find(
          (parent) => parent.id === response.comment_data.id,
        )
        editedSubcomment.is_reported = true
        editedSubcomment.content = response.comment_data.content
      }

      successToast({
        message: response.message,
      })
      setData(currentState)
      handleGlobalState(
        currentModule === 'malware-tools' ? currentModule : currentModule + 's',
        currentState,
        true,
        false,
      )
    } catch (err) {}
  }

  useEffect(() => {
    editorRef.current !== null &&
      document
        .querySelector('.editor-wrapper')
        .scrollIntoView({ behavior: 'smooth' })
  }, [commentEditorOpen])

  const commentDelete = async (id) => {
    try {
      const response = await deleteComment(token(), type + 's', activePiece, id)

      if (response.error) {
        errorToast({
          message: response.message,
        })
        setConfirmDeleteOpen(false)
        return false
      }

      const currentState = { ...contentData }

      if (response.comment_data.parent_id === null) {
        const editedComment = currentState.comments.find(
          (comment) => comment.id === response.comment_data.id,
        )
        editedComment.content = response.comment_data.content
        editedComment.is_deleted = true
      } else {
        const parentComment = currentState.comments.find(
          (parent) => parent.id === response.comment_data.parent_id,
        )
        const editedSubcomment = parentComment.subcomments.find(
          (parent) => parent.id === response.comment_data.id,
        )
        editedSubcomment.content = response.comment_data.content
        editedSubcomment.is_deleted = true
      }

      setConfirmDeleteOpen(false)
      successToast({
        message: response.message,
      })
      setData(currentState)
      handleGlobalState(
        currentModule === 'malware-tools' ? currentModule : currentModule + 's',
        currentState,
        true,
        false,
      )
    } catch (err) {}
  }

  const handleDeleteComment = (id) => commentDelete(id)

  const handleShowReply = (id) => {
    setShowReply([...showReply, id])
    showReply.includes(id)
      ? setShowReply(showReply.filter((item) => item !== id))
      : setShowReply([...showReply, id])
    // setShowReply(id)
  }

  const handleOpenCommentEditor = () => {
    setReplyEditorOpen(false)
    setCommentEditorOpen(true)
  }

  const handleDistributionId = (id) => {
    setDistributionId(id)
    setDistributionAnchor(null)
  }

  const handleEditorChange = (content) => setIsEditorTouched(content)
  const addNewComment = async (id, parentId) => {
    try {
      let data = {}
      if (distributionId < 4 && parentId === undefined) {
        data = { content: isEditorTouched, distribution: distributionId }
      } else if (distributionId >= 4 && parentId === undefined) {
        data = { content: isEditorTouched, group_id: distributionId }
      } else if (parentId !== undefined) {
        data = { content: isEditorTouched, parent_id: parentId }
      }

      const response = await addComment(token(), type + 's', id, data)

      if (response.error) {
        setCommentEditorOpen(false)
        errorToast({
          message: response.message,
        })
        setIsAdding(false)
        return false
      }

      const currentState = { ...contentData }

      if (response.comment_data.parent_id === null) {
        currentState.comments = [
          response.comment_data,
          ...currentState.comments,
        ]
      } else {
        const parentComment = currentState.comments.filter(
          (parent) => parent.id === response.comment_data.parent_id,
        )[0]
        parentComment.subcomments = [
          response.comment_data,
          ...parentComment.subcomments,
        ]
        setReplyEditorOpen(false)
      }

      setCommentEditorOpen(false)
      successToast({
        message: response.message,
      })
      setData(currentState)
      handleGlobalState(
        currentModule === 'malware-tools' ? currentModule : currentModule + 's',
        currentState,
        true,
        false,
      )
      setIsAdding(false)
    } catch (err) {}
  }

  const handleAddComment = (id, parentId) => {
    setIsAdding(true)
    addNewComment(id, parentId)
  }

  const editCurrentComment = async (id, commentId) => {
    try {
      const data = { content: isEditorTouched }
      const response = await editComment(
        token(),
        type + 's',
        id,
        commentId,
        data,
      )

      if (response.error) {
        errorToast({
          message: response.message,
        })
        return false
      }

      const currentState = { ...contentData }

      if (response.comment_data.parent_id === null) {
        const editedComment = currentState.comments.find(
          (comment) => comment.id === response.comment_data.id,
        )
        editedComment.content = response.comment_data.content
        editedComment.updated_at = response.comment_data.updated_at
      } else {
        const parentComment = currentState.comments.find(
          (parent) => parent.id === response.comment_data.parent_id,
        )
        const editedSubcomment = parentComment.subcomments.find(
          (parent) => parent.id === response.comment_data.id,
        )
        editedSubcomment.content = response.comment_data.content
        editedSubcomment.updated_at = response.comment_data.updated_at
      }

      setEditEditorOpen(false)
      successToast({
        message: response.message,
      })
      setData(currentState)
      handleGlobalState(
        currentModule === 'malware-tools' ? currentModule : currentModule + 's',
        currentState,
        true,
        false,
      )
    } catch (err) {}
  }

  const handleEditComment = (id, commentId) => editCurrentComment(id, commentId)

  return (
    <div className='comments-wrapper' style={{ width: '100%' }}>
      <div style={{ width: '100%', textAlign: 'left', margin: '0 auto' }}>
        <h3 style={{ color: '#000', width: '95%', margin: '0 auto' }}>
          Comments
        </h3>

        <Divider style={{ width: '95%', margin: '10px auto' }} />

        {reportDialog ? (
          <ReportModal
            report={report}
            reportDialog={reportDialog}
            setReportDialog={setReportDialog}
          />
        ) : null}

        <div style={{ marginBottom: 20 }}>
          {data?.map((comment) => (
            <Fragment key={comment.id}>
              <div className='single-comment'>
                <div className='single-comment__top'>
                  <div
                    onMouseOver={() => setHoveredId(comment.id)}
                    onMouseLeave={() => setHoveredId(null)}
                    className='single-comment__top-main'
                  >
                    <div className='single-comment__top-content'>
                      <div
                        style={{
                          fontSize: '14px',
                          color:
                            comment.is_reported || comment.is_deleted
                              ? '#7f7f7f'
                              : '#283143',
                          fontStyle:
                            comment.is_reported || comment.is_deleted
                              ? 'italic'
                              : 'unset',
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            comment.is_reported === true
                              ? 'This comment is hidden'
                              : comment.content,
                        }}
                      />

                      {!comment?.is_reported && (
                        <div style={{ fontSize: '11px', color: '#7f7f7f' }}>
                          {`${comment.author_name} - ${formatDate(
                            comment.created_at,
                          )}`}
                        </div>
                      )}

                      {comment.subcomments.length > 0 && (
                        <Button
                          size='small'
                          style={{
                            textTransform: 'capitalize',
                            fontSize: 12,
                            maxHeight: 30,
                            padding: '0 4px',
                            marginTop: 5,
                          }}
                          disableRipple
                          onClick={() => handleShowReply(comment.id)}
                        >
                          {showReply.includes(comment.id) && (
                            <KeyboardArrowUp />
                          )}

                          {!showReply.includes(comment.id) && (
                            <KeyboardArrowDown />
                          )}

                          {!showReply.includes(comment.id) && (
                            <span>
                              Show Replies ({comment.subcomments.length})
                            </span>
                          )}

                          {showReply.includes(comment.id) && (
                            <span>Hide Replies</span>
                          )}
                        </Button>
                      )}
                    </div>

                    {comment.id === hoveredId && (
                      <Fragment>
                        <HoverMenu
                          setMenuHoverAnchor={setMenuHoverAnchor}
                          menuHoverAnchor={menuHoverAnchor}
                          handleCommentMenuAction={handleCommentMenuAction}
                          handleMenuHoverClose={handleMenuHoverClose}
                          commentId={comment.id}
                          canEdit={comment.has_access_to_update}
                          canDelete={comment.has_access_to_delete}
                          authorId={comment.author_id}
                          isReported={comment.is_reported}
                          isDeleted={comment.is_deleted}
                        />

                        <ConfirmDeleteComment
                          confirmDeleteOpen={confirmDeleteOpen}
                          setConfirmDeleteOpen={setConfirmDeleteOpen}
                          handleDeleteComment={handleDeleteComment}
                          id={comment.id}
                        />
                      </Fragment>
                    )}
                  </div>

                  {editEditorOpen[0] && editEditorOpen[1] === comment.id ? (
                    <EditEditor
                      handleEditorChange={handleEditorChange}
                      editorRef={editorRef}
                      isEditorTouched={isEditorTouched}
                      handleEditComment={handleEditComment}
                      activePiece={activePiece}
                      comment={comment}
                      handleCloseEditors={handleCloseEditors}
                    />
                  ) : null}

                  {comment.subcomments.length > 0 &&
                    showReply.includes(comment.id) && (
                      <Subcomments
                        items={comment.subcomments}
                        hoveredId={hoveredId}
                        setHoveredId={setHoveredId}
                        confirmDeleteOpen={confirmDeleteOpen}
                        setMenuHoverAnchor={setMenuHoverAnchor}
                        menuHoverAnchor={menuHoverAnchor}
                        handleCommentMenuAction={handleCommentMenuAction}
                        handleMenuHoverClose={handleMenuHoverClose}
                        editEditorOpen={editEditorOpen}
                        handleEditorChange={handleEditorChange}
                        editorRef={editorRef}
                        isEditorTouched={isEditorTouched}
                        handleEditComment={handleEditComment}
                        activePiece={activePiece}
                        handleCloseEditors={handleCloseEditors}
                        setConfirmDeleteOpen={setConfirmDeleteOpen}
                        handleDeleteComment={handleDeleteComment}
                      />
                    )}
                </div>

                {replyEditorOpen[0] && replyEditorOpen[1] === comment.id ? (
                  <ReplyEditor
                    handleEditorChange={handleEditorChange}
                    editorRef={editorRef}
                    isEditorTouched={isEditorTouched}
                    handleAddComment={handleAddComment}
                    activePiece={activePiece}
                    comment={comment}
                    handleCloseEditors={handleCloseEditors}
                    isAdding={isAdding}
                  />
                ) : null}
              </div>
            </Fragment>
          ))}

          {!commentEditorOpen && (
            <Button onClick={handleOpenCommentEditor} className='add-comment'>
              Add Comment
            </Button>
          )}
        </div>
      </div>

      {commentEditorOpen ? (
        <div className='editor-wrapper'>
          <IconButton
            className='close'
            onClick={() => setCommentEditorOpen(false)}
          >
            <span className='icon-close' />
          </IconButton>

          <FroalaEditorComponent handleEditorChange={handleEditorChange} />

          <div className='editor-footer'>
            <Button
              className='distribution'
              onClick={(event) => setDistributionAnchor(event.currentTarget)}
            >
              Who can see your comment{' '}
              <span style={{ fontWeight: 700, marginLeft: 2 }}>
                (
                {distributionOptions?.find((item) => item.id === distributionId)
                  ?.name || 'Only me'}
                )
              </span>
            </Button>

            <Menu
              anchorEl={distributionAnchor}
              keepMounted
              style={{ maxHeight: 400 }}
              open={Boolean(distributionAnchor)}
              onClose={handleDistributionClose}
            >
              {distributionOptions
                .filter((option) => option.alwaysDisplay)
                .map((item) => (
                  <MenuItem
                    onClick={() => handleDistributionId(item.id)}
                    key={item.id}
                  >
                    {item.id === 3 ? `${organisation_name} only` : item.name}
                  </MenuItem>
                ))}

              {comments_access_groups[type]?.map((group) => (
                <MenuItem
                  key={group.id}
                  onClick={() => handleDistributionId(group.id)}
                >
                  {group.name}
                </MenuItem>
              ))}
            </Menu>

            <Button
              disabled={isEditorTouched.length === 0}
              className='add'
              onClick={() => handleAddComment(activePiece)}
            >
              {isAdding ? (
                <CircularProgress
                  style={{ fontSize: 18, color: '#fff', width: 20, height: 20 }}
                />
              ) : (
                'Add comment'
              )}
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Comments
