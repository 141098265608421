import { Button, Dialog, Divider } from '@material-ui/core'
import Spinner from '../../../../shared/Spinner'

const DeleteModal = ({ isDeleting, setIsDeleting, handleDelete }) => {
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={isDeleting}
      onClose={() => setIsDeleting(undefined)}
    >
      <div style={{ padding: 24 }}>
        <h3>Confirm Delete</h3>

        <Divider style={{ margin: '10px 0', border: 'none' }} />

        <span>This action cannot be undone.</span>
      </div>

      <div
        style={{
          padding: '0 24px 16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => setIsDeleting(false)}>Cancel</Button>

        <Button
          style={{
            backgroundColor: '#FF0033',
            color: '#fff',
            textTransform: 'capitalize',
            fontWeight: 700,
            borderRadius: 12,
            padding: '6px 20px',
            minWidth: 85,
          }}
          onClick={() => handleDelete(isDeleting)}
        >
          {!isDeleting ? (
            <Spinner color='#fff' size={20} border={1} />
          ) : (
            <span>Delete</span>
          )}
        </Button>
      </div>
    </Dialog>
  )
}

DeleteModal.displayName = 'DeleteModal'

export default DeleteModal
