export const roles = [
  { id: 3, name: 'Publisher' },
  { id: 2, name: 'Editor' },
  { id: 1, name: 'Viewer' },
  { id: 0, name: 'No Access' },
]

export const checkboxes = [
  {
    id: 4,
    label: 'Email notifications enabled',
    slug: 'is_notification_system_enabled',
    value: true,
  },
  {
    id: 2,
    label: 'Two Factor Authentication enabled',
    slug: 'is_two_factor_enabled',
    value: true,
  },
  {
    id: 5,
    label: 'API enabled',
    slug: 'has_access_to_developers_platform',
    value: true,
  },
  {
    id: 8,
    label: 'Content Create Permission',
    slug: 'has_content_create_permission',
  },
  {
    id: 1,
    label: 'Tag Creation enabled for this User',
    slug: 'is_tag_creation_enabled',
  },
  { id: 3, label: 'Moderator role enabled', slug: 'is_comments_moderator' },
  {
    id: 6,
    label: 'Link Analysis Enabled',
    slug: 'is_link_analysis_enabled',
    value: true,
  },
  {
    id: 7,
    label: 'Chat Enabled',
    slug: 'is_chat_enabled',
    value: true,
  },
  {
    id: 8,
    label: 'Forum Enabled',
    slug: 'is_forum_enabled',
    value: true,
  },
  {
    id: 9,
    label: 'Community Admin Enabled',
    slug: 'is_community_admin',
    value: false,
  },
]

export const permissions = [
  'Groups',
  'Alerts',
  'Profiles',
  'Scenarios',
  'Reports',
]

export const initData = {
  is_chat_enabled: true,
  is_tag_creation_enabled: false,
  is_two_factor_enabled: false,
  is_comments_moderator: false,
  is_notification_system_enabled: false,
  has_access_to_developers_platform: false,
  privileges: {},
}

export const textFields = [
  { slug: 'username', placeholder: 'Username', width: 'full' },
  { slug: 'first_name', placeholder: 'First Name', width: 'half' },
  { slug: 'last_name', placeholder: 'Last Name', width: 'half' },
  { slug: 'email', placeholder: 'Email', width: 'full' },
]

export const modules = [
  {
    id: 2,
    name: 'Alerts',
    sort_id: 2,
  },
  {
    id: 3,
    name: 'Profiles',
    sort_id: 3,
  },
  {
    id: 4,
    name: 'Scenarios',
    sort_id: 4,
  },
  {
    id: 5,
    name: 'Reports',
    sort_id: 5,
  },
]
