import React from 'react'
import ArticleTextSection from './ArticleTextSection'
import ArticleButtonSection from './ArticleButtonSection'
import ArticleVideoSection from './ArticleVideoSection'

const ArticleSection = (props) => {
  const {
    index,
    content: { type },
  } = props

  if (type === 'text') {
    return (
      <ArticleTextSection key={type} props={props} id={index + '_' + type} />
    )
  } else if (type === 'button') {
    return (
      <ArticleButtonSection key={type} props={props} id={index + '_' + type} />
    )
  } else if (type === 'video') {
    return (
      <ArticleVideoSection key={type} props={props} id={index + '_' + type} />
    )
  }
}

export default ArticleSection
