import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { resetPassword } from '../../../shared/services/api/login/login'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

const ResetPassword = () => {
  const [isLoading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const history = useHistory()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { hash, id } = useParams()
  const [toastMessage, setToastMessage] = useState(undefined)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const data = {
        password: password,
        password_confirmation: repeatPassword,
        noAuthorization: true,
      }

      const response = await resetPassword(token(), hash, id, data)

      if (response.error === true) {
        setLoading(false)
        setToastMessage(['error', response.message])
        // usernameRef.current.value = ''
        return false
      }

      setLoading(false)
      history.push({
        pathname: '/app/auth/login',
        state: { message: response.message },
      })
    } catch (error) {}
  }

  return (
    <Container disableGutters component='main' maxWidth='xs'>
      <Box className='auth-panel'>
        {toastMessage !== undefined && (
          <Snackbar
            autoHideDuration={10000}
            onClose={() => setToastMessage(undefined)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={toastMessage !== undefined}
          >
            <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
              <div
                style={{ fontSize: '14px', color: '#fff', fontWeight: 400 }}
                dangerouslySetInnerHTML={{ __html: toastMessage[1] }}
              />
            </MuiAlert>
          </Snackbar>
        )}

        <h4 style={{ fontWeight: 400 }}>Reset password</h4>

        <form
          className='two-factor-authentication-form'
          onSubmit={handleSubmit}
        >
          <TextField
            onChange={(event) => setPassword(event.target.value)}
            variant='outlined'
            margin='normal'
            required
            type='password'
            style={{ width: '100%' }}
            label='Password'
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />

          <TextField
            onChange={(event) => setRepeatPassword(event.target.value)}
            variant='outlined'
            margin='normal'
            required
            type='password'
            style={{ width: '100%' }}
            label='Repeat password'
          />

          <Box className='form-buttons'>
            <Button
              style={{ minWidth: 88 }}
              type='submit'
              variant='contained'
              color='primary'
              className='form-button--submit'
            >
              {isLoading ? (
                <CircularProgress
                  style={{ fontSize: 20, color: '#fff', width: 24, height: 24 }}
                />
              ) : (
                <span>SUBMIT</span>
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  )
}

export default ResetPassword
