import React, { createContext, useContext, useState } from 'react'

export const dirtyCreateContext = createContext({
  dirtyCreateState: {},
  setDirtyCreateState: () => {},
})

// eslint-disable-next-line react/prop-types
export const DirtyCreateContextProvider = ({ children }) => {
  const [dirtyCreateState, setDirtyCreateState] = useState({})

  const props = {
    dirtyCreateState,
    setDirtyCreateState,
  }
  const Provider = dirtyCreateContext.Provider

  return <Provider value={props}>{children}</Provider>
}

export const useDirtyCreate = () => useContext(dirtyCreateContext)
