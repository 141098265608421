/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import { useQueryCacheState } from '../../useQueryCacheState'
import { getClientId } from '../../../api/clientInfo/clientInfo'

const token = () => {
  return localStorage.getItem('access_token')
}

export const useQueryGetApi = () => {
  return useQuery({
    queryKey: ['user-api'],
    queryFn: () => getClientId(token()),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}

export const useQueryCacheGetApi = () => useQueryCacheState('user-api')
