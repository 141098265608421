export const initialData = {
  8: {
    title: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
    },
    dates: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'executive-summary': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    content: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    assessment: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'sector-relevance': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    tags: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'mitre-tags': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    ioc: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-profiles': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-alerts': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'content-distribution': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'author-settings': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    tlp: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    // "update-summary": {
    //     "is_complete": false,
    //     "is_rejected": false,
    //     "is_accepted": false,
    //     "has_been_edited": false,
    //     "content": {}
    // }
  },
  9: {
    title: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
    },
    dates: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    content: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'source-and-assessment': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      content: {
        free_content: [],
      },
      comments: [],
    },
    assessment: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'sector-relevance': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    tags: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'mitre-tags': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    ioc: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-profiles': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-alerts': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'content-distribution': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'author-settings': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    tlp: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    // "update-summary": {
    //     "is_complete": false,
    //     "is_rejected": false,
    //     "is_accepted": false,
    //     "has_been_edited": false,
    //     "content": {}
    // }
  },
  10: {
    title: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
    },
    dates: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    content: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'executive-summary': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'source-and-assessment': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'sector-relevance': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    tags: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    ioc: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'mitre-tags': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-profiles': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-alerts': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'content-distribution': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'author-settings': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    tlp: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    // "update-summary": {
    //     "is_complete": false,
    //     "is_rejected": false,
    //     "is_accepted": false,
    //     "has_been_edited": false,
    //     "content": {}
    // }
  },
  11: {
    title: {
      is_complete: true,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: true,
      content: {},
    },
    dates: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'irs-answered': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'executive-summary': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    content: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'sector-relevance': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    tags: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'mitre-tags': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    ioc: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-profiles': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'associated-alerts': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'content-distribution': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
      comments: [],
    },
    'author-settings': {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    tlp: {
      is_complete: false,
      is_rejected: false,
      is_accepted: false,
      has_been_edited: false,
      content: {},
    },
    // "update-summary": {
    //     "is_complete": false,
    //     "is_rejected": false,
    //     "is_accepted": false,
    //     "has_been_edited": false,
    //     "content": {}
    // }
  },
}

export const sectorOptions = [
  { id: 6, name: 'Banking & capital markets' },
  { id: 36, name: 'Pension' },
  { id: 18, name: 'Financial services' },
  { id: 24, name: 'Insurance' },
  { id: 20, name: 'Government & public services' },
  { id: 13, name: 'Diplomatic services' },
  { id: 15, name: 'Energy, utilities & mining' },
  { id: 34, name: 'Telecommunications' },
  { id: 33, name: 'Technology' },
  { id: 1, name: 'Academic/Research Institutes' },
  { id: 2, name: 'Aerospace, defence & security' },
  { id: 3, name: 'Agriculture' },
  { id: 4, name: 'Asset & wealth management' },
  { id: 5, name: 'Automotive' },
  { id: 7, name: 'Business and Professional Services' },
  { id: 8, name: 'Capital projects & infrastructure' },
  { id: 9, name: 'Charity/Not-for-profit' },
  { id: 10, name: 'Chemicals' },
  { id: 11, name: 'Commercial Aviation' },
  { id: 12, name: 'Commodities' },
  { id: 14, name: 'Education' },
  { id: 16, name: 'Engineering & construction' },
  { id: 17, name: 'Entertainment & media' },
  { id: 19, name: 'Forest, paper & packaging' },
  { id: 21, name: 'Healthcare' },
  { id: 22, name: 'Hospitality & leisure' },
  { id: 23, name: 'Industrial manufacturing' },
  { id: 25, name: 'IT Industry' },
  { id: 26, name: 'Legal' },
  { id: 27, name: 'Metals' },
  { id: 28, name: 'Pharmaceuticals & life sciences' },
  { id: 29, name: 'Private equity' },
  { id: 30, name: 'Retail & consumer' },
  { id: 31, name: 'Semiconductors' },
  { id: 32, name: 'Sovereign investment funds' },
  { id: 35, name: 'Transportation & logistics' },
]

export const alertTemplates = [
  { name: 'Actor Campaigns', id: 9 },
  { name: 'Credential Breaches', id: 2 },
  { name: 'DDoS', id: 12 },
  { name: 'Exploit Alert', id: 17 },
  { name: 'General Notification', id: 1 },
  { name: 'Huge Impact Vulnerabilities', id: 7 },
  { name: 'Information Leakages', id: 6 },
  { name: 'Malware Analysis', id: 10 },
  { name: 'Nefarious Domains', id: 13 },
  { name: 'Nefarious Forum mention', id: 8 },
  { name: 'New Malware', id: 11 },
  { name: 'Pastebin Dumps', id: 4 },
  { name: 'Phishing Attempts', id: 3 },
  { name: 'PII Exposure', id: 16 },
  { name: 'Sensitive Information Disclosures', id: 5 },
  { name: 'Social Media Alerts', id: 20 },
  { name: 'Supply Chain Event', id: 14 },
  { name: 'Technical Exposure', id: 15 },
  { name: 'Threat Actor Updates', id: 19 },
  { name: 'Trigger Events', id: 18 },
]
