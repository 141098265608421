import { Checkbox, ToggleButton } from '@mui/material'
import Spinner from '../../../../../../shared/Spinner'
import SectorMultiselect from '../SectorMultiselect'
import { Button, MenuItem, Select } from '@material-ui/core'

const ContentRelated = ({
  handleNotifications,
  notificationsEnabled,
  data,
  handleSettingChange,
  handleSectorChange,
  handleSubmit,
}) => {
  const selectItems = [
    'None',
    '1 and above (all)',
    '2 and above',
    '3 and above',
    '4 and above',
    '5',
  ]

  const dataWithoutCountries = () => {
    const newData = { ...data }
    delete newData?.country_profile
    return newData
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
        <h4>Email notifications</h4>

        <ToggleButton
          disabled={!data}
          size='small'
          value='check'
          selected={notificationsEnabled === true}
          // onChange={handleSubmit}
          onChange={() => handleNotifications()}
        >
          {notificationsEnabled ? (
            <span className='icon-checkbox-checked' />
          ) : (
            <span className='icon-checkbox-empty' />
          )}
        </ToggleButton>
      </div>

      {!data ? <Spinner center size={30} border={1} /> : null}

      {notificationsEnabled && data ? (
        <>
          <div className='notification-preferences__container'>
            {Object.entries(dataWithoutCountries())?.map(
              (settingType, index) => (
                <div className='notification-preferences__item' key={index}>
                  <h5>{settingType[1].name}</h5>

                  {settingType[1]?.settings &&
                    Object.entries(settingType[1].settings)?.map(
                      (option, index) => (
                        <div
                          className='notification-preferences__item-inner'
                          key={index}
                        >
                          {settingType[0] !== 'sectors' && (
                            <span>{option[1].name}</span>
                          )}

                          {option[1].type === 'bool' ? (
                            <Checkbox
                              icon={<span className='icon-checkbox-empty' />}
                              checkedIcon={
                                <span className='icon-checkbox-checked' />
                              }
                              // disableRipple
                              className='checkbox'
                              onChange={() =>
                                handleSettingChange(
                                  settingType[0],
                                  option[0],
                                  !option[1].value,
                                )
                              }
                              checked={option[1].value}
                            />
                          ) : option[1].type === 'multiselect' ? (
                            <SectorMultiselect
                              options={option[1].value}
                              handleSectorChange={handleSectorChange}
                            />
                          ) : (
                            <Select
                              value={option[1].value}
                              onChange={(event) =>
                                handleSettingChange(
                                  settingType[0],
                                  option[0],
                                  event.target.value,
                                )
                              }
                            >
                              {selectItems.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  style={{ fontSize: '13px' }}
                                  value={index}
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </div>
                      ),
                    )}
                </div>
              ),
            )}
          </div>

          <Button
            onClick={() => handleSubmit()}
            className='submit-button'
            color='primary'
          >
            {!data ? (
              <Spinner color='#fff' size={20} border={1} />
            ) : (
              'Save Changes'
            )}
          </Button>
        </>
      ) : null}
    </div>
  )
}

export default ContentRelated
