import React from 'react'

const PasswordStrengthComponent = ({ strength, color }) => {
  return (
    <div className='password-strength'>
      <h5>Password strength</h5>

      <div className='password-strength__indicator'>
        <div
          style={{
            backgroundColor: color,
            width: strength * 20 + '%',
            height: '4px',
            transition: 'all 0.25s ease',
          }}
        />
      </div>

      {strength > 0 && (
        <div
          className='password-strength__text'
          style={{ backgroundColor: color }}
        >
          {strength === 1 && 'Bad'}

          {strength === 2 && 'Moderate'}

          {strength === 3 && 'Could be better'}

          {strength === 4 && 'Nice'}

          {strength === 5 && 'Excellent!'}
        </div>
      )}
    </div>
  )
}

export default PasswordStrengthComponent
