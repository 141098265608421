/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Dialog } from '@material-ui/core'
import { Checkbox, FormControlLabel } from '@mui/material'
import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { tutorial } from '../../../shared/services/api/account/account'
import videoNavigation from '../../../assets/videos/tm-navigation.mp4'
import videoWorkspaces from '../../../assets/videos/tm-workspaces.mp4'
import videoSearch from '../../../assets/videos/tm-search.mp4'
import videoDashboardOverview from '../../../assets/videos/tm-dashboard-overview.mp4'
import videoDashboardGraphs from '../../../assets/videos/tm-dashboard-graphs.mp4'
import videoContentCreate from '../../../assets/videos/tm-content-create.mp4'
import videoContentView from '../../../assets/videos/tm-content-view.mp4'
import videoContentManage from '../../../assets/videos/tm-content-manage.mp4'
import { isMobile } from 'react-device-detect'
import { useUserContext } from '../../../context/userContext'

/**
 * Tutorial
 * @param {number|null} tutorialStep - current tutorial step
 * @param {function} setTutorialStep - handling tutorial steps
 */

const Tutorial = ({ tutorialStep, setTutorialStep }) => {
  const { updateTutorialState } = useUserContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const slides = [
    {
      step: 1,
      headline: 'Navigation',
      text: 'You can access any module or content type from the icons on the left. The ThreatMatch logo will take you back to the dashboard.',
      video: videoNavigation,
    },
    {
      step: 2,
      headline: 'Dashboard',
      text: 'ThreatMatch utilises workspaces, so that you can have multiple investigations open at the same time. Simply click to add a new workspace and search across the modules, or create some new content.',
      video: videoWorkspaces,
    },
    {
      step: 3,
      headline: 'Searching',
      text: 'You can search across the platform, by clicking on the search icon.',
      video: videoSearch,
    },
    {
      step: 4,
      headline: 'Dashboard Overview',
      text: 'The dashboard is the start of your user journey. Click on the latest content, to open that content in a new workspace.',
      video: videoDashboardOverview,
    },
    {
      step: 5,
      headline: 'Dashboard Graphs',
      text: 'You can click on graphics within the dashboard, to load a new search with those parameters already established.',
      video: videoDashboardGraphs,
    },
    {
      step: 6,
      headline: 'Manage Content',
      text: 'Your can view your content management area by clicking the edit button.',
      video: videoContentManage,
    },
    {
      step: 7,
      headline: 'Create Content',
      text: 'You can also create new content by opening a new workspace, and selecting create content.',
      video: videoContentCreate,
    },
    {
      step: 8,
      headline: 'Viewing Content',
      text: 'ThreatMatch content comes with reporting, the ability to add comments for your organisation or community, viewing associated content and IOCs.',
      video: videoContentView,
    },
  ]

  const dotPos = () => {
    if (tutorialStep === 1) {
      return 49
    } else if (tutorialStep === 2) {
      return 35
    } else if (tutorialStep === 3) {
      return 21
    } else if (tutorialStep === 4) {
      return 7
    } else if (tutorialStep === 5) {
      return '-7'
    } else if (tutorialStep === 6) {
      return '-21'
    } else if (tutorialStep === 7) {
      return '-35'
    } else if (tutorialStep === 8) {
      return '-49'
    }
  }

  const handleTutorialPerm = async () => {
    try {
      const data = { is_tutorial_active: false }
      await tutorial(token(), data)
      updateTutorialState(false)
    } catch (err) {}
  }

  const handleDisableTutorial = async () => {
    dontShowAgain
      ? await handleTutorialPerm()
      : localStorage.setItem('tutorialActive', 'false')
    setTutorialStep(null)
  }

  return (
    <Dialog
      style={{ zIndex: 9999 }}
      fullWidth
      maxWidth='md'
      open={tutorialStep !== null}
      PaperProps={{ style: { overflowY: 'unset', maxHeight: '80vh' } }}
    >
      <div style={{ padding: '35px 40px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4 style={{ color: '#006FFF' }}>TUTORIAL</h4>

          <FormControlLabel
            style={{ margin: 0 }}
            onChange={() => setDontShowAgain(!dontShowAgain)}
            control={
              <Checkbox
                checked={dontShowAgain}
                style={{ padding: 3, color: '#006FFF', fontSize: 26 }}
                icon={<span className='icon-checkbox-empty' />}
                checkedIcon={<span className='icon-checkbox-checked' />}
              />
            }
            label={
              <span style={{ fontSize: isMobile ? 11 : 14 }}>
                Do not show again
              </span>
            }
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          {slides.map(
            (slide) =>
              slide.step === tutorialStep && (
                <Fragment key={slide.step}>
                  <h3 style={{ marginBottom: 10 }}>{slide.headline}</h3>

                  <span>{slide.text}</span>

                  <div
                    style={{
                      height: isMobile
                        ? 'calc(30vh - 53px)'
                        : 'calc(50vh - 53px)',
                      margin: '20px 0',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <video
                      style={{ borderRadius: 20, width: '100%' }}
                      autoPlay
                      src={slide.video}
                      loop
                      muted
                    />
                  </div>
                </Fragment>
              ),
          )}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => setTutorialStep(tutorialStep - 1)}
            disabled={tutorialStep <= 1}
            style={{
              backgroundColor: '#F2F2F2',
              padding: '8px 24px',
              color: tutorialStep <= 1 ? '#bababa' : '#000',
              borderRadius: 12,
              minWidth: 88,
              textTransform: 'capitalize',
            }}
          >
            Back
          </Button>

          {!isMobile && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 14,
                width: 70,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  transform: `translateX(${dotPos()}px)`,
                  transition: 'all .2s ease',
                }}
              >
                {slides.map((dot) => (
                  <div
                    key={dot.step}
                    style={{
                      minWidth: 12,
                      minHeight: 12,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '0 1px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setTutorialStep(dot.step)}
                  >
                    <span
                      style={{
                        width: tutorialStep === dot.step ? 12 : 9,
                        height: tutorialStep === dot.step ? 12 : 9,
                        borderRadius: 20,
                        backgroundColor:
                          tutorialStep === dot.step ? '#006FFF' : '#c7c7c7',
                        transition: 'all .3s ease',
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <Button
            onClick={() =>
              tutorialStep === 8
                ? handleDisableTutorial()
                : setTutorialStep(tutorialStep + 1)
            }
            style={{
              backgroundColor: '#006FFF',
              padding: '8px 24px',
              color: '#fff',
              fontWeight: 600,
              borderRadius: 12,
              minWidth: 88,
              textTransform: 'capitalize',
            }}
          >
            {tutorialStep >= 8 ? 'Finish' : 'Next'}
          </Button>
        </div>
      </div>

      <div
        style={{
          position: 'absolute',
          bottom: '-50px',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Button
          onClick={() => handleDisableTutorial()}
          style={{
            color: '#fff',
            fontWeight: 600,
            padding: '6px 22px',
            borderRadius: 12,
            fontSize: 16,
            textDecoration: 'underline',
            textTransform: 'capitalize',
          }}
        >
          Skip
        </Button>
      </div>
    </Dialog>
  )
}

Tutorial.displayName = 'Tutorial'

/**
 * The properties.
 * @type {Object}
 */

Tutorial.propTypes = {
  tutorialStep: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])])
    .isRequired,
  setTutorialStep: PropTypes.func.isRequired,
}

export default Tutorial
