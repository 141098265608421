/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { Menu } from '@material-ui/icons'
import { Button, Select, MenuItem } from '@mui/material'
import { TextField } from '@material-ui/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DeleteModal } from '../../components'

const CATEGORY_OPTIONS = [
  { title: 'TM User Interface', icon: '' },
  { title: 'Searching Content', icon: 'search' },
  { title: 'Creating Content', icon: 'edit' },
  { title: 'Threat Alerts', icon: 'alert' },
  { title: 'Incidents', icon: 'incident' },
  { title: 'Operations', icon: 'operation' },
  { title: 'Threat Actors', icon: 'threat-actor' },
  { title: 'Malware & Tools', icon: 'malware-tools' },
  { title: 'Scenarios', icon: 'scenario' },
  { title: 'Link Analysis', icon: 'analysis' },
  { title: 'MITRE ATT&CK', icon: '' },
  { title: 'API', icon: 'developers-platform' },
  { title: 'Support', icon: 'question' },
]

const ArticleButtonSection = ({ props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { content, handleSectionState, index, id, handleDeleteSection } = props
  const sortable = useSortable({ id: id })

  const { attributes, listeners, isDragging, setNodeRef, transform } = sortable

  const disableAxis = (scale) => {
    if (scale) {
      scale.scaleX = 1
      scale.scaleY = 1
      scale.x = 0
    }
    return scale
  }

  const style = {
    transform: CSS.Transform.toString(disableAxis(transform)),
    // transition,
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? '100' : 'auto',
  }

  const fields = [
    { name: 'Strip Colour', slug: 'strip_colour' },
    { name: 'Button Colour', slug: 'colour' },
    { name: 'Button Text', slug: 'text' },
    // { name: 'Button Icon', slug: 'icon' },
  ]

  return (
    <div
      className='knowledge-base__article-section'
      ref={setNodeRef}
      style={style}
    >
      {isModalOpen && (
        <DeleteModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          onDelete={handleDeleteSection}
        />
      )}

      <div className='knowledge-base__article-section-header'>
        <div
          onClick={() =>
            handleSectionState(index, 'isExpanded', !content.isExpanded)
          }
          className='knowledge-base__article-section-header-left'
        >
          <span
            style={{
              fontSize: 22,
              marginRight: 10,
              transform: content.isExpanded ? 'rotate(180deg)' : null,
            }}
            className='icon-down'
          />

          <span>{content.headline || 'Button Section'}</span>
        </div>

        <div className='knowledge-base__article-section-header-right'>
          <Button
            onClick={() => {
              setIsModalOpen(['modal', index])
            }}
            style={{
              textDecoration: 'underline',
              background: 'transparent',
              textTransform: 'capitalize',
              color: '#fff',
              padding: '3px 10px',
              marginRight: 20,
            }}
          >
            Delete
          </Button>

          <Menu
            style={{ cursor: 'move', outline: 'none' }}
            {...listeners}
            {...attributes}
          />
        </div>
      </div>

      {content.isExpanded && (
        <div className='knowledge-base__article-section-content'>
          <div>
            <h5>Headline</h5>

            <TextField
              value={content.headline || ''}
              onChange={(event) =>
                handleSectionState(index, 'headline', event.target.value)
              }
              fullWidth
              placeholder='Enter headline'
              variant='outlined'
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 15,
              width: '100%',
              margin: '15px 0',
            }}
          >
            {fields.map(({ name, slug }) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 0 calc(50% - 7.5px)',
                }}
                key={slug}
              >
                <h5 style={{ marginBottom: 5 }}>{name}</h5>

                <TextField
                  value={content[slug] || ''}
                  onChange={(event) =>
                    handleSectionState(index, slug, event.target.value)
                  }
                  fullWidth
                  placeholder={`Enter ${name}`}
                  variant='outlined'
                />
              </div>
            ))}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '0 0 calc(50% - 7.5px)',
              }}
            >
              <h5 style={{ marginBottom: 5 }}>Button URL</h5>

              <TextField
                value={content.url || ''}
                onChange={(event) =>
                  handleSectionState(index, 'url', event.target.value)
                }
                fullWidth
                placeholder='Enter URL'
                variant='outlined'
              />
            </div>
          </div>

          <h5>Button Icon</h5>

          <Select
            onChange={(event) =>
              handleSectionState(index, 'icon', event.target.value)
            }
            displayEmpty
            value={content.icon || ''}
            renderValue={
              !content.icon
                ? () => <div style={{ color: '#aaa' }}>Select Icon</div>
                : undefined
            }
            variant='outlined'
          >
            {CATEGORY_OPTIONS.map(({ title, icon }, key) => (
              <MenuItem
                value={icon}
                key={key}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <span
                  className={`icon-${icon}`}
                  style={{ fontSize: '20px', marginRight: 10 }}
                />

                <span>{title}</span>
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  )
}

export default ArticleButtonSection
