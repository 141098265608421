import axios from 'axios'
import { apiUrl } from '../../config'
import { parseError } from '../../helpers/axios'

let signal = axios.CancelToken.source()

/**
 * returns if error is being caused by axios cancel
 * @function
 * @returns {Boolean} - true if the error caused by canceling the request
 */
const areRequestsCanceled = (error) => error && axios.isCancel(error)

/**
 * cancels every request
 * @function
 */
const cancelRequests = () => {
  signal.cancel({
    isCanceled: true,
    message: 'Requests canceled',
  })
  signal = axios.CancelToken.source()
}

// request interceptors parse
axios.interceptors.request.use((request) => {
  request.cancelToken = signal.token
  return request
}, parseError)

let refreshTokenPromise
const getRefreshToken = async () => {
  const refreshTokenExpirationTime = localStorage.getItem(
    'refresh_token_expire_in',
  )
  const currentTime = Math.round(new Date() / 1000)
  if (currentTime > refreshTokenExpirationTime) {
    window.location.replace('/app/auth/login')
    localStorage.clear()
    return false
  }

  try {
    const newToken = await axios.post(apiUrl + '/api/auth/refresh-token', {
      refresh_token: localStorage.getItem('refresh_token'),
    })

    localStorage.setItem('access_token', newToken.access_token)
    localStorage.setItem('refresh_token', newToken.refresh_token)
    localStorage.setItem(
      'expires_in',
      Math.round(new Date() / 1000) + newToken.expires_in,
    )
    localStorage.setItem(
      'refresh_token_expire_in',
      Math.round(new Date() / 1000) + newToken.refresh_token_expire_in,
    )

    return newToken
  } catch (err) {
    // console.log('error', err)
  }
}

//handling token refresh
axios.interceptors.response.use(
  (response) => {
    if (response.data) {
      return response.data
    } else return response
  },
  async (error) => {
    if (error?.response?.status === 401) {
      if (!refreshTokenPromise) {
        // check for an existing in-progress request
        // if nothing is in-progress, start a new refresh token request
        refreshTokenPromise = getRefreshToken().then((token) => {
          refreshTokenPromise = null // clear state
          return token // resolve with the new token
        })
      }

      return refreshTokenPromise.then((token) => {
        if (token.error) {
          localStorage.setItem('auth', false)
          window.location.replace('/app/auth/login')
          return false
        }

        !token && localStorage.setItem('targetUrl', window.location.search)
        error.response.config.headers['Authorization'] =
          'Bearer ' + token.access_token
        return axios(error.response.config)
      })
    } else if (
      (error?.response && error.response?.status === 400) ||
      error.response?.status === 403 ||
      error.response?.status === 404 ||
      error.response?.status === 422 ||
      error.response?.status === 500
    ) {
      error.response.data.error = true

      return error.response.data
    }
    return error
  },
)

const get = (url, conf) => axios.get(url, conf).then((response) => response)
const remove = (url, conf, data) =>
  axios.delete(url, { ...conf, data }).then((response) => response)
const post = (url, data, conf) =>
  axios.post(url, data, conf).then((response) => response)
const put = (url, data, conf) =>
  axios.put(url, data, conf).then((response) => response)
const patch = (url, data, conf) =>
  axios.patch(url, data, conf).then((response) => response)

export { areRequestsCanceled, cancelRequests }

export default {
  get,
  remove,
  post,
  put,
  patch,
}
