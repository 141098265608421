export const typeNames = {
  alert: { name: 'Threat Alerts' },
  scenario: { name: 'Scenarios' },
  report: { name: 'Reports' },
  'threat-actor': { name: 'Threat Actors' },
  incident: { name: 'Incidents' },
  operation: { name: 'Operations' },
  'malware-tools': { name: 'Malware & Tools' },
  profile: { name: 'profile' },
  country: { name: 'Countries' },
}
