import {
  useState,
  useRef,
  useEffect,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react'

type UseHoverType<T extends HTMLElement> = [
  RefObject<T>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
]

export const useHover = <T extends HTMLElement>(): UseHoverType<T> => {
  const ref = useRef<T>(null)
  const [hovered, setHovered] = useState(false)

  const enter = () => setHovered(true)
  const leave = () => setHovered(false)

  useEffect(() => {
    ref.current && ref.current.addEventListener('mouseenter', enter)
    ref.current && ref.current.addEventListener('mouseleave', leave)

    return () => {
      ref.current && ref.current.removeEventListener('mouseenter', enter)
      ref.current && ref.current.removeEventListener('mouseleave', leave)
    }
  }, [ref])

  return [ref, hovered, setHovered]
}
