/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */

import { apiUrl } from '../../../config'
import client from '../../http/client'

export const getAllUsersForGroup = (token: string | null, id: number | null) =>
  client.get(apiUrl + `/api/chat/group/${id}/users`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getChatHistory = (
  token: string | null,
  chat_id: number | undefined,
  chat_type: 'group' | 'direct',
  // same_sender: boolean,
  lastMssageId?: string | null,
  limit: number | null = 20,
) =>
  client.get(
    apiUrl +
      `/api/chat/history?chat_id=${chat_id}&chat_type=${chat_type}&limit=${limit}&message_id=${lastMssageId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const sendMessage = (
  token: string | null,
  data: {
    is_same_sender?: boolean
    content: string
    chat_type: string
    chat_id: number | null
    uk_links?: any
    eu_links?: any
    group_id?: number | null
  },
) =>
  client.post(apiUrl + `/api/chat/message`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const addReaction = (
  token: string | null,
  data: {
    id: number | undefined
    chat_type: 'group' | 'direct'
    chat_id: number | null
    content: string | null | undefined
  },
) =>
  client.post(apiUrl + `/api/chat/message/reaction`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const markAsRead = (
  token: string | null,
  data: {
    ids?: string[] | undefined
    mark_read_all?: boolean
    chat_id: number | null
    chat_type: string | 'group' | 'direct'
  },
) =>
  client.post(apiUrl + `/api/chat/message/mark-read`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteMessage = (
  token: string | null,
  data: {
    id: number | null
    chat_id: number | null
    chat_type: string | 'group' | 'direct'
  },
) =>
  client.remove(
    apiUrl + `/api/chat/message/${data.id}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
    data,
  )

export const reportMessage = (
  token: string | null,
  data: {
    reason: string | null
    content_id: number | undefined
    chat_id: number | null
    chat_type: 'group' | 'direct'
    content_type: string
  },
) =>
  client.post(apiUrl + `/api/report-content/chat`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const changeNotificationSettings = (
  token: string | null,
  data: {
    chat_id: number | null
    chat_type: string | 'group' | 'direct'
    chat_email_notify_interval?: boolean | string | '2 HOURS' | '15 MIN' | ''
    chat_email_notify_mute: boolean | string
  },
) =>
  client.put(apiUrl + `/api/chat/settings`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const editMessage = (
  token: string | null,
  data: {
    is_same_sender?: boolean
    content: string
    chat_type: string
    chat_id: number | null
    uk_links?: any
    eu_links?: any
    id: any
  },
) =>
  client.put(apiUrl + `/api/chat/message/${data.id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
