import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { DeleteForever, VisibilityOff } from '@material-ui/icons'
import {
  mitreContentPieces,
  mitreContentSinglePiece,
} from '../../../../../../shared/services/api/mitre/mitre'
import { cancelRequests } from '../../../../../../shared/services/http/client'
import { Autocomplete } from '@material-ui/lab'

const Layers = ({
  data,
  handleMitreNavigatorState,
  handleMitreLayer,
  availableColours,
  isAdding,
  handleMapContentPiece,
}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const columns = [
    'Active',
    'Layer name',
    'Layer mode',
    'Colour',
    'Content Type',
    'Select content',
    '',
  ]
  const [colourMenu, setColourMenu] = useState(null)
  const [options, setOptions] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(data?.length <= 5)
  const [layerModalOpen, setLayerModalOpen] = useState(false)
  const handleChangeState = (prop, value, index) => {
    const currentState = [...data]

    if (prop === 'mode') {
      currentState.forEach((item, ind) => {
        if (!item.is_base) {
          item.mode = value
        }

        if (value === 2) {
          //change mode to merge
          item.colour = currentState[0].colour
        } else if (value === 3 && !item.is_base) {
          //change mode to compare
          item.colour = availableColours[ind - 1].colour
        }
      })
    } else if (prop === 'name') {
      currentState[index].label = value
    } else if (prop === 'active') {
      currentState.forEach((item) => delete item.$isActive)
      currentState[index].$isActive = true
    } else if (prop === 'colour') {
      if (index === 0 && currentState[1] && currentState[1].mode === 2) {
        currentState.forEach((item) => (item.colour = value))
      } else {
        currentState[index].colour = value
      }
      setColourMenu(null)
    } else if (prop === 'visible') {
      currentState[index].$isVisible = value
    } else if (prop === 'module') {
      currentState[index].piece_of_content_module = value
      currentState[index].piece_of_content_id = ''
    } else if (prop === 'piece') {
      currentState[index].piece_of_content_id = value
      const type = currentState[index].piece_of_content_module
      getPieceData(type, value.id, currentState[index].id)
    } else if (prop === 'search') {
      currentState[index].search = value
    }

    handleMitreNavigatorState('layers', prop, currentState)
  }

  const loadPieces = async (type, index) => {
    setLoading(true)
    cancelRequests !== undefined && cancelRequests()
    try {
      const response = await mitreContentPieces(token(), type)
      const list = response.list.data ? response.list.data : response.list
      const currentState = [...data]
      if (
        currentState[index].piece_of_content_id != '' &&
        !currentState[index].piece_of_content_id.id
      ) {
        const initialPieceId = currentState[index].piece_of_content_id
        const pieceData = list?.find((piece) => piece.id === initialPieceId)
        currentState[index].piece_of_content_id = pieceData
      }
      setOptions({ ...options, [index]: list })
      setLoading(false)
    } catch (err) {}
  }

  const getPieceData = async (type, id, layerId) => {
    try {
      const response = await mitreContentSinglePiece(token(), type, id)
      handleMapContentPiece(response, layerId)
    } catch (err) {}
  }

  useEffect(() => {
    data?.forEach((item, index) => {
      if (item?.piece_of_content_module?.length > 0) {
        loadPieces(item.piece_of_content_module, index)
      }
    })

    if (data[1]?.mode === 1) {
      handleChangeState('mode', 2)
    }
  }, [])

  return (
    <div className='mitre-navigator__layers'>
      {layerModalOpen && (
        <Dialog
          fullWidth
          maxWidth='xs'
          open={layerModalOpen}
          onClose={() => setLayerModalOpen(false)}
        >
          <div style={{ padding: 24 }}>
            <h4>Reached maximum layer number for that mode.</h4>

            <Divider
              style={{
                margin: '10px 0',
                border: 'none',
                backgroundColor: '#00000080',
              }}
            />

            <span>
              Maximum number of layers when comparing layers is 10. Maximum
              number of layers when merging layers is 25.
            </span>
          </div>

          <div
            style={{
              padding: '0 24px 16px 24px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              style={{
                textDecoration: 'underline',
                textTransform: 'capitalize',
              }}
              onClick={() => setLayerModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </Dialog>
      )}

      {expanded && (
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell align='left' key={col}>
                  <h4>{col}</h4>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {colourMenu && (
            <Menu
              anchorEl={colourMenu[0]}
              open={colourMenu !== null}
              onClose={() => setColourMenu(null)}
              style={{ padding: 10 }}
            >
              {availableColours.map((item) => (
                <MenuItem
                  key={item.colour}
                  onClick={() =>
                    handleChangeState('colour', item.colour, colourMenu[1])
                  }
                  style={{
                    minWidth: 'unset',
                    height: 26,
                    width: 26,
                    border: '1px solid rgb(0, 0, 0)',
                    borderRadius: 0,
                    margin: 6,
                    padding: 6,
                  }}
                  className={`mitre-colour mitre-colour--${item.colour}`}
                />
              ))}
            </Menu>
          )}

          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell
                  width={50}
                  align='center'
                  className='cell-marker'
                  // style={{ opacity: row.$isActive === true ? 1 : 0 }}
                >
                  <Button
                    style={{
                      backgroundColor: row.$isActive && '#006fff',
                      color: row.$isActive && '#fff',
                      border: row.$isActive && '#efefef',
                    }}
                    className='navigator-marker'
                    onClick={() =>
                      handleChangeState('active', undefined, index)
                    }
                  >
                    {index + 1}
                  </Button>
                </TableCell>

                <TableCell className='cell-name' width={200} align='left'>
                  <TextField
                    defaultValue={row.label}
                    fullWidth
                    placeholder='Name'
                    variant='outlined'
                    onBlur={(event) =>
                      handleChangeState('name', event.target.value, index)
                    }
                    inputProps={{ style: { padding: '12px 14px' } }}
                  />
                </TableCell>

                <TableCell width={110} align='left'>
                  {index === 0 && <span>Base layer</span>}

                  {index === 1 && (
                    <Select
                      className='layer-select'
                      onChange={(event) => {
                        if (event.target.value === 3 && data.length > 10) {
                          setLayerModalOpen(true)
                          return false
                        }

                        handleChangeState('mode', event.target.value)
                      }}
                      displayEmpty
                      value={row.mode || ''}
                      renderValue={
                        !row.mode
                          ? () => (
                              <div style={{ color: '#aaa' }}>Select mode</div>
                            )
                          : undefined
                      }
                      variant='outlined'
                    >
                      <MenuItem style={{ fontSize: 14 }} value={2}>
                        Merge
                      </MenuItem>

                      <MenuItem style={{ fontSize: 14 }} value={3}>
                        Compare
                      </MenuItem>
                    </Select>
                  )}

                  {index !== 0 && index !== 1 && row.mode === 2 && (
                    <div>Merge</div>
                  )}

                  {index !== 0 && index !== 1 && row.mode === 3 && (
                    <div>Compare</div>
                  )}
                </TableCell>

                <TableCell
                  width={50}
                  align='center'
                  style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  <Button
                    style={{
                      minWidth: 'unset',
                      height: 30,
                      width: 30,
                      border: '1px solid #000',
                      borderRadius: 0,
                    }}
                    disabled={data[1] && data[1].mode === 2 && index !== 0}
                    onClick={(event) =>
                      availableColours.length > 0 &&
                      setColourMenu([event.currentTarget, index])
                    }
                    className={`mitre-colour--${row.colour || 1}`}
                  />
                </TableCell>

                <TableCell align='left' className='cell-module' width={140}>
                  <Select
                    className='module-select'
                    onChange={(event) => {
                      handleChangeState('module', event.target.value, index)
                      loadPieces(event.target.value, index)
                    }}
                    displayEmpty
                    value={row.piece_of_content_module || ''}
                    renderValue={
                      !row.piece_of_content_module
                        ? () => (
                            <div style={{ color: '#aaa' }}>
                              Choose (optional)
                            </div>
                          )
                        : undefined
                    }
                    variant='outlined'
                  >
                    <MenuItem value='scenario'>Scenario</MenuItem>

                    <MenuItem value='profile'>Profile</MenuItem>
                  </Select>
                </TableCell>

                <TableCell
                  className='search-content-select'
                  width={500}
                  align='left'
                >
                  <Autocomplete
                    disabled={
                      !row.piece_of_content_module ||
                      row.piece_of_content_module === ''
                    }
                    disableClearable
                    getOptionSelected={(option, value) =>
                      !isLoading && option.id === value.id
                    }
                    renderOption={(option) => (
                      <span style={{ fontSize: 13 }}>{option.title}</span>
                    )}
                    options={(!isLoading && options[index]) || []}
                    getOptionLabel={(option) => option.title || ''}
                    loading={isLoading}
                    value={(!isLoading && row.piece_of_content_id) || ''}
                    onChange={(event, value) =>
                      handleChangeState('piece', value, index)
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder='Search (optional)'
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      )
                    }}
                  />
                </TableCell>

                <TableCell className='cell-actions' align='right'>
                  <Button
                    onClick={() =>
                      handleChangeState('visible', !row.$isVisible, index)
                    }
                    style={{
                      padding: 4,
                      borderRadius: 0,
                      fontSize: 28,
                      boxShadow: '0 2px 5px 0 rgb(0 0 0 / 26%)',
                      minWidth: 'unset',
                    }}
                  >
                    {row.$isVisible ? (
                      <span className='icon-eye' />
                    ) : (
                      <VisibilityOff
                        style={{ width: 18, height: 18, padding: 6 }}
                      />
                    )}
                  </Button>

                  {index !== 0 && (
                    <Button
                      onClick={() => handleMitreLayer(row.id, row.$isActive)}
                      style={{
                        padding: 4,
                        borderRadius: 0,
                        width: 36,
                        height: 36,
                        boxShadow: '0 2px 5px 0 rgb(0 0 0 / 26%)',
                        minWidth: 'unset',
                        marginLeft: 6,
                      }}
                    >
                      <DeleteForever />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {((data[1]?.mode === 3 && data.length < 10) ||
          (data[1]?.mode !== 3 && data?.length <= 24)) && (
          <Button
            onClick={() => handleMitreLayer()}
            className='layer-add'
            style={{ marginTop: expanded ? 20 : 0 }}
            endIcon={
              isAdding ? (
                <CircularProgress
                  style={{ color: '#fff', width: 18, height: 18 }}
                />
              ) : (
                <span className='icon-circle-add' />
              )
            }
          >
            Add layer
          </Button>
        )}

        <Button
          onClick={() => setExpanded(!expanded)}
          className='layer-add'
          style={{ marginTop: expanded ? 20 : 0 }}
          startIcon={
            <span
              className='icon-down'
              style={{ transform: expanded ? 'rotate(180deg' : null }}
            />
          }
        >
          {expanded ? 'Collapse layers' : 'Expand layers'}
        </Button>
      </div>
    </div>
  )
}

export default Layers
