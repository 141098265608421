/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Divider, Menu, MenuItem } from '@material-ui/core'

const settingsOptions = [
  { name: '15 minutes', slug: '15 MINUTES' },
  { name: '120 minutes', slug: '2 HOURS' },
  { name: 'Mute notifications', slug: 'chat_email_notify_mute' },
]

interface HoverItem {
  userId?: number
  groupId?: number
}

interface Props {
  settingsAnchor: any
  setOpenSettingId: React.Dispatch<React.SetStateAction<HoverItem | null>>
  setHoverChatId?: React.Dispatch<React.SetStateAction<HoverItem | null>>
  chat_email_notify_mute: boolean
  chat_email_notify_interval: string
  handleChangeNotificationsSettings: any
  chatId: number
  chatType: string
}

const NotificationSettingsMenu = ({
  settingsAnchor,
  setOpenSettingId,
  setHoverChatId,
  chat_email_notify_mute,
  chat_email_notify_interval,
  handleChangeNotificationsSettings,
  chatId,
  chatType,
}: Props) => {
  return (
    <Menu
      anchorEl={settingsAnchor}
      open
      onClose={() => {
        setOpenSettingId(null)
        setHoverChatId && setHoverChatId(null)
      }}
      className='setting-modal'
      style={{
        zIndex: 9999,
      }}
    >
      <h1
        style={{ fontSize: 16, padding: '5px 16px' }}
        onClick={() => {
          setOpenSettingId(null)
          setHoverChatId && setHoverChatId(null)
        }}
      >
        Set notification interval period
      </h1>
      <Divider />
      {(chat_email_notify_mute
        ? settingsOptions.filter((i) => i.slug !== 'chat_email_notify_mute')
        : settingsOptions
      ).map(({ name, slug }) => (
        <MenuItem
          style={{
            color:
              !chat_email_notify_mute && chat_email_notify_interval === slug
                ? '#fff'
                : undefined,
            backgroundColor:
              !chat_email_notify_mute && chat_email_notify_interval === slug
                ? '#006fff'
                : undefined,
          }}
          key={slug}
          onClick={() => {
            handleChangeNotificationsSettings(
              chatType,
              chatId,
              slug === 'chat_email_notify_mute'
                ? !chat_email_notify_mute
                : slug,
            )
          }}
        >
          {name}
        </MenuItem>
      ))}
    </Menu>
  )
}

export default NotificationSettingsMenu
