import { Button } from '@material-ui/core'
import React from 'react'
import { me } from '../../../../shared/services/api/me/me'
import UploadIcon from '@mui/icons-material/Upload'
import Spinner from '../../../../shared/Spinner'

const UploadPDF = ({ handleUploadPdf, isPreviewingPdf }) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const iocStyle = {
    textTransform: 'capitalize',
    backgroundColor: '#006fff',
    color: '#fff',
    borderRadius: 12,
    padding: '6px 16px',
    minWidth: 140,
  }

  return (
    <>
      <input
        accept='application/pdf'
        hidden
        id='btn-file-pdf'
        type='file'
        onClick={(event) => (event.target.value = null)}
        multiple={false}
        onChange={async (event) => {
          try {
            await me(token())
            handleUploadPdf(event.target.files[0])
          } catch (err) {
            console.log(err)
          }
        }}
      />

      <label htmlFor='btn-file-pdf'>
        <Button
          style={iocStyle}
          startIcon={<UploadIcon />}
          variant='outlined'
          component='span'
        >
          {isPreviewingPdf ? (
            <Spinner centerHorizontally color='#fff' size={22.5} />
          ) : (
            'Upload PDF'
          )}
        </Button>
      </label>
    </>
  )
}

export default UploadPDF
