import { Fragment } from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import ForumCategories from './forumTabs/ForumCategories'
import './ForumConfig.scss'
import NewForumCategory from './forumTabs/NewForumCategory'
import ForumsSetup from './forumTabs/ForumsSetup'

const forumConfigModules = [
  { name: 'Categories', slug: 'categories', icon: 'icon-module' },
  { name: 'Create New Category', slug: 'category-new', icon: 'icon-module' },
  { name: 'Forums', slug: 'forums', icon: 'icon-module' },
]
const ForumConfig = () => {
  return (
    <div className='logs'>
      <div className='account-tabs__wrapper'>
        <h2>Forum Setup</h2>

        <div className='tab-group'>
          {forumConfigModules.map(({ slug, icon, name }) => (
            <Fragment key={slug}>
              <NavLink
                isActive={() =>
                  location.pathname.startsWith(`/app/admin/forum/${slug}`)
                }
                to={`/app/admin/forum/${slug}`}
              >
                <span className={icon} />

                {name}
              </NavLink>
            </Fragment>
          ))}
        </div>
      </div>

      <div className='account-tabs__container'>
        <Switch>
          <Route
            path='/app/admin/forum/categories'
            component={ForumCategories}
          />

          <Route
            path='/app/admin/forum/category-new'
            component={NewForumCategory}
          />

          <Route path='/app/admin/forum/forums' component={ForumsSetup} />
        </Switch>
      </div>
    </div>
  )
}

export default ForumConfig
