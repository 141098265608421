/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useEffect } from 'react'
import './Operations.scss'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getOperations } from '../../../../shared/services/api/logs/logs'
import Pagination from '@material-ui/lab/Pagination'
import {
  Button,
  Divider,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Spinner from '../../../../shared/Spinner'
import { formatGlobalSearch } from '../../../../shared/helpers/formatDate'
import { apiUrl } from '../../../../shared/config'
import { useAdmin } from '../../../../context/adminContext'
import useNotification from '../../../../hooks/useNotification'
import { Info } from '@material-ui/icons'
import { getLogsExport } from '../../../../shared/services/api/admin/contentExport/contentExport'

const columnData = [
  { id: 'Username' },
  { id: 'Action' },
  { id: 'Content' },
  { id: 'Email' },
  { id: 'Action Date' },
  { id: 'Action Time' },
]

const Operations = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setIsLoading] = useState(false)
  const {
    adminState: { operations },
    setAdminState,
  } = useAdmin()
  const [page, setPage] = useState(
    +Object.fromEntries(new URLSearchParams(operations?.previousQuery))?.page ||
      1,
  )
  const [keyword, setKeyword] = useState(
    Object.fromEntries(new URLSearchParams(operations?.previousQuery))
      ?.keyword || '',
  )
  const [columns, setColumns] = useState(columnData)
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date())
  const [selectedDateTo, setSelectedDateTo] = useState(new Date())
  const { successToast, errorToast, warningToast } = useNotification()

  const exportLogs = async () => {
    const data = {
      dateFrom: formatGlobalSearch(selectedDateFrom),
      dateTo: formatGlobalSearch(selectedDateTo),
    }
    const response = await getLogsExport(token(), 'operations', data)

    if (response?.error) {
      errorToast({ message: response?.message })
    } else {
      successToast({ message: response?.message })
    }
  }

  const fetchOperations = async (forceReload) => {
    try {
      const query = new URLSearchParams({
        keyword: keyword,
        page: page,
      }).toString()

      if (operations?.previousQuery === query && !forceReload) return false
      setIsLoading(true)
      const response = await getOperations(token(), query)

      if (response?.list?.data?.length === 0) {
        warningToast({ message: 'No results found.' })
      }

      response.list.previousQuery = query
      setAdminState((currentState) => ({
        ...currentState,
        operations: response.list,
      }))
      setIsLoading(false)
    } catch {}
  }

  useEffect(() => {
    const ignore = fetchOperations()
  }, [page])

  const handleSelectedDateFrom = (value) => {
    setSelectedDateFrom(value)
    value > selectedDateTo && setSelectedDateTo(value)
  }

  return (
    <div className='operations'>
      <div className='operations-header'>
        <h2>Operations</h2>
      </div>

      <Divider />

      <div className='operations-header__datepicker'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <h5>Search</h5>

            <form
              onSubmit={(event) => {
                event.preventDefault()
                setPage(1)
                const ignore = fetchOperations(true)
              }}
            >
              <OutlinedInput
                variant='outlined'
                placeholder='Search'
                value={keyword}
                onChange={(event) => setKeyword(event.target.value)}
                startAdornment={
                  <InputAdornment position='start'>
                    <span style={{ fontSize: 26 }} className='icon-search' />
                  </InputAdornment>
                }
              />
            </form>
          </div>

          <div>
            <div>
              <h5>Date from</h5>

              <DatePicker
                disableToolbar
                autoOk
                variant='inline'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                value={selectedDateFrom}
                onChange={(value) => handleSelectedDateFrom(value)}
                InputProps={{ endAdornment: <span className='icon-down' /> }}
              />
            </div>

            <div>
              <h5>Date to</h5>

              <DatePicker
                disableToolbar
                autoOk
                InputProps={{ endAdornment: <span className='icon-down' /> }}
                variant='inline'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                minDate={selectedDateFrom}
                value={selectedDateTo}
                onChange={setSelectedDateTo}
              />
            </div>

            <Button onClick={() => exportLogs()} size='large'>
              Export
            </Button>
          </div>
        </MuiPickersUtilsProvider>
      </div>

      {isLoading ? (
        <Spinner centerHorizontally padding={20} size={30} />
      ) : (
        <div className='operations-list'>
          <table>
            <thead>
              <tr>
                {columns.map((item) => (
                  <th key={item.id}>{item.id}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {operations?.data?.map((log) => {
                const {
                  id,
                  user,
                  action,
                  email,
                  details,
                  created_at_date,
                  created_at_time,
                } = log
                const { username } = user || {}

                return (
                  <tr key={id}>
                    <td>
                      {username || (
                        <i style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ maxWidth: 'max-content' }}>
                            User deleted
                          </span>

                          <Tooltip placement='top' title={email} arrow>
                            <Info style={{ fontSize: 17.5, marginLeft: 6 }} />
                          </Tooltip>
                        </i>
                      )}
                    </td>

                    <td>{action}</td>

                    <td>
                      {details?.title || (
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          User related action
                          <Tooltip placement='top' title={details?.email} arrow>
                            <Info style={{ marginLeft: 6, fontSize: 17.5 }} />
                          </Tooltip>
                        </span>
                      )}
                    </td>

                    <td>
                      {user?.email || (
                        <i
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <span style={{ maxWidth: 'max-content' }}>
                            User deleted
                          </span>

                          <Tooltip placement='top' title={email} arrow>
                            <Info style={{ fontSize: 17.5, marginLeft: 6 }} />
                          </Tooltip>
                        </i>
                      )}
                    </td>

                    <td>{created_at_date}</td>

                    <td>{created_at_time}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <Pagination
            onChange={(event, value) => setPage(value)}
            page={page}
            count={operations?.last_page}
            shape='rounded'
            hideNextButton
            hidePrevButton
            color='primary'
          />
        </div>
      )}
    </div>
  )
}

export default Operations
