/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import { useQueryCacheState } from '../../useQueryCacheState'
import { loginHistory } from '../../../api/loginHistory/loginHistory'

const token = () => {
  return localStorage.getItem('access_token')
}

export const useQueryGetLoginHistory = (page: number) => {
  return useQuery({
    queryKey: ['user-login-history', page],
    queryFn: () => loginHistory(token(), page),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}

export const useQueryCacheGetLoginHistory = () =>
  useQueryCacheState('user-login-history')
