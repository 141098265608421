import React, { useEffect, useState, useRef } from 'react'
import './Reports.scss'
import Pagination from '@material-ui/lab/Pagination'
import Button from '@material-ui/core/Button'
import {
  reportsCollection,
  filterSettings,
} from '../../shared/services/api/reports/reports'
import SinglePiece from '../SinglePiece'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { Divider, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import Filters from './Filters'
import ModuleListing from '../ModuleListing'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import OrderBy from '../Profiles/Filters/OrderBy'
import Spinner from '../../shared/Spinner'
import { useGlobalContext } from '../../context/globalContext'
import { isMobile } from 'react-device-detect'
import BaseButton from '../../shared/components/Button'
import { useViewStateContext } from '../../context/viewStateContext'
import { useUserContext } from '../../context/userContext'
import { useChat } from '../../context/chatContext'
import debounce from 'lodash/debounce'

const Reports = ({ currentModule, configuration, modifyWorkspaceName }) => {
  const {
    isContentBeingEdited,
    globalState: {
      reports: { readItems },
    },
  } = useGlobalContext()
  const {
    user: {
      is_management_mode_possible,
      modules: { create_mode },
    },
  } = useUserContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { activePiece, setActivePiece, workspaces, setWorkspaces } =
    useViewStateContext()
  const history = useHistory()
  const keywordsInput = useRef(null)
  const activeItemRef = useRef(null)
  const wrapperRef = useRef(null)
  const [isLoading, setLoading] = useState(true)
  const [reportsData, setReportsData] = useState(null)
  const [filtersOpen, setFiltersOpen] = useState(
    isMobile ? false : currentModule.filtersOpen || window.innerWidth > 1200,
  )
  const [filterOptions, setFilterOptions] = useState([])
  const [toastMessage, setToastMessage] = useState(false)
  const [lastFilteredData, setLastFilteredData] = useState({})
  const [orderBy, setOrderBy] = useState('default')
  const { chatOpen } = useChat()

  useEffect(() => {
    chatOpen && setFiltersOpen(false)
    const handleWindowResize = debounce(() => {
      !chatOpen && setFiltersOpen(window.innerWidth >= 1200)
    }, 25)

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [chatOpen])

  const loadOptions = async () => {
    try {
      const response = await filterSettings(token())
      setFilterOptions(response)
    } catch (err) {}
  }

  const getFilteredReports = async (data, page) => {
    // cancelRequests !== undefined && cancelRequests();
    try {
      const filteredData = { ...data }
      setLoading(true)

      if (filteredData.orderBy && filteredData.orderBy === 'default') {
        delete filteredData.orderBy
      }

      if (filteredData?.dateRange?.length === 1) {
        filteredData.dateFrom = moment(filteredData.dateRange[0]).format(
          'YYYY-MM-DD',
        )
        filteredData.dateTo = moment(Date.now()).format('YYYY-MM-DD')
        delete filteredData.dateRange
      } else if (filteredData?.dateRange?.length === 2) {
        filteredData.dateFrom = moment(filteredData.dateRange[0]).format(
          'YYYY-MM-DD',
        )
        filteredData.dateTo = moment(
          filteredData.dateRange[1] || Date.now(),
        ).format('YYYY-MM-DD')
        delete filteredData.dateRange
      }

      if (typeof filteredData.sectors === 'object') {
        let arr = filteredData.sectors.map((item) => {
          return item['id']
        })
        filteredData.sectors = arr.join()
      }

      if (filteredData?.types) {
        filteredData.types = filteredData.types.map((type) => type.id)
      }

      filteredData.page = page || 1
      filteredData.limit = 40

      const query = new URLSearchParams(filteredData).toString()
      const reports = await reportsCollection(
        token(),
        decodeURIComponent(query),
      )

      setWorkspaces((currentState) => {
        const currentWorkspace = currentState?.find(
          (workspace) => workspace.id === currentModule.id,
        )

        if (!currentWorkspace) return currentState
        currentWorkspace.list = reports?.list
        return currentState
      })
      handleReportsList(reports.list)
      setLastFilteredData(filteredData)
    } catch {}
  }

  const handleReportsList = async (list) => {
    setReportsData(list)
    setLoading(false)
    loadOptions()

    setTimeout(() => {
      if (activeItemRef.current) {
        activeItemRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        })
      } else if (wrapperRef.current) {
        wrapperRef.current.scrollIntoView({ behavior: 'auto', block: 'center' })
      }
    })

    if (currentModule.isNew) {
      const firstItemId = list?.data[0]?.id && { id: list.data[0].id }
      firstItemId && handleSetPiece(firstItemId, undefined, list)
    }
  }

  useEffect(() => {
    setActivePiece(currentModule.currentPiece)
    currentModule.list === undefined
      ? getFilteredReports(currentModule.filters)
      : handleReportsList(currentModule.list)
  }, [currentModule.id])

  const pageChange = (event, value) => {
    handleFilters('page', value === 1 ? [] : value)
    getFilteredReports(lastFilteredData, value)
  }

  const handleSetPiece = (piece, fromSinglePiece, listOnInitialLoad) => {
    if (fromSinglePiece) {
      if (reportsData.data.filter((item) => item.id === piece)[0]) {
        reportsData.data.filter((item) => item.id === piece)[0].read = true
        setReportsData(reportsData)
      }
    } else {
      const list = listOnInitialLoad || reportsData

      list.data.forEach((scenario) => {
        if (piece.id === scenario.id) piece.read = true
      })

      setReportsData(list)
      setActivePiece(piece.id)
      setWorkspaces((currentState) => {
        const currentWorkspace = currentState.find(
          (workspace) => workspace.id === currentModule.id,
        )

        if (!currentWorkspace) return currentState
        currentWorkspace.currentPiece = piece.id
        currentWorkspace.scrollPosition = 0
        currentWorkspace.currentTab = 'content'
        listOnInitialLoad && delete currentWorkspace.isNew
        return currentState
      })
    }
  }

  const orderChange = (value) => {
    setOrderBy(value)
    handleFilters('orderBy', value)
    setTimeout(() => handleSubmit())
  }

  const handleFilters = (key, value) => {
    const singlePickValues = [
      'keywords',
      'types',
      'page',
      'dateRange',
      'sectors',
      'orderBy',
    ]
    const filterKey = currentModule?.filters[key]
    const currentState = [...workspaces]
    const currentWorkspace = currentState.find(
      (workspace) => workspace.id === currentModule.id,
    )
    const isSingleValueFilter = singlePickValues.includes(key)

    if (!filterKey) {
      currentWorkspace.filters[key] = isSingleValueFilter ? value : [value]
      setWorkspaces(currentState)
      return false
    }

    currentWorkspace.filters[key] = isSingleValueFilter
      ? value
      : filterKey?.includes(value)
      ? filterKey?.filter((val) => val !== value)
      : [...filterKey, value]
    currentWorkspace.filters[key]?.length === 0 &&
      delete currentWorkspace.filters[key]
    setWorkspaces(currentState)
  }

  const handleSubmit = () => {
    const data = currentModule.filters

    handleFilters('page', 1)
    getFilteredReports(data)
  }

  const changeStatus = (id, type) => {
    const currentState = { ...reportsData }
    const filtered = currentState.data.find((item) => item.id === id)

    if (type === 'flagged') {
      filtered.is_flagged = true
    }
    if (type === 'unflagged') {
      filtered.is_flagged = false
    }
    if (type === 'unread') {
      if (filtered) {
        filtered.read = false
      }

      setActivePiece(null)
      setWorkspaces((currentState) => {
        const currentWorkspace = currentState.find(
          (workspace) => workspace.id === currentModule.id,
        )

        if (!currentWorkspace) return currentState
        currentWorkspace.currentPiece = null
        currentWorkspace.scrollPosition = 0
        currentWorkspace.currentTab = 'content'
        return currentState
      })
    }
    setReportsData(currentState)
  }

  const resetPiece = () => {
    setActivePiece(null)
    setWorkspaces((currentState) => {
      const currentWorkspace = currentState.find(
        (workspace) => workspace.id === currentModule.id,
      )

      if (!currentWorkspace) return currentState
      currentWorkspace.currentPiece = null
      return currentState
    })
  }

  return (
    <div className='reports'>
      <div className={filtersOpen ? 'filters expanded' : 'filters'}>
        <div className='heading'>
          {filtersOpen && <h2>Filter & Search</h2>}

          {filtersOpen ? (
            <Tooltip title='Close filters'>
              <IconButton
                disableRipple
                onClick={() => {
                  setFiltersOpen(!filtersOpen)
                  setWorkspaces((currentState) => {
                    const currentWorkspace = currentState.find(
                      (workspace) => workspace.id === currentModule.id,
                    )

                    currentWorkspace.filtersOpen = !filtersOpen
                    return currentState
                  })
                }}
              >
                <span
                  style={{ transform: 'rotate(180deg)' }}
                  className='icon-expand-content'
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              style={{
                transform: 'rotate(270deg)',
                textTransform: 'capitalize',
                background: 'transparent',
                border: 'none',
                minWidth: 150,
              }}
              variant='outlined'
              disableRipple
              onClick={() => {
                setFiltersOpen(!filtersOpen)
                setWorkspaces((currentState) => {
                  const currentWorkspace = currentState.find(
                    (workspace) => workspace.id === currentModule.id,
                  )

                  currentWorkspace.filtersOpen = !filtersOpen
                  return currentState
                })
              }}
              endIcon={
                <span
                  style={{ transform: 'rotate(90deg)' }}
                  className='icon-expand-content'
                />
              }
            >
              Filters & Search
            </Button>
          )}
        </div>

        {toastMessage && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={5000}
            onClose={() => setToastMessage(false)}
            open={toastMessage && toastMessage[0] !== undefined}
          >
            <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
              {toastMessage[1]}
            </MuiAlert>
          </Snackbar>
        )}

        {filtersOpen && (
          <Divider style={{ margin: '20px 0', padding: '0 25px' }} />
        )}

        {filtersOpen && (
          <Filters
            keywordsInput={keywordsInput}
            handleFilters={handleFilters}
            handleSubmit={handleSubmit}
            filterOptions={filterOptions}
            setOrderBy={setOrderBy}
            setFiltersOpen={setFiltersOpen}
          />
        )}
      </div>

      {(!isMobile || (!activePiece && isMobile)) && (
        <div
          style={{ width: isMobile ? '100%' : undefined }}
          className='list-container'
        >
          {reportsData && (
            <div ref={wrapperRef} className='list-header'>
              <div className='list-header__top'>
                <span style={{ fontWeight: 100, fontSize: 16 }}>
                  Total results: <b>{reportsData.total}</b>
                </span>

                {!isMobile &&
                  is_management_mode_possible &&
                  create_mode.find((module) => module.slug === 'report') && (
                    <BaseButton
                      onClick={() =>
                        isContentBeingEdited
                          ? setToastMessage([
                              'warning',
                              'A content is currently being edited. Exit Create Content Mode.',
                            ])
                          : history.push('/app/content-create/report/new')
                      }
                    >
                      <span
                        className='icon-edit'
                        style={{ fontSize: 16, marginRight: '6px' }}
                      />

                      <span>Create new report</span>
                    </BaseButton>
                  )}
              </div>

              <div className='list-header__nav'>
                <Pagination
                  style={{ marginTop: 1 }}
                  onChange={pageChange}
                  page={currentModule?.filters?.page || 1}
                  count={reportsData?.last_page}
                  color='primary'
                  disabled={isLoading}
                  shape='rounded'
                  size='small'
                  hideNextButton
                  hidePrevButton
                />

                <OrderBy orderBy={orderBy} orderChange={orderChange} />
              </div>
            </div>
          )}

          {isLoading && <Spinner center size={30} border={1} />}

          {!isLoading &&
            reportsData?.data !== null &&
            reportsData?.data?.length === 0 && <div>No Results Found</div>}

          {!isLoading && reportsData?.data !== null && (
            <>
              <ModuleListing
                items={reportsData?.data || []}
                activeItemRef={activeItemRef}
                handleSetPiece={handleSetPiece}
                type='report'
                filters={currentModule.filters}
              />

              <Pagination
                onChange={pageChange}
                page={currentModule?.filters?.page || 1}
                count={reportsData?.last_page}
                color='primary'
                disabled={isLoading}
                shape='rounded'
                size='small'
              />
            </>
          )}
        </div>
      )}

      {(!isMobile || (activePiece && isMobile)) && (
        <SinglePiece
          items={readItems}
          currentModule={currentModule}
          filtersOpen={filtersOpen}
          listLoading={isLoading}
          setToastMessage={setToastMessage}
          configuration={configuration}
          type='report'
          modifyWorkspaceName={modifyWorkspaceName}
          changeStatus={changeStatus}
          handleSetPiece={handleSetPiece}
          resetPiece={resetPiece}
        />
      )}
    </div>
  )
}

export default Reports
