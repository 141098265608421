/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */

import Tooltip from '@mui/material/Tooltip'
import parse from 'html-react-parser'

const Hint = ({ text, size, margin }) => {
  return (
    <Tooltip placement='top' arrow title={parse(text)}>
      <span
        style={{ fontSize: size || 28, marginBottom: margin ? 8 : 0 }}
        className='icon-circle-info'
      />
    </Tooltip>
  )
}

export default Hint
