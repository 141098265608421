/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import { Menu } from '@material-ui/icons'
import { Button } from '@mui/material'
import { TextField } from '@material-ui/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DeleteModal } from '../../components'
import FroalaEditorComponent from '../../../../shared/FroalaEditorComponent/FroalaEditorComponent'

const ArticleTextSection = ({ props }) => {
  const { content, handleSectionState, index, id, handleDeleteSection } = props
  const sortable = useSortable({ id: id })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [text, setText] = useState(content?.content)

  useEffect(() => handleSectionState(index, 'content', text), [text])

  const { attributes, listeners, isDragging, setNodeRef, transform } = sortable

  const disableAxis = (scale) => {
    if (scale) {
      scale.scaleX = 1
      scale.scaleY = 1
      scale.x = 0
    }
    return scale
  }

  const style = {
    transform: CSS.Transform.toString(disableAxis(transform)),
    // transition,
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? '10000' : 'auto',
  }

  return (
    <div
      className='knowledge-base__article-section'
      ref={setNodeRef}
      style={style}
    >
      {isModalOpen && (
        <DeleteModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          onDelete={handleDeleteSection}
        />
      )}

      <div className='knowledge-base__article-section-header'>
        <div
          onClick={() =>
            handleSectionState(index, 'isExpanded', !content.isExpanded)
          }
          className='knowledge-base__article-section-header-left'
        >
          <span
            style={{
              fontSize: 22,
              marginRight: 10,
              transform: content.isExpanded ? 'rotate(180deg)' : null,
            }}
            className='icon-down'
          />

          <span>{content.headline || 'Text Section'}</span>
        </div>

        <div className='knowledge-base__article-section-header-right'>
          <Button
            onClick={() => setIsModalOpen(['modal', index])}
            style={{
              textDecoration: 'underline',
              background: 'transparent',
              textTransform: 'capitalize',
              color: '#fff',
              padding: '3px 10px',
              marginRight: 20,
            }}
          >
            Delete
          </Button>

          <Menu
            style={{ cursor: 'move', outline: 'none' }}
            {...listeners}
            {...attributes}
          />
        </div>
      </div>

      {content.isExpanded && (
        <div className='knowledge-base__article-section-content'>
          <div style={{ marginBottom: 20 }}>
            <h5>Headline</h5>

            <TextField
              value={content.headline || ''}
              onChange={(event) =>
                handleSectionState(index, 'headline', event.target.value)
              }
              fullWidth
              placeholder='Enter headline'
              variant='outlined'
            />
          </div>

          <div>
            <h5>Text</h5>

            <FroalaEditorComponent
              handleEditorChange={setText}
              commentText={content.content}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ArticleTextSection
