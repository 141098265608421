import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
} from '@material-ui/core'
import AssociatedTags from './AssociatedTags'
import { tagsList } from '../../../../../shared/services/api/createContent/createAlert'

const FullTagsList = ({
  fullListOpen,
  setFullListOpen,
  categories,
  data,
  handleState,
}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [tabValue, setTabValue] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [tagsOptions, setTagsOptions] = useState([])
  const [currentState, setCurrentState] = useState(data || [])
  const handleTemporaryState = (value, shouldDelete) => {
    const state = [...currentState]
    const deletePiece =
      shouldDelete && state?.filter((item) => item.id !== value)
    const addPiece = [...state, value]

    setCurrentState(shouldDelete ? deletePiece : addPiece)
  }

  const styles = {
    left: {
      width: '35%',
      overflow: 'auto',
      marginRight: 15,
    },
    right: {
      width: '65%',
      display: 'flex',
      marginLeft: 15,
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tab: {
      textTransform: 'capitalize',
      minHeight: 16,
      fontSize: 12,
      maxWidth: 'unset',
      padding: '3px 6px',
    },
    headline: {
      marginTop: 5,
      textTransform: 'capitalize',
      pointerEvents: 'none',
      fontSize: 18,
      padding: '3px 6px',
      minHeight: 16,
      opacity: 1,
    },
    saveButton: {
      backgroundColor: '#006fff',
      color: '#fff',
      textTransform: 'capitalize',
      fontWeight: 700,
      borderRadius: 12,
      padding: '6px 20px',
    },
  }

  const getTagOptions = async () => {
    setLoading(true)
    try {
      const type = 'tag'
      const slug = categories[tabValue]?.slug
      const response = await tagsList(token(), type, slug)
      setTagsOptions(response.list)
      setLoading(false)
    } catch (err) {}
  }

  const handleSaveFullList = () => {
    const isTemporary = true
    handleState(currentState, isTemporary)
    setFullListOpen(false)
  }

  useEffect(() => {
    getTagOptions()
  }, [tabValue])

  return (
    <Dialog fullWidth maxWidth='md' open={fullListOpen}>
      <DialogTitle id='alert-dialog-title'>Tags search</DialogTitle>

      <Divider style={{ margin: '0 24px' }} />

      <DialogContent className='tag-list-dialog' style={styles.wrapper}>
        <div style={styles.left}>
          <h3 style={{ marginBottom: 10 }}>Choose Tag Category</h3>

          <Tabs
            // style={styles.tab}
            orientation='vertical'
            value={tabValue}
            indicatorColor='primary'
            onChange={(event, newValue) => setTabValue(newValue)}
          >
            {categories.map((category) => (
              <Tab
                key={category.name}
                label={category.name}
                className='tag-tab'
                disableRipple
                style={category.id ? styles.tab : styles.headline}
              />
            ))}
          </Tabs>
        </div>

        <div style={styles.right}>
          <AssociatedTags
            currentState={currentState}
            options={tagsOptions || []}
            isLoading={isLoading}
            handleTemporaryState={handleTemporaryState}
          />
        </div>
      </DialogContent>

      <DialogActions
        style={{ padding: '0 24px 16px 24px', justifyContent: 'space-between' }}
      >
        <Button onClick={() => setFullListOpen(false)}>Cancel</Button>

        <Button style={styles.saveButton} onClick={() => handleSaveFullList()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FullTagsList
