import React from 'react'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core'
import './MuiDatePicker.scss'

const MuiDatePicker = ({
  value,
  initialDate,
  onDateRangeChange,
  dateLabel,
  minDate,
  maxDate,
}) => {
  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableFuture
            className='picker'
            autoComplete='off'
            minDate={minDate}
            maxDate={maxDate}
            margin='normal'
            id='date-picker-dialog'
            format='dd/MM/yyyy'
            value={initialDate || value || null}
            placeholder='dd/mm/yyyy'
            inputVariant='outlined'
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            helperText={null}
            onChange={(date) => onDateRangeChange(dateLabel, date)}
            variant='inline'
            fullWidth
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  )
}

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'none',
        },
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#fff',
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: 'rgb(0, 111, 255)',
      },
      root: {
        '&:hover': {
          color: 'rgb(0, 111, 255)',
        },
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: 'rgb(0, 111, 255)',
        color: '#fff',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'rgb(0, 111, 255)',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: '#fff',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#fff',
        fontFamily: '"Do Hyeon", sans-serif',
        backgroundColor: '#CDD3DB',
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: 'rgb(0, 111, 255)',
        },
      },
      container: {
        backgroundColor: '#000',
      },
      daySelected: {
        backgroundColor: 'rgb(0, 111, 255)',
        color: '#fff',
        '&:hover': {
          backgroundColor: 'rgb(0, 111, 255)',
        },
      },
      current: {
        color: 'white',
      },
    },
  },
})

export default MuiDatePicker
