import React, { useState, Fragment, useEffect } from 'react'
import { Divider } from '@material-ui/core'
import AssociatedChips from '../microcomponents/AssociatedChips'
import { getAssociatedProfiles } from '../../../../../shared/services/api/createContent/createAlert'
import Spinner from '../../../../../shared/Spinner'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const AssociatedProfiles = ({ isExpanded }) => {
  const {
    handleCreateState,
    createContentState: { related },
  } = useCreateManagementContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [associatedProfilesOptions, setAssociatedProfilesOptions] = useState({})
  const [isLoading, setLoading] = useState(true)
  const profiles = ['Threat Actor', 'Incident', 'Operation', 'Malware & Tools']

  const handleState = (value, shouldDelete) => {
    let currentState = (related && [...related]) || []

    if (shouldDelete) {
      currentState = currentState.filter((item) => item.id !== value)
    } else {
      if (value?.associated_profile) {
        const { associated_profile } = value

        currentState = currentState
          ? [...currentState, associated_profile]
          : [associated_profile]
      }
    }
    handleCreateState('related', currentState, true)
  }

  useEffect(() => {
    return () => {}
  }, [])

  const getAssociatiations = async () => {
    setLoading(true)
    try {
      const response = await getAssociatedProfiles(token())
      setAssociatedProfilesOptions(response.list)
      setLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    isExpanded && getAssociatiations()
  }, [isExpanded])

  return (
    <div className='middle'>
      {isLoading ? (
        <Spinner centerHorizontally size={30} border={1} />
      ) : (
        <div className='tags-modules'>
          {profiles.map((profile) => (
            <Fragment key={profile}>
              <AssociatedChips
                title={profile}
                chosenElements={related || []}
                options={associatedProfilesOptions[profile]}
                isLoading={isLoading}
                handleState={handleState}
              />

              {profile !== 'Malware & Tools' && (
                <Divider style={{ margin: '20px 0' }} />
              )}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default AssociatedProfiles
