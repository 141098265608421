import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@material-ui/core'
import { useCreateManagementContext } from '../../../../../../context/createManagementState'

const ConfirmChangeGroup = ({
  confirmChangeGroup,
  setConfirmChangeGroup,
  handleContentDistributionState,
  getGroupOptions,
}) => {
  const { setCreateContentState } = useCreateManagementContext()

  const handleChangeGroup = async () => {
    const id = confirmChangeGroup[1]
    const name = confirmChangeGroup[2]
    handleContentDistributionState('group-change')
    handleContentDistributionState('groups', id, name)

    const data = await getGroupOptions(id, true)
    setCreateContentState((prevState) => ({
      ...prevState,
      can_publish_or_deny: data.can_current_user_publish_or_deny,
    }))

    setConfirmChangeGroup(false)
  }

  return (
    <Dialog open={confirmChangeGroup[0]} fullWidth maxWidth='xs'>
      <DialogTitle id='alert-dialog-title'>Confirm Change Group</DialogTitle>

      <Divider style={{ margin: '0 24px' }} />

      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          You are about to switch groups and all current distribution settings
          will be lost.
        </DialogContentText>
      </DialogContent>

      <DialogActions style={{ padding: '0 24px 16px 24px' }}>
        <Button onClick={() => setConfirmChangeGroup(false)}>Cancel</Button>

        <Button
          style={{
            backgroundColor: '#FF0033',
            color: '#fff',
            textTransform: 'capitalize',
            fontWeight: 700,
            borderRadius: 12,
            padding: '6px 20px',
          }}
          onClick={() => handleChangeGroup()}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmChangeGroup
