/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { MouseEvent, useEffect, useRef, useState } from 'react'
import { reverse, unionBy, uniqBy, merge } from 'lodash'
import Pusher from 'pusher-js/with-encryption'
import { v4 } from 'uuid'
import parse from 'html-react-parser'
import { useChat } from '../../../context/chatContext'
import {
  addReaction,
  editMessage,
  getChatHistory,
  markAsRead,
  sendMessage as sendMessageAPI,
} from '../../../shared/services/api/chat/chat'
import { useHistory } from 'react-router-dom'
import { ChatData } from './SingleConversation/SingleConversation'
import { useUserContext } from '../../../context/userContext'
import useNotification from '../../../hooks/useNotification'
import { apiUrl, pusherKey } from '../../../shared/config'
import client from '../../../shared/services/http/client'
import {
  formatCreatedAtMessage,
  formatGlobalSearch,
} from '../../../shared/helpers/formatDate'

const isValidURL = (url: string) => {
  const regex =
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/

  return regex?.test(url)
}

export const useSingleConversation = () => {
  const [chatData, setChatData] = useState<ChatData[] | []>([])
  const [message, setMessage] = useState<string>('')
  const messageRef = useRef<null | HTMLDivElement>(null)
  const [showPreview, setShowPreview] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const rootSentinelRef = useRef<HTMLInputElement>(null)
  const [totalUnread, setTotalUnread] = useState<null | number>(null)
  const [hiddenUnreadMessages, setHiddenUnreadMessages] = useState<
    null | number
  >(null)
  const [canLoadOlder, setCanLoadOlder] = useState<boolean>(true)
  const [canLoadNewer, setCanLoadNewer] = useState<boolean>(false)
  const [isFetching, setFetching] = useState<boolean>(false)
  const [isBeingCached, setIsBeingCached] = useState<boolean>(false)
  const [isUserLeaving, setIsUserLeaving] = useState<undefined | string>(
    undefined,
  )
  const [highlightedId, setHighlightedId] = useState<string | null>(null)
  const [mentionIds, setMentionIds] = useState<any[]>([])
  const scrollRef = useRef<null | HTMLDivElement>(null)
  const [lockSend, setLockSend] = useState<boolean>(false)
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const {
    activeChat,
    activeUserChat,
    setGroupChats,
    setPeople,
    people,
    setCachedPiecesOfContent,
    editedMessageId,
    setEditedMessageId,
    chatOpen,
    queryData,
    setQueryData,
    groupChats,
    setChatOpen,
    setEmailFetchState,
  } = useChat()
  const history = useHistory()
  const { user } = useUserContext()
  const { errorToast } = useNotification()
  const currentInstance = process.env.REACT_APP_CURRENT_INSTANCE
  const [forceNoTotalUnread, setForceNoTotalUnread] = useState<boolean>(false)

  const fetchHistory = async (
    activeUserChat: any,
    activeUserChatId: any,
    activeChatId: any,
    isInfiniteScroll: boolean,
    historyType?: string,
  ) => {
    if (isFetching) return false
    if (queryData?.group_id && queryData?.chat_id && !activeUserChatId)
      return false //direct email should ignore fetching group history
    try {
      setFetching(true)
      const handleLastMsgId = () => {
        if (isInfiniteScroll) {
          //scrolling event
          if (historyType === 'old') {
            return chatData?.slice(-1)[0]?.id + ''
          } else if (historyType === 'new') {
            return chatData[0]?.id + ''
          }
        } else {
          //entering from email
          if (
            (activeUserChatId && queryData?.group_id) ||
            (!activeUserChatId && !queryData?.group_id)
          ) {
            return queryData?.message_id || null
          }
        }
        return null
      }
      const lastMessageId = handleLastMsgId()
      const { data } = await getChatHistory(
        token(),
        activeUserChat ? activeUserChatId : activeChatId,
        activeUserChat ? 'direct' : 'group',
        lastMessageId,
      )

      if (forceNoTotalUnread) {
        data.total_unread_messages = 0
      }

      const finalMessages = () => {
        if (isInfiniteScroll) {
          if (historyType === 'old') {
            return [...data?.chat?.older_messages]
          } else if (historyType === 'new') {
            return [...reverse(data?.chat?.newer_messages)]
          }
        }
        if (!isInfiniteScroll && lastMessageId) {
          return [
            ...reverse(data?.chat?.newer_messages),
            data?.chat?.target_message,
            ...data?.chat?.older_messages,
          ]
        } else if (!isInfiniteScroll && !lastMessageId) {
          return [...data?.chat?.older_messages]
        }
        return []
      }
      setHighlightedId(!isInfiniteScroll && lastMessageId)
      const messages = finalMessages()
      setCanLoadOlder(data?.chat?.older_messages?.length === 20)
      setCanLoadNewer(data?.chat?.newer_messages?.length === 20)
      const daysFiltered = (
        historyType === 'old' || !isInfiniteScroll
          ? data?.chat?.older_messages
          : data?.chat?.newer_messages
      )?.reduce((groups: any, msg: any) => {
        const date = msg.created_at.split('T')[0]
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(msg)
        return groups
      }, {})

      Object.values(daysFiltered)?.forEach((day: any) => {
        const firstMessageOfTheDay = day?.slice(-1)[0]?.id
        const firstMessage = messages.find((m) => m.id === firstMessageOfTheDay)

        if (firstMessage) {
          firstMessage.isFirstMessage = true
        }
      })
      if (data?.total_unread_messages === 0 && !forceNoTotalUnread) {
        const lastUnreadMsg = [...chatData, ...messages]
          ?.filter((m: any) => m.read_at === null)
          ?.slice(-1)[0]

        if (lastUnreadMsg) {
          lastUnreadMsg.renderSeparator = true
        }
      }

      !isInfiniteScroll && setTotalUnread(data?.total_unread_messages)
      setChatData((current) =>
        lastMessageId && historyType === 'old' //scrolling top
          ? unionBy(current, uniqBy(messages, 'id'), 'id')
          : historyType === 'new' //scrolling bottom, reverse messages
          ? unionBy(messages, uniqBy(current, 'id'), 'id')
          : uniqBy(messages, 'id'),
      )
      if (rootSentinelRef?.current && !lastMessageId && historyType !== 'new') {
        rootSentinelRef.current.scrollTop =
          rootSentinelRef?.current?.scrollHeight
      }

      setTimeout(() => {
        setFetching(false)
      }, 100)

      if (activeUserChat && queryData?.group_id && queryData?.chat_id) {
        history.replace({ search: '' })
        setQueryData(null)
        setChatOpen(true)
        setEmailFetchState('finished')
      } else if (!activeUserChat && !queryData?.group_id) {
        history.replace({ search: '' })
        setQueryData(null)
        setChatOpen(JSON.parse(localStorage.getItem('isChatOpen') ?? 'false'))
        setEmailFetchState('finished')
      }
      // if (messageRef?.current) {
      //   messageRef.current.scrollTop = messageRef?.current?.scrollHeight
      // }
    } catch (err) {
      console.log(err)
    }
  }

  const handleAddReaction = async (messageId?: number, emoticon?: string) => {
    await addReaction(token(), {
      id: messageId,
      chat_id: activeUserChat?.id || activeChat?.id || null,
      chat_type: activeUserChat ? 'direct' : 'group',
      content: emoticon,
    })
  }

  useEffect(() => {
    if (highlightedId && chatOpen) {
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }, 800)
    }
  }, [chatOpen])

  let pusher: Pusher | null = null

  useEffect(() => {
    const firstUserId = user?.user_info?.central_user_id as number
    const secondUserId = activeUserChat?.id as number
    const users = [firstUserId, secondUserId].sort((a, b) => a - b)
    const directChannel = `private-encrypted-direct.${users[0]}.${users[1]}`
    const groupChannel = `private-encrypted-group.${activeChat?.id}`

    if (pusher === null) {
      pusher = new Pusher(pusherKey || '', {
        authEndpoint: apiUrl + '/api/broadcasting/auth',
        channelAuthorization: {
          transport: 'ajax',
          endpoint: apiUrl + '/api/broadcasting/auth',
          headersProvider: () => {
            return {
              Authorization: 'Bearer ' + token(),
            }
          },
        },
        cluster: 'eu',
      })
    }
    const channel = pusher?.subscribe(
      activeUserChat ? directChannel : groupChannel,
    )

    fetchHistory(activeUserChat, activeUserChat?.id, activeChat?.id, false)

    channel?.bind('chat-message-deleted', (data: any) => {
      setChatData((prevState) => {
        return [
          ...prevState.map((msg) =>
            msg.id !== data.message_id
              ? msg
              : { ...msg, content: '', deleted_at: String(Date.now()) },
          ),
        ]
      })
    })

    channel?.bind('chat-message-reported', (data: any) => {
      setChatData((prevState) => {
        return [
          ...prevState.map((msg) =>
            msg.id !== data.message_id
              ? msg
              : {
                  ...msg,
                  content: '',
                  is_reported: 1,
                  reported_at: String(Date.now()),
                },
          ),
        ]
      })
    })

    channel.bind('pusher:subscription_error', (error: any) => {
      if (error?.status === 401) {
        setLockSend(true)
        setTimeout(() => {
          fetchHistory(
            activeUserChat,
            activeUserChat?.id,
            activeChat?.id,
            false,
          )
          setLockSend(false)
        }, 4000)
      }
    })

    channel?.bind('chat-message', (data: any) => {
      if (data?.chat_type === 'direct') {
        setPeople((current) => {
          const currentChat = current?.list?.find(
            ({ central_user_id }) => central_user_id === data?.chat_id,
          )

          if (currentChat) {
            currentChat.last_message_at = formatCreatedAtMessage(
              data?.created_at,
            )
          }

          return current
        })
      }

      setChatData((prevState) => {
        const isTodayFirstMessage =
          formatGlobalSearch(prevState[0]?.created_at) !==
          formatGlobalSearch(new Date())

        return [
          {
            ...data,
            isFirstMessage: isTodayFirstMessage ? true : false,
            deleted_at: null,
            is_reported: 0,
          },
          ...prevState,
        ]
      })

      if (rootSentinelRef?.current) {
        rootSentinelRef.current.scrollTop =
          rootSentinelRef?.current?.scrollHeight
      }
    })

    channel?.bind('chat-message-updated', (data: any) => {
      setChatData((prevState) => {
        return [
          ...prevState.map((msg) =>
            msg?.id === data?.message_id
              ? {
                  ...msg,
                  content: data?.content,
                  uk_links: data?.uk_links,
                  eu_Links: data?.eu_links,
                  type: isValidURL(data?.content) ? 'LINK' : 'TEXT',
                }
              : msg,
          ),
        ]
      })
    })

    channel?.bind('chat-event', (data: any) => {
      setChatData((prevState) => {
        prevState.forEach((msg: any) => {
          if (data.id === msg.id) {
            const selfId = user?.user_info?.central_user_id
            const newMessageAuthorId = data?.author?.centralised_id
            let newReactions = []
            if (selfId === newMessageAuthorId) {
              newReactions =
                msg?.reactions?.filter(
                  (re: any) => re.author.centralised_id !== selfId,
                ) ?? []
            } else {
              newReactions =
                msg?.reactions?.filter(
                  (re: any) =>
                    newMessageAuthorId !== re?.author?.centralised_id,
                ) ?? []
            }

            msg.reactions = [
              ...newReactions,
              { ...data, chat_message_id: data.id },
            ]
          }
        })
        return [...prevState]
      })
    })

    return () => {
      channel?.unsubscribe()
    }
  }, [activeUserChat?.id, activeChat?.id, localStorage.getItem('access_token')])

  const handleSendMessage = async (message: string) => {
    if (!message?.length) return false

    let msgParsed: any[] = []
    let isLink = false
    let externalLink: any | null = null

    const internalLinks: { [key: string]: any } = {
      alert: [],
      profile: [],
      report: [],
      scenario: [],
    }

    if (mentionIds?.length > 0) {
      //adding html tag to mentioned user
      message = message.replace(
        /\{(.+?)\}/g,
        (m, label, url) => `<span class="content-mention">${label}</span>`,
      )
    }

    message?.split(' ').forEach(async (msg) => {
      if (isValidURL(msg)) {
        const query = new URL(msg)
        const id = query?.searchParams?.get('id')
        const type = query?.searchParams?.get('type')

        msgParsed = [...msgParsed, `<span class='content-link'>${msg}</span>`]

        if (id && type) {
          isLink = true
          const finalModule =
            type === 'alert' || type === 'scenario' || type === 'report'
              ? type
              : 'profile'
          const newArray = internalLinks[finalModule]?.includes(id)
            ? internalLinks[finalModule]
            : [...internalLinks[finalModule], id]
          internalLinks[finalModule] = newArray
        } else {
          isLink = true
          externalLink = externalLink ? [...externalLink, msg] : [msg]
        }
      } else {
        msgParsed = [...msgParsed, msg]
      }
    })

    if (isLink && externalLink) {
      finaliseSendMessageWithExternalLink(
        msgParsed.join(' '),
        isLink,
        externalLink,
        internalLinks,
      )
    } else if (externalLink) {
      finaliseSendMessageWithExternalLink(
        msgParsed.join(' '),
        isLink,
        externalLink,
        null,
      )
    } else {
      finaliseSendMessage(msgParsed.join(' '), isLink, internalLinks)
    }
  }

  const handlePreviewData = (previewData: any) => {
    const anotherInstanceLinks: { [key: string]: any } = {
      alert: [],
      profile: [],
      scenario: [],
      report: [],
    }

    previewData &&
      Object.entries(previewData)?.forEach((i: any) => {
        const module = i[0]
        const contents = i[1]
        Object.values(contents)?.map((content: any) => {
          const { eu_id, uk_id } = content
          const differentInstanceId = currentInstance === 'uk' ? eu_id : uk_id
          anotherInstanceLinks[module] = [
            ...anotherInstanceLinks[module],
            differentInstanceId?.toString(),
          ]
        })
      })

    return anotherInstanceLinks
  }

  const finaliseSendMessage = async (
    finalMessage: any,
    isLink: boolean,
    internalLinks: any,
  ) => {
    try {
      // if (editedMessageId) {
      const lastMessageSenderId = chatData[0]?.author?.centralised_id
      const data = {
        id: editedMessageId ? editedMessageId : v4(),
        content: finalMessage,
        chat_type: activeUserChat ? 'direct' : 'group',
        chat_id: activeUserChat?.id || activeChat?.id || null,
        type: isLink ? 'LINK' : 'TEXT',
        uk_links: {},
        eu_links: {},
        group_id: activeUserChat ? activeChat?.id : null,
        is_same_sender:
          lastMessageSenderId === user?.user_info?.central_user_id,
        mention_ids: mentionIds,
      }

      if (!data.chat_id) return false
      let numberOfContents = 0
      Object.values(internalLinks)?.forEach((e: any) => {
        numberOfContents = numberOfContents + +e.length
      })
      if (numberOfContents > 0 && isLink) {
        const previewData = await fetchPreviewData(internalLinks)
        const instanceData = handlePreviewData(previewData)

        if (isLink) {
          data.uk_links =
            currentInstance === 'uk' ? internalLinks : instanceData
          data.eu_links =
            currentInstance === 'eu' ? internalLinks : instanceData
        }
      }
      setMessage('')
      const response = (editedMessageId
        ? await editMessage(token(), data)
        : await sendMessageAPI(token(), data)) as unknown as {
        error: boolean
        message: string
      }
      if (response.error) {
        return errorToast({
          message: response.message,
        })
      }

      editedMessageId && setEditedMessageId(null)
      setTimeout(() => {
        if (messageRef?.current) {
          messageRef.current.scrollTop = messageRef?.current?.scrollHeight
        }
      })
      setMentionIds([])
      // }
    } catch {}
  }

  const finaliseSendMessageWithExternalLink = async (
    finalMessage: any,
    isLink: boolean,
    externalLink: null | any,
    internalLinks: null | any,
  ) => {
    if (!externalLink) return false
    setIsLoading(true)

    const lastMessageSenderId = chatData[0]?.author?.centralised_id
    const data = {
      id: editedMessageId ? editedMessageId : v4(),
      content: finalMessage,
      chat_type: activeUserChat ? 'direct' : 'group',
      chat_id: activeUserChat?.id || activeChat?.id || null,
      group_id: activeChat?.id || null,
      external_link: [], //externalLink,
      external_link_data: [], //external_link_data,
      is_same_sender: lastMessageSenderId === user?.user_info?.central_user_id,
      type: isLink ? 'LINK' : 'TEXT',
      uk_links: {},
      eu_links: {},
      mention_ids: mentionIds,
    }

    if (!data.chat_id) return false

    let numberOfContents = 0
    internalLinks !== null &&
      Object.values(internalLinks)?.forEach((e: any) => {
        numberOfContents = numberOfContents + +e.length
      })
    if (numberOfContents > 0 && isLink) {
      const previewData = await fetchPreviewData(internalLinks)
      const instanceData = handlePreviewData(previewData)
      if (isLink) {
        data.uk_links = currentInstance === 'uk' ? internalLinks : instanceData
        data.eu_links = currentInstance === 'eu' ? internalLinks : instanceData
      }
    }

    setMessage('')
    const response = (editedMessageId
      ? await editMessage(token(), data)
      : await sendMessageAPI(token(), data)) as unknown as {
      error: boolean
      message: string
    }

    if (response.error) {
      return errorToast({
        message: response.message,
      })
    }

    setEditedMessageId(null)
    setIsLoading(false)
    setTimeout(() => {
      if (messageRef?.current) {
        messageRef.current.scrollTop = messageRef?.current?.scrollHeight
      }
    })
    setMentionIds([])
  }

  useEffect(() => {
    if (pusher === null) {
      pusher = new Pusher(pusherKey || '', {
        authEndpoint: apiUrl + '/api/broadcasting/auth',
        channelAuthorization: {
          transport: 'ajax',
          endpoint: apiUrl + '/api/broadcasting/auth',
          headersProvider: () => {
            return {
              Authorization: 'Bearer ' + token(),
            }
          },
        },
        cluster: 'eu',
      })
    }
    const firstUserId = user?.user_info?.central_user_id as number

    const channel = pusher?.subscribe(`private-notification.${firstUserId}`)
    channel?.bind('new-direct-message-notification', (data: any) => {
      const { user_id } = data

      setPeople((prevState) => {
        const newPeopleList = prevState?.list?.map((person) => {
          if (
            (person.central_user_id === user_id &&
              user_id !== prevState?.currentChatId?.id) ||
            (!chatOpen &&
              user_id === prevState?.currentChatId?.id &&
              person.central_user_id === user_id)
          ) {
            return {
              ...person,
              chat_settings: {
                ...person.chat_settings,
                total_unread_messages:
                  person.chat_settings.total_unread_messages + 1,
              },
            }
          } else {
            return person
          }
        })
        const currentChat = newPeopleList?.find(
          ({ central_user_id }) => central_user_id === data?.user_id,
        )
        if (currentChat) {
          currentChat.last_message_at = formatCreatedAtMessage(data?.created_at)
        }
        return { list: newPeopleList, currentChatId: prevState.currentChatId }
      })
    })

    channel?.bind('new-group-message-notification', (data: any) => {
      const { chat_id } = data

      setGroupChats((prevState) => {
        const newGroupList = prevState?.list?.map((group) => {
          if (
            (group.id === chat_id &&
              chat_id !== prevState?.currentChatId?.id) ||
            (chat_id === prevState?.currentChatId?.id &&
              group.id === chat_id &&
              !chatOpen)
          ) {
            return {
              ...group,
              chat_settings: {
                ...group.chat_settings,
                total_unread_messages:
                  group.chat_settings.total_unread_messages + 1,
              },
            }
          } else {
            return group
          }
        })
        return { list: newGroupList, currentChatId: prevState.currentChatId }
      })
    })

    return () => {
      channel?.unsubscribe()
    }
  }, [chatOpen])

  useEffect(() => {
    return () => {
      pusher?.disconnect()
    }
  }, [])

  useEffect(() => {
    const currentState = [...chatData]
    const finalLinks: { [key: string]: any } = {
      alert: [],
      profile: [],
      scenario: [],
      report: [],
    }
    currentState?.forEach((msg) => {
      const curLinks = currentInstance === 'uk' ? msg.uk_links : msg.eu_links
      if (curLinks && Object.keys(curLinks).length > 0) {
        Object.entries(curLinks).forEach((i: any) => {
          const module = i[0]
          const contents = i[1]
          contents?.forEach((e: any) => {
            if (!e?.id) {
              finalLinks[module] = [...finalLinks[module], e]
            }
          })
        })
      }
    })
    currentState?.forEach((msg) => {
      if (msg?.type === 'LINK' && !msg?.hasBeenParsed) {
        const strippedLink = msg?.content?.replaceAll(/<\/?[^>]+(>|$)/gi, '')
        let links: any[] = []
        strippedLink?.split(' ')?.forEach((mes) => {
          if (isValidURL(mes)) {
            links = [...links, parse(mes)]
          }
        })

        links?.forEach((link) => {
          const query = new URL(link)
          const id = query?.searchParams?.get('id')
          const type = query?.searchParams?.get('type')?.toLowerCase() || ''
          if (!id || !type) return false
          const finalModule =
            type === 'alert' || type === 'scenario' || type === 'report'
              ? type
              : 'profile'

          msg.contentId = Number(id)
          msg.contentType = finalModule
          msg.hasBeenParsed = true
        })
      }
    })

    let numberOfContents = 0
    Object.values(finalLinks)?.forEach((e: any) => {
      numberOfContents = numberOfContents + +e.length
    })
    numberOfContents > 0 && fetchPreviewData(finalLinks)
    return () => {}
  }, [chatData])

  const fetchPreviewData = async (contentList: { [key: string]: any }) => {
    try {
      setIsBeingCached(true)
      const data = {
        modules: contentList,
      }
      const response = await client.post(
        apiUrl + '/api/chat/preview/contents',
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token(),
          },
        },
      )

      setTimeout(() => {
        setCachedPiecesOfContent((currentState: any) => {
          return merge(currentState, response.data)
        })
        setIsBeingCached(false)
      })

      return response.data
    } catch {}
  }

  const convertExternalLink = async (externalLink: string) => {
    let external_link_data = null
    try {
      const token = localStorage.getItem('access_token')
      const data = { link: externalLink }
      const response = await client.post(
        apiUrl + '/api/chat/preview/external',
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      )
      if (response?.data) {
        external_link_data = response.data
        return external_link_data
      }
    } catch (error) {}
    return external_link_data
  }

  const handleFocus = (event: MouseEvent<HTMLDivElement>) => {
    const target = event?.target as Element
    const formElement = target?.parentElement?.parentElement
      ?.lastChild as HTMLElement
    if (target?.classList?.value === 'infinite-scroll') {
      formElement?.querySelector('input')?.focus()
    }
  }

  const markAllAsRead = async () => {
    setForceNoTotalUnread(true)
    const data = {
      ids: [''],
      mark_read_all: true,
      chat_id: activeUserChat?.id || activeChat?.id || null,
      chat_type: activeUserChat ? 'direct' : 'group',
    }
    await markAsRead(token(), data)
    setChatData((currentData) => {
      currentData?.map((item) => {
        if (item.read_at === null) {
          item.read_at = ''
          return item
        } else return item
      })

      return currentData
    })

    if (activeUserChat?.id) {
      const newState = { ...people }
      const currentChat = newState?.list?.find(
        ({ central_user_id }) => central_user_id === activeUserChat?.id,
      )
      if (currentChat) {
        currentChat.chat_settings.total_unread_messages = 0
      }
      setPeople(newState)
    } else if (activeChat?.id) {
      const newState = { ...groupChats }
      const currentChat = newState?.list?.find(
        ({ id }) => id === activeChat?.id,
      )
      if (currentChat) {
        currentChat.chat_settings.total_unread_messages = 0
      }
      setGroupChats(newState)
    }
    setTotalUnread(null)
    setHiddenUnreadMessages(null)
    setTimeout(() => setForceNoTotalUnread(false), 10000)
  }

  const handleInfiniteScroll = (historyType: string) => {
    fetchHistory(
      activeUserChat,
      activeUserChat?.id,
      activeChat?.id,
      true,
      historyType,
    )
  }

  useEffect(() => {
    setHiddenUnreadMessages(null)
    setTotalUnread(null)
    setPeople((prevState) => {
      return { list: prevState.list, currentChatId: activeUserChat }
    })

    setGroupChats((prevState) => {
      return { list: prevState.list, currentChatId: activeChat }
    })
  }, [activeChat?.id, activeUserChat?.id])

  return {
    chatData,
    message,
    messageRef,
    showPreview,
    isLoading,
    rootSentinelRef,
    totalUnread,
    setTotalUnread,
    hiddenUnreadMessages,
    canLoadNewer,
    canLoadOlder,
    isFetching,
    isBeingCached,
    isUserLeaving,
    highlightedId,
    scrollRef,
    fetchHistory,
    handleAddReaction,
    handleSendMessage,
    handlePreviewData,
    handleInfiniteScroll,
    markAllAsRead,
    handleFocus,
    setIsUserLeaving,
    setHiddenUnreadMessages,
    setIsBeingCached,
    setShowPreview,
    setMessage,
    setChatData,
    mentionIds,
    setMentionIds,
    lockSend,
  }
}
