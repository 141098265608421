export const layoutsList = [
  // {
  //   id: 1,
  //   name: 'organic',
  //   curvedLinks: true,
  //   stretch: 5,
  //   tightness: 1,
  // },
  // {
  //   id: 2,
  //   name: 'standard',
  //   curvedLinks: false,
  //   stretch: 5,
  //   tightness: 3,
  // },
  {
    id: 3,
    name: 'lens',
    curvedLinks: false,
    stretch: 10,
    tightness: 5,
  },
  {
    id: 5,
    name: 'radial',
    curvedLinks: true,
    stretch: 5,
    tightness: 5,
  },
  {
    id: 6,
    name: 'sequential',
    curvedLinks: false,
    stretch: 2,
    tightness: 10,
  },
  {
    id: 7,
    name: 'structural',
    curvedLinks: false,
    stretch: 6,
    tightness: 4,
  },
]
