import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, Dialog, DialogContent, TextField } from '@material-ui/core'
import Button from '../../../shared/components/Button'

const CreateModal = ({ isModalOpen, setIsModalOpen, onAdd }) => {
  const [inputValue, setInputValue] = useState('')
  const [modalType, id] = isModalOpen

  return (
    <Dialog
      id='popup-blur'
      open={!!isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <div style={{ width: '600px' }}>
        <div
          style={{
            padding: '20px 0',
            width: '100%',
            backgroundColor: '#006BF5',
          }}
          id='form-dialog-title'
        >
          <h3 style={{ color: '#fff', paddingLeft: '20px' }}>
            Add {modalType === 'subcategory' ? 'Subcategory' : 'Category'}
          </h3>
        </div>

        <DialogContent style={{ padding: 0 }}>
          <div
            style={{
              margin: '30px 20px 10px 20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{ fontSize: '14px', fontWeight: '700', color: '#212935' }}
            >
              {modalType === 'subcategory' ? 'Subcategory' : 'Category'} Name
            </div>

            <TextField
              style={{ width: '100%' }}
              onChange={(event) => setInputValue(event.target.value)}
              margin='dense'
              variant='outlined'
              id='token'
              placeholder='Name'
              type='text'
            />

            <Divider style={{ width: '100%', marginTop: 30 }} />

            <Button
              className='modal-btn'
              disabled={!inputValue}
              onClick={() =>
                onAdd({ title: inputValue, icon: 'question', parent_id: id })
              }
              type='button'
              background='green'
            >
              Create
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

CreateModal.defaultProps = {}

CreateModal.propTypes = {
  isModalOpen: PropTypes.instanceOf(Array).isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
}

CreateModal.displayName = 'CreateModal'

export default CreateModal
