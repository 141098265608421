/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react'

import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/js/third_party/embedly.min.js'

import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/third_party/embedly.min.css'

import Froala from 'react-froala-wysiwyg'
import { config } from './options'
import { useCreateManagementContext } from '../../context/createManagementState'

const FroalaEditorComponent = ({
  label,
  internal_key,
  textKey,
  reportSubBlockIndex,
  commentText,
  handleEditorChange,
}) => {
  const [isFroalaInitialized, setIsFroalaInitialized] = useState(false)
  let handleCreateState, dev_update_summary, dev_content, free_content, content
  internal_key !== undefined &&
    ({
      handleCreateState,
      createContentState: {
        dev_update_summary,
        dev_content,
        dev_content: {
          [internal_key]: {
            content,
            content: { free_content },
          },
        },
      },
    } = useCreateManagementContext())
  const text = textKey && content[textKey]
  const [model, setModel] = useState(
    commentText !== undefined
      ? commentText
      : reportSubBlockIndex !== undefined
      ? free_content[reportSubBlockIndex].element.text || ''
      : internal_key === 'update-summary'
      ? dev_update_summary || ''
      : text || '',
  )

  const onChange = () => {
    if (handleEditorChange) {
      //comments case
      handleEditorChange(model)
      return false
    }

    if (isFroalaInitialized && internal_key !== undefined) {
      //create mode case
      if (dev_content[internal_key].is_complete)
        dev_content[internal_key].is_complete = false
      if (dev_content[internal_key].is_accepted)
        dev_content[internal_key].is_accepted = false
      if (dev_content[internal_key].is_rejected)
        dev_content[internal_key].is_rejected = false

      if (reportSubBlockIndex !== undefined) {
        free_content[reportSubBlockIndex].element.text = model
        handleCreateState(internal_key, dev_content[internal_key])
        return false
      } else if (internal_key === 'update-summary') {
        handleCreateState('dev_update_summary', model, true)
      } else {
        dev_content[internal_key].content[textKey] = model
        handleCreateState(internal_key, dev_content[internal_key])
      }
    }
  }

  useEffect(() => {
    isFroalaInitialized && onChange()
  }, [model])

  return (
    <>
      {label && <h5 style={{ marginBottom: 5 }}>{label}</h5>}

      <Froala
        model={model}
        onModelChange={(text) => setModel(text)}
        tag='textarea'
        config={config}
        onManualControllerReady={(editor) => {
          editor?.initialize()
          setTimeout(() => setIsFroalaInitialized(true))
        }}
      />
    </>
  )
}

export default FroalaEditorComponent
