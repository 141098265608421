import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const getMitreData = (token, type, view) =>
  client.get(
    apiUrl +
      `/api/scenario-configuration/mitre-navigator/${type}/raw?is_viewer_mode=${view}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const getMitreNavigatorData = (token, id, view) =>
  client.get(
    apiUrl +
      `/api/scenario-configuration/mitre-navigator?framework_id=${id}&is_viewer_mode=${view}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const addLayer = (token, id) =>
  client.post(
    apiUrl +
      `/api/admin/scenarios/linear-content-builder/${id}/mitre-navigator/layers`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const deleteLayer = (token, id, layerId) =>
  client.remove(
    apiUrl +
      `/api/admin/scenarios/linear-content-builder/${id}/mitre-navigator/layers/${layerId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const mitreContentPieces = (token, type) =>
  client.get(apiUrl + `/api/mitre-navigator/search/${type}?nopagination`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const mitreContentSinglePiece = (token, type, id) =>
  client.get(apiUrl + `/api/mitre-navigator/config/${type}/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
