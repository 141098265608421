import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const colours = [
  { colour_title: 'CLEAR', colour: 'clear', colour_code: '#0000' },
  { colour_title: 'GREEN', colour: 'green', colour_code: '#3aaa35' },
  { colour_title: 'AMBER', colour: 'amber', colour_code: '#ffc000' },
  {
    colour_title: 'AMBER+STRICT',
    colour: 'amber_strict',
    colour_code: '#ffc000',
  },
  { colour_title: 'RED', colour: 'red', colour_code: '#f03' },
]

const Tlp = ({}) => {
  const {
    handleCreateState,
    createContentState: { dev_tlp },
  } = useCreateManagementContext()

  const handleState = (prop, value) => {
    const newState =
      prop === 'colour'
        ? { caveat: dev_tlp.caveat || '', colour: value }
        : { ...dev_tlp, [prop]: value }

    handleCreateState('dev_tlp', newState, true)
  }

  return (
    <div className='content-section__content'>
      <div className='tlp'>
        <Divider />

        <RadioGroup row>
          {Object.values(colours).map(
            ({ colour, colour_code, colour_title }) => (
              <FormControlLabel
                style={{
                  textTransform: 'capitalize',
                  color: '#fff',
                  backgroundColor:
                    dev_tlp?.colour === colour ? colour_code : '#1b222e', //data.content.colour === radio.colour ? radio.colour_code : '#1b222e',
                  padding: '10px 14px',
                  borderRadius: 12,
                  margin: '0 7px 7px 0',
                }}
                key={colour}
                value={colour}
                onChange={() => handleState('colour', colour)}
                checked={dev_tlp?.colour === colour}
                control={
                  <Radio
                    style={{ color: '#fff', padding: 0, marginRight: 8 }}
                  />
                }
                label={colour_title}
              />
            ),
          )}
        </RadioGroup>

        <TextField
          label='Caveat'
          multiline
          inputProps={{ style: { textTransform: 'uppercase' } }}
          fullWidth
          onBlur={(event) => handleState('caveat', event.target.value)}
          variant='outlined'
          rows={4}
          defaultValue={dev_tlp?.caveat || ''}
        />
      </div>
    </div>
  )
}

export default Tlp
