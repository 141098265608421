/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useRef } from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import Hint from '../Hint'
import { useGlobalContext } from '../../../../../context/globalContext'

const CreateContentHeader = ({
  sectionData,
  sectionSettings,
  handleCompleteSections,
  handleExpandedSections,
  shouldBeDisabled,
  isManager,
  handleSectionStatus,
}) => {
  const { isContentBeingEdited } = useGlobalContext()
  const shouldDisableAndRenderTooltip = () => {
    if (
      isContentBeingEdited === false &&
      (sectionSettings.internal_key === 'ioc' ||
        sectionSettings.internal_key === 'author-settings' ||
        sectionSettings.internal_key === 'content-distribution' ||
        sectionSettings.internal_key === 'tlp')
    ) {
      return true
    }
  }

  const background = () => {
    if (
      (sectionData.is_accepted && isManager) ||
      (!isManager && sectionData.is_complete && !sectionData.is_rejected)
    ) {
      return '#3aaa35'
    } else if (sectionData.is_rejected) {
      return '#ff0033'
    }
  }

  const ManagerRight = () => {
    if (
      sectionData.is_complete &&
      (sectionData.is_accepted || sectionData.is_rejected)
    ) {
      return <Statuses />
    }
    if (
      sectionData.is_complete &&
      !sectionData.is_accepted &&
      !sectionData.is_rejected
    ) {
      return (
        <div>
          <Tooltip arrow placement='top' title='Reject'>
            <span>
              <IconButton
                style={{ padding: 3 }}
                disabled={shouldBeDisabled}
                onClick={() =>
                  handleSectionStatus(
                    sectionSettings.internal_key,
                    'is_rejected',
                  )
                }
              >
                <Close style={{ color: '#ff0033', fontSize: 32 }} />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip arrow placement='top' title='Accept'>
            <span>
              <IconButton
                style={{ padding: 3 }}
                disabled={shouldBeDisabled}
                onClick={() =>
                  handleSectionStatus(
                    sectionSettings.internal_key,
                    'is_accepted',
                  )
                }
              >
                <Check style={{ color: '#56df50', fontSize: 32 }} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      )
    } else {
      return false
    }
  }

  const Statuses = () => {
    return (
      <div className='status-container'>
        {sectionData.is_accepted && (
          <Button
            onClick={() =>
              handleSectionStatus(
                sectionSettings.internal_key,
                'is_accepted',
                true,
                true,
              )
            }
          >
            Accepted
          </Button>
        )}

        {sectionData.is_rejected && (
          <Button
            onClick={() =>
              handleSectionStatus(
                sectionSettings.internal_key,
                'is_rejected',
                true,
                true,
              )
            }
          >
            Rejected
          </Button>
        )}
      </div>
    )
  }

  const ManagerLeft = () => {
    if (
      sectionData.is_complete &&
      (sectionData.is_accepted || sectionData.is_rejected)
    ) {
      return <Statuses />
    }
    if (
      sectionData.is_complete &&
      !sectionData.is_accepted &&
      !sectionData.is_rejected
    ) {
      return (
        <Fragment>
          <Button
            onClick={() =>
              handleSectionStatus(sectionSettings.internal_key, 'is_rejected')
            }
            disabled={shouldBeDisabled}
            className='content-section__header-left__button-reject'
          >
            Reject
          </Button>

          <Button
            onClick={() =>
              handleSectionStatus(sectionSettings.internal_key, 'is_accepted')
            }
            disabled={shouldBeDisabled}
            className='content-section__header-left__button-accept'
          >
            Accept
          </Button>
        </Fragment>
      )
    } else if (
      (isManager && !sectionData.is_accepted && !sectionData.is_complete) ||
      (isManager &&
        !sectionData.is_accepted &&
        !sectionData.is_rejected &&
        !sectionData.is_complete)
    ) {
      return <CompleteCheckboxLeft label='Mark as complete' />
    } else {
      return false
    }
  }

  const CompleteCheckboxLeft = ({ label }) => {
    return (
      <Tooltip
        title={
          sectionSettings.internal_key === 'content-distribution' &&
          shouldBeDisabled
            ? 'Your organisation needs to be selected '
            : ''
        }
      >
        <FormControlLabel
          onChange={() =>
            handleCompleteSections(sectionSettings.internal_key, sectionData)
          }
          label={label}
          control={
            label ? (
              <Checkbox
                checked={sectionData.is_complete || false}
                className='cb-complete'
                disableRipple
                icon={<span className='icon-checkbox-empty' />}
                checkedIcon={<span className='icon-checkbox-checked' />}
                disabled={shouldBeDisabled}
              />
            ) : (
              <Tooltip arrow placement='top' title='Mark as complete'>
                <Checkbox
                  checked={sectionData.is_complete || false}
                  className='cb-complete'
                  icon={<span className='icon-checkbox-empty' />}
                  checkedIcon={<span className='icon-checkbox-checked' />}
                  disabled={shouldBeDisabled}
                />
              </Tooltip>
            )
          }
        />
      </Tooltip>
    )
  }

  const AnalystView = ({ label }) => {
    return sectionData.is_accepted || sectionData.is_rejected ? (
      <div className='status-container'>
        {sectionData.is_accepted && (
          <Button
            onClick={() =>
              handleSectionStatus(sectionSettings.internal_key, 'is_accepted')
            }
          >
            Accepted
          </Button>
        )}

        {sectionData.is_rejected && (
          <Button
            onClick={() =>
              handleSectionStatus(sectionSettings.internal_key, 'is_rejected')
            }
          >
            Rejected
          </Button>
        )}
      </div>
    ) : (
      <FormControlLabel
        onChange={() =>
          handleCompleteSections(sectionSettings.internal_key, sectionData)
        }
        label={label}
        control={
          label ? (
            <Checkbox
              checked={sectionData.is_complete || false}
              className='cb-complete'
              disableRipple
              icon={<span className='icon-checkbox-empty' />}
              checkedIcon={<span className='icon-checkbox-checked' />}
              disabled={shouldBeDisabled}
            />
          ) : (
            <Tooltip arrow placement='top' title='Mark as complete'>
              <Checkbox
                checked={sectionData.is_complete || false}
                className='cb-complete'
                icon={<span className='icon-checkbox-empty' />}
                checkedIcon={<span className='icon-checkbox-checked' />}
                disabled={shouldBeDisabled}
              />
            </Tooltip>
          )
        }
      />
    )
  }

  const LeftContentView = () => {
    const headerRef = useRef(null)

    return (
      <div
        style={{
          backgroundColor: background(),
          borderRadius: sectionData.is_expanded ? '12px 12px 0px 0px' : 12,
        }}
        className='content-section__header'
      >
        <div
          className='content-section__header-left'
          ref={headerRef}
          onClick={() =>
            handleExpandedSections(sectionSettings.internal_key, sectionData)
          }
        >
          <span
            style={{
              transform: sectionData.is_expanded ? 'rotate(180deg)' : '',
            }}
            className='icon-down'
          />

          <h3 style={{ textTransform: 'capitalize', marginRight: 8 }}>
            {sectionSettings.headline}
          </h3>

          {sectionSettings.columns.hint &&
            sectionSettings.columns.hint.is_tooltip && (
              <Hint text={sectionSettings.columns.hint.text} />
            )}

          {sectionSettings.is_mandatory && (
            <span style={{ marginLeft: 8 }} className='label-required'>
              Required
            </span>
          )}
        </div>

        {isManager && <ManagerLeft />}

        {!isManager && <AnalystView label='Mark as complete' />}
      </div>
    )
  }

  const RightContentViewWithTooltip = () => {
    return (
      <Tooltip
        placement='left'
        arrow
        title='Please add content to other sections first.'
      >
        <div
          style={{
            backgroundColor: sectionData.is_complete && '#3aaa35',
            borderRadius:
              sectionData.is_complete || shouldDisableAndRenderTooltip()
                ? 12
                : '12px 12px 0px 0px',
          }}
          className='content-section__header'
        >
          <div className='content-section__header-left'>
            <h3 style={{ textTransform: 'capitalize', color: '#ffffff2e' }}>
              {sectionSettings.headline}
            </h3>

            {sectionSettings.columns.hint &&
              sectionSettings.columns.hint.is_tooltip && (
                <Hint text={sectionSettings.columns.hint.text} />
              )}

            {sectionSettings.is_mandatory && (
              <span
                style={{ marginLeft: 8 }}
                className='label-required__sidebar'
              >
                Required
              </span>
            )}
          </div>
        </div>
      </Tooltip>
    )
  }

  const RightContentView = () => {
    return (
      <div
        style={{
          backgroundColor: background(),
          borderRadius:
            (isManager && sectionData.is_accepted) ||
            sectionData.is_rejected ||
            (!isManager && sectionData.is_complete)
              ? 12
              : '12px 12px 0px 0px',
        }}
        className='content-section__header'
      >
        <div className='content-section__header-left'>
          <div className='content-section__header-left-headline'>
            <h4 style={{ textTransform: 'capitalize' }}>
              {sectionSettings.headline}
            </h4>

            {sectionSettings.columns.hint &&
              sectionSettings.columns.hint.is_tooltip && (
                <Hint text={sectionSettings.columns.hint.text} />
              )}
          </div>

          {sectionSettings.is_mandatory && (
            <span style={{ marginLeft: 5 }} className='label-required__sidebar'>
              Required
            </span>
          )}
        </div>

        {isManager && <ManagerRight />}

        {!isManager && <AnalystView />}

        {((isManager && !sectionData.is_accepted && !sectionData.is_complete) ||
          (isManager &&
            !sectionData.is_accepted &&
            !sectionData.is_rejected &&
            !sectionData.is_complete)) && <CompleteCheckboxLeft />}
      </div>
    )
  }

  return (
    <Fragment>
      {!sectionSettings.is_sidebar_section &&
        !shouldDisableAndRenderTooltip() && <LeftContentView />}

      {!sectionSettings.is_sidebar_section &&
        shouldDisableAndRenderTooltip() && <RightContentViewWithTooltip />}

      {sectionSettings.is_sidebar_section &&
        !shouldDisableAndRenderTooltip() && <RightContentView />}

      {sectionSettings.is_sidebar_section &&
        shouldDisableAndRenderTooltip() && <RightContentViewWithTooltip />}
    </Fragment>
  )
}

export default CreateContentHeader
