/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Tooltip } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import './TotalsTab.scss'
import { useHistory } from 'react-router-dom'
import subDays from 'date-fns/subDays'
import { useGlobalContext } from '../../../context/globalContext'

/**
 * TotalsTab
 * @param {object} totals - list of items to map
 * @param {object} tab - current tab
 */

const TotalsTab = ({ alertTypes, totals, tab }) => {
  const history = useHistory()
  const { setGlobalSearchFilters } = useGlobalContext()
  const returnWidth = (count, total) => {
    const percentage = ((count / total.reduce((a, b) => a + b)) * 100).toFixed(
      2,
    )
    if (isNaN(percentage)) {
      return '20%'
    } else {
      return percentage + '%'
    }
  }

  const severityName = (level) => {
    if (level === 1) {
      return 'Very low'
    } else if (level === 2) {
      return 'Low'
    } else if (level === 3) {
      return 'Medium'
    } else if (level === 4) {
      return 'High'
    } else if (level === 5) {
      return 'Very high'
    }
  }

  return (
    <div className='totals-tab'>
      {Object.entries(totals).map((type) => {
        const name = type[0]
        const { count, id } = type[1]
        const tooltipDesc =
          alertTypes?.find((type) => type.name === name && type.id)?.tooltip ||
          ''

        return (
          <div className='type-row' key={name}>
            <Tooltip placement='top' arrow title={tooltipDesc}>
              <span
                className='icon-circle-info'
                style={{ fontSize: '26px', marginRight: '6px' }}
              />
            </Tooltip>

            <span
              style={{
                fontSize: 13,
                color: '#a4a7ab',
                minWidth: 100,
                width: 'min-content',
                lineHeight: '13px',
              }}
            >
              {name}
            </span>

            <div>
              {Object.entries(count).map((item) => {
                const severityLevel = item[0] * 1
                const severityCount = item[1]

                return (
                  <Tooltip
                    key={severityLevel}
                    title={severityName(severityLevel)}
                  >
                    <span
                      style={{
                        width: returnWidth(severityCount, Object.values(count)),
                      }}
                      className={`severity severity-${severityLevel}`}
                      onClick={() => {
                        setGlobalSearchFilters({
                          fromDashboard: true,
                          currentTab: 'alerts',
                          priority: [severityLevel],
                          dateRange: [subDays(new Date(), tab), new Date()],
                          types: [{ name: name, id: id }],
                        })
                        history.push('/app/search')
                      }}
                    >
                      {severityCount}
                    </span>
                  </Tooltip>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

TotalsTab.displayName = 'TotalsTab'

/**
 * The properties.
 * @type {Object}
 */

TotalsTab.propTypes = {
  alertTypes: PropTypes.instanceOf(Array).isRequired,
  totals: PropTypes.instanceOf(Object).isRequired,
  tab: PropTypes.number.isRequired,
}

export default TotalsTab
