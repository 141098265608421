/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { ArrowDownward, Info } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import MitreSubTechnique from './MitreSubTechnique'

const MitreTechnique = ({
  items,
  view,
  layers,
  category,
  isInAnyLayer,
  returnActive,
  returnShade,
  handleAddTag,
  returnCompareBoxes,
  handleShowInfo,
  handleExpanded,
  stageId,
}) => {
  const isCompareMode = layers[1]?.mode
  const returnBorder = (technique_id, tactic_id) => {
    if (view) {
      if (isCompareMode) return null

      if (isInAnyLayer(technique_id, tactic_id)) return '2px solid #666'
    } else if (!view) {
      if (returnActive(technique_id, tactic_id)) return '2px dotted #006fff'
    }
  }

  const renderNumber = (tec) => {
    const isCompare = layers[1]?.mode === 3
    if (isCompare) return false
    return returnShade(tec.pivot.technique_id, tec.pivot.tactic_id)[1] || null
  }

  const shadeToNumber = (tec) => {
    const number = returnShade(tec.pivot.technique_id, tec.pivot.tactic_id)[1]
    return number.split('-')[number.split('-')?.length - 1]
  }

  return items.map((tec) => (
    <div
      key={tec.id}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div
        className={`navigator-technique${view ? ' is-view' : ''}`}
        style={{
          marginBottom: tec.sub_techniques.length === 0 ? 8 : 0,
          border: returnBorder(tec.pivot.technique_id, tec.pivot.tactic_id),
        }}
        onClick={() =>
          !view &&
          handleAddTag(
            tec.pivot.technique_id,
            tec.pivot.tactic_id,
            category.name,
            tec,
            category.category_id,
          )
        }
      >
        <div
          className={`navigator-technique__background ${
            returnShade(tec.pivot.technique_id, tec.pivot.tactic_id)[0] || ''
          } ${
            returnShade(tec.pivot.technique_id, tec.pivot.tactic_id)[1] || ''
          } ${
            returnShade(tec.pivot.technique_id, tec.pivot.tactic_id)[2] || ''
          }`}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 5,
            zIndex: 5,
          }}
          className='technique-footer'
        >
          <div
            className={`navigator-technique__background ${
              returnShade(tec.pivot.technique_id, tec.pivot.tactic_id)[0] || ''
            } ${
              returnShade(tec.pivot.technique_id, tec.pivot.tactic_id)[1] || ''
            } ${
              returnShade(tec.pivot.technique_id, tec.pivot.tactic_id)[2] || ''
            }`}
          />

          {renderNumber(tec) ? (
            <span
              style={{
                position: 'absolute',
                top: 0,
                right: 3,
                fontSize: 32,
                opacity: 0.75,
                lineHeight: '36px',
                fontWeight: 400,
                color: '#000',
                textAlign: 'center',
              }}
            >
              {shadeToNumber(tec)}
            </span>
          ) : null}

          <span style={{ zIndex: 5, userSelect: 'none', width: '75%' }}>
            {tec.name}
          </span>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 15,
              zIndex: 5,
            }}
            className='technique-footer'
          >
            <div style={{ display: 'flex' }}>
              {Object.entries(returnCompareBoxes()).map((box) => {
                const id = box[0]
                const value = box[1]
                const colourId = layers?.find((item) => item?.id == id)?.colour
                const equalTechnique =
                  value?.techniques[tec.pivot.technique_id] &&
                  value?.techniques[tec.pivot.technique_id][tec.pivot.tactic_id]
                if (equalTechnique) {
                  return (
                    <span
                      key={
                        id +
                        '_' +
                        tec.pivot.technique_id +
                        '_' +
                        tec.pivot.tactic_id
                      }
                      className={`compare-box mitre-colour--${colourId || 1}`}
                      // style={{ display: 'block', width: 12, height: 12, border: '2px solid #fff', marginRight: 4 }}
                    />
                  )
                }
              })}
            </div>

            <Info
              onClick={(event) =>
                handleShowInfo(event, [tec.name, tec.description])
              }
              style={{ color: '#0000008a' }}
            />
          </div>
        </div>
      </div>

      {tec.sub_techniques.length > 0 && (
        <IconButton
          className='technique-expand'
          onClick={() => handleExpanded(stageId, category.id, tec.id)}
          disableRipple
          style={{
            width: '100%',
            borderRadius: 0,
            backgroundColor: '#b1b1b1',
            padding: 5,
            marginBottom: 8,
            marginTop: '-2px',
            transform: tec.isExpanded ? 'rotate(180deg)' : '',
          }}
        >
          <ArrowDownward style={{ color: '#fff', fontSize: 14 }} />
        </IconButton>
      )}

      {tec.isExpanded && (
        <div style={{ marginRight: 20 }}>
          {tec.sub_techniques.map((subtec) => (
            <MitreSubTechnique
              key={subtec.id}
              view={view}
              tacticId={tec.pivot.tactic_id || ''}
              subtec={subtec}
              layers={layers}
              categoryName={category.name}
              returnActive={returnActive}
              handleShowInfo={handleShowInfo}
              isInAnyLayer={isInAnyLayer}
              handleAddTag={handleAddTag}
              returnShade={returnShade}
              returnCompareBoxes={returnCompareBoxes}
            />
          ))}
        </div>
      )}
    </div>
  ))
}

export default MitreTechnique
