/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import './NotificationPreferences.scss'
import MuiAlert from '@material-ui/lab/Alert'
import { Divider, Tabs, Tab, Snackbar } from '@material-ui/core'
import ContentRelated from './tabs/ContentRelated'
import {
  saveNotifications,
  updateNotifications,
} from '../../../../../shared/services/api/notifications/notifications'
import ChatRelated from './tabs/ChatRelated'
import { useUserContext } from '../../../../../context/userContext'
import ForumRelated from './tabs/ForumRelated'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useNotification from '../../../../../hooks/useNotification'
import { useQueryGetNotifications } from '../../../../../shared/services/queries/user/account/useQueryGetNotifications'
import { useQueryGetAccount } from '../../../../../shared/services/queries/user/account/useQueryGetAccount'

/**
 * NotificationPreferences
 * @param {object} userData - user details
 * @param {function} setUserData - updates user details
 */

const NotificationPreferences = ({}) => {
  const user = useUserContext()
  const userData = useQueryGetAccount()
  const { data } = useQueryGetNotifications()

  const [tab, setTab] = useState(
    user.user.is_origin_organisation_notification_system_enabled
      ? 'content'
      : 'chat',
  )
  const token = () => {
    return localStorage.getItem('access_token')
  }

  const [toastMessage, setToastMessage] = useState(false)
  const queryClient = useQueryClient()
  const { successToast } = useNotification()
  const tabs = user.user.is_origin_organisation_notification_system_enabled
    ? [{ name: 'Content', slug: 'content' }]
    : []

  if (user?.user?.is_chat_enabled) {
    tabs.push({ name: 'Chat', slug: 'chat' })
  }

  if (user?.user?.is_forum_enabled) {
    tabs.push({ name: 'Forum', slug: 'forum' })
  }

  const updateNotification = useMutation({
    mutationFn: async (newValue) => {
      const response = await updateNotifications(token(), {
        is_notification_system_enabled: newValue,
      })
      return response
    },
    onSuccess: (resMsg) => {
      successToast({ message: resMsg?.message })
      queryClient.invalidateQueries(['user-account'])
    },
  })

  const settingsChange = useMutation({
    mutationFn: (newData) => {
      queryClient.setQueryData(['user-notifications'], newData)
    },
  })

  const handleNotifications = () => {
    const newValue = !userData?.data?.data?.is_notification_system_enabled
    updateNotification.mutate(newValue)
  }

  const handleSettingChange = (settingType, option, newValue) => {
    const currentState = { ...data }
    if (settingType === 'social_notification') {
      currentState[settingType][option] = newValue
    } else {
      currentState[settingType].settings[option].value = newValue
    }

    settingsChange.mutate(currentState)
  }

  const handleSectorChange = (id) => {
    const currentState = { ...data }
    if (id === 'clear') {
      currentState?.sectors?.settings?.all_sectors?.value?.forEach((i) => {
        i.selected = false
      })
    } else {
      const currentValue =
        currentState?.sectors?.settings?.all_sectors?.value?.find(
          (item) => item.id === id,
        )?.selected

      currentState.sectors.settings.all_sectors.value.find(
        (item) => item.id === id,
      ).selected = !currentValue
    }

    settingsChange.mutate(currentState)
  }

  const handleSubmit = () => {
    const save = async () => {
      try {
        let response = await saveNotifications(token(), data)
        setToastMessage(response.message)
      } catch {}
    }

    save()
  }
  return (
    <div className='notification-preferences'>
      <h2>Notification Preferences</h2>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setToastMessage(false)}
        open={toastMessage !== false}
      >
        <MuiAlert elevation={6} variant='filled' severity='success'>
          {toastMessage}
        </MuiAlert>
      </Snackbar>

      <Divider />

      <Tabs
        value={tab}
        indicatorColor='primary'
        onChange={(event, newValue) => setTab(newValue)}
      >
        {tabs.map(({ name, slug }) => (
          <Tab
            key={name}
            label={name}
            value={slug}
            // disableRipple
          />
        ))}
      </Tabs>

      {tab === 'content' && (
        <ContentRelated
          notificationsEnabled={
            userData?.data?.data?.is_notification_system_enabled
          }
          handleNotifications={handleNotifications}
          handleSettingChange={handleSettingChange}
          handleSectorChange={handleSectorChange}
          data={data}
          isLoading={!data}
          handleSubmit={handleSubmit}
        />
      )}

      {tab === 'chat' ? (
        <ChatRelated
          data={data?.social_notification}
          handleSettingChange={handleSettingChange}
          handleSubmit={handleSubmit}
          isLoading={!data}
        />
      ) : null}

      {tab === 'forum' ? (
        <ForumRelated
          data={data?.social_notification}
          handleSettingChange={handleSettingChange}
          handleSubmit={handleSubmit}
          isLoading={!data}
        />
      ) : null}
    </div>
  )
}

NotificationPreferences.displayName = 'NotificationPreferences'

/**
 * The properties.
 * @type {Object}
 */

NotificationPreferences.propTypes = {
  // userData: PropTypes.instanceOf(Object).isRequired,
}

export default NotificationPreferences
