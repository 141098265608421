/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Avatar, InputAdornment, TextField } from '@material-ui/core'
import { PushPin } from '@mui/icons-material'
import { Pagination } from '@mui/material'
import { useState } from 'react'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import parse from 'html-react-parser'

import { apiUrl } from '../../../shared/config'
import { formatDate } from '../../../shared/helpers/formatDate'
import { useQueryGetForumSearchedThreads } from '../../../shared/services/queries/forum/useQueryGetSearchedThreads'

const token = () => {
  return localStorage.getItem('access_token')
}

const SearchedThreads = () => {
  const params: { keyword: string; forumId: string } = useParams()
  const [searchPhrase, setSearchPhrase] = useState(params.keyword)
  const history = useHistory()
  const [page, setPage] = useState(1)

  const searchedThreads = useQueryGetForumSearchedThreads(
    params?.keyword,
    params?.forumId,
    page,
  )
  const totalPages = Math.ceil(
    searchedThreads?.data?.data?.results.total /
      searchedThreads?.data?.data?.results.perPage,
  )

  const totalSearchedThreads = searchedThreads?.data?.data?.results.total
  const getInitails = (name: string) => {
    const initials = []
    const fullDisplayName = name?.split(' ')
    initials[0] = fullDisplayName[0]?.at(0)
    initials[1] = fullDisplayName[1]?.at(0)

    return initials.join('')
  }

  return (
    <div className='forum__main'>
      <header className='forum__header'>
        <div>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              history.push({
                pathname: `/app/forum/${params?.forumId}/search/${searchPhrase}`,
                state: {
                  searchPhrase,
                },
              })
            }}
          >
            <TextField
              fullWidth
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              value={searchPhrase}
              onChange={(event) => {
                setSearchPhrase(event.target.value)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <span
                      className='icon-search'
                      style={{ color: '#fff', fontSize: 28 }}
                    />
                  </InputAdornment>
                ),
              }}
              style={{ color: '#fff' }}
              placeholder='Search'
              variant='standard'
            />
          </form>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
            gap: 8,
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              minWidth: 170,
              gap: 15,
            }}
          >
            <h5 style={{ position: 'absolute', top: '-20px' }}>Sort by</h5>
          </div>
        </div>
      </header>
      <main className='forum__main-content'>
        <div className='forum__threads'>
          <div style={{ fontSize: '18px', fontWeight: 700 }}>
            {totalSearchedThreads && totalSearchedThreads !== 0
              ? `${totalSearchedThreads} Result${
                  totalSearchedThreads === 1 ? '' : 's'
                } found for "${searchPhrase}"`
              : `No results found`}
          </div>
          <div className='forum__threads-row' style={{ marginTop: '18px' }}>
            <div style={{ flex: 1, fontWeight: 'bold' }}>Thread</div>
            <div
              style={{
                flexBasis: '100px',
                display: 'flex',
                fontWeight: 'bold',
                justifyContent: 'center',
              }}
            >
              Stats
            </div>
            <div
              style={{
                flexBasis: '225px',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              Last post
            </div>
          </div>
          <div
            className='forum__threads-cards'
            style={{ overflowY: 'scroll', maxHeight: '500px' }}
          >
            {searchedThreads?.data?.data?.results.items.map(
              ({
                id,
                title,
                post,
                thread,
                category,
                category_id,
                content,
                is_pinned,
                last_post_at,
                total_posts,
                author,
              }: {
                id: number
                title: string
                total_posts: number
                last_post_at: string
                is_pinned: boolean
                category_id: number
                content: string
                author: { display_name: string; avatar: string }
                category: {
                  name: string
                  color: string
                }
                post: {
                  id: number
                  total_posts: number
                  author: { display_name: string; avatar: string }
                }
                thread: {
                  id: number
                  title: string
                  last_post_at: string
                  total_posts: number
                  category: {
                    id: number
                    name: string
                    color: string
                  }
                }
              }) => (
                <NavLink
                  key={id}
                  to={{
                    pathname: `/app/forum/${params?.forumId}/${
                      category_id ?? thread?.category?.id
                    }/${thread?.id ? thread?.id : id}`,
                    state: {
                      categoryName: category?.name ?? thread.category.name,
                      categoryColor: category?.color ?? thread.category.color,
                    },
                  }}
                >
                  <div
                    style={{
                      color: 'white',
                      padding: '0 20px',
                      minHeight: '72px',
                      background: is_pinned
                        ? 'rgba(0,111,255,0.12)'
                        : '#283143',
                      border: '1px solid #767A822C',
                      display: 'flex',
                      position: 'relative',
                    }}
                  >
                    {is_pinned && (
                      <div
                        style={{
                          width: '16px',
                          height: '16px',
                          border: '2px solid #006FFF',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          left: '-8px',
                          top: '40%',
                        }}
                      >
                        <PushPin
                          style={{
                            width: '13px',
                            height: '13px',
                            transform: 'rotate(-45deg)',
                          }}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        flex: 1,
                        paddingBottom: '8px',
                      }}
                    >
                      <p style={{ marginBottom: '4px' }}>
                        {title ?? thread?.title}
                      </p>
                      <div
                        style={{
                          fontSize: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                        }}
                      >
                        {' '}
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            background:
                              category?.color ?? thread.category.color,
                          }}
                        />
                        {category?.name ?? thread?.category.name}
                      </div>
                      {thread?.id && (
                        <span
                          style={{
                            display: 'block',
                            marginTop: '4px',
                            fontSize: 10,
                            overflow: 'hidden',
                          }}
                        >
                          {parse(content)}
                        </span>
                      )}
                    </div>
                    <div
                      style={{
                        flexBasis: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        fontSize: 14,
                        paddingTop: '20px',
                      }}
                    >
                      {total_posts ?? thread?.total_posts ?? 'No'} Replies
                    </div>
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        flexBasis: '225px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        paddingTop: '8px',
                      }}
                    >
                      <div style={{ display: 'flex', gap: '12px' }}>
                        <Avatar>
                          {post?.author?.avatar ?? author?.avatar ? (
                            <img
                              src={`${apiUrl}/storage/module/user/avatar/${
                                post?.author?.avatar ?? author?.avatar
                              }?access_token=${token()}`}
                              alt={`Avatar ${
                                post?.author.display_name ?? author.display_name
                              }`}
                              style={{
                                width: 48,
                                height: 48,
                                objectFit: 'cover',
                                borderRadius: 20,
                              }}
                            />
                          ) : (
                            <span>
                              {getInitails(
                                post?.author?.display_name ??
                                  author.display_name,
                              )}
                            </span>
                          )}
                        </Avatar>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span>
                            {post?.author?.display_name ?? author?.display_name}
                          </span>
                          <span style={{ fontSize: '12px' }}>
                            {formatDate(last_post_at ?? thread.last_post_at)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              ),
            )}
          </div>
        </div>
      </main>

      <div
        style={{
          position: 'absolute',
          bottom: 14,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 'calc(100% - 60px)',
          height: '50px',
        }}
      >
        <Pagination
          onChange={(_, value: number) => setPage(value)}
          page={page || 1}
          color='primary'
          size='small'
          count={totalPages || 0}
          shape='rounded'
          hideNextButton
          hidePrevButton
        />
      </div>
    </div>
  )
}

export default SearchedThreads
