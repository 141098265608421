import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Divider,
  IconButton,
  Snackbar,
  TextField,
} from '@material-ui/core'
import {
  deleteOrganisation,
  instanceOrganisations,
} from '../../../../shared/services/api/instances/instances'
import Spinner from '../../../../shared/Spinner'
import CreateIcon from '@material-ui/icons/Create'
import Pagination from '@material-ui/lab/Pagination'
import { DeleteForever } from '@material-ui/icons'
import ConfirmDelete from '../../../ContentManage/ConfirmDelete'
import MuiAlert from '@material-ui/lab/Alert'

const OrganisationList = ({ instanceId, name }) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const history = useHistory()
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(true)
  const columns = ['Name', 'Users', 'Community', '']
  const [page, setPage] = useState(1)
  const [confirmDelete, setConfirmDelete] = useState(undefined)
  const [isDeleting, setDeleting] = useState(false)
  const [toastMessage, setToastMessage] = useState(undefined)
  const [searchPhrase, setSearchPhrase] = useState('')
  const getOrganisations = async (newPage) => {
    setLoading(true)
    try {
      const payload = {}

      if (newPage && newPage !== 1) payload.page = newPage
      if (searchPhrase.length > 0) payload.keyword = searchPhrase

      const params = new URLSearchParams(payload).toString()
      const response = await instanceOrganisations(
        token(),
        instanceId,
        decodeURIComponent(params),
      )
      setData(response.list || [])
      setLoading(false)
    } catch (err) {
      // setLoading(false)
    }
  }

  const handleDeleteOrganisation = async (id) => {
    setDeleting(true)
    try {
      const response = await deleteOrganisation(token(), instanceId, id)

      if (response.error) {
        setToastMessage(['error', response.message])
        setDeleting(false)
        return false
      }

      setToastMessage(['success', response.message])
      setDeleting(false)
      setConfirmDelete(undefined)

      setPage(1)
      getOrganisations()
    } catch {}
  }

  useEffect(() => {
    getOrganisations()
  }, [])

  return (
    <div className='organisation-list'>
      <div
        className='organisation-list__header'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Organisations - {name || ''}</h2>

        <Button
          onClick={() =>
            history.push(
              `/app/admin/instances/${instanceId}/organisations/create`,
            )
          }
          variant='contained'
          className='create-new'
          color='primary'
        >
          Create New Organisation
        </Button>
      </div>

      <Divider />

      {toastMessage && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setToastMessage(undefined)}
          open={toastMessage !== undefined}
        >
          <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
            {toastMessage[1]}
          </MuiAlert>
        </Snackbar>
      )}

      {confirmDelete !== undefined && (
        <ConfirmDelete
          isDeleting={isDeleting}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
          handleDeletePiece={handleDeleteOrganisation}
        />
      )}

      <div>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            setPage(1)
            getOrganisations()
          }}
        >
          <TextField
            variant='outlined'
            placeholder='Search'
            InputProps={{
              startAdornment: (
                <span style={{ fontSize: 22 }} className='icon-search' />
              ),
            }}
            value={searchPhrase}
            inputProps={{ style: { padding: 14, width: 300 } }}
            onChange={(event) => setSearchPhrase(event.target.value)}
          />
        </form>
      </div>

      {isLoading && <Spinner centerHorizontally padding={20} size={30} />}

      {!isLoading && data?.data && (
        <div className='instances-container'>
          <table>
            <thead>
              <tr>
                {columns.map((item) => (
                  <th key={item}>{item}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {data.data.map(({ name, id, users_count, chat_groups }) => (
                <tr key={id}>
                  <td>
                    <span style={{ textTransform: 'capitalize' }}>{name}</span>
                  </td>

                  <td>{users_count}</td>

                  <td>
                    <span style={{ fontWeight: 500, fontSize: 12 }}>
                      {chat_groups?.map((group) => group.name).join(', ')}
                    </span>
                  </td>

                  <td>
                    <Button
                      onClick={() =>
                        history.push(
                          `/app/admin/instances/${instanceId}/organisations/${id}`,
                        )
                      }
                      startIcon={<CreateIcon />}
                      className='edit'
                    >
                      Edit
                    </Button>

                    <IconButton
                      style={{ padding: 9, marginRight: 20, marginLeft: 10 }}
                      onClick={() => setConfirmDelete(id)}
                    >
                      <DeleteForever
                        style={{ fontSize: 18.5, color: '#ff0000' }}
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {data.data && data.data.length > 0 && (
        <Pagination
          onChange={(event, value) => {
            setPage(value)
            getOrganisations(value)
          }}
          page={page}
          count={data.last_page || 1}
          shape='rounded'
          hideNextButton
          hidePrevButton
          color='primary'
          style={{ marginTop: 20 }}
        />
      )}
    </div>
  )
}

export default OrganisationList
