import React, { useEffect, useState } from 'react'
import { accountVerification } from '../../../shared/services/api/account/account'
import PropTypes from 'prop-types'
import {
  Divider,
  Dialog,
  DialogContent,
  TextField,
  Button,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Spinner from '../../../shared/Spinner'
import { useUserContext } from '../../../context/userContext'

/**
 * AccountPopup
 * @param {boolean} isPopupOpen - is account modal open
 * @param {function} setIsPopupOpen - setting account popup open state
 * @param {function} setAuthenticated - setting authenticated state
 */

const AccountPopup = ({ setAuthenticated, isPopupOpen, setIsPopupOpen }) => {
  const {
    user: {
      user_info: { is_two_factor_enabled },
    },
  } = useUserContext()
  const [isLoading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [TwoFAToken, setTwoFAToken] = useState('')
  const [error, setError] = useState(false)
  const token = () => {
    return localStorage.getItem('access_token')
  }

  useEffect(() => {
    const app = document.querySelector('#app')
    isPopupOpen ? app.classList.add('overlay') : app.classList.remove('overlay')
  }, [isPopupOpen])

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    let payload = { password: password }

    if (is_two_factor_enabled) {
      payload.token = TwoFAToken
    }

    try {
      const userData = await accountVerification(token(), payload)
      if (userData.message === 'Invalid data') {
        setError([true, userData.message])
        setLoading(false)
        return false
      }

      document.querySelector('#app').classList.remove('overlay')
      setLoading(false)
      setAuthenticated(true)
    } catch (err) {}
  }

  return (
    <Dialog
      id='popup-blur'
      open={isPopupOpen}
      onClose={() => setIsPopupOpen(false)}
    >
      <div style={{ padding: '20px 25px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#fff',
          }}
          id='form-dialog-title'
        >
          <h3 style={{ color: '#000' }}>Password required</h3>

          <Button
            style={{ color: '#fff', padding: 0 }}
            onClick={() => setIsPopupOpen(false)}
          >
            Cancel
          </Button>
        </div>

        <Divider style={{ width: '100%', margin: '15px 0' }} />

        <DialogContent style={{ padding: 0 }}>
          <span style={{ fontSize: 13 }}>
            Your password is required to view and edit your Account Settings.
          </span>

          {error[0] && (
            <Alert
              onClose={() => {
                setError(false)
              }}
              style={{ padding: '0 16px', marginTop: 10 }}
              severity='error'
            >
              {error[1]}
            </Alert>
          )}

          <form style={{ marginTop: 10 }} onSubmit={handleSubmit}>
            <TextField
              style={{ width: '100%' }}
              onChange={(event) => setPassword(event.target.value)}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              variant='outlined'
              margin='dense'
              id='password'
              label='Password'
              type='password'
            />

            {is_two_factor_enabled && (
              <TextField
                style={{ width: '100%' }}
                onChange={(event) => setTwoFAToken(event.target.value)}
                margin='dense'
                variant='outlined'
                id='token'
                label='Token'
                type='text'
              />
            )}

            <Button
              style={{
                minWidth: 193,
                textTransform: 'capitalize',
                backgroundColor: '#006BF5',
                borderRadius: '10px',
                marginTop: '20px',
              }}
              variant='contained'
              type='submit'
              color='primary'
            >
              {isLoading ? (
                <Spinner color='#fff' size={23} border={1} />
              ) : (
                <span>Go to account settings</span>
              )}
            </Button>
          </form>
        </DialogContent>
      </div>
    </Dialog>
  )
}

AccountPopup.displayName = 'AccountPopup'

/**
 * The properties.
 * @type {Object}
 */

AccountPopup.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  setIsPopupOpen: PropTypes.func.isRequired,
  setAuthenticated: PropTypes.func.isRequired,
}

export default AccountPopup
