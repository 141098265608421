import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const getClientId = (token) =>
  client.get(apiUrl + '/api/developers-platform', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getSecretKey = (token) =>
  client.get(apiUrl + '/api/developers-platform/show-secret', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const regenerateSecretKey = (token) =>
  client.post(
    apiUrl + '/api/developers-platform/regenerate-secret',
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
