import React from 'react'
import './ClientInfo.scss'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

/**
 * ClientInfo
 * @param {string} clientId - client hash id
 * @param {string} secretKey - generated secret key
 * @param {boolean} isGenerating - is secret key being generated
 * @param {function} handleShowSecret - handling visibility of secret key
 * @param {boolean} secretShown - is secret key shown
 * @param {function} handleGenerateSecret - generating new secret key
 */

const ClientInfo = ({
  clientId,
  secretKey,
  isGenerating,
  handleShowSecret,
  secretShown,
  handleGenerateSecret,
}) => {
  return (
    <div className='client-info'>
      <h3>API Client ID and Secret Key</h3>

      <div className='data-wrapper'>
        <div className='client-id'>
          <h4>Client ID:</h4>

          <span>{clientId}</span>
        </div>

        <div className='secret-key'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              minHeight: 30,
              padding: '6px 0',
            }}
          >
            <h4>Secret Key:</h4>

            {isGenerating ? (
              <CircularProgress style={{ width: 30, height: 30 }} />
            ) : (
              <span>{secretKey}</span>
            )}
          </div>

          {!secretShown && (
            <Button onClick={handleShowSecret} variant='contained'>
              Show Secret Key
            </Button>
          )}

          {secretShown && !isGenerating && (
            <Button
              style={{ backgroundColor: '#006fff' }}
              onClick={handleGenerateSecret}
              variant='contained'
              color='primary'
            >
              Regenerate
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

ClientInfo.displayName = 'ClientInfo'

/**
 * The properties.
 * @type {Object}
 */

ClientInfo.defaultProps = {
  clientId: null,
}

ClientInfo.propTypes = {
  clientId: PropTypes.string,
  secretKey: PropTypes.string.isRequired,
  isGenerating: PropTypes.bool.isRequired,
  handleShowSecret: PropTypes.func.isRequired,
  secretShown: PropTypes.bool.isRequired,
  handleGenerateSecret: PropTypes.func.isRequired,
}

export default ClientInfo
