/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Avatar, IconButton, Tooltip } from '@material-ui/core'
import { Fragment, useEffect, useState } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useChat } from '../../../../context/chatContext'
import {
  changeNotificationSettings,
  getAllUsersForGroup,
} from '../../../../shared/services/api/chat/chat'
import { apiUrl } from '../../../../shared/config'
import { NotificationsOff } from '@mui/icons-material'
import NotificationSettingsMenu from './NotificationSettingsMenu'
import ConversationListUserModal from './ConversationListUserModal'
import orderBy from 'lodash/orderBy'

interface UserInfo {
  id: number
  display: string
  central_user_id: number
  display_name: string
  app_id: number
  organisation_name: string
  avatar: string
  last_message_at: string | null
  chat_settings: {
    chat_email_notify_interval: string
    chat_email_notify_mute: boolean
    total_unread_messages: number
  }
}

interface HoverItem {
  userId?: number
  groupId?: number
}

const ConversationList = () => {
  const {
    groupChats,
    setGroupChats,
    activeChat,
    setActiveChat,
    activeUserChat,
    setActiveUserChat,
    people,
    setPeople,
    chatOpen,
    setChatOpen,
  } = useChat()
  const [hoverChatId, setHoverChatId] = useState<null | HoverItem>(null)
  const [openSettingId, setOpenSettingId] = useState<null | HoverItem>(null)
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const [settingsAnchor, setSettingsAnchor] =
    useState<null | HTMLButtonElement>(null)
  const token = () => {
    return localStorage.getItem('access_token')
  }
  useEffect(() => {
    ;(async () => {
      if (activeChat?.id && !activeUserChat) {
        const { data } = (await getAllUsersForGroup(
          token(),
          activeChat.id,
        )) as Awaited<Promise<{ data: UserInfo[] | [] }>>

        setPeople({ list: data, currentChatId: activeUserChat || null })
      }
    })()
  }, [activeChat?.id, activeUserChat])

  const handleSetActiveChat = (chatInfo: { id: number; name: string }) => {
    setActiveChat(chatInfo)
    setActiveUserChat(null)
    setChatOpen(true)
  }

  const messageItemClass = (unreadMessages: number, id: number) =>
    `${unreadMessages > 0 ? ' item-unread' : ''}${
      id === activeChat?.id ? ' item-active' : ''
    }`

  const peopleMessageItemClass = (unreadMessages: number, id: number) =>
    `${unreadMessages > 0 ? ' item-unread' : ''}${
      id === activeUserChat?.id ? ' item-active' : ''
    }`
  const handleChangeNotificationsSettings = async (
    chatType: string,
    chatId: number,
    value: string | boolean,
  ) => {
    const isMute = (val: any) => 'boolean' === typeof val

    const data = {
      chat_id: chatId,
      chat_type: chatType,
      chat_email_notify_interval: isMute(value) ? '2 HOURS' : value,
      chat_email_notify_mute: isMute(value) && value,
    }

    const handleStateData = (state: any, stateType: string) => {
      const newState = state?.list?.find((i: any) => i[stateType] === chatId)
        ?.chat_settings
      if (!newState) return state
      if (isMute(value)) {
        newState.chat_email_notify_mute = !!value
      } else {
        newState.chat_email_notify_interval = value.toString()
        newState.chat_email_notify_mute = false
      }
      return state
    }

    if (chatType === 'direct') {
      setPeople((currentState) =>
        handleStateData(currentState, 'central_user_id'),
      )
    } else {
      setGroupChats((currentState) => handleStateData(currentState, 'id'))
    }
    await changeNotificationSettings(token(), data)
    setOpenSettingId(null)
    setSettingsAnchor(null)
  }

  const handleContextMenu = (event: any, type: string, id: number) => {
    event.preventDefault()
    setOpenSettingId({ [type]: id })
    setSettingsAnchor(event.currentTarget)
  }

  const sortedGrous = () =>
    orderBy(groupChats?.list, ['is_organisation'], ['desc'])

  const sortedPeople = () => {
    const past = new Date(0)
    const key = 'last_message_at'

    const ordered = orderBy(people?.list, ['display_name'], ['asc'])

    const sortedDates = ordered?.sort((a, b) => {
      const dateA = a[key] ? new Date(a[key]) : past
      const dateB = b[key] ? new Date(b[key]) : past
      return dateB.getTime() - dateA.getTime()
    })

    return sortedDates
  }

  const handleScroll = () => {
    setIsScrolling(true)
    setTimeout(() => setIsScrolling(false), 2000)
  }

  return (
    <div
      onScroll={() => !isScrolling && handleScroll()}
      className='chat__conversations'
    >
      <div className='chat__conversations-header'>
        <IconButton
          onClick={() => setChatOpen((current) => !current)}
          style={{ color: '#fff', transform: chatOpen ? 'rotate(180deg)' : '' }}
        >
          <ChevronLeftIcon style={{ fontSize: 28 }} />
        </IconButton>
      </div>

      <div className='chat__conversations-messages'>
        <div className='chat__conversations-messages-groups'>
          {sortedGrous()?.map(
            ({ name, initials, id, chat_settings, is_organisation }) => (
              <Fragment key={id}>
                <Tooltip arrow placement='left' title={isScrolling ? '' : name}>
                  <div
                    onContextMenu={(event) =>
                      handleContextMenu(event, 'groupId', id)
                    }
                    onMouseEnter={() => setHoverChatId({ groupId: id })}
                    onMouseLeave={() => setHoverChatId(null)}
                    className={`chat__conversations-messages-groups-item${messageItemClass(
                      0,
                      id,
                    )}`}
                    onClick={(event) => {
                      const targetTag = (event.target as HTMLElement)?.tagName
                      if (
                        openSettingId !== null &&
                        (targetTag === 'DIV' || targetTag === 'LI')
                      ) {
                        return false
                      }
                      handleSetActiveChat({ id, name })
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        style={{ height: 36, width: 36 }}
                        className='chat-avatar'
                      >
                        {initials ? (
                          <span
                            style={{
                              fontSize: 15,
                              fontWeight: 600,
                            }}
                          >
                            {initials}
                          </span>
                        ) : (
                          <span
                            style={{ fontSize: 28 }}
                            className='icon-group'
                          />
                        )}
                      </Avatar>
                    </div>

                    {chat_settings.chat_email_notify_mute ? (
                      <Tooltip
                        arrow
                        placement='top'
                        title='Conversation is muted'
                      >
                        <NotificationsOff
                          style={{
                            position: 'absolute',
                            right: 0,
                            fontSize: 20,
                            color: '#ff6570',
                            top: 0,
                          }}
                        />
                      </Tooltip>
                    ) : null}

                    {openSettingId?.groupId === id ? (
                      <NotificationSettingsMenu
                        settingsAnchor={settingsAnchor}
                        setOpenSettingId={setOpenSettingId}
                        setHoverChatId={setHoverChatId}
                        chat_email_notify_mute={
                          chat_settings.chat_email_notify_mute
                        }
                        chat_email_notify_interval={
                          chat_settings.chat_email_notify_interval
                        }
                        handleChangeNotificationsSettings={
                          handleChangeNotificationsSettings
                        }
                        chatType='group'
                        chatId={id}
                      />
                    ) : null}

                    {chat_settings?.total_unread_messages > 0 ? (
                      <span className='chat-avatar-unread'>
                        {chat_settings?.total_unread_messages}
                      </span>
                    ) : null}
                  </div>
                </Tooltip>

                {is_organisation ? (
                  <h5 style={{ textAlign: 'center', marginBottom: 6 }}>
                    Groups
                  </h5>
                ) : null}
              </Fragment>
            ),
          )}
        </div>

        <div
          className='chat__conversations-messages-people'
          style={{ marginTop: '16px' }}
        >
          <h5 style={{ textAlign: 'center' }}>People</h5>

          <Tooltip arrow title='Search all users'>
            <IconButton onClick={() => setSearchOpen(true)}>
              <span
                style={{ fontSize: 26, color: '#fff' }}
                className='icon-search'
              />
            </IconButton>
          </Tooltip>

          {searchOpen ? (
            <ConversationListUserModal setSearchOpen={setSearchOpen} />
          ) : null}

          {sortedPeople()?.map(
            ({ display_name, central_user_id, avatar, chat_settings }) => (
              <Tooltip
                arrow
                placement='left'
                title={isScrolling ? '' : display_name}
                key={central_user_id}
              >
                <div
                  key={central_user_id}
                  onContextMenu={(event) =>
                    handleContextMenu(event, 'userId', central_user_id)
                  }
                  className={`chat__conversations-messages-people-item${peopleMessageItemClass(
                    0,
                    central_user_id,
                  )}`}
                  onMouseEnter={() =>
                    setHoverChatId({ userId: central_user_id })
                  }
                  onMouseLeave={() => setHoverChatId(null)}
                  onClick={(event) => {
                    const targetTag = (event.target as HTMLElement)?.tagName
                    if (
                      openSettingId !== null &&
                      (targetTag === 'DIV' || targetTag === 'LI')
                    ) {
                      return false
                    }
                    setActiveUserChat({
                      id: central_user_id,
                      name: display_name,
                    })
                    setChatOpen(true)
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      style={{
                        height: 36,
                        width: 36,
                        backgroundColor: undefined,
                      }}
                      className='chat-avatar'
                    >
                      {avatar ? (
                        <img
                          src={`${apiUrl}/storage/module/user/avatar/${avatar}?access_token=${token()}`}
                          alt={avatar}
                          style={{
                            width: 40,
                            height: 40,
                            objectFit: 'cover',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                          }}
                        />
                      ) : (
                        <span
                          style={{
                            fontSize: 15,
                            fontWeight: 600,
                          }}
                        >
                          {display_name
                            ?.split(' ')
                            .slice(0, 2)
                            ?.map((str) => str[0])
                            ?.join('')}
                        </span>
                        // <span style={{ fontSize: 26 }} className='icon-user' />
                      )}
                    </Avatar>
                  </div>

                  {chat_settings?.chat_email_notify_mute ? (
                    <Tooltip
                      arrow
                      placement='top'
                      title='Conversation is muted'
                    >
                      <NotificationsOff
                        style={{
                          position: 'absolute',
                          right: 0,
                          fontSize: 20,
                          color: '#ff6570',
                          top: 0,
                        }}
                      />
                    </Tooltip>
                  ) : null}

                  {openSettingId?.userId === central_user_id ? (
                    <NotificationSettingsMenu
                      settingsAnchor={settingsAnchor}
                      setOpenSettingId={setOpenSettingId}
                      setHoverChatId={setHoverChatId}
                      chat_email_notify_mute={
                        chat_settings?.chat_email_notify_mute
                      }
                      chat_email_notify_interval={
                        chat_settings?.chat_email_notify_interval
                      }
                      handleChangeNotificationsSettings={
                        handleChangeNotificationsSettings
                      }
                      chatType='direct'
                      chatId={central_user_id}
                    />
                  ) : null}

                  {chat_settings?.total_unread_messages > 0 ? (
                    <span className='chat-avatar-unread'>
                      {chat_settings?.total_unread_messages}
                    </span>
                  ) : null}
                </div>
              </Tooltip>
            ),
          )}
        </div>
      </div>
    </div>
  )
}

export default ConversationList
