import { Button, CircularProgress } from '@material-ui/core'
import React from 'react'
import { me } from '../../../../../shared/services/api/me/me'

const UploadImage = ({
  ioc,
  isAdding,
  subSectionIndex,
  uploadImage,
  uploadIoc,
  avatar,
}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const iocStyle = {
    textTransform: 'capitalize',
    backgroundColor: '#006fff',
    color: '#fff',
    borderRadius: 12,
    fontSize: 12,
    padding: avatar ? '5.5px 14px' : '6px 14px',
    fontWeight: 700,
    border: 'none',
  }

  const printFiles = async (files) => {
    for (const file of files) {
      await uploadImage(file)
    }
  }

  return (
    <>
      <input
        accept={ioc ? '.csv' : 'image/*'}
        hidden
        id={`btn-file-${ioc ? 'ioc' : 'gallery'}-${
          subSectionIndex ? '-' + subSectionIndex : ''
        }`}
        type='file'
        onClick={(event) => (event.target.value = null)}
        multiple={!ioc && !avatar}
        onChange={async (event) => {
          try {
            await me(token())
            ioc
              ? uploadIoc(event.target.files[0])
              : printFiles(event.target.files)
          } catch (err) {}
        }}
      />

      <label
        style={{ display: avatar ? 'flex' : null }}
        htmlFor={`btn-file-${ioc ? 'ioc' : 'gallery'}-${
          subSectionIndex ? '-' + subSectionIndex : ''
        }`}
      >
        <Button
          style={(avatar || ioc) && iocStyle}
          variant='outlined'
          component='span'
        >
          {!isAdding && (avatar ? 'Set Avatar' : !ioc && 'Add image(s)')}

          {ioc && !isAdding && 'Upload IOC (.CSV)'}

          {(ioc || avatar) && isAdding && (
            <CircularProgress
              style={{ height: 21.5, width: 21.5, color: '#fff' }}
            />
          )}
        </Button>
      </label>
    </>
  )
}

export default UploadImage
