import { Fragment, useEffect } from 'react'
import { Route, useLocation, NavLink, useRouteMatch } from 'react-router-dom'
import './SystemSettings.scss'
import { Divider } from '@material-ui/core'
import SingleGroupSetup from './SettingsTabs/Groups/SingleGroupSetup'
import SystemSettingsList from './SystemSettingsList'
import TabWrapper from './SystemSettingsList/TabWrapper'
import SingleSector from './SettingsTabs/Sectors/SingleSector'

const SystemSettings = ({}) => {
  const groupEditPath = useRouteMatch('/app/admin/system-settings/groups/:id')
  const sectorEditPath = useRouteMatch('/app/admin/system-settings/sectors/:id')
  const settingsModules = [
    { name: 'Groups', slug: 'groups', icon: 'icon-group' },
    { name: 'Sectors', slug: 'sectors', icon: 'icon-office' },
    {
      name: 'Tags',
      slug: 'tags',
      icon: 'icon-tag_1',
      hasDivider: true,
      tabs: ['tags', 'tag-categories'],
    },
    {
      name: 'Scenarios module config',
      slug: 'scenario-configuration',
      icon: 'icon-scenario',
      hasDivider: true,
      tabs: ['types', 'frameworks'],
    },
    { name: 'Admin Users', slug: 'users', icon: 'icon-admin' },
  ]
  const location = useLocation()

  useEffect(() => {
    return () => {}
  })

  return (
    <div className='system-settings'>
      {!groupEditPath && !sectorEditPath && (
        <Route path='/app/admin/system-settings'>
          <div className='system-settings__container'>
            <div className='account-tabs__wrapper'>
              <h2>System settings</h2>

              <div className='tab-group'>
                {settingsModules.map((setting) => (
                  <Fragment key={setting.name}>
                    <NavLink
                      isActive={() =>
                        location.pathname.startsWith(
                          `/app/admin/system-settings/${setting.slug}`,
                        )
                      }
                      to={`/app/admin/system-settings/${
                        setting.slug === 'scenario-configuration'
                          ? 'scenario-configuration/types'
                          : setting.slug === 'tags'
                          ? 'tags/tags'
                          : setting.slug
                      }`}
                    >
                      <span className={setting.icon} />

                      {setting.name}
                    </NavLink>

                    {setting.hasDivider && <Divider />}
                  </Fragment>
                ))}
              </div>
            </div>

            <div className='account-tabs__container'>
              <div className='groups'>
                {settingsModules.map((module) => (
                  <Route
                    key={module.slug}
                    // exact={!module.hasDivider}
                    path={`/app/admin/system-settings/${module.slug}`}
                  >
                    {module.slug === 'knowledge-base' && (
                      <div>Knowledge base</div>
                    )}

                    {module.hasDivider && module.slug !== 'knowledge-base' && (
                      <TabWrapper
                        title={module.name}
                        tabUrl={module.slug}
                        tabs={module.tabs}
                      />
                    )}

                    {!module.hasDivider && module.slug !== 'knowledge-base' && (
                      <SystemSettingsList
                        title={module.name}
                        tabUrl={module.slug}
                      />
                    )}
                  </Route>
                ))}
              </div>
            </div>
          </div>
        </Route>
      )}

      <Route
        exact
        path='/app/admin/system-settings/groups/:id'
        component={SingleGroupSetup}
      />

      <Route
        exact
        path='/app/admin/system-settings/sectors/:id'
        component={SingleSector}
      />
    </div>
  )
}

export default SystemSettings
