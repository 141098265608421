/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Fragment } from 'react'
import HoverMenu from './HoverMenu'
import ConfirmDeleteComment from './ConfirmDeleteComment'
import EditEditor from './EditEditor'
import { formatDate } from '../../../../../../../shared/helpers/formatDate'

const Subcomments = ({
  items,
  setHoveredId,
  hoveredId,
  confirmDeleteOpen,
  setMenuHoverAnchor,
  menuHoverAnchor,
  handleCommentMenuAction,
  handleMenuHoverClose,
  editEditorOpen,
  handleEditorChange,
  editorRef,
  isEditorTouched,
  handleEditComment,
  activePiece,
  handleCloseEditors,
  setConfirmDeleteOpen,
  handleDeleteComment,
}) => {
  return items.map((subcomment) => (
    <div
      className={`subcomment${subcomment.is_deleted ? ' deleted' : ''}`}
      onMouseOver={() => setHoveredId(subcomment.id)}
      onMouseLeave={() => setHoveredId(null)}
      key={subcomment.id}
    >
      <div className='subcomment-content'>
        <div>
          <div
            style={{
              fontSize: '14px',
              color:
                subcomment.is_reported || subcomment.is_deleted
                  ? '#7f7f7f'
                  : '#283143',
              fontStyle: subcomment.is_reported ? 'italic' : 'unset',
            }}
            dangerouslySetInnerHTML={{
              __html:
                subcomment.is_reported === true
                  ? 'This comment is hidden'
                  : subcomment.content,
            }}
          />

          {subcomment.is_reported === false && (
            <div style={{ fontSize: '11px', color: '#7f7f7f' }}>
              {subcomment.author_name} -{formatDate(subcomment.created_at)}
            </div>
          )}
        </div>

        {subcomment.id === hoveredId && (
          <Fragment>
            <HoverMenu
              setMenuHoverAnchor={setMenuHoverAnchor}
              menuHoverAnchor={menuHoverAnchor}
              handleCommentMenuAction={handleCommentMenuAction}
              handleMenuHoverClose={handleMenuHoverClose}
              commentId={subcomment.id}
              canEdit={subcomment.has_access_to_update}
              canDelete={subcomment.has_access_to_delete}
              subcomment
              authorId={subcomment.author_id}
              isReported={subcomment.is_reported}
              isDeleted={subcomment.is_deleted}
            />

            <ConfirmDeleteComment
              confirmDeleteOpen={confirmDeleteOpen}
              setConfirmDeleteOpen={setConfirmDeleteOpen}
              handleDeleteComment={handleDeleteComment}
              id={subcomment.id}
            />
          </Fragment>
        )}
      </div>

      {editEditorOpen[0] && editEditorOpen[1] === subcomment.id && (
        <EditEditor
          handleEditorChange={handleEditorChange}
          editorRef={editorRef}
          isEditorTouched={isEditorTouched}
          handleEditComment={handleEditComment}
          activePiece={activePiece}
          comment={subcomment}
          handleCloseEditors={handleCloseEditors}
        />
      )}
    </div>
  ))
}

export default Subcomments
