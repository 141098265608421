import React from 'react'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const PdfSettings = ({ data }) => {
  const { handleCreateState } = useCreateManagementContext()
  const options = [
    {
      name: 'Enable Numbered Headings',
      slug: 'dev_are_numbered_headings_enabled',
    },
    {
      name: 'Include Table of Contents in PDF',
      slug: 'dev_is_table_included_in_pdf',
    },
    { name: 'Include Cover Page in PDF', slug: 'dev_pdf_cover_enabled' },
  ]

  const handleState = (slug, value) => {
    const isOutsideDevContent = true
    handleCreateState(slug, value, isOutsideDevContent)
  }

  return (
    <div className='content-section__content'>
      <Divider />

      <h5>Select options</h5>

      <FormGroup>
        {Object.values(options).map((option) => (
          <FormControlLabel
            style={{
              textTransform: 'capitalize',
              color: '#fff',
              borderRadius: 12,
              margin: 0,
            }}
            key={option.slug}
            control={
              <Checkbox
                onChange={(event, value) => handleState(option.slug, value)}
                checked={data[option.slug] ? data[option.slug] : false}
                icon={<span className='icon-checkbox-empty' />}
                checkedIcon={<span className='icon-checkbox-checked' />}
                style={{ color: '#fff', padding: 0, marginRight: 5 }}
              />
            }
            label={option.name}
          />
        ))}
      </FormGroup>
    </div>
  )
}

export default PdfSettings
