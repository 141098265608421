import { Checkbox, FormControlLabel } from '@material-ui/core'
import { moduleTypes } from '../data'

const OrganisationModuleAccess = ({ modules, handleOrganisationState }) => {
  return (
    <div
      className='edit-organisation__section'
      style={{ width: '33.3%', marginBottom: 0 }}
    >
      <div className='edit-organisation__section-content groups'>
        <h4>Module Access</h4>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {moduleTypes.map((option) => (
            <FormControlLabel
              key={option.id}
              onChange={() => handleOrganisationState('modules', option.id)}
              style={{ textTransform: 'capitalize' }}
              label={option.name}
              control={
                <Checkbox
                  color='default'
                  checked={modules.includes(option.id) || false}
                  style={{ padding: 3, color: '#006FFF' }}
                  icon={
                    <span
                      style={{ color: '#0000008a' }}
                      className='icon-checkbox-empty'
                    />
                  }
                  checkedIcon={<span className='icon-checkbox-checked' />}
                />
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default OrganisationModuleAccess
