import React from 'react'
import { Avatar, Divider } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

/**
 * Capability - create mode
 * @param {number|string} data - includes data
 */

const Capability = ({ data }) => {
  const { handleCreateState } = useCreateManagementContext()
  const levels = [1, 2, 3, 4, 5]
  const handleState = (value) => handleCreateState('dev_priority', value, true)

  return (
    <div className='content-section__content'>
      <Divider />

      <h5>Capability</h5>

      <div className='levels-container'>
        {levels.map((level) => (
          <Avatar
            key={level}
            onClick={() => handleState(level)}
            className={
              data && data === level
                ? `level-${level}`
                : `level-${level} inactive`
            }
          >
            {level}
          </Avatar>
        ))}
      </div>
    </div>
  )
}

Capability.displayName = 'Capability'

/**
 * The properties.
 * @type {Object}
 */

Capability.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([undefined]),
  ]).isRequired,
}

export default Capability
