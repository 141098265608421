import React, { useState } from 'react'
import './PdfDownloads.scss'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PdfLogs from './PdfLogs'
import TopDownloaders from './TopDownloaders'
import { Divider } from '@material-ui/core'

const PdfDownloads = () => {
  const [value, setValue] = useState('logs')

  return (
    <div className='pdf-downloads'>
      <div className='pdf-downloads__header'>
        <h2>PDF Downloads</h2>
      </div>

      <Divider />

      <Tabs
        className='tabs-container'
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
      >
        <Tab value='logs' label='Logs' />

        <Tab value='top-downloaders' label='Top Downloaders' />
      </Tabs>

      <Divider />

      <div className='tab-container'>
        {value === 'logs' && <PdfLogs />}

        {value === 'top-downloaders' && <TopDownloaders />}
      </div>
    </div>
  )
}

export default PdfDownloads
