import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const overviewImage = (token, data) =>
  client.post(apiUrl + '/api/files/image', data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
