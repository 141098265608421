import React, { useEffect, useState } from 'react'
import './AdminPanel.scss'
import '../SystemSettings/SettingsTabs/Groups/Groups.scss'
import '../SystemSettings/SettingsTabs/Sectors/Sectors.scss'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { Route, useHistory, useLocation } from 'react-router-dom'
import Logs from '../Logs'
import SystemSettings from '../SystemSettings'
import Account from '../Account'
import Instances from '../Instances'
import PropTypes from 'prop-types'
import KnowledgeBaseAdmin from '../KnowledgeBaseAdmin/KnowldegeBaseAdmin'
import KnowledgeBaseAdminArticle from '../KnowledgeBaseAdmin/KnowledgeBaseAdminArticle/KnowledgeBaseAdminArticle'
import { useUserContext } from '../../context/userContext'
import AdminContentExports from '../AdminContentExports.js/AdminContentExports.js'
import ForumConfig from '../ForumConfig/ForumConfig'
/**
 * InitialPanel
 * @param {function} handleModule - set admin module
 * @param {array} menu - available admin modules
 */

const InitialPanel = ({ handleModule, menu }) => {
  return (
    <div className='admin-panel__container'>
      <h1 className='admin-panel__heading'>ThreatMatch Admin</h1>

      <Divider
        style={{ backgroundColor: '#283143', width: '100%', margin: '40px 0' }}
      />

      <div className='admin-panel__modules'>
        {menu.map((item) => (
          <Button
            className='admin-panel__select'
            onClick={() => handleModule(item.key)}
            key={item.key}
            size='small'
            disableRipple
            color='primary'
          >
            {item.name}

            <span
              style={{
                transform: item.moduleIndex === 'apps' ? 'rotate(180deg)' : '',
              }}
              className={`${
                item.moduleIndex === 'apps'
                  ? 'icon-scenario'
                  : item.moduleIndex === 'settings'
                  ? 'icon-settings'
                  : 'icon-logs path2'
              }`}
            />
          </Button>
        ))}
      </div>
    </div>
  )
}

/**
 * AdminPanel
 */
const AdminPanel = ({}) => {
  const { user } = useUserContext()
  const history = useHistory()
  const location = useLocation()
  const [menu, setMenu] = useState([])
  useEffect(() => {
    let newMenu = [...user.menu]
    newMenu[0].key = 'instances'
    newMenu[1].key = 'system-settings/groups'
    newMenu[2].key = 'logs/login-history'
    newMenu = [
      ...newMenu,
      { name: 'Knowledge base', key: 'knowledge-base/all' },
    ]

    setMenu(newMenu)
  }, [])

  const handleModule = (item) => {
    if (item === 'settings') {
      history.push(`/app/admin/${item}/groups`)
    } else if (item === 'log') {
      history.push(`/app/admin/${item}/login-history`)
    }
    history.push(`/app/admin/${item}`)
  }

  return (
    <div className='admin-panel'>
      <Route exact path='/app/admin'>
        <InitialPanel handleModule={handleModule} menu={menu} />
      </Route>

      <Route path='/app/admin/instances' component={Instances} />

      <Route path='/app/admin/system-settings' component={SystemSettings} />

      <Route path='/app/admin/logs' component={Logs} />

      <Route
        path='/app/admin/content-exports'
        component={AdminContentExports}
      />

      <Route path='/app/admin/forum' component={ForumConfig} />

      {!location.pathname.startsWith('/app/admin/knowledge-base/article') && (
        <Route
          path='/app/admin/knowledge-base'
          component={KnowledgeBaseAdmin}
        />
      )}

      <Route
        path='/app/admin/knowledge-base/article/:id'
        component={KnowledgeBaseAdminArticle}
      />

      <Route path='/app/admin/settings' component={Account} />
    </div>
  )
}

InitialPanel.displayName = 'InitialPanel'

/**
 * The properties.
 * @type {Object}
 */

InitialPanel.propTypes = {
  handleModule: PropTypes.func.isRequired,
  menu: PropTypes.instanceOf(Array).isRequired,
}

AdminPanel.propTypes = {}

export default AdminPanel
