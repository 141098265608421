export const allModules = [
  // { name: 'All', slug: 'all' },
  { name: 'Threat Alerts', slug: 'alerts' },
  { name: 'Scenarios', slug: 'scenarios' },
  { name: 'Reports', slug: 'reports' },
  { name: 'Threat Actors', slug: 'threat-actors' },
  { name: 'Incidents', slug: 'incidents' },
  { name: 'Operations', slug: 'operations' },
  { name: 'Malware & Tools', slug: 'malware-tools' },
  // { name: 'IOCs', slug: 'ioc' },
]
