import client from '../../../http/client'

import { apiUrl } from '../../../../config/index'

const token = () => {
  return localStorage.getItem('access_token') || ''
}

export const getAdminForums = () =>
  client.get(apiUrl + `/api/admin/forum`, {
    headers: {
      Authorization: 'Bearer ' + token(),
    },
  })

export const getSingleAdminForum = (token: string, id: number | string) =>
  client.get(apiUrl + `/api/admin/forum/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const assignCategoriesToForums = ({
  forumId,
  payload,
}: {
  forumId: number | string
  payload: {
    categories_ids: number[] | string[]
  }
}) =>
  client.post(apiUrl + `/api/admin/categories/forum/${forumId}`, payload, {
    headers: {
      Authorization: 'Bearer ' + token(),
    },
  })
