import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const loginHistory = (token, page) =>
  client.get(apiUrl + `/api/logs/signings/me?page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
