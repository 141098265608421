/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Button, Dialog, Divider } from '@material-ui/core'

interface Props {
  confirmDeleteOpen: any
  setConfirmDeleteOpen: any
  handleDelete: any
  text?: any
}
const ThreadWarningModal = ({
  confirmDeleteOpen,
  setConfirmDeleteOpen,
  handleDelete,
  text,
}: Props) => {
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={!!confirmDeleteOpen}
      onClose={() => setConfirmDeleteOpen(false)}
    >
      <div style={{ padding: 24 }}>
        <h3>
          {text ||
            'Deleting this category will move all threads in this category to the General category'}
        </h3>

        <Divider
          style={{
            margin: '10px 0',
            border: 'none',
            backgroundColor: '#00000080',
          }}
        />

        <span>This action cannot be undone.</span>
      </div>

      <div
        style={{
          padding: '0 24px 16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>

        <Button
          style={{
            backgroundColor: '#FF0033',
            color: '#fff',
            textTransform: 'capitalize',
            fontWeight: 700,
            borderRadius: 12,
            padding: '6px 20px',
          }}
          onClick={() => handleDelete()}
        >
          Confirm
        </Button>
      </div>
    </Dialog>
  )
}

export default ThreadWarningModal
