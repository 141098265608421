import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import './ChangePassword.scss'
import { changePassword } from '../../../../../shared/services/api/changePassword/changePassword'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Divider } from '@material-ui/core'
import {
  strengthIndicator,
  strengthColor,
  hasUppercase,
  hasNumber,
  hasSpecial,
} from './PasswordStrength'
import PasswordStrengthComponent from './PasswordStrengthComponent'
import useNotification from '../../../../../hooks/useNotification'
import { useUserContext } from '../../../../../context/userContext'

/**
 * ChangePassword
 */

const ChangePassword = ({}) => {
  const [isLoading, setLoading] = useState(null)
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [strength, setStrength] = useState(0)
  const [color, setColor] = useState('')
  const { errorToast, successToast } = useNotification()
  const {
    setIdle,
    user: {
      user_info: { is_two_factor_enabled },
    },
  } = useUserContext()
  const [changePasswordState, setChangePasswordState] = useState({
    old_password: '',
    password: '',
    password_confirmation: '',
    token: '',
  })
  const FIELDS = [
    { name: 'Old Password', slug: 'old_password', shouldRender: true },
    { name: 'New Password', slug: 'password', shouldRender: true },
    {
      name: 'Repeat Password',
      slug: 'password_confirmation',
      shouldRender: true,
    },
    {
      name: '2 Factor Authentication token',
      slug: 'token',
      shouldRender: is_two_factor_enabled,
    },
  ]

  const handleChangePasswordState = (key, value) => {
    const currentState = { ...changePasswordState }

    currentState[key] = value
    setChangePasswordState(currentState)
  }

  const handleSubmit = async (event) => {
    const data = changePasswordState

    !is_two_factor_enabled && delete data.token
    event.preventDefault()

    try {
      setLoading(true)
      const response = await changePassword(data, token())
      response.error
        ? errorToast({
            message: response.message,
            keepOpen: true,
            forcePurify: true,
          })
        : successToast({ message: response.message })
      setStrength(0)
      setColor('')
      setLoading(false)
      !response.error && setIdle(true)
    } catch (error) {}
  }

  useEffect(() => {
    const strIndicator = strengthIndicator(changePasswordState?.password)
    setStrength(strIndicator)
    setColor(strengthColor(strIndicator))
  }, [changePasswordState?.password])

  return (
    <div className='change-password'>
      <h2>Change Your Password</h2>

      <Divider className='horizontal' />

      {isLoading ? (
        <div className='loader-container'>
          {' '}
          <CircularProgress />
        </div>
      ) : (
        <div className='change-password__inner'>
          <form onSubmit={handleSubmit}>
            {FIELDS.map(
              ({ name, slug, shouldRender }) =>
                shouldRender && (
                  <>
                    <TextField
                      key={slug}
                      onChange={(event) =>
                        handleChangePasswordState(slug, event.target.value)
                      }
                      label={name}
                      type={slug === 'token' ? 'text' : 'password'}
                      variant='outlined'
                      required
                    />

                    {slug === 'password' && (
                      <PasswordStrengthComponent
                        color={color}
                        strength={strength}
                      />
                    )}
                  </>
                ),
            )}

            <Button variant='contained' type='submit' color='primary'>
              Confirm password change
            </Button>
          </form>

          <Divider className='vertical' orientation='vertical' />

          <div className='change-password__inner-right'>
            <span>
              In order to protect your account, your password must have the
              following:
            </span>

            <div>
              {changePasswordState?.password.length >= 8 ? (
                <span className='icon-circle-check' />
              ) : (
                <span className='password-check'>X</span>
              )}

              <span>A minimum of 8 characters</span>
            </div>

            <div>
              {hasSpecial(changePasswordState?.password) ? (
                <span className='icon-circle-check' />
              ) : (
                <span className='password-check'>X</span>
              )}

              <span>A special character</span>
            </div>

            <div>
              {hasUppercase(changePasswordState?.password) ? (
                <span className='icon-circle-check' />
              ) : (
                <span className='password-check'>X</span>
              )}

              <span>An uppercase letter</span>
            </div>

            <div>
              {hasNumber(changePasswordState?.password) ? (
                <span className='icon-circle-check' />
              ) : (
                <span className='password-check'>X</span>
              )}

              <span>A number</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

ChangePassword.displayName = 'ChangePassword'

/**
 * The properties.
 * @type {Object}
 */

ChangePassword.propTypes = {}

export default ChangePassword
