import {
  Backdrop,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import React, { useState, Fragment } from 'react'
import ContentDatepicker from '../../shared/Components/ContentDatepicker'
import Gallery from '../../shared/Components/Gallery'
import CreateContentHeader from '../../shared/Components/microcomponents/CreateContentHeader'
import ConnectorsWithModal from '../../shared/Components/ConnectorsWithModal'
import AssociatedProfiles from '../../shared/Components/AssociatedProfiles'
import AssociatedAlerts from '../../shared/Components/AssociatedAlerts'
import ContentDistribution from '../../shared/Components/ContentDistribution'
import Severity from '../../shared/Components/Severity'
import SectorRelevance from '../../shared/Components/SectorRelevance'
import PdfSettings from '../../shared/Components/PdfSettings'
import Tlp from '../../shared/Components/Tlp'
import AuthorSettings from '../../shared/Components/AuthorSettings'
import Ioc from '../../shared/Components/Ioc'
import MitreTags from '../../shared/Components/MitreTags'
import { useGlobalContext } from '../../../../context/globalContext'
import AlertType from './AlertType'
import LatLong from '../../shared/Components/LatLong/LatLong'
import { useCreateManagementContext } from '../../../../context/createManagementState'
import FroalaEditorComponent from '../../../../shared/FroalaEditorComponent/FroalaEditorComponent'

const DateComponent = ({ fields, data }) => {
  return (
    <div>
      <h5>Dates</h5>

      <div className='datepicker'>
        {fields.map(({ name, label }) => (
          <ContentDatepicker key={name} data={data} name={name} label={label} />
        ))}
      </div>

      <Divider style={{ margin: '20px 0' }} />
    </div>
  )
}

const ContentSection = ({
  alertTypes,
  configuration,
  handleCompleteSections,
  handleExpandedSections,
  handleSectionStatus,
  setToastMessage,
  handleAutosave,
  isTemplateSet,
  setTemplate,
  isSaving,
}) => {
  const { isContentBeingEdited } = useGlobalContext()
  const { createContentState, handleCreateState } = useCreateManagementContext()
  configuration.forEach((section) => {
    const hasRequiredText =
      section?.headline?.split(' ')?.slice(-1)[0] === '(required)'
    if (hasRequiredText) {
      section.headline = section?.headline?.split(' ')?.slice(0, -1).join(' ')
    }
  })

  const handleChangeTemplate = async (id) =>
    handleCreateState('dev_content', [id, createContentState.dev_content], true)
  const [isLoading, setLoading] = useState(false)

  const returnDataPerSection = (section) => {
    if (isSaving) return true
    if (section === 'overview') {
      return createContentState.dev_content.overview.content.text === undefined //&& Object.keys(state.dev_content.overview.content.text)
    } else if (section === 'tags') {
      return (
        !createContentState.dev_content['tags'].content.connectors ||
        !createContentState.dev_content['tags'].content.connectors.length
      )
    } else if (section === 'dates') {
      if (createContentState.dev_additional_dates) {
        return !Object.keys(createContentState.dev_additional_dates).length
      } else return true
    } else if (section === 'severity-score') {
      return !createContentState.dev_priority
    } else if (section === 'content-distribution') {
      const isOriginOrg =
        isContentBeingEdited[1] &&
        createContentState.dev_content[section]?.content?.organisations &&
        Object.values(
          createContentState.dev_content[section]?.content?.organisations,
        ).find((org) => org.is_origin)
      return (
        !isOriginOrg &&
        createContentState.dev_content[section].is_complete === false
      )
    } else if (section === 'tlp') {
      return (
        !createContentState.dev_tlp ||
        !createContentState.dev_tlp.colour ||
        createContentState.dev_tlp.colour.length === 0
      )
    } else if (section === 'update-summary') {
      return (
        createContentState.dev_update_summary &&
        !createContentState.dev_update_summary.length
      )
    } else if (section === 'mitre-tags') {
      return (
        !createContentState.tag_tactics ||
        Object.keys(createContentState.tag_tactics).length === 0
      )
    } else if (section === 'ioc') {
      return (
        !createContentState.ioc ||
        !createContentState.ioc.data ||
        createContentState.ioc.data.length === 0
      )
    } else if (section === 'associated-profiles') {
      return (
        !createContentState.related || createContentState.related.length === 0
      )
    } else if (section === 'associated-alerts') {
      return (
        !createContentState.related_alerts ||
        createContentState.related_alerts.length === 0
      )
    } else if (section === 'assessment' || section === 'recommendation') {
      return !createContentState.dev_content[section].content.text
    } else if (section === 'sector-relevance') {
      return (
        !createContentState.relevance ||
        (createContentState.relevance &&
          createContentState.relevance.sectors &&
          createContentState.relevance.sectors.length === 0)
      )
    }
  }

  const shouldRender = ({ internal_key, is_sidebar_section }) => {
    if (
      internal_key === 'update-summary' &&
      createContentState?.published !== 1
    ) {
      return false
    }
    return (
      createContentState?.dev_content[internal_key] &&
      !is_sidebar_section &&
      internal_key !== 'title'
    )
  }

  return (
    <div className='create-alert__content-container'>
      {isLoading && (
        <Backdrop open={isLoading} style={{ zIndex: 9 }}>
          <CircularProgress />
        </Backdrop>
      )}

      <div className='create-alert__left'>
        <div className='content-title'>
          <TextField
            value={createContentState?.dev_title || ''}
            onChange={(event) =>
              handleCreateState('dev_title', event.target.value, true)
            }
            onBlur={async (event) =>
              !isSaving &&
              (await handleAutosave('titleChange', event.target.value))
            }
            placeholder='Enter content title here'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span className='label-required'>Required</span>
                </InputAdornment>
              ),
            }}
            variant='outlined'
          />
        </div>

        {configuration.map(
          (section) =>
            shouldRender(section) && (
              <div key={section.internal_key} className='content-section'>
                <CreateContentHeader
                  handleCompleteSections={handleCompleteSections}
                  handleExpandedSections={handleExpandedSections}
                  sectionData={
                    createContentState.dev_content[section.internal_key]
                  }
                  sectionSettings={section}
                  shouldBeDisabled={returnDataPerSection(section.internal_key)}
                  status={createContentState.status_id}
                  isManager={createContentState.can_publish_or_deny}
                  handleSectionStatus={handleSectionStatus}
                />

                {createContentState.dev_content[section.internal_key]
                  .is_expanded && (
                  <div className='content-section__content'>
                    {Object.entries(section.columns).map((component) => {
                      const type = component[1].type

                      return (
                        <Fragment key={component[0]}>
                          {type === 'tinymce' && (
                            <FroalaEditorComponent
                              label={component[1].label}
                              internal_key={section.internal_key}
                              textKey={component[0]}
                            />
                          )}

                          {type === 'gallery' && (
                            <Gallery
                              data={
                                createContentState.dev_content[
                                  section.internal_key
                                ]
                              }
                              internal_key={section.internal_key}
                              setLoading={setLoading}
                              isLoading={isLoading}
                              setToastMessage={setToastMessage}
                            />
                          )}

                          {type === 'datepicker' && (
                            <DateComponent
                              fields={component[1].fields}
                              data={createContentState.dev_additional_dates}
                            />
                          )}

                          {type === 'ioc' && (
                            <Ioc
                              data={createContentState.ioc || []}
                              id={createContentState.id}
                              type='alert'
                            />
                          )}

                          {type ===
                            'mitre-navigator-tags-selection-summary' && (
                            <MitreTags
                              tagTactics={createContentState.tag_tactics || []}
                              devContent={createContentState.dev_content}
                              tags={createContentState.tags || []}
                              devTags={createContentState.tags_dev || []}
                              type='alert'
                            />
                          )}

                          {type === 'connectors' && (
                            <ConnectorsWithModal
                              data={
                                createContentState.dev_content[
                                  section.internal_key
                                ]
                              }
                              internal_key={section.internal_key}
                              categories={
                                component[1].tags_search_popup_tag_categories
                              }
                              tagTactics={createContentState.tag_tactics || []}
                              devTags={createContentState.tags_dev || []}
                              devContent={createContentState.dev_content}
                              tags={createContentState.tags || []}
                              alert
                            />
                          )}

                          {type === 'associated-profiles' && (
                            <AssociatedProfiles
                              isExpanded={
                                createContentState.dev_content[
                                  section.internal_key
                                ].is_expanded
                              }
                            />
                          )}

                          {type === 'associated-alerts' && (
                            <AssociatedAlerts
                              isExpanded={
                                createContentState.dev_content[
                                  section.internal_key
                                ].is_expanded
                              }
                            />
                          )}

                          {type === 'content-distribution' &&
                            isContentBeingEdited && (
                              <ContentDistribution
                                data={
                                  createContentState.dev_content[
                                    section.internal_key
                                  ]
                                }
                                internal_key={section.internal_key}
                                type='alert'
                              />
                            )}

                          {type === 'tinymce-table-column-name-binded' &&
                            createContentState.published === 1 && (
                              <FroalaEditorComponent
                                label='Update summary'
                                internal_key={section.internal_key}
                                textKey='text'
                              />
                            )}
                        </Fragment>
                      )
                    })}
                  </div>
                )}
              </div>
            ),
        )}
      </div>

      <div className='create-alert__right'>
        <AlertType
          alertTypes={alertTypes}
          isTemplateSet={isTemplateSet}
          setTemplate={setTemplate}
          handleAutosave={handleAutosave}
          handleChangeTemplate={handleChangeTemplate}
        />

        {configuration.map(
          (section) =>
            createContentState.dev_content[section.internal_key] &&
            section.is_sidebar_section && (
              <div key={section.internal_key} className='content-section'>
                <CreateContentHeader
                  handleCompleteSections={handleCompleteSections}
                  handleExpandedSections={handleExpandedSections}
                  sectionData={
                    createContentState.dev_content[section.internal_key]
                  }
                  sectionSettings={section}
                  shouldBeDisabled={returnDataPerSection(section.internal_key)}
                  isManager={createContentState.can_publish_or_deny}
                  handleSectionStatus={handleSectionStatus}
                />

                {((createContentState.can_publish_or_deny &&
                  !createContentState.dev_content[section.internal_key]
                    .is_rejected &&
                  !createContentState.dev_content[section.internal_key]
                    .is_accepted) ||
                  (!createContentState.can_publish_or_deny &&
                    !createContentState.dev_content[section.internal_key]
                      .is_complete)) &&
                  Object.values(section.columns).map((component) => (
                    <Fragment key={component.type}>
                      {component.type === 'severity' && <Severity />}

                      {component.type === 'sector-relevance' && (
                        <SectorRelevance
                          data={{
                            sectors:
                              createContentState?.relevance?.sectors || [],
                            sector_priorities:
                              createContentState?.relevance
                                ?.sector_priorities || [],
                          }}
                          type='alert'
                        />
                      )}

                      {component.type === 'pdf-settings' && (
                        <PdfSettings
                          data={{
                            dev_are_numbered_headings_enabled:
                              createContentState.dev_are_numbered_headings_enabled,
                            dev_is_table_included_in_pdf:
                              createContentState.dev_is_table_included_in_pdf,
                            dev_pdf_cover_enabled:
                              createContentState.dev_pdf_cover_enabled,
                          }}
                          internal_key={section.internal_key}
                        />
                      )}

                      {component.type === 'tlp' && isContentBeingEdited && (
                        <Tlp internal_key={section.internal_key} />
                      )}

                      {component.type === 'author-settings' &&
                        isContentBeingEdited && (
                          <AuthorSettings
                            data={{
                              dev_hide_all_authors_data:
                                createContentState.dev_hide_all_authors_data,
                              dev_show_authors_data:
                                createContentState.dev_show_authors_data,
                              dont_show_authors:
                                createContentState.dont_show_authors,
                              author_organisation:
                                createContentState.author_organisation,
                              author_name: createContentState.author_name,
                            }}
                            internal_key={section.internal_key}
                          />
                        )}

                      {component.type === 'lat-long' && <LatLong />}
                    </Fragment>
                  ))}
              </div>
            ),
        )}
      </div>
    </div>
  )
}

export default ContentSection
