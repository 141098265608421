import { Checkbox, Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'

const OrganisationSectors = ({
  sectorsOptions,
  sectors,
  handleOrganisationState,
}) => {
  const chosenSectors = sectorsOptions.filter((i) => sectors.includes(i.id))

  return (
    <div className='edit-organisation__section'>
      <div className='edit-organisation__section-content groups'>
        <h4>Sectors</h4>

        <div className='organisation-sectors'>
          <Autocomplete
            multiple
            value={sectors || []}
            options={sectorsOptions}
            style={{ marginBottom: 15 }}
            disableClearable
            getOptionSelected={(option, value) => option.id === value}
            disableCloseOnSelect
            onChange={(event, value) => {
              if (value[value.length - 1] && value[value.length - 1].id) {
                handleOrganisationState('sectors', value[value.length - 1])
              } else {
                handleOrganisationState('sectors', value)
              }
            }}
            popupIcon={<span className='icon-down' />}
            getOptionLabel={(option) => option.name}
            renderOption={(option, props) => {
              return (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  className='sector-checkbox'
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      style={{ padding: 3, marginRight: 10, fontSize: 26 }}
                      checked={props.selected}
                      icon={<span className='icon-checkbox-empty' />}
                      checkedIcon={<span className='icon-checkbox-checked' />}
                    />

                    <span>{option.name}</span>
                  </div>
                </div>
              )
            }}
            renderTags={() => {
              return false
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  style={{ color: '#fff' }}
                  variant='outlined'
                  placeholder='Search Sectors'
                />
              )
            }}
          />

          {chosenSectors.map((sector) => (
            <Chip
              style={{
                backgroundColor: '#ffc9c9',
                color: '#000',
                margin: 2,
                fontSize: 10,
              }}
              onClick={() => {}}
              onDelete={() => {
                const newValue = chosenSectors
                  .map((i) => i.id)
                  .filter((i) => i !== sector.id)
                handleOrganisationState('sectors', newValue)
              }}
              label={sector.name}
              key={sector.id}
              deleteIcon={
                <span
                  className='icon-close'
                  style={{
                    width: 'unset',
                    height: 'unset',
                    fontSize: 14,
                    padding: '4px 4px 4px 0',
                    color: '#000',
                  }}
                />
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default OrganisationSectors
