import React, { useEffect } from 'react'
import { Divider, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const AuthorSettings = ({ data }) => {
  const { handleCreateState } = useCreateManagementContext()
  const authorOptions = [
    { name: 'Show Org Name', id: 1, shouldRender: true },
    {
      name: 'Show Author Name (author and company)',
      id: 2,
      shouldRender: !data.dont_show_authors,
    },
    {
      name: 'Display as Withheld (no org/author info)',
      id: 3,
      shouldRender: true,
    },
  ]

  useEffect(() => {
    if (
      data.dev_hide_all_authors_data === undefined &&
      data.dev_show_authors_data === undefined
    ) {
      handleState(1)
    }
  }, [data.author_organisation, data.author_name])

  const handleState = (value) => {
    const currentState = { ...data }
    currentState.dev_hide_all_authors_data = value === 3
    currentState.dev_show_authors_data = value === 2
    handleCreateState(
      'author-settings',
      {
        dev_show_authors_data: currentState.dev_show_authors_data,
        dev_hide_all_authors_data: currentState.dev_hide_all_authors_data,
      },
      true,
    )
  }

  const handleChecked = (id) => {
    if (
      data.dev_hide_all_authors_data === undefined &&
      data.dev_show_authors_data === undefined
    ) {
      return false
    }

    if (id === 1) {
      return !data.dev_hide_all_authors_data && !data.dev_show_authors_data
    } else if (id === 2) {
      return !data.dev_hide_all_authors_data && data.dev_show_authors_data
    } else if (id === 3) {
      return data.dev_hide_all_authors_data && !data.dev_show_authors_data
    }
  }

  return (
    <div className='content-section__content'>
      <Divider />

      <div>
        <h5>Select options</h5>

        <RadioGroup>
          {authorOptions.map(
            (option) =>
              option.shouldRender && (
                <FormControlLabel
                  style={{
                    textTransform: 'capitalize',
                    color: '#fff',
                    borderRadius: 12,
                    margin: '10px 0 0 0',
                  }}
                  onChange={(event) => handleState(event.target.value * 1)}
                  key={option.id}
                  value={option.id}
                  checked={handleChecked(option.id)}
                  control={
                    <Radio
                      style={{ color: '#fff', padding: 0, marginRight: 5 }}
                    />
                  }
                  label={option.name}
                />
              ),
          )}
        </RadioGroup>

        {Object.keys(data).length > 0 && (
          <div
            style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}
          >
            <span>Author displayed as:</span>

            <span
              style={{
                padding: '6px 10px',
                backgroundColor: '#506185',
                fontWeight: 700,
                borderRadius: 4,
                width: 'fit-content',
              }}
            >
              {!data.dev_hide_all_authors_data &&
                !data.dev_show_authors_data &&
                `${data.author_organisation}`}

              {!data.dev_hide_all_authors_data &&
                data.dev_show_authors_data &&
                `${data.author_name}, ${data.author_organisation}`}

              {data.dev_hide_all_authors_data &&
                !data.dev_show_authors_data &&
                'Withheld'}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default AuthorSettings
