import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const getLoginHistory = (token, payload) =>
  client.get(apiUrl + `/api/logs/signings?${payload}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getPdfLogs = (token, payload) =>
  client.get(apiUrl + `/api/logs/pdf?${payload}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getTotalDownloads = (token, payload) =>
  client.get(apiUrl + `/api/logs/pdf/top-downloaders?${payload}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getOperations = (token, payload) =>
  client.get(apiUrl + `/api/logs/operations?${payload}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getApiUsage = (token, payload) =>
  client.get(apiUrl + `/api/logs/api-usage?${payload}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getViewLogs = (token, payload) =>
  client.get(apiUrl + `/api/logs/views?${payload}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
