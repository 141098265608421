/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Chip,
  CircularProgress,
  Snackbar,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { Info, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'
import Spinner from '../../../../shared/Spinner'
import {
  singleSector,
  addSector,
  editSector,
  organisationsList,
  tagsList,
} from '../../../../shared/services/api/admin/settings/sectors'
import { Autocomplete } from '@material-ui/lab'
import debounce from 'lodash/debounce'
import { cancelRequests } from '../../../../shared/services/http/client'
import { Button } from '@mui/material'
const initialData = {
  name: '',
  organisations: [],
  tags: [],
  tagsData: [],
}

const SingleSector = () => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { id } = useParams()
  const history = useHistory()
  const isCreateNew = id === 'new'
  const titleInput = useRef(null)
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState(isCreateNew ? initialData : {})
  const [expanded, setExpanded] = useState(['select-organisations'])
  const [organisationOptions, setOrganisationOptions] = useState([])
  const [toastMessage, setToastMessage] = useState(undefined)
  const [inputValue, setInputValue] = useState('')
  const [tagsOptions, setTagsOptions] = useState([])
  const [isTagsLoading, setTagsLoading] = useState(false)
  const [isSaving, setSaving] = useState(false)

  const getOrganisations = async () => {
    let orgArray = []
    const organisations = await organisationsList(token(), id)
    organisations.list.forEach((item) => {
      item.organisations.forEach((org) => {
        org.app = item.name
        orgArray = [...orgArray, org]
      })
    })

    setOrganisationOptions(orgArray)
    setLoading(false)
  }
  const getSingleSector = async () => {
    try {
      const response = await singleSector(token(), id)
      getOrganisations()
      setData(response.data)
    } catch (err) {}
  }

  const saveSector = async (shouldClose) => {
    setSaving(true)

    try {
      let response = ''
      if (isCreateNew) {
        response = await addSector(token(), data)
      } else {
        response = await editSector(token(), id, data)
      }

      if (response.error) {
        setSaving(false)
        setToastMessage(['error', response.message])

        return false
      }

      setToastMessage(['success', response.message])
      setSaving(false)
      shouldClose && history.push('/app/admin/system-settings/sectors')
    } catch (err) {}
  }

  useEffect(() => {
    isCreateNew ? getOrganisations() : getSingleSector()

    return () => {}
  }, [])

  const handleState = (prop, value) => {
    let currentState = { ...data }

    if (prop === 'delete') {
      currentState.organisations = currentState.organisations.filter(
        (org) => org !== value,
      )
    } else {
      currentState[prop] = value
    }
    setData(currentState)
  }

  const getTagOptions = debounce(async () => {
    cancelRequests !== undefined && cancelRequests()
    setTagsLoading(true)
    try {
      const response = await tagsList(token(), inputValue)
      response.list && setTagsOptions(response.list)
      setTagsLoading(false)
    } catch (err) {}
  }, 300)

  useEffect(() => {
    inputValue === '' ? setTagsOptions([]) : getTagOptions(inputValue)
  }, [inputValue])

  const handleExpandedSections = (name) =>
    expanded.includes(name)
      ? setExpanded(expanded.filter((val) => val !== name))
      : setExpanded([...expanded, name])

  return isLoading ? (
    <Spinner center size={50} border={1} />
  ) : (
    <div className='single-sector-setup'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: 56 }} className='icon-group' />

          <h2>Sector Setup - {isCreateNew ? 'Create New' : data.name}</h2>
        </div>

        <div>
          <Button
            onClick={() => saveSector()}
            className='submit'
            disabled={isSaving}
            style={{ backgroundColor: '#6180a8', minWidth: 99 }}
          >
            {isSaving ? (
              <Spinner centerHorizontally size={22.5} color='#fff' />
            ) : (
              'Save'
            )}
          </Button>

          <Button
            onClick={() => saveSector(true)}
            className='submit'
            disabled={isSaving}
            style={{ marginLeft: 20, minWidth: 156 }}
          >
            {isSaving ? (
              <Spinner centerHorizontally size={22.5} color='#fff' />
            ) : (
              'Save & Close'
            )}
          </Button>
        </div>
      </div>

      {toastMessage && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={() => setToastMessage(undefined)}
          open={toastMessage !== undefined}
        >
          <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
            {toastMessage[1]}
          </MuiAlert>
        </Snackbar>
      )}

      <div className='single-sector-setup__content'>
        <TextField
          inputRef={titleInput}
          defaultValue={data.name || ''}
          fullWidth
          style={{ marginBottom: 20 }}
          onBlur={() => handleState('name', titleInput.current.value)}
          placeholder='Enter sector name'
          variant='outlined'
        />

        <div className='setup-section'>
          <div
            className='setup-section__header'
            style={{
              borderRadius: expanded.includes('select-organisations')
                ? '12px 12px 0px 0px'
                : 12,
            }}
            onClick={() => handleExpandedSections('select-organisations')}
          >
            {data && !data.isExpanded && <KeyboardArrowDown />}

            {data && data.isExpanded && <KeyboardArrowUp />}

            <h3 style={{ textTransform: 'capitalize' }}>
              Select organisations
            </h3>

            <Tooltip title='123123'>
              <Info style={{ fontSize: 24, margin: '0 10px' }} />
            </Tooltip>
          </div>

          {expanded.includes('select-organisations') && (
            <div className='setup-section__users'>
              <h4 style={{ marginBottom: 10 }}>Assign organisations</h4>

              <Autocomplete
                multiple
                disableClearable
                filterSelectedOptions
                groupBy={(option) => option.app}
                getOptionSelected={(option, value) => option.id === value}
                options={organisationOptions || []}
                value={data.organisations || []}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  const { key } = event

                  if (key === 'Backspace' || key === 'Delete') {
                    return false
                  }
                  const value = newValue[newValue.length - 1]
                  const newState = [...data.organisations, value.id]
                  handleState('organisations', newState)
                }}
                renderTags={() => {
                  return false
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Search organisations'
                    variant='outlined'
                    className='tag-input'
                    size='small'
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoading ? <CircularProgress size={20} /> : null}

                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              <div style={{ margin: '10px 0' }}>
                {data.organisations.map((organisation) => {
                  const currentOrg = organisationOptions.filter(
                    (org) => org.id === organisation,
                  )[0]
                  return (
                    <Chip
                      style={{
                        backgroundColor: '#ffc9c9',
                        color: '#000',
                        margin: 2,
                        fontSize: 12,
                        padding: 4,
                      }}
                      onClick={() => {}}
                      onDelete={() => handleState('delete', currentOrg.id)}
                      label={currentOrg.name}
                      key={currentOrg.id}
                      deleteIcon={
                        <span
                          className='icon-close'
                          style={{
                            width: 'unset',
                            height: 'unset',
                            fontSize: 14,
                            paddingRight: 3,
                            color: '#000',
                          }}
                        />
                      }
                    />
                  )
                })}
              </div>

              <h5 style={{ marginBottom: 10 }}>Assign tags</h5>

              <Autocomplete
                multiple
                blurOnSelect={false}
                filterSelectedOptions
                disableCloseOnSelect
                getOptionSelected={(option, value) => option.id == value.id}
                getOptionLabel={(option) => option.name || option.text}
                options={[...tagsOptions, ...data.tagsData]}
                value={data.tagsData || []}
                onChange={(event, value) => {
                  const { key } = event

                  if (key === 'Backspace' || key === 'Delete') {
                    return false
                  }

                  const currentState = { ...data }
                  const newValue = value[value.length - 1]
                  const newTag = {
                    id: newValue.id,
                    text: newValue.name,
                    pivot: { sector_id: id * 1, tag_id: newValue.id },
                  }
                  currentState.tagsData = [...currentState.tagsData, newTag]
                  currentState.tags = [...currentState.tags, newTag.id]
                  setData(currentState)
                }}
                inputValue={inputValue}
                onInputChange={(event, value, reason) => {
                  if (event && event.type === 'blur') {
                    setInputValue('') //inputValue);
                  } else if (reason !== 'reset') {
                    setInputValue(value)
                  }
                }}
                renderTags={() => {
                  return false
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Search tags'
                    variant='outlined'
                    className='tag-input'
                    size='small'
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      style: { paddingRight: 12 },
                      endAdornment: isTagsLoading ? (
                        <Spinner centerHorizontally size={20} />
                      ) : null,
                    }}
                  />
                )}
              />

              <div style={{ margin: '10px 0' }}>
                {data.tagsData.map((piece) => (
                  <Chip
                    style={{
                      backgroundColor: '#FFF4C9',
                      color: '#000',
                      margin: 2,
                      fontSize: 10,
                      height: 28,
                    }}
                    onClick={() => {}}
                    onDelete={() => {
                      const currentState = { ...data }
                      currentState.tagsData = currentState.tagsData.filter(
                        (tag) => tag.id !== piece.id,
                      )
                      currentState.tags = currentState.tags.filter(
                        (tag) => tag !== piece.id,
                      )
                      setData(currentState)
                    }}
                    label={piece.text}
                    key={piece.id}
                    deleteIcon={
                      <span
                        className='icon-close'
                        style={{
                          width: 'unset',
                          height: 'unset',
                          fontSize: 14,
                          padding: '4px 4px 4px 0',
                          color: '#000',
                        }}
                      />
                    }
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SingleSector
