/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import './Developers.scss'
import ClientInfo from './ClientInfo'
import { Divider } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import Spinner from '../../shared/Spinner'
import Button from '@material-ui/core/Button'
import {
  getSecretKey,
  regenerateSecretKey,
} from '../../shared/services/api/clientInfo/clientInfo'
import { useQueryGetApi } from '../../shared/services/queries/user/account/useQueryGetApi'

const Developers = () => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [secretKey, setSecretKey] = useState(
    'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
  )
  const [secretShown, setSecretShown] = useState(false)
  const [isGenerating, setGenerating] = useState(false)
  const history = useHistory()
  const data = useQueryGetApi()

  const handleShowSecret = async (event) => {
    setSecretShown(true)
    event.preventDefault()
    try {
      setGenerating(true)
      const response = await getSecretKey(token())

      setSecretKey(response.secret)
    } catch {}

    setGenerating(false)
  }

  const handleGenerateSecret = async (event) => {
    event.preventDefault()
    try {
      setGenerating(true)
      const response = await regenerateSecretKey(token())
      setSecretKey(response.secret)
    } catch {}

    setGenerating(false)
  }

  return (
    <div className='api'>
      <h2>API</h2>

      <Divider />

      {!data?.data?.client?.id ? (
        <Spinner centerHorizontally size={30} border={1} />
      ) : (
        <>
          <div className='api__wrapper'>
            <div className='api__warning'>
              <h4 style={{ color: '#fff', marginBottom: 10 }}>
                Security Key Notice
              </h4>

              <div>
                Obtain and store your secret key in a secure place. Activity is
                monitored and appropriate action will be taken for any mis-use.
              </div>

              <div>Read our documentation for Access Token and API usage.</div>
            </div>

            <Link
              target='_blank'
              rel='noopener noreferrer'
              to='/app/documentation'
            >
              <Button>View Documentation</Button>
            </Link>
          </div>

          <ClientInfo
            clientId={data?.data?.client?.id}
            isGenerating={isGenerating}
            secretKey={secretKey}
            secretShown={secretShown}
            handleShowSecret={handleShowSecret}
            handleGenerateSecret={handleGenerateSecret}
          />
        </>
      )}
    </div>
  )
}

export default Developers
