import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import './ForgotPassword.scss'
import { forgotPassword } from '../../../shared/services/api/login/login'
import Spinner from '../../../shared/Spinner'

const ForgotPassword = ({ setToastMessage }) => {
  const history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const usernameRef = useRef(null)
  const [username, setUsername] = useState('')

  const handleBack = () => {
    history.push('/app/auth/login')
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const data = {
        username: usernameRef.current.value,
        noAuthorization: true,
      }
      const response = await forgotPassword(data)

      if (response.error) {
        setLoading(false)
        setToastMessage(['error', response.message])
        usernameRef.current.value = ''
        return false
      }

      setLoading(false)

      history.push({
        pathname: '/app/auth/login',
        state: { message: response.message },
      })
    } catch (error) {}
  }

  return (
    <Box className='auth-panel'>
      <Typography className='headline' variant='body1'>
        Forgot password?
      </Typography>

      <form className='forgot-password-form' onSubmit={handleSubmit}>
        <TextField
          inputRef={usernameRef}
          fullWidth
          variant='outlined'
          margin='normal'
          required
          defaultValue={username}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          label='Your Username'
          name='username'
          onChange={(event) => setUsername(event.target.value)}
        />

        <Box className='form-button'>
          <Button
            color='default'
            type='submit'
            variant='contained'
            className='form-button--submit'
          >
            {isLoading ? (
              <Spinner centerHorizontally size={23} color='#fff' />
            ) : (
              <span>SUBMIT</span>
            )}
          </Button>

          <Button
            color='default'
            onClick={() => handleBack()}
            variant='contained'
            className='form-button--back'
          >
            LOGIN
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default ForgotPassword
