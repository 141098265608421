import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import './Account.scss'
import AccountTabs from './AccountTabs'
// import { account } from '../../shared/services/api/account/account'
import Spinner from '../../shared/Spinner'
import { useUserContext } from '../../context/userContext'
import { useQueryGetAccount } from '../../shared/services/queries/user/account/useQueryGetAccount'

/**
 * Account
 */

const Account = ({}) => {
  const {
    leftMenuVisible,
    user: { is_admin },
  } = useUserContext()
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(true)
  const history = useHistory()
  const location = useLocation()
  const userAccount = useQueryGetAccount()

  useEffect(() => {
    !isPopupOpen && !is_admin && history.push('/app/settings/notifications')
    !isPopupOpen && is_admin && history.push('/app/admin')
  }, [isPopupOpen])

  useEffect(() => {
    setAuthenticated(false)
  }, [location.pathname])

  return (
    <div className='account' style={{ width: leftMenuVisible ? null : '100%' }}>
      {!userAccount?.data ? (
        <Spinner center size={30} border={1} />
      ) : (
        <AccountTabs
          userData={userAccount?.data?.data}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          isAuthenticated={isAuthenticated}
          setAuthenticated={setAuthenticated}
        />
      )}
    </div>
  )
}

Account.displayName = 'Account'

/**
 * The properties.
 * @type {Object}
 */

Account.propTypes = {}

export default Account
