import React from 'react'

import './KnowledgeBaseTextContent.scss'
import TextSection from './TextSection'
import ButtonSection from './ButtonSection'
import { formatDate } from '../../../../../../shared/helpers/formatDate'

const KnowledgeBaseTextContent = ({ article }) => {
  const { title, created_at, updated_at, content } = article || {}

  const textContent = JSON.parse(content).filter(
    (template) => template.type !== 'video',
  )

  return (
    <div className='knowledge-base-article-content'>
      <h1 className='knowledge-base-article-content__title'>{title}</h1>

      <div className='knowledge-base-article-content__info'>
        <span className='knowledge-base-article-content__published-info'>
          Published: {formatDate(created_at)}
        </span>

        <span className='knowledge-base-article-content__published-info'>
          Updated: {formatDate(updated_at)}
        </span>

        <span className='knowledge-base-article-content__author-info'>
          Author: Security Alliance
        </span>
      </div>

      {textContent.map((section) =>
        section.type === 'text' ? (
          <TextSection key={section.headline} section={section} />
        ) : (
          <ButtonSection key={section.headline} section={section} />
        ),
      )}
    </div>
  )
}

export default KnowledgeBaseTextContent
