import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const addComment = (token, currentModule, id, data) =>
  client.post(apiUrl + `/api/${currentModule}/${id}/comment`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const editComment = (token, currentModule, pieceId, commentId, data) =>
  client.put(
    apiUrl + `/api/${currentModule}/${pieceId}/comment/${commentId}`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const reportComment = (token, currentModule, pieceId, data) =>
  client.post(
    apiUrl + `/api/${currentModule}/${pieceId}/comment/report`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const deleteComment = (token, currentModule, pieceId, commentId) =>
  client.remove(
    apiUrl + `/api/${currentModule}/${pieceId}/comment/${commentId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
