import { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { UAParser } from 'ua-parser-js'

import useNotification from '../../hooks/useNotification'
import { submitTicket } from '../../shared/services/api/support/support'
import { useUserContext } from '../../context/userContext'

const useSupport = () => {
  const location = useLocation()
  const history = useHistory()
  const isComingFromAskAnalyst =
    location.state && !!location.state.rfi_entity_title
  const [isTicketSent, setTicketSent] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const { leftMenuVisible } = useUserContext()
  const { successToast, errorToast } = useNotification()
  const initialFilters = () => {
    const filtersFromState = location.state
    const currentFilters = { type: '' }

    if (filtersFromState) {
      currentFilters.rfi_entity_title = filtersFromState.rfi_entity_title || ''
      currentFilters.rfi_category = filtersFromState.rfi_category || ''
      currentFilters.type = filtersFromState.type || ''
      currentFilters.redirect_url = filtersFromState.redirect_url || ''
    }

    return currentFilters
  }
  const [ticketState, setTicketState] = useState(initialFilters)
  const [priorityMenu, setPriorityMenu] = useState(null)
  const token = () => {
    return localStorage.getItem('access_token')
  }

  const handleSubmitTicket = async () => {
    setLoading(true)
    try {
      const payload = ticketState

      const parser = new UAParser()
      payload.user_browser = `${parser.getBrowser().name} ${
        parser.getBrowser().version
      }`
      // payload.user_browser_plugins =
      //   navigator.plugins || 'Not specified.'
      payload.user_screen_resolution =
        `${screen.width}x${screen.height}` || 'Not specified'
      payload.user_language = navigator.language
      payload.user_device = parser.getDevice().type ?? 'desktop'

      const formData = new FormData()

      ticketState.files &&
        [].forEach.call(ticketState.files, (file) => {
          formData.append('files[]', file)
        })

      Object.entries(payload).forEach(([key, value]) => {
        if (key !== 'files') {
          formData.append(key, value)
        }
      })

      const response = await submitTicket(token(), formData)

      if (response.error) {
        errorToast({ message: response.message })
        setLoading(false)
        return false
      }

      successToast({ message: response.message })
      setTicketSent(true)
      setLoading(false)
      setTicketState({ type: '' })

      setTimeout(() => {
        if (
          isComingFromAskAnalyst &&
          payload.type === 'rfi' &&
          payload.rfi_category === 1
        ) {
          history.push('/app/workspace')
        }
      }, 2000)
    } catch (err) {}
  }

  const handleTicketState = (prop, value) => {
    let currentState = { ...ticketState }
    const currentTicketPriority = currentState.priority

    if (prop === 'type') {
      currentState = {}
      if (value === 'rfi') {
        currentState.rfi_category = ''
      }
    } else if (prop === 'rfi_category') {
      const currentTicketType = currentState.type
      currentState = {}
      currentState.type = currentTicketType

      if (value === 3) {
        currentState.rfi_type = ''
        currentState.rfi_reason = ''
      }
    }

    if (currentTicketPriority) {
      currentState.priority = currentTicketPriority
    }

    currentState[prop] = value

    setTicketState(currentState)
  }

  return {
    handleSubmitTicket,
    handleTicketState,
    ticketState,
    isTicketSent,
    isLoading,
    leftMenuVisible,
    priorityMenu,
    setTicketSent,
    setTicketState,
    setPriorityMenu,
  }
}

export default useSupport
