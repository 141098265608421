import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import FroalaEditorComponent from '../../../../../shared/FroalaEditorComponent/FroalaEditorComponent'

const OrganisationNotes = ({
  titleInput,
  name,
  text,
  handleOrganisationState,
}) => {
  const [notes, setNotes] = useState(text)

  useEffect(() => handleOrganisationState('notes', notes), [notes])

  return (
    <div className='edit-organisation__section'>
      <div className='edit-organisation__section-content'>
        <h4>Organisation Name</h4>

        <TextField
          inputRef={titleInput}
          fullWidth
          defaultValue={name || ''}
          onBlur={(event) =>
            handleOrganisationState('name', event.target.value)
          }
          style={{ marginBottom: 20 }}
          placeholder='Enter organisation name'
          variant='outlined'
        />

        <h4>Notes</h4>

        <FroalaEditorComponent
          handleEditorChange={setNotes}
          commentText={notes}
        />
      </div>
    </div>
  )
}

export default OrganisationNotes
