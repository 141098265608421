export const handleDeleteTacticIfMissing = (currentState) => {
  const isPresent = (items, id) => items.includes(id)
  let isTacticPresent = {}

  Object.keys(currentState.tacticsNames).forEach((tacticId) => {
    //check if tactic name exists in any layer
    Object.values(currentState.mapping).forEach((layer) => {
      if (!isTacticPresent[tacticId]) {
        isTacticPresent[tacticId] = []
        isTacticPresent[tacticId] = [isPresent(layer.tactics, tacticId * 1)]
      } else {
        isTacticPresent[tacticId] = [
          ...isTacticPresent[tacticId],
          isPresent(layer.tactics, tacticId * 1),
        ]
      }
    })
  })

  Object.entries(isTacticPresent).forEach((tactic) => {
    //remove tactic if does not exist in any layer
    const id = tactic[0]
    const value = tactic[1]
    const tacticMissingInAllLayers = value.every((i) => i === false)
    if (tacticMissingInAllLayers) {
      delete currentState.tacticsNames[id]
    }
  })
  return currentState.tacticsNames
}

export const handleDeleteTechniqueIfMissing = (currentState) => {
  const isPresent = (items, id) => items.includes(id)
  let isTechniquePresent = {}

  Object.keys(currentState.techniquesNames).forEach((techniqueId) => {
    //check if technique name exists in any layer
    Object.values(currentState.mapping).forEach((layer) => {
      if (!isTechniquePresent[techniqueId]) {
        isTechniquePresent[techniqueId] = []
        isTechniquePresent[techniqueId] = [
          isPresent(Object.keys(layer.techniques), techniqueId),
        ]
      } else {
        isTechniquePresent[techniqueId] = [
          ...isTechniquePresent[techniqueId],
          isPresent(Object.keys(layer.techniques), techniqueId),
        ]
      }
    })
  })

  Object.entries(isTechniquePresent).forEach((technique) => {
    //remove technique if does not exist in any layer
    const id = technique[0]
    const value = technique[1]
    const techniqueMissingInAllLayers = value.every((i) => i === false)
    if (techniqueMissingInAllLayers) {
      delete currentState.techniquesNames[id]
    }
  })

  return currentState.techniquesNames
}

export const handleUpdateColourGradeAcrossLayers = (currentState) => {
  let techniques = {}

  Object.values(currentState.mapping).forEach((layer) => {
    Object.entries(layer.techniques).forEach((technique) => {
      const techniqueId = technique[0]
      const tacticId = technique[1]

      Object.entries(tacticId).forEach((tactic) => {
        const tacticId = tactic[0]
        // const tacticValue = tactic[1]
        const current =
          techniques[techniqueId] && techniques[techniqueId][tacticId]
        if (current) {
          techniques[techniqueId] = {
            ...techniques[techniqueId],
            [tacticId]: techniques[techniqueId][tacticId] + 1,
          }
        } else {
          techniques[techniqueId] = {
            ...techniques[techniqueId],
            [tacticId]: 1,
          }
        }
      })
    })
  })

  Object.values(currentState.mapping).forEach((layer) => {
    Object.entries(layer.techniques).forEach((technique) => {
      const techniqueId = technique[0]
      const tactics = technique[1]
      Object.entries(tactics).forEach((tactic) => {
        const tacticId = tactic[0]
        const tacticValue = tactic[1]
        if (techniques[techniqueId] && techniques[techniqueId][tacticId]) {
          tacticValue.colour_grade = techniques[techniqueId][tacticId]
        }
      })
    })
  })

  return currentState.mapping
}
