import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { isLinkValid, activate } from '../../../shared/services/api/login/login'
import {
  hasNumber,
  hasSpecial,
  hasUppercase,
  strengthColor,
  strengthIndicator,
} from '../../Account/AccountTabs/Tabs/ChangePassword/PasswordStrength'
import './ActivateAccount.scss'

const ActivateAccount = ({ setToastMessage }) => {
  const [isLoading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const history = useHistory()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { hash, id } = useParams()
  const [strength, setStrength] = useState(0)
  const [color, setColor] = useState('')
  const [isValid, setIsValid] = useState(false)

  const verifyLinkValidity = async () => {
    try {
      const response = await isLinkValid(token(), hash, id)

      if (response.error) {
        setToastMessage(['error', response.message])
        setIsValid(false)

        setTimeout(() => history.push('/app/auth/login'), 3000)
      } else {
        setIsValid(true)
      }
    } catch {}
  }

  useEffect(() => {
    verifyLinkValidity()
  }, [])

  const handleNewPassword = (event) => {
    setPassword(event.target.value)
    setStrength(strengthIndicator(event.target.value))

    const str = strengthIndicator(event.target.value)
    setColor(strengthColor(str))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const data = {
        password: password,
        password_confirmation: repeatPassword,
        noAuthorization: true,
      }

      const response = await activate(token(), hash, id, data)

      if (response.error) {
        setLoading(false)
        setToastMessage(['error', response.message])
        // usernameRef.current.value = ''
        return false
      }

      setLoading(false)
      history.push({
        pathname: '/app/auth/login',
        state: { message: response.message },
      })
    } catch (error) {}
  }

  return (
    <Container disableGutters component='main' maxWidth='xs'>
      {isValid && (
        <Box className='auth-panel'>
          <div className='activate-account'>
            <h4 style={{ fontWeight: 400, marginBottom: 10 }}>
              ThreatMatch Account Activation
            </h4>

            <form onSubmit={handleSubmit}>
              <div className='activate-account__top'>
                <span>
                  In order to protect your account, your password must have the
                  following:
                </span>

                <div>
                  {password.length >= 8 ? (
                    <span className='icon-circle-check' />
                  ) : (
                    <span className='password-check'>X</span>
                  )}

                  <span>A minimum of 8 characters</span>
                </div>

                <div>
                  {hasSpecial(password) ? (
                    <span className='icon-circle-check' />
                  ) : (
                    <span className='password-check'>X</span>
                  )}

                  <span>A special character</span>
                </div>

                <div>
                  {hasUppercase(password) ? (
                    <span className='icon-circle-check' />
                  ) : (
                    <span className='password-check'>X</span>
                  )}

                  <span>An uppercase letter</span>
                </div>

                <div>
                  {hasNumber(password) ? (
                    <span className='icon-circle-check' />
                  ) : (
                    <span className='password-check'>X</span>
                  )}

                  <span>A number</span>
                </div>
              </div>

              <h5 style={{ marginTop: 15 }}>Password</h5>

              <TextField
                onChange={handleNewPassword}
                type='password'
                variant='outlined'
                required
                fullWidth
              />

              <div className='password-strength'>
                <h5>Password strength</h5>

                <div className='password-strength__indicator'>
                  <div
                    style={{
                      backgroundColor: color,
                      width: strength * 20 + '%',
                      height: '4px',
                      transition: 'all 0.25s ease',
                    }}
                  />
                </div>

                {strength > 0 && (
                  <div
                    className='password-strength__text'
                    style={{
                      backgroundColor: color,
                      color: strength === 3 || strength === 4 ? '#000' : '#fff',
                    }}
                  >
                    {strength === 1 && 'Bad'}

                    {strength === 2 && 'Moderate'}

                    {strength === 3 && 'Could be better'}

                    {strength === 4 && 'Nice'}

                    {strength === 5 && 'Excellent!'}
                  </div>
                )}
              </div>

              <h5>Confirm Password</h5>

              <TextField
                onChange={(event) => setRepeatPassword(event.target.value)}
                type='password'
                variant='outlined'
                required
                fullWidth
              />

              <Box className='form-buttons'>
                <Button
                  style={{ minWidth: 88 }}
                  type='submit'
                  variant='contained'
                  color='primary'
                  className='form-button--submit'
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{
                        fontSize: 20,
                        color: '#fff',
                        width: 24,
                        height: 24,
                      }}
                    />
                  ) : (
                    <span>SUBMIT</span>
                  )}
                </Button>
              </Box>
            </form>
          </div>
        </Box>
      )}
    </Container>
  )
}

export default ActivateAccount
