/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { account } from '../../../api/account/account'
import { useQuery } from '@tanstack/react-query'
import { useQueryCacheState } from '../../useQueryCacheState'

const token = () => {
  return localStorage.getItem('access_token')
}

export const useQueryGetAccount = () => {
  return useQuery({
    queryKey: ['user-account'],
    queryFn: () => account(token()),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}

export const useQueryCacheGetAccount = () => useQueryCacheState('user-account')
