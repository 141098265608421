/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { Chip } from '@material-ui/core'
import DescriptionInfo from '../../shared/Components/MitreTags/DescriptionInfo'
import { KeyboardArrowDown } from '@material-ui/icons'

const TtpComponent = ({
  mitreData,
  tacticsIds,
  ttpOpen,
  setTtpOpen,
  index,
}) => {
  const [descriptionInfo, setDescriptionInfo] = useState(undefined)
  const ttpData = () => {
    let ttpsData = {}

    const getMatriceSortId = (data, matriceId) => {
      let matrice = data.matrices.filter((matrice) => matrice.id == matriceId)
      return matrice.length ? matrice[0].sort_id : 0
    }

    mitreData.techniquesNames &&
      Object.entries(mitreData.techniquesNames).forEach((tec) => {
        const techniqueId = tec[0]
        const technique = tec[1]
        let matriceId = technique.matrix_id

        let isTechniqueToBeDisplayed = false

        technique.tactics_ids &&
          technique.tactics_ids.forEach((tacticId) => {
            if (tacticsIds.indexOf(tacticId) > -1) {
              isTechniqueToBeDisplayed = true
            }
          })

        if (isTechniqueToBeDisplayed) {
          if (!ttpsData[matriceId]) {
            ttpsData[matriceId] = {
              name: mitreData.matricesNames[matriceId],
              tactics: {},
              sort_id: getMatriceSortId(mitreData, matriceId),
            }
          } else {
            ttpsData[matriceId].sort_id = getMatriceSortId(mitreData, matriceId)
          }

          technique.tactics_ids.forEach((tacticId) => {
            if (!ttpsData[matriceId].tactics[tacticId]) {
              ttpsData[matriceId].tactics[tacticId] = {
                name: mitreData.tacticsNames[tacticId],
                techniques: {},
              }
            }

            ttpsData[matriceId].tactics[tacticId].techniques[techniqueId] =
              technique
          })
        }
      })

    return ttpsData
  }

  return (
    <>
      {descriptionInfo !== undefined && (
        <DescriptionInfo
          description={descriptionInfo}
          setDescriptionInfo={setDescriptionInfo}
        />
      )}

      {Object.keys(ttpData()).length > 0 && (
        <div>
          <div
            onClick={() =>
              ttpOpen.includes(index)
                ? setTtpOpen(ttpOpen.filter((item) => item !== index))
                : setTtpOpen([...ttpOpen, index])
            }
            className='ttp-expand'
          >
            <h3>Selected TTPs</h3>

            <KeyboardArrowDown
              style={{ transform: ttpOpen.includes(index) && 'rotate(180deg)' }}
            />
          </div>

          {ttpOpen.includes(index) &&
            Object.entries(ttpData()).map((tab) => {
              const tabId = tab[0]
              const tabValue = tab[1]
              return (
                <div style={{ margin: 10 }} key={tabId}>
                  <h3 style={{ marginBottom: 10, marginTop: 10 }}>
                    {tabValue.name}
                  </h3>

                  {Object.entries(tabValue.tactics).map((tactic) => {
                    const id = tactic[0]
                    const value = tactic[1]
                    return (
                      <div
                        style={{ marginLeft: 20, marginBottom: 10 }}
                        key={id}
                      >
                        <h3 style={{ marginBottom: 5 }}>{value.name}</h3>

                        {Object.values(value.techniques).map((technique) => (
                          <Chip
                            style={{
                              backgroundColor: '#006fff',
                              color: '#fff',
                              margin: 2,
                              fontSize: 12,
                              fontWeight: 400,
                            }}
                            onClick={() => {
                              setDescriptionInfo([
                                technique.name,
                                technique.description,
                              ])
                            }}
                            label={technique.name}
                            key={technique.name}
                          />
                        ))}
                      </div>
                    )
                  })}
                </div>
              )
            })}
        </div>
      )}
    </>
  )
}

export default TtpComponent
