import { createContext, useContext, useEffect, useState } from 'react'
import { addWorkspaceHandler } from '../pages/Home/helpers'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import {
  createAnalysis,
  saveAnalysis,
} from '../shared/services/api/linkAnalysis/linkAnalysis'
import useNotification from '../hooks/useNotification'
import { useGlobalContext } from './globalContext'
import { singleAlert } from '../shared/services/api/alerts/alerts'
import { singleReport } from '../shared/services/api/reports/reports'
import { singleScenario } from '../shared/services/api/scenarios/scenarios'
import { singleProfile } from '../shared/services/api/profiles/profiles'
import { useQueryClient } from '@tanstack/react-query'

export const ViewContentContext = createContext({
  workspaces: {},
  setWorkspaces: () => {},
  addWorkspace: () => {},
  workspaceIdCount: 0,
  setWorkspaceIdCount: () => {},
  modifyWorkspaceName: () => {},
  knowledgebaseLink: 'knowledge-base',
  setKnowledgebaseLink: () => {},
  activePiece: null,
  setActivePiece: () => {},
  linkAnalysisState: {},
  setLinkAnalysisState: () => {},
  linkAnalysisOpen: false,
  setLinkAnalysisOpen: () => {},
  isLinkAnalysisModalOpen: false,
  setIsLinkAnalysisModalOpen: () => {},
  publishRegraph: () => {},
  globalSearchResult: {},
  setGlobalSearchResult: () => {},
  globalContentResult: {},
  setGlobalContentResult: () => {},
})

// eslint-disable-next-line react/prop-types
export const ViewContentContextProvider = ({ children }) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [workspaces, setWorkspaces] = useState(null)
  const [workspaceIdCount, setWorkspaceIdCount] = useState(0)
  const [knowledgebaseLink, setKnowledgebaseLink] = useState('knowledge-base')
  const [activePiece, setActivePiece] = useState(null)
  const [linkAnalysisOpen, setLinkAnalysisOpen] = useState(false)
  const [linkAnalysisState, setLinkAnalysisState] = useState({
    layout: null,
    inRange: null,
    undo: [],
    redo: [],
    currentChart: {
      items: {},
    },
  })
  const [isLinkAnalysisModalOpen, setIsLinkAnalysisModalOpen] = useState(false)
  const { handleGlobalState, globalState } = useGlobalContext()
  const { successToast, errorToast, warningToast } = useNotification()
  const [globalSearchResult, setGlobalSearchResult] = useState([])
  const [globalContentResult, setGlobalContentResult] = useState({})
  const [dirtyCreateAnalysisState, setDirtyCreateAnalysisState] = useState({
    items: linkAnalysisState.currentChart.items,
    positions: linkAnalysisState.currentChart.positions,
  })

  useEffect(() => {
    localStorage.setItem('workspaces', JSON.stringify(workspaces))
  }, [workspaces])

  const modifyWorkspaceName = (workspaceId, title) => {
    setWorkspaces((currentState) => {
      const currentWorkspace = currentState?.find(
        (space) => space.id === workspaceId,
      )

      if (!currentWorkspace) return currentState
      currentWorkspace.name = title
      return currentState
    })
  }

  const getPieceData = async (type, id) => {
    let response

    try {
      if (type === 'alert') {
        response = await singleAlert(token(), id)
      } else if (type === 'report') {
        response = await singleReport(token(), id)
      } else if (type === 'scenario') {
        response = await singleScenario(token(), id)
      } else {
        response = await singleProfile(token(), id)
      }

      if (response?.error) {
        return false
      }

      response.data.content = JSON.parse(response.data.content)
      if (response.data.read === false) response.data.read = true

      if (
        response?.data?.mitre_navigator_data?.layers &&
        (response?.data?.mitre_navigator_data?.layers?.length === 1 ||
          response?.data?.mitre_navigator_data?.layers[1]?.mode === 2)
      ) {
        response.data.mitre_navigator_data.layers[0].$isActive = true
      }

      response?.data?.mitre_navigator_data?.layers?.forEach(
        (item) => (item.$isVisible = true),
      )
      const moduleName = type === 'malware-tools' ? type : type + 's'
      await handleGlobalState(moduleName, response.data, false)
    } catch (err) {}
  }

  const addWorkspace = (type, id, name, fromDashboard, openInNewTab) => {
    let currentWorkspaces = [...workspaces]

    if (isMobile) {
      const dashboard = workspaces[0]
      currentWorkspaces = [dashboard]
    }

    if (currentWorkspaces.length < 16) {
      const doesPieceAlreadyExist = currentWorkspaces.find(
        (space) => space.currentPiece === id,
      )

      if (doesPieceAlreadyExist && doesPieceAlreadyExist.id !== 0) {
        const currentState = [...workspaces]

        currentState.forEach((w) => (w.active = false))
        currentState.find(
          (w) => w.id === doesPieceAlreadyExist.id,
        ).active = true
        setWorkspaces(currentState)
      } else {
        const globalSearchFilters =
          typeof fromDashboard === 'object' ? fromDashboard : undefined
        const newWorkspace = addWorkspaceHandler(
          type,
          id,
          name,
          fromDashboard,
          workspaceIdCount,
          globalSearchFilters,
          currentWorkspaces,
          openInNewTab,
        )
        const moduleName = type === 'malware-tools' ? type : type + 's'
        const pieceExists = globalState[moduleName]?.readItems?.find(
          (i) => i.id === id,
        )

        openInNewTab && !pieceExists && getPieceData(type, id)
        setWorkspaceIdCount(workspaceIdCount + 1)

        setWorkspaces((currentState) => {
          openInNewTab !== true &&
            currentState.forEach((item) => (item.active = false))

          const newState = [...currentState, newWorkspace]
          return newState
        })
        location.pathname !== '/app/workspace' &&
          !openInNewTab &&
          history.push('/app/workspace')
      }
    } else {
      warningToast({
        message:
          'Reached workspaces count limit. Close a workspace to open new.',
      })
    }
  }

  const resetLinkAnalysisState = () => {
    setLinkAnalysisOpen(false)
    setLinkAnalysisState({
      layout: null,
      inRange: null,
      undo: [],
      redo: [],
      currentChart: {
        items: {},
      },
    })
    const currentWorkspace = workspaces.find(
      (workspace) => workspace.state === 'analysis',
    )

    currentWorkspace.analysisId = null
    setWorkspaces(workspaces)
    localStorage.setItem('workspaces', JSON.stringify(workspaces))
  }

  const publishRegraph = async () => {
    const currentWorkspace = workspaces.find(
      (workspace) => workspace.state === 'analysis',
    )
    const currentAnalysisId = currentWorkspace.analysisId || null
    const isEditing = currentWorkspace?.edit

    try {
      let response = ''
      const payload = {
        title: linkAnalysisState.extras.analysisName || 'Link Analysis title',
        source: JSON.stringify(linkAnalysisState.currentChart.items),
        extras: JSON.stringify({
          comboLookup: linkAnalysisState.extras.comboLookup,
          groups: linkAnalysisState.extras.combos,
          layout: linkAnalysisState.extras.currentLayout,
        }),
        node_positions: JSON.stringify({
          positions: linkAnalysisState.currentChart?.positions,
        }),
      }

      if (isEditing && currentAnalysisId) {
        response = await saveAnalysis(token(), currentAnalysisId, payload)
        setDirtyCreateAnalysisState({
          items: linkAnalysisState.currentChart.items,
          positions: linkAnalysisState.currentChart.positions,
        })
        setLinkAnalysisOpen(false)
        resetLinkAnalysisState()
      } else {
        response = await createAnalysis(token(), payload)
        setDirtyCreateAnalysisState({
          items: linkAnalysisState.currentChart.items,
          positions: linkAnalysisState.currentChart.positions,
        })

        const currentWorkspace = workspaces.find(
          (workspace) => workspace.state === 'analysis',
        )

        currentWorkspace.edit = true
        currentWorkspace.analysisId = response?.data?.id
        setWorkspaces(workspaces)
      }
      queryClient.invalidateQueries(['link-analysis', { page: 1 }])

      if (response.error) {
        errorToast({ message: response.message })
        return null
      } else {
        successToast({ message: response.message })
      }

      return true
    } catch {}
  }

  const props = {
    workspaces,
    setWorkspaces,
    addWorkspace,
    workspaceIdCount,
    setWorkspaceIdCount,
    modifyWorkspaceName,
    knowledgebaseLink,
    setKnowledgebaseLink,
    activePiece,
    setActivePiece,
    linkAnalysisState,
    setLinkAnalysisState,
    linkAnalysisOpen,
    setLinkAnalysisOpen,
    isLinkAnalysisModalOpen,
    setIsLinkAnalysisModalOpen,
    publishRegraph,
    globalSearchResult,
    setGlobalSearchResult,
    globalContentResult,
    setGlobalContentResult,
    dirtyCreateAnalysisState,
    setDirtyCreateAnalysisState,
  }
  const Provider = ViewContentContext.Provider

  return <Provider value={props}>{children}</Provider>
}

export const useViewStateContext = () => useContext(ViewContentContext)
