import React, { useEffect, useState } from 'react'
import { Route, useLocation, NavLink, useParams } from 'react-router-dom'
import './SingleInstance.scss'
import '../../Account/AccountTabs/Tabs/TabList/TabList.scss'
import OrganisationList from './Tabs/OrganisationList'
import EditOrganisation from './EditOrganisation/EditOrganisation'
import { instanceSettings } from '../../../shared/services/api/instances/instances'

const SingleInstance = () => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { id } = useParams()
  const location = useLocation()
  const instanceId = id
  const [isEditMode, setEditMode] = useState(null)
  const [data, setData] = useState(undefined)
  const [isLoading, setLoading] = useState(true)
  const tabs = [
    { name: 'organisations', icon: 'icon-office' },
    // { name: 'themes', icon: 'icon-theme' },
    // { name: 'settings', icon: 'icon-settings' }
  ]

  const getInstanceSettings = async () => {
    try {
      const response = await instanceSettings(token(), instanceId)
      setData(response.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    tabs.forEach((tab) => {
      if (
        location.pathname === `/app/admin/instances/${instanceId}/${tab.name}`
      ) {
        setEditMode(false)
        getInstanceSettings()
      }
    })
  }, [location.pathname])

  return (
    <>
      {isEditMode === false && (
        <div className='single-instance'>
          <div className='account-tabs__wrapper'>
            <h2>Instances & Orgs</h2>

            <div className='tab-group'>
              {tabs.map((tab) => (
                <NavLink
                  key={tab.name}
                  isActive={() =>
                    location.pathname.startsWith(
                      `/app/admin/instances/${instanceId}/${tab.name}`,
                    )
                  }
                  to={`/app/admin/instances/${instanceId}/${tab.name}`}
                >
                  <span className={tab.icon} />
                  {tab.name}
                </NavLink>
              ))}
            </div>
          </div>

          <div className='account-tabs__container'>
            {isLoading ? (
              <div className='instance-settings' />
            ) : (
              <Route
                exact
                path={`/app/admin/instances/${instanceId}/organisations`}
              >
                <OrganisationList name={data.name} instanceId={instanceId} />
              </Route>
            )}
          </div>
        </div>
      )}

      <Route path={`/app/admin/instances/${instanceId}/organisations/:id`}>
        <EditOrganisation instanceId={instanceId} setEditMode={setEditMode} />
      </Route>
    </>
  )
}

export default SingleInstance
