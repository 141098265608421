/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/prop-types */
import { Button, Dialog, Divider, TextField } from '@material-ui/core'
import { Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { login, login2FA } from '../../../shared/services/api/login/login'
import MuiAlert from '@material-ui/lab/Alert'
import Spinner from '../../../shared/Spinner'
import { useUserContext } from '../../../context/userContext'
import { useMsal } from '@azure/msal-react'

const IdleModal = ({ isIdle, setIdle }) => {
  const {
    user: {
      user_info: { username, is_two_factor_enabled, sso_provider },
    },
  } = useUserContext()
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [token, setToken] = useState('')
  const [toastMessage, setToastMessage] = useState(undefined)
  const [twoFaStep, setTwoFaStep] = useState(false)
  const { instance } = useMsal()
  const [azureAccessToken, setAzureAccessToken] = useState('')
  const isLoggedInViaMicrosoft = !!sso_provider

  const handleSignInSSO = async () => {
    const response = await instance.loginPopup({
      scopes: ['User.Read'],
    })
    setAzureAccessToken(response.accessToken)
    if (is_two_factor_enabled) {
      setTwoFaStep(true)
    } else {
      setLocalStorage(response)
      setIdle(false)
    }
  }

  useEffect(() => {
    localStorage.removeItem('auth')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('expires_in')
  }, [])

  const setLocalStorage = (response) => {
    localStorage.setItem('auth', 'true')
    localStorage.setItem('access_token', response.access_token)
    localStorage.setItem('refresh_token', response.refresh_token)
    localStorage.setItem('expires_in', response.expires_in)
    localStorage.setItem(
      'refresh_token_expire_in',
      Math.round(new Date() / 1000) + response.refresh_token_expire_in,
    )
  }

  const timeoutLogin = async () => {
    setIsLoading(true)
    try {
      const data = {
        noAuthorization: true,
        username: username,
        password: password,
      }
      const response = await login(data)

      if (response.error) {
        setToastMessage(response.message)
        setIsLoading(false)
        return false
      }

      if (response.is_two_factor_enabled) {
        setTwoFaStep(true)
        setIsLoading(false)
      } else {
        setLocalStorage(response)
        setIdle(false)
      }
    } catch (err) {}
  }

  const timeoutTwoStep = async () => {
    setIsLoading(true)
    try {
      let response

      if (isLoggedInViaMicrosoft) {
        response = await login2FA({
          token: token,
          noAuthorization: true,
          sso_access_token: azureAccessToken,
          sso_provider: 'azure',
        })
      } else {
        response = await login2FA({
          noAuthorization: true,
          username: username,
          password: password,
          token: token,
        })
      }

      if (response.error) {
        setIsLoading(false)
        setToastMessage(response.message)
        return false
      }

      setLocalStorage(response)
      setIsLoading(false)
      setIdle(false)
    } catch (error) {}
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    twoFaStep ? timeoutTwoStep() : timeoutLogin()
  }

  return (
    <Dialog className='idle-modal' style={{ zIndex: 9999 }} open={isIdle}>
      <div style={{ padding: '20px 25px' }}>
        <h3>Still there?</h3>

        <Divider style={{ margin: '15px 0' }} />

        <div>
          You have been logged out due to inactivity.{' '}
          {isLoggedInViaMicrosoft
            ? 'Please log in again to continue'
            : 'Please enter your password to continue.'}
        </div>

        {toastMessage ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={3000}
            onClose={() => setToastMessage(undefined)}
            open={toastMessage !== undefined}
          >
            <MuiAlert variant='filled' severity='error'>
              <span>{toastMessage}</span>
            </MuiAlert>
          </Snackbar>
        ) : null}

        {isLoggedInViaMicrosoft && !twoFaStep ? (
          <Button
            style={{
              textTransform: 'capitalize',
              backgroundColor: '#006BF5',
              borderRadius: '10px',
              marginTop: '20px',
              minWidth: 81,
              color: '#fff  ',
            }}
            type='button'
            variant='contained'
            className='form-button--submit'
            onClick={handleSignInSSO}
          >
            LOGIN WITH MICROSOFT
          </Button>
        ) : (
          <div>
            <form style={{ marginTop: 10 }} onSubmit={handleSubmit}>
              {!twoFaStep && (
                <TextField
                  style={{ width: '100%' }}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  autoFocus
                  variant='outlined'
                  margin='dense'
                  placeholder='Password'
                  type='password'
                />
              )}

              {is_two_factor_enabled && twoFaStep ? (
                <>
                  <h3>Two-Factor Authentication required</h3>

                  <span>
                    Please use Google Authentication Mobile App installed on
                    your mobile phone and type the token below.
                  </span>

                  <TextField
                    style={{ width: '100%' }}
                    value={token}
                    autoFocus
                    onChange={(event) => setToken(event.target.value)}
                    margin='dense'
                    variant='outlined'
                    placeholder='Token'
                    type='text'
                  />
                </>
              ) : null}

              <Button
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: '#006BF5',
                  borderRadius: '10px',
                  marginTop: '20px',
                  minWidth: 81,
                }}
                disabled={
                  (is_two_factor_enabled &&
                    password.length === 0 &&
                    token.length === 0) ||
                  (!is_two_factor_enabled && password.length === 0)
                }
                variant='contained'
                type='submit'
                color='primary'
              >
                {isLoading ? (
                  <Spinner size={22.5} border={1} color='#fff' />
                ) : (
                  <span>Submit</span>
                )}
              </Button>
            </form>
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default IdleModal
