import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from '@material-ui/core'
import { tlpColours } from '../data'

const TlpModal = ({
  tlpDialogOpen,
  setTlpDialogOpen,
  tlp_state,
  titleRefs,
  descriptionRefs,
  handleOrganisationState,
}) => {
  return (
    <Dialog
      open={tlpDialogOpen}
      onClose={() => setTlpDialogOpen(false)}
      maxWidth='sm'
      fullWidth
    >
      <h2 style={{ padding: '16px 24px' }}>Define TLP Classification</h2>

      <Divider style={{ margin: '0 24px' }} />

      <DialogContent>
        {tlpColours.map(({ color, name, colour_name }) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              marginBottom: 20,
            }}
            key={name}
          >
            <div
              style={{
                border: `2px solid ${color}`,
                height: 105,
                width: 30,
                borderRadius: 8,
                marginRight: 15,
                backgroundColor: name === 'clear' ? '#fff' : `${color}`,
              }}
              className='column-left'
            />

            <div
              className='column-right'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: 'calc(100% - 34px)',
              }}
            >
              <h4 style={{ textTransform: 'capitalize' }}>{colour_name}</h4>

              <TextField
                inputRef={(element) => (titleRefs.current[name] = element)}
                style={{ margin: '15px 0' }}
                fullWidth
                inputProps={{ style: { padding: 14 } }}
                defaultValue={tlp_state[name]?.title || ''}
                placeholder={`${colour_name} Title Org`}
                variant='outlined'
              />

              <TextField
                inputRef={(element) =>
                  (descriptionRefs.current[name] = element)
                }
                fullWidth
                inputProps={{ style: { padding: 14 } }}
                defaultValue={tlp_state[name]?.description || ''}
                placeholder={`${colour_name} description`}
                variant='outlined'
              />
            </div>
          </div>
        ))}
      </DialogContent>

      <DialogActions style={{ padding: '0 24px 16px 24px' }}>
        <Button
          style={{
            textTransform: 'capitalize',
            backgroundColor: '#006bf5',
            fontWeight: 700,
            color: '#fff',
            borderRadius: 12,
            padding: '6px 18px',
          }}
          onClick={() => {
            handleOrganisationState('tlp_classifications')
            setTlpDialogOpen(false)
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TlpModal
