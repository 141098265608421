/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Avatar, Button, InputAdornment, TextField } from '@material-ui/core'
import parse from 'html-react-parser'
import { PushPin } from '@mui/icons-material'
import { Pagination } from '@mui/material'
import { useEffect, useState } from 'react'
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { apiUrl } from '../../../shared/config'
import { formatDate } from '../../../shared/helpers/formatDate'
import { useQueryGetForumThreads } from '../../../shared/services/queries/forum/useQueryGetThreads'

const token = () => {
  return localStorage.getItem('access_token')
}

const Threads = () => {
  const params: { forumId: string; categoryId: string } = useParams()
  const history = useHistory()
  const location: {
    state: { categoryName: string; categoryColor: string; forumName: string }
  } = useLocation()
  const [searchPhrase, setSearchPhrase] = useState('')

  const [page, setPage] = useState(1)
  const threads = useQueryGetForumThreads(
    Number(params?.forumId),
    Number(params?.categoryId),
    page,
  )
  const totalPages = Math.ceil(
    threads.data?.data?.threads?.total / threads.data?.data?.threads?.perPage,
  )
  const totalThreads = threads.data?.data?.threads?.total

  const getInitails = (name: string) => {
    if (!name) return null

    const initials = []
    const fullDisplayName = name?.split(' ')
    initials[0] = fullDisplayName[0].at(0)
    initials[1] = fullDisplayName[1].at(0)

    return initials.join('')
  }

  useEffect(() => {
    if (location.state?.categoryName) {
      localStorage.setItem('categoryName', location.state.categoryName)
      localStorage.setItem('categoryColor', location.state.categoryColor)
    }
  }, [params.categoryId])

  return (
    <div className='forum__main'>
      <header className='forum__header'>
        <div
          style={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <NavLink
            to={{
              pathname: `/app/forum/${params?.forumId}`,
              state: {
                forumName: location?.state?.forumName,
              },
            }}
          >
            <h2
              style={{
                textDecoration: 'underline',
                fontSize: '18px',
                color: '#fff',
                fontWeight: 400,
              }}
            >
              Categories
            </h2>
          </NavLink>

          <span>&gt;</span>
          <div
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <div
              style={{
                width: '12px',
                height: '12px',
                background:
                  location.state?.categoryColor ||
                  (localStorage.getItem('categoryColor') as string),
              }}
            />
            <span>
              {location.state?.categoryName ||
                localStorage.getItem('categoryName')}
            </span>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <form
              onSubmit={(event) => {
                event.preventDefault()
                history.push({
                  pathname: `/app/forum/${params?.forumId}/search/${searchPhrase}`,
                  state: {
                    searchPhrase,
                  },
                })
              }}
            >
              <TextField
                fullWidth
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                value={searchPhrase}
                onChange={(event) => {
                  setSearchPhrase(event.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <span
                        className='icon-search'
                        style={{ color: '#fff', fontSize: 28 }}
                      />
                    </InputAdornment>
                  ),
                }}
                style={{ color: '#fff' }}
                placeholder='Search'
                variant='standard'
              />
            </form>
          </div>
          <Button
            onClick={() => {
              history.push({
                pathname: '/app/forum/thread-new',
                state: {
                  forumId: params?.forumId,
                  category: params?.categoryId,
                },
              })
            }}
            style={{
              textTransform: 'capitalize',
              background: '#006fff',
              color: '#fff',
              maxHeight: 36,
              borderRadius: '12px',
              minWidth: 160,
            }}
          >
            Create New Thread
          </Button>
        </div>
      </header>
      <main className='forum__main-content'>
        <div className='forum__threads'>
          <div className='forum__threads-row' style={{ marginTop: '12px' }}>
            <div style={{ flex: 1, fontWeight: 'bold' }}>Thread</div>
            <div
              style={{
                flexBasis: '100px',
                display: 'flex',
                fontWeight: 'bold',
                justifyContent: 'center',
              }}
            >
              Stats
            </div>
            <div
              style={{
                flexBasis: '225px',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              Last post
            </div>
          </div>
          <div className='forum__threads-cards'>
            {threads?.data?.data?.threads?.items?.map(
              (thread: {
                id: number
                title: string
                total_posts: number
                last_post_at: string
                is_pinned: boolean
                post: {
                  content: string
                }
                lastPost: {
                  author: {
                    display_name: string
                    avatar: string
                  }
                }
              }) => (
                <NavLink
                  key={thread.id}
                  to={{
                    pathname: `/app/forum/${params?.forumId}/${params?.categoryId}/${thread.id}`,
                    state: {
                      categoryName: location?.state?.categoryName,
                      categoryColor: location?.state?.categoryColor,
                    },
                  }}
                >
                  <div
                    key={thread.id}
                    style={{
                      color: 'white',
                      padding: '0 20px',
                      height: '72px',
                      background: thread.is_pinned
                        ? 'rgba(0,111,255,0.12)'
                        : '#283143',
                      border: '1px solid #767A822C',
                      display: 'flex',
                      position: 'relative',
                      alignItems: 'center',
                    }}
                  >
                    {thread.is_pinned && (
                      <div
                        style={{
                          width: '16px',
                          height: '16px',
                          border: '1px solid #006FFF',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          left: '-11px',
                          top: 26,
                          borderRadius: 5,
                          padding: 2,
                        }}
                      >
                        <PushPin
                          style={{
                            width: '15px',
                            height: '15px',
                            transform: 'rotate(-45deg)',
                          }}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        flex: 1,
                        width: '60%',
                        maxHeight: 50,
                        overflow: 'hidden',
                      }}
                    >
                      <p style={{ margin: 0, color: '#fff' }}>
                        {thread?.title && parse(thread?.title)}
                      </p>

                      <span className='forum__threads-cards-content'>
                        {thread?.post?.content && parse(thread?.post?.content)}
                      </span>
                    </div>
                    <div className='forum__threads-cards-right'>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          minWidth: 'max-content',
                          fontSize: 14,
                        }}
                      >
                        {thread?.total_posts} Replies
                      </div>
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          flexBasis: '225px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '12px',
                          }}
                        >
                          <Avatar>
                            {thread.lastPost.author.avatar ? (
                              <img
                                src={`${apiUrl}/storage/module/user/avatar/${
                                  thread.lastPost.author.avatar
                                }?access_token=${token()}`}
                                alt={`Avatar ${thread.lastPost.author.display_name}`}
                                style={{
                                  width: 48,
                                  height: 48,
                                  objectFit: 'cover',
                                  borderRadius: 20,
                                }}
                              />
                            ) : thread.lastPost.author.display_name ? (
                              <span>
                                {getInitails(
                                  thread.lastPost.author.display_name,
                                )}
                              </span>
                            ) : (
                              <span
                                style={{
                                  display: 'flex',
                                  paddingLeft: '8px',
                                  fontSize: '8px',
                                }}
                              >
                                User deleted
                              </span>
                            )}
                          </Avatar>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <span style={{ fontWeight: 600, fontSize: 14 }}>
                              {thread.lastPost.author.display_name}
                            </span>
                            <span style={{ fontSize: 10, fontWeight: 400 }}>
                              {formatDate(thread.last_post_at)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              ),
            )}
          </div>
        </div>
      </main>

      <div
        style={{
          bottom: 14,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: '50px',
        }}
      >
        <Pagination
          onChange={(_, value: number) => setPage(value)}
          page={page || 1}
          color='primary'
          size='small'
          count={totalPages || 0}
          shape='rounded'
          hideNextButton
          hidePrevButton
        />

        <h5 style={{ color: '#fff' }}>Total results: {totalThreads || ''}</h5>
      </div>
    </div>
  )
}

export default Threads
