import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Dialog, DialogContent, TextField } from '@material-ui/core'
import Button from '../../../shared/components/Button'
import { useViewStateContext } from '../../../context/viewStateContext'

const Modal = ({
  isModalOpen,
  setIsModalOpen,
  analysisName,
  setAnalysisName,
}) => {
  const { setLinkAnalysisState, publishRegraph } = useViewStateContext()

  const handleInputChange = (event) => {
    setAnalysisName(event.target.value)
    setLinkAnalysisState((current) => ({
      ...current,
      extras: {
        ...current.extras,
        analysisName: event.target.value,
      },
    }))
  }

  const handleClick = async () => {
    const response = await publishRegraph()
    response && setIsModalOpen(false)
    setAnalysisName('')
  }

  return (
    <Dialog
      id='popup-blur'
      open={!!isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <div style={{ width: '500px' }}>
        <DialogContent style={{ padding: 0 }}>
          <div
            style={{
              margin: '30px 20px 10px 20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{ fontSize: '14px', fontWeight: '700', color: '#212935' }}
            >
              Name
            </div>

            <TextField
              style={{ width: '100%' }}
              onChange={handleInputChange}
              value={analysisName}
              margin='dense'
              variant='outlined'
              id='token'
              placeholder='Name'
              type='text'
            />

            <Divider style={{ width: '100%', marginTop: 30 }} />

            <Button
              className='modal-btn'
              disabled={!analysisName}
              onClick={handleClick}
              type='button'
              background='green'
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

Modal.defaultProps = {}

Modal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
}

Modal.displayName = 'Modal'

export default Modal
