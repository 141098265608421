/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Snackbar,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import Pagination from '@material-ui/lab/Pagination'
import { Stack } from '@mui/material'
import { DataGrid, GridOverlay } from '@mui/x-data-grid'
import React, { useState, useEffect, useRef } from 'react'
import { formatIoc } from '../../../../../shared/helpers/formatDate'
import { preview } from '../../../../../shared/services/api/createContent/createAlert'
import { iocCollection } from '../../../../../shared/services/api/ioc/ioc'
import ModuleListing from '../../../../ModuleListing'
import Content from '../../../../SinglePiece/PieceContent/PieceTabs/TabContent/Content'
import PreviewHeading from './PreviewHeading'
import Spinner from '../../../../../shared/Spinner'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'

/**
 * Preview
 * @param {object} isPreviewOpen - is preview modal open
 * @param {function} setPreviewOpen - handling preview modal state
 * @param {number} id - piece id
 * @param {object} configuration - modules configuration
 * @param {string} type - piece type
 */

const Preview = ({
  isPreviewOpen,
  setPreviewOpen,
  id,
  configuration,
  type,
}) => {
  const [data, setData] = useState(undefined)
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [currentTab, setCurrentTab] = useState('content')
  const [isFetching, setFetching] = useState(false)
  const [toastMessage, setToastMessage] = useState(null)
  const searchRef = useRef(null)

  const columns = [
    {
      field: 'value',
      headerName: 'Data',
      editable: false,
      sortable: false,
      flex: 0.9,
      renderHeader: () => {
        return (
          <div
            className='sortable-header'
            onClick={() => saveSortBeforeFetch('value')}
          >
            <span>Data</span>

            {data.ioc.sortBy === 'value' && data.ioc.sortOrder === 'asc' && (
              <KeyboardArrowDown />
            )}

            {data.ioc.sortBy === 'value' && data.ioc.sortOrder === 'desc' && (
              <KeyboardArrowUp />
            )}
          </div>
        )
      },
      renderCell: (params) => <span>{params.value}</span>,
    },
    {
      flex: 0.1,
      field: 'type',
      headerName: 'Type',
      editable: false,
      sortable: false,
      renderHeader: () => {
        return (
          <div
            className='sortable-header'
            onClick={() => saveSortBeforeFetch('type')}
          >
            <span>Type</span>

            {data.ioc.sortBy === 'type' && data.ioc.sortOrder === 'asc' && (
              <KeyboardArrowDown />
            )}

            {data.ioc.sortBy === 'type' && data.ioc.sortOrder === 'desc' && (
              <KeyboardArrowUp />
            )}
          </div>
        )
      },
      // renderCell: params => <span>{params.value}</span>
    },
    {
      field: 'date',
      flex: 0.1,
      headerName: 'Date',
      minwidth: 80,
      editable: false,
      sortable: false,
      renderHeader: () => {
        return (
          <div
            className='sortable-header'
            onClick={() => saveSortBeforeFetch('date')}
          >
            <span>Date</span>

            {data.ioc.sortBy === 'date' && data.ioc.sortOrder === 'asc' && (
              <KeyboardArrowDown />
            )}

            {data.ioc.sortBy === 'date' && data.ioc.sortOrder === 'desc' && (
              <KeyboardArrowUp />
            )}
          </div>
        )
      },
      renderCell: (params) => <span>{formatIoc(params.value)}</span>,
    },
    {
      flex: 0.4,
      field: 'comment',
      headerName: 'Comments',
      minwidth: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          <span>{params.value}</span>
        </div>
      ),
    },
  ]

  const saveSortBeforeFetch = (sortBy) => {
    let currentState = { ...data }
    const payload = {}
    if (sortBy) {
      if (!data.ioc.sortBy || sortBy !== data.ioc.sortBy) {
        payload.sortOrder = 'asc'
        payload.sortBy = sortBy
      } else if (data.ioc.sortOrder === 'asc') {
        payload.sortOrder = 'desc'
        payload.sortBy = sortBy
      } else if (data.ioc.sortOrder === 'desc') {
        delete payload.sortOrder
        delete payload.sortBy
      }
    }

    if (payload.sortBy) {
      currentState.ioc.sortBy = payload.sortBy
    } else {
      delete currentState.ioc.sortBy
    }
    if (payload.sortOrder) {
      currentState.ioc.sortOrder = payload.sortOrder
    } else {
      delete currentState.ioc.sortOrder
    }

    if (data.ioc.search) {
      payload.keyword = data.ioc.search
    }

    setData(currentState)

    setTimeout(() => {
      requestSearch(undefined, payload)
    })
  }

  const requestSearch = async (pageValue, sortPayload) => {
    setFetching(true)
    data.ioc.data = []
    try {
      let currentState = { ...data }
      let payload = {}
      if (sortPayload) {
        payload = sortPayload
      }

      if (pageValue && pageValue !== 'search') {
        payload.page = pageValue
      }

      if (data.ioc.search && searchRef.current.value.length > 0) {
        payload.keyword = searchRef.current.value
      }

      if (searchRef.current.value.length === 0) {
        delete payload.keyword
      }

      if (data.ioc.sortBy) {
        payload.sortBy = data.ioc.sortBy
      }

      if (data.ioc.sortOrder) {
        payload.sortOrder = data.ioc.sortOrder
      }

      const query = new URLSearchParams(payload).toString()
      type = type.slice(0, -1)
      const response = await iocCollection(token(), type, data.id, query)

      if (payload.keyword) {
        currentState.ioc.search = payload.keyword
      } else {
        searchRef.current.value = ''
      }
      if (payload.sortBy) {
        currentState.ioc.sortBy = payload.sortBy
      }
      if (payload.sortOrder) {
        currentState.ioc.sortOrder = payload.sortOrder
      }
      data.ioc.current_page = response.current_page
      data.ioc.total = response.total
      data.ioc.last_page = response.last_page
      if (response.data.length === 0) {
        data.ioc.data = null
      } else {
        data.ioc.data = response.data
      }

      setFetching(false)
    } catch (err) {
      setFetching(false)
    }
  }

  const getPreviewData = async () => {
    try {
      const response = await preview(token(), type, id)
      if (response.error) {
        setToastMessage(['error', response.message])
        return false
        // setData({})
      }

      response.data.dev_content = JSON.parse(response.data.dev_content)
      if (
        response.data.mitre_navigator_data.layers &&
        response.data.mitre_navigator_data.layers[1] &&
        response.data.mitre_navigator_data.layers[1].mode === 2
      ) {
        response.data.mitre_navigator_data.layers[0].$isActive = true
      }
      response.data.mitre_navigator_data.layers &&
        response.data.mitre_navigator_data.layers.forEach(
          (item) => (item.$isVisible = true),
        )
      setData(response.data)
    } catch (err) {}
  }

  useEffect(() => {
    getPreviewData()
  }, [])

  const updateMitre = (mitreData) => {
    const currentState = { ...data }
    currentState.mitre_navigator_data.layers = mitreData
    setData(currentState)
  }

  return (
    <Dialog fullWidth className='preview' maxWidth='lg' open={isPreviewOpen}>
      <DialogTitle>
        {data && (
          <span style={{ textTransform: 'capitalize' }}>
            {type === 'profiles' ? data.typeName : type.slice(0, -1)} preview
          </span>
        )}
      </DialogTitle>

      {toastMessage !== null && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setToastMessage(null)}
          open={toastMessage !== null}
        >
          <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
            {toastMessage[1]}
          </MuiAlert>
        </Snackbar>
      )}

      <Divider style={{ margin: '0 24px' }} />

      <DialogContent style={{ marginTop: 20, padding: 0 }}>
        {data ? (
          <div className='single-piece__details'>
            <PreviewHeading data={data} />

            <Divider style={{ marginTop: 20 }} />

            <div className='single-piece__tabs'>
              <div
                className='single-piece__tabs-inner'
                style={{ width: '95%' }}
              >
                <Tabs
                  value={currentTab}
                  onChange={(event, newValue) => setCurrentTab(newValue)}
                  className='content-tabs'
                >
                  <Tab value='content' label='Content' />

                  {data.linked_alerts_dev.length !== 0 && (
                    <Tab
                      value='associated-alerts'
                      label={`Associated alerts (${data.linked_alerts_dev.length})`}
                    />
                  )}

                  {data.linked_profiles_dev.length !== 0 && (
                    <Tab
                      value='associated-profiles'
                      label={`Associated profiles (${data.linked_profiles_dev.length})`}
                    />
                  )}

                  {data.ioc.length !== 0 && (
                    <Tab value='ioc' label={`IOCs (${data.ioc.total})`} />
                  )}
                </Tabs>
              </div>
            </div>

            <Divider style={{ marginBottom: 20 }} />

            {currentTab === 'content' && (
              <Content
                data={data}
                isPreview
                configuration={configuration}
                type={type}
                updateMitre={updateMitre}
              />
            )}

            {currentTab === 'associated-alerts' &&
              data.linked_alerts_dev.length > 0 && (
                <div className='associated-content'>
                  <ModuleListing
                    associated='alerts'
                    items={data.linked_alerts_dev}
                    isSidebar
                  />
                </div>
              )}

            {currentTab === 'associated-profiles' &&
              data.linked_profiles_dev.length > 0 && (
                <div className='associated-content'>
                  <ModuleListing
                    associated='profiles'
                    items={data.linked_profiles_dev}
                    isSidebar
                    type='profile'
                  />
                </div>
              )}

            {currentTab === 'ioc' && (
              <div style={{ width: '95%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 20,
                  }}
                >
                  <form
                    style={{ width: '400px' }}
                    onSubmit={(event) => {
                      event.preventDefault()
                      searchRef.current.value.length > 0
                        ? requestSearch('search', {
                            keyword: searchRef.current.value,
                          })
                        : requestSearch()
                    }}
                  >
                    <TextField
                      fullWidth
                      variant='outlined'
                      inputRef={searchRef}
                      defaultValue={data.ioc.search ? data.ioc.search : ''}
                      className='search'
                      inputProps={{ style: { padding: '12px 16px' } }}
                      placeholder='Search'
                      InputProps={{
                        endAdornment: isFetching && <CircularProgress />,
                      }}
                    />
                  </form>
                </div>

                <DataGrid
                  rows={data.ioc.data || []}
                  columns={columns}
                  style={{ width: '100%' }}
                  loading={isFetching}
                  pageSize={20}
                  components={{
                    Footer: () =>
                      data.ioc.data !== null &&
                      data.ioc.total > 20 && (
                        <Pagination
                          style={{ display: 'flex', padding: 20 }}
                          size='small'
                          onChange={(event, value) => requestSearch(value)}
                          page={data.ioc.current_page || 1}
                          color='primary'
                          disabled={isFetching}
                          count={data.ioc.last_page}
                          shape='rounded'
                          hideNextButton
                          hidePrevButton
                        />
                      ),
                    LoadingOverlay: () => (
                      <GridOverlay>
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            width: '100%',
                          }}
                        >
                          <LinearProgress />
                        </div>
                      </GridOverlay>
                    ),
                    NoRowsOverlay: () => (
                      <Stack
                        style={{
                          position: 'absolute',
                          left: '50%',
                          top: '80%',
                          transform: 'translate(-50%, -50%)',
                        }}
                        alignItems='center'
                        justifyContent='center'
                      >
                        {isFetching ? '' : 'No data found'}
                      </Stack>
                    ),
                  }}
                  autoPageSize
                  disableColumnMenu
                  autoHeight
                  disableColumnFilter
                  disableSelectionOnClick
                />
              </div>
            )}
          </div>
        ) : (
          <Spinner center size={30} border={1} />
        )}
      </DialogContent>

      <DialogActions style={{ padding: '16px 24px' }}>
        <Button
          style={{
            textTransform: 'capitalize',
            fontSize: 13,
            fontWeight: 700,
            borderRadius: 12,
            padding: '5px 15px',
          }}
          onClick={() => setPreviewOpen(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

Preview.displayName = 'Preview'

/**
 * The properties.
 * @type {Object}
 */

Preview.propTypes = {
  isPreviewOpen: PropTypes.bool.isRequired,
  setPreviewOpen: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  configuration: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string.isRequired,
}

export default Preview
