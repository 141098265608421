/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Menu } from '@material-ui/icons'
import { TextField } from '@material-ui/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const ArticleVideoSection = ({ props }) => {
  const { content, handleSectionState, index, id } = props
  const sortable = useSortable({ id: id })

  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable

  const disableAxis = (scale) => {
    if (scale && scale.x !== 0) {
      scale.x = 0
    }
    return scale
  }

  const style = {
    transform: CSS.Transform.toString(disableAxis(transform)),
    transition,
    opacity: isDragging ? 0.9 : 1,
  }

  return (
    <div
      className='knowledge-base__article-section'
      ref={setNodeRef}
      style={style}
    >
      <div className='knowledge-base__article-section-header'>
        <div
          onClick={() =>
            handleSectionState(index, 'isExpanded', !content.isExpanded)
          }
          className='knowledge-base__article-section-header-left'
        >
          <span
            style={{
              fontSize: 22,
              marginRight: 10,
              transform: content.isExpanded ? 'rotate(180deg)' : null,
            }}
            className='icon-down'
          />

          <span>Video section</span>
        </div>

        <div className='knowledge-base__article-section-header-right'>
          <Menu
            style={{ cursor: 'move', outline: 'none' }}
            {...listeners}
            {...attributes}
          />
        </div>
      </div>

      {content.isExpanded && (
        <div className='knowledge-base__article-section-content'>
          <div style={{ marginBottom: 20 }}>
            <h5>Youtube/Vimeo URL</h5>

            <TextField
              value={content.url || ''}
              onChange={(event) =>
                handleSectionState(index, 'url', event.target.value)
              }
              fullWidth
              placeholder='Enter Youtube/Vimeo URL'
              variant='outlined'
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ArticleVideoSection
