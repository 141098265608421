import { apiUrl } from '../../../config'
import client from '../../http/client'

export const getForumThreads = (
  token: string,
  forumId: number,
  categoryId: number,
  page: number,
) =>
  client.get(
    apiUrl +
      `/api/forum/${forumId}/categories/${categoryId}/threads?page=${page}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const getForumSearchedThreads = (
  token: string,
  keyword: string,
  forumId: string,
  page: number,
) =>
  client.get(
    apiUrl + `/api/forum/${forumId}/search?keyword=${keyword}&page=${page}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
