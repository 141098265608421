import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import React, { useState } from 'react'
import TlpModal from './TlpModal'

const OrganisationSettings = ({
  default_group_settings,
  handleOrganisationState,
  titleRefs,
  descriptionRefs,
  tlp_state,
}) => {
  const [tlpDialogOpen, setTlpDialogOpen] = useState(false)
  const defaultSettings = [
    {
      slug: 'is_notification_system_enabled',
      name: 'Email Notifications Enabled',
    },
    // { slug: 'include_new_users', name: 'Include New Users' },
    // { slug: 'is_chat_enabled', name: 'Chat Enabled' },
    { slug: 'dont_show_authors', name: 'Hide Authors' },
    { slug: 'make_anonymous', name: 'Make Anonymous' },
    { slug: 'is_pdf_upload_enabled', name: 'PDF Upload Enabled' },
  ]

  return (
    <div
      className='edit-organisation__section'
      style={{ width: '33.3%', marginBottom: 0 }}
    >
      <div
        className='edit-organisation__section-content groups'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <h4>Organisation settings</h4>

        {defaultSettings.map(({ slug, name }) => (
          <FormControlLabel
            key={slug}
            onChange={() =>
              handleOrganisationState(
                'settings',
                !default_group_settings[slug],
                slug,
              )
            }
            style={{ textTransform: 'capitalize' }}
            label={name}
            control={
              <Checkbox
                color='default'
                checked={default_group_settings[slug] || false}
                style={{ padding: 3, color: '#006FFF' }}
                icon={
                  <span
                    style={{ color: '#0000008a' }}
                    className='icon-checkbox-empty'
                  />
                }
                checkedIcon={<span className='icon-checkbox-checked' />}
              />
            }
          />
        ))}

        <Button
          style={{
            border: '1px solid #868b96',
            borderRadius: 12,
            textTransform: 'capitalize',
          }}
          onClick={() => setTlpDialogOpen(true)}
        >
          Define TLP Classifications
        </Button>

        {tlpDialogOpen && (
          <TlpModal
            tlpDialogOpen={tlpDialogOpen}
            setTlpDialogOpen={setTlpDialogOpen}
            tlp_state={tlp_state}
            titleRefs={titleRefs}
            descriptionRefs={descriptionRefs}
            handleOrganisationState={handleOrganisationState}
          />
        )}
      </div>
    </div>
  )
}

export default OrganisationSettings
