import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import SingleOrganisation from './SingleOrganisation'

const OrganisationGroups = ({
  groups,
  allGroups,
  handleOrganisationState,
  saveOrganisation,
  fetchNewUsers,
  getAvailableUserGroups,
}) => {
  const doesGroupExist = (groupId) =>
    Object.values(groups).find((group) => group.id === groupId) !== undefined
  const sortedGroups = allGroups?.sort(
    (a, b) => b.is_community_group - a.is_community_group,
  )

  return (
    <div className='edit-organisation__section'>
      <div className='edit-organisation__section-content groups'>
        <h4 style={{ marginBottom: 10 }}>Groups</h4>

        <Autocomplete
          multiple
          blurOnSelect
          disableClearable
          filterSelectedOptions
          getOptionSelected={(option, value) => option.id === value.id}
          options={allGroups}
          value={Object.values(groups) || []}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => {
            const newValue = value[value.length - 1]
            const newGroup = {
              id: newValue.id,
              name: newValue.name,
              settings: {
                include_new_users: true,
                make_anonymous: false,
                renderSettings: [true, true],
              },
            }
            handleOrganisationState('other_groups_settings', newGroup)
          }}
          popupIcon={<span className='icon-down' />}
          renderOption={({ name, is_community_group }) => {
            return (
              <>
                <span>{name}</span>

                {is_community_group && (
                  <span
                    style={{
                      backgroundColor: '#bdf2ff',
                      padding: '4px 10px',
                      marginLeft: 10,
                      fontWeight: 500,
                      fontSize: 11,
                      borderRadius: 12,
                    }}
                  >
                    Community Group
                  </span>
                )}
              </>
            )
          }}
          renderTags={() => {
            return false
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Search Groups'
              variant='outlined'
              className='tag-input'
              size='small'
              fullWidth
              InputProps={{ ...params.InputProps }}
            />
          )}
        />

        {sortedGroups.length > 0 && (
          <div style={{ margin: '10px 0' }}>
            {sortedGroups.map(
              (org) =>
                doesGroupExist(org.id) && (
                  <SingleOrganisation
                    organisation={org}
                    key={org.id}
                    handleOrganisationState={handleOrganisationState}
                    saveOrganisation={saveOrganisation}
                    groups={groups}
                    fetchNewUsers={fetchNewUsers}
                    getAvailableUserGroups={getAvailableUserGroups}
                  />
                ),
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default OrganisationGroups
