import React, { useState } from 'react'
import { Divider, Dialog, DialogContent, Button } from '@material-ui/core'
import BaseButton from './Button'
import { useGlobalContext } from '../../context/globalContext'
import Save from '@material-ui/icons/Save'
import Spinner from '../Spinner'
import { useViewStateContext } from '../../context/viewStateContext'

const Modal = () => {
  const { isModalOpen, setIsModalOpen } = useGlobalContext()
  const {
    workspaces,
    setWorkspaces,
    setIsLinkAnalysisModalOpen,
    setLinkAnalysisOpen,
    setLinkAnalysisState,
    publishRegraph,
  } = useViewStateContext()
  const [isSavingLinkAnalysis, setSavingLinkAnalysis] = useState(false)

  const handleCloseLinkAnalysis = () => {
    setIsModalOpen(false)
    setLinkAnalysisState({
      layout: null,
      inRange: null,
      undo: [],
      redo: [],
      currentChart: {
        items: {},
      },
    })
    setLinkAnalysisOpen(false)
    setIsLinkAnalysisModalOpen(false)

    let newWorkspaces = [...workspaces]
    newWorkspaces = newWorkspaces.filter((workspace) => !workspace.active)
    newWorkspaces[0].active = true

    setWorkspaces(newWorkspaces)
  }

  return (
    <Dialog id='popup-blur' open={isModalOpen}>
      <div style={{ width: '420px', padding: '10px 30px' }}>
        <div
          style={{
            padding: '20px 0',
            maxWidth: '100%',
            backgroundColor: '#fff',
          }}
          id='form-dialog-title'
        >
          <h3 style={{ color: '#000', paddingLeft: '20px' }}>
            Unsaved Changes
          </h3>

          <Divider style={{ width: '100%', marginTop: 30 }} />
        </div>

        <DialogContent style={{ padding: 0 }}>
          <div
            style={{
              fontSize: '16px',
              color: '#212935',
              marginBottom: '24px',
              padding: '0 5px',
            }}
          >
            You have unsaved changes. If you leave without saving, your changes
            will be lost. Are you sure you want to leave?
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'capitalize',
                textDecoration: 'underline',
                borderRadius: '10px',
                marginTop: '10px',
                fontSize: '14px',
                fontWeight: 700,
              }}
              onClick={() => setIsModalOpen(false)}
              variant='text'
              type='button'
              color='#212935'
            >
              CANCEL
            </Button>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BaseButton
                background='red'
                variant='contained'
                type='button'
                onClick={() => handleCloseLinkAnalysis()}
              >
                Exit
              </BaseButton>

              <div style={{ margin: '0 10px' }} />

              <BaseButton
                onClick={async () => {
                  setSavingLinkAnalysis(true)
                  const response = await publishRegraph()

                  response && handleCloseLinkAnalysis()
                  setSavingLinkAnalysis(false)
                }}
                variant='contained'
                type='button'
                color='blue'
                icon={<Save />}
              >
                {isSavingLinkAnalysis ? (
                  <Spinner size={22.5} color='#fff' centerHorizontally />
                ) : (
                  'Save & Exit'
                )}
              </BaseButton>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

Modal.propTypes = {
  //   isModalOpen: PropTypes.bool.isRequired,
  //   setIsLinkAnalysisModalOpen: PropTypes.func.isRequired,
  //   onCancel: PropTypes.func.isRequired,
}

Modal.displayName = 'Modal'

export default Modal
