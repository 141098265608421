import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const addNewOrganisation = (token, instanceId, orgData) =>
  client.post(apiUrl + `/api/apps/${instanceId}/organisations`, orgData, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const editOrganisation = (token, instanceId, orgId, orgData) =>
  client.put(
    apiUrl + `/api/apps/${instanceId}/organisations/${orgId}`,
    orgData,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const fetchAvailableGroups = (token, instanceId, organisationId) =>
  client.get(
    apiUrl +
      `/api/apps/${instanceId}/organisations/${organisationId}/users/groups`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const fetchUserData = (token, instanceId, organisationId, userId) =>
  client.get(
    apiUrl +
      `/api/apps/${instanceId}/organisations/${organisationId}/users/${userId}/edit`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const updateUser = (
  token,
  instanceId,
  organisationId,
  userId,
  payload,
) =>
  client.put(
    apiUrl +
      `/api/apps/${instanceId}/organisations/${organisationId}/users/${userId}`,
    payload,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const deleteOrganisationUser = (
  token,
  instanceId,
  organisationId,
  userId,
) =>
  client.remove(
    apiUrl +
      `/api/apps/${instanceId}/organisations/${organisationId}/users/${userId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const createUser = (token, instanceId, organisationId, payload) =>
  client.post(
    apiUrl + `/api/apps/${instanceId}/organisations/${organisationId}/users`,
    payload,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const resetTwoFa = (token, instanceId, organisationId, userId) =>
  client.put(
    apiUrl +
      `/api/apps/${instanceId}/organisations/${organisationId}/users/${userId}/reset`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const resendActivationLink = (
  token,
  instanceId,
  organisationId,
  userId,
) =>
  client.post(
    apiUrl +
      `/api/apps/${instanceId}/organisations/${organisationId}/users/${userId}/resend-activation-link`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
