import { Button, Dialog, Divider } from '@material-ui/core'
import PropTypes from 'prop-types'
import Spinner from '../../../shared/Spinner'

/**
 * ConfirmDelete
 * @param {array|number} confirmDelete - is confirm delete modal open
 * @param {function} setConfirmDelete - handling delete modal state
 * @param {function} handleDeletePiece - deleting piece
 * @param {boolean} isDeleting - is delete request in progress
 */

const ConfirmDeleteModerationModal = ({
  confirmDelete,
  setConfirmDelete,
  handleDeletePiece,
  isDeleting,
  typeOfReportedContent,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={confirmDelete !== undefined}
      onClose={() => setConfirmDelete(undefined)}
    >
      <div style={{ padding: 24 }}>
        <h3>Confirm Delete</h3>

        <Divider style={{ margin: '10px 0', border: 'none' }} />

        <span>This action cannot be undone.</span>
      </div>

      <div
        style={{
          padding: '0 24px 16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => setConfirmDelete(undefined)}>Cancel</Button>

        <Button
          style={{
            backgroundColor: '#FF0033',
            color: '#fff',
            textTransform: 'capitalize',
            fontWeight: 700,
            borderRadius: 12,
            padding: '6px 20px',
            minWidth: 85,
          }}
          onClick={() =>
            handleDeletePiece({
              id: confirmDelete,
              type: typeOfReportedContent,
            })
          }
        >
          {isDeleting ? (
            <Spinner color='#fff' size={20} border={1} />
          ) : (
            <span>Delete</span>
          )}
        </Button>
      </div>
    </Dialog>
  )
}

ConfirmDeleteModerationModal.displayName = 'ConfirmDeleteModerationModal'

/**
 * The properties.
 * @type {Object}
 */

ConfirmDeleteModerationModal.propTypes = {
  confirmDelete: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.number,
  ]).isRequired,
  setConfirmDelete: PropTypes.func.isRequired,
  handleDeletePiece: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
}

export default ConfirmDeleteModerationModal
