/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrl } from '../../../config'
import client from '../../http/client'

export const getForumCategories = (
  token: string | null,
  forumId: number | string,
) =>
  client.get(apiUrl + `/api/forum/${forumId}/categories`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const createForumThread = ({
  token,
  forumId,
  categoryId,
  data,
}: {
  token: string
  forumId: number | string
  categoryId: number | string
  data: {
    forum_id: number | string
    category_id: number | string
    title: string
    content: any
    is_pinned: boolean
  }
}) =>
  client.post(
    apiUrl + `/api/forum/${forumId}/categories/${categoryId}/threads`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
