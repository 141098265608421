import client from '../../../http/client'
import { apiUrl } from '../../../../config/index'

const token = () => {
  return localStorage.getItem('access_token') || ''
}

export const getForumCategories = () =>
  client.get(apiUrl + `/api/admin/categories`, {
    headers: {
      Authorization: 'Bearer ' + token(),
    },
  })

export const createForumCategory = (data: {
  name?: string
  color?: string
  description: string
  updated_at?: string
}) =>
  client.post(apiUrl + `/api/admin/categories`, data, {
    headers: {
      Authorization: 'Bearer ' + token(),
    },
  })

export const updateForumCategory = ({
  categoryId,
  data,
}: {
  categoryId: number | string | null
  data: {
    id?: string | number | null
    name?: string
    color?: string
    description: string
    updated_at?: string
  }
}) =>
  client.put(apiUrl + `/api/admin/categories/${categoryId}`, data, {
    headers: {
      Authorization: 'Bearer ' + token(),
    },
  })

export const deleteForumCategory = ({ id }: { id: number | string | null }) =>
  client.remove(apiUrl + `/api/admin/categories/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token(),
    },
  })
