import React, { useState } from 'react'
import Button from '../../../../shared/components/Button'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const ArticleButtons = ({ data, saveArticle }) => {
  const location = useLocation()
  const [articleStatus, setArticleStatus] = useState(data.is_published)

  return (
    <div
      style={{
        width: '25%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '20px',
      }}
    >
      {location.pathname === '/app/admin/knowledge-base/article/create' ? (
        <>
          <Button
            onClick={() => saveArticle({ is_published: false })}
            background='lightBlue'
          >
            Save Draft
          </Button>

          <Button
            onClick={() => saveArticle({ is_published: true })}
            background='green'
          >
            Save & Publish
          </Button>
        </>
      ) : articleStatus ? (
        <>
          <Button
            onClick={() => {
              saveArticle({ is_published: false })
              setArticleStatus(false)
            }}
            background='white'
            isDarkFontColor
          >
            Unpublish
          </Button>

          <Button
            onClick={() => {
              saveArticle({ is_published: true })
              setArticleStatus(true)
            }}
            background='green'
          >
            Save
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={() => {
              saveArticle({ is_published: false })
              setArticleStatus(false)
            }}
            background='lightBlue'
          >
            Save draft
          </Button>

          <Button
            onClick={() => {
              saveArticle({ is_published: true })
              setArticleStatus(true)
            }}
            background='green'
          >
            Publish
          </Button>
        </>
      )}
    </div>
  )
}

ArticleButtons.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  saveArticle: PropTypes.func.isRequired,
}

export default ArticleButtons
