import React from 'react'
import { useHistory } from 'react-router-dom'
import { Divider } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PropTypes from 'prop-types'

import ArticleButtons from './ArticleButtons'
import Button from '../../../../shared/components/Button'

const ArticleHeader = ({ data, saveArticle }) => {
  const history = useHistory()

  return (
    <div className='knowledge-base__article-header'>
      <div
        style={{
          width: '75%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => history.push('/app/admin/knowledge-base/all')}
            background='white'
            isDarkFontColor
          >
            <ArrowBackIcon />

            <span>Back</span>
          </Button>

          <Divider
            style={{ height: 40, marginLeft: 15, backgroundColor: '#505050' }}
            orientation='vertical'
          />

          <span
            style={{ fontSize: 32, margin: '0 10px' }}
            className='icon-alert'
          />

          <h2>Knowledge Base Content</h2>
        </div>

        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
          {isCreateNew && <h4 style={{ marginRight: 10 }}>Select Template</h4>}

          {!isCreateNew && (
            <h4 style={{ marginRight: 10, padding: '13.5px 0' }}>
              Selected Template:{' '}
            </h4>
          )}

          {isCreateNew ? (
            <Select
              variant='outlined'
              value={data.type || 'text'}
              onChange={(event) => handleState('type', event.target.value)}
            >
              <MenuItem value='text'>Text Resource</MenuItem>

              <MenuItem value='video'>Video Resource</MenuItem>
            </Select>
          ) : (
            <h4>{data.type}</h4>
          )}
        </div>
      </div> */}
      </div>

      <ArticleButtons data={data} saveArticle={saveArticle} />
    </div>
  )
}

ArticleHeader.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  saveArticle: PropTypes.func.isRequired,
}

export default ArticleHeader
