/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Avatar, Divider, IconButton, Tooltip } from '@material-ui/core'
import { formatDate } from '../../../../../shared/helpers/formatDate'
import { useChat } from '../../../../../context/chatContext'
import { useViewStateContext } from '../../../../../context/viewStateContext'
import { OpenInNew } from '@mui/icons-material'

interface Props {
  readonly previewModule?: string
  readonly previewId?: number
  cachedItems?: any
}
const PreviewItem = ({ previewModule, previewId, cachedItems }: Props) => {
  const { cachedPiecesOfContent } = useChat()
  const data =
    previewModule &&
    previewId &&
    (cachedItems
      ? cachedItems[previewModule][previewId]
      : cachedPiecesOfContent[previewModule][previewId])
  const { addWorkspace }: { addWorkspace: any } = useViewStateContext()

  return (
    <div className='listing-item'>
      <div className='listing-item__left'>
        <div className='priority'>
          <Avatar
            style={{
              color: '#767a82',
              border: '2px solid #767a82',
            }}
          >
            <span
              style={{
                fontSize: 24,
              }}
              className={`icon-${
                previewModule === 'profile' ? data.type : previewModule
              }`}
            />
          </Avatar>
        </div>

        {data?.priority_id > 0 && data.priorityName && (
          <div className='priority'>
            <Tooltip
              arrow
              placement='top'
              title={
                data.priorityName ? data.priorityName : data.capabilityName
              }
            >
              <Avatar className={`level-${data?.priority_id}`}>
                {data?.priority_id}
              </Avatar>
            </Tooltip>
          </div>
        )}

        {data.capability > 0 && data.capabilityName && (
          <div className='priority'>
            <Tooltip
              arrow
              placement='top'
              title={
                data.priorityName ? data.priorityName : data.capabilityName
              }
            >
              <Avatar className={`level-${data.capability}`}>
                {data.capability}
              </Avatar>
            </Tooltip>
          </div>
        )}
      </div>

      <div className='listing-item__right'>
        <h4>{`${data.title}${data.known_as ? ' - ' + data.known_as : ''}`}</h4>

        <Divider
          style={{
            backgroundColor: '#767a82',
            width: '100%',
            margin: '15px 0',
          }}
        />

        <span style={{ fontSize: 12, color: '#767a82' }}>
          Updated at: {formatDate(data.updated_at)}
        </span>

        <span style={{ fontSize: 12, color: '#767a82' }}>
          Author: {data.author}
        </span>

        <div className='labels'>
          {data.content_label && (
            <span className='label-status'>{data.content_label}</span>
          )}

          {data.read === false && <span className='label-read'>Unread</span>}
        </div>
      </div>

      <Tooltip arrow placement='top' title='Open in new workspace'>
        <IconButton
          style={{
            position: 'absolute',
            right: 12,
            top: 8,
            padding: 6,
            borderRadius: 4,
          }}
          onClick={() => {
            const moduleType =
              previewModule === 'profile' ? data.type : previewModule
            moduleType &&
              previewId &&
              addWorkspace(moduleType, previewId, data.title, false, true)
          }}
        >
          <OpenInNew />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default PreviewItem
