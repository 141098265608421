/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, Fragment, useRef } from 'react'
import {
  CircularProgress,
  Divider,
  LinearProgress,
  Menu,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core'
import Content from './TabContent/Content'
import Comments from './TabContent/Comments'
import AssociatedAlerts from './TabContent/AssociatedAlerts'
import AssociatedProfiles from './TabContent/AssociatedProfiles'
import { DataGrid, GridOverlay } from '@mui/x-data-grid'
import { formatIoc } from '../../../../shared/helpers/formatDate'
import { Button, MenuItem, Pagination, Stack } from '@mui/material'
import { iocViewCollection } from '../../../../shared/services/api/ioc/ioc'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { handlePayload } from './fn'
import DownloadIcon from '@mui/icons-material/Download'
import { exportIoc } from '../../../../shared/services/api/alerts/alerts'
import { useViewStateContext } from '../../../../context/viewStateContext'

const PieceTabs = ({
  data,
  currentModule,
  getPieceData,
  currentTab,
  setCurrentTab,
  configuration,
  type,
  setData,
  updateMitre,
  listLoading,
}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { activePiece, setWorkspaces } = useViewStateContext()
  const [isFetching, setFetching] = useState(false)
  const [exportOpen, setExportOpen] = useState(null)
  const searchRef = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const exportIocTypes = [
    { name: 'CSV', slug: 'csv' },
    { name: 'MISP', slug: 'misp' },
    { name: 'STIX 2.1', slug: 'stix' },
  ]
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue)
    setWorkspaces((currentState) => {
      const currentWorkspace = currentState.find(
        (workspace) => workspace.id === currentModule.id,
      )

      if (!currentWorkspace) return currentState
      currentWorkspace.currentTab = newValue
      return currentState
    })
  }

  const requestSearch = async (pageValue, sortPayload) => {
    setFetching(true)
    data.ioc.data = []
    try {
      let currentState = { ...data }
      let payload = {}
      payload = handlePayload(
        payload,
        pageValue,
        sortPayload,
        data.ioc,
        searchRef.current.value,
      )

      const query = new URLSearchParams(payload).toString()
      const response = await iocViewCollection(token(), type, data.id, query)

      if (payload.keyword) {
        currentState.ioc.search = payload.keyword
      } else {
        delete currentState.ioc.search
      }
      if (payload.sortBy) {
        currentState.ioc.sortBy = payload.sortBy
      }
      if (payload.sortOrder) {
        currentState.ioc.sortOrder = payload.sortOrder
      }
      data.ioc.current_page = response.current_page
      data.ioc.total = response.total
      data.ioc.last_page = response.last_page
      if (response.data.length === 0) {
        data.ioc.data = null
      } else {
        data.ioc.data = response.data
      }

      setFetching(false)
    } catch (err) {
      setFetching(false)
    }
  }

  const saveSortBeforeFetch = (sortBy) => {
    const payload = {}
    if (sortBy) {
      if (!data.ioc.sortBy || sortBy !== data.ioc.sortBy) {
        payload.sortOrder = 'asc'
        payload.sortBy = sortBy
      } else if (data.ioc.sortOrder === 'asc') {
        payload.sortOrder = 'desc'
        payload.sortBy = sortBy
      } else if (data.ioc.sortOrder === 'desc') {
        delete payload.sortOrder
        delete payload.sortBy
      }
    }

    if (payload.sortBy) {
      data.ioc.sortBy = payload.sortBy
    } else {
      delete data.ioc.sortBy
    }
    if (payload.sortOrder) {
      data.ioc.sortOrder = payload.sortOrder
    } else {
      delete data.ioc.sortOrder
    }

    if (data.ioc.search) {
      payload.keyword = data.ioc.search
    }

    setTimeout(() => {
      requestSearch(undefined, payload)
    })
  }

  const handleExportIoc = async (type) => {
    setLoading(true)
    try {
      const pieceType =
        currentModule.state === 'alert' ||
        currentModule.state === 'scenario' ||
        currentModule.state === 'report'
          ? currentModule.state
          : 'profile'
      const payload = {
        content_id: data.id,
        module: pieceType,
        type: type,
      }
      const query = new URLSearchParams(payload).toString()
      const response = await exportIoc(token(), query)

      if (response.error) {
        setLoading(false)
        setExportOpen(null)

        return false
      }
      const url = `${response}?access_token=${token()}`
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null

      setLoading(false)
      setExportOpen(null)
    } catch (error) {}
  }

  const columns = [
    {
      field: 'value',
      headerName: 'Data',
      editable: false,
      sortable: false,
      renderHeader: () => {
        return (
          <div
            className='sortable-header'
            onClick={() => saveSortBeforeFetch('value')}
          >
            <span>Data</span>

            {data.ioc.sortBy === 'value' && data.ioc.sortOrder === 'asc' && (
              <KeyboardArrowDown />
            )}

            {data.ioc.sortBy === 'value' && data.ioc.sortOrder === 'desc' && (
              <KeyboardArrowUp />
            )}
          </div>
        )
      },
      flex: 0.9,
      renderCell: (params) => (
        <div>
          <span>{params.value}</span>
        </div>
      ),
    },
    {
      flex: 0.13,
      field: 'type',
      headerName: 'Type',
      editable: false,
      sortable: false,
      renderHeader: () => {
        return (
          <div
            className='sortable-header'
            onClick={() => saveSortBeforeFetch('type')}
          >
            <span>Type</span>

            {data.ioc.sortBy === 'type' && data.ioc.sortOrder === 'asc' && (
              <KeyboardArrowDown />
            )}

            {data.ioc.sortBy === 'type' && data.ioc.sortOrder === 'desc' && (
              <KeyboardArrowUp />
            )}
          </div>
        )
      },
    },
    {
      field: 'date',
      flex: 0.15,
      headerName: 'Date',
      minwidth: 80,
      editable: false,
      sortable: false,
      renderHeader: () => {
        return (
          <div
            className='sortable-header'
            onClick={() => saveSortBeforeFetch('date')}
          >
            <span>Date</span>

            {data.ioc.sortBy === 'date' && data.ioc.sortOrder === 'asc' && (
              <KeyboardArrowDown />
            )}

            {data.ioc.sortBy === 'date' && data.ioc.sortOrder === 'desc' && (
              <KeyboardArrowUp />
            )}
          </div>
        )
      },
      renderCell: (params) => <span>{formatIoc(params.value)}</span>,
    },
    {
      flex: 0.4,
      field: 'comment',
      headerName: 'Comments',
      minWidth: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          <span>{params.value}</span>
        </div>
      ),
    },
  ]

  return (
    <Fragment>
      <div className='single-piece__tabs'>
        <div className='single-piece__tabs-inner' style={{ width: '95%' }}>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            className='content-tabs'
          >
            <Tab value='content' label='Content' />

            <Tab
              value='comments'
              label={`Comments (${data.comments.length})`}
            />

            <Tab
              style={{
                display: data.linked_alerts.length === 0 ? 'none' : 'flex',
              }}
              value='associated-alerts'
              label={`Associated alerts (${data.linked_alerts.length})`}
            />

            <Tab
              style={{
                display: data.linked_profiles.length === 0 ? 'none' : 'flex',
              }}
              value='associated-profiles'
              label={`Associated profiles (${data.linked_profiles.length})`}
            />

            <Tab
              style={{ display: data?.hasNoIoc ? 'none' : 'flex' }}
              value='ioc'
              label={`IOCs (${data.ioc.total})`}
            />
          </Tabs>
        </div>
      </div>

      <Divider style={{ marginBottom: 20 }} />

      {currentTab === 'content' && (
        <Content
          data={data}
          configuration={configuration}
          type={type}
          updateMitre={updateMitre}
          currentModule={currentModule}
          listLoading={listLoading}
        />
      )}

      {currentTab === 'comments' && (
        <Comments
          data={data.comments}
          activePiece={activePiece}
          getPieceData={getPieceData}
          currentModule={currentModule.state}
          type={type}
          contentData={data}
          setData={setData}
        />
      )}

      {currentTab === 'associated-alerts' && data.linked_alerts.length > 0 && (
        <AssociatedAlerts items={data.linked_alerts} />
      )}

      {currentTab === 'associated-profiles' &&
        data.linked_profiles.length > 0 && (
          <AssociatedProfiles items={data.linked_profiles} type={type} />
        )}

      {currentTab === 'ioc' && (
        <div style={{ width: '95%', marginBottom: 25 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 20,
              alignItems: 'center',
            }}
          >
            <Button
              className='export-ioc'
              startIcon={<DownloadIcon />}
              onClick={(event) => setExportOpen(event.currentTarget)}
            >
              {isLoading ? (
                <CircularProgress
                  style={{ height: 24, width: 24, color: '#fff' }}
                />
              ) : (
                'Export IOCs'
              )}
            </Button>

            <Menu
              anchorEl={exportOpen}
              PaperProps={{
                style: {
                  width: 140,
                },
              }}
              open={exportOpen !== null}
              onClose={() => setExportOpen(null)}
            >
              {exportIocTypes.map((type) => (
                <MenuItem
                  key={type.slug}
                  style={{
                    display: 'list-item',
                    padding: '4px 12px',
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                  onClick={() => handleExportIoc(type.slug)}
                >
                  {type.name}
                </MenuItem>
              ))}
            </Menu>

            <form
              style={{ width: '50%' }}
              onSubmit={(event) => {
                event.preventDefault()
                searchRef.current.value.length > 0
                  ? requestSearch('search', {
                      keyword: searchRef.current.value,
                    })
                  : requestSearch()
              }}
            >
              <TextField
                variant='outlined'
                inputRef={searchRef}
                defaultValue={data?.ioc?.search || ''}
                className='search'
                placeholder='Search'
                InputProps={{
                  endAdornment: isFetching && <CircularProgress />,
                }}
              />
            </form>
          </div>

          <DataGrid
            rows={data.ioc.data || []}
            columns={columns}
            style={{ width: '100%' }}
            pageSize={20}
            autoPageSize
            disableColumnMenu
            autoHeight
            disableColumnFilter
            disableSelectionOnClick
            loading={isFetching}
            components={{
              Footer: () =>
                data.ioc.data !== null &&
                data?.ioc?.total > 20 && (
                  <Pagination
                    style={{ display: 'flex', padding: 20 }}
                    size='small'
                    onChange={(event, value) => requestSearch(value)}
                    page={data?.ioc?.current_page || 1}
                    color='primary'
                    disabled={isFetching}
                    count={data?.ioc?.last_page}
                    shape='rounded'
                    hideNextButton
                    hidePrevButton
                  />
                ),
              LoadingOverlay: () => (
                <GridOverlay>
                  <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                  </div>
                </GridOverlay>
              ),
              NoRowsOverlay: () => (
                <Stack
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '80%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  alignItems='center'
                  justifyContent='center'
                >
                  {isFetching ? '' : 'No data found'}
                </Stack>
              ),
            }}
          />
        </div>
      )}
    </Fragment>
  )
}

export default PieceTabs
