import React from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import './ContentDatepicker.scss'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const ContentDatepicker = ({ data, label, name }) => {
  const { handleCreateState } = useCreateManagementContext()
  const handleSetDatesState = (prop, newValue) => {
    if (!data) {
      data = {}
    }

    handleCreateState(
      'dev_additional_dates',
      { ...data, [prop]: newValue },
      true,
    )
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        disableToolbar
        autoOk
        variant='inline'
        inputVariant='outlined'
        format='dd/MM/yyyy'
        value={data && data[name] ? data[name] : null}
        // minDate={data && name === 'discovery_date' ? data.event_date : false}
        label={label}
        onChange={(value) => handleSetDatesState(name, value)}
        style={{ marginRight: 20 }}
        InputProps={{
          endAdornment: <span className='icon-down' />,
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

export default ContentDatepicker
