import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import {
  createUser,
  fetchUserData,
  resetTwoFa,
  updateUser,
} from '../../../../../../shared/services/api/editOrganisation/editOrganisation'
import Spinner from '../../../../../../shared/Spinner'
import { PhoneIphone } from '@material-ui/icons'
import { textFields, checkboxes, initData, permissions } from './data'
import GroupsTable from './GroupsTable'
import { useForm } from 'react-hook-form'
import { formatDate } from '../../../../../../shared/helpers/formatDate'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

const EditOrganisationUserDialog = ({
  userDialogOpen,
  setUserDialogOpen,
  instanceId,
  orgId,
  availableUserGroups,
  fetchNewUsers,
  setToastMessage,
  groups,
  searchData,
  groupsIncludeNewUsers,
  notificationsEnabled,
  chosenModules,
  isOrganisationChatEnabled,
  isOrganisationForumEnabled,
}) => {
  const isCreateUser = userDialogOpen === 'new'
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [userData, setUserData] = useState(cloneDeep(initData))
  const [isLoading, setLoading] = useState(!isCreateUser)
  const { getValues, register } = useForm()
  const [isSaving, setSaving] = useState(false)

  useEffect(() => {
    isCreateUser ? setDefaultUserPrivileges() : getUserData()
  }, [])

  const setDefaultUserPrivileges = () => {
    const currentData = { ...userData }
    currentData.privileges = {}

    availableUserGroups.available_groups &&
      Object.values(availableUserGroups.available_groups)?.forEach(
        ({ id, module_access }) => {
          currentData.privileges[id] = { module_access: module_access }

          if (
            groupsIncludeNewUsers &&
            groupsIncludeNewUsers[id] &&
            !groupsIncludeNewUsers[id]?.settings?.include_new_users
          ) {
            delete currentData?.privileges[id]
          }
        },
      )

    setUserData(currentData)
  }

  const getUserData = async () => {
    try {
      const response = await fetchUserData(
        token(),
        instanceId,
        orgId,
        userDialogOpen,
      )

      if (
        response?.data?.privileges &&
        Object.keys(response.data.privileges).length
      ) {
        Object.values(response.data.privileges).forEach((groupRoles) => {
          Object.values(groupRoles.module_access).forEach(
            (module) => delete module.is_extra_module,
          ) //deleting is_extra_module to be able to compare permissions
        })
      }

      if (response?.data?.privileges?.length === 0) {
        response.data.privileges = {}
      }

      if (response.error) {
        setLoading(false)
        return false
      }

      setUserData(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const updateUserData = async () => {
    setSaving(true)
    try {
      const payload = { ...getValues(), privileges: userData.privileges }
      let response = {}

      if (isCreateUser) {
        response = await createUser(token(), instanceId, orgId, payload)
      } else {
        response = await updateUser(
          token(),
          instanceId,
          orgId,
          userDialogOpen,
          payload,
        )
      }

      if (response.error) {
        let errors = []

        if (response.errors) {
          Object.values(response.errors) &&
            Object.values(response.errors).forEach(
              (er) => (errors = [...errors, er]),
            )
          setToastMessage(['error', errors.join(', ')])
        } else {
          setToastMessage(['error', response.message])
        }

        setSaving(false)
        return false
      }

      setTimeout(async () => {
        await fetchNewUsers(searchData)
        setUserDialogOpen(null)
        setToastMessage(['success', response.message])
      }, 6000)
    } catch (err) {}
  }

  const handleReset2Fa = async () => {
    try {
      const response = await resetTwoFa(
        token(),
        instanceId,
        orgId,
        userDialogOpen,
      )

      if (response.error) {
        setToastMessage(['error', response.message])
        return false
      }

      setToastMessage(['success', response.message])
    } catch {}
  }

  const handleUserData = (prop, value) => {
    const currentData = { ...userData }

    currentData[prop] = value
    setUserData(currentData)
  }

  const maxPossibleRoles = () =>
    cloneDeep(
      userData?.privileges[availableUserGroups?.default_group_id]
        ?.module_access,
    )

  const handleCycleUserPermissions = (groupId) => {
    const currentState = { ...userData }
    const maxCurrentRole = {
      ...currentState?.privileges[groupId]?.module_access,
    }
    const mainGroupRoles = Object.values({ ...maxPossibleRoles() }).map(
      (role) => role.role_id,
    )
    const hasDifferentRoles = mainGroupRoles.some(
      (role) => role !== mainGroupRoles[0],
    )

    if (hasDifferentRoles) {
      //logic is different when roles are not matching - should get maximum privileges for each module
      const roles = { ...maxPossibleRoles() }
      const alreadyHasMaximumPermissions = isEqual(
        roles,
        currentState?.privileges[groupId]?.module_access,
      )

      if (alreadyHasMaximumPermissions) {
        delete currentState.privileges[groupId]
      } else {
        currentState.privileges[groupId] = { module_access: roles }
      }

      setUserData(currentState)

      return false
    } else {
      //logic should cycle through possible roles
      const newRoles = {}
      const rolesWithExtra = { ...maxPossibleRoles() }
      const roles = {}
      Object.values(rolesWithExtra).forEach(
        (module) =>
          (roles[module.id] = { id: module.id, role_id: module.role_id }),
      )
      const maxCurrentRoleToCycle = Math.max(
        ...Object.values(maxCurrentRole).map((role) => role.role_id),
      )
      const alreadyHasMaximumPermissions = isEqual(
        roles,
        currentState?.privileges[groupId]?.module_access,
      )
      const currentRoles = Object.values({
        ...currentState?.privileges[groupId]?.module_access,
      }).map((role) => role.role_id)
      const hasDifferentCurrentRoles = currentRoles.some(
        (role) => role !== currentRoles[0],
      )

      if (alreadyHasMaximumPermissions) {
        delete currentState.privileges[groupId]

        setUserData(currentState)
        return false
      }

      Object.values(roles).forEach((role) => {
        const giveCurrentMaxRole =
          currentRoles.length !== Object.keys(roles).length ||
          hasDifferentCurrentRoles
            ? maxCurrentRoleToCycle
            : maxCurrentRoleToCycle + 1
        newRoles[role.id] = { id: role.id, role_id: giveCurrentMaxRole }
      })

      if (!currentState.privileges[groupId]) {
        currentState.privileges[groupId] = { module_access: roles }
        Object.values(currentState?.privileges[groupId]?.module_access).forEach(
          (role) => (role.role_id = 1),
        )
      } else {
        currentState.privileges[groupId].module_access = newRoles
      }

      setUserData(currentState)
    }
  }

  const handleUserPermissions = (groupId, moduleId, value, isDefaultGroup) => {
    const currentState = cloneDeep(userData.privileges)

    if (!currentState[groupId]) {
      currentState[groupId] = { module_access: {} }
    }

    if (!currentState[groupId]?.module_access[moduleId]) {
      currentState[groupId].module_access[moduleId] = { id: moduleId }
    }

    if (value === 0) {
      if (isDefaultGroup) {
        Object.values(currentState).forEach(
          ({ module_access }) => delete module_access[moduleId],
        )
      } else {
        delete currentState[groupId]?.module_access[moduleId]
      }
    } else {
      if (isDefaultGroup) {
        Object.entries(currentState).forEach((group) => {
          const id = +group[0]
          const { module_access } = group[1]

          if (id === groupId) {
            module_access[moduleId].role_id = value
          } else {
            if (!module_access[moduleId]) {
              return false
            }

            const currentRole = module_access[moduleId]?.role_id

            if (value < currentRole) {
              module_access[moduleId].role_id = value
            }
          }
        })
      } else {
        currentState[groupId].module_access[moduleId] = {
          id: moduleId,
          role_id: value,
        }
      }
    }

    if (
      currentState[groupId]?.module_access &&
      !Object.keys(currentState[groupId]?.module_access).length
    ) {
      delete currentState[groupId]
    }

    setUserData({ ...userData, privileges: currentState })
  }

  return (
    <Dialog open={userDialogOpen !== null} maxWidth='lg' fullWidth>
      {isLoading ? (
        <div style={{ height: 529, width: '100%' }}>
          <Spinner center size='50' />
        </div>
      ) : (
        <div>
          <div style={{ display: 'flex' }}>
            <DialogContent
              style={{ width: '40%', padding: '20px 10px 10px 25px' }}
            >
              <div className='dialog-content__left'>
                <h3>
                  {isCreateUser
                    ? 'Add new user'
                    : `Edit user - ${userData.first_name || ''} ${
                        userData.last_name || ''
                      }`}
                </h3>

                <Divider style={{ margin: '15px 0' }} />

                {textFields.map(({ slug, width, placeholder }) => (
                  <TextField
                    key={slug}
                    fullWidth={width === 'full'}
                    style={{
                      margin:
                        slug === 'first_name' ? '5px 10px 5px 0' : '5px 0',
                      width: width === 'half' ? 'calc(50% - 5px)' : null,
                    }}
                    inputProps={{ style: { padding: 14 } }}
                    defaultValue={userData[slug] || ''}
                    placeholder={placeholder}
                    variant='outlined'
                    {...register(slug)}
                  />
                ))}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '10px 0',
                  }}
                >
                  <div>
                    <span>Last login: </span>

                    <span style={{ fontSize: 14, fontWeight: 600 }}>
                      {userData.last_login_at
                        ? formatDate(userData.last_login_at)
                        : 'User has not logged in yet.'}
                    </span>
                  </div>

                  {userData.is_two_factor_enabled && (
                    <Button
                      startIcon={<PhoneIphone />}
                      onClick={() => handleReset2Fa()}
                      style={{
                        textTransform: 'capitalize',
                        borderRadius: 12,
                        padding: '6px 16px',
                        backgroundColor: '#006bf5',
                        color: '#fff',
                        fontWeight: 600,
                      }}
                    >
                      Reset 2FA
                    </Button>
                  )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {checkboxes.map(
                    ({ id, slug, label, value }) =>
                      (id !== 4 || (id === 4 && notificationsEnabled)) && (
                        <>
                          <FormControlLabel
                            disabled={
                              (!isOrganisationChatEnabled &&
                                slug === 'is_chat_enabled') ||
                              (!isOrganisationForumEnabled &&
                                slug === 'is_forum_enabled')
                            }
                            key={id}
                            onChange={() =>
                              handleUserData(slug, !userData[slug])
                            }
                            style={{ textTransform: 'capitalize' }}
                            label={
                              slug === 'is_chat_enabled' &&
                              !isOrganisationChatEnabled ? (
                                <Tooltip
                                  title={`The organisation has disabled chat so you can't change this option.`}
                                >
                                  <span
                                    style={{
                                      fontWeight:
                                        slug === 'is_link_analysis_enabled'
                                          ? 600
                                          : null,
                                    }}
                                  >
                                    {label}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span
                                  style={{
                                    fontWeight:
                                      slug === 'is_link_analysis_enabled'
                                        ? 600
                                        : null,
                                  }}
                                >
                                  {label}
                                </span>
                              )
                            }
                            control={
                              !isOrganisationChatEnabled &&
                              slug === 'is_chat_enabled' ? (
                                <Tooltip title="You can't enable chat because the organisation has no access to chat.">
                                  <Checkbox
                                    color='default'
                                    {...register(slug)}
                                    disabled={
                                      !isOrganisationChatEnabled &&
                                      slug === 'is_chat_enabled'
                                    }
                                    defaultChecked={
                                      isCreateUser
                                        ? value
                                        : userData[slug] || false
                                    }
                                    style={{
                                      padding: 3,
                                      color: '#fff',
                                      fontSize: 34,
                                    }}
                                    icon={
                                      <span
                                        style={{ color: '#000' }}
                                        className='icon-checkbox-empty'
                                      />
                                    }
                                    checkedIcon={
                                      <span
                                        style={{ color: '#006bf5' }}
                                        className='icon-checkbox-checked'
                                      />
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                <Checkbox
                                  color='default'
                                  {...register(slug)}
                                  defaultChecked={
                                    isCreateUser
                                      ? value
                                      : userData[slug] || false
                                  }
                                  style={{
                                    padding: 3,
                                    color: '#fff',
                                    fontSize: 34,
                                  }}
                                  icon={
                                    <span
                                      style={{ color: '#000' }}
                                      className='icon-checkbox-empty'
                                    />
                                  }
                                  checkedIcon={
                                    <span
                                      style={{ color: '#006bf5' }}
                                      className='icon-checkbox-checked'
                                    />
                                  }
                                />
                              )
                            }
                          />
                          {slug === 'is_chat_enabled' &&
                            !isOrganisationChatEnabled && (
                              <span
                                style={{
                                  marginLeft: '22px',
                                  fontWeight: 'bold',
                                  fontSize: '12px',
                                }}
                              >
                                {`The organisation has disabled chat so you can't
                                change this option.`}
                              </span>
                            )}
                          {slug === 'is_forum_enabled' &&
                            !isOrganisationForumEnabled && (
                              <span
                                style={{
                                  marginLeft: '22px',
                                  fontWeight: 'bold',
                                  fontSize: '12px',
                                }}
                              >
                                {`The organisation has disabled forum so you can't
                                change this option.`}
                              </span>
                            )}
                        </>
                      ),
                  )}
                </div>
              </div>
            </DialogContent>

            <DialogContent
              style={{ width: '60%', padding: '20px 25px 10px 10px' }}
            >
              <div className='dialog-content__right'>
                <h3>Edit Permissions</h3>

                <Divider style={{ margin: '15px 0' }} />

                <table style={{ width: '100%' }}>
                  <thead>
                    <tr style={{ backgroundColor: '#E0E0E0', fontSize: 14 }}>
                      {permissions.map((item) => (
                        <th
                          style={{
                            padding: 14,
                            borderRadius:
                              (item === 'Groups' && '10px 0 0 10px') ||
                              (item === 'Link Analysis' && '0 10px 10px 0'),
                          }}
                          key={item}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    <GroupsTable
                      privileges={userData.privileges || {}}
                      chosenGroups={groups || []}
                      groupsList={
                        availableUserGroups.available_groups_list || []
                      }
                      defaultGroupId={availableUserGroups.default_group_id}
                      handleUserPermissions={handleUserPermissions}
                      chosenModules={chosenModules}
                      handleCycleUserPermissions={handleCycleUserPermissions}
                    />
                  </tbody>
                </table>
              </div>
            </DialogContent>
          </div>

          <Divider style={{ margin: '10px 24px' }} />

          <DialogActions
            style={{
              padding: '0 24px 16px 24px',
              justifyContent: 'space-between',
            }}
          >
            <Button
              style={{
                textTransform: 'capitalize',
                fontWeight: 700,
                color: '#000',
                borderRadius: 12,
                padding: '6px 18px',
              }}
              onClick={() => setUserDialogOpen(null)}
            >
              Cancel
            </Button>

            <Button
              style={{
                minWidth: 128,
                textTransform: 'capitalize',
                backgroundColor: '#006bf5',
                fontWeight: 700,
                color: '#fff',
                borderRadius: 12,
                padding: '6px 18px',
              }}
              onClick={() => {
                updateUserData()
                //   handleOrganisationState('tlp_state')
                //   setTlpDialogOpen(false)
              }}
            >
              {isSaving ? (
                <Spinner size={22.5} color='#fff' centerHorizontally />
              ) : (
                <span>Save & Close</span>
              )}
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  )
}

export default EditOrganisationUserDialog
