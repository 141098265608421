import { Button, OutlinedInput, Tooltip } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { moduleTypes } from '../../data'
import EditOrganisationUserDialog from './EditOrganisationUserDialog'
import SingleUser from '../SingleUser'
import Pagination from '@material-ui/lab/Pagination'
import Spinner from '../../../../../../shared/Spinner'
import { useParams } from 'react-router-dom'
import ConfirmDelete from '../../../../../ContentManage/ConfirmDelete'
import {
  deleteOrganisationUser,
  resendActivationLink,
} from '../../../../../../shared/services/api/editOrganisation/editOrganisation'

const OrganisationUsers = ({
  users,
  handleOrganisationState,
  instanceId,
  orgId,
  availableUserGroups,
  fetchNewUsers,
  usersLoading,
  setToastMessage,
  groups,
  saveOrganisation,
  getAvailableUserGroups,
  groupsIncludeNewUsers,
  notificationsEnabled,
  chosenModules,
  handleAllUsers,
  allUsersExpanded,
  isOrganisationChatEnabled,
  isOrganisationForumEnabled,
}) => {
  const [userDialogOpen, setUserDialogOpen] = useState(null)
  const { id } = useParams()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const handleExpandedUsers = (id) => handleOrganisationState('users', id)
  const [searchData, setSearchData] = useState({})
  const handleSetSearchData = (key, value) =>
    setSearchData({ ...searchData, [key]: value })
  const handleChangePage = (page) => handleSetSearchData('page', page)
  const [confirmDelete, setConfirmDelete] = useState(undefined)
  const [isDeleting, setDeleting] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const handleSubmitSearch = (event) => {
    event.preventDefault()
    searchData.page !== 1
      ? setSearchData({ ...searchData, page: 1 })
      : fetchNewUsers(searchData)
  }

  useEffect(
    () => Object.keys(searchData).length > 0 && fetchNewUsers(searchData),
    [searchData.page],
  )

  const handleDeleteUser = async () => {
    setDeleting(true)
    try {
      const response = await deleteOrganisationUser(
        token(),
        instanceId,
        orgId,
        confirmDelete,
      )

      if (response.error) {
        setToastMessage(['error', response.message])
        setDeleting(false)
        return false
      }

      setToastMessage(['success', response.message])
      setDeleting(false)
      setConfirmDelete(undefined)
      fetchNewUsers(searchData)
    } catch {}
  }

  const handleResendLink = async (userId) => {
    try {
      const response = await resendActivationLink(
        token(),
        instanceId,
        orgId,
        userId,
      )

      if (response.error) {
        setToastMessage(['error', response.message])
        return false
      }

      setToastMessage(['success', response.message])
    } catch (err) {}
  }

  return (
    <div className='edit-organisation__section'>
      <div className='edit-organisation__section-content'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Tooltip
            arrow
            placement='top'
            title={
              id === 'create'
                ? 'Users can be added only to an existing organisation.'
                : ''
            }
          >
            <Button
              disableRipple
              onClick={async () => {
                if (id !== 'create') {
                  setSaving(true)
                  const isSaved = await saveOrganisation(true)

                  if (isSaved) {
                    await getAvailableUserGroups()
                    setSaving(false)
                    setUserDialogOpen('new')
                  }
                }
              }}
              style={{
                backgroundColor: id === 'create' ? '#bababa' : '#006bf5',
                textTransform: 'capitalize',
                padding: '12px 18px',
                borderRadius: 12,
                color: '#fff',
                minWidth: 136,
                height: 36,
              }}
            >
              {isSaving ? (
                <Spinner size={22.5} centerHorizontally color='#fff' />
              ) : (
                <span>Add new user</span>
              )}
            </Button>
          </Tooltip>

          <form style={{ width: '50%' }} onSubmit={handleSubmitSearch}>
            <OutlinedInput
              value={searchData.search || ''}
              variant='outlined'
              placeholder='Search users'
              fullWidth
              inputProps={{ style: { padding: '14px 14px 14px 0' } }}
              onChange={(event) =>
                handleSetSearchData('search', event.target.value)
              }
              startAdornment={
                <span style={{ fontSize: 26 }} className='icon-search' />
              }
            />
          </form>
        </div>

        {userDialogOpen !== null && (
          <EditOrganisationUserDialog
            userDialogOpen={userDialogOpen}
            isOrganisationChatEnabled={isOrganisationChatEnabled}
            isOrganisationForumEnabled={isOrganisationForumEnabled}
            setUserDialogOpen={setUserDialogOpen}
            instanceId={instanceId}
            orgId={orgId}
            availableUserGroups={availableUserGroups}
            fetchNewUsers={fetchNewUsers}
            setToastMessage={setToastMessage}
            groups={[
              availableUserGroups.available_groups_list.find(
                (group) => group.id === availableUserGroups.default_group_id,
              ),
              ...groups,
            ]}
            searchData={searchData}
            groupsIncludeNewUsers={groupsIncludeNewUsers}
            notificationsEnabled={notificationsEnabled}
            chosenModules={chosenModules}
          />
        )}

        {confirmDelete !== undefined && (
          <ConfirmDelete
            isDeleting={isDeleting}
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
            handleDeletePiece={handleDeleteUser}
          />
        )}

        <table>
          <thead>
            <tr>
              <th>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>User</span>

                  <Button
                    style={{
                      textTransform: 'capitalize',
                      padding: '4px 14px',
                      borderRadius: 12,
                      fontSize: 12,
                      marginLeft: 5,
                    }}
                    endIcon={<span className='icon-down' />}
                    onClick={() => handleAllUsers()}
                  >
                    {allUsersExpanded() ? 'Collapse all' : 'Expand All'}
                  </Button>
                </div>
              </th>

              {moduleTypes
                .filter((m) => m.id !== 6 && m.id != 12 && m.id != 13)
                .map((type) => (
                  <th key={type.id}>{type.name}</th>
                ))}

              <th>Options</th>
            </tr>
          </thead>

          {!usersLoading && users.data && (
            <tbody style={{ position: 'relative' }}>
              {users.data.map((user) => (
                <SingleUser
                  key={user.id}
                  user={user}
                  setUserDialogOpen={setUserDialogOpen}
                  handleExpandedUsers={handleExpandedUsers}
                  groupsList={availableUserGroups.available_groups_list}
                  defaultGroupId={availableUserGroups.default_group_id}
                  setConfirmDelete={setConfirmDelete}
                  handleResendLink={handleResendLink}
                  groups={groups}
                  saveOrganisation={saveOrganisation}
                />
              ))}
            </tbody>
          )}
        </table>

        {usersLoading && <Spinner centerHorizontally padding={30} size={30} />}

        {users.data && users.data.length > 0 && (
          <Pagination
            onChange={(event, value) => handleChangePage(value)}
            page={searchData.page || 1}
            count={users.last_page || 1}
            shape='rounded'
            size='small'
            hideNextButton
            hidePrevButton
            color='primary'
            style={{ marginTop: 20 }}
          />
        )}
      </div>
    </div>
  )
}

export default OrganisationUsers
