/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, InputAdornment, TextField } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { useUserContext } from '../../../context/userContext'
import { useQueryGetCategories } from '../../../shared/services/queries/forum/useQueryGetCategories'

const Categories = () => {
  const { forumId }: { forumId: string } = useParams()
  const location: { state: { forumName: string } } = useLocation()
  const categories = useQueryGetCategories(Number(forumId))
  const history = useHistory()
  const { user } = useUserContext()
  const [searchPhrase, setSearchPhrase] = useState('')

  useEffect(() => {
    location.state?.forumName &&
      localStorage.setItem('forumName', location.state.forumName)
  }, [forumId])

  return (
    <div className='forum__main'>
      {user?.forum_groups.length !== 1 && (
        <div
          style={{
            height: 'calc(100% - 70px)',
            width: '60px',
            top: 70,
            background: '#283143',
            position: 'fixed',
            left: 60,
            borderLeft: '1px solid #1b222e',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <NavLink
            to='/app/forum'
            style={{
              display: 'block',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '200px',
                color: 'white',
                position: 'relative',
              }}
            >
              <span style={{ transform: 'rotate(270deg)', display: 'block' }}>
                Swap Forum
              </span>
              <span
                className='icon-expand-content'
                style={{
                  display: 'block',
                  color: '#fff',
                  fontSize: '25px',
                  width: '50px',
                  position: 'absolute',
                  top: '-15px',
                  left: '90px',
                }}
              />
            </div>
          </NavLink>
        </div>
      )}
      <header className='forum__header'>
        <h2>
          {location.state?.forumName || localStorage.getItem('forumName')} Forum
        </h2>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <form
              onSubmit={(event) => {
                event.preventDefault()
                history.push({
                  pathname: `/app/forum/${forumId}/search/${searchPhrase}`,
                  state: {
                    searchPhrase,
                  },
                })
              }}
            >
              <TextField
                fullWidth
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                value={searchPhrase}
                onChange={(event) => {
                  setSearchPhrase(event.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <span
                        className='icon-search'
                        style={{ color: '#fff', fontSize: 28 }}
                      />
                    </InputAdornment>
                  ),
                }}
                style={{ color: '#fff' }}
                placeholder='Search'
                variant='standard'
              />
            </form>
          </div>
          <Button
            onClick={() => {
              history.push({
                pathname: '/app/forum/thread-new',
                state: {
                  forumId: forumId,
                },
              })
            }}
            style={{
              textTransform: 'capitalize',
              background: '#006fff',
              color: '#fff',
              maxHeight: 36,
              borderRadius: '12px',
              minWidth: 160,
            }}
          >
            Create New Thread
          </Button>
        </div>
      </header>

      <main className='forum__main-content'>
        <div className='forum__categories'>
          <div className='forum__categories-row'>
            <div style={{ flex: 1 }}>Category</div>
            <div>Threads</div>
            <div>Replies</div>
          </div>
          <div className='forum__categories-cards'>
            {categories?.data?.data?.categories?.map(
              (category: {
                id: number
                name: string
                color: string
                description: string
                threads_count: number
                threads_sum_visits: number
                posts_count: number
              }) => (
                <NavLink
                  key={category.id}
                  to={{
                    pathname: `/app/forum/${forumId}/${category.id}`,
                    state: {
                      categoryName: category?.name,
                      categoryColor: category?.color,
                      forumName: location?.state?.forumName,
                    },
                  }}
                >
                  <div
                    style={{
                      color: 'white',
                      padding: '8px 20px',
                      marginBottom: '4px',
                      height: 100,
                      background: '#283143',
                      border: '1px solid #767A822C',
                      borderRadius: '0 12px 12px 0',
                      borderLeft: `4px solid ${category?.color}`,
                      display: 'flex',
                      gap: '16px',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <h5 style={{ fontSize: '20px', marginBottom: '12px' }}>
                        {category.name}
                      </h5>
                      <p style={{ fontSize: '14px', color: '#ccc' }}>
                        {category.description}
                      </p>
                    </div>
                    <div
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        flexBasis: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {category.threads_count}
                    </div>
                    <div
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        flexBasis: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {category.posts_count}
                    </div>
                  </div>
                </NavLink>
              ),
            )}
          </div>
        </div>
      </main>
    </div>
  )
}

export default Categories
