/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton } from '@material-ui/core'

import './KnowledgeBaseTopBar.scss'
import AutocompleteSearch from '../AutocompleteSearch'
import { useKnowledgeBaseContext } from '../../../context/knowledgeBaseContext'

const KnowledgeBaseTopBar = () => {
  const { activeLink, categories } = useKnowledgeBaseContext()
  const [isAutocompleteShown, setIsAutocompleteShown] = useState(false)
  const { push } = useHistory()

  const activeRoutes = activeLink.split('/')
  // let activeNames = []

  // if (activeRoutes === 1) {
  //   /* CATEGORRY */
  //   const categoryName =
  //     categories.find((category) => category.slug === activeLink) || {}

  //   activeNames = Object.keys(categoryName).length > 0 && [categoryName]
  // }

  // if (activeRoutes === 2) {
  //   /* SUBCATEGORY */
  //   const categoryName = categories.find(
  //     (category) => category.slug === activeLink.split('/')[0]
  //   )

  //   const subcategoryName =
  //     categoryName?.child_categories?.find(
  //       (subcategory) => subcategory.slug === activeLink.split('/')[1]
  //     ) ||
  //     categoryName?.articles?.find(
  //       (article) => article.slug === activeLink.split('/')[1]
  //     )

  //   activeNames = categoryName &&
  //     subcategoryName && [categoryName, subcategoryName]
  // }

  // if (activeRoutes === 3) {
  //   /* ARTICLES */
  //   const categoryName = categories.find(
  //     (category) => category.slug === activeLink.split('/')[0]
  //   )

  //   const subcategoryName = categoryName?.child_categories?.find(
  //     (subcategory) => subcategory.slug === activeLink.split('/')[1]
  //   )

  //   const articleName = subcategoryName?.articles.find(
  //     (article) => article.slug === activeLink.split('/')[2]
  //   )

  //   activeNames = categoryName &&
  //     subcategoryName &&
  //     articleName && [categoryName, subcategoryName, articleName]
  // }

  return (
    <div className='knowledge-base-topbar'>
      {isAutocompleteShown ? (
        <>
          <AutocompleteSearch isOutlined />

          <IconButton onClick={() => setIsAutocompleteShown(false)}>
            <span className='icon-close' />
          </IconButton>
        </>
      ) : (
        <>
          <div className='knowledge-base-topbar__route-title'>
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => push(`/app/knowledge-base`)}
            >
              Knowledge Base
            </span>

            {activeRoutes &&
              activeRoutes?.map((item, index, array) => {
                return (
                  <span
                    key={item}
                    onClick={() =>
                      index !== array.length - 1 &&
                      push(`/app/knowledge-base/${item}`)
                    }
                    style={{
                      cursor: index === array.length - 1 ? '' : 'pointer',
                    }}
                  >
                    <span className='knowledge-base-topbar__sign'>&gt;</span>

                    <span
                      className='knowledge-base-topbar__category-name'
                      style={{
                        fontWeight: index === array.length - 1 ? 'bold' : '',
                      }}
                    >
                      {item}
                    </span>
                  </span>
                )
              })}
          </div>

          <span
            className='icon-search'
            style={{ fontSize: '30px', cursor: 'pointer' }}
            onClick={() => setIsAutocompleteShown(true)}
          />
        </>
      )}
    </div>
  )
}

export default KnowledgeBaseTopBar
