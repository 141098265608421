import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { Checkbox, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

/**
 * GlobalAutocomplete
 * @param {object} filters - current filters
 * @param {function} handleFilters - handling filters state
 * @param {object} filterOptions - filter options
 * @param {string} type - filter type
 */

const GlobalAutocomplete = ({
  filters,
  handleFilters,
  filterOptions,
  type,
}) => {
  return (
    <Autocomplete
      multiple
      value={filters[type] || []}
      options={
        filterOptions && Object.keys(filterOptions).length > 0
          ? type === 'sectors'
            ? filterOptions[type]
            : filterOptions[type].list
          : [] || []
      }
      getOptionSelected={(option, value) => option.name === value.name}
      disableCloseOnSelect
      onChange={(e, newValue) => handleFilters(type, newValue)}
      getOptionLabel={(option) => option.name}
      renderOption={(option, { selected }) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              minHeight: 30,
            }}
            className='sector-checkbox'
          >
            <div>
              <Checkbox
                style={{ padding: 0, marginRight: 10 }}
                checked={selected}
                icon={<span className='icon-checkbox-empty' />}
                checkedIcon={<span className='icon-checkbox-checked' />}
              />

              <span>{option.name}</span>
            </div>
          </div>
        )
      }}
      renderInput={(params) => {
        params.InputProps.startAdornment = params.InputProps.startAdornment
          ? `Selected: ${params.InputProps.startAdornment.length}`
          : ''
        return (
          <TextField
            {...params}
            style={{ color: '#fff' }}
            variant='outlined'
            placeholder={`Search ${type}`}
          />
        )
      }}
    />
  )
}

GlobalAutocomplete.displayName = 'GlobalAutocomplete'

/**
 * The properties.
 * @type {Object}
 */

GlobalAutocomplete.propTypes = {
  filters: PropTypes.instanceOf(Object).isRequired,
  handleFilters: PropTypes.func.isRequired,
  filterOptions: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string.isRequired,
}

export default GlobalAutocomplete
