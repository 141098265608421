import { Button, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import './CreateAlert.scss'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  editPiece,
  sendInitialDraft,
} from '../../../../shared/services/api/createContent/createAlert'
import ContentSection from './ContentSection'
import PublicationCheck from '../../shared/Components/microcomponents/PublicationCheck'
import { saveScenario } from '../../shared/helpers/saveDraft'
import Hint from '../../shared/Components/Hint'
import Spinner from '../../../../shared/Spinner'
import BottomBar from '../../shared/Components/microcomponents/BottomBar'
import PropTypes from 'prop-types'
import { useGlobalContext } from '../../../../context/globalContext'
import CreateToast from '../../shared/CreateToast'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useDirtyCreate } from '../../../../context/dirtyCreateContext'
import useNotification from '../../../../hooks/useNotification'
import { cloneDeep } from 'lodash'
import { changesMade, saveDraftHandler } from '../../shared/helpers/saveHandler'
import { useCreateManagementContext } from '../../../../context/createManagementState'

/**
 * Scenario - create mode
 * @param {object} configuration - includes configuration for scenario module
 */

const Scenario = ({ configuration }) => {
  const { createContentState, handleCreateState, setCreateContentState } =
    useCreateManagementContext()
  const {
    isContentBeingEdited,
    setIsContentBeingEdited,
    setConfirmAutosaveModal,
  } = useGlobalContext()
  const { dirtyCreateState, setDirtyCreateState } = useDirtyCreate()
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setLoading] = useState(false)
  const [toastMessage, setToastMessage] = useState(undefined)
  const [isPublicationCheckActive, setIsPublicationCheckActive] =
    useState(false)
  const [isSaving, setSaving] = useState(false)
  const [isSendingForApproval, setSendingForApproval] = useState(false)
  const [isDenying, setDenying] = useState(false)
  const [isPreviewOpen, setPreviewOpen] = useState(false)
  const { infoToast, successToast, errorToast } = useNotification()

  const initialDraft = async (title) => {
    if (isSaving) return false

    try {
      setSaving(true)
      setToastMessage(['info', 'Creating the content'])
      const data = {
        ...createContentState,
        dev_title: title || 'Scenario title',
        dev_framework_id: createContentState.dev_framework_id,
      }
      const response = await sendInitialDraft(token(), 'scenarios', data)

      if (response.error) {
        setToastMessage(['error', response.message])
        setSaving(false)
        setIsContentBeingEdited(false)
        return false
      }

      const currentConfig = configuration.frameworks.filter(
        (item) => item.id === createContentState.dev_framework_id,
      )[0].schema

      response.data.dev_content = {}
      currentConfig.forEach((section) => {
        if (section.internal_key !== 'update-summary') {
          response.data.dev_content[section.internal_key] = {
            is_complete: false,
            is_rejected: false,
            is_accepted: false,
            has_been_edited: false,
            content: {},
            comments: [],
          }
        }
      })

      Object.entries(response.data.content_distribution.organisations).forEach(
        (org) => {
          org[1].id = org[0]
        },
      )
      response.data.dev_content['content-distribution'].content =
        response.data.content_distribution
      response.data.dev_tlp = { colour: 'amber' }
      response.data.dev_type_id = createContentState.dev_type_id
      setCreateContentState(response.data)
      setDirtyCreateState(cloneDeep(response.data))
      setToastMessage(['success', response.message])
      setIsContentBeingEdited(['scenario', response.data.id])
      setSaving(false)
    } catch (err) {
      console.log(err)
    }
  }

  const loadPieceData = async () => {
    setLoading(true)
    try {
      const response = await editPiece(token(), 'scenarios', id)

      if (response.error) {
        setToastMessage(['error', response.message])
        setLoading(false)
        return false
      }

      if (response.data.isLocked) {
        const { first_name, last_name } = response.data.isLocked[0]
        setToastMessage([
          'warning',
          `Content is currently being edited by ${first_name} ${last_name}`,
        ])
        setLoading(false)
        setTimeout(() => history.push('/app/content-management'), 6000)
        return false
      }

      const currentFramework =
        configuration?.frameworks?.find(
          (item) => item.id === response.data.dev_framework_id,
        ) || configuration?.frameworks[0]

      currentFramework?.schema?.forEach((section) => {
        if (
          !response.data.dev_content[section.internal_key] &&
          section.internal_key !== 'update-summary'
        ) {
          response.data.dev_content[section.internal_key] = {
            content: {},
            is_complete: false,
            is_rejected: false,
            is_accepted: false,
            has_been_edited: false,
            comments: [],
          }
        }
      })

      setIsContentBeingEdited(['scenario', response.data.id])

      Object.entries(response.data.content_distribution.organisations).forEach(
        (org) => {
          const id = org[0]
          org[1].id = id
        },
      )

      response.data.dev_content['content-distribution'].content =
        response.data.content_distribution

      if (response?.data?.relevance?.organisations) {
        response.data.dev_content['content-distribution'].content.relevance =
          response.data.relevance.organisations
      }

      Object.values(response.data.dev_content).forEach((section) => {
        if (section.is_rejected) {
          section.is_complete = true
        }
      })

      if (response.data.published !== 1 && !response.data.is_duplicated) {
        delete response.data.dev_content['update-summary']
      }

      if (response.data.dev_content['update-summary']) {
        response.data.dev_content['update-summary'].is_accepted = false
      }

      if (response?.data?.mitre_navigator_data?.layers?.length > 0) {
        response.data.mitre_navigator_data.layers[0].$isActive = true
        response.data.mitre_navigator_data.layers.forEach(
          (layer) => (layer.$isVisible = true),
        )
      }

      if (response?.data?.mitre_navigator_data?.layers[0]?.colour === null) {
        response.data.mitre_navigator_data.layers[0].colour = 1
      }

      setCreateContentState(response.data)
      setDirtyCreateState(cloneDeep(response.data))
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const contentPath = location.pathname.split('/').slice(-2)
    const contentType = contentPath[0]
    const contentId = contentPath[1]
    if (
      isContentBeingEdited &&
      isContentBeingEdited[0] !== contentType &&
      isContentBeingEdited[1] != contentId
    ) {
      setLoading(true)
      setCreateContentState({})
      setIsContentBeingEdited(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (
      id !== 'new' &&
      (isContentBeingEdited === null || isContentBeingEdited === false)
    ) {
      setCreateContentState({})
      loadPieceData()
    } else if (id === 'new' && isContentBeingEdited === true) {
      initialDraft()
    }
  }, [isContentBeingEdited])

  const handleExpandedSections = (section, data) => {
    const currentState = { ...data }

    currentState.is_expanded = !currentState.is_expanded
    handleCreateState(section, currentState, undefined, true)
  }

  const handleCompleteSections = (section) => {
    const currentState = { ...createContentState.dev_content[section] }

    currentState.is_complete = !currentState.is_complete

    if (currentState.is_complete) {
      currentState.is_expanded = false
    }

    if (createContentState.can_publish_or_deny && currentState.is_complete) {
      currentState.is_accepted = true
    }

    if (currentState.is_complete) {
      if (createContentState.can_publish_or_deny) {
        currentState.is_accepted = true
        handleCreateState(section, currentState, undefined, true)
        handleAutosave()
        return false
      } else if (
        !createContentState.can_publish_or_deny &&
        !currentState.is_rejected
      ) {
        handleCreateState(section, currentState, undefined, true)
        handleAutosave()
        return false
      }
    }

    handleCreateState(section, currentState, undefined, true)
  }

  const handleSectionStatus = (section, data, shouldReset, isSidebar) => {
    const currentState = { ...createContentState.dev_content[section] }

    if (shouldReset) {
      if (isSidebar) {
        currentState[data] = !currentState[data]
        currentState.is_complete = true
      } else {
        currentState[data] = !currentState[data]
        if (currentState.is_rejected) {
          currentState.is_complete = true
        }
      }
    } else {
      if (!createContentState.can_publish_or_deny) {
        currentState.is_accepted = false
        currentState.is_rejected = false
        currentState.is_complete = false
      } else if (
        data === 'is_accepted' &&
        createContentState.can_publish_or_deny
      ) {
        currentState.is_accepted = true
        currentState.is_rejected = false
        currentState.is_expanded = false
        currentState.is_complete = true
      } else if (
        data === 'is_rejected' &&
        createContentState.can_publish_or_deny
      ) {
        currentState.is_accepted = false
        currentState.is_rejected = true
        currentState.is_expanded = false
        currentState.is_complete = true
      }
    }

    handleCreateState(section, currentState)

    if (
      currentState.is_complete &&
      currentState.is_rejected &&
      createContentState.can_publish_or_deny
    ) {
      handleAutosave()

      return false
    }

    if (currentState.is_complete && !currentState.is_rejected) {
      if (
        (createContentState.can_publish_or_deny && currentState.is_accepted) ||
        (!createContentState.can_publish_or_deny && !currentState.is_accepted)
      ) {
        handleAutosave()
      }
    }
  }

  const handleCancelCreate = (message) => {
    history.push({
      pathname: '/app/content-management',
      state: { message: message },
    })
    setTimeout(() => {
      setCreateContentState({})
      setIsContentBeingEdited(false)
      setConfirmAutosaveModal(false)
    })
  }

  const handleSaveDraft = async (actionName, actionValue) => {
    const isContentChanged = await changesMade(
      isContentBeingEdited,
      actionValue,
      actionName,
      createContentState,
      dirtyCreateState,
    )

    if (
      !isContentChanged &&
      actionName !== 'approval' &&
      actionName !== 'deny'
    ) {
      successToast({ message: 'Scenario has been saved' })
      return false
    }

    setSaving(true)

    // const createStateDeepClone = cloneDeep(createContentState)
    // const dirtyCreateStateDeepClone = cloneDeep(dirtyCreateState)
    // const diff = difference(createStateDeepClone, dirtyCreateStateDeepClone)
    // const isDistributionOrRelevanceChanged = !!(diff?.content_distribution || diff?.relevance)

    if (actionName === 'titleChange') createContentState.dev_title = actionValue
    const currentState = cloneDeep(createContentState)
    const data = saveScenario(currentState)
    await saveDraftHandler(
      'scenarios',
      data,
      setSaving,
      setDenying,
      actionName,
      handleCancelCreate,
      setSendingForApproval,
      infoToast,
      successToast,
      errorToast,
    )
    // const finalState = {...createContentState, ...publicationCheckData}
    // setCreateContentState(finalState)
    setDirtyCreateState(cloneDeep(currentState))
  }

  const handleChangeFramework = (id) => {
    if (isContentBeingEdited) {
      const currentConfig = configuration?.frameworks?.find(
        (item) => item.id === id,
      )?.schema
      Object.keys(createContentState.dev_content).forEach((section) => {
        if (!currentConfig.find((item) => item.internal_key === section)) {
          delete createContentState.dev_content[section]
        }
      })
      currentConfig.forEach((section) => {
        if (
          !createContentState.dev_content[section.internal_key] &&
          section.internal_key !== 'update-summary'
        ) {
          createContentState.dev_content[section.internal_key] = {
            is_complete: false,
            is_rejected: false,
            is_accepted: false,
            has_been_edited: false,
            content: {},
            comments: [],
          }
        }
      })

      handleCreateState(
        'dev_content',
        [id, createContentState.dev_content],
        true,
      )
    } else {
      handleCreateState('dev_framework_id', id, true)
    }
  }

  const handleSendForApproval = () => handleSaveDraft('approval')
  const handleDeny = () => handleSaveDraft('deny')
  const handleAutosave = (action, value) => {
    if (isContentBeingEdited) {
      handleSaveDraft(action, value)
    } else {
      action && value.length > 0 && initialDraft(value)
    }
  }
  const isContentAlreadyEdited = JSON.parse(
    localStorage.getItem('isContentEdited'),
  )
  const handleTabClosing = () =>
    !isContentAlreadyEdited && localStorage.setItem('isContentEdited', null)

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing)
    return () => window.removeEventListener('unload', handleTabClosing)
  }, [])

  useEffect(() => {
    let intervalId
    if (!isContentAlreadyEdited && Number(id)) {
      localStorage.setItem('isContentEdited', id)
    } else if (
      isContentAlreadyEdited &&
      id &&
      Number(id) !== isContentBeingEdited[1]
    ) {
      intervalId = setTimeout(
        () => (window.location.href = '/app/workspace'),
        500,
      )
    }

    return () => {
      localStorage.setItem('isContentEdited', null)
      clearTimeout(intervalId)
    }
  }, [id])

  return (
    <>
      {isLoading && <Spinner center size={50} border={1} />}

      {toastMessage && (
        <CreateToast
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}

      {!isLoading && (
        <div className='create-alert scenario'>
          <div className='create-alert__header'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: 'calc(75% - 30px)',
                padding: '10px 20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', height: 50 }}
                >
                  <Button
                    onClick={() => handleCancelCreate('')}
                    disabled={isSaving}
                    startIcon={<ArrowBackIcon />}
                    style={{
                      textTransform: 'capitalize',
                      backgroundColor: 'transparent',
                      color: '#fff',
                      padding: '6px 20px 6px 6px',
                      borderRadius: 0,
                      marginRight: 20,
                      borderRight: '1px solid #505050',
                    }}
                  >
                    Back to content
                  </Button>

                  <span
                    style={{ fontSize: 32, marginRight: 10 }}
                    className='icon-scenario'
                  />

                  <h2>Create Scenario</h2>
                </div>

                {createContentState.dev_framework_id ? (
                  <div className='pos-top'>
                    <h5 style={{ marginRight: 10 }}>Select Framework </h5>

                    <Select
                      onChange={(event) =>
                        handleChangeFramework(event.target.value)
                      }
                      displayEmpty
                      className='select-framework'
                      value={createContentState.dev_framework_id || ''}
                      renderValue={
                        !createContentState.dev_framework_id
                          ? () => (
                              <div style={{ color: '#aaa' }}>
                                Select framework
                              </div>
                            )
                          : undefined
                      }
                      variant='outlined'
                    >
                      {configuration.frameworks.map((type) => (
                        <MenuItem key={type.name} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                ) : (
                  <div className='pos-center'>
                    <h3>Select Framework</h3>

                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        gap: 15,
                      }}
                    >
                      {configuration.frameworks.map((type) => (
                        <Button
                          onClick={() =>
                            handleCreateState('dev_framework_id', type.id, true)
                          }
                          key={type.name}
                          value={type.id}
                        >
                          {type.name}
                        </Button>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {createContentState.dev_framework_id !== undefined && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 15,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      width: '33.3%',
                    }}
                  >
                    <Hint
                      size={34}
                      text='Scenario type is a category that users can filter by when searching for Scenarios.'
                      margin
                    />

                    <div style={{ width: '100%' }}>
                      <h5 style={{ marginBottom: 5 }}>
                        Select type (required)
                      </h5>

                      <Select
                        onChange={(event) =>
                          handleCreateState(
                            'dev_type_id',
                            event.target.value,
                            true,
                          )
                        }
                        fullWidth
                        displayEmpty
                        value={createContentState.dev_type_id || ''}
                        required
                        renderValue={
                          !createContentState.dev_type_id
                            ? () => (
                                <div style={{ color: '#aaa' }}>Select type</div>
                              )
                            : undefined
                        }
                        variant='outlined'
                      >
                        {configuration.types.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      width: '33.3%',
                      margin: '0 20px',
                    }}
                  >
                    <Hint
                      size={34}
                      text='Asset types are business functions or assets that the Scenario is targeting. Asset type is also a category that users can filter by when searching for Scenarios.'
                      margin
                    />

                    <div style={{ width: '100%' }}>
                      <h5 style={{ marginBottom: 5 }}>
                        Asset / Critical Function / Target type
                      </h5>

                      <TextField
                        fullWidth
                        value={createContentState.dev_asset_type || ''}
                        onChange={(event) =>
                          handleCreateState(
                            'dev_asset_type',
                            event.target.value,
                            true,
                          )
                        }
                        variant='outlined'
                        inputProps={{ style: { padding: '15.5px 14px' } }}
                        placeholder='Asset / Critical Function / Target type'
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      width: '33.3%',
                    }}
                  >
                    <Hint
                      size={34}
                      text='This is the specific asset, under the asset type, that was targeted in the scenario. This is non mandatory.'
                      margin
                    />

                    <div style={{ width: '100%' }}>
                      <h5 style={{ marginBottom: 5 }}>
                        Asset / Critical Function / Target name
                      </h5>

                      <TextField
                        value={createContentState.dev_asset_name || ''}
                        onChange={(event) =>
                          handleCreateState(
                            'dev_asset_name',
                            event.target.value,
                            true,
                          )
                        }
                        fullWidth
                        variant='outlined'
                        inputProps={{ style: { padding: '15.5px 14px' } }}
                        placeholder='Asset / Critical Function / Target name'
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isContentBeingEdited &&
            typeof isContentBeingEdited[1] === 'number' &&
            createContentState.dev_framework_id !== undefined && (
              <>
                <ContentSection
                  configuration={
                    configuration?.frameworks?.find(
                      (item) => item.id === createContentState.dev_framework_id,
                    )?.schema || configuration?.frameworks[0]?.schema
                  }
                  handleCompleteSections={handleCompleteSections}
                  handleExpandedSections={handleExpandedSections}
                  handleSectionStatus={handleSectionStatus}
                  setToastMessage={setToastMessage}
                  handleAutosave={handleAutosave}
                  isSaving={isSaving}
                />

                {isPublicationCheckActive && (
                  <PublicationCheck
                    isOpen={isPublicationCheckActive}
                    setIsPublicationCheckActive={setIsPublicationCheckActive}
                    handleCancelCreate={handleCancelCreate}
                    type='scenarios'
                    setToastMessage={setToastMessage}
                  />
                )}

                {typeof isContentBeingEdited[1] === 'number' && (
                  <BottomBar
                    module='scenarios'
                    isSaving={isSaving}
                    handleSaveDraft={handleSaveDraft}
                    isPreviewOpen={isPreviewOpen}
                    setPreviewOpen={setPreviewOpen}
                    configuration={
                      configuration?.frameworks?.find(
                        (item) =>
                          item.id === createContentState.dev_framework_id,
                      )?.schema || configuration?.frameworks[0]?.schema
                    }
                    isDenying={isDenying}
                    handleDeny={handleDeny}
                    isSendingForApproval={isSendingForApproval}
                    handleSendForApproval={handleSendForApproval}
                    setIsPublicationCheckActive={setIsPublicationCheckActive}
                    setToastMessage={setToastMessage}
                  />
                )}
              </>
            )}
        </div>
      )}
    </>
  )
}

Scenario.displayName = 'Scenario'

/**
 * The properties.
 * @type {Object}
 */

Scenario.defaultProps = {
  configuration: {
    types: {},
    frameworks: {},
  },
}

Scenario.propTypes = {
  configuration: PropTypes.instanceOf(Object),
}

export default Scenario
