import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import React from 'react'
import Spinner from '../../../../../../shared/Spinner'

const options = [
  { name: 'Daily email notification', slug: 'chat_email_notify_daily' },
  {
    name: 'Notify me when directly messaged',
    slug: 'chat_email_notify_direct',
  },
  { name: 'Notify me when mentioned', slug: 'chat_email_notify_mention' },
  {
    name: 'Notification interval',
    slug: 'chat_email_notify_interval',
    isSelect: true,
  },
]

// handleSettingChange(
//   settingType[0],
//   option[0],
//   event.target.value,
// )

const intervalOptions = ['15 MINUTES', '2 HOURS']
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChatRelated = ({
  data,
  handleSettingChange,
  handleSubmit,
  isLoading,
}) => {
  const setSelectValue = () => {
    if (data?.chat_email_notify_interval === '15 MINUTES') {
      return '15 MINUTES'
    } else if (data?.chat_email_notify_interval === '2 HOURS') {
      return '2 HOURS'
    } else {
      return ''
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
      {options?.map(({ name, slug, isSelect }) => (
        <FormControlLabel
          label={name}
          key={slug}
          control={
            isSelect ? (
              <Select
                value={setSelectValue()}
                onChange={(event) =>
                  handleSettingChange(
                    'social_notification',
                    'chat_email_notify_interval',
                    event.target.value,
                  )
                }
                className='chat-related-select'
              >
                {intervalOptions.map((item) => (
                  <MenuItem
                    key={item}
                    style={{ fontSize: '13px', textTransform: 'lowercase' }}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Checkbox
                icon={<span className='icon-checkbox-empty' />}
                checkedIcon={<span className='icon-checkbox-checked' />}
                // disableRipple
                color='primary'
                style={{ color: '#006fff', padding: 6 }}
                className='checkbox'
                onChange={(event, newValue) =>
                  handleSettingChange('social_notification', slug, newValue)
                }
                checked={data ? data[slug] : false}
              />
            )
          }
        />
      ))}

      <Button
        onClick={() => handleSubmit()}
        className='submit-button'
        color='primary'
        style={{ marginTop: 15, width: 'max-content' }}
      >
        {isLoading ? (
          <Spinner color='#fff' size={21} border={1} />
        ) : (
          'Save Changes'
        )}
      </Button>
    </div>
  )
}

export default ChatRelated
