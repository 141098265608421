/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './UserDetails.scss'
import { Divider } from '@material-ui/core'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { useHistory } from 'react-router-dom'
import { IconButton, Tooltip } from '@mui/material'
import {
  setAvatar,
  tutorial,
} from '../../../../../shared/services/api/account/account'
import { useUserContext } from '../../../../../context/userContext'
import UploadImage from '../../../../CreateContent/shared/Components/UploadImage'
import { Delete } from '@mui/icons-material'
import { me } from '../../../../../shared/services/api/me/me'
import useNotification from '../../../../../hooks/useNotification'

/**
 * UserDetails
 * @param {object} userData - user details
 */

const UserDetails = ({ userData }) => {
  const {
    user: {
      is_chat_enabled,
      user_info: { is_tutorial_active, avatar },
    },
    updateTutorialState,
  } = useUserContext()
  const { setUser } = useUserContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { email, first_name, last_name, username } = userData
  const history = useHistory()
  const [isAdding, setIsAdding] = useState(false)
  const { error } = useNotification()
  const navigate = () => {
    history.push({
      pathname: '/app/support',
      state: {
        type: 'other',
        redirect_url: '/app/settings/user-details',
      },
    })
  }

  const handleSetAvatar = async (imageData) => {
    try {
      setIsAdding(true)
      const data = new FormData()
      !imageData ? delete data.imageData : data.append('file', imageData)
      data.append('module', 'user')
      data.append('directory', 'avatar')
      const av = await setAvatar(token(), data)

      if (av?.error) {
        errorToast({ message: av?.message })
        setIsAdding(false)
      }
      if (!av.error) {
        const response = await me(token())
        setUser(response)
        setIsAdding(false)
      }
    } catch (err) {}
  }

  const handleResetTutorial = async () => {
    try {
      const data = { is_tutorial_active: true }
      await tutorial(token(), data)
      updateTutorialState(true)
    } catch (err) {}
  }

  return (
    <div className='user-details'>
      <h2>User Details</h2>

      <Divider />

      <span style={{ marginBottom: '20px' }}>
        <span>
          These details are read-only. To make changes to your details, please
        </span>

        <button
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            marginLeft: 4,
            border: 'none',
            background: 'none',
            fontFamily: 'inherit',
            fontSize: 16,
            padding: 0,
          }}
          onClick={() => navigate()}
        >
          contact us
        </button>
      </span>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h4>Username:</h4>

          <h4>First name:</h4>

          <h4>Last name:</h4>

          <h4>Email:</h4>

          <h4>Tutorial status:</h4>

          {is_chat_enabled && <h4>Avatar:</h4>}
        </div>

        <div className='user-details__data-container'>
          <span>{username}</span>

          <span>{first_name}</span>

          <span>{last_name}</span>

          <span>{email}</span>

          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: is_tutorial_active ? 'green' : 'red' }}>
              {is_tutorial_active ? 'Active' : 'Inactive'}
            </span>

            {!is_tutorial_active && (
              <Tooltip arrow title='Reset tutorial status' placement='top'>
                <IconButton
                  onClick={() => handleResetTutorial()}
                  style={{ padding: 4, marginLeft: 10 }}
                >
                  <RestartAltIcon style={{ fontSize: 24 }} />
                </IconButton>
              </Tooltip>
            )}
          </span>

          {is_chat_enabled ? (
            <span
              style={{
                display: 'flex',
                position: 'relative',
              }}
            >
              <UploadImage
                uploadImage={handleSetAvatar}
                isAdding={isAdding}
                avatar
              />

              {avatar ? (
                <Tooltip placement='top' title='Delete Avatar' arrow>
                  <IconButton
                    style={{ padding: 5, width: 32, height: 32, marginLeft: 6 }}
                    onClick={() => handleSetAvatar(null)}
                  >
                    <Delete style={{ color: 'red', fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  )
}

UserDetails.displayName = 'UserDetails'

/**
 * The properties.
 * @type {Object}
 */

UserDetails.propTypes = {
  userData: PropTypes.instanceOf(Object).isRequired,
}

export default UserDetails
