/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, Divider, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import { formatDate } from '../../shared/helpers/formatDate'
import './GlobalSearch'
import PropTypes from 'prop-types'
import { OpenInNew } from '@material-ui/icons'
import { IconButton } from '@mui/material'
import { useViewStateContext } from '../../context/viewStateContext'

/**
 * Chart
 * @param {object} data - global listing data
 * @param {object} filters - current applied filters
 */

const GlobalListing = ({ data, filters }) => {
  const { addWorkspace } = useViewStateContext()
  const [hoveredItem, setHoveredItem] = useState(null)

  const handleOpenPiece = (id, type, profileType, title, disableOpenInNew) => {
    const tab = !filters.currentTab ? 'all' : filters.currentTab
    if (tab === 'all' || tab === 'ioc') {
      type === 'profile' && profileType
        ? addWorkspace(profileType, id, title, filters, disableOpenInNew)
        : addWorkspace(type, id, title, filters, disableOpenInNew)
    } else {
      const typeFromParameters =
        tab === 'threat-alert'
          ? 'threat-alerts'
          : tab === 'malware-tools'
          ? 'malware-tools'
          : tab.slice(0, -1)
      addWorkspace(typeFromParameters, id, title, filters, disableOpenInNew)
    }
  }

  return (
    <div className='global-search__listing'>
      <div className='global-search__listing-inner'>
        {data?.map(
          ({
            id,
            module,
            typeSlug,
            priority,
            title,
            priorityName,
            capability,
            capabilityName,
            known_as,
            content_label,
            published_updated_at,
            author,
            read,
          }) => (
            <div
              className='listing-item'
              onMouseEnter={() => setHoveredItem(id)}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={() =>
                typeSlug !== 'country' &&
                handleOpenPiece(id, module, typeSlug, title)
              }
              key={
                filters.currentTab === 'all' || filters.currentTab === 'ioc'
                  ? module + '_' + id
                  : module + id
              }
            >
              <div className='listing-item__left'>
                <div className='priority'>
                  <Avatar
                    style={{
                      backgroundColor: 'transparent',
                      border: '2px solid #767a82',
                    }}
                  >
                    <span
                      style={{
                        fontSize: 24,
                        padding:
                          module === 'alert'
                            ? '0 0 2px 0'
                            : module === 'scenario'
                            ? '2px 0 0 0 '
                            : 0,
                      }}
                      className={`icon-${
                        module === 'profile' ? typeSlug : module
                      }`}
                    />
                  </Avatar>
                </div>

                {priority > 0 && priorityName && (
                  <div className='priority'>
                    <Tooltip
                      arrow
                      placement='top'
                      title={priorityName ? priorityName : capabilityName}
                    >
                      <Avatar className={`level-${priority}`}>
                        {priority}
                      </Avatar>
                    </Tooltip>
                  </div>
                )}

                {capability > 0 && capabilityName && (
                  <div className='priority'>
                    <Tooltip
                      arrow
                      placement='top'
                      title={priorityName ? priorityName : capabilityName}
                    >
                      <Avatar className={`level-${capability}`}>
                        {capability}
                      </Avatar>
                    </Tooltip>
                  </div>
                )}
              </div>

              <div className='listing-item__right'>
                <h4 style={{ color: '#fff' }}>{`${title}${
                  known_as ? ' - ' + known_as : ''
                }`}</h4>

                <Divider
                  style={{
                    backgroundColor: '#767A82',
                    width: '100%',
                    margin: '15px 0',
                  }}
                />

                <span style={{ color: '#ffffff80', fontSize: 12 }}>
                  Updated at: {formatDate(published_updated_at)}
                </span>

                <span style={{ color: '#ffffff80', fontSize: 12 }}>
                  Author: {author}
                </span>

                <div className='labels'>
                  {content_label && (
                    <span className='label-status'>{content_label}</span>
                  )}

                  {read === false && <span className='label-read'>Unread</span>}
                </div>
              </div>

              {hoveredItem === id && (
                <Tooltip arrow placement='top' title='Open in new workspace'>
                  <IconButton
                    className='open-new-tab'
                    style={{
                      position: 'absolute',
                      right: 20,
                      top: 6,
                      padding: 9,
                    }}
                    onClick={(event) => {
                      const disableOpenInNew = true
                      event.preventDefault()
                      event.stopPropagation()
                      handleOpenPiece(
                        id,
                        module,
                        typeSlug,
                        title,
                        disableOpenInNew,
                      )
                    }}
                  >
                    <OpenInNew style={{ color: '#fff', fontSize: 24 }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ),
        )}
      </div>
    </div>
  )
}

GlobalListing.displayName = 'GlobalListing'

/**
 * The properties.
 * @type {Object}
 */

GlobalListing.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
}

export default GlobalListing
