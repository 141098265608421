/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import reject from 'lodash/reject'
import { useChat } from '../../context/chatContext'
import { markAsRead } from '../services/api/chat/chat'

interface Props {
  readonly children?: any
  readonly hasMore?: boolean
  readonly reverse?: boolean
  readonly threshold?: number
  readonly onLoadMore?: any
  readonly rootSentinelRef?: any
  readonly data?: any[]
  readonly chatType: 'group' | 'direct'
  readonly chatId: number | null
  readonly totalUnread: number | null
  setHiddenUnreadMessages?: any
  readonly canLoadOlder?: boolean
  readonly canLoadNewer?: boolean
  readonly hiddenUnreadMessages?: number | null
  readonly isFetching?: boolean
}

const InfiniteScroll = ({
  children,
  hasMore = true,
  threshold = 0.9,
  onLoadMore,
  rootSentinelRef,
  data,
  setHiddenUnreadMessages,
  hiddenUnreadMessages,
  canLoadOlder,
  canLoadNewer,
  isFetching,
  chatType,
  chatId,
}: Props) => {
  const useRefVariable = (value: any) => {
    const ref = useRef()
    ref.current = value
    return ref
  }
  const olderMsgRef = useRef<any>()
  const newerMsgRef = useRef<any>()
  const hasMoreRef = useRefVariable(hasMore)
  const { activeChat, activeUserChat, setPeople, setGroupChats, chatOpen } =
    useChat()
  const [shouldJustify, setShouldJustify] = useState(false)
  const [scrollDirection, setScrollDirection] = useState<string | null>(null)
  const [shouldInitMarkRead, setShouldInitMarkRead] = useState<boolean>(true)
  const [visibleUnread, setVisibleUnread] = useState<[] | string[]>([])
  useEffect(() => {
    const rootSentinel = rootSentinelRef?.current
    const olderMsg = olderMsgRef?.current
    const newerMsg = newerMsgRef?.current
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry.isIntersecting && canLoadOlder && !isFetching) {
          if (entry?.target == olderMsg) {
            setScrollDirection('top')
            onLoadMore('old')
          } else if (entry?.target == newerMsg) {
            setScrollDirection('bottom')
            rootSentinelRef.current.scrollTo(0, -2)
            onLoadMore('new')
          }
        }
      },
      {
        root: rootSentinel,
        threshold,
      },
    )
    olderMsg && observer.observe(olderMsg)
    newerMsg && observer.observe(newerMsg)
    return () => {
      olderMsg && observer.unobserve(olderMsg)
      newerMsg && observer.unobserve(newerMsg)
    }
  }, [threshold, hasMoreRef, onLoadMore])

  const visibleMessagesHandler = (shouldObjectify?: boolean | undefined) => {
    return cloneDeep(data)
      ?.map(({ id }) => {
        const target = rootSentinelRef?.current?.querySelector(`#id_${id}`)
        const top = target?.getBoundingClientRect().top || 0
        if (target && top > 150) {
          return shouldObjectify ? { id: id } : id
        }
      })
      .filter((i) => i)
  }

  const handleReadMessages = async (
    isInitial: boolean,
    unreadItems: string[],
  ) => {
    const unreadData = {
      ids: unreadItems,
      mark_read_all: false,
      chat_id: chatId,
      chat_type: chatType,
    }
    if (!unreadItems?.length) return false
    data?.map((item) => {
      if (unreadItems?.includes(item?.id + '')) {
        item.read_at = ''
        return item
      } else return item
    })
    const token = () => localStorage.getItem('access_token')
    await markAsRead(token(), unreadData)
    if (chatType === 'direct') {
      setPeople((prevState) =>
        handleUnreadLabel(prevState, 'people', unreadItems?.length),
      )
    } else {
      setGroupChats((prevState) =>
        handleUnreadLabel(prevState, 'group', unreadItems?.length),
      )
    }
  }

  useEffect(() => {
    setShouldInitMarkRead(true)
  }, [activeChat?.id, activeUserChat?.id])

  const handleUnreadLabel = (data: any, type: string, unreadNumber: number) => {
    const newPeopleList = data?.list?.map((chat: any) => {
      if (
        (type === 'people' &&
          chat.central_user_id === data?.currentChatId?.id) ||
        (type === 'group' && chat?.id === data?.currentChatId?.id)
      ) {
        return {
          ...chat,
          chat_settings: {
            ...chat.chat_settings,
            total_unread_messages:
              chat.chat_settings.total_unread_messages - unreadNumber < 0
                ? 0
                : chat.chat_settings.total_unread_messages - unreadNumber,
          },
        }
      } else {
        return chat
      }
    })
    return { list: newPeopleList, currentChatId: data.currentChatId }
  }

  useEffect(() => {
    if (shouldInitMarkRead && visibleUnread?.length) {
      handleReadMessages(true, visibleUnread)
      setShouldInitMarkRead(false)
    }
  }, [visibleUnread])

  const scrollhandler = debounce(async () => {
    const unread =
      data
        ?.filter(
          ({ read_at, id }) =>
            read_at === null && visibleMessagesHandler()?.includes(id),
        )
        ?.map((i) => i?.id) || []
    unread?.length > 0 && handleReadMessages(false, unread)

    const hiddenMessages = reject(
      data?.filter(({ read_at }) => read_at === null),
      (item) => find(visibleMessagesHandler(true), { id: item?.id }),
    )

    data?.map((item) => {
      if (visibleUnread?.includes(item?.id as never)) {
        item.read_at = ''
        return item
      } else return item
    })

    setHiddenUnreadMessages(hiddenMessages?.length)

    const target =
      rootSentinelRef?.current
        ?.querySelector('.chat__single-conversation-separator-wrapper-new')
        ?.getBoundingClientRect().top || 0

    const isNewSeparatorVisible = target > 100
    isNewSeparatorVisible && setHiddenUnreadMessages(0)
  }, 100)

  useEffect(() => {
    setTimeout(() => {
      let totalHeight = 0
      Array?.from(rootSentinelRef?.current?.children)?.forEach(
        (child: any) => (totalHeight = totalHeight + child.clientHeight),
      )
      const lowerThanParent =
        totalHeight < rootSentinelRef?.current?.clientHeight - 80
      setShouldJustify(lowerThanParent || false)
      const hiddenMessages = reject(
        data?.filter(({ read_at }) => !read_at),
        (item) => find(visibleMessagesHandler(true), { id: item?.id }),
      )
      setHiddenUnreadMessages(hiddenMessages?.length)

      const unread =
        data
          ?.filter(
            ({ read_at, id }) =>
              !read_at && visibleMessagesHandler()?.includes(id),
          )
          ?.map((i) => i?.id) || []

      setVisibleUnread(unread)
      data?.map((item) => {
        if (unread?.includes(item?.id as never)) {
          item.read_at = ''
          return item
        } else return item
      })
    }, 0)
    rootSentinelRef?.current?.addEventListener('scroll', scrollhandler)
    return () => {
      rootSentinelRef?.current?.removeEventListener('scroll', scrollhandler)
    }
  }, [data, chatOpen])

  return (
    <div
      ref={rootSentinelRef}
      className='infinite-scroll'
      style={{
        overflow: isFetching ? 'hidden' : 'auto',
        justifyContent: shouldJustify ? 'flex-end' : '',
      }}
    >
      {canLoadNewer && (
        <div
          ref={newerMsgRef}
          style={{
            display: 'flex',
            minHeight: 1,
            visibility: 'hidden',
            // marginBottom: ',
          }}
        />
      )}

      {children}

      {canLoadOlder && (
        <div
          ref={olderMsgRef}
          style={{
            display: 'flex',
            minHeight: 50,
            visibility: 'hidden',
            marginBottom: '-150px',
          }}
        />
      )}
    </div>
  )
}

export default InfiniteScroll
