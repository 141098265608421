export const handlePayload = (
  payload,
  pageValue,
  sortPayload,
  ioc,
  searchValue,
) => {
  if (sortPayload) {
    payload = sortPayload
  }

  if (pageValue && pageValue !== 'search') {
    payload.page = pageValue
  }

  if (ioc.search && searchValue.length > 0) {
    payload.keyword = searchValue
  }

  if (ioc.sortBy) {
    payload.sortBy = ioc.sortBy
  }

  if (ioc.sortOrder) {
    payload.sortOrder = ioc.sortOrder
  }

  if (searchValue.length === 0) {
    delete payload.keyword
  }

  return payload
}
