import client from '../../../http/client'

import { apiUrl } from '../../../../config/index'

export const sectors = (token) =>
  client.get(apiUrl + `/api/sectors`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleSector = (token, id) =>
  client.get(apiUrl + `/api/sectors/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const editSector = (token, id, data) =>
  client.put(apiUrl + `/api/sectors/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const addSector = (token, data) =>
  client.post(apiUrl + `/api/sectors`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const organisationsList = (token) =>
  client.get(apiUrl + `/api/apps/list-with-organisations?nopagination`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const tagsList = (token, keyword) =>
  client.get(apiUrl + `/api/tags?keyword=${keyword}&nopagination`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
