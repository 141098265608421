import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import './Support.scss'
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Delete, Upload } from '@mui/icons-material'
import { KeyboardArrowDown } from '@material-ui/icons'
import { priorityItems, ticketConfig, ticketTypes } from './supportConfig'
import BaseButton from '../../shared/components/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import useLogic from './useSupport'

const Support = ({}) => {
  const {
    handleSubmitTicket,
    handleTicketState,
    ticketState,
    isTicketSent,
    isLoading,
    leftMenuVisible,
    priorityMenu,
    setTicketSent,
    setTicketState,
    setPriorityMenu,
  } = useLogic()
  const { push } = useHistory()

  return (
    <div className='support' style={{ width: leftMenuVisible ? null : '100%' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: 50,
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: '800px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: 40,
          }}
        >
          <BaseButton
            background='white'
            isDarkFontColor
            onClick={() => push(ticketState.redirect_url || '/app/workspace')}
          >
            <ArrowBackIcon style={{ marginRight: '4px' }} />

            <span style={{ fontSize: '16px' }}>Back</span>
          </BaseButton>

          <h2 style={{ color: '#fff', marginLeft: '100px' }}>
            Cant find the help you need? Contact Us.
          </h2>
        </div>

        <div style={{ maxWidth: 800, width: '100%' }}>
          {isTicketSent ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <BaseButton onClick={() => setTicketSent(false)}>
                Send another ticket?
              </BaseButton>
            </div>
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault()
                handleSubmitTicket()
              }}
            >
              <h5>Select ticket type *</h5>

              <Select
                value={ticketState.type}
                fullWidth
                required
                variant='outlined'
                displayEmpty
                renderValue={
                  ticketState.type === ''
                    ? () => <div style={{ color: '#aaa' }}>Select type</div>
                    : undefined
                }
                onChange={(event) =>
                  handleTicketState('type', event.target.value)
                }
              >
                {ticketTypes.map((item) => (
                  <MenuItem key={item.id} value={item.type}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>

              {ticketState.type !== '' &&
                ticketConfig[ticketState.type].map((item, index) => {
                  return (
                    <div key={index}>
                      {item.type === 'single-line' && (
                        <>
                          <h5>{item.label} *</h5>

                          <TextField
                            placeholder={item.label}
                            variant='outlined'
                            value={ticketState[item.slug] || ''}
                            required={item.required}
                            onChange={(event) =>
                              handleTicketState(item.slug, event.target.value)
                            }
                            fullWidth
                          />
                        </>
                      )}

                      {item.type === 'multi-line' && (
                        <>
                          <h5>{item.label} *</h5>

                          <TextField
                            placeholder={item.label}
                            value={ticketState[item.slug] || ''}
                            onChange={(event) =>
                              handleTicketState(item.slug, event.target.value)
                            }
                            required={item.required}
                            multiline
                            fullWidth
                            rows={4}
                            variant='outlined'
                          />
                        </>
                      )}

                      {item.type === 'upload' && (
                        <Fragment>
                          <input
                            hidden
                            id='support-file-upload'
                            type='file'
                            onClick={(event) => (event.target.value = null)}
                            multiple
                            onChange={(event) =>
                              handleTicketState('files', event.target.files)
                            }
                          />

                          <label htmlFor='support-file-upload'>
                            <BaseButton
                              type='button'
                              className='upload-btn'
                              background='lightBlue'
                              variant='outlined'
                              component='span'
                            >
                              <Upload />

                              {item.label}
                            </BaseButton>
                          </label>
                        </Fragment>
                      )}

                      {item.type === 'select' && (
                        <>
                          <h5>Select a category *</h5>

                          <Select
                            value={ticketState.rfi_category}
                            fullWidth
                            required
                            variant='outlined'
                            displayEmpty
                            renderValue={
                              ticketState.rfi_category === ''
                                ? () => (
                                    <div style={{ color: '#aaa' }}>
                                      Select category
                                    </div>
                                  )
                                : undefined
                            }
                            onChange={(event) =>
                              handleTicketState(
                                'rfi_category',
                                event.target.value,
                              )
                            }
                          >
                            {item.options.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>

                          {ticketState.rfi_category !== '' &&
                            item.options
                              .filter(
                                (i) => i.id === ticketState.rfi_category,
                              )[0]
                              .fields.map((field, fieldIndex) => {
                                return (
                                  <Fragment key={fieldIndex + '_' + field.slug}>
                                    {field.type === 'single-line' && (
                                      <>
                                        <h5>{`${field.label} ${
                                          field.required ? '*' : ''
                                        }`}</h5>

                                        <TextField
                                          placeholder={field.label}
                                          variant='outlined'
                                          fullWidth
                                          required={field.required}
                                          value={ticketState[field.slug] || ''}
                                          onChange={(event) =>
                                            handleTicketState(
                                              field.slug,
                                              event.target.value,
                                            )
                                          }
                                        />
                                      </>
                                    )}

                                    {field.type === 'select' && (
                                      <>
                                        <h5>{field.label} *</h5>

                                        <Select
                                          value={ticketState[field.slug]}
                                          fullWidth
                                          variant='outlined'
                                          displayEmpty
                                          required={field.required}
                                          renderValue={
                                            ticketState[field.slug] === ''
                                              ? () => (
                                                  <div
                                                    style={{ color: '#aaa' }}
                                                  >
                                                    Select {field.label}
                                                  </div>
                                                )
                                              : undefined
                                          }
                                          onChange={(event) =>
                                            handleTicketState(
                                              field.slug,
                                              event.target.value,
                                            )
                                          }
                                        >
                                          {field.options.map((item) => (
                                            <MenuItem
                                              key={item.id}
                                              value={
                                                field.slug === 'rfi_type'
                                                  ? item.name
                                                  : item.id
                                              }
                                            >
                                              {item.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </>
                                    )}

                                    {field.type === 'multi-line' && (
                                      <>
                                        <h5>{`${field.label} ${
                                          field.required ? '*' : ''
                                        }`}</h5>

                                        <TextField
                                          placeholder={field.label}
                                          value={ticketState[field.slug] || ''}
                                          required={field.required}
                                          onChange={(event) =>
                                            handleTicketState(
                                              field.slug,
                                              event.target.value,
                                            )
                                          }
                                          multiline
                                          fullWidth
                                          rows={4}
                                          variant='outlined'
                                        />
                                      </>
                                    )}
                                  </Fragment>
                                )
                              })}
                        </>
                      )}
                    </div>
                  )
                })}

              {ticketState.files && ticketState.files.length > 0 && (
                <div className='files-wrapper'>
                  <h5>Files:</h5>

                  {[...ticketState.files].map((file, index) => (
                    <div className='files-item' key={index}>
                      <span>{file.name}</span>

                      <IconButton
                        onClick={() => {
                          const currentState = { ...ticketState }
                          const newFiles = Array.from(
                            currentState.files,
                          ).filter((item, ind) => ind !== index)
                          currentState.files = newFiles
                          setTicketState(currentState)
                        }}
                        style={{ padding: 6 }}
                      >
                        <Delete style={{ color: '#000' }} />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}

              <Divider style={{ margin: '20px 0' }} />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Button
                  className='support-priority'
                  style={{
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    background: 'transparent',
                  }}
                  onClick={(event) => setPriorityMenu(event.currentTarget)}
                  endIcon={<KeyboardArrowDown />}
                >
                  {ticketState.priority ? (
                    <span>
                      Priority{' '}
                      <span
                        style={{
                          color:
                            ticketState.priority === 1
                              ? 'blue'
                              : ticketState.priority === 2
                              ? 'orange'
                              : 'red',
                        }}
                      >
                        {
                          priorityItems.filter(
                            (i) => i.value === ticketState.priority,
                          )[0].name
                        }
                      </span>
                    </span>
                  ) : (
                    'Set priority *'
                  )}
                </Button>

                <Menu
                  anchorEl={priorityMenu}
                  open={priorityMenu != null}
                  onClose={() => setPriorityMenu(null)}
                  PaperProps={{
                    style: {
                      width: 150,
                    },
                  }}
                >
                  {priorityItems.map((item) => (
                    <MenuItem
                      key={item.value}
                      style={{
                        textTransform: 'capitalize',
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        handleTicketState('priority', item.value)
                        setPriorityMenu(null)
                      }}
                      value={item.value}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Menu>

                <BaseButton
                  // className='support-submit'
                  disabled={!ticketState.priority}
                  background='green'
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{
                        fontSize: 20,
                        color: '#fff',
                        width: 24,
                        height: 24,
                      }}
                    />
                  ) : (
                    <span>Submit</span>
                  )}
                </BaseButton>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default Support
