import client from '../../../http/client'

import { apiUrl } from '../../../../config/index'

export const list = (token, url, query) =>
  client.get(apiUrl + `/api/${url}?${query}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleItemData = (token, url, id) =>
  client.get(apiUrl + `/api/${url}/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const reorderFrameworks = (token, data) =>
  client.post(apiUrl + `/api/scenario-configuration/frameworks/reorder`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const addSingleItem = (token, url, typeData) =>
  client.post(apiUrl + `/api/${url}`, typeData, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const editSingleItem = (token, url, id, typeData) =>
  client.put(apiUrl + `/api/${url}/${id}`, typeData, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteSingleItem = (token, url, id) =>
  client.remove(apiUrl + `/api/${url}/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const resendAdminLink = (token, userId) =>
  client.post(
    apiUrl +
      `/api/apps/null/organisations/null/users/${userId}/resend-activation-link`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
