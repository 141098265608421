import { Dispatch, Fragment, SetStateAction } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { useUserContext } from '../../../../../../context/userContext'

// const replyMenuItem = { name: 'Reply' }
const editMenuItem = { name: 'Edit' }
const deleteMenuItem = { name: 'Delete' }
const reportMenuItem = { name: 'Report' }

interface HoverMenuProps {
  authorId?: number
  menuHoverAnchor: HTMLButtonElement | null
  setMenuHoverAnchor: Dispatch<SetStateAction<HTMLButtonElement | null>>
  handleMenuAction: (name: string) => void
  handleMenuHoverClose: () => void
}

const HoverMenu = ({
  setMenuHoverAnchor,
  handleMenuAction,
  menuHoverAnchor,
  handleMenuHoverClose,
  authorId,
}: HoverMenuProps) => {
  const { user } = useUserContext()

  let optionsMenu = []

  if (user?.user_info.central_user_id === authorId) {
    optionsMenu = [editMenuItem, deleteMenuItem]
  } else {
    optionsMenu = [reportMenuItem]
  }

  return (
    <Fragment>
      <IconButton
        style={{
          borderRadius: 6,
          padding: 0,
          color: 'white',
          backgroundColor: '#283143',
        }}
        onClick={(event) => setMenuHoverAnchor(event.currentTarget)}
        size='small'
      >
        <span className='icon-more' />
      </IconButton>

      <Menu
        anchorEl={menuHoverAnchor}
        keepMounted
        open={Boolean(menuHoverAnchor)}
        onClose={handleMenuHoverClose}
      >
        {optionsMenu.map(({ name }) => (
          <MenuItem onClick={() => handleMenuAction(name)} key={name}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  )
}

export default HoverMenu
