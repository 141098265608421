import { Button, Chip, CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import cloneDeep from 'lodash/cloneDeep'
import SingleGroupUser from './SingleGroupUser'
import { IconButton, Tooltip } from '@mui/material'
import sortBy from 'lodash/sortBy'
import { useQueryGetModules } from '../../../../shared/services/queries/admin/useQueryGetModules'

const SingleGroupEdit = ({
  title,
  isLoading,
  selectedOrganisations,
  organisationOptions,
  handleGroupEditData,
  expandedOrgs,
  handleExpandedOrgs,
  users,
  handleEditData,
  setPrivilegesModal,
  organisationsInApps,
  isGroupChatEnabled,
  isGroupForumEnabled,
}) => {
  const orgArray = Object.values(selectedOrganisations)
  const mappedUsers = (orgId) =>
    organisationOptions?.find((org) => org.id === orgId)?.users
  const isUserChosen = (orgId, userId) =>
    users[orgId]?.find((user) => user.id === userId)
  const { data: modules } = useQueryGetModules()

  let modulesToDisplay = modules?.list?.filter((module) => module.id !== 6)

  if (modules && !isGroupChatEnabled) {
    modulesToDisplay = [...modulesToDisplay].filter(
      (module) => module.id !== 12,
    )
  }

  if (modules && !isGroupForumEnabled) {
    modulesToDisplay = [...modulesToDisplay].filter(
      (module) => module.id !== 13,
    )
  }

  return (
    <div className='setup-section__users'>
      <h4 style={{ marginBottom: 10 }}>{title}</h4>

      <Autocomplete
        multiple
        blurOnSelect
        disableClearable
        filterSelectedOptions
        groupBy={(option) => option.app}
        getOptionSelected={(option, value) => option.id === value.id}
        options={organisationOptions || []}
        value={orgArray || []}
        getOptionLabel={(option) => option.name}
        onChange={async (event, newValue) => {
          const value = newValue[newValue.length - 1]
          if (!value?.id) return false

          const newVal = {}
          newVal[value.id] = {}
          newVal[value.id].id = value.id
          newVal[value.id].name = value.name
          newVal[value.id].module_access = {}
          value?.modules?.forEach((module) => {
            const moduleId = module.pivot.module_id
            newVal[value.id].module_access[moduleId] = {
              id: moduleId,
              role_id: 1,
            }
          })
          newVal[value.id].include_new_users = true
          newVal[value.id].make_anonymous = false

          const usersFromOrg = cloneDeep(organisationOptions).find(
            (org) => org.id === value.id,
          ).users
          const newUserArr = usersFromOrg.map((user) => {
            const userAccess = {
              ...user?.origin_organisation_privileges?.module_access,
            }

            Object.values(userAccess)?.forEach((module) => (module.role_id = 1))
            return {
              id: user.id,
              module_access: {
                ...userAccess,
                12: { id: 12, role_id: 1, is_extra_module: true },
              },
            }
          })

          handleEditData('new_group_users', newUserArr, value.id)
          handleGroupEditData(newVal)
        }}
        renderTags={() => {
          return false
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Find organisation or select from the list below'
            variant='outlined'
            className='tag-input'
            size='small'
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress size={20} /> : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      <div style={{ margin: '10px 0' }}>
        {orgArray.length > 0 &&
          orgArray.map((organisation) => (
            <Chip
              style={{
                backgroundColor: '#ffc9c9',
                color: '#000',
                margin: 2,
                fontSize: 11,
              }}
              onClick={() => {}}
              onDelete={() => handleGroupEditData(organisation.id, 'delete')}
              label={organisation.name}
              key={organisation.id}
              deleteIcon={
                <span
                  className='icon-close'
                  style={{
                    width: 'unset',
                    height: 'unset',
                    fontSize: 14,
                    paddingRight: 3,
                    color: '#000',
                  }}
                />
              }
            />
          ))}
      </div>

      {organisationsInApps.map(
        (application) =>
          application.organisations.length > 0 && (
            <div style={{ color: '#000' }} key={application.id}>
              <h3 style={{ marginBottom: 10 }}>
                Application: {application.name}
              </h3>

              {orgArray
                .filter((org) =>
                  application.organisations.some((org2) => org.id === org2.id),
                )
                .map((organisation) => (
                  <div
                    key={organisation.id}
                    className='org-item'
                    style={{
                      maxHeight: expandedOrgs.includes(organisation.id)
                        ? 500
                        : null,
                    }}
                  >
                    <div
                      style={{ position: 'sticky', top: 0 }}
                      className='org-item__heading'
                    >
                      <Button
                        onClick={() => handleExpandedOrgs(organisation.id)}
                        disableRipple
                        style={{
                          minWidth: expandedOrgs.includes(organisation.id)
                            ? null
                            : 'fit-content',
                        }}
                        startIcon={
                          <span
                            style={{
                              transform: expandedOrgs.includes(organisation.id)
                                ? 'rotate(180deg)'
                                : '',
                            }}
                            className='icon-down'
                          />
                        }
                        className='org-item__expand'
                      >
                        <b>{organisation.name}</b>
                      </Button>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '75%',
                          justifyContent: 'flex-end',
                          gap: 20,
                        }}
                      >
                        {expandedOrgs.includes(organisation.id) && (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                gap: 15,
                              }}
                            >
                              {modulesToDisplay
                                ?.filter((module) => module.name !== 'Analysis')
                                ?.map((module) => (
                                  <div
                                    key={module.id}
                                    style={{
                                      width: '25%',
                                      textAlign: 'center',
                                      fontSize: 12,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {module.name}
                                  </div>
                                ))}
                            </div>

                            <Tooltip
                              placement='top'
                              arrow
                              title='Default organisation privileges'
                            >
                              <IconButton
                                style={{ padding: '4px 5px' }}
                                onClick={() =>
                                  setPrivilegesModal(['org', organisation.id])
                                }
                              >
                                <span
                                  style={{ fontSize: 28, color: '#000' }}
                                  className='icon-settings'
                                />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </div>

                    {expandedOrgs.includes(organisation.id) && (
                      <div
                        className='user-wrapper'
                        style={{
                          marginRight:
                            mappedUsers(organisation.id).length >= 10
                              ? '-8px'
                              : 0,
                        }}
                      >
                        {sortBy(
                          mappedUsers(organisation.id),
                          (usr) => isUserChosen(organisation.id, usr.id),
                          ['first_name', 'asc'],
                        ).map((user) => (
                          <SingleGroupUser
                            user={user}
                            users={users}
                            orgId={organisation?.id}
                            orgModules={organisation?.module_access}
                            key={user.id}
                            handleEditData={handleEditData}
                            setPrivilegesModal={setPrivilegesModal}
                            organisationOptions={organisationOptions}
                            isGroupChatEnabled={isGroupChatEnabled}
                            isGroupForumEnabled={isGroupForumEnabled}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ),
      )}
    </div>
  )
}

export default SingleGroupEdit
