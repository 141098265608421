import React, { useEffect, useState } from 'react'
import { Route, useHistory, useLocation, Switch } from 'react-router-dom'
import { Button, Divider, TextField } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import Pagination from '@material-ui/lab/Pagination'
import './SystemSettingsList.scss'
import { IconButton, Tooltip } from '@mui/material'
import Spinner from '../../../shared/Spinner'
import ConfirmDelete from '../../ContentManage/ConfirmDelete'
import {
  deleteSingleItem,
  list,
  resendAdminLink,
} from '../../../shared/services/api/admin/settings/systemSettings'
import EditAdminUser from './EditLayouts/EditAdminUser'
import { Send } from '@material-ui/icons'
import useNotification from '../../../hooks/useNotification'
import { useAdmin } from '../../../context/adminContext'

const SystemSettingsList = ({ tabUrl, title, isTags }) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { adminState, setAdminState } = useAdmin()
  const history = useHistory()
  const location = useLocation()
  const [isLoading, setLoading] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(undefined)
  const [isDeleting, setDeleting] = useState(false)
  const tabApi = isTags ? tabUrl.split('/')[1] : tabUrl
  const [keyword, setKeyword] = useState(
    Object.fromEntries(new URLSearchParams(adminState[tabApi]?.previousQuery))
      ?.keyword || '',
  )
  const [page, setPage] = useState(
    +Object.fromEntries(new URLSearchParams(adminState[tabApi]?.previousQuery))
      ?.page || 1,
  )
  const { successToast, errorToast, warningToast } = useNotification()

  const getList = async (forceReload) => {
    try {
      const query = new URLSearchParams({
        keyword: keyword,
        page: page,
      }).toString()

      if (adminState[tabApi]?.previousQuery === query && !forceReload)
        return false
      setLoading(true)
      const response = await list(token(), tabApi, query)

      if (response?.list?.data?.length === 0) {
        warningToast({ message: 'No results found.' })
      }

      response.list.previousQuery = query
      setAdminState({ ...adminState, [tabApi]: response.list })
      setLoading(false)
    } catch {}
  }

  const handleDeleteType = async (id) => {
    setDeleting(true)
    try {
      const response = await deleteSingleItem(token(), tabApi, id)

      if (response.error) {
        errorToast({ message: response.message })
        setDeleting(false)
        return false
      }

      successToast({ message: response.message })
      await getList(true)
      setDeleting(false)
      setConfirmDelete(undefined)
    } catch {}
  }

  const handleResendLink = async (id) => {
    try {
      const response = await resendAdminLink(token(), id)

      if (response.error) {
        errorToast({ message: response.message })
        return false
      }

      successToast({ message: response.message })
    } catch {}
  }

  useEffect(() => {
    if (location.pathname === `/app/admin/system-settings/${tabUrl}`) {
      getList()
    }
  }, [location.pathname, page])

  return (
    <Switch>
      <Route exact path={`/app/admin/system-settings/${tabUrl}`}>
        <>
          {title && (
            <div className='groups-header'>
              <h2>{title}</h2>

              <Button
                onClick={() =>
                  history.push(`/app/admin/system-settings/${tabUrl}/new`)
                }
                variant='contained'
                className='create-new'
                color='primary'
              >
                Add new {title.slice(0, -1)}
              </Button>
            </div>
          )}

          {confirmDelete !== undefined && (
            <ConfirmDelete
              isDeleting={isDeleting}
              confirmDelete={confirmDelete}
              setConfirmDelete={setConfirmDelete}
              handleDeletePiece={handleDeleteType}
            />
          )}

          <Divider />

          {isLoading && <Spinner centerHorizontally padding={20} size={30} />}

          {!isLoading && (
            <>
              <div className='groups-list'>
                <form
                  onSubmit={(event) => {
                    event.preventDefault()
                    setPage(1)
                    getList()
                  }}
                >
                  <TextField
                    variant='outlined'
                    placeholder='Search'
                    InputProps={{
                      startAdornment: (
                        <span
                          style={{ fontSize: 22 }}
                          className='icon-search'
                        />
                      ),
                    }}
                    value={keyword}
                    onChange={(event) => setKeyword(event.target.value)}
                  />
                </form>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#e0e0e0',
                    padding: 16,
                    borderRadius: '10px',
                  }}
                >
                  <span style={{ width: '80%' }}>Name</span>

                  <span style={{ width: '20%', textAlign: 'right' }}>
                    Options
                  </span>
                </div>

                {/* {adminState[tabApi]?.data?.length === 0 && <div>No results found</div>} */}

                {adminState[tabApi]?.data?.map(
                  ({
                    id,
                    is_account_activated,
                    first_name,
                    last_name,
                    username,
                    name,
                    is_community_group,
                    is_deletable,
                  }) => (
                    <div key={id} className='list-item'>
                      <div className='list-item__left'>
                        {tabUrl === 'users' ? (
                          <div
                            style={{
                              fontSize: 14,
                              paddingLeft: 5,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Tooltip
                              arrow
                              placement='top'
                              title={
                                is_account_activated
                                  ? 'User Activated'
                                  : 'User has not activated their account yet'
                              }
                            >
                              <span
                                className={
                                  is_account_activated
                                    ? 'icon-circle-check'
                                    : 'icon-close'
                                }
                                style={{
                                  color: is_account_activated
                                    ? '#0bff00'
                                    : '#ff0033',
                                  fontSize: is_account_activated ? 24 : 20,
                                  marginRight: 5,
                                  padding: is_account_activated ? 0 : 2,
                                }}
                              />
                            </Tooltip>

                            <span>
                              {`${first_name} ${last_name} `}(<b>{username}</b>)
                            </span>
                          </div>
                        ) : (
                          <div style={{ fontSize: 14, paddingLeft: 15 }}>
                            <span>{name}</span>

                            {is_community_group && (
                              <span className='community-label'>
                                Community Group
                              </span>
                            )}
                          </div>
                        )}
                      </div>

                      <div className='list-item__right'>
                        {!is_account_activated && tabUrl === 'users' && (
                          <Tooltip
                            placement='top'
                            arrow
                            title='Resend activation link'
                          >
                            <IconButton
                              onClick={() => handleResendLink(id)}
                              style={{ padding: 7 }}
                            >
                              <Send
                                style={{ fontSize: 19, color: '#006fff' }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Button
                          onClick={() =>
                            history.push({
                              pathname: `/app/admin/system-settings/${tabUrl}/${id}`,
                              state: {
                                redirected_url: `/app/admin/system-settings/tags/tags`,
                              },
                            })
                          }
                          startIcon={<CreateIcon />}
                          className='edit'
                        >
                          Edit
                        </Button>

                        {((tabUrl === 'groups' && is_deletable) ||
                          tabUrl !== 'groups') && (
                          <Button
                            onClick={() => setConfirmDelete(id)}
                            className='delete'
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </div>
                  ),
                )}
              </div>

              <Pagination
                onChange={(event, value) => setPage(value)}
                style={{ marginTop: 20 }}
                page={page}
                count={adminState[tabApi]?.last_page}
                shape='rounded'
                hideNextButton
                hidePrevButton
                color='primary'
              />
            </>
          )}
        </>
      </Route>

      <Route
        exact
        path='/app/admin/system-settings/users/:id'
        component={EditAdminUser}
      />
    </Switch>
  )
}

export default SystemSettingsList
