import React, { useState } from 'react'
import { MenuItem } from '@material-ui/core'
import NestedMenuItem from 'material-ui-nested-menu-item'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import './NestedMenu.scss'

const NestedMenu = (props) => {
  const { contextMenuEvents, menu, selection } = props
  const [menuPosition] = useState({ top: menu.y, left: menu.x })
  let menuItems = []

  if (!menu.visible) {
    return null
  }

  let defaultItems = [
    { handleClick: contextMenuEvents['selectAll'], text: 'Select All' },
    { handleClick: contextMenuEvents['selectNone'], text: 'Clear Selection' },
  ]

  let combo = [{ handleClick: contextMenuEvents['rename'], text: 'Rename' }]

  const isCombo =
    Object.keys(selection).length > 0 &&
    Object.keys(selection)[0].startsWith('_combonode')
  const isNode =
    menu.id !== null &&
    Object.keys(selection).length === 1 &&
    selection[menu.id].type !== 'link' &&
    !isCombo
  const isOtherElement =
    Object.keys(selection).length > 1 ||
    (selection.length && selection[menu.id].type === 'link') ||
    menu.id == null

  if (isNode) {
    selection[menu?.id]?.contextMenu?.forEach((item) => {
      item.handleClick = contextMenuEvents[item.event]
    })
    menuItems = selection[menu.id].contextMenu
  } else if (isOtherElement && !isCombo) {
    menuItems = defaultItems
  } else if (isCombo) {
    menuItems = combo
  } else {
    menuItems = defaultItems
  }

  let expandMenuItems = menuItems?.filter((item) => item.type === 'expand')
  let closeMenuItems = menuItems?.filter((item) => item.type === 'close')
  let infoMenuItems = menuItems?.filter((item) => item.type === 'information')
  let menuItemsPerType = [
    { type: 'Expand', items: expandMenuItems },
    { type: 'Close', items: closeMenuItems },
  ]

  if (selection[menu.id]?.type !== 'tag') {
    menuItemsPerType.push({ type: 'Info', items: infoMenuItems })
  }

  return (
    <>
      {!isNode && (
        <div className='menu' style={{ left: menu.x, top: menu.y }}>
          {menuItems?.map(({ text, handleClick }) => (
            <MenuItem button key={text} onClick={handleClick}>
              {text}
            </MenuItem>
          ))}
        </div>
      )}

      {isNode && (
        <div className='menu' style={{ left: menu.x, top: menu.y }}>
          {menuItemsPerType?.map(({ type, items }) => (
            <NestedMenuItem
              key={type}
              label={type}
              parentMenuOpen={!!menuPosition}
            >
              {items?.map(({ text, handleClick }) => (
                <ListItem
                  button
                  style={{ fontSize: 24 }}
                  key={text}
                  className='menu-item'
                  onClick={handleClick}
                >
                  <ListItemText style={{ fontSize: 24 }} primary={text} />
                </ListItem>
              ))}
            </NestedMenuItem>
          ))}
        </div>
      )}
    </>
  )
}

export default NestedMenu
