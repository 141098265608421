import React, { useEffect, useState } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import Login from '../Login'
import ForgotPassword from '../Login/ForgotPassword'
import './Auth.scss'
import TwoFactorAuthentication from '../Login/TwoFactorAuthentication'
import { ReactComponent as Logo } from '../../assets/seca-logo.svg'
import { default as background } from '../../assets/login-bg-new.png'
import ResetPassword from '../Login/ResetPassword/ResetPassword'
import TwoFactorAuthenticationConfirm from '../Login/TwoFactorAuthenticationConfirm/TwoFactorAuthenticationConfirm'
import ActivateAccount from '../Login/ActiateAccount'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { useForm } from 'react-hook-form'

/**
 * Auth
 * @param {string|null|number} savedUrlForRedirection - url if opened with params to open a piece of content
 */

const Auth = ({}) => {
  const history = useHistory()
  const [toastMessage, setToastMessage] = useState(null)
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const isAuth = JSON.parse(localStorage.getItem('auth'))

    isAuth && history.push('/app/workspace')
  }, [])

  return (
    <div className='auth'>
      <div
        style={{ backgroundImage: `url(${background})` }}
        className='auth-background'
      />

      {toastMessage && (
        <Snackbar
          autoHideDuration={3000}
          onClose={() => setToastMessage(undefined)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={toastMessage !== undefined}
        >
          <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
            {toastMessage[1]}
          </MuiAlert>
        </Snackbar>
      )}

      <div className='auth-container'>
        <div className='auth-wrapper'>
          <div className='auth__header--logo'>
            <Logo />
          </div>

          <Switch>
            <Route exact path='/app/auth/login'>
              <Login
                setToastMessage={setToastMessage}
                getValues={getValues}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
              />
            </Route>

            <Route exact path='/app/auth/two-factor-authentication'>
              <TwoFactorAuthentication
                getValues={getValues}
                setToastMessage={setToastMessage}
              />
            </Route>

            <Route exact path='/app/auth/two-factor-authentication-confirm'>
              <TwoFactorAuthenticationConfirm getValues={getValues} />
            </Route>

            <Route exact path='/app/auth/reset/:hash/:id'>
              <ResetPassword />
            </Route>

            <Route exact path='/app/auth/activate/:hash/:id'>
              <ActivateAccount setToastMessage={setToastMessage} />
            </Route>

            <Route exact path='/app/auth/forgot-password'>
              <ForgotPassword setToastMessage={setToastMessage} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

Auth.displayName = 'Auth'

/**
 * The properties.
 * @type {Object}
 */

Auth.defaultProps = {
  savedUrlForRedirection: null,
}

Auth.propTypes = {}

export default Auth
