import { useState } from 'react'
import './Moderation.scss'
import { Tabs, Tab } from '@material-ui/core'

import { ReportedComments } from './components/ReportedComments'
import { ReportedChatMessages } from './components/ReportedChatMessages'
import { ReportedForumContent } from './components/ReportedForumContent'

const tabs = [
  { name: 'Comments', slug: 'comments' },
  { name: 'Chat', slug: 'chat' },
  { name: 'Forum', slug: 'forum' },
]

const Moderation = ({}) => {
  const [tab, setTab] = useState('comments')

  return (
    <div className='moderation'>
      <h2 className='moderation__title'>Moderation</h2>

      <Tabs
        value={tab}
        indicatorColor='primary'
        onChange={(_, newValue) => setTab(newValue)}
      >
        {tabs.map(({ name, slug }) => (
          <Tab
            key={name}
            label={name}
            value={slug}
            // disableRipple
          />
        ))}
      </Tabs>
      {tab === 'comments' && <ReportedComments type={tab} />}
      {tab === 'chat' && <ReportedChatMessages type={tab} />}
      {tab === 'forum' && <ReportedForumContent type={tab} />}
    </div>
  )
}

export default Moderation
