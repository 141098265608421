export const TEMPLATES = {
  text: {
    title: '',
    type: 'text',
    content: [
      { type: 'video', url: '', isExpanded: true },
      { type: 'text', headline: '', content: '', isExpanded: true },
      {
        type: 'button',
        headline: '',
        strip_colour: '',
        colour: '',
        url: '',
        icon: '',
        text: '',
        isExpanded: true,
      },
    ],
  },
  // video: {
  //   title: '',
  //   type: 'video',
  //   content: [
  //     { type: 'video', url: '', isExpanded: true },
  //     { type: 'text', headline: '', content: '', isExpanded: true },
  //   ]
  // }
}
