import { Button, Dialog, Divider, MenuItem, Select } from '@material-ui/core'
import { Dispatch, SetStateAction, useState } from 'react'
import Spinner from '../../../../../shared/Spinner/Spinner'

const reportReasons = [
  { id: 1, name: 'Inappropriate language' },
  { id: 2, name: 'Spam' },
  { id: 3, name: 'Harassment' },
  { id: 10, name: 'Something else' },
]

interface ReportModalProps {
  reportDialog: undefined | number
  setReportDialog: Dispatch<SetStateAction<number | undefined>>
  reportMessageCallback: (reportMsg: string) => void
}

const ReportModal = ({
  reportDialog,
  setReportDialog,
  reportMessageCallback,
}: ReportModalProps) => {
  const [reportReason, setReportReason] = useState('')
  const [isLoading, setLoading] = useState(false)

  return (
    <Dialog
      open={reportDialog !== undefined}
      fullWidth
      maxWidth='xs'
      onClose={() => {
        setReportDialog(undefined)
        setTimeout(() => setReportReason(''), 100)
      }}
    >
      <div style={{ padding: '20px 25px' }}>
        <h3>Report Message</h3>

        <Divider style={{ width: '100%', margin: '15px 0' }} />

        <Select
          fullWidth
          onChange={(event) => setReportReason(event.target?.value as string)}
          variant='outlined'
          value={reportReason}
          // placeholder='Select reason'
          displayEmpty
          renderValue={
            reportReason === ''
              ? () => <span style={{ color: '#aaa' }}>Select reason</span>
              : undefined
          }
          //   onClose={() =>
          //     setTimeout(
          //       () => document.activeElement && document.activeElement.blur(),
          //       0,
          //     )
          //   }
        >
          {reportReasons.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <Button
            onClick={() => {
              setReportDialog(undefined)
              setTimeout(() => setReportReason(''), 100)
            }}
            style={{
              textTransform: 'capitalize',
              padding: '4px 15px',
              borderRadius: 12,
              color: '#000',
            }}
            // variant='contained'
            color='primary'
          >
            Cancel
          </Button>

          <Button
            style={{
              textTransform: 'capitalize',
              backgroundColor: reportReason === '' ? '#aaa' : '#006fff',
              color: '#fff',
              padding: '5px 15px',
              borderRadius: 12,
              minWidth: 85,
            }}
            // variant='contained'
            disabled={reportReason === ''}
            onClick={async () => {
              setLoading(true)
              await reportMessageCallback(reportReason)
              setLoading(false)
            }}
            color='primary'
          >
            {isLoading ? <Spinner color='#fff' size={22.5} /> : 'Confirm'}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default ReportModal
