/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Tooltip, ClickAwayListener } from '@material-ui/core'
import { deepSearch } from '../../../../../../shared/helpers/deepSearch'
import { useUserContext } from '../../../../../../context/userContext'
import parse from 'html-react-parser'
import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react'
import ExternalLink from '../ExternalLink'
import HoverMenu from './HoverMenu'
import {
  deleteMessage,
  reportMessage,
} from '../../../../../../shared/services/api/chat/chat'
import { useChat } from '../../../../../../context/chatContext'
import { ReactionPopup } from './ReactionPopup'
import ReportModal from '../ReportModal'
import useNotification from '../../../../../../hooks/useNotification'
import { ChatData } from '../../SingleConversation'
import { formatChatMsgDate } from '../../../../../../shared/helpers/formatDate'

interface ExternalLinkData {
  readonly description: string
  readonly image: string
  readonly title: string
  readonly url: string
}
interface Props {
  // readonly cachedPiecesOfContent: any
  hoveredMessageId: number | null
  readonly userId: number | undefined
  readonly setShowPreview: Dispatch<any>
  readonly id: number | undefined
  readonly content: string | undefined
  readonly external_link?: string | null | undefined
  readonly external_link_data?: ExternalLinkData[] | null | undefined
  readonly type?: string | undefined
  readonly contentType?: string | undefined
  readonly contentId?: number | string | undefined
  readonly created_at?: string
  readonly onAddReaction: (id?: number, emoticon?: string) => void
  readonly reactions: any[]
  readonly chatId: number | null
  readonly chatType: 'direct' | 'group'
  readonly isBeingCached?: boolean
  readonly showPreview: any
  readonly setChatData: Dispatch<SetStateAction<[] | ChatData[]>>
  readonly deletedAt?: string
  readonly setIsUserLeaving: Dispatch<string>
  readonly highlightedId: string | null
  setHoveredMessageId: Dispatch<SetStateAction<number | null>>
  is_reported: number
  readonly mention_ids?: number[] | []
}
const isValidURL = (url: string) => {
  const regex =
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
  return regex?.test(url)
}
const EMOJI = [
  { value: '😀', id: '1' },
  { value: '🙂', id: '2' },
  { value: '❤️', id: '3' },
  { value: '👍', id: '4' },
  { value: '😊', id: '5' },
  { value: '😁', id: '6' },
  { value: '😃', id: '7' },
  { value: '😄', id: '8' },
  { value: '😎', id: '9' },
  { value: '😧', id: '10' },
  { value: '🙌', id: '11' },
  { value: '👎', id: '12' },
  { value: '😠', id: '13' },
  { value: '😮', id: '14' },
  { value: '😔', id: '15' },
]

const SingleMessage = ({
  is_reported,
  hoveredMessageId,
  setHoveredMessageId,
  userId,
  setShowPreview,
  id,
  content,
  external_link,
  external_link_data,
  type,
  contentType,
  contentId,
  created_at,
  deletedAt,
  onAddReaction,
  reactions,
  chatId,
  chatType,
  isBeingCached,
  showPreview,
  setChatData,
  setIsUserLeaving,
  highlightedId,
  mention_ids,
}: Props) => {
  const { user } = useUserContext()
  const [isEmojiPanelVisible, setIsEmojiPanelVisible] = useState<boolean>(false)
  const [isReactionPopupOpen, setIsReactionPopupOpen] = useState<boolean>(false)
  const msgWidth = useRef<HTMLDivElement | null>(null)
  const { successToast, errorToast } = useNotification()
  const [reportDialog, setReportDialog] = useState<undefined | number>(
    undefined,
  )

  const [menuHoverAnchor, setMenuHoverAnchor] =
    useState<HTMLButtonElement | null>(null)
  const currentInstance = process.env.REACT_APP_CURRENT_INSTANCE
  const parentRef = useRef<any>(null)
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { cachedPiecesOfContent, editedMessageId, setEditedMessageId } =
    useChat()

  const handleMenuHoverClose = () => setMenuHoverAnchor(null)
  const handleMenuAction = async (action: string) => {
    setMenuHoverAnchor(null)

    if (action === 'Delete') {
      await deleteMessage(token(), {
        id: id ?? null,
        chat_id: chatId,
        chat_type: chatType,
      })
    } else if (action === 'Edit') {
      setEditedMessageId(id ?? null)
    } else if (action === 'Report') {
      setReportDialog(id)
    }
  }

  const emojiCounter = useMemo(
    () =>
      [...(reactions ?? [])]?.reduce((prev, next) => {
        if (!prev[next.content]) {
          return {
            ...prev,
            [next.content]: 1,
          }
        }
        return {
          ...prev,
          [next.content]: prev[next.content] + 1,
        }
      }, {}) ?? [],
    [reactions],
  )
  const myReaction = useMemo(
    () =>
      [...(reactions ?? [])]?.find(
        (reaction) =>
          user?.user_info.central_user_id === reaction.author?.centralised_id,
      ),
    [reactions],
  )

  const convertMessageWithLink = (content: any) => {
    let finalContent: any[] = []
    const currentContent = content
    currentContent
      ?.replaceAll(
        /(<(\w+)(?:[^>]*class="content-link")[^>]*>)([^<]*)<\/\2>/gim,
        (x: string, y: string) => (y ? x.replaceAll(/<[^>]*>/g, '') : ' '),
      )
      ?.split(' ')
      ?.forEach((msg: string) => {
        if (isValidURL(msg)) {
          const query = new URL(
            parse(msg?.replaceAll(/<[^>]*>/g, '')) as string,
          )
          const id = query?.searchParams?.get('id')
          let type = query?.searchParams?.get('type')?.toLowerCase() || ''
          const ukAppUrl = process.env.REACT_APP_UK_APP_URL
          const euAppUrl = process.env.REACT_APP_EU_APP_URL
          const isLinkOutsideInternal =
            query?.origin !== ukAppUrl &&
            query?.origin !== euAppUrl &&
            query.origin !== 'http://localhost:3000'

          if (id && type && isLinkOutsideInternal) {
            //if link is with id and type, but is not internal
            finalContent = [
              ...finalContent,
              `<span class='content-link external'>${parse(query.href)}</span>`,
            ]
          }
          const possibleTypes = [
            'alert',
            'profile',
            'threat-actor',
            'operation',
            'malware-tools',
            'incident',
            'scenario',
            'report',
          ]
          const profileTypes = [
            'threat-actor',
            'operation',
            'malware-tools',
            'incident',
          ]
          if (profileTypes.includes(type)) {
            type = 'profile'
          }
          if (!id || !type) {
            finalContent = [
              ...finalContent,
              `<span class='content-link external'>${parse(query.href)}</span>`,
            ]
          } else {
            if (isLinkOutsideInternal) {
              return false
            }
            const originInstance =
              query.origin === 'http://localhost:3000'
                ? 'eu'
                : query?.origin == ukAppUrl //if query origin is UK instance
                ? 'uk'
                : query?.origin == euAppUrl && 'eu' //if query origin is EU/AMS instance
            const altContent: any =
              currentInstance !== originInstance
                ? Object.values(cachedPiecesOfContent[type])?.find(
                    (item: any) => {
                      return (
                        +id ===
                        (originInstance === 'uk' ? item?.uk_id : item?.eu_id)
                      )
                    },
                  )
                : null
            let altId = null

            if (altContent) {
              if (currentInstance === 'uk') {
                altId = cachedPiecesOfContent[type][altContent.uk_id]?.title
              } else {
                altId = cachedPiecesOfContent[type][altContent.eu_id]?.title
              }
            }

            const cachedTitle =
              (cachedPiecesOfContent && id && type && altContent
                ? altId
                : cachedPiecesOfContent[type][id]?.title) ||
              'Content restricted'
            let title = ''
            if (cachedTitle) {
              title = cachedTitle
            } else {
              title =
                (isBeingCached && 'Loading content') ||
                !possibleTypes.includes(type)
                  ? 'link error'
                  : ''
            }
            finalContent = [
              ...finalContent,
              `<span class='content-link'>${title}</span>`,
            ]
          }
        } else {
          finalContent = [...finalContent, msg]
        }
      })

    const joinedContent = finalContent.join(' ')
    if (mention_ids && mention_ids?.length > 0) {
      return parse(
        joinedContent?.replaceAll(
          `<span class="content-mention">${user?.user_info?.chat_display_name}</span>`,
          `<span class="content-mention mentioned">${user?.user_info?.chat_display_name}</span>`,
        ),
      )
    }
    return parse(joinedContent)
  }

  const highlightMentions = (content: any) => {
    if (mention_ids && mention_ids?.length > 0) {
      return parse(
        content?.replaceAll(
          `<span class="content-mention">${user?.user_info?.chat_display_name}</span>`,
          `<span class="content-mention mentioned">${user?.user_info?.chat_display_name}</span>`,
        ),
      )
    } else {
      return parse(content)
    }
  }

  const reportMessageCallback = async (reportReason: string | null) => {
    const payload = {
      reason: reportReason,
      content_id: id,
      chat_id: chatId,
      chat_type: chatType,
      content_type: 'CHAT MESSAGE',
    }
    const response = (await reportMessage(token(), payload)) as unknown as {
      error: boolean
      message: string
    }

    if (response.error) {
      errorToast({ message: response.message })
      return false
    }

    successToast({ message: response.message })
    setChatData((prevState) => {
      return prevState.map((msg) =>
        msg.id == id ? { ...msg, content: 'This message is hidden' } : msg,
      )
    })
    setReportDialog(undefined)
  }

  const normalParse = (content: any, type: string) => {
    if (type === 'LINK') {
      return convertMessageWithLink(content)
    } else {
      return parse(content?.replaceAll(/<[^>]*>/g, ''))
    }
  }

  return (
    <>
      {isReactionPopupOpen && is_reported !== 1 && (
        <ReactionPopup
          reactions={reactions}
          setIsReactionPopupOpen={setIsReactionPopupOpen}
          isSender={user?.user_info?.central_user_id === userId}
        />
      )}
      {reportDialog ? (
        <ReportModal
          reportMessageCallback={reportMessageCallback}
          reportDialog={reportDialog}
          setReportDialog={setReportDialog}
        />
      ) : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          marginBottom: 12,
          width: '100%',
        }}
        ref={parentRef}
      >
        <div style={{ width: '100%' }}>
          <div
            style={{
              fontSize: 14,
              fontStyle: is_reported === 1 || !userId ? 'italic' : '',
              color:
                highlightedId === id + ''
                  ? '#000'
                  : is_reported === 1 || !userId
                  ? '#bababa'
                  : '',
              background:
                highlightedId === id + ''
                  ? '#fff'
                  : user?.user_info?.central_user_id === userId
                  ? '#006fff'
                  : '#4b5467',
              height: 'max-content',
            }}
            ref={msgWidth}
            onClick={(event) => {
              const target = event.target as Element
              if (
                target?.classList?.value === 'content-link external' &&
                isValidURL(target.innerHTML)
              ) {
                setIsUserLeaving(target.innerHTML)
              }
              if (target.classList.value !== 'content-link') return false
              const content =
                currentInstance &&
                deepSearch(
                  cachedPiecesOfContent,
                  'title',
                  (k: any, v: string) => v === target?.innerHTML,
                )

              if (!content) return false
              const contentId =
                currentInstance === 'uk' ? content?.uk_id : content?.eu_id
              if (showPreview && showPreview[1] === contentId) {
                setShowPreview(null)
                return false
              }
              const type =
                currentInstance &&
                Object.keys(cachedPiecesOfContent).find(
                  (key) => cachedPiecesOfContent[key][contentId],
                )
              setShowPreview([type, contentId, id])
            }}
            className='chat__single-conversation-single-message-content'
          >
            {/* {external_link != null &&
              !external_link?.length &&
              !mention_ids && (
                <ExternalLink
                  isReported={is_reported === 1}
                  convertMessageWithLink={convertMessageWithLink}
                  external_link_data={external_link_data}
                  content={content}
                />
              )} */}
            {type === 'LINK' && contentType && contentId ? (
              <span>{convertMessageWithLink(content)}</span>
            ) : (
              (mention_ids?.length && (
                <span>{highlightMentions(content)}</span>
              )) || <span>{normalParse(content, type || '')}</span>
            )}
            {content === '' && deletedAt && 'Message deleted'}
            {reactions?.length > 0 && reactions[0].content !== '' && (
              <div
                onClick={() => setIsReactionPopupOpen(true)}
                style={{
                  position: 'absolute',
                  right: '6px',
                  bottom: '-12px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'max-content',
                  height: '10px',
                  backgroundColor: '#283143',
                  opacity: 0.8,
                  padding: '6px',
                  gap: '6px',
                  cursor: 'pointer',
                  zIndex: 9,
                }}
              >
                {Object.entries(emojiCounter).map(([content, qty]) =>
                  content !== '' ? (
                    <span key={id + content}>
                      {content} {qty !== 1 && qty}
                    </span>
                  ) : null,
                )}
              </div>
            )}
          </div>

          {hoveredMessageId === id ? (
            <div
              style={{
                position: 'absolute',
                fontSize: 10,
                width: 'max-content',
                marginTop: '-1px',
              }}
            >
              {formatChatMsgDate(created_at)}
            </div>
          ) : null}
        </div>

        {hoveredMessageId &&
        is_reported !== 1 &&
        editedMessageId !== id &&
        deletedAt === null ? (
          <div
            style={{
              position: 'absolute',
              left: user?.user_info?.central_user_id === userId ? '-50px' : ``,
              right: user?.user_info?.central_user_id !== userId ? '-50px' : ``,
              top: `calc(${parentRef?.current?.clientHeight / 2 - 10}px)`,
              display: 'flex',
              gap: '4px',
              borderRadius: '8px',
            }}
          >
            {user?.user_info?.central_user_id === userId ? (
              <>
                <HoverMenu
                  handleMenuHoverClose={handleMenuHoverClose}
                  menuHoverAnchor={menuHoverAnchor}
                  setMenuHoverAnchor={setMenuHoverAnchor}
                  authorId={userId}
                  handleMenuAction={handleMenuAction}
                />
                <span
                  onClick={() =>
                    setIsEmojiPanelVisible((prevState) => !prevState)
                  }
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '14px',
                    height: '14px',
                    backgroundColor: '#283143',
                    padding: '4px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  😀
                </span>
              </>
            ) : (
              <>
                <span
                  onClick={() =>
                    setIsEmojiPanelVisible((prevState) => !prevState)
                  }
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '14px',
                    height: '14px',
                    backgroundColor: '#283143',
                    padding: '4px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  😀
                </span>
                <HoverMenu
                  handleMenuHoverClose={handleMenuHoverClose}
                  menuHoverAnchor={menuHoverAnchor}
                  setMenuHoverAnchor={setMenuHoverAnchor}
                  authorId={userId}
                  handleMenuAction={handleMenuAction}
                />
              </>
            )}
          </div>
        ) : null}
        {isEmojiPanelVisible && (
          <ClickAwayListener
            onClickAway={() =>
              setIsEmojiPanelVisible((prevState) => !prevState)
            }
          >
            <div
              style={{
                position: 'absolute',
                background: 'white',
                width: '200px',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 10,
                bottom: '30px',
                boxShadow: '0px 0px 20px 3px rgba(0, 0, 0, 0.4)',
                right: user?.user_info?.central_user_id === userId ? 0 : '',
                left: user?.user_info?.central_user_id !== userId ? 0 : '',
                zIndex: 10,
              }}
            >
              {EMOJI.map(({ value, id: idReaction }) => (
                <Button
                  key={idReaction}
                  className='emoji-button'
                  style={{
                    background: myReaction?.content === value ? '#283143' : '',
                  }}
                  onClick={() => {
                    onAddReaction(
                      id,
                      myReaction?.content === value ? '' : value,
                    )
                    setIsEmojiPanelVisible(false)
                    setHoveredMessageId(null)
                  }}
                >
                  <span>{value}</span>
                </Button>
              ))}
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>
  )
}

// const memoizedSingleMessage = memo(SingleMessage)
// export default memoizedSingleMessage
export default SingleMessage
