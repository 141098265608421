import React from 'react'
import { Button, Tooltip } from '@material-ui/core'
import Preview from '../Preview'
import Status from './Status'
import { unpublishContent } from '../../../../../shared/services/api/createContent/createAlert'
import PropTypes from 'prop-types'
import { useGlobalContext } from '../../../../../context/globalContext'
import Spinner from '../../../../../shared/Spinner'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

/**
 * BottomBar - create mode
 * @param {string} module - module name
 * @param {boolean} isSaving - is content being saved
 * @param {function} handleSaveDraft - handling content save
 * @param {boolean} isPreviewOpen - is preview modal open
 * @param {function} setPreviewOpen - handling preview modal state
 * @param {object} configuration - includes configuration for module
 * @param {boolean} isDenying - is content being denied
 * @param {function} handleDeny - handling being denied state
 * @param {boolean} isSendingForApproval - is content being sent for approval
 * @param {function} handleSendForApproval - handling being sent for approval state
 * @param {function} setIsPublicationCheckActive - handling publication modal state
 * @param {function} setToastMessage - setting toast message
 * @param {boolean} isTemplateSet - is template set and complete
 */

const BottomBar = ({
  module,
  isSaving,
  handleSaveDraft,
  isPreviewOpen,
  setPreviewOpen,
  configuration,
  isDenying,
  handleDeny,
  isSendingForApproval,
  handleSendForApproval,
  setIsPublicationCheckActive,
  setToastMessage,
  isTemplateSet = true,
}) => {
  const { createContentState, handleCreateState, setCreateContentState } =
    useCreateManagementContext()
  const { isContentBeingEdited } = useGlobalContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const unpublish = async () => {
    try {
      const response = await unpublishContent(
        token(),
        module,
        createContentState.id,
      )
      setToastMessage(['success', response.message])
      handleCreateState('published', 0, true)

      setCreateContentState({
        ...createContentState,
        statusName: response.statusName,
        status_id: response.status_id,
        visibilityIcon: response.visibilityIcon,
        visibilityName: response.visibilityName,
      })
    } catch (err) {}
  }

  const isPublishable = () => {
    const isManager = createContentState.can_publish_or_deny
    const checkIfCanBePublished = (status) =>
      !Object.values(configuration).some((section) => {
        //returns false if all required sections are complete, so reversing the boolean
        if (createContentState.published === 1) {
          if (
            section.internal_key !== 'title' &&
            section.is_mandatory &&
            createContentState.dev_content[section.internal_key] &&
            !createContentState.dev_content[section.internal_key][status]
          ) {
            return true
          }
        } else if (
          section.internal_key !== 'update-summary' &&
          section.internal_key !== 'title' &&
          section.is_mandatory &&
          createContentState.dev_content[section.internal_key] &&
          !createContentState.dev_content[section.internal_key][status]
        ) {
          return true
        }
      })

    const isRejected = () =>
      Object.values(configuration).some((section) => {
        if (
          section.internal_key !== 'title' &&
          section.is_mandatory &&
          createContentState.dev_content[section.internal_key] &&
          createContentState.dev_content[section.internal_key].is_rejected
        ) {
          return true
        }
      })

    if (
      (isManager && checkIfCanBePublished('is_accepted')) ||
      (!isManager && checkIfCanBePublished('is_complete') && !isRejected())
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className='bottom-bar'>
      <div className='bottom-bar__left'>
        <Button
          onClick={() => setTimeout(() => handleSaveDraft(), 100)}
          style={{
            backgroundColor: '#6180A8',
            minWidth: 91,
            pointerEvents: isSaving ? 'none' : 'all',
          }}
        >
          {isSaving ? (
            <Spinner size={19} color='#fff' centerHorizontally />
          ) : (
            'Save Draft'
          )}
        </Button>

        <Button
          onClick={() => !isSaving && setPreviewOpen(true)}
          style={{ backgroundColor: '#DC722B' }}
        >
          {isSaving ? (
            <Spinner size={19} color='#fff' centerHorizontally />
          ) : (
            'Preview'
          )}
        </Button>

        {isPreviewOpen && (
          <Preview
            id={createContentState.id}
            isPreviewOpen={isPreviewOpen}
            setPreviewOpen={setPreviewOpen}
            configuration={configuration}
            type={module}
          />
        )}
      </div>

      <div
        className='bottom-bar__middle'
        style={{ display: 'flex', fontWeight: 700, fontSize: 13 }}
      >
        <div style={{ display: 'flex' }}>
          <span>
            {isContentBeingEdited && Object.keys(createContentState).length
              ? `Visibility: ${createContentState.visibilityName}`
              : ''}
          </span>

          <Status visibility={createContentState.visibilityName} />
        </div>

        <div style={{ display: 'flex' }}>
          <span>
            {isContentBeingEdited && Object.keys(createContentState).length
              ? `Status: ${createContentState.statusName}`
              : ''}
          </span>

          <Status status={createContentState.statusName} />
        </div>
      </div>

      <div className='bottom-bar__right'>
        {createContentState.can_publish_or_deny &&
          createContentState.status_id === 3 && (
            <Button
              onClick={() => handleDeny()}
              style={{ backgroundColor: '#f4511e' }}
            >
              {isDenying ? (
                <Spinner size={19} color='#fff' centerHorizontally />
              ) : (
                'Deny'
              )}
            </Button>
          )}

        {isPublishable() &&
          createContentState.can_publish_or_deny &&
          isTemplateSet && (
            <Button
              disabled={isSaving}
              onClick={() => setIsPublicationCheckActive(true)}
              style={{ backgroundColor: '#3AAA35', minWidth: 72 }}
            >
              {isSaving ? (
                <Spinner size={19} color='#fff' centerHorizontally />
              ) : (
                'Publish'
              )}
            </Button>
          )}

        {isPublishable() &&
          !createContentState.can_publish_or_deny &&
          isTemplateSet && (
            <Button
              disabled={isSaving}
              onClick={() => handleSendForApproval()}
              style={{ backgroundColor: '#3AAA35' }}
            >
              {isSendingForApproval ? (
                <Spinner size={19} color='#fff' centerHorizontally />
              ) : (
                'Send for approval'
              )}
            </Button>
          )}

        {(!isPublishable() || !isTemplateSet) && (
          <Tooltip title='All required sections must be completed'>
            <Button disableRipple style={{ backgroundColor: '#a4a4a4' }}>
              {createContentState.can_publish_or_deny === true && 'Publish'}

              {createContentState.can_publish_or_deny === false &&
                'Send for approval'}
            </Button>
          </Tooltip>
        )}

        {createContentState.can_publish_or_deny === true &&
          createContentState.published === 1 && (
            <Button
              onClick={() => unpublish()}
              style={{ backgroundColor: '#fff', color: '#000' }}
            >
              Unpublish
            </Button>
          )}
      </div>
    </div>
  )
}

BottomBar.displayName = 'BottomBar'

/**
 * The properties.
 * @type {Object}
 */

BottomBar.defaultProps = {
  isTemplateSet: true,
}

BottomBar.propTypes = {
  configuration: PropTypes.instanceOf(Object).isRequired,
  module: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
  isPreviewOpen: PropTypes.bool.isRequired,
  setPreviewOpen: PropTypes.func.isRequired,
  isDenying: PropTypes.bool.isRequired,
  handleDeny: PropTypes.func.isRequired,
  isSendingForApproval: PropTypes.bool.isRequired,
  handleSendForApproval: PropTypes.func.isRequired,
  setIsPublicationCheckActive: PropTypes.func.isRequired,
  setToastMessage: PropTypes.func.isRequired,
  isTemplateSet: PropTypes.bool,
}

export default BottomBar
