/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import './LoginHistory.scss'
import Pagination from '@material-ui/lab/Pagination'
import { Button, Divider } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { formatDate } from '../../../../../shared/helpers/formatDate'
import Spinner from '../../../../../shared/Spinner'
import { useQueryGetLoginHistory } from '../../../../../shared/services/queries/user/account/useQueryGetLoginHistory'

const LoginHistory = () => {
  const [page, setPage] = useState(1)
  const history = useHistory()

  const loginHistoryList = useQueryGetLoginHistory(page)

  return (
    <div className='login-history'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Login History</h2>

        <Button
          onClick={() => {
            history.push({
              pathname: '/app/support',
              state: {
                type: 'security_incident',
                redirect_url: '/app/settings/login-history',
              },
            })
          }}
          style={{
            textTransform: 'capitalize',
            backgroundColor: '#ff0033',
            color: '#fff',
            padding: '8px 12px',
            borderRadius: 12,
            fontWeight: 700,
          }}
        >
          Report a security incident with a suspicious activity
        </Button>
      </div>

      <Divider />

      <div className='login-history__main'>
        <div className='login-history__header'>
          <span>Timestamp</span>

          <span>IP Address</span>

          <span>Status</span>
        </div>

        <Divider style={{ margin: '15px 0' }} />

        {!loginHistoryList?.data?.list?.data ? (
          <Spinner centerHorizontally size={30} border={1} />
        ) : (
          <div className='login-history__entries'>
            {loginHistoryList?.data?.list?.data?.map((entry) => {
              const { id, created_at, ip, is_signed_in } = entry
              return (
                <div key={id}>
                  <span>{formatDate(created_at)} </span>

                  <span>{ip}</span>

                  <span>{is_signed_in ? 'Success' : 'Fail'}</span>
                </div>
              )
            })}
          </div>
        )}

        <Pagination
          onChange={(event, value) => setPage(value)}
          page={page}
          color='primary'
          disabled={!loginHistoryList?.data?.list?.data}
          count={loginHistoryList?.data?.list?.last_page}
          shape='rounded'
          hideNextButton
          hidePrevButton
        />
      </div>
    </div>
  )
}

export default LoginHistory
