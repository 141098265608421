/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { Autocomplete } from '@material-ui/lab'
import { Checkbox, TextField } from '@mui/material'
const SectorMultiselect = ({ options, handleSectorChange }) => {
  const value = [...options]
    .map((i) => i.selected && i.id)
    .filter((e) => e !== false)

  return (
    <Autocomplete
      multiple
      value={value}
      options={options || []}
      disableCloseOnSelect
      onChange={(e, value) => {
        const newValue = value[value.length - 1]?.id

        handleSectorChange(newValue || 'clear')
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              minHeight: 30,
              lineHeight: 1,
            }}
            className='sector-checkbox'
          >
            <div>
              <Checkbox
                style={{ padding: 0, marginRight: 10 }}
                checked={option.selected}
                icon={<span className='icon-checkbox-empty' />}
                checkedIcon={<span className='icon-checkbox-checked' />}
              />

              <span style={{ fontSize: 13, fontWeight: 500 }}>
                {option.name}
              </span>
            </div>
          </div>
        )
      }}
      renderInput={(params) => {
        params.InputProps.startAdornment =
          params.InputProps.startAdornment && ''
        return (
          <div>
            <TextField
              {...params}
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <span style={{ position: 'absolute', left: 14 }}>
                    {value.length ? `Selected: ${value.length}` : ''}
                  </span>
                ),
              }}
              style={{ color: '#fff' }}
              inputProps={{
                ...params.inputProps,
                style: {
                  paddingLeft: value.length ? 104 : 14,
                  minWidth: value.length ? 160 : 250,
                },
              }}
              variant='outlined'
              placeholder='Search Sectors'
            />
          </div>
        )
      }}
    />
  )
}

export default SectorMultiselect
