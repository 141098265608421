/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { userPermissions } from '../../../../shared/services/api/admin/settings/groups'
import Spinner from '../../../../shared/Spinner'
import {
  modules,
  permissions,
} from '../../../Instances/SingleInstance/EditOrganisation/sections/OrganisationUsers/data'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { Tooltip } from '@mui/material'

const PrivilegesModal = ({
  privilegesModal,
  setPrivilegesModal,
  chosenElement,
  handleEditData,
  setToastMessage,
  maxUserPrivileges,
  userData,
  groupName,
}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setLoading] = useState(false)
  const [temporaryState, setTemporaryState] = useState(cloneDeep(chosenElement))
  const [userModules, setUserModules] = useState([])
  const isOrgModal = privilegesModal[0] === 'org'
  const maxPrivileges = cloneDeep(maxUserPrivileges)
  const orgFields = [
    { name: 'Include new users', slug: 'include_new_users' },
    { name: 'Make anonymous', slug: 'make_anonymous' },
  ]

  useEffect(() => {
    !isOrgModal && getUserModules()
  }, [])

  const getUserModules = async () => {
    setLoading(true)
    try {
      const orgId = privilegesModal[1]
      const response = await userPermissions(token(), orgId, chosenElement.id)
      let possibleModules = []
      const module_access = maxPrivileges

      response.list.forEach((module) => {
        Object.keys(module_access).forEach((possible) => {
          if (module.id === +possible) {
            possibleModules = [...possibleModules, module]
          }
        })
      })
      setUserModules(possibleModules)
      setLoading(false)
    } catch (err) {}
  }

  const analystRoles = [
    { id: 2, name: 'Editor' },
    { id: 1, name: 'Viewer' },
  ]
  const managerRoles = [
    { id: 3, name: 'Publisher' },
    { id: 2, name: 'Editor' },
    { id: 1, name: 'Viewer' },
  ]

  const handleTemporaryState = (prop, value, userSetting) => {
    const currentState = { ...temporaryState }
    if (!isOrgModal) {
      if (userSetting === 'access') {
        if (value === undefined) {
          currentState.module_access[prop] = { id: prop, role_id: 1 }
        } else {
          delete currentState.module_access[value.id]
        }
      } else if (userSetting === 'role') {
        if (value === 0) {
          delete currentState.module_access[prop]
        } else {
          if (currentState.module_access[prop]?.role_id) {
            //if module exists
            currentState.module_access[prop].role_id = value
          } else {
            currentState.module_access[prop] = { role_id: value, id: prop }
          }
        }
      }
    } else {
      currentState[prop] = value
    }

    setTemporaryState(currentState)
  }

  const handleConfirmSave = () => {
    const slug = isOrgModal ? 'org_privileges' : 'user_privileges'
    const orgId = isOrgModal ? temporaryState.id : privilegesModal[1]
    handleEditData(slug, temporaryState, orgId)
    setPrivilegesModal(undefined)
    setToastMessage([
      'success',
      `${isOrgModal ? 'Organisation' : 'User'} privileges have been saved`,
    ])
  }

  const handleCyclePermissions = () => {
    const currentState = { ...temporaryState }
    const moduleAccessCleared = {}
    const chatAccess = currentState.module_access[12]
    Object.entries(currentState.module_access)?.forEach((i) => {
      const key = i[0]
      const value = i[1]
      moduleAccessCleared[key] = { id: +key, role_id: +value.role_id }
    })
    const alreadyHasMaximumPermissions = isEqual(
      moduleAccessCleared,
      maxPrivileges,
    )
    const mainGroupRoles = Object.values({ ...currentState.module_access }).map(
      (role) => role.role_id,
    )
    const hasDifferentRoles = mainGroupRoles.some(
      (role) => role !== mainGroupRoles[0],
    )
    const mainMaxRoles = Object.values({ ...maxPrivileges }).map(
      (role) => role.role_id,
    )
    const hasDifferentMaxRoles = mainMaxRoles.some(
      (role) => role !== mainMaxRoles[0],
    )
    const maxCurrentRole = Math.max(...mainGroupRoles)

    if (alreadyHasMaximumPermissions) {
      currentState.module_access = {}
      if (chatAccess?.id) {
        currentState.module_access[12] = chatAccess
      }

      setTemporaryState(currentState)

      return false
    }

    if (hasDifferentMaxRoles) {
      currentState.module_access = { ...maxPrivileges }
      if (chatAccess?.id) {
        currentState.module_access[12] = chatAccess
      }

      setTemporaryState(currentState)

      return false
    }

    if (!Object.keys(currentState.module_access)?.length) {
      //if there are no permissions
      currentState.module_access = maxPrivileges
      Object.values(currentState?.module_access).forEach(
        (role) => (role.role_id = 1),
      )
    } else {
      //if some permissions exist
      if (hasDifferentRoles) {
        //if roles are different
        Object.values(currentState?.module_access).forEach((module) => {
          module.role_id = maxCurrentRole
        })
      } else {
        //if all roles are the same
        Object.values(currentState?.module_access).forEach((module) => {
          if (module.role_id === 3) {
            delete currentState.module_access[module.id]
          } else {
            module.role_id = module.role_id + 1
          }
        })
      }
    }

    if (chatAccess?.id) {
      currentState.module_access[12] = chatAccess
    }
    setTemporaryState(currentState)
  }

  return (
    <Dialog
      fullWidth
      maxWidth={isOrgModal ? 'sm' : 'md'}
      open={privilegesModal !== undefined}
      onClose={() => setPrivilegesModal(undefined)}
    >
      <div style={{ padding: 24 }}>
        {isOrgModal && (
          <h3>{`Default organisation privileges - ${temporaryState.name}`}</h3>
        )}

        {!isOrgModal && (
          <h3>{`User Privileges - ${userData.first_name} ${userData.last_name}`}</h3>
        )}

        <Divider style={{ margin: '10px 0', border: 'none' }} />

        {isLoading && <Spinner centerHorizontally padding={10} size={30} />}

        {!isLoading && !isOrgModal && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr style={{ backgroundColor: '#E0E0E0', fontSize: 14 }}>
                  {permissions.map((item) => (
                    <th
                      style={{
                        padding: '14px 14px 14px 0',
                        borderRadius:
                          (item === 'Groups' && '10px 0 0 10px') ||
                          (item === 'Reports' && '0 10px 10px 0'),
                      }}
                      key={item}
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                <tr style={{ fontSize: 13, textAlign: 'center' }}>
                  <td
                    style={{
                      width: '16.6%',
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#222222',
                      paddingLeft: null,
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip
                      arrow
                      placement='top'
                      title='Click to cycle through privileges'
                    >
                      <span onClick={() => handleCyclePermissions()}>
                        {groupName}
                      </span>
                    </Tooltip>
                  </td>

                  {modules.map((module) => {
                    const roleId =
                      temporaryState?.module_access[module.id]?.role_id

                    return (
                      <td style={{ width: '16.6%' }} key={module.id}>
                        <div className='user-roles'>
                          <Select
                            onChange={(event) =>
                              handleTemporaryState(
                                module.id,
                                event.target.value,
                                'role',
                              )
                            }
                            value={roleId || ''}
                            renderValue={
                              !roleId
                                ? () => (
                                    <span style={{ color: '#aaa' }}>
                                      Set Role
                                    </span>
                                  )
                                : null
                            }
                            displayEmpty
                            style={{
                              borderRadius: 12,
                              backgroundColor:
                                roleId === 3
                                  ? 'rgb(255 0 51 / 30%)'
                                  : roleId === 2
                                  ? 'rgb(255 191 0 / 30%)'
                                  : roleId === 1 && 'rgb(58 170 53 / 30%)',
                            }}
                            variant='outlined'
                          >
                            {maxPrivileges[module.id]?.role_id === 1 && (
                              <MenuItem
                                style={{ color: '#3aaa35', fontWeight: 500 }}
                                value={1}
                              >
                                Viewer
                              </MenuItem>
                            )}

                            {maxPrivileges[module.id]?.role_id === 2 &&
                              analystRoles.map(({ name, id }) => (
                                <MenuItem
                                  style={{
                                    color:
                                      id === 3
                                        ? '#ff0033'
                                        : id === 2
                                        ? '#ffbf00'
                                        : id === 1 && '#3aaa35',
                                    fontWeight: 500,
                                  }}
                                  key={id}
                                  value={id}
                                >
                                  {name}
                                </MenuItem>
                              ))}

                            {maxPrivileges[module.id]?.role_id === 3 &&
                              managerRoles.map(({ name, id }) => (
                                <MenuItem
                                  style={{
                                    color:
                                      id === 3
                                        ? '#ff0033'
                                        : id === 2
                                        ? '#ffbf00'
                                        : id === 1 && '#3aaa35',
                                    fontWeight: 500,
                                  }}
                                  key={id}
                                  value={id}
                                >
                                  {name}
                                </MenuItem>
                              ))}

                            <MenuItem value={0}>No Access</MenuItem>
                          </Select>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {isOrgModal && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {orgFields.map((field) => (
              <FormControlLabel
                key={field.slug}
                control={
                  <Checkbox
                    style={{ padding: 3, backgroundColor: 'transparent' }}
                    checked={temporaryState[field.slug] || false}
                    color='primary'
                    onChange={() =>
                      handleTemporaryState(
                        field.slug,
                        !temporaryState[field.slug],
                      )
                    }
                    icon={
                      <span
                        style={{ fontSize: 34 }}
                        className='icon-checkbox-empty'
                      />
                    }
                    checkedIcon={
                      <span
                        style={{ color: '#006fff', fontSize: 34 }}
                        className='icon-checkbox-checked'
                      />
                    }
                  />
                }
                label={field.name}
              />
            ))}
          </div>
        )}
      </div>

      <div
        style={{
          padding: '0 24px 16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => setPrivilegesModal(undefined)}>Cancel</Button>

        <Button
          style={{
            backgroundColor: '#006fff',
            color: '#fff',
            textTransform: 'capitalize',
            fontWeight: 700,
            borderRadius: 12,
            padding: '6px 20px',
            minWidth: 85,
          }}
          onClick={() => handleConfirmSave()}
        >
          Save
        </Button>
      </div>
    </Dialog>
  )
}

export default PrivilegesModal
