import React from 'react'
import { Chip, CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const AssociatedTags = ({
  options,
  isLoading,
  currentState,
  handleTemporaryState,
}) => {
  const filteredChosenElements = () =>
    options.filter((item) =>
      currentState.some(
        (item2) => item.id === item2.id && item.name === item2.name,
      ),
    )

  return (
    <div style={{ width: '100%' }} className='associated'>
      <h5>Selected: {currentState?.length}</h5>

      <div style={{ margin: '10px 0' }}>
        {currentState?.map((piece) => (
          <Chip
            style={{
              backgroundColor: '#FFF4C9',
              color: '#000',
              margin: 2,
              fontSize: 10,
            }}
            onClick={() => {}}
            onDelete={() => handleTemporaryState(piece.id, true)}
            label={piece.title}
            key={piece.id + piece.name}
            deleteIcon={
              <span
                className='icon-close'
                style={{
                  width: 'unset',
                  height: 'unset',
                  fontSize: 14,
                  padding: '4px 4px 4px 0',
                  color: '#000',
                }}
              />
            }
          />
        ))}
      </div>

      <Autocomplete
        multiple
        // blurOnSelect
        disableCloseOnSelect
        disableClearable
        filterSelectedOptions
        getOptionSelected={(option, value) =>
          option.id === value.id && option.name === value.name
        }
        getOptionLabel={(option) => option.title}
        options={options || []}
        value={filteredChosenElements() || []}
        onChange={(event, value) => {
          const { key } = event
          if (key === 'Backspace' || key === 'Delete') {
            return false
          }

          const newValue = value[value.length - 1]

          newValue && handleTemporaryState(newValue)
        }}
        renderTags={() => {
          return false
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Search for tags'
            variant='outlined'
            className='tag-input'
            size='small'
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress size={20} /> : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  )
}

export default AssociatedTags
