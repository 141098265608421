/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import './Instances.scss'
import { Button, Divider } from '@material-ui/core'
import { instances } from '../../shared/services/api/instances/instances'
import SingleInstance from './SingleInstance/SingleInstance'
import Spinner from '../../shared/Spinner'
import CreateIcon from '@material-ui/icons/Create'
import { apiUrl } from '../../shared/config'

const Instances = () => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const history = useHistory()
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(true)
  const getInstances = async () => {
    try {
      const response = await instances(token())
      setData(response.list.data)
      setLoading(false)
    } catch (err) {}
  }

  const getUserList = async () =>
    (window.location.href = `${apiUrl}/apps/download/users.xlsx?access_token=${token()}`)

  useEffect(() => {
    getInstances()
  }, [])

  return (
    <Fragment>
      <Route exact path='/app/admin/instances'>
        <div className='instances-organisations'>
          <div className='main__inner'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h3>Instances</h3>

              <Button
                onClick={() => getUserList()}
                style={{
                  textAlign: 'center',
                  textTransform: 'unset',
                  borderRadius: 12,
                  padding: '6px 16px',
                  backgroundColor: '#006fff',
                  color: '#fff',
                }}
              >
                Download users (.xlsx)
              </Button>
            </div>

            <Divider style={{ margin: '20px 0' }} />

            <div className='instances-container'>
              <span>Name</span>

              {data.map((instance) => (
                <Fragment key={instance.id}>
                  <div className='instance-wrapper'>
                    <span>{instance.name}</span>

                    <Button
                      onClick={() =>
                        history.push(
                          `/app/admin/instances/${instance.id}/organisations`,
                        )
                      }
                      startIcon={<CreateIcon />}
                      className='edit'
                    >
                      Edit
                    </Button>
                  </div>
                </Fragment>
              ))}
            </div>

            {isLoading && <Spinner centerHorizontally padding={20} size={30} />}
          </div>
        </div>
      </Route>

      <Route path='/app/admin/instances/:id'>
        <SingleInstance />
      </Route>
    </Fragment>
  )
}

export default Instances
