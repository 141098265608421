import React, { useState } from 'react'
// import './NodeDetails.scss'
// import { layouts } from './../../services/layouts'
import { IconButton, Tooltip, Divider, MenuItem, Menu } from '@material-ui/core'

import ShareIcon from '@material-ui/icons/Share'
import ZoomInSharpIcon from '@material-ui/icons/ZoomInSharp'
import ZoomOutSharpIcon from '@material-ui/icons/ZoomOutSharp'
import PanToolIcon from '@material-ui/icons/PanTool'
import ImageIcon from '@material-ui/icons/Image'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import Save from '@material-ui/icons/Save'
import '../../assets/style.css'
import { HelpOutline } from '@material-ui/icons'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useGlobalContext } from '../../../../context/globalContext'
import { useViewStateContext } from '../../../../context/viewStateContext'

const Navigation = ({
  layoutsList,
  handMode,
  setAnimationTime,
  setLayout,
  generateImage,
  toggleHandMode,
  handleBetweenness,
  handleDegrees,
  zoom,
  combineSelection,
  uncombineSelection,
  handleResetFilter,
  doUndo,
  doRedo,
  state,
  setIsModalOpen: setIsSaveModalOpen,
  isLinkAnalysisEqual,
  setAnalysisName,
  setIsView,
}) => {
  const [toolbarAnchor, setToolbarAnchor] = useState(null)
  const [filterAnchor, setFilterAnchor] = useState(null)
  const [legendOn, setLegendOn] = useState(false)
  // const [saveOn, setSaveOn] = useState(true)
  const { setIsModalOpen } = useGlobalContext()
  const {
    workspaces,
    setWorkspaces,
    setLinkAnalysisOpen,
    setLinkAnalysisState,
  } = useViewStateContext()

  const handleToolbarMenuClose = () => {
    setToolbarAnchor(null)
    setFilterAnchor(null)
  }

  const handleToolbar = (event) => {
    setToolbarAnchor(event.currentTarget)
  }

  const handleFilter = (event) => {
    setFilterAnchor(event.currentTarget)
  }

  const handleChooseLayout = (layout) => {
    setAnimationTime(750)
    setLayout(layout)
    setToolbarAnchor(null)
  }

  const degreesHandler = () => {
    handleDegrees()
    setFilterAnchor(null)
  }

  const betweennessHandler = () => {
    handleBetweenness()
    setFilterAnchor(null)
  }

  const resetHandler = () => {
    handleResetFilter()
    setFilterAnchor(null)
  }

  const modules = [
    { name: 'Threat Alert', icon: 'alert', color: '#B53F3F' },
    { name: 'Threat Actor', icon: 'threat-actor', color: '#1111A5' },
    { name: 'Incident', icon: 'incident', color: '#CF8300' },
    { name: 'Operation', icon: 'operation', color: '#1A1A1A' },
    { name: 'Malware & Tools', icon: 'malware-tools', color: '#834EFF' },
    { name: 'Scenario', icon: 'scenario', color: '#2EA3A7' },
    { name: 'Report', icon: 'report', color: '#3AAA35' },
    { name: 'Tag', icon: 'tag', color: '#707070' },
  ]

  const resetLinkAnalysisState = () => {
    setLinkAnalysisOpen(false)
    setLinkAnalysisState({
      layout: null,
      inRange: null,
      undo: [],
      redo: [],
      currentChart: {
        items: {},
      },
    })
    setAnalysisName('')
    const currentWorkspace = workspaces.find(
      (workspace) => workspace.state === 'analysis',
    )

    currentWorkspace.analysisId = null
    currentWorkspace.edit = false
    setWorkspaces(workspaces)
    localStorage.setItem('workspaces', JSON.stringify(workspaces))
    setIsView(true)
  }

  return (
    <div className='navigation'>
      <Tooltip title='Back'>
        <IconButton
          onClick={() => {
            if (isLinkAnalysisEqual) {
              resetLinkAnalysisState()
            } else {
              setIsModalOpen(true)
            }
          }}
        >
          <ArrowBackIcon style={{ color: '#607d8b' }} />
        </IconButton>
      </Tooltip>

      <Divider style={{ margin: '0 5px' }} orientation='vertical' flexItem />

      <Tooltip title='Zoom in'>
        <IconButton onClick={() => zoom('in')}>
          <ZoomInSharpIcon style={{ color: '#607d8b' }} />
        </IconButton>
      </Tooltip>

      <Tooltip title='Zoom out'>
        <IconButton onClick={() => zoom('out')}>
          <ZoomOutSharpIcon style={{ color: '#607d8b' }} />
        </IconButton>
      </Tooltip>

      {handMode ? (
        <Tooltip title='Toggle hand mode'>
          <IconButton onClick={toggleHandMode}>
            <PanToolIcon style={{ color: '#607d8b' }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='Toggle hand mode'>
          <IconButton onClick={toggleHandMode}>
            <span style={{ color: '#607d8b' }} className='icon-select' />
          </IconButton>
        </Tooltip>
      )}

      <Divider style={{ margin: '0 5px' }} orientation='vertical' flexItem />

      <Tooltip title='Change layout'>
        <IconButton onClick={handleToolbar}>
          <ShareIcon style={{ color: '#607d8b' }} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={toolbarAnchor}
        keepMounted
        open={Boolean(toolbarAnchor)}
        onClose={handleToolbarMenuClose}
      >
        {layoutsList.map((layout) => (
          <MenuItem
            button
            key={layout.id}
            onClick={() => handleChooseLayout(layout)}
          >
            {layout.name}
          </MenuItem>
        ))}
      </Menu>

      <Tooltip title='Analysis'>
        <IconButton onClick={handleFilter}>
          <span style={{ color: '#607d8b' }} className='icon-filter' />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={filterAnchor}
        keepMounted
        open={Boolean(filterAnchor)}
        onClose={handleToolbarMenuClose}
      >
        <MenuItem button onClick={degreesHandler}>
          Number of links
        </MenuItem>

        <MenuItem button onClick={betweennessHandler}>
          Network Importance
        </MenuItem>

        <MenuItem button onClick={resetHandler}>
          Reset sizes
        </MenuItem>
      </Menu>

      <Tooltip title='Download image'>
        <IconButton download='ReGraph_image_demo.png' onClick={generateImage}>
          <ImageIcon style={{ color: '#607d8b' }} />
        </IconButton>
      </Tooltip>

      <Divider style={{ margin: '0 5px' }} orientation='vertical' flexItem />

      <Tooltip title='Group selected nodes'>
        <IconButton
          style={{ width: '50px', height: '50px' }}
          onClick={combineSelection}
        >
          <GroupWorkIcon style={{ color: '#607d8b' }} />
        </IconButton>
      </Tooltip>

      <Tooltip title='Ungroup'>
        <IconButton
          style={{ width: '50px', height: '50px' }}
          onClick={uncombineSelection}
        >
          <span style={{ color: '#607d8b' }} className='icon-ungroup' />
        </IconButton>
      </Tooltip>

      <Divider style={{ margin: '0 5px' }} orientation='vertical' flexItem />

      <IconButton style={{ width: '50px', height: '50px' }} onClick={doUndo}>
        <UndoIcon style={{ color: '#607d8b' }} />

        <span style={{ fontSize: '16px' }}>{state?.undo?.length}</span>
      </IconButton>

      <IconButton style={{ width: '50px', height: '50px' }} onClick={doRedo}>
        <RedoIcon style={{ color: '#607d8b' }} />

        <span style={{ fontSize: '16px' }}>{state?.redo?.length}</span>
      </IconButton>

      <Divider style={{ margin: '0 5px' }} orientation='vertical' flexItem />

      <Tooltip title='Toggle legend'>
        <IconButton
          style={{ width: '50px', height: '50px' }}
          onClick={() => setLegendOn(!legendOn)}
        >
          <HelpOutline style={{ color: '#607d8b' }} />
        </IconButton>
      </Tooltip>

      <Divider style={{ margin: '0 5px' }} orientation='vertical' flexItem />

      <Tooltip title='Save'>
        <IconButton
          style={{ width: '50px', height: '50px' }}
          onClick={() => setIsSaveModalOpen(true)}
        >
          <Save style={{ color: '#607d8b' }} />
        </IconButton>
      </Tooltip>

      {legendOn && (
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 65,
            width: 490,
            display: 'flex',
            zIndex: 1,
            gap: 20,
          }}
        >
          {modules.map(({ name, icon, color }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontSize: 11,
              }}
              key={icon}
            >
              <div
                style={{
                  borderRadius: 25,
                  padding: 6,
                  backgroundColor: color,
                  width: 30,
                  height: 30,
                  marginBottom: 5,
                }}
              >
                <span
                  className={`icon-${icon}`}
                  style={{ fontSize: 30, color: '#fff' }}
                />
              </div>

              <span style={{ textAlign: 'center' }}>{name}</span>
            </div>
          ))}
        </div>
      )}

      {/* {saveOn &&
          <div
            className='save-container'
            style={{
              position: 'absolute', right: 0, top: 65, width: 380, backgroundColor: '#fff',
              display: 'flex', zIndex: 2, padding: '10px 12px', borderRadius: '12px', gap: 20,
              alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px 4px 6px #00000029'
            }}
          >
            <TextField
              defaultValue={analysisName || ''}
              value={analysisName}
              onBlur={() => {
                setLinkAnalysisState((current) => ({
                  ...current,
                  extras: {
                    ...current.extras,
                    analysisName: analysisName,
                  }
                }))
              }}
              onChange={(event) => {
                setAnalysisName(event.target.value)
              }}
              placeholder='Enter analysis title here'
              variant='outlined'
            />

            <BaseButton onClick={async () => {
              setIsSaveModalOpen(true)
            }}>
              <span className="icon-edit" style={{ fontSize: '20px', marginRight: '4px' }} />

              <span>Save</span>
            </BaseButton>
          </div>} */}
    </div>
  )
}

export default Navigation
