import { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import Pagination from '@material-ui/lab/Pagination'
import Spinner from '../../../../shared/Spinner'
import { useHistory, useParams } from 'react-router-dom'
import { TextField } from '@mui/material'
import {
  singleTag,
  editTagCategory,
  addTagCategory,
  deleteTagFromCategoryTag,
} from '../../../../shared/services/api/admin/settings/tags'
import './EditTag.scss'
import useNotification from '../../../../hooks/useNotification'
import DeleteModal from './DeleteModal'

const TAGS_PER_PAGE = '10'

const EditTagCategory = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const initialData = { name: '', icon: null, tags: [] }
  const history = useHistory()
  const { id } = useParams()
  const isCreateNew = id === 'new'
  const [isLoading, setLoading] = useState(!isCreateNew)
  const [data, setData] = useState(isCreateNew ? initialData : {})
  const [isSaving, setSaving] = useState(false)
  const { successToast, errorToast } = useNotification()
  const [page, setPage] = useState(1)
  const [isDeletingTagId, setIsDeletingTagId] = useState(false)

  const indexOfLastPage = page * TAGS_PER_PAGE
  const indexOfFirstPage = indexOfLastPage - TAGS_PER_PAGE
  const totalPages = Math.ceil(data?.tags?.length / TAGS_PER_PAGE)
  const currentTags = data?.tags?.slice(indexOfFirstPage, indexOfLastPage)

  const getTagCategoryDetails = async () => {
    try {
      const response = await singleTag(token(), 'tag-categories', id)
      setData(response.data)
      setLoading(false)
    } catch (err) {}
  }

  const submitTagData = async () => {
    setSaving(true)
    try {
      let response = ''
      if (id === 'new') {
        response = await addTagCategory(token(), data)
      } else {
        response = await editTagCategory(token(), id, data)
      }

      if (response.error) {
        errorToast({ message: response.message })
        setLoading(false)
        setSaving(false)
        return false
      }

      successToast({ message: response.message })
      setLoading(false)
      setSaving(false)
      isCreateNew &&
        history.push('/app/admin/system-settings/tags/tag-categories')
    } catch {}
  }

  useEffect(() => {
    ;(async () => {
      !isCreateNew && (await getTagCategoryDetails())
    })()
  }, [isCreateNew])

  const handleState = (prop, value) => {
    const currentData = { ...data }

    currentData[prop] = value
    setData(currentData)
  }

  const handleDeleteTagFromCategoryTag = async (tagId) => {
    try {
      await deleteTagFromCategoryTag(token(), id, tagId)
      successToast({ message: 'Tag deleted successfully' })
      const newTags = data.tags.filter((tag) => tag.id !== tagId)
      handleState('tags', newTags)

      setIsDeletingTagId(false)
    } catch {}
  }

  return isLoading ? (
    <Spinner centerHorizontally padding={20} size={30} />
  ) : (
    <>
      {isDeletingTagId && (
        <DeleteModal
          isDeleting={isDeletingTagId}
          setIsDeleting={setIsDeletingTagId}
          handleDelete={handleDeleteTagFromCategoryTag}
        />
      )}
      <div className='edit-admin'>
        <h2 style={{ marginBottom: 10 }}>
          {isCreateNew ? 'Create New Tag Category' : 'Edit Tag Category'}
        </h2>

        <div className='edit-admin__form'>
          <div style={{ width: '100%', margin: '6px 0' }}>
            <h5 style={{ marginBottom: 5 }}>Name</h5>

            <TextField
              fullWidth
              variant='outlined'
              placeholder='Name'
              value={data.name || ''}
              required
              onChange={(event) => handleState('name', event.target.value)}
            />
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <Button
            disabled={isSaving}
            onClick={() => submitTagData()}
            className='create-new'
          >
            {isSaving ? (
              <Spinner size={22.5} centerHorizontally color='#fff' />
            ) : id === 'new' ? (
              'Add New Tag Category'
            ) : (
              'Edit Tag Category'
            )}
          </Button>

          <Button
            style={{
              textTransform: 'capitalize',
              marginLeft: 20,
              borderRadius: 12,
              padding: '6px 16px',
            }}
            onClick={() =>
              history.push('/app/admin/system-settings/tags/tag-categories')
            }
          >
            Cancel
          </Button>
        </div>

        {data.tags.length > 0 && (
          <div style={{ marginTop: '40px' }}>
            <h5>Tags that belong to this category:</h5>
            <ul style={{ paddingLeft: 0 }}>
              {currentTags.map((tag, i) => (
                <li
                  key={`${tag.id}${i}`}
                  className='list-item'
                  style={{
                    // paddingBottom: '8px',
                    listStyle: 'none',
                    padding: ' 8px 0 8px 0',
                    borderBottom: '1px solid #dedede',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{tag.name}</span>
                  <div>
                    <Button
                      onClick={() =>
                        history.push({
                          pathname: `/app/admin/system-settings/tags/tags/${tag.id}`,
                          state: {
                            redirected_url: `/app/admin/system-settings/tags/tag-categories/${id}`,
                          },
                        })
                      }
                      startIcon={<CreateIcon />}
                      className='edit'
                    >
                      Edit
                    </Button>

                    <Button
                      className='delete'
                      onClick={() => setIsDeletingTagId(tag.id)}
                    >
                      Delete
                    </Button>
                  </div>
                </li>
              ))}
              <Pagination
                onChange={(event, value) => setPage(value)}
                page={page}
                count={totalPages}
                shape='rounded'
                hideNextButton
                hidePrevButton
                color='primary'
                style={{ marginTop: '12px' }}
              />
            </ul>
          </div>
        )}
      </div>
    </>
  )
}

export default EditTagCategory
