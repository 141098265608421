import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import { formatDate } from '../../../../../../shared/helpers/formatDate'
import Spinner from '../../../../../../shared/Spinner'

import './KnowledgeBaseTextContent.scss'
import TextSection from './TextSection'
import ButtonSection from './ButtonSection'

const KnowledgeBaseVideoContent = ({ article }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { name, created_at, updated_at, content } = article || {}
  const videoContent = JSON.parse(content).find(
    (template) => template.type === 'video',
  )
  const textContent = JSON.parse(content).filter(
    (template) => template.type !== 'video',
  )

  const handlePlay = () => setIsLoading(false)

  return (
    <div className='knowledge-base-article-content'>
      {videoContent?.url && (
        <div className='knowledge-base-article-content__video-container'>
          {isLoading && <Spinner centerHorizontally size={30} />}

          <ReactPlayer onReady={handlePlay} url={videoContent?.url} controls />
        </div>
      )}

      <h1 className='knowledge-base-article-content__title'>{name}</h1>

      <div className='knowledge-base-article-content__info'>
        <span className='knowledge-base-article-content__published-info'>
          Published: {formatDate(created_at)}
        </span>

        <span className='knowledge-base-article-content__published-info'>
          Updated: {formatDate(updated_at)}
        </span>

        <span className='knowledge-base-article-content__author-info'>
          Author: Security Alliance
        </span>
      </div>

      {textContent?.map((section) =>
        section.type === 'text' ? (
          <TextSection key={section.headline} section={section} />
        ) : (
          <ButtonSection key={section.headline} section={section} />
        ),
      )}
    </div>
  )
}

export default KnowledgeBaseVideoContent
