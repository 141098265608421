/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Snackbar,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { Info, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  createGroup,
  organisationsList,
  saveGroup,
  singleGroup,
} from '../../../../shared/services/api/admin/settings/groups'
import SingleGroupEdit from './SingleGroupEdit'
import './SingleGroupSetup.scss'
import PrivilegesModal from './PrivilegesModal'
import MuiAlert from '@material-ui/lab/Alert'
import Spinner from '../../../../shared/Spinner'
const initialData = {
  is_community_group: false,
  is_global_group: false,
  push_content_distributed_to_that_group_to_misp: false,
  users_in_organisations: {},
  organisations: [],
  name: '',
}

const SingleGroupSetup = () => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const history = useHistory()
  const { id } = useParams()
  const isCreateNew = id === 'new'
  const titleInput = useRef(null)
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState(isCreateNew ? initialData : null)
  const [expanded, setExpanded] = useState(['select-users'])
  const [expandedOrgs, setExpandedOrgs] = useState([])
  const [organisationOptions, setOrganisationOptions] = useState([])
  const [organisationsInApps, setOrganisationsInApps] = useState([])
  const [privilegesModal, setPrivilegesModal] = useState(undefined)
  const [toastMessage, setToastMessage] = useState(undefined)
  const [isSaving, setSaving] = useState(false)
  const initialsInput = useRef(null)

  const MISC_FIELDS = [
    { slug: 'is_community_group', label: 'Community Group' },
    { slug: 'is_global_group', label: 'Global Group' },
    {
      slug: 'push_content_distributed_to_that_group_to_misp',
      label: 'Push content distributed to that group to SA MISP Platform',
    },
  ]

  if (data?.is_global_group === false) {
    MISC_FIELDS.push({ slug: 'is_chat_enabled', label: 'Chat enabled' })
    MISC_FIELDS.push({ slug: 'is_forum_enabled', label: 'Forum enabled' })
  }
  const getOrgList = async () => {
    try {
      let orgArray = []
      const organisations = await organisationsList(token())

      organisations?.list?.forEach((item) => {
        item?.organisations?.forEach((org) => {
          org.app = item.name
        })
      })

      setOrganisationsInApps(organisations.list)

      organisations?.list?.forEach((item) => {
        item?.organisations?.forEach((org) => {
          orgArray = [...orgArray, org]
        })
      })

      setOrganisationOptions(orgArray)
      setLoading(false)
    } catch (err) {}
  }

  const saveSingleGroup = async () => {
    setSaving(true)
    try {
      let response

      if (isCreateNew) {
        response = await createGroup(token(), data)
      } else {
        response = await saveGroup(token(), id, data)
      }

      if (response.error) {
        let errors = []
        Object.values(response.errors) &&
          Object.values(response.errors).forEach((er) => {
            errors = [...errors, er]
          })
        setToastMessage(['error', errors.join(', ')])
        setSaving(false)
        return false
      }

      setToastMessage(['success', response.message])

      setSaving(false)

      if (isCreateNew) {
        history.push(`/app/admin/system-settings/groups/${response.data.id}`)
        const ignore = getOrgList()
      }
    } catch (err) {
      setSaving(false)
    }
  }

  const getSingleGroup = async () => {
    try {
      const response = await singleGroup(token(), id)

      Object.entries(response.data.organisations).map((key) => {
        key[1].id = key[0] * 1
      })

      setData(response.data)

      const ignore = getOrgList()
    } catch (err) {}
  }

  useEffect(() => {
    !isCreateNew ? getSingleGroup() : getOrgList()
  }, [])

  const handleGroupEditData = (value, action) => {
    let currentState = { ...data.organisations }
    if (action === 'delete') {
      delete currentState[value]
    } else {
      currentState = { ...currentState, ...value }
    }

    handleEditData('organisations', currentState)
  }

  const handleExpandedSections = (name) =>
    expanded.includes(name)
      ? setExpanded(expanded.filter((val) => val !== name))
      : setExpanded([...expanded, name])
  const handleExpandedOrgs = (id) =>
    expandedOrgs.includes(id)
      ? setExpandedOrgs(expandedOrgs.filter((val) => val !== id))
      : setExpandedOrgs([...expandedOrgs, id])

  const handleEditData = (prop, value, orgId) => {
    const currentState = { ...data }

    if (prop === 'users_in_organisations') {
      if (!currentState[prop][orgId]) {
        currentState[prop][orgId] = [
          { id: value.id, module_access: value.module_access },
        ]
      } else if (
        currentState[prop][orgId].find((user) => user.id === value.id) !==
        undefined
      ) {
        currentState[prop][orgId] = currentState[prop][orgId].filter(
          (user) => user.id !== value.id,
        )
      } else {
        const newModuleAccess = {
          id: value.id,
          module_access: value.module_access,
        }
        currentState[prop][orgId] = [
          ...currentState[prop][orgId],
          newModuleAccess,
        ]
      }

      if (currentState[prop][orgId].length === 0) {
        delete currentState[prop][orgId]
      }
    } else if (prop === 'org_privileges') {
      currentState.organisations[orgId] = value
    } else if (prop === 'user_privileges') {
      const currentUser = currentState.users_in_organisations[orgId].find(
        (user) => user.id === value.id,
      )
      currentUser.module_access = value.module_access
    } else if (prop === 'new_group_users') {
      currentState.users_in_organisations[orgId] = value
    } else if (prop === 'handleIncludeNewUsers') {
      currentState.organisations[orgId].include_new_users = value
    } else if (prop === 'users_in_organisations_chat') {
      const currentUser = currentState.users_in_organisations[orgId].find(
        (user) => user.id === value.id,
      )
      currentUser.module_access = value.module_access
    } else {
      currentState[prop] = value
    }

    setData(currentState)
  }

  const returnChosenElement = () => {
    if (privilegesModal[0] === 'org') {
      return data.organisations[privilegesModal[1]]
    } else {
      return Object.values(
        data.users_in_organisations[privilegesModal[1]].filter(
          (user) => user.id === privilegesModal[2],
        ),
      )[0]
    }
  }

  useEffect(() => {
    if (isCreateNew && data?.name?.length > 0 && !isSaving) {
      const ignore = saveSingleGroup(true)
    }
  }, [data?.name])

  return isLoading ? (
    <Spinner center size={50} border={1} />
  ) : (
    <div className='single-group-setup'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: 56 }} className='icon-group' />

          <h2>Group Setup - {isCreateNew ? 'Create New' : data.name}</h2>
        </div>

        <span>Distribution Jobs in Queue: {data.items_in_queue}</span>
        <Button
          onClick={() => !isSaving && saveSingleGroup()}
          className='finish-new'
        >
          Finish
        </Button>
      </div>

      <div className='single-group-setup__container'>
        {privilegesModal !== undefined && (
          <PrivilegesModal
            privilegesModal={privilegesModal}
            setPrivilegesModal={setPrivilegesModal}
            chosenElement={returnChosenElement()}
            handleEditData={handleEditData}
            setToastMessage={setToastMessage}
            maxUserPrivileges={
              organisationOptions
                ?.find((org) => org.id === privilegesModal[1])
                ?.users.find((user) => user.id === returnChosenElement().id)
                ?.origin_organisation_privileges?.module_access
            }
            userData={organisationOptions
              ?.find((org) => org.id === privilegesModal[1])
              ?.users.find((user) => user.id === returnChosenElement().id)}
            groupName={data?.name}
          />
        )}

        {toastMessage && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={3000}
            onClose={() => setToastMessage(undefined)}
            open={toastMessage !== undefined}
          >
            <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
              {toastMessage[1]}
            </MuiAlert>
          </Snackbar>
        )}

        <div className='single-group-setup__content'>
          <TextField
            inputRef={titleInput}
            defaultValue={data.name || ''}
            fullWidth
            style={{ marginBottom: 20 }}
            onBlur={() => handleEditData('name', titleInput.current.value)}
            placeholder='Enter group name'
            variant='outlined'
          />

          <div className='setup-section'>
            <div
              className='setup-section__header'
              style={{
                borderRadius: expanded.includes('select-users')
                  ? '12px 12px 0px 0px'
                  : 12,
              }}
              onClick={() => handleExpandedSections('select-users')}
            >
              {!data?.isExpanded && <KeyboardArrowDown />}

              {data?.isExpanded && <KeyboardArrowUp />}

              <h3 style={{ textTransform: 'capitalize' }}>Select users</h3>

              <Tooltip title='123123'>
                <Info style={{ fontSize: 24, margin: '0 10px' }} />
              </Tooltip>
            </div>

            {expanded.includes('select-users') && (
              <SingleGroupEdit
                title='Select Organisation'
                isLoading={isLoading}
                handleGroupEditData={handleGroupEditData}
                selectedOrganisations={data.organisations}
                organisationOptions={organisationOptions}
                isGroupChatEnabled={data.is_chat_enabled}
                isGroupForumEnabled={data.is_forum_enabled}
                expandedOrgs={expandedOrgs}
                handleExpandedOrgs={handleExpandedOrgs}
                users={data.users_in_organisations}
                handleEditData={handleEditData}
                setPrivilegesModal={setPrivilegesModal}
                organisationsInApps={organisationsInApps}
              />
            )}
          </div>
        </div>

        <div className='single-group-setup__misc'>
          <div
            style={{
              padding: '20px 25px',
              borderRadius: 12,
              backgroundColor: '#283143',
              marginBottom: 20,
            }}
          >
            <h3>Miscellaneous</h3>

            <Divider style={{ margin: '15px 0' }} />

            <h5>Select options</h5>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '-9px',
              }}
            >
              {MISC_FIELDS.map(({ label, slug }) => (
                <FormControlLabel
                  key={slug}
                  onChange={(event, value) => handleEditData(slug, value)}
                  style={{ textTransform: 'capitalize', margin: 0 }}
                  label={label}
                  control={
                    <Checkbox
                      checked={data[slug] || false}
                      style={{ padding: 3, color: '#fff' }}
                      icon={<span className='icon-checkbox-empty' />}
                      checkedIcon={<span className='icon-checkbox-checked' />}
                    />
                  }
                />
              ))}

              <div style={{ margin: '10px 10px 0 10px' }}>
                <h5>Group name for chat avatar</h5>

                <TextField
                  inputRef={initialsInput}
                  defaultValue={data?.initials || ''}
                  fullWidth
                  inputProps={{ maxLength: 2 }}
                  style={{ marginTop: 5 }}
                  onBlur={() =>
                    handleEditData('initials', initialsInput.current.value)
                  }
                  placeholder='Set chat avatar name'
                  variant='outlined'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleGroupSetup
