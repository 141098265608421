export const hasNumber = (value) => {
  return new RegExp(/[0-9]/).test(value)
}

export const hasMixed = (value) => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value)
}

export const hasSpecial = (value) => {
  return new RegExp(/[!#@$%^&*£€)(+=._-]/).test(value)
}

export const hasUppercase = (password) => {
  return password ? /[A-Z]/.test(password) : false
}

export const strengthColor = (count) => {
  if (count < 2) return 'red'

  if (count < 3) return '#dc722b'

  if (count < 4) return 'yellow'

  if (count < 5) return 'lightgreen'

  if (count < 6) return 'green'
}

export const strengthIndicator = (value) => {
  let strengths = 0

  if (value.length > 1) strengths++

  if (value.length > 7) strengths++

  if (hasNumber(value)) strengths++

  if (hasSpecial(value)) strengths++

  if (hasMixed(value)) strengths++

  return strengths
}
