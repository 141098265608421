import React from 'react'
import { Button, IconButton } from '@material-ui/core'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
import { CircularProgress } from '@mui/material'
import FroalaEditorComponent from '../../../../../../../shared/FroalaEditorComponent/FroalaEditorComponent'

const ReplyEditor = ({
  handleCloseEditors,
  handleEditorChange,
  isEditorTouched,
  handleAddComment,
  activePiece,
  comment,
  isAdding,
}) => {
  return (
    <div className='single-comment__bottom reply'>
      <SubdirectoryArrowRightIcon style={{ fontSize: 34 }} />

      <div className='editor-wrapper'>
        <IconButton className='close' onClick={handleCloseEditors}>
          <span className='icon-close' />
        </IconButton>

        <FroalaEditorComponent handleEditorChange={handleEditorChange} />

        <div className='editor-footer'>
          <div />

          <Button
            disabled={isEditorTouched.length === 0}
            className='add'
            onClick={() => handleAddComment(activePiece, comment.id)}
          >
            {isAdding ? (
              <CircularProgress
                style={{ fontSize: 18, color: '#fff', width: 20, height: 20 }}
              />
            ) : (
              'Add reply'
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ReplyEditor
