import React, { useState, useEffect } from 'react'
import './ApiUsage.scss'
import { getViewLogs } from '../../../../shared/services/api/logs/logs'
import Pagination from '@material-ui/lab/Pagination'
import {
  Button,
  Divider,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Spinner from '../../../../shared/Spinner'
import {
  formatCreate,
  formatGlobalSearch,
} from '../../../../shared/helpers/formatDate'
import useNotification from '../../../../hooks/useNotification'
import { useAdmin } from '../../../../context/adminContext'
import { getLogsExport } from '../../../../shared/services/api/admin/contentExport/contentExport'

const columnData = [
  { id: 'Email' },
  { id: 'Organisation' },
  { id: 'Content title' },
  { id: 'Content type' },
  { id: 'Open' },
  { id: 'Last Active' },
  { id: 'Time spent' },
]

const ViewLogs = () => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setIsLoading] = useState(false)
  const {
    adminState: { viewLogs },
    setAdminState,
  } = useAdmin()
  const [page, setPage] = useState(
    +Object.fromEntries(new URLSearchParams(viewLogs?.previousQuery))?.page ||
      1,
  )
  const [keyword, setKeyword] = useState(
    Object.fromEntries(new URLSearchParams(viewLogs?.previousQuery))?.keyword ||
      '',
  )
  const [columns] = useState(columnData)
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date())
  const [selectedDateTo, setSelectedDateTo] = useState(new Date())
  const { successToast, errorToast, warningToast } = useNotification()

  const exportLogs = async () => {
    const data = {
      dateFrom: formatGlobalSearch(selectedDateFrom),
      dateTo: formatGlobalSearch(selectedDateTo),
    }
    const response = await getLogsExport(token(), 'views', data)

    if (response?.error) {
      errorToast({ message: response?.message })
    } else {
      successToast({ message: response?.message })
    }
  }

  const fetchLogs = async (forceReload) => {
    try {
      const query = new URLSearchParams({
        keyword: keyword,
        page: page,
      }).toString()

      if (viewLogs?.previousQuery === query && !forceReload) return false
      setIsLoading(true)
      let response = await getViewLogs(token(), query)

      if (response.error) {
        errorToast({ message: response.message })
        setIsLoading(false)

        return false
      }

      if (response?.list?.data?.length === 0) {
        warningToast({ message: 'No results found.' })
      }

      response.list.previousQuery = query
      setAdminState((currentState) => ({
        ...currentState,
        viewLogs: response.list,
      }))
      setIsLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    fetchLogs()
  }, [page])

  const handleSelectedDateFrom = (value) => {
    setSelectedDateFrom(value)
    value > selectedDateTo && setSelectedDateTo(value)
  }

  return (
    <div className='signings'>
      <div className='signings-header'>
        <h2>Content view</h2>
      </div>

      <Divider />

      <div className='signings-header__datepicker'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <h5>Search</h5>

            <form
              onSubmit={(event) => {
                event.preventDefault()
                setPage(1)
                fetchLogs(true)
              }}
            >
              <OutlinedInput
                variant='outlined'
                placeholder='Search'
                value={keyword}
                onChange={(event) => setKeyword(event.target.value)}
                startAdornment={
                  <InputAdornment position='start'>
                    <span style={{ fontSize: 26 }} className='icon-search' />
                  </InputAdornment>
                }
              />
            </form>
          </div>

          <div>
            <div>
              <h5>Date from</h5>

              <DatePicker
                disableToolbar
                autoOk
                variant='inline'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                value={selectedDateFrom}
                onChange={(value) => handleSelectedDateFrom(value)}
                InputProps={{ endAdornment: <span className='icon-down' /> }}
              />
            </div>

            <div>
              <h5>Date to</h5>

              <DatePicker
                disableToolbar
                autoOk
                InputProps={{ endAdornment: <span className='icon-down' /> }}
                variant='inline'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                minDate={selectedDateFrom}
                value={selectedDateTo}
                onChange={setSelectedDateTo}
              />
            </div>

            <Button onClick={() => exportLogs()} size='large'>
              Export
            </Button>
          </div>
        </MuiPickersUtilsProvider>
      </div>

      {isLoading ? (
        <Spinner centerHorizontally padding={20} size={30} />
      ) : (
        <div>
          <table>
            <thead>
              <tr>
                {columns.map((item) => (
                  <th style={{ fontSize: 13 }} key={item.id}>
                    {item.id}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {viewLogs?.data?.map(
                ({
                  id,
                  user_email,
                  content_title,
                  content_type,
                  open,
                  last_active,
                  time_spent,
                  organization_name,
                }) => (
                  <tr key={id}>
                    <td>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {user_email}
                      </span>
                    </td>

                    <td>
                      <span>{organization_name}</span>
                    </td>

                    <td>
                      <span>{content_title}</span>
                    </td>

                    <td>
                      <span>{content_type}</span>
                    </td>

                    <td>{formatCreate(open)}</td>

                    <td>{formatCreate(last_active)}</td>

                    <td>{time_spent} sec</td>
                  </tr>
                ),
              )}
            </tbody>
          </table>

          <Pagination
            onChange={(event, value) => setPage(value)}
            page={page}
            count={viewLogs?.last_page}
            shape='rounded'
            hideNextButton
            hidePrevButton
            color='primary'
          />
        </div>
      )}
    </div>
  )
}

export default ViewLogs
