import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'
import { confirmToken } from '../../../shared/services/api/login/login'

const TwoFactorAuthenticationConfirm = ({ getValues }) => {
  const location = useLocation()
  const history = useHistory()
  const [secret, setSecret] = useState('')
  const [imageData, setImageData] = useState('')

  useEffect(() => {
    setSecret(
      (location.state && location.state.secret) || 'Error displaying token',
    )
    setImageData(
      (location.state && location.state.imageData) ||
        'Error displaying QR code',
    )
  }, [])

  const handleConfirmToken = async () => {
    try {
      const data = {
        noAuthorization: true,
      }

      let response

      if (location.state?.sso_access_token) {
        response = await confirmToken({
          ...data,
          sso_access_token: location.state.sso_access_token,
          sso_provider: 'azure',
        })
      } else {
        response = await confirmToken({
          ...data,
          ...getValues(),
        })
      }

      history.push({
        pathname: '/app/auth/two-factor-authentication',
        state: {
          message: response.message,
          sso_access_token: location.state.sso_access_token,
        },
      })
    } catch (err) {}
  }

  return (
    <Container disableGutters component='main' maxWidth='xs'>
      <Box className='auth-panel'>
        <h3>Scan the QR code</h3>

        <h5 style={{ marginTop: 20 }}>
          Please scan the QR code with the Google Authenticator App on your
          mobile phone.
        </h5>

        {location.state && location.state.imageData ? (
          <img src={imageData} alt={imageData} />
        ) : (
          <h3 style={{ margin: '20px 0', fontWeight: 700 }}>{imageData}</h3>
        )}

        <h5 style={{ marginBottom: 20 }}>
          If you have issues with the QR code, please use the token below:
        </h5>

        <h3 style={{ marginBottom: 20 }}>{secret}</h3>

        <Button
          style={{
            backgroundColor: '#006FFF',
            textTransform: 'capitalize',
            color: '#fff',
            padding: '4px 14px',
            borderRadius: 12,
          }}
          onClick={() => handleConfirmToken()}
        >
          Confirm
        </Button>
      </Box>
    </Container>
  )
}

export default TwoFactorAuthenticationConfirm
