import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Divider } from '@mui/material'
import PropTypes from 'prop-types'

const SelectCategoriesBox = ({
  data,
  categories,
  handleCategories,
  // handleExpandCategories
}) => {
  return (
    <div className='knowledge-base__article-content-right'>
      <h4>Select categories</h4>

      <Divider style={{ margin: '15px 0', backgroundColor: '#1B222E' }} />

      {categories.map((category) => (
        <div
          style={{ borderTop: '1px solid #283143', padding: '2px' }}
          key={category.id}
        >
          <FormControlLabel
            style={{ textTransform: 'capitalize', margin: 0, fontSize: 13 }}
            onChange={() => handleCategories(category.id)}
            label={category.title}
            control={
              <Checkbox
                color='default'
                checked={data.categories?.includes(category.id) || false}
                style={{ padding: 3 }}
                icon={
                  <span
                    style={{ color: '#fff', fontSize: 28 }}
                    className='icon-checkbox-empty'
                  />
                }
                checkedIcon={
                  <span
                    style={{ fontSize: 28, color: '#006fff' }}
                    className='icon-checkbox-checked'
                  />
                }
              />
            }
          />
        </div>
        // <div
        //     className='category-wrapper' key={category.id} onClick={(event) => handleExpandCategories(event, category.id)}
        //     style={{cursor: 'pointer'}}
        // >
        //     <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0', padding: '8px 10px' }}>
        //     {/* <IconButton style={{ marginRight: 3, padding: 6 }}>
        //         <span
        //         style={{ fontSize: 18, color: '#fff', transform: category.isExpanded ? null : 'rotate(270deg)' }}
        //         className='icon-down'
        //         />
        //     </IconButton> */}

        //     <span>{category.title}</span>
        //     </div>

        //     {/* {category.isExpanded && category.child_categories.map(sub => (
        //     <div style={{ borderTop: '1px solid #283143', padding: '1.5px 20px', }} key={sub.id}>
        //         <FormControlLabel
        //         style={{ textTransform: 'capitalize', margin: 0, fontSize: 13 }}
        //         onChange={() => handleCategories(sub.id)}
        //         label={sub.title}
        //         control={
        //             <Checkbox
        //             color='default'
        //             checked={data.categories?.includes(sub.id) || false}
        //             style={{ padding: 3 }}
        //             icon={<span style={{ color: '#fff', fontSize: 28 }} className='icon-checkbox-empty' />}
        //             checkedIcon={<span style={{ fontSize: 28, color: '#006fff' }} className='icon-checkbox-checked' />}
        //             />
        //         }
        //         />
        //     </div>
        //     ))} */}
        // </div>
      ))}
    </div>
  )
}

SelectCategoriesBox.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  categories: PropTypes.instanceOf(Array).isRequired,
  handleCategories: PropTypes.func.isRequired,
  // handleExpandCategories:  PropTypes.func.isRequired,
}

export default SelectCategoriesBox
