import { v4 as uuid } from 'uuid'
import { useSnackbar } from 'notistack'
import MuiAlert from '@material-ui/lab/Alert'
import { IconButton } from '@material-ui/core'

const SNACKBAR_VARIANT = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
}

const { SUCCESS, INFO, ERROR, WARNING } = SNACKBAR_VARIANT

const defaultNotificationConfig = {
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  autoHideDuration: 5000,
}

export default function useNotification(
  notificationConfig = defaultNotificationConfig,
) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const openSnackbar = ({ message, variant, ...rest }) => {
    const key = uuid()
    closeSnackbar()
    if (rest.keepOpen) defaultNotificationConfig.autoHideDuration = null //case for opening left menu module with no access to it

    enqueueSnackbar(message, {
      ...notificationConfig,
      key,
      content: (
        <MuiAlert
          variant='filled'
          severity={variant}
          className='notification-wrapper'
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {rest.forcePurify ? (
              <div dangerouslySetInnerHTML={{ __html: message }} />
            ) : (
              <span>{message}</span>
            )}

            {rest.keepOpen && (
              <IconButton
                style={{ color: '#fff', padding: 9, fontSize: 22 }}
                onClick={() => closeSnackbar()}
              >
                <span className='icon-close' />
              </IconButton>
            )}
          </div>
        </MuiAlert>
      ),
      variant,
    })
  }

  const successToast = (props) => openSnackbar({ variant: SUCCESS, ...props })
  const infoToast = (props) => openSnackbar({ variant: INFO, ...props })
  const errorToast = (props) => openSnackbar({ variant: ERROR, ...props })
  const warningToast = (props) => openSnackbar({ variant: WARNING, ...props })

  return {
    successToast,
    infoToast,
    warningToast,
    errorToast,
  }
}
