import React, { useEffect, useState } from 'react'
import { allowed } from '../../shared/services/api/apiDocumentation/apiDocumentation'
import { apiUrl } from '../../shared/config'
import { useHistory } from 'react-router-dom'

const ApiDocumentation = () => {
  const [isAllowed, setAllowed] = useState(null)
  const history = useHistory()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const checkIfAllowed = async () => {
    try {
      const response = await allowed(token())

      if (response.error) {
        setAllowed(false)
        history.push('/app/workspace')
        return false
      }

      setAllowed(true)
      getDocumentation()
    } catch (err) {}
  }

  const getDocumentation = async () => {
    try {
      const script = document.createElement('script')
      script.src =
        'https://cdn.jsdelivr.net/npm/redoc@latest/bundles/redoc.standalone.js'
      script.async = true
      document.body.appendChild(script)
    } catch {}
  }

  useEffect(() => {
    checkIfAllowed()
  }, [])

  return (
    isAllowed && (
      <redoc
        style={{ display: 'block', background: '#fff', width: '100%' }}
        // eslint-disable-next-line react/no-unknown-property
        spec-url={`${apiUrl}/documentation.json?access_token=${token()}`}
      />
    )
  )
}

export default ApiDocumentation
