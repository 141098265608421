import React, { useEffect, useState } from 'react'
import { Avatar, Checkbox, Divider, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { allowedSectors } from '../../../../../shared/services/api/createContent/createAlert'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const SectorRelevance = ({ data, type }) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [sectorOptions, setSectorOptions] = useState([])
  const { handleCreateState } = useCreateManagementContext()
  const severity = [1, 2, 3, 4, 5]

  useEffect(() => {
    loadSectors()
  }, [])

  const loadSectors = async () => {
    try {
      const response = await allowedSectors(token(), type)
      setSectorOptions(response.list)
    } catch {}
  }

  const handleSeverityState = (event, value, id) => {
    event.stopPropagation()
    const currentState = { ...data }
    if (id) {
      currentState.sector_priorities[id] =
        currentState.sector_priorities[id] === value ? null : value
    } else {
      currentState.sectors = value.map((item) => (item.name ? item.id : item))
    }
    handleCreateState('relevance', currentState, true)
  }

  const returnClass = (id, level) => {
    if (data.sector_priorities[id] && data.sector_priorities[id] === level) {
      return `level-${data.sector_priorities[id]}`
    } else {
      return 'inactive'
    }
  }

  return (
    <div className='content-section__content'>
      <Divider />

      <h5>Select Sectors</h5>

      <div className='sector'>
        <Autocomplete
          multiple
          value={data?.sectors || []}
          options={sectorOptions || []}
          getOptionSelected={(option, value) => option.id === value}
          disableCloseOnSelect
          onChange={(event, value) => handleSeverityState(event, value)}
          getOptionLabel={(option) => option.name}
          renderOption={(option, props) => {
            return (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minHeight: 30,
                }}
                className='sector-checkbox'
              >
                <div>
                  <Checkbox
                    style={{ padding: 0, marginRight: 10 }}
                    checked={props.selected}
                    icon={<span className='icon-checkbox-empty' />}
                    checkedIcon={<span className='icon-checkbox-checked' />}
                  />

                  <span style={{ fontSize: 13 }}>{option.name}</span>
                </div>

                {type === 'alert' && props.selected && (
                  <div
                    style={{ display: 'flex', flexDirection: 'row' }}
                    className='sector-levels'
                  >
                    {severity.map((level) => (
                      <Avatar
                        key={level}
                        onClick={(event) =>
                          handleSeverityState(event, level, option.id)
                        }
                        className={returnClass(option.id, level)}
                      >
                        {level}
                      </Avatar>
                    ))}
                  </div>
                )}
              </div>
            )
          }}
          renderInput={(params) => {
            params.InputProps.startAdornment = params.InputProps.startAdornment
              ? `Sectors Selected: ${params.InputProps.startAdornment.length}`
              : ''
            return (
              <TextField
                {...params}
                style={{ color: '#fff' }}
                variant='outlined'
                placeholder='Search Sectors'
              />
            )
          }}
        />
      </div>
    </div>
  )
}

export default SectorRelevance
