import { useQuery } from '@tanstack/react-query'
import { getReportedResource } from '../../api/moderation/moderation'

export const useQueryGetReportedResources = (type: string) => {
  return useQuery({
    queryKey: ['moderation', type],
    queryFn: () => getReportedResource(type),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}
