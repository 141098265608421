/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  Divider,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { Dispatch, SetStateAction, useState } from 'react'
import { useChat } from '../../../../context/chatContext'
import SingleUser from './SingleUser'

interface Props {
  setSearchOpen: Dispatch<SetStateAction<boolean>>
}
const ConversationListUserModal = ({ setSearchOpen }: Props) => {
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const { people } = useChat()

  return (
    <Dialog
      // style={{ maxHeight: 500 }}
      PaperProps={{
        style: {
          maxHeight: 500,
        },
      }}
      fullWidth
      maxWidth='sm'
      open
      onClose={() => setSearchOpen(false)}
    >
      <div style={{ padding: '24px 24px 0px 24px' }}>
        <h3>Search all users</h3>

        <Divider style={{ margin: '10px 0', border: 'none' }} />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 16px 10px 16px',
        }}
      >
        <TextField
          value={searchPhrase}
          onChange={(event) => setSearchPhrase(event.target.value)}
          inputProps={{
            style: { padding: '14px 14px 14px 0' },
          }}
          InputProps={{
            // style: { padding: 14 },
            startAdornment: (
              <InputAdornment position='start'>
                <span style={{ fontSize: 26 }} className='icon-search' />
              </InputAdornment>
            ),
          }}
          placeholder='Search people'
          variant='outlined'
          type='search'
        />
      </div>

      {people?.list
        ?.filter(
          ({ display_name }) =>
            display_name?.toLowerCase().includes(searchPhrase?.toLowerCase()),
        )
        .map(
          ({ display_name, central_user_id, avatar, chat_settings }: any) => (
            <SingleUser
              key={central_user_id}
              display_name={display_name}
              central_user_id={central_user_id}
              avatar={avatar}
              chat_settings={chat_settings}
              setSearchOpen={setSearchOpen}
            />
          ),
        )}

      <div
        style={{
          padding: '0 24px 16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => setSearchOpen(false)}>Cancel</Button>
      </div>
    </Dialog>
  )
}

export default ConversationListUserModal
