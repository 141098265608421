import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const submitTicket = (token, payload) =>
  client.post(apiUrl + `/api/support-ticket/submit`, payload, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
