import React, { useEffect } from 'react'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

const CreateToast = ({ toastMessage, setToastMessage }) => {
  useEffect(() => {
    setTimeout(() => setToastMessage(undefined), 6000)
  }, [])

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={toastMessage !== undefined}
    >
      <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
        {toastMessage[1]}
      </MuiAlert>
    </Snackbar>
  )
}

export default CreateToast
