/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-autofocus */
import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { login2FA } from '../../../shared/services/api/login/login'
import Spinner from '../../../shared/Spinner'

const TwoFactorAuthentication = ({ getValues, setToastMessage }) => {
  const [isLoading, setLoading] = useState(false)
  const location = useLocation()
  const [token, setToken] = useState('')
  const history = useHistory()

  useEffect(() => {
    location?.state?.message &&
      setToastMessage(['success', location.state.message])
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const data = {
        token: token,
        noAuthorization: true,
      }

      let response

      if (location.state?.sso_access_token) {
        response = await login2FA({
          ...data,
          sso_access_token: location.state.sso_access_token,
          sso_provider: 'azure',
        })
      } else {
        response = await login2FA({
          ...data,
          ...getValues(),
        })
      }

      if (response.error === true) {
        setLoading(false)
        setToastMessage(['error', response.message])
        return false
      }

      setLoading(false)

      const {
        access_token,
        refresh_token,
        expires_in,
        is_admin,
        refresh_token_expire_in,
      } = response

      if (access_token && refresh_token) {
        const contentUrl = localStorage.getItem('targetUrl')
        localStorage.setItem('auth', 'true')
        localStorage.setItem('access_token', access_token)
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem(
          'expires_in',
          Math.round(new Date() / 1000) + expires_in,
        )
        localStorage.setItem(
          'refresh_token_expire_in',
          Math.round(new Date() / 1000) + refresh_token_expire_in,
        )
        localStorage.removeItem('targetUrl')

        is_admin
          ? history.push('/app/admin')
          : history.push(`/app/workspace${contentUrl || ''}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container disableGutters component='main' maxWidth='xs'>
      <Box className='auth-panel'>
        <Typography className='headline' component='h1' variant='h5'>
          Log in
        </Typography>

        <Typography component='h3' variant='h6'>
          Two-Factor Authentication required
        </Typography>

        <span>
          Please use Google Authentication Mobile App installed on your mobile
          phone and type the token below.
        </span>

        <form
          className='two-factor-authentication-form'
          onSubmit={handleSubmit}
        >
          <TextField
            onChange={(event) => setToken(event.target.value)}
            variant='outlined'
            margin='normal'
            required
            style={{ width: '100%' }}
            id='token'
            label='Token'
            autoFocus
          />

          <Box className='form-buttons'>
            <Button
              style={{ minWidth: 88 }}
              type='submit'
              variant='contained'
              color='primary'
              className='form-button--submit'
            >
              {isLoading ? (
                <Spinner centerHorizontally size={23} color='#fff' />
              ) : (
                <span>SUBMIT</span>
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  )
}

export default TwoFactorAuthentication
