import React, { createContext, useContext, useState } from 'react'
export const adminContext = createContext({
  adminState: {},
  setAdminState: () => {},
})
// eslint-disable-next-line react/prop-types
export const AdminContextProvider = ({ children }) => {
  const [adminState, setAdminState] = useState({})
  const props = {
    adminState,
    setAdminState,
  }
  const Provider = adminContext.Provider
  return <Provider value={props}>{children}</Provider>
}
export const useAdmin = () => useContext(adminContext)
