import React from 'react'
import { Button } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import '../SystemSettingsList.scss'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Menu } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

const SingleFramework = (props) => {
  const { id, name, setConfirmDelete, tabUrl } = props
  const history = useHistory()
  let sortable = useSortable({ id: id })

  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable

  const disableAxis = (scale) => {
    if (scale) {
      scale.scaleX = 1
      scale.scaleY = 1
      scale.x = 0
    }
    return scale
  }

  const style = {
    transform: CSS.Transform.toString(disableAxis(transform)),
    transition,
    opacity: isDragging ? 0.6 : 1,
    zIndex: isDragging ? '10000' : 'auto',
  }

  return (
    <div className='list-item' ref={setNodeRef} style={style}>
      <div className='list-item__left'>
        <div style={{ fontSize: 14, paddingLeft: 15 }}>
          <span>{name}</span>
        </div>
      </div>

      <div
        className='list-item__right'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Menu className='icon-drag' {...listeners} {...attributes} />

        <Button
          onClick={() =>
            history.push(`/app/admin/system-settings/${tabUrl}/${id}`)
          }
          startIcon={<CreateIcon />}
          className='edit'
        >
          Edit
        </Button>

        <Button onClick={() => setConfirmDelete(id)} className='delete'>
          Delete
        </Button>
      </div>
    </div>
  )
}

export default SingleFramework
