import React, { useState, useEffect } from 'react'
import { Chip, CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { tagsList } from '../../../../../shared/services/api/createContent/createAlert'
import { handleMitreTags } from '../../helpers/modifyMitreTags'
import differenceBy from 'lodash/differenceBy'
import PropTypes from 'prop-types'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

/**
 * Capability - create mode
 * @param {object} data - includes data
 * @param {string} internal_key - connectors internal key
 * @param {string} name - connectors name
 * @param {string|array} categorySlug - connector category slug
 * @param {object} tagTactics - content tag tactics
 * @param {object} devContent - content dev_content
 * @param {array} devTags - content dev_tags
 * @param {array} tags - content tags
 * @param {array} popularTags - connector popular tags
 */

const Connectors = ({
  data,
  internal_key,
  name,
  categorySlug,
  tagTactics,
  devContent,
  devTags,
  tags,
  popularTags,
}) => {
  const {
    handleCreateState,
    mitreConfig: { configuration },
  } = useCreateManagementContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isLoading, setLoading] = useState(false)
  const [tagsOptions, setTagsOptions] = useState([])

  const handleState = (value, shouldAdd) => {
    const newMitreData = handleMitreTags(
      configuration,
      value.id || value.tag_id,
      value.tactic_id,
      value,
      tagTactics,
      devTags,
      tags,
      devContent,
      internal_key,
      shouldAdd,
    )
    handleCreateState('dev_content_mitre', newMitreData, true, internal_key)
  }

  const getTagOptions = async () => {
    setLoading(true)
    try {
      const type = 'tag'
      const response = await tagsList(token(), type, categorySlug)
      response?.list?.length > 0 && setTagsOptions(response.list)
      setLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    getTagOptions()
  }, [])

  return (
    <>
      <h5>{name} tags</h5>

      <div style={{ width: '100%' }} className='associated'>
        <div style={{ margin: '10px 0' }}>
          {data?.content?.connectors?.map((piece) => (
            <Chip
              style={{
                backgroundColor: '#FFF4C9',
                color: '#000',
                margin: 2,
                fontSize: 10,
              }}
              onClick={() => {}}
              onDelete={() => handleState(piece)}
              label={piece.name}
              key={piece.id + '_' + piece.name}
              deleteIcon={
                <span
                  className='icon-close'
                  style={{
                    width: 'unset',
                    height: 'unset',
                    fontSize: 14,
                    padding: '4px 4px 4px 0',
                    color: '#000',
                  }}
                />
              }
            />
          ))}
        </div>

        <Autocomplete
          multiple
          blurOnSelect={false}
          filterSelectedOptions
          disableCloseOnSelect
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.title}
          options={tagsOptions || []}
          value={data?.content?.connectors || []}
          onChange={(event, value) => {
            const { key } = event

            if (key === 'Backspace' || key === 'Delete') {
              return false
            }

            const newValue = value[value.length - 1]
            handleState(newValue, true)
          }}
          renderTags={() => {
            return false
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Search tags'
              variant='outlined'
              className='tag-input'
              size='small'
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress size={20} /> : null}

                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>

      {popularTags.length > 0 && (
        <div style={{ margin: '10px 0' }}>
          <h5>Popular tags:</h5>

          {differenceBy(popularTags, data.content.connectors, 'id').map(
            (piece) => (
              <Chip
                style={{
                  backgroundColor: '#0095ff',
                  color: '#fff',
                  margin: 2,
                  fontSize: 11,
                }}
                onClick={() => handleState(piece, true)}
                label={piece.name}
                key={piece.id}
                deleteIcon={
                  <span
                    className='icon-close'
                    style={{
                      width: 'unset',
                      height: 'unset',
                      fontSize: 14,
                      padding: '4px 4px 4px 0',
                      color: '#000',
                    }}
                  />
                }
              />
            ),
          )}
        </div>
      )}
    </>
  )
}

Connectors.displayName = 'Connectors'

/**
 * The properties.
 * @type {Object}
 */

Connectors.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  internal_key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  categorySlug: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]).isRequired,
  tagTactics: PropTypes.instanceOf(Object).isRequired,
  devContent: PropTypes.instanceOf(Object).isRequired,
  devTags: PropTypes.instanceOf(Array).isRequired,
  tags: PropTypes.instanceOf(Array).isRequired,
  popularTags: PropTypes.instanceOf(Array).isRequired,
}

export default Connectors
