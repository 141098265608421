/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, IconButton } from '@material-ui/core'
import { useChat } from '../../../../../context/chatContext'
import { apiUrl } from '../../../../../shared/config'

const SingleConversationHeader = ({}) => {
  const { activeChat, activeUserChat, people, setChatOpen } = useChat()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const RenderAvatar = () => {
    const hasAvatar =
      people?.list?.find(
        ({ central_user_id }) => central_user_id === activeUserChat?.id,
      )?.avatar || null

    return hasAvatar ? <UserAvatar avatarUrl={hasAvatar} /> : <DummyAvatar />
  }

  const DummyAvatar = () => (
    <span style={{ fontSize: 26 }} className='icon-user' />
  )

  const UserAvatar = ({ avatarUrl }: any) => (
    <img
      src={`${apiUrl}/storage/module/user/avatar/${avatarUrl}?access_token=${token()}`}
      alt={avatarUrl}
      style={{
        width: 40,
        height: 40,
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    />
  )
  return (
    <div className='chat__single-conversation-header'>
      <div className='chat__single-conversation-header-left'>
        <Avatar className='chat-avatar' style={{ marginRight: 10 }}>
          <RenderAvatar />
        </Avatar>
        <div style={{ fontSize: 14, fontWeight: 600 }}>
          {activeUserChat?.id ? activeUserChat?.name : activeChat?.name}
        </div>
      </div>

      <div className='chat__single-conversation-header-right'>
        <IconButton
          onClick={() => setChatOpen(false)}
          style={{ color: '#fff', padding: 8 }}
        >
          <span className='icon-close' />
        </IconButton>
      </div>
    </div>
  )
}

export default SingleConversationHeader
