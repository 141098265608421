import React from 'react'

import './ButtonSection.scss'

const ButtonSection = ({
  section: { strip_colour, headline, colour, url, icon, text },
}) => (
  <div className='strip' style={{ backgroundColor: `${strip_colour}` }}>
    <h2 className='strip__title'>{headline}</h2>

    <a
      className='strip__button'
      style={{ backgroundColor: `${colour}` }}
      href={url.indexOf('://') === -1 ? 'https://' + url : url}
      target='_blank'
      rel='noopener noreferrer'
    >
      <span
        className={`icon-${icon}`}
        style={{ fontSize: 20, color: '#212935' }}
      />

      {text}
    </a>
  </div>
)

export default ButtonSection
