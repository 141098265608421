/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Divider, Tooltip } from '@material-ui/core'
import { Reply, OpenInNew } from '@material-ui/icons'
import React, { useState } from 'react'
import { formatDate } from '../../../shared/helpers/formatDate'
import { typeNames } from '../../../shared/helpers/typeNames'
import './LatestTab.scss'
import PropTypes from 'prop-types'
import { Avatar, IconButton } from '@mui/material'
import { useViewStateContext } from '../../../context/viewStateContext'

/**
 * LatestTab
 * @param {object} content - list of items to map
 * @param {function} addWorkspace - adding a workspace
 */

const CommentLayout = ({ data, addWorkspace }) => {
  const {
    content,
    author_name,
    updated_at,
    commentable_item_title,
    commentable_item_type,
    commentable_item_id,
  } = data

  return (
    <div
      className='listing-item__details'
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div>
        <div
          style={{ color: '#fff' }}
          className='listing-item__details-content'
          dangerouslySetInnerHTML={{ __html: content }}
        />

        <span style={{ color: '#ffffff80', fontSize: 12 }}>
          {author_name}
          {` - `}

          {formatDate(updated_at)}
        </span>

        <div style={{ marginTop: 10 }}>
          <span
            style={{
              backgroundColor: '#272d39',
              padding: '4px 10px',
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            New comment on: {commentable_item_title}
          </span>
        </div>
      </div>

      <Button
        className='listing-item__details-reply'
        onClick={() =>
          addWorkspace(
            commentable_item_type,
            commentable_item_id,
            commentable_item_title,
            true,
          )
        }
        startIcon={<Reply />}
      >
        Reply
      </Button>
    </div>
  )
}

const ContentLayout = ({ content }) => {
  const {
    priority,
    priority_name,
    main_content_type,
    profile_type,
    content_label,
    read,
    title,
    author,
    updated_at,
  } = content

  return (
    <>
      <div>
        <Avatar
          style={{
            border: '1px solid #767A82',
            backgroundColor: 'transparent',
            marginRight: 20,
            marginTop: 5,
          }}
        >
          <span
            style={{
              fontSize: 28,
              padding:
                main_content_type === 'alert'
                  ? '0 0 2px 0'
                  : main_content_type === 'scenario'
                  ? '2px 0 0 0 '
                  : 0,
            }}
            className={`icon-${
              main_content_type === 'profile' ? profile_type : main_content_type
            }`}
          />
        </Avatar>

        {priority_name && (
          <div
            className='priority'
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Avatar className={`level-${priority}`}>{priority}</Avatar>

            <span style={{ fontSize: 14, marginTop: 5 }}>Global</span>
          </div>
        )}
      </div>

      <div className='listing-item__details'>
        <div className='listing-item__details-header'>
          <span
            style={{
              backgroundColor: '#506185',
              padding: '4px 8px',
              fontSize: 12,
              borderRadius: 4,
              textTransform: 'capitalize',
            }}
          >
            {profile_type
              ? typeNames[profile_type].name
              : main_content_type === 'alert'
              ? 'Threat Alerts'
              : main_content_type + 's'}
          </span>
        </div>

        <h4 style={{ color: '#fff' }}>{title}</h4>

        <Divider
          style={{
            backgroundColor: '#767A82',
            width: '100%',
            margin: '15px 0',
            height: 0.5,
          }}
        />

        <span style={{ color: '#ffffff80', fontSize: 12 }}>
          Updated at: {formatDate(updated_at)}
        </span>

        <span style={{ color: '#ffffff80', fontSize: 12 }}>
          Author: {author}
        </span>

        <div className='labels' style={{ marginTop: '12px' }}>
          {content_label && (
            <span className='label-status'>{content_label}</span>
          )}

          {read === false && <span className='label-read'>Unread</span>}
        </div>
      </div>
    </>
  )
}

const LatestTab = ({ data }) => {
  const { addWorkspace } = useViewStateContext()
  const [isHoveredItem, setIsHoveredItem] = useState(null)

  return (
    <div className='latest-tab'>
      {data.length > 0 ? (
        data.map((content) => {
          const { id, title, main_content_type, profile_type } = content

          return (
            <div
              key={`${id}-${main_content_type}`}
              className='listing-item'
              onMouseEnter={() => setIsHoveredItem(id)}
              onMouseLeave={() => setIsHoveredItem(null)}
              style={{ position: 'relative' }}
              onClick={() =>
                main_content_type &&
                addWorkspace(
                  profile_type ? profile_type : main_content_type,
                  id,
                  title,
                )
              }
            >
              <ContentLayout content={content} />

              {isHoveredItem === id && (
                <Tooltip arrow placement='top' title='Open in new workspace'>
                  <IconButton
                    className='open-new-tab'
                    style={{
                      position: 'absolute',
                      right: 14,
                      top: 6,
                      padding: 9,
                    }}
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      addWorkspace(
                        profile_type ? profile_type : main_content_type,
                        id,
                        title,
                        undefined,
                        true,
                      )
                      // handleOpenPiece(id, undefined, profile_type ? profile_type : main_content_type, title)
                    }}
                  >
                    <OpenInNew style={{ color: '#fff', fontSize: 24 }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )
        })
      ) : (
        <h4 style={{ textAlign: 'center', color: '#fff' }}>
          No content to display
        </h4>
      )}
    </div>
  )
}

LatestTab.displayName = 'LatestTab'
CommentLayout.displayName = 'CommentLayout'
ContentLayout.displayName = 'ContentLayout'

/**
 * The properties.
 * @type {Object}
 */

LatestTab.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

ContentLayout.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
  // hoveredItem: PropTypes.string.isRequired,
}

CommentLayout.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  addWorkspace: PropTypes.func.isRequired,
}

export default LatestTab
