export const statuses = [
  { name: 'Approved', slug: 'approved' },
  { name: 'Update Request', slug: 'update-request' },
  { name: 'Awaiting Approval', slug: 'awaiting-approval' },
  { name: 'Draft', slug: 'draft' },
  { name: 'Rejected', slug: 'rejected' },
  { name: 'Unpublished Changes', slug: 'unpublished-changes' },
]

export const contentTypes = [
  { name: 'Threat Alerts', slug: 'threat-alerts' },
  { name: 'Scenarios', slug: 'scenarios' },
  { name: 'Reports', slug: 'reports' },
  { name: 'Threat Actors', slug: 'threat-actors' },
  { name: 'Incidents', slug: 'incidents' },
  { name: 'Operations', slug: 'operations' },
  { name: 'Malware & Tools', slug: 'malware-tools' },
]
