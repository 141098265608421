import React from 'react'
import { Tabs, Tab } from '@mui/material'
import { useHistory } from 'react-router-dom'

import './KnowledgeBaseAdmin.scss'
import {
  CreateModal,
  DeleteModal,
  ResourcesTab,
  SettingsTab,
  LeftBar,
} from './components'
import useLogic from './useKnowledgeBaseAdmin'
import Button from '../../shared/components/Button'

const KnowledgeBaseAdmin = () => {
  const {
    categories,
    getAllFilteredArticles,
    getCurrentTabValue,
    handleAddCategory,
    handleCurrentTab,
    handleDeleteArticle,
    handleDeleteCategory,
    handleExpandCategories,
    handleSubmit,
    isCategorySlug,
    isCreateModalOpen,
    isDeleteModalOpen,
    isLoading,
    setIsCreateModalOpen,
    setIsDeleteModalOpen,
    setTitleInput,
    slug,
    titleInput,
  } = useLogic()
  const history = useHistory()

  return (
    <div className='knowledge-base-admin'>
      {isCreateModalOpen && (
        <CreateModal
          isModalOpen={isCreateModalOpen}
          setIsModalOpen={setIsCreateModalOpen}
          onAdd={handleAddCategory}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={handleDeleteCategory}
        />
      )}

      <div className='knowledge-base-admin__top'>
        <div className='knowledge-base-admin__top-heading'>
          <h3>Knowledge Base</h3>
        </div>

        <div className='knowledge-base-admin__top-manage'>
          <div>
            {slug !== 'all' && (
              <Tabs
                value={getCurrentTabValue() || 'resources'}
                onChange={(_, newValue) => handleCurrentTab(newValue)}
              >
                <Tab
                  style={{ color: '#fff' }}
                  label='Resources'
                  value='resources'
                />

                <Tab
                  style={{ color: '#fff' }}
                  label='Settings'
                  value='settings'
                />
              </Tabs>
            )}
          </div>

          <Button
            onClick={() =>
              history.push('/app/admin/knowledge-base/article/create')
            }
          >
            <span className='icon-edit' style={{ fontSize: 20 }} />

            <span>Create Article</span>
          </Button>
        </div>
      </div>

      <div className='knowledge-base-admin__content'>
        <LeftBar
          categories={categories}
          handleExpandCategories={handleExpandCategories}
          isLoading={isLoading}
          setIsCreateModalOpen={setIsCreateModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />

        {getCurrentTabValue() === 'resources' ? (
          <ResourcesTab
            getAllFilteredArticles={getAllFilteredArticles}
            handleDeleteArticle={handleDeleteArticle}
            isLoading={isLoading}
          />
        ) : (
          <SettingsTab
            handleSubmit={handleSubmit}
            isCategorySlug={isCategorySlug}
            setTitleInput={setTitleInput}
            titleInput={titleInput}
          />
        )}
      </div>
    </div>
  )
}

export default KnowledgeBaseAdmin
