import React from 'react'
import { Button, Divider } from '@material-ui/core'
import PropTypes from 'prop-types'

/**
 * ConfirmAutosave
 * @param {function} setConfirmAutosaveModal - handling delete modal state
 * @param {function} delayedAutosave - handling delete modal state
 */

const ConfirmAutosave = ({ setConfirmAutosaveModal, delayedAutosave }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 28,
        zIndex: 100,
        padding: '18px 24px',
        borderRadius: 12,
        boxShadow: '0px 4px 10px #00000029',
        backgroundColor: '#fff',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      <h3>Save the content?</h3>

      <Divider style={{ margin: '10px 0', border: 'none' }} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          style={{ textTransform: 'capitalize' }}
          onClick={() => setConfirmAutosaveModal(false)}
        >
          No
        </Button>

        <Button
          style={{
            backgroundColor: '#006fff',
            color: '#fff',
            textTransform: 'capitalize',
            fontWeight: 700,
            borderRadius: 12,
            padding: '6px 20px',
            minWidth: 85,
          }}
          onClick={() => {
            delayedAutosave()
            setConfirmAutosaveModal(false)
          }}
        >
          <span>Yes</span>
        </Button>
      </div>
    </div>
  )
}

ConfirmAutosave.displayName = 'ConfirmAutosave'

/**
 * The properties.
 * @type {Object}
 */

ConfirmAutosave.propTypes = {
  setConfirmAutosaveModal: PropTypes.func.isRequired,
  delayedAutosave: PropTypes.func.isRequired,
  // isDeleting: PropTypes.bool.isRequired,
}

export default ConfirmAutosave
