import client from '../../../http/client'

import { apiUrl } from '../../../../config/index'

export const getModules = (token) =>
  client.get(apiUrl + `/api/modules`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const groups = (token) =>
  client.get(apiUrl + `/api/groups`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const createGroup = (token, payload) =>
  client.post(apiUrl + `/api/groups`, payload, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const saveGroup = (token, id, payload) =>
  client.put(apiUrl + `/api/groups/${id}`, payload, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleGroup = (token, id) =>
  client.get(apiUrl + `/api/groups/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const organisationsList = (token) =>
  client.get(
    apiUrl + `/api/apps/list-with-organisations?nopagination&with_users=1`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const userPermissions = (token, orgId, userId) =>
  client.get(apiUrl + `/api/modules/content/organisations/${orgId}/${userId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
