export const priorityItems = [
  { value: 1, name: 'minor' },
  { value: 2, name: 'major' },
  { value: 3, name: 'critical' },
]

export const ticketTypes = [
  { id: 1, name: 'Report issue', type: 'issue' },
  { id: 2, name: 'Request for intelligence', type: 'rfi' },
  { id: 3, name: 'Comments', type: 'comments' },
  { id: 4, name: 'Security incident', type: 'security_incident' },
  { id: 5, name: 'Other', type: 'other' },
]

export const ticketConfig = {
  issue: [
    { type: 'single-line', label: 'Title', slug: 'title', required: true },
    {
      type: 'multi-line',
      label: 'Description',
      slug: 'description',
      required: true,
    },
    { type: 'upload', label: 'Upload files' },
  ],
  rfi: [
    {
      type: 'select',
      label: 'Select a category',
      required: true,
      options: [
        {
          id: 1,
          label: 'Additional information',
          fields: [
            {
              type: 'single-line',
              label: 'Profile / Threat Alert / Report name',
              slug: 'rfi_entity_title',
              required: true,
            },
            {
              type: 'single-line',
              label: 'Title',
              slug: 'title',
              required: true,
            },
            {
              type: 'multi-line',
              label: 'Information required',
              slug: 'rfi_information',
              required: true,
            },
            {
              type: 'multi-line',
              label: 'Additional comments',
              slug: 'rfi_additional_comments',
            },
          ],
        },
        {
          id: 2,
          label: 'Request update to content',
          fields: [
            {
              type: 'single-line',
              label: 'Profile / Threat Alert / Report name',
              slug: 'rfi_entity_title',
              required: true,
            },
            {
              type: 'single-line',
              label: 'Title',
              slug: 'title',
              required: true,
            },
            {
              type: 'multi-line',
              label: 'Information required',
              slug: 'rfi_information',
              required: true,
            },
            {
              type: 'multi-line',
              label: 'Additional comments',
              slug: 'rfi_additional_comments',
            },
          ],
        },
        {
          id: 3,
          label: 'New content request',
          fields: [
            {
              type: 'single-line',
              label: 'Title',
              slug: 'title',
              required: true,
            },
            {
              type: 'select',
              label: 'Type',
              required: true,
              slug: 'rfi_type',
              options: [
                { id: 1, name: 'Threat Alerts' },
                { id: 2, name: 'Profiles' },
                { id: 3, name: 'Scenarios' },
                { id: 4, name: 'Reports' },
                // { id: 5, name: 'Project' },
              ],
            },
            {
              type: 'select',
              label: 'Reason',
              required: true,
              slug: 'rfi_reason',
              options: [
                { id: 1, name: 'Intelligence Gap' },
                { id: 2, name: 'Business case' },
                { id: 3, name: 'Project' },
              ],
            },
            {
              type: 'multi-line',
              label: 'Supporting information',
              slug: 'rfi_supporting_information',
              required: true,
            },
            { type: 'multi-line', label: 'Sources', slug: 'rfi_sources' },
          ],
        },
      ],
    },
  ],
  comments: [
    { type: 'single-line', label: 'Title', slug: 'title', required: true },
    {
      type: 'multi-line',
      label: 'Write your comments',
      slug: 'description',
      required: true,
    },
  ],
  security_incident: [
    { type: 'single-line', label: 'Title', slug: 'title', required: true },
    {
      type: 'multi-line',
      label: 'Description',
      slug: 'description',
      required: true,
    },
  ],
  other: [
    { type: 'single-line', label: 'Title', slug: 'title', required: true },
    { type: 'multi-line', label: 'Details', slug: 'details', required: true },
  ],
}
