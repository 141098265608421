import { useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import './Home.scss'
import LeftMenu from '../LeftMenu'
import TopBar from '../TopBar'
import {
  me,
  profileType,
  refreshToken,
  updateTerms,
} from '../../shared/services/api/me/me'
import WorkspaceContent from '../WorkspaceContent'
import Account from '../Account'
import AdminPanel from '../AdminPanel'
import CreateContent from '../CreateContent'
import { useIdleTimer } from 'react-idle-timer'
import IdleModal from './IdleModal'
import {
  contentConfiguration,
  lockContent,
  saveDraft,
} from '../../shared/services/api/createContent/createAlert'
import { typeNames } from '../../shared/helpers/typeNames'
import GlobalSearch from '../GlobalSearch/GlobalSearch'
import ContentManage from '../ContentManage/ContentManage'
import { setWorkspacesNumber } from '../../shared/helpers/workspacesNumber'
import GlobalToast from '../../shared/GlobalToast/GlobalToast'
import Support from '../Support'
import ApiDocumentation from '../ApiDocumentation'
// import Spinner from '../../shared/Spinner'
import { useThrottle } from '../../hooks/useThrottle'
import {
  saveProfile,
  saveAlert,
  saveReport,
  saveScenario,
} from '../CreateContent/shared/helpers/saveDraft'
import Tutorial from './Tutorial'
import ConfirmAutosave from './ConfirmAutosave'
import { useGlobalContext } from '../../context/globalContext'
import NotFoundPage from '../../shared/NotFoundPage'
import TosModal from './TosModal'
import * as workerTimers from 'worker-timers'
import { isMobile } from 'react-device-detect'
import KnowledgeBase from '../KnowledgeBase'
import KnowledgeBaseCategory from '../KnowledgeBase/KnowledgeBaseCategory'
import { KnowledgeBaseContextProvider } from '../../context/knowledgeBaseContext'
import KnowledgeBaseArticle from '../KnowledgeBase/KnowledgeBaseCategory/KnowledgeBaseSubcategory/KnowledgeBaseArticle'
import Modal from '../../shared/components/Modal'
import useNotification from '../../hooks/useNotification'
import { useCreateManagementContext } from '../../context/createManagementState'
import { useUserContext } from '../../context/userContext'
import { useViewStateContext } from '../../context/viewStateContext'
import Moderation from '../Moderation/Moderation'
import { DirtyCreateContextProvider } from '../../context/dirtyCreateContext'
import { AdminContextProvider } from '../../context/adminContext'
import Chat from '../Chat/Chat.tsx'
import Forum from '../Forum/Forum'

const REFRESH_INTERVAL = 9 * 60000
const TIMEOUT_MINUTES = 235 * 60000

const Home = () => {
  const location = useLocation()
  const history = useHistory()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const workspacesFromLS = JSON.parse(localStorage.getItem('workspaces'))
  const {
    isContentBeingEdited,
    toastMessage,
    setToastMessage,
    confirmAutosaveModal,
    setConfirmAutosaveModal,
    isModalOpen,
  } = useGlobalContext()
  const { user, setUser, isIdle, setIdle } = useUserContext()
  const {
    workspaces,
    setWorkspaces,
    addWorkspace,
    workspaceIdCount,
    setWorkspaceIdCount,
    linkAnalysisOpen,
  } = useViewStateContext()

  const { createContentState, setCreateContentState } =
    useCreateManagementContext()

  // const [isLoading, setLoading] = useState(false)
  // const [isIdle, setIdle] = useState(false)
  const [configuration, setConfiguration] = useState([])
  const [tutorialStep, setTutorialStep] = useState(null)
  const [isTosActive, setTosActive] = useState(false)
  const { warningToast } = useNotification()

  const getConfiguration = async (currentWorkspaces, isTutorialActive) => {
    isTutorialActive &&
      localStorage.getItem('tutorialActive') !== 'false' &&
      setTutorialStep(1)
    try {
      const response = await contentConfiguration(token())

      setConfiguration(response)
      // location.pathname === '/app/workspace' && currentWorkspaces[0].active
      //   ? null
      //   : setLoading(false)
    } catch (err) {}
  }

  const getUser = async (currentWorkspaces) => {
    try {
      const response = await me(token())
      const { is_admin } = response
      response.modules.create_mode =
        response?.modules?.create_mode?.filter(
          (menuItem) => menuItem.slug !== 'country',
        ) || []
      response.modules.view_mode =
        response?.modules?.view_mode?.filter(
          (menuItem) => menuItem.slug !== 'country',
        ) || []
      setUser(response)

      if (is_admin) {
        location.pathname === '/app/workspace' && history.push('/app/admin')
        setLoading(false)
      } else {
        if (location.pathname === '/app/documentation') {
          setLoading(false)
        } else if (!response.is_terms_of_use_accepted) {
          setTosActive(true)
        } else {
          await getConfiguration(
            currentWorkspaces,
            response.user_info.is_tutorial_active,
          )
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const acceptTerms = async () => {
    try {
      const response = await updateTerms(token())

      if (!response.error) {
        setTosActive(false)
        await getConfiguration(workspaces, user.user_info.is_tutorial_active)
      }
    } catch {}
  }

  const handleTokenRefresh = async () => {
    const token = localStorage.getItem('refresh_token')

    if (token !== null && token !== 'undefined') {
      try {
        const response = await refreshToken(token)
        const {
          access_token,
          refresh_token,
          expires_in,
          refresh_token_expire_in,
        } = response

        localStorage.setItem('access_token', access_token)
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem(
          'expires_in',
          Math.round(new Date() / 1000) + expires_in,
        )
        localStorage.setItem(
          'refresh_token_expire_in',
          Math.round(new Date() / 1000) + refresh_token_expire_in,
        )
      } catch {}
    }
  }

  useEffect(() => {
    const interval = workerTimers.setInterval(() => {
      isContentBeingEdited[0] === undefined && !isIdle && handleTokenRefresh()
    }, REFRESH_INTERVAL)
    return () => workerTimers.clearInterval(interval)
  }, [isContentBeingEdited])

  useEffect(() => {
    location.pathname !== '/app/settings/notifications' &&
      document.querySelector('#app').classList.remove('overlay')

    if (
      (createContentState.dev_framework_id || createContentState.dev_type_id) &&
      !isContentBeingEdited
    ) {
      setCreateContentState({})
    }
  }, [location.pathname])

  const handleOnIdle = async () => {
    isIdle === false && setIdle(true)
  }

  useIdleTimer({
    timeout: TIMEOUT_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  })

  useEffect(() => {
    ;(async () => {
      const isAuth = JSON.parse(localStorage.getItem('auth'))
      const query = new URLSearchParams(location.search)

      if (isAuth && query.get('id')) {
        const idFromParameters = query.get('id') * 1
        let typeFromParameters = query?.get('type')?.toLowerCase()

        if (
          workspaces !== null &&
          idFromParameters != null &&
          typeFromParameters !== null
        ) {
          if (typeFromParameters === 'profile') {
            try {
              const response = await profileType(token(), idFromParameters)

              if (response.error) {
                setToastMessage(['warning', response.message])
                setTimeout(() => setToastMessage(null), 5000)
                history.push('/app/workspace')
                return false
              }

              history.push('/app/workspace')
              addWorkspace(
                response.typeSlug,
                idFromParameters,
                response.typeName,
              )
            } catch (err) {}
          } else {
            history.push('/app/workspace')
            typeNames[typeFromParameters] &&
              addWorkspace(
                typeFromParameters,
                idFromParameters,
                typeNames[typeFromParameters]?.name,
              )
          }
        }

        workspaces !== null && setWorkspacesNumber(workspaces.length)
      }
    })()
  }, [workspaces])

  useEffect(() => {
    let currentWorkspaces = {}
    if (workspacesFromLS !== null) {
      currentWorkspaces = workspacesFromLS
      let idFromLS = workspacesFromLS.slice(-1)[0].id
      setWorkspaceIdCount(idFromLS)
    } else {
      currentWorkspaces = [
        {
          name: 'ThreatMatch Dashboard',
          id: workspaceIdCount,
          state: 'dashboard',
          active: true,
        },
      ]
    }

    setWorkspaces(currentWorkspaces)
    getUser(currentWorkspaces)
  }, [])

  const changeModule = (type, name) => {
    //add new workspace only if clicked from dashboard or other part of application
    const linkAnalysisAlreadyOpen = workspaces.find(
      (workspace) => workspace.state === 'analysis',
    )

    if (linkAnalysisAlreadyOpen && type === 'analysis') {
      return warningToast({
        message:
          'A link analysis workspace is currently open. Please close it before opening a new one',
      })
    }
    //
    if (workspaces[0].active || location.pathname !== '/app/workspace') {
      addWorkspace(type, undefined, name)
    } else {
      workspaces.forEach((item) => {
        if (item.active) {
          item.state = type
          item.name = name
          item.currentPage = 1
          item.currentPiece = null
          item.currentTab = item.state === 'analysis' ? 'view' : 'content'
          item.filters = {}
          item.isNew = !isMobile //for loading the first piece of content when opening a module
          delete item.list
        }
      })
      setWorkspaces(workspaces)
      localStorage.setItem('workspaces', JSON.stringify(workspaces))
    }
    history.push('/app/workspace')
  }

  useEffect(() => {
    //locking content even if outside create mode
    if (
      isContentBeingEdited &&
      isContentBeingEdited[1] &&
      typeof isContentBeingEdited[1] === 'number'
    ) {
      handleLockContent()
    }

    const interval = workerTimers.setInterval(() => {
      if (
        isContentBeingEdited &&
        isContentBeingEdited[1] &&
        typeof isContentBeingEdited[1] === 'number' &&
        !isIdle
      ) {
        handleLockContent()
      }
    }, 30000)

    return () => workerTimers.clearInterval(interval)
  }, [isContentBeingEdited])

  const handleLockContent = async () => {
    try {
      const contentId = isContentBeingEdited[1]
      const typeName = isContentBeingEdited[0]
      const type =
        typeName === 'alert' || typeName === 'scenario' || typeName === 'report'
          ? typeName + 's'
          : 'profiles'
      const payload = { contentId: contentId }
      await lockContent(token(), type, payload)
    } catch (err) {}
  }

  const autosave = () => {
    if (isContentBeingEdited && typeof isContentBeingEdited[1] === 'number') {
      setToastMessage(['info', 'Saving'])
      delayedSaveDraft()
    }
  }

  const handleSaveDraft = async () => {
    try {
      let data = {}
      if (isContentBeingEdited[0] === 'alert') {
        data = saveAlert(createContentState)
      } else if (isContentBeingEdited[0] === 'scenario') {
        data = saveScenario(createContentState)
      } else if (isContentBeingEdited[0] === 'report') {
        data = saveReport(createContentState)
      } else {
        data = saveProfile(createContentState)
      }

      const module =
        isContentBeingEdited[0] === 'alert' ||
        isContentBeingEdited[0] === 'report' ||
        isContentBeingEdited[0] === 'scenario'
          ? isContentBeingEdited[0] + 's'
          : 'profiles'
      const response = await saveDraft(token(), module, data.id, data)
      response.error
        ? setToastMessage(['error', 'Error'])
        : setToastMessage(['success', 'Saved'])
    } catch (err) {
      setToastMessage(['error', 'An error occurred'])
    }
  }

  const delayedAutosave = useThrottle(autosave, 500)
  const delayedSaveDraft = useThrottle(handleSaveDraft, 1500)

  return (
    <>
      {isModalOpen && linkAnalysisOpen && <Modal />}
      {/* 
      {isLoading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#1b222e',
          }}
        >
          <Spinner center size={80} border={1} />
        </div>
      )} */}

      {confirmAutosaveModal === true && isContentBeingEdited && (
        <ConfirmAutosave
          setConfirmAutosaveModal={setConfirmAutosaveModal}
          delayedAutosave={delayedAutosave}
        />
      )}

      {tutorialStep !== null && (
        <Tutorial
          tutorialStep={tutorialStep}
          setTutorialStep={setTutorialStep}
        />
      )}

      {isTosActive && (
        <TosModal isTosActive={isTosActive} acceptTerms={acceptTerms} />
      )}

      {toastMessage !== null && <GlobalToast autosave={autosave} time={6000} />}

      {user && (
        <div
          className={`home dark-theme${
            !user?.is_chat_enabled ? ' no-chat' : ''
          }`}
        >
          {isIdle && <IdleModal isIdle={isIdle} setIdle={setIdle} />}

          {location.pathname !== '/app/documentation' && <TopBar />}

          {!user.is_admin && location.pathname !== '/app/documentation' && (
            <LeftMenu changeModule={changeModule} />
          )}

          {user.is_chat_enabled &&
            !user.is_admin &&
            location.pathname !== '/app/documentation' && <Chat />}

          <Switch>
            <Route path='/app/settings' component={Account} />

            <Route path='/app/documentation' component={ApiDocumentation} />

            <Route path='/app/forum' component={Forum} />

            {user.is_admin && (
              <AdminContextProvider>
                <Route path='/app/admin'>
                  <AdminPanel />
                </Route>
              </AdminContextProvider>
            )}

            {!user.is_admin && (
              <Route exact path='/app/workspace'>
                <WorkspaceContent configuration={configuration} />
              </Route>
            )}

            <Route exact path='/app/support' component={Support} />

            <Route exact path='/app/moderation' component={Moderation} />

            {/*<Route exact path='/app/link-analysis' component={LinkAnalysis} />*/}

            <Route exact path='/app/search' component={GlobalSearch} />

            <Route
              exact
              path='/app/content-management'
              component={ContentManage}
            />

            <DirtyCreateContextProvider>
              <Route path='/app/content-create'>
                <CreateContent configuration={configuration} />
              </Route>
            </DirtyCreateContextProvider>

            <KnowledgeBaseContextProvider>
              <Route
                exact
                path='/app/knowledge-base'
                component={KnowledgeBase}
              />

              <Route
                exact
                path='/app/knowledge-base/:category'
                component={KnowledgeBaseCategory}
              />

              <Route
                exact
                path='/app/knowledge-base/:category/:article'
                component={KnowledgeBaseArticle}
              />

              {/* <Route
                exact
                path='/app/knowledge-base/:category/:subcategory/:article'
                component={KnowledgeBaseArticle}
              /> */}
            </KnowledgeBaseContextProvider>

            <Route component={NotFoundPage} />
          </Switch>
        </div>
      )}
    </>
  )
}

export default Home
