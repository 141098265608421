/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react'
import { DataGrid, GridOverlay } from '@mui/x-data-grid'
import {
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
} from '@material-ui/core'
import './Ioc.scss'
import { Delete, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import TextField from '@mui/material/TextField'
import { iocTypes } from './data'
import IocDialog from './IocDialog'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
  addIoc,
  deleteIoc,
  editIoc,
  uploadIoc,
  iocCollection,
} from '../../../../../shared/services/api/ioc/ioc'
import MuiAlert from '@material-ui/lab/Alert'
import ConfirmDeleteIoc from './ConfirmDeleteIoc'
import UploadImage from '../UploadImage'
import { downloadIoc } from '../../../../../shared/services/api/createContent/createAlert'
import { Pagination } from '@material-ui/lab'
import { Stack } from '@mui/material'
import { me } from '../../../../../shared/services/api/me/me'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const Ioc = ({ data, id, type }) => {
  const { handleCreateState } = useCreateManagementContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [toastMessage, setToastMessage] = useState(undefined)
  const [hoveredRow, setHoveredRow] = useState(null)
  const columns = [
    {
      field: 'value',
      headerName: 'Data',
      editable: false,
      sortable: false,
      renderHeader: () => {
        return (
          <div
            className='sortable-header'
            onClick={() => saveSortBeforeFetch('value')}
            onKeyDown={() => saveSortBeforeFetch('value')}
          >
            <span>Data</span>

            {data.sortBy === 'value' && data.sortOrder === 'asc' && (
              <KeyboardArrowDown />
            )}

            {data.sortBy === 'value' && data.sortOrder === 'desc' && (
              <KeyboardArrowUp />
            )}
          </div>
        )
      },
      flex: 0.8,
      renderCell: (params) => (
        <div>
          <span>{params.value}</span>
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 110,
      field: 'type',
      headerName: 'Type',
      renderHeader: () => {
        return (
          <div
            className='sortable-header'
            onClick={() => saveSortBeforeFetch('type')}
            onKeyDown={() => saveSortBeforeFetch('type')}
          >
            <span>Type</span>

            {data.sortBy === 'type' && data.sortOrder === 'asc' && (
              <KeyboardArrowDown />
            )}

            {data.sortBy === 'type' && data.sortOrder === 'desc' && (
              <KeyboardArrowUp />
            )}
          </div>
        )
      },
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div style={{ marginTop: 0 }}>
          <Select
            value={params.value}
            variant='outlined'
            displayEmpty
            className='type-select'
            renderValue={
              params.value === ''
                ? () => <span style={{ color: '#aaa' }}>Select reason</span>
                : undefined
            }
            onChange={(event) => {
              event.stopPropagation()
              handleChange(params.id, 'type', event.target.value)
            }}
            onClose={() => {
              setTimeout(() => {
                document.activeElement.blur()
              }, 0)
            }}
          >
            {iocTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </div>
      ),
    },
    {
      field: 'date',
      flex: 0.22,
      headerName: 'Date',
      renderHeader: () => {
        return (
          <div
            className='sortable-header'
            onClick={() => saveSortBeforeFetch('date')}
            onKeyDown={() => saveSortBeforeFetch('date')}
          >
            <span>Date</span>

            {data.sortBy === 'date' && data.sortOrder === 'asc' && (
              <KeyboardArrowDown />
            )}

            {data.sortBy === 'date' && data.sortOrder === 'desc' && (
              <KeyboardArrowUp />
            )}
          </div>
        )
      },
      minwidth: 80,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div style={{ marginTop: 0 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disableToolbar
              autoOk
              fullWidth
              variant='inline'
              inputVariant='outlined'
              format='dd/MM/yyyy'
              className='ioc-date'
              value={params.value}
              onChange={(value) => handleChange(params.id, 'date', value)}
            />
          </MuiPickersUtilsProvider>
        </div>
      ),
    },
    {
      flex: 0.5,
      field: 'comment',
      headerName: 'Comments',
      minwidth: 200,
      editable: true,
      fontSize: 11,
      sortable: false,
      renderCell: (params) => (
        <div
          className='comments-wrapper'
          onMouseEnter={() => setHoveredRow(params.id)}
          onMouseLeave={() => setHoveredRow(null)}
        >
          <span>{params.value}</span>

          {hoveredRow === params.id && (
            <Tooltip
              arrow
              title='Double click anywhere on the cell to start editing'
              placement='top'
            >
              <span
                style={{
                  fontSize: 18,
                  marginLeft: 10,
                  marginBottom: 2,
                  cursor: 'text',
                }}
                className='icon-edit'
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      flex: 0.05,
      field: 'options',
      headerName: 'Options',
      minwidth: 80,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => setConfirmDeleteOpen(params.id)}
          size='small'
        >
          <Delete />
        </IconButton>
      ),
    },
  ]

  const [rows, setRows] = useState(data.data ? data.data : [])
  const [addIocDialog, setIocDialog] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(undefined)
  const [selectionModel, setSelectionModel] = useState([])
  const [isAdding, setAdding] = useState(false)
  const searchRef = useRef(null)
  const [isFetching, setFetching] = useState(false)

  const saveSortBeforeFetch = (sortBy) => {
    let currentState = { ...data }
    const payload = {}
    if (sortBy) {
      if (!data.sortBy || sortBy !== data.sortBy) {
        payload.sortOrder = 'asc'
        payload.sortBy = sortBy
      } else if (data.sortOrder === 'asc') {
        payload.sortOrder = 'desc'
        payload.sortBy = sortBy
      } else if (data.sortOrder === 'desc') {
        delete payload.sortOrder
        delete payload.sortBy
      }
    }

    if (payload.sortBy) {
      currentState.sortBy = payload.sortBy
    }
    if (payload.sortOrder) {
      currentState.sortOrder = payload.sortOrder
    }

    if (data.search) {
      payload.keyword = data.search
    }

    handleCreateState('ioc', currentState, true)

    setTimeout(() => {
      requestSearch('sort', payload)
    })
  }

  const requestSearch = async (pageValue, sortPayload) => {
    setFetching(true)
    setRows([])
    try {
      let payload = {}
      if (sortPayload) {
        payload = sortPayload
      }

      if (pageValue && pageValue !== 'search' && pageValue !== 'sort') {
        payload.page = pageValue
      }

      if (data.search && searchRef.current.value.length > 0) {
        payload.keyword = searchRef.current.value
      }

      if (searchRef.current.value.length === 0) {
        delete payload.keyword
      }

      if (data.sortBy && pageValue !== 'sort') {
        payload.sortBy = data.sortBy
      }

      if (data.sortOrder && pageValue !== 'sort') {
        payload.sortOrder = data.sortOrder
      }

      const query = new URLSearchParams(payload).toString()
      const response = await iocCollection(token(), type, id, query)
      let currentState = ''
      currentState = response
      if (payload.keyword) {
        currentState.search = payload.keyword
      } else {
        searchRef.current.value = ''
      }
      if (payload.sortBy) {
        currentState.sortBy = payload.sortBy
      }
      if (payload.sortOrder) {
        currentState.sortOrder = payload.sortOrder
      }
      if (payload.page) {
        currentState.page = payload.page
      }
      handleCreateState('ioc', currentState, true)
      setRows(response.data)
      setFetching(false)
    } catch (err) {}
  }

  const handleChange = (id, prop, value) => {
    const currentState = { ...data }
    const currentRow = currentState.data.filter((row) => row.id == id)[0]
    currentRow[prop] = value
    editIocRecord(currentRow)
    setRows(currentState.data)
    handleCreateState('ioc', currentState, true)
  }

  const editIocRecord = async (editData) => {
    try {
      const response = await editIoc(token(), editData.id, editData)
      setToastMessage([response.error ? 'error' : 'success', response.message])
    } catch (err) {}
  }

  const addIocRecord = async (addData) => {
    try {
      const response = await addIoc(token(), addData)
      setToastMessage([response.error ? 'error' : 'success', response.message])
      setIocDialog(undefined)
      setRows(response.iocs.data)
      handleCreateState('ioc', response.iocs, true)
    } catch (err) {}
  }

  const handleRowEditStop = (params, event) => {
    if (event.key === 'Enter' && event.target.value.length > 0) {
      const currentState = { ...data }
      const currentRow = currentState.data.filter(
        (row) => row.id === params.row.id,
      )[0]
      currentRow.comment = event.target.value
      editIocRecord(currentRow)
      handleCreateState('ioc', currentState, true)
    }
  }

  const handleDeleteIoc = async () => {
    try {
      const response = await deleteIoc(token(), confirmDeleteOpen)
      const currentState = { ...data }
      const newRows = rows.filter((row) => row.id !== confirmDeleteOpen)
      currentState.data = newRows
      setRows(newRows)
      handleCreateState('ioc', currentState, true)
      setToastMessage([response.error ? 'error' : 'success', response.message])
      setConfirmDeleteOpen(undefined)

      if (data.page && data.page > 1 && newRows.length === 0) {
        requestSearch(1)
      }
    } catch (err) {}
  }

  const handleUploadIoc = async (imageData) => {
    const formData = new FormData()

    formData.append('file', imageData)
    formData.append('content_id', id)
    formData.append('module', type)

    try {
      setAdding(true)
      const response = await uploadIoc(token(), formData)

      if (response.error) {
        setToastMessage(['error', response.message])
        setAdding(false)
        return false
      }

      setToastMessage(['success', response.message])
      setIocDialog(undefined)
      setRows(response.iocs.data)
      handleCreateState('ioc', response.iocs, true)
      setAdding(false)
    } catch (err) {}
  }

  const del = async (id) => {
    try {
      await deleteIoc(token(), id)
    } catch (err) {}
  }

  const handleDeleteMultipleIoc = async () => {
    await me(token())
    const currentState = { ...data }
    const modelIds = [...selectionModel]
    await Promise.all(selectionModel.map(del))
    const newRows = rows.filter((row) => !modelIds.includes(row.id))
    currentState.data = newRows
    setRows(newRows)
    handleCreateState('ioc', currentState, true)
    setConfirmDeleteOpen(undefined)
    setToastMessage(['success', 'Selected IOC records have been deleted'])
    if (data.page && data.page > 1 && newRows.length === 0) {
      requestSearch(1)
    }
  }

  const handleDownloadIocTemplate = (event) => {
    event.preventDefault()
    downloadIocTemplate()
  }

  const downloadIocTemplate = async () => {
    try {
      const response = await downloadIoc(token())
      const url = `${response}?access_token=${token()}`
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } catch (error) {}
  }

  return (
    <div className='ioc'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div>
          <UploadImage uploadIoc={handleUploadIoc} ioc isAdding={isAdding} />

          <Button
            onClick={() => setConfirmDeleteOpen(true)}
            disabled={selectionModel.length === 0}
            style={{
              textTransform: 'capitalize',
              backgroundColor: '#E0E0E0',
              color: '#000',
              borderRadius: 12,
              fontSize: 12,
              padding: '6px 14px',
              fontWeight: 700,
              margin: '0 20px',
            }}
          >
            Remove
          </Button>

          <Button
            onClick={(event) => handleDownloadIocTemplate(event)}
            style={{
              textTransform: 'capitalize',
              backgroundColor: 'transparent',
              color: '#000',
              borderRadius: 12,
              fontSize: 12,
              padding: '6px 14px',
              fontWeight: 700,
            }}
          >
            Download IOC template (.csv)
          </Button>
        </div>

        <div>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              requestSearch('search', { keyword: searchRef.current.value })
            }}
          >
            <TextField
              variant='outlined'
              inputRef={searchRef}
              defaultValue={data.search ? data.search : ''}
              className='search'
              placeholder='Search'
              InputProps={{
                endAdornment: isFetching && <CircularProgress />,
              }}
            />
          </form>
        </div>
      </div>

      {confirmDeleteOpen && (
        <ConfirmDeleteIoc
          confirmDeleteOpen={confirmDeleteOpen}
          setConfirmDeleteOpen={setConfirmDeleteOpen}
          handleDeleteIoc={handleDeleteIoc}
          handleDeleteMultipleIoc={handleDeleteMultipleIoc}
        />
      )}

      {addIocDialog && (
        <IocDialog
          addIocDialog={addIocDialog}
          setIocDialog={setIocDialog}
          addIocRecord={addIocRecord}
          id={id}
          type={type}
        />
      )}

      {toastMessage && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={toastMessage[0] === 'error' ? 7000 : 3500}
          onClose={() => setToastMessage(false)}
          open={toastMessage !== undefined}
        >
          <MuiAlert variant='filled' severity={toastMessage[0]}>
            {toastMessage[1]}
          </MuiAlert>
        </Snackbar>
      )}

      <DataGrid
        rows={rows}
        columns={columns}
        style={{ width: '100%' }}
        pageSize={20}
        autoPageSize
        disableColumnMenu
        autoHeight
        onSelectionModelChange={(newSelection) =>
          setSelectionModel(newSelection)
        }
        editMode='row'
        onRowEditStop={handleRowEditStop}
        onRowEditCommit={(params, event) => {
          if (event.pointerType === 'mouse') {
            event.defaultMuiPrevented = true
          }
        }}
        disableColumnFilter
        checkboxSelection
        disableSelectionOnClick
        loading={isFetching}
        components={{
          Footer: () => (
            <Pagination
              style={{ display: 'flex', padding: 20 }}
              size='small'
              onChange={(event, value) => requestSearch(value)}
              page={data.page || 1}
              color='primary'
              disabled={isFetching}
              count={data.last_page}
              shape='rounded'
              hideNextButton
              hidePrevButton
            />
          ),
          LoadingOverlay: () => (
            <GridOverlay>
              <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
              </div>
            </GridOverlay>
          ),
          NoRowsOverlay: () => (
            <Stack
              style={{
                position: 'absolute',
                left: '50%',
                top: '80%',
                transform: 'translate(-50%, -50%)',
              }}
              alignItems='center'
              justifyContent='center'
            >
              {isFetching ? '' : 'No data found'}
            </Stack>
          ),
        }}
      />

      <Button
        onClick={() => setIocDialog(true)}
        style={{
          textTransform: 'capitalize',
          backgroundColor: '#006fff',
          color: '#fff',
          borderRadius: 12,
          fontSize: 12,
          padding: '6px 14px',
          fontWeight: 700,
          marginTop: 20,
        }}
      >
        Add IOC Record
      </Button>
    </div>
  )
}

export default Ioc
