/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react'
import { formatDate } from '../../../shared/helpers/formatDate'
import OptionsMenu from './OptionsMenu'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'

const ModerationSub = ({
  hoveredId,
  setHoveredId,
  setMenuHoverAnchor,
  menuHoverAnchor,
  sub,
  handleChangeStatus,
  commentModal,
}) => {
  return (
    <div
      onMouseOver={() => setHoveredId(sub.id)}
      onMouseLeave={() => setHoveredId(null)}
      className='moderation-modal__comment sub'
      style={{ borderLeft: sub.is_reported ? '5px solid #ff0033' : null }}
      key={sub.id}
    >
      <SubdirectoryArrowRightIcon
        style={{ fontSize: 18, width: 28, marginRight: 10 }}
      />

      <div className='moderation-modal__comment-content'>
        <div
          style={{
            fontSize: '14px',
            color: sub.is_reported || sub.is_deleted ? '#7f7f7f' : '#283143',
            fontStyle: sub.is_reported || sub.is_deleted ? 'italic' : 'unset',
          }}
          dangerouslySetInnerHTML={{ __html: sub.content }}
        />

        <div style={{ fontSize: '11px', color: '#7f7f7f' }}>
          {`${sub.author_name} - ${formatDate(sub.created_at)}`}
        </div>
      </div>

      {sub.id === hoveredId && sub.is_reported && (
        <OptionsMenu
          setMenuHoverAnchor={setMenuHoverAnchor}
          menuHoverAnchor={menuHoverAnchor}
          comment={sub}
          handleChangeStatus={handleChangeStatus}
          id={commentModal}
        />
      )}
    </div>
  )
}

export default ModerationSub
