import { getForumCategories } from './../../api/forum/categories'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const token = () => {
  return localStorage.getItem('access_token')
}

export const useQueryGetCategories = (forumId: number) => {
  return useQuery({
    queryKey: ['category', forumId],
    queryFn: () => getForumCategories(token(), forumId),
  })
}

export const useQueryCacheGetCategories = (id: number) => {
  const queryClient = useQueryClient()
  const state = queryClient.getQueryState(['category', id])

  return state?.data ?? null
}
