import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Divider } from '@material-ui/core'

import './KnowledgeBase.scss'
import AutocompleteSearch from './AutocompleteSearch'
import { useKnowledgeBaseContext } from '../../context/knowledgeBaseContext'
import Button from '../../shared/components/Button'
import Spinner from '../../shared/Spinner'

const KnowledgeBase = () => {
  const { categories } = useKnowledgeBaseContext()
  const history = useHistory()

  return (
    <div className='knowledge-base'>
      <div className='knowledge-base__inner'>
        <Button
          className='submit-ticket-btn'
          type='button'
          onClick={() =>
            history.push({
              pathname: '/app/support',
              state: { redirect_url: '/app/knowledge-base' },
            })
          }
          style={{
            textTransform: 'none',
            position: 'absolute',
            top: '90px',
            right: '30px',
          }}
        >
          Submit a ticket
        </Button>

        <div className='knowledge-base__inner-top'>
          <h2>Need some help?</h2>

          <AutocompleteSearch isOutlined={false} />
        </div>

        <Divider
          style={{
            width: '100%',
          }}
        />

        <div className='knowledge-base__inner-bottom'>
          <h4>Browse by category</h4>

          <div className='knowledge-base__categories'>
            {categories.length === 0 ? (
              <Spinner centerHorizontally size={50} />
            ) : (
              categories?.map(({ title, slug, icon }) => {
                return (
                  <NavLink
                    className='knowledge-base__category-link'
                    key={slug}
                    to={`knowledge-base/${slug}`}
                  >
                    <span
                      className={`icon-${icon}`}
                      style={{ fontSize: '32px', color: '#006fff' }}
                    />

                    {title}
                  </NavLink>
                )
              })
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default KnowledgeBase
