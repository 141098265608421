/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Add, Delete } from '@material-ui/icons'
import { IconButton } from '@mui/material'
import PropTypes from 'prop-types'

import Spinner from '../../../shared/Spinner'

const LeftBar = ({
  categories,
  isLoading,
  setIsCreateModalOpen,
  setIsDeleteModalOpen,
}) => {
  const location = useLocation()

  return (
    <div className='knowledge-base-admin__content-left'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h5>Categories</h5>

        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            cursor: 'pointer',
          }}
          onClick={() => setIsCreateModalOpen(['category', null])}
        >
          <span style={{ fontSize: 12, textDecoration: 'underline' }}>
            Add new category
          </span>

          <Add style={{ fontSize: 12 }} />
        </div>
      </div>

      <div className='knowledge-base-admin__categories'>
        {isLoading ? (
          <Spinner center size={30} />
        ) : (
          categories?.map((category) => (
            <div key={category?.slug}>
              <NavLink
                isActive={() =>
                  location.pathname ===
                  `/app/admin/knowledge-base/${category?.slug}`
                }
                to={`/app/admin/knowledge-base/${category?.slug}`}
                activeClassName='category-active'
              >
                <div
                  style={{ width: 'calc(100% - 12px)' }}
                  className='category-wrapper'
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 'calc(100% - 71px)',
                    }}
                  >
                    {/* {category.slug !== 'all' && (
                        <IconButton
                          onClick={(event) =>
                            handleExpandCategories(event, category.id)}
                          style={{ marginRight: 6, padding: 6 }}
                        >
                          <span
                            style={{
                              fontSize: 18,
                              color: '#fff',
                              transform: category.isExpanded
                                ? null
                                : 'rotate(270deg)',
                            }}
                            className='icon-down'
                          />
                        </IconButton>
                      )} */}

                    <span
                      style={{
                        position: category.slug !== 'all' ? 'absolute' : null,
                        left: 15,
                        width:
                          category.slug === 'all'
                            ? '100%'
                            : 'calc(100% - 126px)',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: 18,
                        padding: category.slug === 'all' ? '3.75px 10px' : 0,
                      }}
                    >
                      {category.title}
                    </span>
                  </div>

                  {category.slug !== 'all' && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <Button
                          onClick={() =>
                            setIsCreateModalOpen(['subcategory', category.id])}
                          className='knowledge-base-admin__categories-btn-add'
                        >
                          Add
                        </Button> */}

                      <IconButton
                        onClick={() =>
                          setIsDeleteModalOpen(['category', category.id])
                        }
                        style={{ padding: 6, marginLeft: 5 }}
                      >
                        <Delete style={{ color: '#9498a1', fontSize: 18 }} />
                      </IconButton>
                    </div>
                  )}
                </div>
              </NavLink>

              {/* {category.isExpanded &&
                  category.child_categories.map((subcategory) => (
                    <NavLink
                      key={subcategory.id}
                      isActive={() =>
                        location.pathname ===
                        `/app/admin/knowledge-base/${category.slug}/${subcategory.slug}`}
                      to={`/app/admin/knowledge-base/${category.slug}/${subcategory.slug}`}
                      activeClassName='category-active'
                    >
                      <div
                        style={{ marginLeft: 30 }}
                        className='category-wrapper'
                      >
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <span style={{ fontSize: 18, paddingLeft: 8 }}>
                            {subcategory.title}
                          </span>
                        </div>

                        <IconButton
                          onClick={() =>
                            setIsDeleteModalOpen([
                              'subcategory',
                              subcategory.id,
                              subcategory.parent_id,
                            ])}
                          style={{ padding: 6, marginLeft: 10 }}
                        >
                          <Delete
                            style={{ color: '#9498a1', fontSize: 18 }}
                          />
                        </IconButton>
                      </div>
                    </NavLink>
                  ))} */}
            </div>
          ))
        )}
      </div>
    </div>
  )
}

LeftBar.propTypes = {
  categories: PropTypes.instanceOf(Array).isRequired,
  handleExpandCategories: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsCreateModalOpen: PropTypes.func.isRequired,
  setIsDeleteModalOpen: PropTypes.func.isRequired,
}

export default LeftBar
