/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import './KnowledgeBaseView.scss'
import { useKnowledgeBaseContext } from '../../../context/knowledgeBaseContext'

const KnowledgeBaseView = () => {
  const {
    activeLink,
    categories,
    categoriesShown,
    expandedCategories,
    setCategoriesShown,
    setExpandedCategories,
  } = useKnowledgeBaseContext()
  const [expandedSubcategories, setExpandedSubcategories] = useState([])
  const subcategorySlug = activeLink.split('/')[1]
  const isArticle = categories
    .find((category) => category.slug === activeLink.split('/')[0])
    ?.articles.find((articleItem) => articleItem.slug === subcategorySlug)

  const handleDoubleClick = (event, slug, isSubcategorySlug) =>
    event.detail === 2
      ? isSubcategorySlug
        ? handleExpandedSubcategories(slug)
        : handleExpandedCategories(slug)
      : null

  const handleExpandedCategories = (slug) => {
    let currentState = [...expandedCategories]

    if (currentState.includes(slug)) {
      currentState = currentState.filter((category) => category !== slug)
    } else {
      currentState = [...currentState, slug]
    }

    setExpandedCategories(currentState)
  }

  const handleExpandedSubcategories = (slug) => {
    let currentState = [...expandedSubcategories]

    if (currentState.includes(slug)) {
      currentState = currentState.filter((category) => category !== slug)
    } else {
      currentState = [...currentState, slug]
    }

    setExpandedSubcategories(currentState)
  }

  useEffect(() => {
    if (activeLink.split('/').length === 3 || isArticle?.title) {
      setCategoriesShown(false)
    } else {
      setCategoriesShown(true)
    }
  }, [activeLink])

  return (
    <div
      className='knowledge-base-sidebar'
      style={{ flexBasis: categoriesShown ? '20%' : '2%' }}
    >
      <div
        className='knowledge-base-sidebar__header'
        style={{
          transform: categoriesShown ? '' : 'rotate(270deg)',
          justifyContent: categoriesShown ? 'space-around' : 'center',
          height: categoriesShown ? '' : '100%',
          width: categoriesShown ? '' : '60px',
        }}
      >
        <h4 className='knowledge-base-sidebar__title'>Knowledge Base</h4>

        <span
          className='icon-expand-content'
          style={{
            fontSize: '25px',
            transform: categoriesShown ? 'rotate(-180deg)' : 'rotate(90deg)',
            display: 'block',
            cursor: 'pointer',
          }}
          onClick={() => setCategoriesShown((prevState) => !prevState)}
        />
      </div>

      {categoriesShown && (
        <div className='knowledge-base-sidebar__content'>
          {categories?.map(({ title, slug, articles }) => {
            return (
              <Fragment key={slug}>
                <NavLink
                  className='knowledge-base-sidebar__link'
                  to={`/app/knowledge-base/${slug}`}
                >
                  <span
                    className='icon-collapse'
                    style={{
                      color: expandedCategories?.includes(slug)
                        ? '#006FFF'
                        : '#ccc',
                      transform: `rotate(${
                        expandedCategories.includes(slug) ? '90deg' : '0deg'
                      })`,
                    }}
                    onClick={(event) => {
                      event.preventDefault()
                      handleExpandedCategories(slug)
                    }}
                  />

                  <h5
                    className='knowledge-base-sidebar__link-name'
                    onClick={(event) => handleDoubleClick(event, slug)}
                    style={{
                      fontWeight: `${
                        activeLink.split('/')[0] === slug ? 'bold' : 'normal'
                      }`,
                    }}
                  >
                    {title}
                  </h5>
                </NavLink>

                {expandedCategories.includes(slug) &&
                  articles.map(({ slug: articleSlug, title }) => {
                    return (
                      <div
                        key={articleSlug}
                        className='knowledge-base-sidebar__subcategories-link'
                      >
                        {/* <span
                            className='icon-collapse'
                            style={{
                              display: 'inline-block',
                              color: expandedSubcategories?.includes(
                                articleSlug
                              )
                                ? '#006FFF'
                                : '#ccc',
                                transform: `rotate(${
                                  expandedSubcategories?.includes(
                                    articleSlug
                                  ) ? '90deg' : '0deg'
                                })`,
                            }}
                            onClick={(event) => {
                              event.preventDefault()
                              handleExpandedSubcategories(articleSlug)
                            }}
                          /> */}

                        <NavLink
                          style={{
                            fontWeight: `${
                              articleSlug === activeLink.split('/')[1]
                                ? 'bold'
                                : 'normal'
                            }`,
                            color: '#212935',
                          }}
                          onClick={(event) => {
                            handleDoubleClick(event, articleSlug, true)
                          }}
                          to={`/app/knowledge-base/${slug}/${articleSlug}`}
                        >
                          {title}
                        </NavLink>

                        {/* {expandedSubcategories.includes(subcategorySlug) &&
                            articles.map((article) => (
                              <Fragment key={article.id}>
                                <NavLink
                                  className='knowledge-base-sidebar__link'
                                  to={`/app/knowledge-base/${slug}/${subcategorySlug}/${article.slug}`}
                                  style={{ marginLeft: '15px' }}
                                >
                                    {article.title.length > 35 ? (<Tooltip title={article.title} placement='top'>
                                      <h5
                                        className='knowledge-base-sidebar__link-name'
                                        style={{
                                          fontWeight: `${
                                            activeLink.split('/')[2] ===
                                            article.slug
                                              ? 'bold'
                                              : 'normal'
                                          }`,
                                        }}
                                      >
                                        {article.title.slice(0,35)} ...
                                      </h5>
                                    </Tooltip>) : (
                                      <h5
                                      className='knowledge-base-sidebar__link-name'
                                      style={{
                                        fontWeight: `${
                                          activeLink.split('/')[2] ===
                                          article.slug
                                            ? 'bold'
                                            : 'normal'
                                        }`,
                                      }}
                                    >
                                      {article.title}
                                    </h5>
                                    )}
                                </NavLink>
                              </Fragment>
                            ))} */}
                      </div>
                    )
                  })}
              </Fragment>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default KnowledgeBaseView
