import React from 'react'

import './KnowledgeBaseList.scss'
import KnowledgeBaseListButton from './KnowledgeBaseListButton'
import { useKnowledgeBaseContext } from '../../../context/knowledgeBaseContext'
import Spinner from '../../../shared/Spinner'

const KnowledgeBaseList = () => {
  const { activeLink, categories } = useKnowledgeBaseContext()

  const activeArticles = categories.find(
    (category) => category.slug === activeLink,
  )
  // let result = []

  // console.log(result)

  // const data = categories.find((category) => {
  //   if (category.slug === activeLink) {
  //     result = category
  //   }

  // else if (activeLink.startsWith(category.slug)) {
  //   const articles = category.child_categories.find((subcategory) => {
  //     if (subcategory.slug === activeLink.split('/')[1]) {
  //       result = {
  //         title: subcategory.title,
  //         articles: subcategory.articles,
  //       }
  //     }
  //   })

  //   return articles
  // }
  // })

  if (categories.length === 0) {
    return <Spinner center size={50} />
  }

  // if (
  //   result?.child_categories?.length > 0 ||
  //   (result?.child_categories?.length >= 0 && result?.articles?.length > 0)
  // ) {
  /* displays individual articles and subcategories with articles */

  return (
    <div className='knowledge-base-list'>
      <div className='knowledge-base-list__subcategory'>
        {activeArticles?.articles?.map((article) => (
          <KnowledgeBaseListButton article={article} key={article.slug} />
        ))}
      </div>

      {/* <div className='knowledge-base-list__articles_with_subcategories'>
          {result?.child_categories?.map(({ title, slug, articles }) => {
            return (
              <div key={slug}>
                <div className='knowledge-base-list__subcategory'>
                  <h3 className='knowledge-base-list__subcategory-title'>
                    {title}
                  </h3>

                  {articles.map((article) => (
                    <KnowledgeBaseListButton
                      article={article}
                      subcategorySlug={slug}
                      key={article.slug}
                    />
                  ))}
                </div>
              </div>
            )
          })}
        </div> */}
    </div>
  )
}

// if (result?.articles?.length > 0) {
//   return (
//     <div className='knowledge-base-list'>
//       <div className='knowledge-base-list__subcategory'>
//         <h3 className='knowledge-base-list__subcategory-title'>
//           {result.title}
//         </h3>

//         {result?.articles?.map((article) => (
//           <KnowledgeBaseListButton article={article} key={article.slug} />
//         ))}
//       </div>
//     </div>
//   )
// }

//   return (
//     <div style={{ fontSize: '16px', color: '#212935', paddingTop: '30px', paddingLeft: '40px', fontWeight: 700 }}>
//       No added articles yet
//     </div>)
// }

export default KnowledgeBaseList
