/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, Button, Divider, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import './ModuleListing.scss'
import Flag from '@material-ui/icons/Flag'
import { formatDate } from '../../shared/helpers/formatDate'
import { workspacesNumber } from '../../shared/helpers/workspacesNumber'
import MuiAlert from '@material-ui/lab/Alert'
import { Snackbar } from '@mui/material'
import { OpenInNew } from '@material-ui/icons'
import { useViewStateContext } from '../../context/viewStateContext'

const ModuleListing = ({
  items,
  activeItemRef,
  handleSetPiece,
  associated,
  isSidebar,
  type,
  filters,
}) => {
  const {
    workspaces,
    addWorkspace,
    setWorkspaces,
    activePiece,
    setActivePiece,
    isViewFetching,
  } = useViewStateContext()
  const [toastMessage, setToastMessage] = useState(undefined)
  const [hoveredItem, setHoveredItem] = useState(null)

  const handleClick = (item, disableOpenInNew) => {
    const workpaceWithChosenContent = workspaces?.find(
      (space) => space.currentPiece === item.id,
    )

    if (isSidebar) {
      return false
    }

    if (workpaceWithChosenContent) {
      const currentState = [...workspaces]

      currentState.forEach((w) => (w.active = false))
      currentState.find(
        (w) => w.id === workpaceWithChosenContent.id,
      ).active = true
      setWorkspaces(currentState)
    } else {
      const { typeSlug, id, title } = item
      if (typeSlug !== 'country') {
        associated
          ? handleOpenAssociatedContent(typeSlug, id, title, disableOpenInNew)
          : handleSetPiece(item)
      }
    }
  }

  useEffect(() => {
    return () => {}
  }, [])

  const handleOpenAssociatedContent = (
    contentType,
    id,
    title,
    disableOpenInNew,
  ) => {
    if (workspacesNumber > 15) {
      setToastMessage('You cannot open more workspaces')
    } else {
      !disableOpenInNew && setActivePiece(null)
      const typeName =
        contentType === 'threat-actor' ||
        contentType === 'incident' ||
        contentType === 'country' ||
        contentType === 'operation' ||
        contentType === 'malware-tools'
          ? contentType
          : 'alert'
      !isSidebar &&
        addWorkspace(typeName, id, title, undefined, disableOpenInNew)
    }
  }

  const returnIcon = (slug) => {
    if (associated === 'alerts') {
      return 'icon-alert'
    } else if (associated === 'profiles') {
      return 'icon-' + slug
    } else if (type === 'alert' && !associated) {
      return ['center', 'icon-alert']
    } else if (type === 'scenario') {
      return ['center', 'icon-scenario']
    } else if (type === 'report') {
      return 'icon-report'
    } else if (type === 'profile') {
      return 'icon-' + slug
    }
  }

  return items?.map((item) => {
    const {
      priority,
      priorityName,
      capabilityName,
      title,
      author,
      author_name,
      capability,
      published_at,
      published_updated_at,
      typeSlug,
      dev_title,
      is_flagged,
    } = item

    return (
      <div
        style={{ cursor: isViewFetching && 'wait' }}
        ref={activePiece === item.id ? activeItemRef : undefined}
        onMouseEnter={() => !isSidebar && setHoveredItem(item.id)}
        onMouseLeave={() => setHoveredItem(null)}
        onClick={() =>
          !isViewFetching && activePiece !== item.id && handleClick(item)
        }
        className={`${isSidebar ? 'preview ' : ''}button list-item${
          !item.read ? ' unread' : ''
        }${activePiece === item.id ? ' active' : ''}`}
        key={item.id}
      >
        <div className='list-item__left'>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={3500}
            onClose={() => setToastMessage(undefined)}
            open={toastMessage !== undefined}
          >
            <MuiAlert variant='filled' severity='warning'>
              <span>{toastMessage}</span>
            </MuiAlert>
          </Snackbar>

          <div className='priority'>
            <Avatar>
              <span
                style={{
                  color: '#666666',
                  fontSize: 28,
                  paddingBottom:
                    type === 'alert' && returnIcon(typeSlug)[0] === 'center'
                      ? 2
                      : 0,
                  paddingTop:
                    type === 'scenario' && returnIcon(typeSlug)[0] === 'center'
                      ? 3
                      : 0,
                }}
                className={
                  returnIcon(typeSlug)[0] === 'center'
                    ? returnIcon(typeSlug)[1]
                    : returnIcon(typeSlug)
                }
              />
            </Avatar>
          </div>

          {priority > 0 && ( //alerts
            <div className='priority'>
              <Tooltip
                arrow
                placement='top'
                title={priorityName || capabilityName || ''}
              >
                <Avatar className={`level-${priority}`}>{priority}</Avatar>
              </Tooltip>
            </div>
          )}

          {capability > 0 &&
            capabilityName && ( //profiles
              <div className='priority'>
                <Tooltip
                  placement='top'
                  arrow
                  title={priorityName || capabilityName || ''}
                >
                  <Avatar className={`level-${capability}`}>
                    {capability}
                  </Avatar>
                </Tooltip>
              </div>
            )}
        </div>

        <div className='list-item__right'>
          <div className='title-container'>
            <span style={{ width: '100%' }} className='title'>
              {dev_title || title}
            </span>

            {hoveredItem === item.id &&
              (item.id !== activePiece || is_flagged) && (
                <div
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    right: '-6px',
                    top: '-6px',
                    padding: '4px 5px',
                    backgroundColor: '#fff',
                    boxShadow: 'rgb(0 0 0 / 15%) 0px 1px 2px 0px',
                    border: '1px solid #ccc',
                    borderRadius: 8,
                    gap: 6,
                  }}
                >
                  {is_flagged && (
                    <Flag style={{ color: '#3f51b5', fontSize: 22 }} />
                  )}

                  {item.id !== activePiece && (
                    <Tooltip
                      arrow
                      placement='top'
                      title='Open in new workspace'
                    >
                      <Button
                        className='open-new-tab'
                        style={{
                          display: hoveredItem === item.id ? 'flex' : 'none',
                        }}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          if (associated) {
                            handleClick(item, true)
                          } else {
                            addWorkspace(
                              type === 'profile' ? item.typeSlug : type,
                              item.id,
                              item.title,
                              filters,
                              true,
                            )
                          }
                        }}
                        startIcon={<OpenInNew />}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
          </div>

          <Divider />

          <span className='updated-at'>
            Updated on:{' '}
            {!published_at
              ? '-'
              : published_updated_at > published_at
              ? formatDate(published_updated_at)
              : formatDate(published_at)}
          </span>

          <span className='author'>Author: {author_name || author || '-'}</span>

          <div className='labels'>
            {item.content_label && (
              <span className='label-status'>{item.content_label}</span>
            )}

            {item.read === false && <span className='label-read'>Unread</span>}
          </div>
        </div>
      </div>
    )
  })
}

export default ModuleListing
