import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import './KnowledgeBaseArticle.scss'
import { KnowledgeBaseTopBar, KnowledgeBaseView } from '../../../components'
import { useKnowledgeBaseContext } from '../../../../../context/knowledgeBaseContext'
import {
  KnowledgeBaseTextContent,
  KnowledgeBaseVideoContent,
} from './components/'
import { getSingleArticle } from '../../../../../shared/services/api/knowledgebase/knowledgebase'
import { useViewStateContext } from '../../../../../context/viewStateContext'

const token = () => {
  return localStorage.getItem('access_token')
}

const fetchSingleArticle = async (id) => {
  try {
    const response = await getSingleArticle(token(), id)

    return response
  } catch (err) {
    // setLoading(false)
    console.log(err)
  }
}

const KnowledgeBaseArticle = () => {
  const { category, article } = useParams()
  const { setActiveLink } = useKnowledgeBaseContext()
  const [articleContent, setArticleContent] = useState({})
  const { setKnowledgebaseLink } = useViewStateContext()

  useEffect(() => {
    ;(async () => {
      setArticleContent(await fetchSingleArticle(article))
    })()
  }, [article])

  useEffect(() => {
    setActiveLink(`${category}/${article}`)
    setKnowledgebaseLink(`knowledge-base/${category}/${article}`)
  }, [category, article])

  return (
    <div className='knowledge-base-article'>
      <KnowledgeBaseView />

      <div className='knowledge-base-article__content-wrapper'>
        <KnowledgeBaseTopBar />

        {articleContent?.type === 'text' && (
          <KnowledgeBaseTextContent article={articleContent} />
        )}

        {articleContent?.type === 'video' && (
          <KnowledgeBaseVideoContent article={articleContent} />
        )}

        {articleContent.error && (
          <div
            style={{
              fontSize: '16px',
              color: '#212935',
              paddingTop: '30px',
              paddingLeft: '40px',
              fontWeight: 700,
            }}
          >
            No found article
          </div>
        )}
      </div>
    </div>
  )
}

export default KnowledgeBaseArticle
