/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'

import { useUserContext } from './userContext'
interface GroupChats {
  id: number
  name: string
  is_organisation?: boolean
  initials: string | null
  chat_settings: {
    chat_email_notify_interval: string
    chat_email_notify_mute: boolean
    total_unread_messages: number
  }
  pivot: {
    organisation_id: number
    group_id: number
  }
}

interface UserInfo {
  central_user_id: number
  display_name: string
  app_id: number
  organisation_name: string
  avatar: string
  last_message_at: string | null
  chat_settings: {
    chat_email_notify_interval: string
    chat_email_notify_mute: boolean
    total_unread_messages: number
  }
}

interface PeopleData {
  list: UserInfo[] | []
  currentChatId: null | { id: number; name: string }
}

interface GroupData {
  list: GroupChats[] | []
  currentChatId: null | { id: number; name: string }
}

interface DefaultState {
  groupChats: GroupData
  setGroupChats: Dispatch<SetStateAction<GroupData>>
  people: PeopleData
  setPeople: Dispatch<React.SetStateAction<PeopleData>>
  activeChat: null | { id: number; name: string }
  setActiveChat: Dispatch<SetStateAction<null | { id: number; name: string }>>
  chatCardDetails: object //TO FIX
  setChatCardDetails: Dispatch<SetStateAction<never>>
  activeUserChat: null | { id: number; name: string }
  setActiveUserChat: Dispatch<
    SetStateAction<null | { id: number; name: string }>
  >
  cachedPiecesOfContent: any
  setCachedPiecesOfContent: Dispatch<any>
  editedMessageId: null | number
  setEditedMessageId: Dispatch<SetStateAction<null | number>>
  chatOpen: boolean
  setChatOpen: Dispatch<SetStateAction<boolean>>
  queryData: any
  setQueryData: Dispatch<SetStateAction<any>>
  emailFetchState: null | 'started' | 'finished'
  setEmailFetchState: Dispatch<SetStateAction<null | 'started' | 'finished'>>
}

interface ContentPreview {
  author?: string
  centralised_id?: number
  content_label?: string
  eu_id?: number | null
  id?: number
  known_as?: string
  priority?: number
  priorityName?: string
  read?: boolean
  title?: string
  uk_id?: number | null
  updated_at?: string
}

interface InitCached {
  [key: string]: { [key: number]: ContentPreview }
}
{
  alert: {
  }
  profile: {
  }
  scenario: {
  }
  report: {
  }
}

const initCached = {
  alert: {},
  profile: {},
  scenario: {},
  report: {},
}

const defaultState = {
  groupChats: { list: [], currentChatId: null },
  setGroupChats: () => {},
  people: { list: [], currentChatId: null },
  setPeople: () => {},
  activeChat: null,
  setActiveChat: () => {},
  chatCardDetails: {},
  setChatCardDetails: () => {},
  activeUserChat: null,
  setActiveUserChat: () => {},
  cachedPiecesOfContent: initCached,
  setCachedPiecesOfContent: () => {},
  editedMessageId: null,
  setEditedMessageId: () => {},
  chatOpen: true,
  setChatOpen: () => {},
  queryData: null,
  setQueryData: () => {},
  emailFetchState: null,
  setEmailFetchState: () => {},
}

const ChatContext = createContext<DefaultState>(defaultState)

interface ChildrenType {
  children: ReactElement
}

export const ChatContextProvider = ({ children }: ChildrenType) => {
  const { user } = useUserContext()
  const [groupChats, setGroupChats] = useState<GroupData>({
    list: [],
    currentChatId: null,
  })
  const [activeChat, setActiveChat] = useState<null | {
    id: number
    name: string
  }>(null)
  const [chatCardDetails, setChatCardDetails] = useState<object>({})
  const [activeUserChat, setActiveUserChat] = useState<null | {
    id: number
    name: string
  }>(null)
  const [people, setPeople] = useState<PeopleData>({
    list: [],
    currentChatId: null,
  })
  const [cachedPiecesOfContent, setCachedPiecesOfContent] =
    useState<InitCached>(initCached)
  const [editedMessageId, setEditedMessageId] = useState<null | number>(null)
  const [chatOpen, setChatOpen] = useState<boolean>(
    JSON.parse(localStorage.getItem('isChatOpen') ?? 'false'),
  )
  const [queryData, setQueryData] = useState<any>(null)
  const [emailFetchState, setEmailFetchState] = useState<
    null | 'started' | 'finished'
  >(null)

  useEffect(() => {
    setEditedMessageId(null)
  }, [activeChat])

  useEffect(() => {
    localStorage.setItem('isChatOpen', JSON.stringify(chatOpen))
  }, [chatOpen])

  useEffect(() => {
    user?.chat_groups &&
      setGroupChats({ list: user?.chat_groups, currentChatId: null })
  }, [user?.chat_groups])

  const props = {
    groupChats,
    setGroupChats,
    activeChat,
    setActiveChat,
    chatCardDetails,
    setChatCardDetails,
    activeUserChat,
    setActiveUserChat,
    people,
    setPeople,
    cachedPiecesOfContent,
    setCachedPiecesOfContent,
    editedMessageId,
    setEditedMessageId,
    chatOpen,
    setChatOpen,
    queryData,
    setQueryData,
    emailFetchState,
    setEmailFetchState,
  }

  return <ChatContext.Provider value={props}>{children}</ChatContext.Provider>
}

export const useChat = () => useContext(ChatContext)
